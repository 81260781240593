import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid } from '@mui/material'
import ButtonWithLoading from '../../_components/_form/ButtonWithLoading'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../_utils/Validation'
import { generalErrorHandler, renderToastSuccess } from '../../_utils/Helper'

export type URLPlacementFormModalState = {
    id?: number
    slug: string
    group_id: null | number
    group_name: string
    pretext: string
}

interface URLPlacementFormModalProps {
    isOpen: boolean
    onClose: () => void
    onFinish: () => void
    /**
     * data for edit form
     */
    editData?: URLPlacementFormModalState
}

const URLPlacementFormModal = (props: URLPlacementFormModalProps) => {
    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState<URLPlacementFormModalState>(INITIAL_STATE)
    const [error, setError] = useState<IValidationErrors<URLPlacementFormModalState>>({})

    useEffect(() => {
        if (!props.isOpen) {
            setState(INITIAL_STATE)
            setError({})
        }
    }, [props.isOpen])

    useEffect(() => {
        if (props.isOpen && props.editData) {
            setState(props.editData)
        }
    }, [props.editData, props.isOpen])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            [`${name}_id`]: value.id,
            [`${name}_name`]: value.label,
        }))

        setError(prev => ({
            ...prev,
            [`${name}_id`]: '',
        }))
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setState(prev => ({
            ...prev,
            [`${name}_id`]: null,
            [`${name}_name`]: '',
        }))

        setError(prev => ({
            ...prev,
            [`${name}_id`]: '',
        }))
    }

    const handleSubmit = () => {
        const { errors, isValid } = validateData(state, rules, alias)
        setError(errors)

        if (isValid) {
            setIsLoading(true)

            DefaultAxios.request({
                method: props.editData ? 'PATCH' : 'POST',
                url: `${process.env.REACT_APP_API_URL}/url-whatsapp${props.editData ? `/${props.editData.id}` : ''}`,
                data: state,
            })
                .then(res => res.data)
                .then(() => {
                    renderToastSuccess(props.editData ? 'Berhasil mengubah data' : 'Berhasil menambahkan data baru')
                    props.onClose()
                    props.onFinish()
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={() => isLoading ? null : props.onClose()}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>URL Placement Form</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ paddingTop: 8 }}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Slug"
                            name="slug"
                            placeholder="Masukan Slug"
                            value={state.slug}
                            onChange={handleChange}
                            error={!!error.slug}
                            helperText={error.slug}
                            disabled={isLoading}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/lead-group`}
                            label="Group"
                            name="group"
                            initialQuery={state.group_name}
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            placeholder="Masukan Nama Group"
                            errorText={error.group_id}
                            disabled={isLoading}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Pretext"
                            name="pretext"
                            placeholder="Masukan Pretext"
                            value={state.pretext}
                            onChange={handleChange}
                            error={!!error.pretext}
                            helperText={error.pretext}
                            disabled={isLoading}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWithLoading
                    color="primary"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                >
                    Submit
                </ButtonWithLoading>
            </DialogActions>
        </Dialog>
    )
}

const INITIAL_STATE: URLPlacementFormModalState = {
    slug: '',
    group_id: null,
    group_name: '',
    pretext: '',
}

const rules: IValidationRules = {
    slug: 'required',
    group_id: 'required',
    pretext: 'required',
}

const alias: IValidationAlias = {
    slug: 'Slug',
    group_id: 'Group',
    pretext: 'Pretext',
}

export default URLPlacementFormModal
