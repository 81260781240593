import React from 'react';
import { Dialog } from '@mui/material';

interface Props {
    images: string[] | undefined;
    onClose: () => void;
}

const MultipleImagePreview = (props: Props) => {
    return (
        <Dialog
            open={!!props.images?.length}
            onClose={props.onClose}
            scroll="body"
            style={{
                backgroundColor: 'transparent',
                boxShadow: 'initial',
            }}
            maxWidth={false}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'initial'
                }
            }}
        >
            {
                props.images && props.images.length ?
                    props.images.map((image, i) => {
                        return (
                            <img
                                src={image}
                                width="100%"
                                style={{
                                    backgroundColor: 'transparent',
                                    display: 'block',
                                    marginBottom: '10px',
                                    minWidth: '400px'
                                }}
                                alt="preview"
                                key={i}
                            />
                        )
                    }) : null
            }
        </Dialog>
    );
}

export default MultipleImagePreview;