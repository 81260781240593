import React, { useEffect, useState } from 'react';
import {
    Grid,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    Theme,
    useMediaQuery,
    useTheme
} from '@mui/material';
import moment from 'moment';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import DateRangePicker, { DateRange } from '../../_components/_form/DateRangePicker';

/**
 * Utils
 */
import { generalErrorHandler } from '../../_utils/Helper';
import DefaultAxios from '../../_utils/DefaultAxios';

const formatDate = (date: DateRange) => {
    if (!date) return ''

    return moment(date).format('DD/MM/YYYY')
}

const LeadCounterReport = ({is_new} : { is_new?: boolean}) => {
    const theme = useTheme<Theme>()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const initialDateFilter = {
        date_start: moment().subtract(1, 'weeks'),
        date_end: moment()
    };

    const [isLoading, setIsLoading] = useState(false);
    const [dateFilter, setDateFilter] = useState<{ date_start: DateRange, date_end: DateRange }>(initialDateFilter);
    const [rows, setRows] = useState<any[]>([]);

    const loadData = () => {
        setIsLoading(true);
        let data = {
            date: `${formatDate(dateFilter.date_start)} - ${formatDate(dateFilter.date_end)}`,
        }
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/pic-lead-counter-report${is_new ? '/new' : ''}`, { params: data })
            .then(res => {
                setRows(res.data);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleDateChanged = (dates: { startDate: DateRange, endDate: DateRange }) => {
        let newFilter = {
            date_start: dates.startDate,
            date_end: dates.endDate
        }
        setDateFilter(newFilter);
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Grid container spacing={3}>
            <LoadingScreen open={isLoading} fullScreen />

            {
                !isMobile &&
                <Grid item xs={12} md={6} />
            }
            <Grid item xs={12} md={6} style={{ textAlign: 'end' }}>
                <DateRangePicker
                    startDate={dateFilter.date_start}
                    endDate={dateFilter.date_end}
                    onDatesChange={handleDateChanged}
                    onFinished={loadData}
                    fullWidth
                />

            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Counter</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, key) => (
                                <TableRow key={key}>
                                    <TableCell>
                                        {row.name}
                                    </TableCell>
                                    <TableCell>
                                        {row.counter || 0}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell>Total</TableCell>
                                <TableCell>
                                    {rows.reduce((a, b) => {
                                        return a + (b.counter || 0);
                                    }, 0)}
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}

export default LeadCounterReport;