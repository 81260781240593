import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import DefaultAxios from '../../_utils/DefaultAxios';

interface Props {
    name: string;
    value: string;
    onChange: Function;
    imagesUpload?: boolean;
    imagesUploadHandler?: Function;
    customPage?: boolean;
    smallHeight?: boolean;
}

const TextEditor = (props: Props) => {
    return (
        <Editor
            tinymceScriptSrc='https://d2jgpj59n9tcev.cloudfront.net/tinymce/tinymce.min.js'
            licenseKey='gpl'
            value={props.value}
            init={{
                height: props.smallHeight ? 150 : props.customPage ? 300 : 500,
                // style: classes.editor,
                // selector: '#textarea',
                // init_instance_callback: function () {
                //     const freeTiny = document.querySelector('.tox .tox-notification--in');
                //     if (freeTiny) {
                //         freeTiny.style.display = 'none';
                //     }
                // },
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help code wordcount'
                ],
                toolbar:
                    props.smallHeight ? 'undo redo bold italic underline strikethrough fontsizeselect forecolor code' : `undo redo | formatselect | bold italic ${props.customPage ? 'underline strikethrough forecolor fontsizeselect' : ''} backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ${props.imagesUpload ? 'image' : ''} media removeformat code link | help`,
                invalid_elements:
                    'script',
                images_upload_handler: (blobInfo: any, success: any, failure: any, progress: any) => {
                    if (props.imagesUploadHandler) {
                        props.imagesUploadHandler(blobInfo, success, failure, progress);
                    } else {
                        const fd = new FormData();

                        fd.append('image', blobInfo.blob(), blobInfo.filename());

                        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/editor-upload-file`, fd)
                            .then(res => {
                                success(res.data);
                            })
                            .catch(err => {
                                failure('Image upload failed');
                            });
                    }
                }
            }}
            onEditorChange={(value) => props.onChange(props.name, value)}
        />
    );
}

export default TextEditor;