import React, { useState } from 'react'
import { Grid, Button, IconButton } from '@mui/material'
import DataTable from '../../_components/_dataTable/DataTable'


/**
 * Icons
 */
import DeleteIcon from '@mui/icons-material/Delete';
import BlacklistSystemFormModal from './_components/BlacklistSystemFormModal';
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper';
import LoadingScreen from '../../_components/LoadingScreen';

const BlacklistSystemList = () => {
    const [addModal, setAddModal] = useState(false)
    const [redrawDataTable, setReDrawDataTable] = useState(new Date().getTime())
    const [isLoading, setIsLoading] = useState(false)

    const handleAdd = () => {
        setAddModal(true)
    }

    const handleDeletePhone = (row: any) => {
        renderWarningButton('Apakah anda ingin menghapus phone ini dari list?')
            .then(res => {
                if (res.dismiss) return

                setIsLoading(true)
                DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/blacklisted-phone/${row.id}`)
                    .then(res => res.data)
                    .then(res => res && renderToastSuccess('Phone Deleted!'))
                    .catch(generalErrorHandler)
                    .finally(() => [setIsLoading(false), setReDrawDataTable(new Date().getTime())])

            })
    }

    return (
        <>
            <LoadingScreen open={isLoading} />
            <Grid container>
                <Grid item xs={12}>
                    <Button
                        variant='contained'
                        onClick={handleAdd}
                        style={{ float: 'right' }}
                    >
                        Add
                    </Button>
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                    <DataTable
                        reDraw={redrawDataTable}
                        columns={[
                            { name: 'phone', label: 'Phone', filterable: true },
                            { name: 'reason', label: 'Reason', filterable: true },
                            {
                                name: 'EXTRA', label: 'Action', filterable: false,
                                render: (row: any) => {
                                    return (
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <IconButton
                                                onClick={() => handleDeletePhone(row)}
                                                color='secondary'
                                                title='Delete Phone'
                                            >
                                                <DeleteIcon />
                                            </IconButton>

                                        </div>
                                    )
                                }
                            }
                        ]}
                        url={`${process.env.REACT_APP_API_URL}/blacklisted-phone`}
                    />
                </Grid>
            </Grid>

            <BlacklistSystemFormModal
                open={addModal}
                onClose={(refresh) => {
                    setAddModal(false)

                    if (refresh) {
                        setReDrawDataTable(new Date().getTime())
                    }
                }}
            />
        </>
    )
}

export default BlacklistSystemList