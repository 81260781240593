export type CustomRulesOuput = {
    isValid: boolean
    errorMessage: string
}

export type IValidationErrors<T = any> = Partial<{
    [key in keyof T]: string
}>

export interface IValidationRules {
    [key: string]: string | ((value: string) => CustomRulesOuput)
}

export interface IValidationAlias {
    [key: string]: string
}

type IMessages = {
    [key in 'required' | 'email' | 'number' | 'file' | 'latitude' | 'longitude']: string
}

const messages: IMessages = {
    required: 'Field :key is required',
    email: 'Invalid format for Email',
    number: 'Field :key need to be a number',
    file: 'File in not recognized',
    latitude: 'Invalid latitude value',
    longitude: 'Invalid longitude value'
}

const parseMessage = (messageType: keyof IMessages, fieldName?: string) => {
    if (fieldName) {
        return messages[messageType].replace(':key', fieldName)
    } else {
        return messages[messageType]
    }
}

/**
 * Validate data
 * @param data object with key value pair. e.g { name: 'Rakumairu' }
 * @param rules object with key for data and its rules. e.g { name: 'required|required_if|number|email|nullable' }
 *              for custom rules you can pass a function that returns isValid and error message
 * @param exceptions array of key that need to be skipped
 */
export const validateData = (data: any, rules: IValidationRules, alias?: IValidationAlias, exceptions?: string[]) => {
    const errors: IValidationErrors = {}
    let valid = true

    for (const key in data) {
        if (exceptions?.includes(key)) continue

        if (!(key in rules)) continue

        const currentValue = data[key]
        const keyName = alias ? alias[key] || key : key

        const currentRules = rules[key]

        // Default rules validation
        if (typeof currentRules === 'string') {
            const rulesList = typeof currentRules === 'string' ? currentRules.split('|') : currentRules

            if (rulesList.length === 0) continue

            if (rulesList.includes('nullable') && !currentValue) continue

            if (rulesList.includes('required') && !currentValue) {
                valid = false
                errors[key] = parseMessage('required', keyName)
            }

            const requiredIfArray = rulesList.filter(value => /required_if/.test(value))
            if (requiredIfArray.length > 0 && !currentValue) {
                const requirementArray = requiredIfArray[0].split(':')
                // check if there's actually a requirement
                if (requirementArray.length < 2) throw new Error('required_if requires secondary parameter. e.g required_if:name=cat')

                const requirement = requirementArray[1]
                // check if the requirements is an equal comparison
                if (requirement.includes('=')) {
                    const splittedRequirement = requirement.split('=')

                    if (splittedRequirement.length !== 2) throw new Error('equal comparison requires key and value pair that need to be checked')

                    const secondKey = splittedRequirement[0]
                    const secondValue = ['true', 'false'].includes(splittedRequirement[1]) ? splittedRequirement[1] === 'true' ? true : false : splittedRequirement[1]

                    if (data[secondKey] === secondValue) {
                        valid = false
                        errors[key] = parseMessage('required', keyName)
                    }
                }
            }

            if (rulesList.includes('email') && !!currentValue) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (!re.test(String(currentValue).toLowerCase())) {
                    valid = false
                    errors[key] = parseMessage('email')
                }
            }

            if (rulesList.includes('number') && !!currentValue) {
                if (!Number.isFinite(Number(currentValue))) {
                    valid = false
                    errors[key] = parseMessage('number', keyName)
                }
            }

            if (rulesList.includes('latitude') && !!currentValue) {
                const parsedLat = parseFloat(currentValue)
                if (!(isFinite(parsedLat) && Math.abs(parsedLat) <= 90)) {
                    valid = false
                    errors[key] = parseMessage('latitude')
                }
            }

            if (rulesList.includes('longitude') && !!currentValue) {
                const parsedLat = parseFloat(currentValue)
                if (!(isFinite(parsedLat) && Math.abs(parsedLat) <= 180)) {
                    valid = false
                    errors[key] = parseMessage('longitude')
                }
            }

            if (rulesList.includes('File') && !!currentValue) {
                if (!('type' in currentValue)) {
                    valid = false
                    errors[key] = parseMessage('file', keyName)
                }
            }
        } else {
            // Custom validation
            const { isValid, errorMessage } = currentRules(currentValue)
            if (!isValid) {
                valid = false
                errors[key] = errorMessage
            }
        }
    }

    return {
        isValid: valid,
        errors,
    }
}

export const validateOne = (key: string, value: any, rules: IValidationRules, alias?: IValidationAlias, exceptions?: string[]) => {
    return validateData({ [key]: value }, rules, alias, exceptions)
}