import React, { useEffect, useRef, useState } from 'react'

import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Avatar,
    Divider,
} from '@mui/material';

import { generateStyle } from '../../_utils/DefaultStyles';
import { Grid } from '@mui/material';
import Rank1 from "../../Assets/Images/svg/rank1.svg"
import Rank2 from "../../Assets/Images/svg/rank2.svg"
import Rank3 from "../../Assets/Images/svg/rank3.svg"
import { converNumberFormat, generalErrorHandler } from '../../_utils/Helper';
import DefaultAxios from '../../_utils/DefaultAxios';
import LoadingScreen from '../../_components/LoadingScreen';

interface AgentRanking {
    name: string;
    avatar_url: string;
    nominal: number;
    group_label: string
    // area: string
}

interface FilterOption {
    id: number;
    name: string;
    isSelected?: boolean;
}


const useStyles = generateStyle((theme) => ({
    tableContainer: {
        position: 'relative',
        flex: 1,
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 200px)',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
        },
    },
    sidebarContainers: {
        width: '263px',
        flexShrink: 0,
        marginLeft: -10,
        paddingRight: 25
    },
    title: {
        fontSize: '30px',
        color: '#484848',
        fontWeight: 700,
        marginLeft: 30
    },
    subtitle: {
        color: '#484848',
        fontSize: '18px',
        marginLeft: 30
    },
    filterTitle: {
        fontSize: '18px',
        fontWeight: 700,
        paddingLeft: '30px',
        paddingTop: 10
    },
    listItem: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        marginTop: 10,
        marginBotom: 10,
        fontSize: 14,
        color: "#484848",
        cursor: 'pointer',
        '&.selected': {
            backgroundColor: '#035AA6',
            color: '#ffffff',
            borderRadius: 12,
            fontWeight: 'bold',
            width: "100%"
        },
        '&:hover': {
            backgroundColor: 'rgba(3, 90, 166, 0.08)',
            borderRadius: 12,
        },
    },
    divider: {
        marginRight: 45,
        marginLeft: 45,
        marginTop: 10
    },
    sidebar: {
        borderRadius: '12px',
        backgroundColor: "#EFF7FE",
        marginTop: 40,
        paddingTop: 10,
        paddingBottom: 20,
        height: 'calc(100vh - 216px)'
    },
    filterSection: {
        flex: 1,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
        },
        backgroundColor: "#EFF7FE"
    },
    mainContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    table: {
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
    tableHead: {
        height: 50,
        // position: 'relative',
        zIndex: 999,
        backgroundColor: '#00A1DF',
        '& th:first-of-type': {
            borderTopLeftRadius: '12px',
        },
        '& th:last-of-type': {
            borderTopRightRadius: '12px',
        },
        overflow: 'hidden'
    },
    tableWrapper: {
        flex: 1,
        marginTop: 13,
        overflowY: 'auto',
        maxHeight: 'calc(-109px + 100vh)',
        '&::-webkit-scrollbar': {
            display: 'none',
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
        },
        '&:after': {
            content: '""',
            height: '50px',
            background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
            pointerEvents: 'none',
        },
    },
    scrollableSection: {
        maxHeight: 'calc(100% - 230px)',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
            width: '8px',
        },
        // Untuk browser yang mendukung scrollbar selain Webkit
        '&::-moz-scrollbar': {
            display: 'none',
        },
    },
    agentInfo: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
}), 'TopSellerList');

// Sample Data
const filterOptions: FilterOption[] = [
    { id: 0, name: 'Surat Pemesanan', isSelected: true },
    { id: 1, name: 'Non Refundable' },
    { id: 2, name: 'Akad' },
];


const TopSellerList = () => {
    const { Root, classes } = useStyles();

    const [filterOption, setFilterOption] = useState<FilterOption[]>(filterOptions);
    const [agents, setAgents] = useState<AgentRanking[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [groups, setGroups] = useState<{ id: number; name: string; isSelected?: boolean }[]>([]);

    const isFirstRender = useRef(true)

    const currentDate = new Date();
    const monthYear = new Intl.DateTimeFormat('id-ID', {
        month: 'long',
        year: 'numeric',
    }).format(currentDate);

    useEffect(() => {
        fetchGroups()
    }, [])

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false; // set ke false setelah render pertama
            return;
        }
        fetchAgentData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterOption, groups]);

    const fetchGroups = () => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/lead-group/all`)
            .then(res => res.data)
            .then(data => {
                const groupsWithSelection = [
                    { id: -1, name: 'All', isSelected: true }, // menambahkan opsi All
                    ...data.map((item: { id: number; name: string }) => ({
                        ...item,
                        isSelected: false
                    }))
                ];
                setGroups(groupsWithSelection);
            })
            .catch(generalErrorHandler);
    };

    const fetchAgentData = async () => {
        try {
            setLoading(true);
            const selectedGroupId = groups.find(group => group.isSelected)?.id;

            const response = await DefaultAxios.get(`${process.env.REACT_APP_API_URL}/ranking-agent`, {
                params: {
                    type: filterOption.find(option => option.isSelected)?.id,
                    group_id: selectedGroupId === -1 ? null : selectedGroupId,
                },
            });

            // Menangani respons dan memformat data agent
            const formattedAgents = response.data.map((agent: any) => {
                return {
                    name: agent.name,
                    avatar_url: agent.avatar_url,
                    nominal: agent.nominal,
                    group_label: agent.group_label
                };
            });

            setAgents(formattedAgents);
        } catch (error) {
            generalErrorHandler(error)
        } finally {
            setLoading(false);
        }
    };

    const getBackgroundColor = (agentId: number) => {
        if (agentId === 0) return '#A4D2FA';
        if (agentId === 1) return '#BDDFFC';
        if (agentId === 2) return '#DFEFFE';
    };

    const toggleGroupOneSelection = (id: number) => {
        setFilterOption((prevOptions) =>
            prevOptions.map((option) =>
                ({ ...option, isSelected: option.id === id })
            )
        );
    };

    const toggleGroupTwoSelection = (id: number) => {
        setGroups((prevGroups) =>
            prevGroups.map((group) => ({
                ...group,
                isSelected: group.id === id
            }))
        );
    };

    return (
        <Root>
            <LoadingScreen open={loading} />
            <Grid container spacing={2}>
                {/* <Grid item xs={2}> */}
                <div className={classes.sidebarContainers}>
                    <Box>
                        <Typography variant="h6" className={classes.title}>
                            Top Seller
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                            {monthYear}
                        </Typography>
                    </Box>

                    <Box className={classes.sidebar}>
                        <Typography className={classes.filterTitle}>Filter By</Typography>

                        <List sx={{ marginRight: '30px', marginLeft: '30px' }}>
                            {filterOption.map((option) => (
                                <ListItem
                                    key={option.id}
                                    className={`${classes.listItem} ${option.isSelected ? 'selected' : ''}`}
                                    onClick={() => toggleGroupOneSelection(option.id)}
                                >
                                    <ListItemText
                                        primary={<Box component="span" sx={{ fontWeight: option.isSelected ? 'bold' : 'normal', fontSize: 14 }}>{option.name}</Box>}
                                    />
                                </ListItem>
                            ))}
                        </List>

                        <Divider className={classes.divider} />

                        <div className={classes.scrollableSection}>
                            <List sx={{ marginRight: '30px', marginLeft: '30px' }}>
                                {groups.map((option) => (
                                    <ListItem
                                        key={option.id}
                                        className={`${classes.listItem} ${option.isSelected ? 'selected' : ''}`}
                                        onClick={() => toggleGroupTwoSelection(option.id)}
                                    >
                                        <ListItemText
                                            primary={<Box component="span" sx={{ fontWeight: option.isSelected ? 'bold' : 'normal', fontSize: 14 }}>{option.name}</Box>}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </Box>
                </div>
                <div className={classes.mainContent}>
                    <TableContainer component={Box} className={classes.tableWrapper}>
                        <Table stickyHeader sx={{
                            overflowY: 'hidden',
                        }}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell sx={{
                                        fontWeight: 'bold',
                                        color: "#ffffff",
                                        fontSize: 16,
                                        padding: '8px 0px 8px 25px',
                                        width: {
                                            xs: 55,
                                            '@media (max-width:1366px)': {
                                                width: 48,
                                            },
                                        },
                                        backgroundColor: '#035AA6'
                                    }}>
                                        Position
                                    </TableCell>
                                    <TableCell sx={{
                                        fontWeight: 'bold',
                                        color: "#ffffff",
                                        fontSize: 16,
                                        padding: '8px 25px',
                                        '@media (max-width:1366px)': {
                                            padding: '8px 20px',
                                        },
                                        marginLeft: '-48px',
                                        backgroundColor: '#035AA6'
                                    }}>
                                        Agents
                                    </TableCell>
                                    <TableCell sx={{
                                        fontWeight: 'bold',
                                        color: "#ffffff",
                                        fontSize: 16,
                                        padding: '8px 25px',
                                        backgroundColor: '#035AA6'
                                    }} align="right">
                                        Value
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {agents.length > 0 ? (agents.map((agent, id: number) => (
                                    <TableRow key={id} sx={{ background: getBackgroundColor(id) }}>
                                        <TableCell sx={{
                                            padding: '8px 0px 8px 25px',
                                            maxWidth: 35,
                                            marginLeft: '-18px'
                                        }}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginLeft: '-2px'
                                            }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: id === 0 || id === 1 ? 20 : 16,
                                                        fontWeight: id === 0 || id === 1 || id === 2 ? 700 : 400,
                                                        color: "#484848",
                                                    }}
                                                >
                                                    {id + 1}.
                                                </Typography>
                                                {id === 0 && <img src={Rank1} alt="Rank 1" style={{ marginLeft: 18 }} />}
                                                {id === 1 && <img src={Rank2} alt="Rank 2" style={{ marginLeft: 18 }} />}
                                                {id === 2 && <img src={Rank3} alt="Rank 3" style={{ marginLeft: 22 }} />}
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{
                                            padding: '8px 0px 8px 35px',
                                            '@media (max-width:1366px)': {
                                                padding: '8px 0px 8px 30px',
                                            },
                                            maxWidth: 300,
                                            marginLeft: '-28px'
                                        }}>
                                            <Box className={classes.agentInfo} sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginLeft: '-10px'
                                            }}>
                                                <Avatar src={agent.avatar_url} />
                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography sx={{
                                                        fontSize: id === 0 || id === 1 ? 20 : 16,
                                                        fontWeight: 700,
                                                        color: "#484848",
                                                    }}>
                                                        {agent.name}
                                                    </Typography>
                                                    {agent?.group_label !== null && <span style={{ color: "#484848", fontSize: id === 0 || id === 1 ? 16 : 14, marginTop: 2 }}>{agent.group_label}</span>}
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right" sx={{ padding: '8px 25px' }}>
                                            <Typography sx={{
                                                fontSize: id === 0 || id === 1 ? 20 : 16,
                                                fontWeight: 700,
                                                color: "#484848"
                                            }}>
                                                Rp {converNumberFormat(String(agent.nominal)).replace(/,/g, ".")}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))) : (
                                    <Typography sx={{ width: 125 }}>Tidak ada data</Typography>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </div>

                {/* </Grid> */}
            </Grid>
        </Root>
    )
}

export default TopSellerList