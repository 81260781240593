import React, { useEffect, useMemo, useReducer, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    MenuItem
} from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import TextArea from '../../_components/_form/TextArea';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import helpers from '../../_utils/Helpers';
import DefaultAxios from '../../_utils/DefaultAxios';

interface Props {
    open: boolean;
    onClose: (fetch: boolean) => void;
    leadId: string;
}

interface IState {
    id: string;
    client_name: string;
    client_phone: string;
    client_email: string;
    status: string;
    source: string;
    cluster_id: string;
    cluster_name: string;
    other_cluster_name: string
    cluster_has_placement: string // 0 | 1
    agent_id: string;
    agent_name: string;
    notes: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IErrorState {
    client_name: string;
    client_phone: string;
    client_email: string;
    source: string;
    cluster_id: string;
    other_cluster_name: string
    agent_id: string;
}

const LeadForm = ({ open, onClose, leadId }: Props) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/lead`;

    const initialState = {
        id: '',
        client_name: '',
        client_phone: '',
        client_email: '',
        status: '',
        source: 'instagram-dm',
        cluster_id: '',
        cluster_name: '',
        other_cluster_name: '',
        cluster_has_placement: '1',
        agent_id: '',
        agent_name: '',
        notes: '',
    };

    const initialErrorState = {
        client_name: '',
        client_phone: '',
        client_email: '',
        source: '',
        cluster_id: '',
        other_cluster_name: '',
        agent_id: '',
    };

    const sourceLists = [
        { key: "instagram-dm", value: "Instagram DM" },
        { key: "facebook-dm", value: "Facebook DM" },
        { key: "whatsapp", value: "Whatsapp" },
        { key: "youtube", value: "Youtube" },
        { key: "tiktok", value: "TikTok" },
    ];

    const sourceListsEdit = [
        { key: "web", value: "Web" },
        { key: "input-agent", value: "Input Agent" },
        { key: "instagram-dm", value: "Instagram DM" },
        { key: "facebook-dm", value: "Facebook DM" },
        { key: "youtube", value: "Youtube" },
        { key: "whatsapp", value: "Whatsapp" },
        { key: "tiktok", value: "TikTok" },
    ]

    const [isLoading, setIsLoading] = useState(false);
    const [forceAgentForm, setForceAgentForm] = useState(false);

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    const showAgentForm = useMemo(() => inputState.cluster_id === 'other' || forceAgentForm, [inputState.cluster_id, forceAgentForm])

    useEffect(() => {
        if (!open) {
            setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
            setErrorState({ name: '', value: initialErrorState, type: 'REPLACE_STATE' });
        } else {
            if (leadId) {
                loadData();
            }
        }
        // eslint-disable-next-line
    }, [open]);

    useEffect(() => {
        if (inputState.id === '' && !!inputState.cluster_id && inputState.cluster_id !== '' && inputState.cluster_id !== 'other') {
            setIsLoading(true);
            DefaultAxios.get(`${API_URL}/check-cluster-placement/${inputState.cluster_id}`)
                .then(res => {
                    setForceAgentForm(res.data);
                })
                .catch(err => {
                    helpers.generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line
    }, [inputState.cluster_id])

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${leadId}`)
            .then(res => {
                const newState = {
                    ...initialState,
                    ...res.data,
                    cluster_id: res.data.cluster_id || 'other',
                    cluster_name: res.data.cluster_name || 'Lainnya',
                };
                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target;
        let name = target.name;
        let value = target.value;

        if (name === 'client_phone') {
            value = helpers.convertIndonesiaPhoneNumber(value);
        }

        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name, value: value.id, type: 'SET_ITEM' });
        setInputState({ name: 'cluster_has_placement', value: value.has_placement, type: 'SET_ITEM' })
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.client_name) {
            newError.client_name = 'Nama Client wajib diisi';
            isValid = false;
        }

        if (inputState.client_email && !helpers.isValidEmail(inputState.client_email)) {
            newError.client_email = 'Format email tidak benar';
            isValid = false;
        }

        if (!inputState.client_phone) {
            newError.client_phone = 'No Hp Client wajib diisi';
            isValid = false;
        }

        if (!inputState.source) {
            newError.source = 'Source wajib diisi';
            isValid = false;
        }

        if (!inputState.cluster_id) {
            newError.cluster_id = 'Cluster wajib diisi';
            isValid = false;
        }

        if (inputState.cluster_id === 'other' && !inputState.other_cluster_name) {
            newError.other_cluster_name = 'Cluster wajib diisi';
            isValid = false;
        }

        if (!inputState.agent_id && showAgentForm) {
            newError.agent_id = 'Agent wajib diisi';
            isValid = false;
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;
        let data: any = { ...inputState };

        if (data.id) {
            axios = DefaultAxios.patch(`${API_URL}/${data.id}`, data)
        } else {
            axios = DefaultAxios.post(API_URL, data);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        onClose(true);
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            fullWidth
            maxWidth="md"
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>{leadId ? 'Edit Lead Form' : 'Add Lead Form'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ paddingTop: 8 }}>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Nama Client"
                            name="client_name"
                            value={inputState.client_name}
                            onChange={handleChange}
                            error={!!errorState.client_name}
                            helperText={errorState.client_name}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="No Hp Client"
                            name="client_phone"
                            value={inputState.client_phone}
                            onChange={handleChange}
                            error={!!errorState.client_phone}
                            helperText={errorState.client_phone}
                        />
                    </Grid>

                    {!leadId &&
                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                label="Source"
                                name="source"
                                value={inputState.source}
                                onChange={handleChange}
                                error={!!errorState.source}
                                helperText={errorState.source}
                            >
                                {(leadId ? sourceListsEdit : sourceLists).map(sourceList => <MenuItem value={sourceList.key} key={sourceList.key}>{sourceList.value}</MenuItem>)}
                            </TextField>
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            label="Cluster"
                            name="cluster_id"
                            initialQuery={inputState.cluster_name}
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/cluster?is_admin=1`}
                            placeholder="Cluster"
                            errorText={errorState.cluster_id}
                            emptyOption={{
                                id: 'other',
                                label: 'Lainnya',
                                display: 'No Result? Isi sendiri nama cluster lainnya'
                            }}
                        />
                    </Grid>

                    {
                        inputState.cluster_id === 'other' &&
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Nama Cluster"
                                name="other_cluster_name"
                                value={inputState.other_cluster_name}
                                onChange={handleChange}
                                error={!!errorState.other_cluster_name}
                                helperText={errorState.other_cluster_name}
                            />
                        </Grid>
                    }

                    {
                        showAgentForm &&
                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                label="Agent"
                                name="agent_id"
                                initialQuery={inputState.agent_name}
                                onChange={handleAutocomplete}
                                onInputChange={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/user/agent`}
                                placeholder="Agent"
                                errorText={errorState.agent_id}
                            />
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <TextArea
                            label="Notes"
                            name="notes"
                            value={inputState.notes || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default LeadForm;