import React, { useState, useEffect } from 'react';
import {
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Button,
    Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, InputAdornment, Typography
} from '@mui/material';

/**
 * Components
 */
import TextArea from '../../../_components/_form/TextArea';
import DatePicker from '../../../_components/_form/DatePicker';

/**
 * Icons
 */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler, renderSuccessButton } from '../../../_utils/Helper';
import { IValidationAlias, IValidationErrors, IValidationRules, validateData, validateOne } from '../../../_utils/Validation'
import { numberToCurrency } from '../../../_utils/Helpers';
import { format } from "date-fns";
import { generateStyle } from '../../../_utils/DefaultStyles';
import helpers from '../../../_utils/Helpers';

interface Props {
    permissions: any;
    closingId: string;
    data: any;
    onSubmit: Function
    onDelete: Function
    agent_name: string
    cobroke_name: string
    is_cobroke: number
}

interface IFormState {
    closing_id: string
    type: string
    borrower_type: string
    nominal: string
    nominal_cobroke: string
    dates: Date | null
    notes: string
}

interface IDetail {
    id: number
    agent_id: string
    created_at: string
    date: string
    type: string
    released_at: string
    released_date: string
    nominal: number
    paid_transaction_id: number | null
    notes: string
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        width: '100%',
        '& .MuiTextField-root': {
            backgroundColor: '#fff'
        }
    },
    buttonContainer: {
        // justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '16px',
        marginBottom: 8
    },
    buttonContainerDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    buttonContainerMobile: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
}), "PinjamanList");

const PinjamanList = (props: Props) => {
    const { Root, classes } = useStyles();
    const [modalOpen, setModalOpen] = useState(false)
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false)
    const [errorReason, setErrorReason] = useState('')
    const [deleteState, setDeleteState] = useState({
        id: '',
        deleted_reason: ''
    })
    const [formState, setFormState] = useState<IFormState>(initialFormState)

    const [error, setError] = useState<IValidationErrors>({})

    const validationRules: IValidationRules = {
        type: 'required',
        // borrower_type: props.is_cobroke ? 'required' : '',
        // nominal: 'required',
        // nominal: () => ({
        //     isValid: formState.nominal !== '' && formState.nominal !== '0',
        //     errorMessage: 'Field Nominal is required',
        // }),
        dates: 'required',
        notes: 'required_if:type=other',
    }

    useEffect(() => {
        if (!props.cobroke_name) {
            setFormState(prev => ({
                ...prev,
                borrower_type: '0'
            }))
        }
    }, [props.cobroke_name, modalOpen])

    useEffect(() => {
        setFormState(initialFormState)
    }, [props.data])

    // const handleModalOpen = () => {
    //     setModalOpen(true);
    // }

    const handleModalClose = () => {
        setModalOpen(false);
    }

    // const handleModalDeleteOpen = (id: string) => {
    //     setModalDeleteOpen(true);
    //     setDeleteState({
    //         id: id,
    //         deleted_reason: ''
    //     })
    // }

    const handleModalDeleteClose = () => {
        setModalDeleteOpen(false);
        setDeleteState({
            id: '',
            deleted_reason: ''
        })
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        const { errors, isValid } = validateOne(name, value, validationRules, alias)

        if (isValid) {
            setError(prev => ({
                ...prev,
                [name]: '',
            }))
        } else {
            setError(prev => ({
                ...prev,
                ...errors,
            }))
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, deletedState?: boolean) => {
        let name = e.target.name;
        let value: any = e.target.value;

        if (deletedState) {
            setDeleteState(prev => ({
                ...prev,
                [name]: value,
            }))
        } else {
            setFormState(prev => ({
                ...prev,
                [name]: value,
            }))
        }
    }

    const handleChangeDate = (name: string, date: string | Date) => {
        setFormState(prev => ({
            ...prev,
            [name]: date,
        }))

        if (date) {
            setError(prev => ({
                ...prev,
                dates: '',
            }))
        }
    }

    const handleSubmit = () => {
        const { isValid, errors } = validateData(formState, validationRules, alias)
        setError(errors)

        if (isValid) {
            handleModalClose()
            props.onSubmit(formState)
        }
    }

    const handleDelete = () => {
        if (!deleteState.deleted_reason) {
            setErrorReason('Field Deleted Reason is required')
        } else {
            setErrorReason('')
            setModalDeleteOpen(false)
            const data = {
                deleted_reason: deleteState.deleted_reason
            }
            DefaultAxios
                .delete(`${process.env.REACT_APP_API_URL}/closing-loan/${deleteState.id}`, { params: data })
                .then(() => {
                    renderSuccessButton('Berhasil Dihapus')
                    .then(() => {
                        props.onDelete();
                    });
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
        }
    }

    const renderTable = (data: any, name: string, number: number) => {
        const renderType = (type: string) => {
            let finalType = '';

            switch (type) {
                case 'commission_loan':
                    finalType = 'Pinjaman Komisi'
                    break;
                case 'compliment_client':
                    finalType = 'Compliment Client'
                    break;
                default:
                    finalType = `Lainnya`
                    break;
            }

            return finalType
        }
        return (
            <>
                <Typography variant="subtitle2" sx={{ ml: 2 }} >Advisor {props.is_cobroke ? number : ''}: {name}</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Jenis Pinjaman
                            </TableCell>
                            <TableCell>
                                Nominal
                            </TableCell>
                            <TableCell>
                                Tanggal Pengajuan Pinjaman
                            </TableCell>
                            <TableCell>
                                Tanggal Release Pinjaman
                            </TableCell>
                            <TableCell>
                                Paid
                            </TableCell>
                            {/* <TableCell>
                                Notes
                            </TableCell>
                            <TableCell>
                                Action
                            </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data && data.length > 0 ?
                                data.map((row: IDetail, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell>{renderType(row.type)}</TableCell>
                                        <TableCell>{row.nominal ? `Rp ${numberToCurrency(row.nominal)}` : '-'}</TableCell>
                                        <TableCell>{row.date ? format(new Date(row.date), 'dd-MMM-yyyy') : '-'}</TableCell>
                                        <TableCell>{row.released_at ? format(new Date(row.released_at), 'dd-MMM-yyyy') : '-'}</TableCell>
                                        <TableCell>{row.paid_transaction_id ? <CheckCircleIcon color="primary" /> : <CancelIcon color="error" />}</TableCell>
                                    </TableRow>
                                ))
                                :
                                <TableRow>
                                    <TableCell colSpan={4} align='center'>List tidak ditemukan</TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </>
        )
    }

    return (
        <Root>
            <div className={classes.root}>
                <Dialog
                    open={modalDeleteOpen}
                    onClose={handleModalDeleteClose}
                    fullWidth
                >
                    <DialogTitle>Deleted Reason</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <TextArea
                                    // label="Notes"
                                    name="deleted_reason"
                                    value={deleteState.deleted_reason}
                                    onChange={(e: any) => handleChange(e, true)}
                                    multiline
                                    error={!!errorReason}
                                    helperText={errorReason}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleModalDeleteClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleDelete} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={modalOpen}
                    onClose={handleModalClose}
                >
                    <DialogTitle>Form Add Pinjaman</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    select
                                    name="type"
                                    label="Pinjaman"
                                    value={formState.type}
                                    onChange={(e: any) => handleChange(e)}
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    error={!!error.type}
                                    helperText={error.type}
                                    onBlur={handleBlur}
                                >
                                    <option aria-label="None" value="" key="default" disabled >Silahkan Pilih Opsi</option>
                                    <option value="commission_loan">Pinjaman Komisi</option>
                                    <option value="compliment_client">Compliment Client</option>
                                    <option value="other">Lainnya</option>
                                </TextField>
                            </Grid>
                            {/* <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                select
                                name="borrower_type"
                                label="Peminjam"
                                value={formState.borrower_type}
                                onChange={(e: any) => handleChange(e)}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    native: true,
                                }}
                                disabled={props.is_cobroke !== 1}
                                error={!!error.borrower_type}
                                helperText={error.borrower_type}
                                onBlur={handleBlur}
                            >
                                <option aria-label="None" value="" key="default" disabled>Silahkan Pilih Opsi</option>
                                <option value="0">Advisor Utama</option>
                                <option value="1">Advisor Cobroke</option>
                            </TextField>
                        </Grid> */}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Nominal"
                                    name="nominal"
                                    value={formState.nominal ? helpers.convertNumber(formState.nominal.toString()) : ''}
                                    onChange={(e: any) => handleChange(e)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Rp.</InputAdornment>,
                                    }}
                                    error={!!error.nominal}
                                    helperText={error.nominal}
                                    onBlur={handleBlur}
                                />
                            </Grid>
                            {props.is_cobroke ?
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Nominal Cobroke"
                                        name="nominal_cobroke"
                                        value={formState.nominal_cobroke ? helpers.convertNumber(formState.nominal_cobroke.toString()) : ''}
                                        onChange={(e: any) => handleChange(e)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">Rp.</InputAdornment>,
                                        }}
                                        error={!!error.nominal_cobroke}
                                        helperText={error.nominal_cobroke}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                :
                                ""
                            }
                            <Grid item xs={12}>
                                <DatePicker
                                    label="Tanggal Pengajuan Pinjaman"
                                    format="dd/MM/yyyy"
                                    value={formState.dates}
                                    onChange={(date: any) => handleChangeDate('dates', date)}
                                    error={!!error.dates}
                                    helperText={error.dates}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextArea
                                    label="Notes"
                                    name="notes"
                                    value={formState.notes}
                                    onChange={(e: any) => handleChange(e)}
                                    error={!!error.notes}
                                    helperText={error.notes}
                                    handleBlur={handleBlur}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleModalClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <TableContainer component={Paper} elevation={0}>
                    {/* {props.permissions['closing-loan.manage'] &&
                    <>
                        <div className={`${classes.buttonContainer} ${classes.buttonContainerDesktop}`}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleModalOpen}
                            >
                                Add Pinjaman
                            </Button>
                        </div>
                        <div className={`${classes.buttonContainer} ${classes.buttonContainerMobile}`}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleModalOpen}
                                size="small"
                            >
                                Add Pinjaman
                            </Button>
                        </div>
                    </>
                } */}
                    <Grid sx={{ mb: 4, mt: 2 }}>
                        {renderTable(props.data.loan_lists, props.agent_name, 1)}
                    </Grid>
                    {
                        props.is_cobroke === 1 &&
                        <Grid>
                            {renderTable(props.data.loan_lists_2, props.cobroke_name, 2)}
                        </Grid>
                    }
                </TableContainer>
            </div>
        </Root>
    );
}

const initialFormState = {
    closing_id: '',
    type: '',
    borrower_type: '',
    nominal: '',
    nominal_cobroke: '',
    dates: null,
    notes: '',
}

const alias: IValidationAlias = {
    type: 'Pinjaman',
    borrower_type: 'Peminjam',
    nominal: 'Nominal',
    dates: 'Tanggal',
    notes: 'Notes',
}


export default PinjamanList;