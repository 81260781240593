import React, { useState, useEffect, useReducer } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, MenuItem, InputAdornment, IconButton } from '@mui/material';
import Swal from 'sweetalert2';

import { IOption } from './UserList';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

//Icon
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

/**
 * Utils
 */
import helpers from '../../_utils/Helpers';
import DefaultAxios from '../../_utils/DefaultAxios';
import { agentRole } from '../../_utils/ConstData';
import { inputNumber } from '../../_utils/Helper';

interface Props {
    open: boolean;
    roles: IOption[];
    onClose: (fetch: boolean) => void;
    userId: string;
}

interface IState {
    id: string;
    full_name: string;
    name: string;
    email: string;
    notification_email: string;
    phone: string;
    role_id: string;
    role_name: string
    primary_bank_type_id: string;
    primary_bank_type_label: string;
    primary_bank_number: string;
    primary_bank_behalf_name: string;
    level: string;
    limit_broadcast: number | null;
    commission_percentage: number | null;
    bukan_kata_sandi: string;
    bukan_kata_sandi_confirmation: string;
    group_id: string
    group_name: string
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IErrorState {
    name: string;
    full_name: string;
    email: string;
    notification_email: string;
    role_id: string;
    primary_bank_type_id: string;
    primary_bank_number: string;
    primary_bank_behalf_name: string;
    phone: string;
    level: string;
    limit_broadcast: string;
    commission_percentage: string;
    bukan_kata_sandi: string;
    bukan_kata_sandi_confirmation: string;
    group_id: string
}

const UserForm = ({ open, roles, onClose, userId }: Props) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/user`;

    const initialState = {
        id: '',
        full_name: '',
        name: '',
        email: '',
        notification_email: '',
        phone: '',
        role_id: '',
        role_name: '',
        primary_bank_type_id: '',
        primary_bank_type_label: '',
        primary_bank_number: '',
        primary_bank_behalf_name: '',
        level: '',
        limit_broadcast: null,
        commission_percentage: null,
        bukan_kata_sandi: '',
        bukan_kata_sandi_confirmation: '',
        group_id: '',
        group_name: '',
    };

    const initialErrorState = {
        name: '',
        full_name: '',
        email: '',
        notification_email: '',
        role_id: '',
        bank_name: '',
        primary_bank_number: '',
        primary_bank_behalf_name: '',
        primary_bank_type_id: '',
        phone: '',
        level: '',
        limit_broadcast: '',
        commission_percentage: '',
        bukan_kata_sandi: '',
        bukan_kata_sandi_confirmation: '',
        group_id: '',
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState({
        bukan_kata_sandi: false,
        bukan_kata_sandi_confirmation: false
    });

    useEffect(() => {
        setVisible({
            bukan_kata_sandi: false,
            bukan_kata_sandi_confirmation: false
        })

        if (!open) {
            setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
            setErrorState({ name: '', value: initialErrorState, type: 'REPLACE_STATE' });
        } else {
            if (userId) {
                loadData();
            }
        }
        // eslint-disable-next-line
    }, [open])

    useEffect(() => {
        if (localStorage.getItem("role") !== "superadmin" || !isAgentOrManager()) {
            setInputState({ name: 'level', value: '', type: 'SET_ITEM' });
        }
        // eslint-disable-next-line
    }, [inputState.role_id]);

    const isAgentOrManager = () => {
        return agentRole.includes(roles.find(role => role.key === inputState.role_id)?.value || '');
    }

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${userId}`)
            .then(res => {
                const newState = {
                    ...initialState,
                    ...res.data,
                    group_id: res.data.group_id || '',
                    group_name: res.data.group_name || '',
                    role_name: roles.filter(role => role.key === res.data.role_id)[0]?.value || '',
                };
                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target;
        let name = target.name;
        let value = target.value;

        console.log(name)

        if (['bukan_kata_sandi', 'bukan_kata_sandi_confirmation'].includes(name)) {
            value = value.replace(/\s*/g, '');
        }

        if (['limit_broadcast', 'commission_percentage', 'primary_bank_number'].includes(name)) {
            value = inputNumber(value)
        }

        if (name === 'phone') {
            value = helpers.convertIndonesiaPhoneNumber(value);
        }

        setInputState({ name: name, value: value, type: 'SET_ITEM' });

        if (name === 'role_id') {
            setInputState({ name: 'role_name', value: roles.filter(role => role.key === value)[0]?.value || '', type: 'SET_ITEM' });
        }
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        }

        if (!inputState.email) {
            newError.email = 'Email wajib diisi';
            isValid = false;
        } else if (!helpers.isValidEmail(inputState.email)) {
            newError.email = 'Format email tidak benar';
            isValid = false;
        }

        if (inputState.notification_email) {
            if (!helpers.isValidEmail(inputState.notification_email)) {
                newError.notification_email = 'Format email tidak benar';
                isValid = false;
            }
        }

        if (!inputState.role_id) {
            newError.role_id = 'Role wajib diisi';
            isValid = false;
        }

        if (!inputState.id) {
            if (!inputState.bukan_kata_sandi) {
                newError.bukan_kata_sandi = 'Password wajib diisi';
                isValid = false;
            }

            // if (!inputState.password_confirmation) {
            //     newError.password_confirmation = 'Confirm Password wajib diisi';
            //     isValid = false;
            // } else if (inputState.password_confirmation.length < 8) {
            //     newError.password_confirmation = 'Confirm Password minimal 8 karakter';
            //     isValid = false;
            // }
        }

        if (inputState.bukan_kata_sandi && inputState.bukan_kata_sandi.length < 8) {
            newError.bukan_kata_sandi = 'Password minimal 8 karakter';
            isValid = false;
        }

        if (inputState.bukan_kata_sandi !== inputState.bukan_kata_sandi_confirmation) {
            newError.bukan_kata_sandi_confirmation = 'Confirm Password harus sama dengan Password';
            isValid = false;
        }

        if (
            localStorage.getItem("role") !== "superadmin" ||
            (localStorage.getItem("role") === "superadmin" && isAgentOrManager())
        ) {
            if (!inputState.level) {
                newError.level = 'Level wajib diisi';
                isValid = false;
            }
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            // return;
        }

        let axios;

        if (inputState.id) {
            axios = DefaultAxios.patch(`${API_URL}/${inputState.id}`, inputState)
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        onClose(true);
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        if (name === 'primary_bank_type_id') {
            setInputState({ name: 'primary_bank_type_id', value: value.id, type: 'SET_ITEM' });
            setInputState({ name: 'primary_bank_type_label', value: value.label, type: 'SET_ITEM' });
        } else {
            setInputState({ name: 'group_id', value: value.id, type: 'SET_ITEM' });
            setInputState({ name: 'group_name', value: value.label, type: 'SET_ITEM' });
        }

    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        if (name === 'primary_bank_type_id') {
            setInputState({ name: 'primary_bank_type_id', value: '', type: 'SET_ITEM' });
            setInputState({ name: 'primary_bank_type_label', value: '', type: 'SET_ITEM' });
        } else {
            setInputState({ name: 'group_id', value: '', type: 'SET_ITEM' });
            setInputState({ name: 'group_name', value: '', type: 'SET_ITEM' });
        }
    }

    const handleClearAutoComplete = () => {
        setInputState({ name: 'primary_bank_type_id', value: '', type: 'SET_ITEM' });
        setInputState({ name: 'primary_bank_type_label', value: '', type: 'SET_ITEM' });
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            fullWidth
            maxWidth="md"
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>User Form</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ paddingTop: 8 }}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Name"
                            name="name"
                            value={inputState.name}
                            onChange={handleChange}
                            error={!!errorState.name}
                            helperText={errorState.name}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Full Name"
                            name="full_name"
                            value={inputState.full_name}
                            onChange={handleChange}
                            error={!!errorState.full_name}
                            helperText={errorState.full_name}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Email"
                            name="email"
                            value={inputState.email}
                            onChange={handleChange}
                            error={!!errorState.email}
                            helperText={errorState.email}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Notification Email"
                            name="notification_email"
                            value={inputState.notification_email}
                            onChange={handleChange}
                            error={!!errorState.notification_email}
                            helperText={errorState.notification_email}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Phone"
                            name="phone"
                            value={inputState.phone}
                            onChange={handleChange}
                            error={!!errorState.phone}
                            helperText={errorState.phone}
                        />
                    </Grid>

                    {localStorage.getItem("role") === "superadmin" &&
                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                label="Role"
                                name="role_id"
                                value={inputState.role_id ?? ""}
                                onChange={handleChange}
                                error={!!errorState.role_id}
                                helperText={errorState.role_id}
                            >
                                {roles.map(role => <MenuItem value={role.key} key={role.key}>{role.value}</MenuItem>)}
                            </TextField>
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            label='Nama Bank'
                            name='primary_bank_type_id'
                            onChange={handleAutocomplete}
                            initialQuery={inputState.primary_bank_type_label ? inputState.primary_bank_type_label : ''}
                            onInputChange={handleAutocompleteInputChanged}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/bank-type`}
                            clearable
                            onClear={handleClearAutoComplete}
                            errorText={errorState.primary_bank_type_id}
                        />
                        {/* <TextField
                            fullWidth
                            variant="outlined"
                            label="Nama Bank"
                            name="bank_name"
                            value={inputState.bank_name}
                            onChange={handleChange}
                            error={!!errorState.bank_name}
                            helperText={errorState.bank_name}
                        /> */}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Nomor Rekening"
                            name="primary_bank_number"
                            value={inputState.primary_bank_number}
                            onChange={handleChange}
                            error={!!errorState.primary_bank_number}
                            helperText={errorState.primary_bank_number}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Atas Nama Rekening"
                            name="primary_bank_behalf_name"
                            value={inputState.primary_bank_behalf_name}
                            onChange={handleChange}
                            error={!!errorState.primary_bank_behalf_name}
                            helperText={errorState.primary_bank_behalf_name}
                        />
                    </Grid>

                    {
                        (isAgentOrManager() && !['Telesales Coordinator', 'Telesales'].includes(inputState.role_name)) &&
                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/lead-group`}
                                label="Lead Group"
                                name="group_id"
                                initialQuery={inputState.group_name}
                                onChange={handleAutocomplete}
                                onInputChange={handleAutocompleteInputChanged}
                                placeholder="Masukan Nama Group"
                                errorText={errorState.group_id}
                            />
                        </Grid>
                    }

                    {
                        (localStorage.getItem("role") !== "superadmin" || (localStorage.getItem("role") === "superadmin" && isAgentOrManager())) ?
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Limit Broadcast"
                                        name="limit_broadcast"
                                        value={inputState.limit_broadcast}
                                        onChange={handleChange}
                                        error={!!errorState.limit_broadcast}
                                        helperText={errorState.limit_broadcast}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Persentase Komisi"
                                        name="commission_percentage"
                                        value={inputState.commission_percentage}
                                        onChange={handleChange}
                                        error={!!errorState.commission_percentage}
                                        helperText={errorState.commission_percentage}
                                    />
                                </Grid>
                                {
                                    !['Telesales Coordinator', 'Telesales'].includes(inputState.role_name) &&
                                    <Grid item xs={12}>
                                        <TextField
                                            select
                                            fullWidth
                                            variant="outlined"
                                            label="Level"
                                            name="level"
                                            value={inputState.level}
                                            onChange={handleChange}
                                            error={!!errorState.level}
                                            helperText={errorState.level}
                                        >
                                            <MenuItem value="junior">Junior</MenuItem>
                                            <MenuItem value="mid">Mid</MenuItem>
                                            <MenuItem value="senior">Senior</MenuItem>
                                        </TextField>
                                    </Grid>
                                }
                            </>
                            : null
                    }

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type={visible.bukan_kata_sandi ? 'text' : 'password'}
                            label="Password"
                            name="bukan_kata_sandi"
                            value={inputState.bukan_kata_sandi}
                            onChange={handleChange}
                            error={!!errorState.bukan_kata_sandi}
                            helperText={errorState.bukan_kata_sandi}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setVisible(prevState => ({ ...prevState, bukan_kata_sandi: !prevState.bukan_kata_sandi }))}
                                        >
                                            {visible.bukan_kata_sandi ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type={visible.bukan_kata_sandi_confirmation ? 'text' : 'password'}
                            label="Confirm Password"
                            name="bukan_kata_sandi_confirmation"
                            value={inputState.bukan_kata_sandi_confirmation}
                            onChange={handleChange}
                            error={!!errorState.bukan_kata_sandi_confirmation}
                            helperText={errorState.bukan_kata_sandi_confirmation}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm password visibility"
                                            onClick={() => setVisible(prevState => ({ ...prevState, bukan_kata_sandi_confirmation: !prevState.bukan_kata_sandi_confirmation }))}
                                        >
                                            {visible.bukan_kata_sandi_confirmation ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UserForm;