import React, { useState } from 'react'

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable'
import AgentGroupAddModal from './AgentGroupAddModal'
import AgentGroupDetailModal from './AgentGroupDetailModal'
import { Button, Theme } from '@mui/material'

/**
 * Utils
*/
import { generateStyle } from '../../_utils/DefaultStyles'

/**
 * Icons
 */
import { Add } from '@mui/icons-material'


const AgentGroupList = () => {
    const { Root, classes} = useStyles()

    const [reDraw, setReDraw] = useState(0)

    const [isModalAddOpen, setIsModalAddOpen] = useState(false)
    const [selectedGroupId, setSelectedGroupId] = useState<null | number>(null)

    const columns = [
        {
            name: 'name',
            label: 'Name',
            type: 'string',
            filterable: true,
            render: (data: string, row: any) => {
                return (
                    <span onClick={() => setSelectedGroupId(row.id)} className={classes.link}>
                        {data}
                    </span>
                )
            }
        },
        {
            name: 'leader_name',
            label: 'Leader',
            type: 'string',
            filterable: true,
        },
        {
            name: 'members',
            label: 'Members',
            type: 'string',
            filterable: true,
        },
    ]

    return (
        <Root>
            <AgentGroupAddModal
                open={isModalAddOpen}
                onClose={() => setIsModalAddOpen(false)}
                onRefresh={() => setReDraw(prev => prev + 1)}
            />
            <AgentGroupDetailModal
                id={selectedGroupId}
                onClose={() => setSelectedGroupId(null)}
                onRefresh={() => setReDraw(prev => prev + 1)}
            />
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<Add />}
                onClick={() => setIsModalAddOpen(true)}
            >
                Add Group
            </Button>
            <DataTable
                url={`${process.env.REACT_APP_API_URL}/agent-group`}
                columns={columns}
                reDraw={reDraw}
            />
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.dark,
        },
    },
    addButton: {
        marginBottom: '20px'
    },
}), "AgentGroup_List");

export default AgentGroupList
