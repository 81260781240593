import { Dialog, DialogContent, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';

/**
 * Components
 */
import ChipInfo from './ChipInfo';
import ClusterTables, { IBreakdownBy, IClusterTableType } from './ClusterTables';

/**
 * Utils
*/
import { generateStyle } from '../../../_utils/DefaultStyles';
import { convertNumber } from '../../../_utils/Helper';
import { IFacebookAds } from '../DashboardAds';
import { calculateScore } from '../_utils/DashboardAdsHelper';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material';

type Props = {
    open: boolean
    onClose: () => void
    data: IFacebookAds | null
    customParams: Record<string, any>
}

const DashboardAdsModalDetail = (props: Props) => {
    const [state, setState] = useState<IFacebookAds | null>(null)
    const { Root, classes } = useStyles()
    const theme = useTheme()
    const [clusterTableType, setClusterTableType] = useState<IClusterTableType>("lead")
    const [breakdownBy, setBreakdownBy] = useState<IBreakdownBy>('none')
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const getCPLColor = (cpl: number, priceAverage: number) => {
        if (!cpl && !priceAverage) return 'warning'
        const standard = (priceAverage / 1000_000_000) * 20_000

        switch (true) {
            case cpl > standard:
                return 'danger'
            case cpl <= standard:
                return 'default'
            default: 
                return 'warning'
        }
    }

    useEffect(() => {
        if (props.open && props.data !== null) {
            setState(props.data)
        }

        if (!props.open) {
            setClusterTableType('lead')
            setBreakdownBy('none')
        }
    }, [props.open, props.data])

    useEffect(() => {
        setBreakdownBy('none')
    }, [clusterTableType])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="lg"
            fullWidth
            fullScreen={isMobile}
        >
            <Root>
                <DialogContent sx={isMobile ? { p: 2 } : {}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography fontWeight={'700'} variant='h6'>
                                {props.data?.name || ''}
                            </Typography>
                            {
                                clusterTableType === 'lead' && !isMobile ?
                                    <TextField
                                        select
                                        value={breakdownBy}
                                        label="Breakdown By"
                                        onChange={(e) => setBreakdownBy(e.target.value as IBreakdownBy)}
                                        size='small'
                                        sx={{ ml: 2, minWidth: 150 }}
                                    >
                                        <MenuItem value="none">None</MenuItem>
                                        <MenuItem value="source">Source</MenuItem>
                                        <MenuItem value="campaign">Campaign</MenuItem>
                                        <MenuItem value="adset">Adset</MenuItem>
                                        <MenuItem value="agent">Agent</MenuItem>
                                    </TextField>
                                    : null
                            }
                            <div style={{ display: 'flex', marginLeft: 'auto' }}>
                                <IconButton style={{ marginLeft: 'auto' }} size='small' onClick={props.onClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </Grid>
                        {
                            clusterTableType === 'lead' && isMobile ?
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        label="Breakdown By"
                                        value={breakdownBy}
                                        onChange={(e) => setBreakdownBy(e.target.value as IBreakdownBy)}
                                        size='small'
                                        sx={{ minWidth: 150 }}
                                    >
                                        <MenuItem value="none">None</MenuItem>
                                        <MenuItem value="source">Source</MenuItem>
                                        <MenuItem value="campaign">Campaign</MenuItem>
                                        <MenuItem value="adset">Adset</MenuItem>
                                    </TextField>
                                </Grid>
                                : null
                        }
                        <Grid item xs={12}>
                            <div className={classes.container}>
                                <div className={classes.chipContainer}>
                                    <div className={classes.chip}>
                                        <ChipInfo type="circle" status={calculateScore(state).scoreStatus} label={state?.client_score_average || '-'} />
                                        <span>Client Score</span>
                                    </div>
                                    <div className={classes.chip}>
                                        <ChipInfo type="circle" status={calculateScore(state).status} label={`${state?.leads_warm_and_hot_count} \r\n (${calculateScore(state).warmPercentage} %)`} />
                                        <span>Warm + Hot</span>
                                    </div>
                                    <div className={classes.chip}>
                                        <ChipInfo type="circle" status={getCPLColor(state?.cpl || 0, state?.cluster_price_average || 0)} label={convertNumber(String(state?.cpl || 0), 'Rp. ')} />
                                        <span>CPL</span>
                                    </div>
                                </div>
                                <div className={classes.chipContainer} style={{ marginLeft: 'auto' }}>
                                    <div className={classes.chip}>
                                        <ChipInfo
                                            type='polygon'
                                            active={clusterTableType === 'lead'}
                                            onClick={() => setClusterTableType('lead')} label={props.data?.leads_count || 0}
                                        />
                                        <span>Leads</span>
                                    </div>
                                    <div className={classes.chip}>
                                        <ChipInfo
                                            type='polygon'
                                            active={clusterTableType === 'activity'}
                                            onClick={() => setClusterTableType('activity')} label={props.data?.showing_count || 0}
                                        />
                                        <span>Showing</span>
                                    </div>
                                    <div className={classes.chip}>
                                        <ChipInfo
                                            type='polygon'
                                            active={clusterTableType === 'closing'}
                                            onClick={() => setClusterTableType('closing')} label={props.data?.closing_count || 0}
                                        />
                                        <span>Closing</span>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <ClusterTables
                                type={clusterTableType}
                                id={props.data?.id || 0}
                                breakdownBy={breakdownBy}
                                customParams={props.customParams}
                                disableFetch={!props.open}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Root>
        </Dialog>
    )
}

const useStyles = generateStyle((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            overflowX: 'scroll',
            alignItems: 'flex-start',
            gap: 20,
        }
    },
    chipContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
        }
    },
    chip: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 10,
        fontSize: 14,
        position: 'relative',
        '& > span': {
            textAlign: 'center',
        },
    }
}), 'DashboardAdsModal_Detail')

export default DashboardAdsModalDetail