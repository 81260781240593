import React, { useCallback, useEffect, useState } from 'react'

/**
 * Components
 */
import { Button, Grid, Paper } from '@mui/material'
import Swal from 'sweetalert2'
import LoadingScreen from '../LoadingScreen'
import TextEditor from './TextEditor'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../_utils/Helper'

interface NewArticleFormProps {
    id: string
    url: string
}

const NewArticleForm = (props: NewArticleFormProps) => {
    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState({
        main_article: '',
        facilities_article: '',
        location_article: '',
    })

    const handleEditorChanged = (name: string, value: string) => {
        setState(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    const fetchData = useCallback(() => {
        setIsLoading(true)
        
        DefaultAxios.get(props.url, {params: {id: props.id}})
            .then(res => res.data)
            .then(data => {
                setState(data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }, [props.url, props.id])

    useEffect(() => {
        if (props.url && props.id) fetchData()
    }, [props.url, props.id, fetchData])
    
    const submit = () => {
        setIsLoading(true)
            
        DefaultAxios.post(props.url, {
            ...state,
            id: props.id,
        })
            .then(res => res.data)
            .then(_ => {
                Swal.fire({
                    title: "Article berhasil disimpan",
                    icon: 'success',
                    timer: 1000
                })
                    .then(() => {
                        fetchData()
                    })
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }
    
    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={12}>
                <Paper style={{padding: 16, marginRight: 24}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h3>Artikel Utama</h3>
                            <TextEditor
                                name="main_article"
                                value={state.main_article}
                                onChange={handleEditorChanged}
                                imagesUpload
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <h3>Artikel Fasilitas</h3>
                            <TextEditor
                                name="facilities_article"
                                value={state.facilities_article}
                                onChange={handleEditorChanged}
                                imagesUpload
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <h3>Artikel Lokasi</h3>
                            <TextEditor
                                name="location_article"
                                value={state.location_article}
                                onChange={handleEditorChanged}
                                imagesUpload
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={submit}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </>
    )
}

export default NewArticleForm
