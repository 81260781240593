import { Switch } from "@mui/material";
import { styled } from "@mui/material";

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 32,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 16,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        color: '#d3d3d3',
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: theme.palette.primary.main,
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#fff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        width: 16,
        height: 16,
        borderRadius: 8,
        boxShadow: 'none',
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 20,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#FFFFFF',
        boxSizing: 'border-box',
    },
}));

export default AntSwitch