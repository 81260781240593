import { Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete'
import { Button } from '@mui/material'
import LoadingScreen from '../../../_components/LoadingScreen'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, renderSuccessButton } from '../../../_utils/Helper'

type Props = {
    open: boolean
    onClose: (refresh: boolean) => void
    id: string
    type: number
}

const initialState = {
    id: '',
    type: 0,
    agent_id: 0,
    agent_label: '',
    reason: ''
}

const ChangeAdvisorModal = (props: Props) => {
    const [isLoading, setIsloading] = useState(false)
    const [state, setState] = useState(initialState)

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            agent_id: value.id as number,
            agent_label: value.label
        }))
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    } 

    const handleChangeAutocomplete = () => {
        setState(prev => ({
            ...prev,
            agent_id: 0,
            agent_label: ''
        }))
    }

    const handleSubmit = () => {
        setIsloading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/change-closing-agent`, state)
            .then(res => res.data)
            .then(res => {
                renderSuccessButton('Berhasil Mengganti Agent')
                props.onClose(true)
            })
            .catch(generalErrorHandler)
            .finally(() => { setIsloading(false) })

    }

    useEffect(() => {
        if (props.open) {
            if (props.id) {
                setState(prev => ({
                    ...prev,
                    id: props.id,
                    type: props.type
                }))
            }
        } else {
            setState(initialState)
        }
        
    }, [props.open, props.id, props.type])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>
                Change Advisor
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/user/agent`}
                            onChange={handleAutocomplete}
                            onInputChange={handleChangeAutocomplete}
                            onClear={handleChangeAutocomplete}
                            clearable
                            label='Agent'
                            name='agent_id'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            name='reason'
                            label="Reason"
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            sx={{ float: 'right' }}
                            disabled={!state.agent_id || !state.reason}
                            variant='contained'
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>

                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ChangeAdvisorModal