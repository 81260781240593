import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import ForceAppVersion from './_components/ForceAppVersion';
import FormChangeSettingSlug from './_components/FormChangeSettingSlug';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generateStyle } from '../../_utils/DefaultStyles';

export interface ISimpleImageState {
    id: string;
    name: string;
    file: File | null;
    url: string;
}

export interface IAction {
    name: string,
    value: any,
    type: string
}

export const useStyles = generateStyle(theme => ({
    borderBottomLine: {
        borderBottom: '1px solid #eee',
        textTransform: 'capitalize'
    },
    divider: {
        margin: '50px 0'
    }
}), "Site_Setting")

export const API_URL = `${process.env.REACT_APP_API_URL}/site-setting`;

export interface SiteSettingState {
    [key: string]: {
        id: number
        value: number | string
    }
}

const SiteSetting = () => {

    const { Root, classes } = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState<SiteSettingState>({});

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        DefaultAxios
            .get(API_URL)
            .then(res => {
                setState(res.data);
            });
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, slug: string) => {
        const value = e.target.value

        setState(prev => ({
            ...prev,
            [slug]: {
                ...prev[slug],
                value,
            },
        }))
    }

    return (
        <Root>
            <Grid container spacing={3}>
                <LoadingScreen open={isLoading} fullScreen />
                <Grid item xs={12}>
                    <h1 className={classes.borderBottomLine}>Site Setting</h1>
                </Grid>

                {/* <Grid item xs={12}>
                <h3 className={classes.borderBottomLine}>Gunakan Sistem Leads Baru</h3>
            </Grid>

            <Grid item xs={12}>
                <NewLeadsSystem 
                    setIsLoading={setIsLoading}
                    state={!!state['is-new-lead-system']?.value}
                    loadData={loadData}
                />
            </Grid> */}

                <Grid item xs={12}>
                    <h3 className={classes.borderBottomLine}>Force App Version</h3>
                </Grid>

                <Grid item xs={12}>
                    <ForceAppVersion
                        value={state['force-app-version']?.value as string || ''}
                        handleChange={(e) => handleChange(e, 'force-app-version')}
                        loadData={loadData}
                        setIsLoading={setIsLoading}
                    />
                </Grid>

                <Grid item xs={12}>

                </Grid>

                <Grid item xs={4}>
                    <h3 className={classes.borderBottomLine}>Default Cluster Placement Limit Leads</h3>
                    <FormChangeSettingSlug
                        slug='cluster-placement-limit-leads'
                        value={state['cluster-placement-limit-leads']?.value as string || ''}
                        handleChange={(e) => handleChange(e, 'cluster-placement-limit-leads')}
                        loadData={loadData}
                        setIsLoading={setIsLoading}
                    />
                </Grid>

                <Grid item xs={4}>
                    <h3 className={classes.borderBottomLine}>Default Agent Limit Leads per Day</h3>
                    <FormChangeSettingSlug
                        slug='agent-limit-leads-per-day'
                        value={state['agent-limit-leads-per-day']?.value as string || ''}
                        handleChange={(e) => handleChange(e, 'agent-limit-leads-per-day')}
                        loadData={loadData}
                        setIsLoading={setIsLoading}
                    />
                </Grid>

                <Grid item xs={4}>
                    <h3 className={classes.borderBottomLine}>Default Additional Limit Leads per Day</h3>
                    <FormChangeSettingSlug
                        slug='limit-additional-leads-from-buffer'
                        value={state['limit-additional-leads-from-buffer']?.value as string || ''}
                        handleChange={(e) => handleChange(e, 'limit-additional-leads-from-buffer')}
                        loadData={loadData}
                        setIsLoading={setIsLoading}
                    />
                </Grid>
            </Grid>
        </Root>
    );
}

export default SiteSetting;