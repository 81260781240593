import React, { useState, useEffect, useContext } from 'react';
import { Button, Tooltip, IconButton, Switch } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import UserPlacementModal from "./_components/UserPlacementModal";
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';
import UserForm from './UserForm';
import UserLog from './_components/UserLog';

/**
 * Context
 */
import { PermissionContext } from "../../_contexts/PermissionContext";

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import helpers from '../../_utils/Helpers';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import PublishIcon from '@mui/icons-material/Publish';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import HistoryIcon from '@mui/icons-material/History';

/**
 * Data
 */
// import { agentRole } from '../../_utils/ConstData';
import { renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyles';

interface IProps {
    isApp?: boolean
}

export interface IOption {
    key: string;
    value: string;
}

const useStyles = generateStyle(theme => ({
    actionContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    addButton: {
        marginBottom: '20px'
    },
    importButton: {
        float: 'right'
    },
    errorContainer: {
        maxHeight: '200px',
        overflowY: 'auto'
    }
}), "User_List")

const UserList = ({ isApp }: IProps) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/user`;
    const permissions = useContext(PermissionContext);
    const { Root, classes } = useStyles();

    const [userPlacementValue, setUserPlacementValue] = useState({
        id: "",
        name: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalLogOpen, setModalLogOpen] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [roles, setRoles] = useState<IOption[]>([]);
    const [userId, setUserId] = useState('');
    const [logUserId, setLogUserId] = useState('');

    const columns = [
        {
            name: 'name',
            label: 'Name',
            type: 'string',
            filterable: true
        },
        {
            name: 'full_name',
            label: 'Full Name',
            type: 'string',
            filterable: true
        },
        {
            name: 'level',
            label: 'Level',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: 'junior',
                    value: 'Junior'
                },
                {
                    key: 'mid',
                    value: 'Mid'
                },
                {
                    key: 'senior',
                    value: 'Senior'
                },
            ],
            filterable: true,
            render: (data: any) => {
                return data ? data.charAt(0).toUpperCase() + data.slice(1) : '-';
            }
        },
        {
            name: 'commission_percentage',
            label: 'Persentase Komisi',
            type: 'string',
            filterable: true,
            render: (data: any) => {
                return data ? data : '-';
            }
        },
        {
            name: 'email',
            label: 'Email',
            type: 'string',
            filterable: true
        },
        {
            name: 'phone',
            label: 'Phone',
            type: 'string',
            filterable: true
        },
        {
            name: 'role_name',
            label: 'Role',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...roles
            ],
            filterable: true,
        },
        {
            name: 'is_active',
            label: 'Status',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Active'
                },
                {
                    key: '0',
                    value: 'In-Active'
                }
            ],
            filterable: true,
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return <Switch
                    checked={+data === 1}
                    onChange={() => switchActive(row.id)}
                    color="secondary"
                />
            }
        },
        // {
        //     name: 'is_paused',
        //     label: 'Paused',
        //     type: 'string',
        //     defaultOption: '',
        //     options: [
        //         {
        //             key: '',
        //             value: 'All'
        //         },
        //         {
        //             key: '1',
        //             value: 'Paused'
        //         },
        //         {
        //             key: '0',
        //             value: 'Not Paused'
        //         }
        //     ],
        //     filterable: true,
        //     render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
        //         return <Switch
        //             checked={+data === 1}
        //             onChange={() => switchPause(row.id)}
        //             color="secondary"
        //         />
        //     }
        // },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return (
                    <div className={classes.actionContainer}>
                        {permissions['user.edit'] &&
                            <Tooltip title="Edit">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => handleEdit(row.id)}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }

                        {permissions['user.reset-password'] &&
                            <Tooltip title="Reset Password">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => handleResetPassword(row.id)}
                                >
                                    <RotateLeftIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }

                        <Tooltip title="User Log">
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => handleLog(row.id)}
                            >
                                <HistoryIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }
        },
    ];

    useEffect(() => {
        DefaultAxios.get(`${API_URL}/role-list`)
            .then(res => {
                const options = [];

                for (let role of res.data) {
                    options.push({
                        key: role.id,
                        value: role.name
                    });
                }

                setRoles(options);
            })
        // eslint-disable-next-line
    }, []);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = (fetch: boolean) => {
        setUserId('');
        setModalOpen(false);
        if (fetch) {
            setReDrawDatatable(new Date().getTime());
        }
    }

    const handleModalLogOpen = () => setModalLogOpen(true);
    const handleModalLogClose = () => {
        setLogUserId('');
        setModalLogOpen(false);
    }

    const handleEdit = (id: string) => {
        setUserId(id);
        handleModalOpen();
    }

    const switchActive = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/toggle-active`, { id })
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil mengganti status",
                                icon: 'success',
                                onAfterClose: () => {
                                    setReDrawDatatable(reDrawDatatable + 1)
                                },
                                timer: 1000
                            })
                        })
                        .catch(error => {
                            helpers.generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    // const switchPause = (id: string) => {
    //     Swal.fire({
    //         title: 'Apakah Anda Yakin?',
    //         text: "Apakah anda yakin ingin mengganti status pause?",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Ya'
    //     }).then((result) => {
    //         if (result.value) {
    //             setIsLoading(true);
    //             DefaultAxios
    //                 .post(`${API_URL}/toggle-pause`, { id })
    //                 .then(() => {
    //                     Swal.fire({
    //                         title: "Berhasil mengganti status pause",
    //                         icon: 'success',
    //                         onAfterClose: () => {
    //                             setReDrawDatatable(new Date().getTime())
    //                         },
    //                         timer: 1000
    //                     })
    //                 })
    //                 .catch(error => {
    //                     helpers.generalErrorHandler(error)
    //                 })
    //                 .finally(() => {
    //                     setIsLoading(false);
    //                 })
    //         }
    //     })
    // }

    const handleImportFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            const fd = new FormData();
            fd.append('import_file', files[0]);

            setIsLoading(true);
            DefaultAxios
                .post(`${API_URL}/import`, fd)
                .then((res: { data: string[] }) => {
                    let title = 'Upload successful';
                    let message = '';
                    let icon: 'success' | 'warning' = 'success';

                    if (res.data.length) {
                        title = 'Upload with partiel error';
                        icon = 'warning';
                        message = '<ul class="' + classes.errorContainer + '">';
                        for (let m of res.data) {
                            message += `<li>${m}</li>`;
                        }
                        message += '</ul>';
                    }

                    Swal.fire({
                        title: title,
                        html: message,
                        icon: icon
                    });
                    setReDrawDatatable(new Date().getTime());
                })
                .catch(err => {
                    helpers.generalErrorHandler(err);
                })
                .finally(() => {
                    document.querySelectorAll('[type="file"]').forEach(el => {
                        (el as HTMLInputElement).value = '';
                    })
                    setIsLoading(false);
                });
        }
    }

    const handleResetPassword = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin me-reset password user ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/user/reset-password`, { id })
                        .then((res) => {
                            Swal.fire({
                                title: "Berhasil mereset password",
                                text: `Password Baru adalah: \n ${res.data}`,
                                icon: 'success',
                                timer: 10000
                            });
                        })
                        .catch(error => {
                            helpers.generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleLog = (id: string) => {
        setLogUserId(id);
        handleModalLogOpen();
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />

            <UserPlacementModal
                isOpen={userPlacementValue.id !== "" ? true : false}
                onClose={() => {
                    setReDrawDatatable(new Date().getTime());
                    setUserPlacementValue({
                        id: "",
                        name: ""
                    });
                }}
                user_name={userPlacementValue.name}
                user_id={userPlacementValue.id}
            />

            <UserForm
                open={modalOpen}
                onClose={handleModalClose}
                roles={roles}
                userId={userId}
            />

            <UserLog
                open={modalLogOpen}
                onClose={handleModalLogClose}
                userId={logUserId}
            />

            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={handleModalOpen}
            >
                Add New User
            </Button>
            <Button
                variant="contained"
                component="label"
                color="primary"
                size="small"
                className={classes.importButton}
                startIcon={<PublishIcon />}
            >
                Import
                <input
                    type="file"
                    style={{ display: "none" }}
                    accept=".xls,.xlsx"
                    onChange={handleImportFileChanged}
                />
            </Button>
            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
                isHeaderSticky={isApp}
                tableContainerMaxHeight={isApp ? 'calc(100vh - 180px)' : undefined}
            />
        </Root>
    );
}

export default UserList;