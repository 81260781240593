import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { Button, Dialog, DialogContent, Typography, DialogTitle, Grid, Paper, Chip, Theme, capitalize, TableContainer, Table, TableRow, TableCell, TableBody, TableHead } from '@mui/material'
import LoadingScreen from '../../_components/LoadingScreen'
import CompactFileList from '../../_components/CompactFileList'
import { VerifyTransactionStateDetail } from './VerifyTransactionList'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, renderSuccessButton } from '../../_utils/Helper'
import { numberToCurrency } from '../../_utils/Helpers'

/**
 * ICons
 */

import FindInPageIcon from '@mui/icons-material/FindInPage';
import CancelTransactionModal from './_components/CancelTransactionModal'
import Summary, { ISummary } from '../_closing/_components/Summary'
import { initialSummary } from '../_closing/_components/FormOrder'
import { IEscrowTransaction } from './VerifyTransactionList'
import { generateStyle } from '../../_utils/DefaultStyles'

interface TransactionDetailProps {
    permissions: any
    isOpen: boolean
    onClose: (refresh: boolean) => void
    id: number | null
    code: string
}

const TransactionDetails = (props: TransactionDetailProps) => {
    const {Root, classes} = useStyles()

    const [isLoading, setIsLoading] = useState(false)

    const [cancelModal, setCancelModal] = useState({
        modal: false,
        title: 'Reject'
    })
    const [isEdited, setIsEdited] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL + '/closing-transaction';
    const [state, setState] = useState<VerifyTransactionStateDetail>(initialState)
    const [summaryData, setSummaryData] = useState<ISummary>(initialSummary)

    const [files, setFiles] = useState<any[]>([])

    const fetchData = () => {
        setIsLoading(true)

        DefaultAxios.get(`${API_URL}/${props.id}`)
            .then(res => res.data)
            .then(data => {
                setState(data)
                setSummaryData(data.summary)
                setFiles(data.files)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const renderStatus = (status: string) => {
        return (
            <Chip
                variant='outlined'
                color='primary'
                label={capitalize(`${status}`)}
                sx={{ ml: 1 }}
            />
        )
    }

    const renderSubmitButton = (status: string) => {
        switch (status) {
            case 'proposed':
                if (!props.permissions['closing-transaction.approve']) return null
                return (
                    <Button
                        color="primary"
                        onClick={() => handelAction('approve')}
                        variant='contained'
                    >
                        Approve
                    </Button>
                )
            case 'approved':
                if (!props.permissions['closing-transaction.process']) return null
                return (
                    <Button
                        color="primary"
                        onClick={() => handelAction('process')}
                        variant='contained'
                    >
                        Process
                    </Button>
                )
            // case 'processed':
            //     if (!props.permissions['closing-transaction.release']) return null
            //     return (
            //         <Button
            //             color="primary"
            //             onClick={() => handleSubmit('release')}
            //             variant='contained'
            //         >
            //             Release
            //         </Button>
            //     )
            default:
                return null
        }
    }

    const renderCancelButton = (status: string) => {
        let title = 'Cancel'
        const buttonWithReason = (title: string) => (
            <Button
                color="secondary"
                onClick={() => setCancelModal({
                    modal: true,
                    title: `${title}`
                })}
                variant='contained'
            >
                {title}
            </Button>
        )

        switch (status) {
            case 'proposed':
                if (!props.permissions['closing-transaction.approve']) return null
                title = 'Reject'
                return buttonWithReason(title)
            case 'approved':
                if (!props.permissions['closing-transaction.process']) return null
                title = 'Reject'
                return buttonWithReason(title)
            case 'processed':
                if (!props.permissions['closing-transaction.export']) return null
                return buttonWithReason(title)
            // case 'released':
            //     if (!props.permissions['closing-transaction.cancel']) return null
            //     return buttonWithReason(title)
            default:
                return null
        }
    }

    const renderRevertButton = (status: string) => {
        const button = (
            <Button
                color="secondary"
                onClick={() => console.log('revert')}
                variant='contained'
            >
                Revert
            </Button>
        )

        switch (status) {
            case 'exported':
                if (!props.permissions['closing-transaction.revert-processed']) return null
                return button
            default:
                return null;
        }
    }

    const handleCancel = (reason: string) => {
        handelAction('cancel', reason)
    }

    const handelAction = (action: string, reason?: string) => {
        setIsLoading(true)

        setIsEdited(true)
        DefaultAxios.post(`${API_URL}/${action}`, action === 'cancel' ? { reason, id: props.id } : { id: props.id })
            .then((res) => res.data)
            .then(res => {
                if (action === 'cancel') {
                    renderSuccessButton(`${cancelModal.title} Success!`)
                } else {
                    renderSuccessButton(`${capitalize(action)} Success!`)
                }
                fetchData()
                setCancelModal({
                    modal: false,
                    title: ''
                })
            })
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    const renderContent = useMemo(() => {
        if (state.escrow_transactions.length) {

            const sortedTransaction: { [bank_number: string]: IEscrowTransaction } = {};
            state.escrow_transactions.forEach((transaction) => {
                const bankNumber = transaction.bank_number;
                if (sortedTransaction[bankNumber]) {
                    sortedTransaction[bankNumber].amount += transaction.amount;
                } else {
                    sortedTransaction[bankNumber] = { ...transaction }
                }
            });

            return Object.values(sortedTransaction).map((transaction) => {
                return (
                    <TableRow key={transaction.id}>
                        {/* <TableCell>{transaction.code}</TableCell> */}
                        <TableCell>{transaction.bank_type_label}</TableCell>
                        <TableCell>{transaction.bank_number}</TableCell>
                        <TableCell>{transaction.bank_behalf_name}</TableCell>
                        <TableCell>Rp. {numberToCurrency(transaction.amount)}</TableCell>
                    </TableRow>
                )
            })
        } else {
            return (
                <TableRow>
                    <TableCell colSpan={5}>Tidak ada data transaksi.</TableCell>
                </TableRow>
            )
        }
    }, [state.escrow_transactions])

    const renderTotal = useMemo(() => {
        if (state.escrow_transactions && state.escrow_transactions.length) {
            let total = state.escrow_transactions.reduce((total, item) => total + item.amount, 0)
            return (
                <TableRow>
                    <TableCell colSpan={3} style={{ textAlign: 'right' }}>
                        <strong>
                            Total
                        </strong>
                    </TableCell>
                    <TableCell>
                        {numberToCurrency(total, 'Rp. ')}
                    </TableCell>
                </TableRow>
            )
        }
    }, [state.escrow_transactions])

    const footer = useMemo(() => {
        if (files.length) {
            return (
                <CompactFileList
                    files={files}
                // onUploadFile={handleUploadFile}
                // onDeleteFile={() => loadFooter()}
                />
            )
        } else {
            return (
                <div className={classes.empty}>
                    <FindInPageIcon fontSize={'large'} />
                    <Typography>Tidak ada File</Typography>
                </div>
            )
        }

        // eslint-disable-next-line
    }, [props.permissions, files])

    useEffect(() => {
        if (props.isOpen && props.id) {
            fetchData()
        }

        // eslint-disable-next-line
    }, [props.isOpen])

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />

            <CancelTransactionModal
                open={cancelModal.modal}
                title={`${cancelModal.title} Reason`}
                onClose={() => setCancelModal({
                    modal: false,
                    title: ''
                })}
                onSubmit={handleCancel}
            />

            <Dialog
                open={props.isOpen}
                onClose={() => props.onClose(isEdited)}
                fullWidth
                maxWidth="lg"
            >
                <Root>
                <DialogTitle>
                    <div className={classes.titleContainer}>
                        {props.code}
                        {renderStatus(state.escrow_status)}

                        <div className={classes.buttonActionContainer}>
                            {renderSubmitButton(state.escrow_status)}
                            {renderCancelButton(state.escrow_status)}
                            {renderRevertButton(state.escrow_status)}
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Summary
                                        summaryData={summaryData}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant='h6' sx={{ mb: 1 }}>Summary</Typography>
                                    <TableContainer component={Paper}>
                                        <Table id="copyTable" aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    {/* <TableCell>Kode</TableCell> */}
                                                    <TableCell style={{ minWidth: '80px', maxWidth: '100px' }}>Nama Bank</TableCell>
                                                    <TableCell style={{ minWidth: '100px' }}>No. Rekening</TableCell>
                                                    <TableCell style={{ minWidth: '120px' }}>Atas Nama</TableCell>
                                                    <TableCell style={{ minWidth: '150px' }}>Nominal</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {renderContent}
                                                {renderTotal}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Typography variant='h6' sx={{ mt: 1, mb: 1 }}>Files</Typography>
                                    <Paper className={classes.paper}>
                                        <div className={classes.footer}>
                                            {footer}
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                </Root>
            </Dialog>
        </>
    )
}

export default TransactionDetails

const useStyles = generateStyle((theme: Theme) => ({
        code: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            transition: '150ms color linear',

            '&:hover': {
                color: theme.palette.primary.dark,
            }
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        headerButtons: {
            '& > button:last-child': {
                marginLeft: 12,
            }
        },
        dialogContent: {
            display: 'flex',
            flexDirection: 'column',
        },
        label: {
            fontWeight: 'bold',
            marginBottom: 8,
            fontSize: 16,
        },
        value: {
            fontSize: 16,
        },
        itemContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        buttonContainer: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginBottom: 16,
        },
        paper: {
            padding: theme.spacing(2),
            paddingTop: 0,
            overflow: 'hidden',
        },
        footer: {
            display: 'flex',
            // minHeight: '250px',
            maxHeight: '400px',
            // backgroundColor: '#eee',
            padding: '10px',
            overflow: 'auto',
        },
        titleContainer: {
            display: 'flex'
        },
        buttonActionContainer: {
            marginLeft: 'auto',
            display: 'flex',
            gap: '10px'
        },
        empty: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            height: '100%'
        }
    }), "Transaction_Detail"
);

const initialState: VerifyTransactionStateDetail = {
    closing_id: 0,
    escrow_status: '',
    escrow_transactions: [],
    files: [],
    id: 0,
    status: 0
}