import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import TextEditor from '../../TextEditor'
import LoadingScreen from '../../../LoadingScreen'
import { Box, Fab, Theme, Tooltip } from '@mui/material'
import { Grid, TextField, Button, IconButton } from '@mui/material'

/**
 * Utils
 */
import DefaultAxios from '../../../../_utils/DefaultAxios'
import { useTheme } from '@mui/material'
import { generateStyle } from '../../../../_utils/DefaultStyles'
import { generalErrorHandler, renderErrorButton, renderSuccessButton, renderWarningButton } from '../../../../_utils/Helper'

/**
 * Icons
 */
import { ArrowDownward, ArrowUpward, Cancel, GetApp, Publish, Save, Visibility } from '@mui/icons-material'

interface V1FormProps {
    id: number
    type: string
    slug: string
    setParentHasChanged: (value: boolean) => void
}

interface IState {
    slp_points: string[]
    slp_background_image: File | null
    slp_icon_image: File | null
    slp_background_image_url: string | null
    slp_icon_image_url: string | null
    slp_wa_pretext: string
    nickname: string
}

const V1Form = (props: V1FormProps) => {
    const url = `${process.env.REACT_APP_API_URL}/slp`;
    const [changed, setChanged] = useState(false)
    const [slug, setSlug] = useState('')
    const { Root, classes } = useStyles()
    const theme = useTheme()

    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState<IState>(initialState)
    const [errorState, setErrorState] = useState<Partial<{ [key: string]: string }>>({})

    useEffect(() => {
        props.setParentHasChanged(changed)
        // eslint-disable-next-line
    }, [changed])

    const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
        setErrorState(prev => ({
            ...prev,
            [e.target.name]: ''
        }))
        setChanged(true)
    }

    const handleChangedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        if (['slp_background_image', 'slp_icon_image'].includes(name)) {
            if (target && target.files) {
                value = target.files[0];
            } else {
                value = null;
            }
        }

        setState(prev => ({
            ...prev,
            [name]: value
        }));
    }

    const fetchData = () => {
        setIsLoading(true)

        DefaultAxios.get(url, {
            params: {
                data_id: props.id,
                type: props.type,
                version: '1'
            }
        })
            .then(res => res.data)
            .then((data: any) => {
                if (data) {
                    setState(prev => ({
                        ...prev,
                        ...data
                    }))
                    setSlug(data.slug)
                    setChanged(false)
                }
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setErrorState({})
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (url) {
            fetchData()
        }

        // eslint-disable-next-line
    }, [url])

    const handleChange = (value: string, name: string, index: number) => {

        const point = state.slp_points.slice();
        point[index] = value;

        setState(prev => ({
            ...prev,
            slp_points: prev.slp_points.map((val, idx) => index === idx ? value : val)
        }))

        setChanged(true)
    }

    const addPoint = () => {

        setState(prev => ({
            ...prev,
            slp_points: prev.slp_points ? [...prev.slp_points, ''] : ['']
        }))

        setChanged(true)
    }

    const deletePoint = (index: number) => {
        const lists = state.slp_points.slice();
        lists.splice(index, 1);

        setState(prev => ({
            ...prev,
            slp_points: lists
        }))

        setChanged(true)
    }

    const submit = () => {
        const fd = new FormData();
        fd.append('data_id', props.id.toString())
        fd.append('type', props.type || '')
        fd.append('version', '1')

        Object.entries(state).forEach(([name, value]) => {
            if (typeof value === 'object' && value instanceof Array) {
                value.forEach((point, idx) => {
                    fd.append(`${name}[${idx}]`, point || '')
                })
            } else {
                fd.append(name, value || '')
            }

        })

        setIsLoading(true);

        DefaultAxios.post(url, fd)
            .then(res => {
                renderSuccessButton('Submit data berhasil!')
                    .then((_) => { fetchData() })
            })
            .catch(err => {
                const errData = err?.response?.data
                if (typeof errData?.error === 'string' && errData?.error?.includes('S2')) {
                    return renderErrorButton('WA Pretext wajib ada tulisan -S2')
                }
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleDeleteFile = (name: string) => {
        renderWarningButton('Apakah anda yakin ingin menghapus image ini?')
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)

                    DefaultAxios.post(`${url}/delete-image`, {
                        data_id: props.id,
                        type: props.type,
                        version: '1',
                        image_type: name
                    })
                        .then(res => {
                            renderSuccessButton('Berhasil menghapus image!')
                                .then(() => { fetchData() })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            })
    }

    const handleSort = (index: number, dir: 'up' | 'down' | 'top' | 'bottom') => {
        const points = [...state.slp_points]
        const length = state.slp_points.length
        let toIndex = -1

        switch (dir) {
            case 'up':
                toIndex = index - 1
                break
            case 'down':
                toIndex = index + 1
                break
            case 'top':
                toIndex = 0
                break
            case 'bottom':
                toIndex = length - 1
                break
        }

        const element = points[index];
        points.splice(index, 1);
        points.splice(toIndex, 0, element);

        setState(prev => ({
            ...prev,
            slp_points: points
        }))
    }

    const sxStyles = (theme: Theme) => ({
        warning: {
            position: 'fixed',
            top: '12px',
            left: '8px',
            right: '8px',
            transform: `translateY(${changed ? '0%' : 'calc(-100% - 12px)'})`,
            zIndex: 1300,
            backgroundColor: '#ffb723',
            margin: 0,
            color: 'white',
            padding: '8px 12px !important',
            borderRadius: '8px',
            transition: 'all 0.2s linear',
            [theme.breakpoints.up('sm')]: {
                left: '50%',
                right: 'unset',
                marginLeft: '135px',
                marginRight: 0,
                transform: `translateX(-50%)translateY(${changed ? '0%' : 'calc(-100% - 12px)'})`,
            }
        }
    })

    const renderForm = () => {
        return (
            <>
                {
                    changed ?
                        <Box component="div" sx={sxStyles(theme).warning as React.CSSProperties}>
                            You have unsaved changes, don't forget to submit the data!
                        </Box> :
                        null
                }
                <h3 style={{ padding: 0, margin: 0 }}>SLP Points</h3>
                {
                    state.slp_points ?
                        state.slp_points.map((value, index) =>
                            <Grid container key={`${index}`} spacing={3} alignItems="center">
                                <Grid item xs={12} sm={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                            <div>
                                                {`Point ${index + 1}`}
                                            </div>
                                        </Grid>
                                        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleSort(index, 'top')}
                                                disabled={index === 0}
                                            >
                                                <Publish />
                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleSort(index, 'up')}
                                                disabled={index === 0}
                                            >
                                                <ArrowUpward />
                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleSort(index, 'down')}
                                                disabled={index === state.slp_points.length - 1}
                                            >
                                                <ArrowDownward />
                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleSort(index, 'bottom')}
                                                disabled={index === state.slp_points.length - 1}
                                            >
                                                <GetApp />
                                            </IconButton>
                                            <IconButton
                                                color="secondary"
                                                // style={{ marginLeft: 'auto', marginBottom: 'auto' }}
                                                onClick={() => deletePoint(index)}
                                            >
                                                <Cancel fontSize="large" />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextEditor
                                                name="slp_points"
                                                value={value}
                                                onChange={(name: string, value: string) => handleChange(value, name, index)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) :
                        null
                }
            </>
        )
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={12} marginBottom={2}>
                {/* <h3 style={{ padding: 0, margin: 0, marginBottom: 12 }}>Whatsapp Pretext</h3> */}
                <TextField
                    label="Nickname"
                    name="nickname"
                    value={state.nickname || ''}
                    error={!!errorState.nickname}
                    helperText={errorState.nickname}
                    variant="outlined"
                    fullWidth
                    onChange={handleChangeValue}
                />
            </Grid>
            <Grid item xs={12} marginBottom={2}>
                {/* <h3 style={{ padding: 0, margin: 0, marginBottom: 12 }}>Whatsapp Pretext</h3> */}
                <TextField
                    label="Whatsapp Pretext"
                    name="slp_wa_pretext"
                    value={state.slp_wa_pretext || ''}
                    error={!!errorState.slp_wa_pretext}
                    helperText={errorState.slp_wa_pretext}
                    variant="outlined"
                    fullWidth
                    onChange={handleChangeValue}
                />
            </Grid>
            <div className={classes.listContainer}>
                {renderForm()}
            </div>
            <Grid
                container
                spacing={3}
                alignItems="center"
            >
                <Grid
                    item
                    xs={12}
                >
                    <div className={classes.flexCenter}>
                        <Button
                            variant="contained"
                            onClick={addPoint}

                        >
                            Add Point
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container style={{ alignItems: 'center' }}>
                    <Grid item xs={6}>
                        <div className={classes.imageContainer}>
                            <div style={{ marginBottom: 15 }}>
                                <h3 style={{ marginBottom: 5 }}>Icon</h3>
                                <span>(Recommended Size: 300x300)px</span>
                            </div>
                            {
                                state.slp_icon_image_url ?
                                    <>
                                        <img src={state.slp_icon_image_url} style={{ width: "100%" }} alt="icon" />
                                        <br></br>
                                    </>
                                    : null
                            }
                            <input type="file" name="slp_icon_image" onChange={handleChangedFile} style={{ marginBottom: '10px' }} />
                        </div>
                    </Grid>
                    {
                        !!state.slp_icon_image_url &&
                        <Grid item xs={4}>
                            <IconButton
                                color="secondary"
                                style={{ marginLeft: 12, marginBottom: 'auto' }}
                                onClick={() => handleDeleteFile('slp_icon_image')}
                            >
                                <Cancel fontSize="large" />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container style={{ alignItems: 'center' }}>
                    <Grid item xs={6}>
                        <div className={classes.imageContainer}>
                            <div style={{ marginBottom: 15 }}>
                                <h3 style={{ marginBottom: 5 }}>Background Image</h3>
                                <span>(Recommended Size: 600x400px)</span>
                            </div>
                            {
                                state.slp_background_image_url ?
                                    <>
                                        <img src={state.slp_background_image_url} style={{ width: "100%" }} alt="background" />
                                        <br></br>
                                    </>
                                    : null
                            }
                            <input type="file" name="slp_background_image" onChange={handleChangedFile} style={{ marginBottom: '10px' }} />
                        </div>
                    </Grid>
                    {
                        !!state.slp_background_image_url &&
                        <Grid item xs={4}>
                            <IconButton
                                color="secondary"
                                style={{ marginLeft: 12, marginBottom: 'auto' }}
                                onClick={() => handleDeleteFile('slp_background_image')}
                            >
                                <Cancel fontSize="large" />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid
                    item
                    xs={12}
                >
                    <div className={classes.submitContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={submit}
                        >
                            Submit
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <div className={classes.fab}>
                <Tooltip title="Preview" className={classes.fabSpacing} placement="top" arrow>
                    <Fab aria-label="preview" color="primary" href={`${process.env.REACT_APP_MAIN_URL}/short/${slug}`} target="_blank">
                        <Visibility />
                    </Fab>
                </Tooltip>

                <Tooltip title="Save" className={classes.fabSpacing} placement="top" arrow>
                    <Fab aria-label="Save" color="primary" onClick={submit}>
                        <Save />
                    </Fab>
                </Tooltip>
            </div>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    fab: {
        position: 'fixed',
        bottom: '2rem',
        right: '2rem',
        display: 'none',
        zIndex: 1,
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    fabSpacing: {
        marginRight: '1rem',
    },
    title: {
        fontSize: 16,
        color: '#484848',
        marginBottom: 12,
        fontWeight: 'bold',
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > .error': {
            color: theme.palette.error.main,
            marginTop: 12,
        }
    },
    line: {
        display: 'block',
        width: '100%',
        height: 1,
        backgroundColor: '#cacaca',
        margin: '16px 0px 16px',
    },
    metaContainer: {
        '& .title': {
            fontSize: 16,
            fontWeight: '500',
        },
        '& .subtitle': {
            fontWeight: '500',
        }
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    submitContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: 22
    },
    listContainer: {
        width: '100%',
        '& > *': {
            paddingBottom: 16,
            paddingTop: 16,
        },
        '& > *:first-of-type': {
            paddingTop: 0,
        },
    },
    switchContainer: {
        display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 22
    },
}), 'SLP_V1_Form')

const initialState = {
    slp_points: [],
    slp_background_image: null,
    slp_icon_image: null,
    slp_background_image_url: null,
    slp_icon_image_url: null,
    slp_wa_pretext: '',
    nickname: '',
}

export default V1Form
