import React, { useState } from 'react';
import { Grid, Theme, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import DateRangePicker, { DateRange } from '../../_components/_form/DateRangePicker';

// Utils
import DataTable from '../../_components/_dataTable/DataTable';

const formatDate = (date: DateRange) => {
    if (!date) return ''

    return moment(date).format('DD/MM/YYYY')
}

const ClusterCounterReport = ({is_new} : { is_new?: boolean}) => {
    const theme = useTheme<Theme>()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const initialDateFilter = {
        date_start: moment().subtract(1, 'weeks'),
        date_end: moment()
    };

    const columns = [
        {
            name: 'name',
            label: 'Cluster Name',
            type: 'string',
            filterable: true,
            sortable: false
        },
        {
            name: 'counter',
            label: 'Counter',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (data: any) => {
                return data || 0;
            }
        },
    ];

    const footerColumns = [
        {
            name: 'name',
            render: () => {
                return 'Total';
            }
        },
        {
            name: 'counter',
            render: (data: any[]) => {
                return data.reduce((a, b) => {
                    return a + (b || 0);
                }, 0);
            }
        }
    ];

    const [dateFilter, setDateFilter] = useState<{ date_start: DateRange, date_end: DateRange }>(initialDateFilter);
    const [customParams, setCustomParams] = useState({
        date: `${formatDate(dateFilter.date_start)} - ${formatDate(dateFilter.date_end)}`
    });

    const handleDateChanged = (dates: { startDate: DateRange, endDate: DateRange }) => {
        let newFilter = {
            date_start: dates.startDate,
            date_end: dates.endDate
        }
        setDateFilter(newFilter);
    }

    return (
        <Grid container spacing={3}>
            {
                !isMobile &&
                <Grid item xs={12} md={6} />
            }
            <Grid item xs={12} md={6}>
                <DateRangePicker
                    startDate={dateFilter.date_start}
                    endDate={dateFilter.date_end}
                    onDatesChange={handleDateChanged}
                    onFinished={() => setCustomParams({
                        date: `${formatDate(dateFilter.date_start)} - ${formatDate(dateFilter.date_end)}`
                    })}
                    fullWidth
                />

            </Grid>

            <Grid item xs={12}>
                <DataTable 
                    url={`${process.env.REACT_APP_API_URL}/cluster-lead-counter-report${is_new ? '/new' : ''}`}
                    columns={columns}
                    customParams={customParams}
                    footerColumns={footerColumns}
                />
            </Grid>
        </Grid>
    );
}

export default ClusterCounterReport;