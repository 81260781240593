import React from "react";
import { Card, CardContent } from '@mui/material';

import loadingClass from "../../Assets/CSS/loading.module.css";
import { styled } from "@mui/material";

const SCard = styled(Card)({
  paddingBottom: '0.5rem',
  borderRadius: '0.5rem',
  marginBottom: '1rem',
  boxShadow: '12px 10px 30px #d9d9d9',
  width: '100%'
})

const SCardContent = styled(CardContent)({
  paddingBottom: '0.5rem !important',
  position: 'relative',
  paddingTop: '0.5rem !important'
})

const SDate = styled('div')({
  width: 85,
  height: 14,
  marginBottom: '0.3rem',
  marginTop: '0.2rem'
})

const STop = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.6rem',
  '& .name': {
    margin: 0,
    width: '100%',
    height: 20
  }
})

const SType = styled('div')({
  display: 'flex',
  marginBottom: '0.3rem',
  '& .type-sold': {
    width: 50,
    height: 20
  },
  '& .type-property': {
    width: 80,
    height: 20,
    marginLeft: '0.5rem'
  }
})

const SMiddle = styled('div')({
  '& .address': {
    height: 12,
    width: '70%',
    marginBottom: '0.6rem'
  }
})

const SBottom = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  '& .price-container': {
    '& label': {
      color: '#0000004D',
      fontSize: '11px',
      fontWeight: 700
    },
    '& .price': {
      height: 24,
      width: 140
    }
  },
})

const LoadingCard = () => {

  return (
    <SCard elevation={2}>
      <SCardContent>
        <SDate className={`${loadingClass.loading}`}></SDate>
        <STop>
          <div className={`${loadingClass.loading} name`}></div>
        </STop>
        <SType>
          <div className={`${loadingClass.loading} type-sold`}></div>
          <div className={`${loadingClass.loading} type-property`}></div>
        </SType>
        <SMiddle>
          <div className={`${loadingClass.loading} address`}></div>
        </SMiddle>
        <SBottom>
          <div className="price-container">
            <div className={`${loadingClass.loading} price`}></div>
          </div>
        </SBottom>
      </SCardContent>
    </SCard>
  )
}

export default LoadingCard