import React, { useContext, useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { FormControlLabel, Paper, Typography } from '@mui/material'
import DataTable from '../../../_components/_dataTable/DataTable'
import AntSwitch from './AntSwitch'
import WhatsappPreviewModal, { ChatsData, INITIAL_CHATS_DATA } from '../../_lead/_components/WhatsappPreviewModal'
import { activityTypes } from '../../_activity/ActivityList'
import { IconButton } from '@mui/material'
import { Chip } from '@mui/material'
import { capitalize } from '@mui/material'
import LoadingScreen from '../../../_components/LoadingScreen'
import AdsMainTable from './AdsMainTable'

/**
 * Utils
 */
import { generateStyle } from '../../../_utils/DefaultStyles'
import helpers, { numberToCurrency } from '../../../_utils/Helpers'
import { PermissionContext } from '../../../_contexts/PermissionContext'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../../_utils/Helper'
import { leadMts, leadSources } from '../../../_utils/ConstData'

/**
 * Icons
 */
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export type IClusterTableType = "lead" | "activity" | "closing"
export type IBreakdownBy = "none" | "campaign" | "adset" | "source" | "agent"

type Props = {
    type: IClusterTableType
    breakdownBy: IBreakdownBy
    customParams: Record<string, any>
    disableFetch?: boolean
    id?: number
}

const ClusterTables = (props: Props) => {
    const { Root, classes } = useStyles()
    const [moreDetail, setMoreDetail] = useState(false)
    const permissions = useContext(PermissionContext)
    const [dataType, setDataType] = useState<IClusterTableType>("lead")
    const [isLoading, setIsLoading] = useState(false)
    const [chatsData, setChatsData] = useState<ChatsData>(INITIAL_CHATS_DATA)

    const handleWhatsappPreview = (id: number) => {
        setIsLoading(true)

        let params = window.location.search

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/wa-chats/lead${params || ''}`, {
            params: {
                id,
                restrict_date_ads: props.customParams['restrict_date_ads']
            }
        })
            .then(res => res.data)
            .then(data => {
                setChatsData({
                    chats: data.data,
                    code: data.code,
                    created_at: data.created_at,
                    next_id: data.next_id,
                    prev_id: data.prev_id,
                })
            })
            .catch(err => generalErrorHandler(err))
            .finally(() => {
                setIsLoading(false)
            })
    }

    const leadColumn = [
        {
            name: 'created_at',
            label: 'Date',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return helpers.dateFormat(data, 'DD MMM YYYY HH:mm:ss');
            }
        }, {
            name: 'client_name',
            label: 'Nama Client',
            type: 'string',
            filterable: true,
        }, ...(
            permissions['whatsapp.manage'] ?
                [{
                    name: 'wa_id',
                    label: 'WA Preview',
                    type: 'string',
                    filterable: true,
                    options: [
                        {
                            key: '',
                            value: 'All',
                        },
                        {
                            key: 1,
                            value: 'Yes',
                        },
                        {
                            key: 0,
                            value: 'No',
                        },
                    ],
                    render: (data: any, row: any) => {
                        if (data) {
                            return (
                                <IconButton aria-label="preview chat" title='Preview Chat' onClick={() => handleWhatsappPreview(row.id)} color='primary'>
                                    <WhatsAppIcon />
                                </IconButton>
                            )

                        } else {
                            return '-'
                        }
                    }
                }]
                : []
        ), {
            name: 'agent_name',
            label: 'Agent',
            type: 'string',
            filterable: true
        },
        {
            name: 'utm_source',
            label: 'Source',
            type: 'string',
            filterable: true
        },
        {
            name: 'utm_campaign',
            label: 'Campaign',
            type: 'string',
            filterable: true
        },
        {
            name: 'adsetname',
            label: 'Adset',
            type: 'string',
            filterable: true
        },
        {
            name: 'adgroupid',
            label: 'Ad',
            type: 'string',
            filterable: true
        },
        ...(
            moreDetail ? [
                {
                    name: 'code',
                    label: 'Kode',
                    type: 'string',
                    filterable: true,
                    render: (data: any, row: any) => {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span>
                                    {data}

                                </span>
                            </div>
                        )
                    }
                },
                {
                    name: 'source_label',
                    label: 'Source',
                    type: 'string',
                    defaultOption: '',
                    options: [
                        { key: '', value: 'All' },
                        ...leadSources
                    ],
                    filterable: true,
                    sortable: false,
                },
                {
                    name: 'status_label',
                    label: 'Status',
                    type: 'string',
                    defaultOption: '',
                    options: [
                        {
                            key: '',
                            value: 'All',
                        },
                        {
                            key: 'undefined',
                            value: 'New',
                        },
                        {
                            key: 'no_response',
                            value: 'No Response',
                        },
                        {
                            key: 'warm',
                            value: 'Warm',
                        },
                        {
                            key: 'hot',
                            value: 'Hot',
                        },
                        {
                            key: 'booking_fee',
                            value: 'Booking Fee',
                        },
                        {
                            key: 'closing',
                            value: 'Closing',
                        },
                        {
                            key: 'cold',
                            value: 'Cold',
                        },
                        {
                            key: 'excluded',
                            value: 'Excluded',
                        },
                    ],
                    filterable: true,
                    render: (data: any, row: any) => {
                        let style = { color: '#484848', fontWeight: '400' }
                        switch (row.status) {
                            case 'warm':
                                style = { color: 'orange', fontWeight: '700' }
                                break;
                            case 'hot':
                                style = { color: 'red', fontWeight: '700' }
                                break;
                            default:
                                style = { color: '#484848', fontWeight: '400' }

                        }
                        return (
                            <span style={style}>
                                {data === 'undefined' ? (row.status === 'showing' ? 'Showing' : 'New') : data}
                            </span>
                        )
                    }
                },
                {
                    name: 'objective_score_agent',
                    label: 'Objective Score Agent',
                    type: 'string',
                    defaultOption: '',
                    options: [
                        {
                            key: '',
                            value: 'All',
                        },
                        {
                            key: 0,
                            value: 'Bad  (<60)',
                        },
                        {
                            key: 1,
                            value: 'OK  (60-69)',
                        },
                        {
                            key: 2,
                            value: 'Good  (>=70)',
                        },
                    ],
                    filterable: true,
                    render: (data: any) => {
                        if (data === null) return '-'

                        let color: "primary" | "success" | "warning" | "error" = "primary";

                        if (data >= 70) {
                            color = "success";
                        } else if (data >= 60) {
                            color = "warning";
                        } else {
                            color = "error";
                        }

                        return <Chip
                            color={color}
                            label={data}
                        />;
                    }
                },
                {
                    name: 'objective_score',
                    label: 'Objective Score Client',
                    type: 'string',
                    defaultOption: '',
                    options: [
                        {
                            key: '',
                            value: 'All',
                        },
                        {
                            key: 0,
                            value: 'Bad  (<60)',
                        },
                        {
                            key: 1,
                            value: 'OK  (60-69)',
                        },
                        {
                            key: 2,
                            value: 'Good  (>=70)',
                        },
                    ],
                    filterable: true,
                    render: (data: any) => {
                        if (data === null) return '-'

                        let color: "primary" | "success" | "warning" | "error" = "primary";

                        if (data >= 70) {
                            color = "success";
                        } else if (data >= 60) {
                            color = "warning";
                        } else {
                            color = "error";
                        }

                        return <Chip
                            color={color}
                            label={data}
                        />;
                    }
                },
                {
                    name: 'client_phone',
                    label: 'No Hp',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'cluster_level',
                    label: 'Level Cluster',
                    type: 'string',
                    defaultOption: '',
                    options: [
                        {
                            key: '',
                            value: 'All'
                        },
                        {
                            key: 'none',
                            value: 'None'
                        },
                        {
                            key: 'easy',
                            value: 'Easy'
                        },
                        {
                            key: 'medium',
                            value: 'Medium'
                        },
                        {
                            key: 'hard',
                            value: 'Hard'
                        },
                    ],
                    filterable: true,
                    render: (data: any) => {
                        return data ? data.charAt(0).toUpperCase() + data.slice(1) : 'None';
                    }
                },
                {
                    name: 'cluster_group_name',
                    label: 'Cluster Group',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'cluster_name',
                    label: 'Cluster',
                    type: 'string',
                    filterable: true,
                    render: (data: any, row: any) => {
                        return row.cluster_id ? data : row.other_cluster_name
                    }
                },
                {
                    name: 'mts_label',
                    label: 'Manual Tracking Source',
                    type: 'string',
                    defaultOption: '',
                    options: [
                        { key: '', value: 'All' },
                        ...leadMts,
                    ],
                    filterable: true,
                    sortable: false,
                    render: (data: string, row: any) => {
                        return data === 'undefined' ? '' : data;
                    }
                },
                {
                    name: 'page_source',
                    label: 'Page Source',
                    type: 'string',
                    defaultOption: '',
                    options: [
                        { key: '', value: 'All' },
                        { key: '1', value: 'Short' },
                        { key: '2', value: 'Custom Page' },
                        { key: '3', value: 'External (EMD)' },
                    ],
                    filterable: true,
                    sortable: false,
                    render: (data: any, row: any) => {
                        switch (data) {
                            case 0:
                                return "Normal"
                            case 1:
                                return "Short"
                            case 2:
                                return "Custom"
                            default:
                                return "UNDEFINED"
                        }
                    }
                },
                {
                    name: 'duplicated_by_name',
                    label: 'Duplicated By',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'placement_type',
                    label: 'Placement Type',
                    type: 'string',
                    filterable: true,
                    render: (data: any) => {
                        return data || '-';
                    }
                },
                {
                    name: 'overflow_level',
                    label: 'Overflow Level',
                    type: 'string',
                    filterable: true,
                    render: (data: any) => {
                        return data || '-';
                    }
                },
                {
                    name: 'utm_medium',
                    label: 'UTM Medium',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_content',
                    label: 'UTM Content',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'referrer',
                    label: 'Referrer',
                    type: 'string',
                    filterable: true,
                },
                {
                    name: 'keyword',
                    label: 'Keyword',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'creative',
                    label: 'Creative',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'response_time_in_minutes',
                    label: 'Response Time',
                    type: 'number',
                    filterable: true,
                    render: (data: any) => {
                        return data ? `${data} Menit` : '-';
                    }
                },
                {
                    name: 'is_client_chat_first',
                    label: 'Client Chat Duluan',
                    type: 'string',
                    filterable: true,
                    options: [
                        {
                            key: '',
                            value: 'All'
                        },
                        {
                            key: '1',
                            value: 'Yes'
                        },
                        {
                            key: '0',
                            value: 'No'
                        }
                    ],
                    render: (data: any) => {
                        return data === 1 ? 'Yes' : 'No'
                    }
                },
                {
                    name: 'notes',
                    label: 'Notes',
                    type: 'string',
                    filterable: false,
                    style: { minWidth: 300 },
                },
            ] : []
        ),

    ];

    const activityColumn = [
        {
            name: 'created_at',
            label: 'Date',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return helpers.dateFormat(data, 'DD MMM YYYY HH:mm');
            }
        },
        {
            name: 'client_name',
            label: 'Nama',
            type: 'string',
            filterable: true
        }, ...(
            permissions['whatsapp.manage'] ?
                [{
                    name: 'wa_id',
                    label: 'WA Preview',
                    type: 'string',
                    filterable: true,
                    options: [
                        {
                            key: '',
                            value: 'All',
                        },
                        {
                            key: 1,
                            value: 'Yes',
                        },
                        {
                            key: 0,
                            value: 'No',
                        },
                    ],
                    render: (data: any, row: any) => {
                        if (data) {
                            return (
                                <IconButton aria-label="preview chat" title='Preview Chat' onClick={() => handleWhatsappPreview(row)} color='primary'>
                                    <WhatsAppIcon />
                                </IconButton>
                            )

                        } else {
                            return '-'
                        }
                    }
                }]
                : []
        ),
        {
            name: 'agent_name',
            label: 'Agent',
            type: 'string',
            filterable: true
        },
        {
            name: 'cluster_name',
            label: 'Cluster',
            type: 'string',
            filterable: true
        },
        {
            name: 'telesales_name',
            label: 'Telesales',
            type: 'string',
            filterable: true
        },
        {
            name: 'notes',
            label: 'Notes',
            type: 'string',
            filterable: false
        },
        ...(
            moreDetail ? [
                {
                    name: 'datetime',
                    label: 'Datetime',
                    type: 'date',
                    filterable: true,
                    render: (data: any) => {
                        return helpers.dateFormat(data, 'DD MMM YYYY HH:mm');
                    }
                },
                {
                    name: 'code',
                    label: 'Code',
                    type: 'string',
                    filterable: true,
                    render: (data: any, row: any) => {
                        return data || '-'
                    }
                },
                {
                    name: 'type',
                    label: 'Type',
                    type: 'string',
                    defaultOption: '',
                    options: [
                        {
                            key: '',
                            value: 'All'
                        },
                        ...activityTypes
                    ],
                    filterable: false,
                    render: (data: any) => {
                        return activityTypes.find(activityType => activityType.key === data)?.value;
                    }
                },
                {
                    name: 'is_done',
                    label: 'Done',
                    options: [
                        { key: '', value: 'All' },
                        { key: '1', value: "Yes" },
                        { key: '0', value: 'No' },
                    ],
                    filterable: true,
                    render: (data: any) => {
                        return (
                            data === 0 ? 'No' : 'Yes'
                        )
                    }
                },
                {
                    name: 'is_online',
                    label: 'Online',
                    options: [
                        { key: '', value: 'All' },
                        { key: '1', value: "Yes" },
                        { key: '0', value: 'No' },
                        { key: '2', value: "Undefined" },
                    ],
                    filterable: true,
                    render: (data: any, rows: any) => {
                        return (
                            <span>
                                {data === null ? '-' : data === 0 ? 'No' : 'Yes'}
                            </span>
                        )
                    }
                },
                {
                    name: 'done_at',
                    label: 'Done At',
                    type: 'date',
                    filterable: true,
                    render: (data: any) => {
                        if (data) {
                            return helpers.dateFormat(data, 'DD MMM YYYY HH:mm');
                        }

                        return '-'
                    }
                },
                {
                    name: 'client_phone',
                    label: 'No Hp',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'cluster_closing_name',
                    label: 'Cluster Closing',
                    type: 'string',
                    filterable: true,
                    columnStyle: {
                        minWidth: '200px'
                    },
                    render: (data: any, row: any) => {
                        return (
                            <span>{data}</span>
                        )
                    }
                },
                {
                    name: 'source_label',
                    label: 'Source',
                    type: 'string',
                    defaultOption: '',
                    options: [
                        { key: '', value: 'All' },
                        ...leadSources
                    ],
                    filterable: true
                },
                {
                    name: 'utm_source',
                    label: 'UTM Source',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_medium',
                    label: 'UTM Medium',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_campaign',
                    label: 'UTM Campaign',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_content',
                    label: 'UTM Content',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'keyword',
                    label: 'Keyword',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'adsetname',
                    label: 'Adsetname',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'adgroupid',
                    label: 'Adgroupid',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'creative',
                    label: 'Creative',
                    type: 'string',
                    filterable: true
                },
            ] : []
        )
    ];

    const closingColumns = [
        {
            name: 'utj_date',
            label: 'Tanggal UTJ',
            type: 'date',
            sortPriority: 'desc',
            filterable: true,
            render: (data: any) => {
                return data ? helpers.dateFormat(data, 'DD MMMM YYYY') : '-';
            }
        },
        {
            name: 'cluster_name',
            label: 'Cluster',
            type: 'string',
            filterable: true,

        },
        ...(
            permissions['closing.see-estimated-transaction'] ?
                [{
                    name: 'estimated_transaction_amount',
                    label: 'Estimasi Nilai Transaksi',
                    type: 'number',
                    filterable: false,
                    style: {
                        minWidth: '250px'
                    },
                    render: (data: any, row: any, rows: any, index: number) => {
                        return `Rp ${numberToCurrency(data)}`
                    }
                }] : []
        ),
        {
            name: 'agent_name_full',
            label: 'Agent',
            type: 'string',
            filterable: true
        },
        {
            name: 'telesales_name',
            label: 'Telesales',
            type: 'string',
            filterable: true,
        },
        {
            name: 'client_name',
            label: 'Nama Client',
            type: 'string',
            filterable: true,
        },
        ...(
            permissions['whatsapp.manage'] ?
                [{
                    name: 'wa_id',
                    label: 'WA Preview',
                    type: 'string',
                    filterable: true,
                    options: [
                        {
                            key: '',
                            value: 'All',
                        },
                        {
                            key: 1,
                            value: 'Yes',
                        },
                        {
                            key: 0,
                            value: 'No',
                        },
                    ],
                    render: (data: any, row: any) => {
                        if (data) {
                            return (
                                <IconButton aria-label="preview chat" title='Preview Chat' onClick={() => handleWhatsappPreview(row)} color='primary'>
                                    <WhatsAppIcon />
                                </IconButton>
                            )

                        } else {
                            return '-'
                        }
                    }
                }]
                : []
        ),
        ...(
            moreDetail ? [
                {
                    name: 'sales_order_date',
                    label: 'Tanggal SP',
                    type: 'date',
                    filterable: true,
                    render: (data: any) => {
                        return data ? helpers.dateFormat(data, 'DD MMMM YYYY') : '-';
                    }
                },
                {
                    name: 'status_label',
                    label: 'Status',
                    options: [
                        { key: '', value: 'All' },
                        { key: "draft", value: "Draft" },
                        { key: 'so_uploaded', value: 'Sudah SP' },
                        { key: 'not_complete', value: 'Lunas Sebagian' },
                        { key: 'complete', value: 'Lunas Total' },
                        { key: 'cancel_payment_office', value: 'Gagal UTJ Kantor' },
                        { key: 'cancel_payment_developer', value: 'Gagal UTJ Developer' },
                        { key: 'cancel_payment_kpr', value: 'Gagal Setelah SP' },
                        { key: 'cancel_payment_installment', value: 'Gagal Cicilan' },
                        { key: 'cancel_payment_other', value: 'Gagal Lainnya' },
                    ],
                    filterable: true,
                },
                {
                    name: 'code',
                    label: 'Kode',
                    type: 'string',
                    defaultOption: '',
                    filterable: true,
                    render: (data: string, row: any) => {
                        if (data) {
                            return (
                                <Chip
                                    variant="outlined"
                                    label={row.code}
                                />
                            )
                        } else {
                            return "-";
                        }
                    }
                },
                {
                    name: 'is_cobroke',
                    label: 'Cobroke',
                    type: 'string',
                    defaultOption: '',
                    options: [
                        { key: '', value: 'All' },
                        { key: "1", value: "Yes" },
                        { key: '0', value: 'No' },
                    ],
                    filterable: true,
                    render: (data: any) => {
                        return data === 0 ? 'No' : 'Yes';
                    }
                },
                // {
                //     name: 'is_telesales',
                //     label: 'Closing Telesales',
                //     type: 'string',
                //     defaultOption: '',
                //     options: [
                //         { key: '', value: 'All' },
                //         { key: "1", value: "Yes" },
                //         { key: '0', value: 'No' },
                //     ],
                //     filterable: true,
                //     render: (data: any) => {
                //         return data === 0 ? 'No' : 'Yes';
                //     }
                // },
                {
                    name: 'agent_group_name',
                    label: 'Cabang',
                    type: 'string',
                    filterable: true,

                },
                {
                    name: 'cobroke_agent_name_full',
                    label: 'Cobroke Agent',
                    type: 'string',
                    filterable: true,
                },
                {
                    name: 'cobroke_agent_group_name',
                    label: 'Cabang (Cobroke)',
                    type: 'string',
                    filterable: true,

                },
                {
                    name: 'developer_name',
                    label: 'Developer',
                    type: 'string',
                    filterable: true,
                },
                {
                    name: 'unit_information',
                    label: 'Unit Detail',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'transaction_amount',
                    label: 'Nilai Transaksi',
                    type: 'string',
                    filterable: false,
                    render: (data: number) => `Rp ${numberToCurrency(data)}`
                },
                {
                    name: 'created_at',
                    label: 'Tanggal Input',
                    type: 'date',
                    filterable: true,
                    render: (data: any) => {
                        return data ? helpers.dateFormat(data, 'DD MMMM YYYY') : '-';
                    }
                },
                {
                    name: 'mts_label',
                    label: 'Manual Tracking Source',
                    type: 'string',
                    defaultOption: '',
                    options: [
                        { key: '', value: 'All' },
                        ...leadMts,
                    ],
                    filterable: true,
                    sortable: false,
                    render: (data: string, row: any) => {
                        return data === 'undefined' ? '' : data;
                    }
                },
                {
                    name: 'source_label',
                    label: 'Source',
                    type: 'string',
                    defaultOption: '',
                    options: [
                        { key: '', value: 'All' },
                        ...leadSources
                    ],
                    filterable: true,
                    sortable: false,
                },
                {
                    name: 'notes',
                    label: 'Notes',
                    type: 'string',
                    filterable: false,
                    style: {
                        minWidth: '300px'
                    }
                },
                {
                    name: 'lead_date',
                    label: 'Tanggal Leads',
                    type: 'date',
                    filterable: true,
                    render: (data: any) => {
                        return helpers.dateFormat(data, 'DD MMM YYYY HH:mm');
                    }
                },
                {
                    name: 'lead_client_name',
                    label: 'Nama Client Lead',
                    type: 'string',
                    filterable: true,
                    render: (data: any, row: any) => {
                        return (
                            <span>{data}</span>
                        )
                    }
                },
                {
                    name: 'lead_cluster_name',
                    label: '1st Cluster Ask',
                    type: 'string',
                    filterable: true,
                },
                {
                    name: 'utm_source',
                    label: 'UTM Source',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_medium',
                    label: 'UTM Medium',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_campaign',
                    label: 'UTM Campaign',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_content',
                    label: 'UTM Content',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'referrer',
                    label: 'Referrer',
                    type: 'string',
                    filterable: true,
                },
                {
                    name: 'keyword',
                    label: 'Keyword',
                    type: 'string',
                    filterable: true
                },
                // {
                //     name: 'gclid',
                //     label: 'Gclid',
                //     type: 'string',
                //     filterable: true
                // },
                // {
                //     name: 'fbclid',
                //     label: 'FBClid',
                //     type: 'string',
                //     filterable: true
                // },
                {
                    name: 'adsetname',
                    label: 'Adsetname',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'adgroupid',
                    label: 'Adgroupid',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'creative',
                    label: 'Creative',
                    type: 'string',
                    filterable: true
                },
            ] : []
        )
    ];

    // const campaignAdsetColumn = [
    //     {
    //         name: 'name',
    //         label: 'Adset Name',
    //         type: 'string',
    //         filterable: true,

    //     },
    //     {
    //         name: 'client_score_average',
    //         label: 'Client Score',
    //         type: 'string',
    //         filterable: false,
    //         render: (data: any, row: any) => (
    //             <ChipInfo type="circle" status={calculateScore(row).scoreStatus} label={data || '-'} />
    //         )
    //     },
    //     {
    //         name: 'leads_warm_and_hot_count',
    //         label: 'Warm + Hot',
    //         type: 'string',
    //         filterable: false,
    //         render: (data: any, row: any) => (
    //             <ChipInfo type="circle" status={calculateScore(row).status} label={`${data} \r\n (${calculateScore(row).warmPercentage} %)`} />
    //         )

    //     },
    //     {
    //         name: 'cpl',
    //         label: 'CPL',
    //         type: 'string',
    //         filterable: false,
    //         render: (data: any) => (
    //             <ChipInfo type="circle" status="warning" label={convertNumber(`0`, 'Rp. ')} />
    //         )
    //     },
    //     {
    //         name: 'leads_count',
    //         label: 'Lead',
    //         type: 'string',
    //         filterable: false,
    //         render: (data: any) => (
    //             <ChipInfo type="polygon" label={data} />
    //         )
    //     },
    //     {
    //         name: 'showing_count',
    //         label: 'Showing',
    //         type: 'string',
    //         filterable: false,
    //         render: (data: number) => <ChipInfo type="polygon" label={data} />
    //     },
    //     {
    //         name: 'closing_count',
    //         label: 'Closing',
    //         type: 'string',
    //         filterable: false,
    //         render: (data: any) => <ChipInfo type="polygon" label={data} />
    //     },
    // ]

    useEffect(() => {
        setIsLoading(true)
        setDataType(props.type)
        setMoreDetail(false)
    }, [props.type])

    const renderDataTable = useMemo(() => {
        switch (dataType) {
            case 'lead':
                if (props.breakdownBy === 'campaign') {
                    return (
                        <AdsMainTable
                            url='/facebook-ads/performance-campaign'
                            clusterId={props.id || 0}
                            filter={props.customParams['restrict_date_ads'] ? { date: props.customParams['restrict_date_ads'] } : undefined}
                            redraw={0}
                            campaignAdset={'campaign'}
                        />
                    )
                }

                if (props.breakdownBy === 'adset') {
                    return (
                        <AdsMainTable
                            url='/facebook-ads/performance-adsetname'
                            clusterId={props.id || 0}
                            filter={props.customParams['restrict_date_ads'] ? { date: props.customParams['restrict_date_ads'] } : undefined}
                            redraw={0}
                            campaignAdset={'adsetname'}
                        />
                    )
                }

                if (props.breakdownBy === 'source') {
                    return (
                        <AdsMainTable
                            firstColumnName='Source'
                            url='/facebook-ads/performance-source'
                            clusterId={props.id || 0}
                            filter={props.customParams['restrict_date_ads'] ? { date: props.customParams['restrict_date_ads'] } : undefined}
                            redraw={0}
                            campaignAdset={'source'}
                            hideCPL
                        />
                    )
                }

                if (props.breakdownBy === 'agent') {
                    return (
                        <AdsMainTable
                            firstColumnName='Agent'
                            url='/facebook-ads/performance-agent'
                            clusterId={props.id || 0}
                            filter={props.customParams['restrict_date_ads'] ? { date: props.customParams['restrict_date_ads'] } : undefined}
                            redraw={0}
                            campaignAdset={'agent'}
                            hideCPL
                        />
                    )
                }

                return (
                    <DataTable
                        url={`${process.env.REACT_APP_API_URL}/lead`}
                        columns={leadColumn}
                        responseCallback={() => {
                            setIsLoading(false)
                        }}
                        customParams={props.customParams}
                    />
                )
            case 'activity':
                return (
                    <DataTable
                        url={`${process.env.REACT_APP_API_URL}/activity`}
                        columns={activityColumn}
                        responseCallback={() => {
                            setIsLoading(false)
                        }}
                        customParams={props.customParams}
                    />
                )
            case 'closing':
                return (
                    <DataTable
                        url={`${process.env.REACT_APP_API_URL}/closing-admin`}
                        columns={closingColumns}
                        responseCallback={() => {
                            setIsLoading(false)
                        }}
                        customParams={props.customParams}
                    />
                )
            default:
                return '-'
        }

        // eslint-disable-next-line
    }, [dataType, props.breakdownBy, moreDetail])

    return (
        <>
            <WhatsappPreviewModal
                open={!!chatsData.chats.length}
                chats={chatsData.chats}
                code={chatsData.code}
                created_at={chatsData.created_at}
                onClose={() => {
                    setChatsData(INITIAL_CHATS_DATA)
                }}
                pagination={{
                    hasNext: chatsData.next_id !== null,
                    hasPrev: chatsData.prev_id !== null,
                }}
                onNextPressed={() => {
                    if (chatsData.next_id !== null) handleWhatsappPreview(chatsData.next_id)
                }}
                onPrevPressed={() => {
                    if (chatsData.prev_id !== null) handleWhatsappPreview(chatsData.prev_id)
                }}
            />
            <Root>
                <LoadingScreen open={isLoading} />
                <Paper variant='elevation' elevation={3} className={classes.paper}>
                    <div className={classes.paperHeader}>
                        <Typography fontWeight={'700'} variant='body1'>
                            {
                                props.breakdownBy !== 'none' ?
                                    `Breakdown by ${capitalize(props.breakdownBy)}` :
                                    `Menampilkan ${capitalize(dataType)} List`
                            }

                        </Typography>
                        {
                            dataType === 'lead' && props.breakdownBy !== 'none' ?
                                null :
                                <FormControlLabel
                                    key={dataType}
                                    style={{ marginLeft: 'auto', marginRight: 0 }}
                                    control={
                                        <AntSwitch
                                            checked={moreDetail}
                                            onChange={(e) => {
                                                setMoreDetail(e.target.checked)
                                            }}
                                        />
                                    }
                                    label={
                                        <span style={{ marginLeft: 5, fontSize: 12 }}>More Detail</span>
                                    }
                                />
                        }
                    </div>
                    <div>
                        {!props.disableFetch && renderDataTable}
                    </div>
                </Paper>
            </Root>
        </>
    )
}

const useStyles = generateStyle((theme) => ({
    paper: {
        borderRadius: 8,
        overflow: 'hidden'
    },
    paperHeader: {
        background: theme.palette.primary.main,
        padding: 20,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
    }

}), "Cluster_Tables")

export default ClusterTables