import React, { useMemo } from 'react'

/**
 * Components
 */
import { Divider, Theme } from '@mui/material'
import defaultImage from '../../../Assets/Images/png/avatar.png'

/**
 * Utils
 */
import { FunnelReportItem } from '../FunnelReport'
import { generateStyle } from '../../../_utils/DefaultStyles'

/**
 * Icons
 */
import BoltIcon from '@mui/icons-material/Bolt';
import { convertNumber } from '../../../_utils/Helpers'

interface FunnelReportCardProps extends FunnelReportItem {
    onClosingClick: (item: FunnelReportItem, type: string) => void
}

const FunnelReportCard = (props: FunnelReportCardProps) => {
    const { Root, classes } = useStyles()

    const highScoreColor = '#39AF01';
    const lowScoreColor = '#AF1500';

    const renderContent = () => {
        return (
            <>
                <div className={classes.cluster_row}>
                    <span className={classes.cluster_text}>
                        Leads
                    </span>
                    <span className={classes.cluster_text}>
                        {props.clusters.leads_report} ({props.clusters.leads_transferred_report ? props.clusters.leads_transferred_report : "-"})
                    </span>
                </div>
                <div className={classes.cluster_row}>
                    <span className={classes.cluster_text}>
                        Showing Done
                    </span>
                    <span className={`${classes.cluster_text} ${(props.name === 'All Agents' || !props.clusters.showings_done_report) ? classes.nonClickable : classes.clickable}`} onClick={() => (props.name === 'All Agents' || !props.clusters.showings_done_report) ? undefined : props.onClosingClick(props, 'showing')}>
                        {props.clusters.showings_done_report} ({props.clusters.leads_report === 0 ? '0.00' : ((props.clusters.showings_done_report / props.clusters.leads_report * 100) || 0).toFixed(2)}%)
                    </span>
                </div>
                <div className={classes.cluster_row}>
                    <span className={classes.cluster_text}>
                        UTJ Refundable
                    </span>
                    <span className={`${classes.cluster_text} ${(props.name === 'All Agents' || !props.clusters.closing_utj_refund_report) ? classes.nonClickable : classes.clickable}`} onClick={() => (props.name === 'All Agents' || !props.clusters.closing_utj_refund_report) ? undefined : props.onClosingClick(props, 'utj_refundable')}>
                        {props.clusters.closing_utj_refund_report} ({props.clusters.showings_done_report === 0 ? '0.00' : ((props.clusters.closing_utj_refund_report / props.clusters.leads_report * 100) || 0).toFixed(2)}%)
                    </span>
                </div>
                <div className={classes.cluster_row}>
                    <span className={classes.cluster_text}>
                        UTJ Non Refundable
                    </span>
                    <span className={`${classes.cluster_text} ${(props.name === 'All Agents' || !props.clusters.closing_utj_non_refund_report) ? classes.nonClickable : classes.clickable}`} onClick={() => (props.name === 'All Agents' || !props.clusters.closing_utj_non_refund_report) ? undefined : props.onClosingClick(props, 'utj_non_refundable')}>
                        {props.clusters.closing_utj_non_refund_report} ({props.clusters.showings_done_report === 0 ? '0.00' : ((props.clusters.closing_utj_non_refund_report / props.clusters.showings_done_report * 100) || 0).toFixed(2)}%)
                    </span>
                </div >
                <div className={classes.cluster_row}>
                    <span className={classes.cluster_text}>
                        GMV Akad (NoC)
                    </span>
                    <span className={`${classes.cluster_text} ${(props.name === 'All Agents' || !props.clusters.closing_agreed_sum) ? classes.nonClickable : classes.clickable}`} onClick={() => (props.name === 'All Agents' || !props.clusters.closing_agreed_sum) ? undefined : props.onClosingClick(props, 'gmv_akad')}>
                        {props.clusters.closing_agreed_sum ? convertNumber(String(props.clusters.closing_agreed_sum), 'Rp. ') : 'Rp. -'} ({props.clusters.closing_agreed_count || '0'})
                    </span>
                </div >
                {/* <div className={classes.cluster_row}>
                    <span className={classes.cluster_text}>
                        Responsiveness Score
                    </span>
                    <span className={classes.cluster_text}>
                        {clusters.response_score > 100 ? '100' : clusters.response_score}
                    </span>
                </div> */}

            </>
        )
    }

    const score = useMemo(() => {
        return {
            osa_score: props.clusters.osa_score,
            response_time_score: props.clusters.response_time_score
        }
    }, [props.clusters])

    const generateScoreComponent = (score: {
        osa_score: number,
        response_time_score: number
    }) => {
        const osaColor = score.osa_score >= 75 ? highScoreColor : lowScoreColor
        const responseColor = score.response_time_score >= 75 ? highScoreColor : lowScoreColor
        return (
            <div
                className={classes.responseText}
                style={{
                    color: osaColor,
                    backgroundColor: `${osaColor}20`,
                    border: `1px solid ${osaColor}`
                }}
            >
                {score.osa_score > 100 ? '100' : score.osa_score || 'N/A'}
                <div className={classes.speedText} style={{
                    border: `1px solid ${responseColor}`
                }}>
                    <BoltIcon color={'warning'} style={{ fontSize: 12 }} />
                    <span>{score.response_time_score > 100 ? '100' : score.response_time_score || 'N/A'}</span>
                </div>
            </div>

        )
    }

    return (
        <Root>

            <div className={classes.root} style={{ paddingBottom: 4 }}>
                <div className={classes.cluster_row}>
                    <span className={classes.name}>
                        <img src={props.avatar_url || defaultImage} alt="profile" className={classes.avatar} />
                        {props.name}
                    </span>
                    <div>
                        {/* <div className={classes.cluster_text}>Score</div> */}
                        <div className={classes.score}>
                            {generateScoreComponent(score)}
                        </div>
                    </div>
                </div>
                <Divider className={classes.divider} />
                <div className="">
                    {
                        renderContent()
                    }
                </div>
            </div>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        borderRadius: 20,
        boxShadow: '3px 3px 10px #52575d1a',
        padding: '21px 23px 33px',
        backgroundColor: 'white',
        alignSelf: 'start',
    },
    name: {
        color: '#3D3D3D',
        fontSize: 20,
        fontWeight: 700,
        display: 'flex',
        gap: 10,
        alignItems: 'center'
    },
    score: {
        color: '#3D3D3D',
        fontSize: 18,
        fontWeight: 700,
        textAlign: 'center'
    },
    divider: {
        borderColor: '#EBF2F7',
        marginTop: 13,
        marginBottom: 11,
    },
    cluster_row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 14,
    },
    cluster_text: {
        fontSize: 14,
    },
    total: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#035AA633',
        padding: '10px 14px',
        borderRadius: 10,
    },
    total_text: {
        color: '#3D3D3D',
        fontSize: 16,
        fontWeight: 500,
    },
    showMoreContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContentL: 'center',
        marginTop: 8,
        cursor: 'pointer',
        opacity: 0.6,
    },
    showMoreText: {
        color: theme.palette.primary.main,
        fontSize: 12,
    },
    showMoreIcon: {
        color: theme.palette.primary.main,
        fontSize: 16,
    },
    more: {
        transform: 'rotate(-90deg)',
    },
    less: {
        transform: 'rotate(90deg)',
    },
    avatar: {
        height: 40,
        width: 40,
        borderRadius: '50%'
    },
    clickable: {
        fontWeight: '700',
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
    nonClickable: {
        color: '#484848'
    },
    responseText: {
        width: 50,
        height: 50,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    speedText: {
        fontSize: 10,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        position: 'absolute',
        bottom: -6,
        right: -6,
        background: 'white',
        padding: `1px 4px`
    }
}), "FunnelReport_Card"
)

export default FunnelReportCard