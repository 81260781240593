import { Box, ClickAwayListener, Typography } from '@mui/material';
import React, { useState } from 'react'
import { ChromePicker } from 'react-color';

interface ColorPickerProps {
    name: string
    value: string
    onChange: (name: string, value: string) => void
    disabled?: boolean
}

const ColorPicker = (props: ColorPickerProps) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-flex',
                alignItems: 'center',
                cursor: props.disabled ? 'auto' : 'pointer',
                width: 'auto'
            }}
            onClick={props.disabled ? undefined : () => setIsOpen(true)}
        >
            <Box
                sx={{
                    backgroundColor: props.value.includes('#') ? props.value : 'white',
                    border: '1px solid black',
                    borderColor: props.value !== '#ffffff' ? props.value : 'black',
                    borderRadius: '4px',
                    height: 20,
                    width: 16,
                    marginRight: 1,
                }}
            />
            <Typography variant='body1'>
                {props.value.includes('#') ? props.value : 'Pilih Warna'}
            </Typography>
            {
                isOpen ?
                    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                        <Box sx={{ position: 'absolute', top: -235, left: -63, zIndex: 99999 }}>
                            <ChromePicker
                                color={props.value}
                                onChange={x => {
                                    props.onChange(props.name, x.hex)
                                }}
                                disableAlpha
                            />
                        </Box>
                    </ClickAwayListener>
                    : null
            }
        </Box>
    )
}

export default ColorPicker
