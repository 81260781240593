import React from 'react'

/**
 * Components
 */
import { Button, Grid, IconButton, TextField } from '@mui/material'

/**
 * Utils
 */


/**
 * Icons
 */
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { generateStyle } from '../../_utils/DefaultStyles';

interface MultiItemFormProps {
    name: string
    label: string
    list: string[]
    errors: string[]
    onChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void
    onBlur?: (e: React.FocusEvent<HTMLInputElement>, index: number) => void
    onAdd: (name: string) => void
    onDelete: (name: string, index: number) => void
    onMove: (name: string, index: number, direction: 'up' | 'down') => void
}

const MultiItemForm = (props: MultiItemFormProps) => {
    const {Root, classes} = useStyles()

    const renderForm = () => {
        return (
            <>
                {
                    props.list.map((value, index) =>
                        <Grid container key={`${index}`} spacing={1} alignItems="start">
                            <Grid item xs={12} sm={9}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            placeholder={`${props.label} ${index + 1}`}
                                            label={`${props.label} ${index + 1}`}
                                            name={props.name}
                                            value={value}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e, index)}
                                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => props.onBlur ? props.onBlur(e, index) : null}
                                            error={!!props.errors[index]}
                                            helperText={props.errors[index]}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3} style={{display: 'flex', marginTop: 8}}>
                                        <IconButton
                                            color={index === 0 ? 'default' : 'primary'}
                                            style={{marginLeft: 'auto', marginBottom: 'auto'}}
                                            onClick={() => props.onMove(props.name, index, 'up')}
                                            disabled={index === 0}
                                        >
                                            <ArrowUpwardIcon fontSize="medium" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={3} style={{display: 'flex', marginTop: 8}}>
                                        <IconButton
                                            color={index === props.list.length - 1 ? 'default' : 'primary'}
                                            style={{marginLeft: 'auto', marginBottom: 'auto'}}
                                            onClick={() => props.onMove(props.name, index, 'down')}
                                            disabled={index === props.list.length - 1}
                                        >
                                            <ArrowDownwardIcon fontSize="medium" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={3} style={{display: 'flex', marginTop: 8}}>
                                        <IconButton
                                            color="secondary"
                                            style={{marginLeft: 'auto', marginBottom: 'auto'}}
                                            onClick={() => props.onDelete(props.name, index)}
                                        >
                                            <CancelIcon fontSize="medium" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            </>
        )
    }
    return (
        <Root>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div className={classes.listContainer}>
                        {renderForm()}
                    </div>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <div className={classes.flexCenter}>
                        <Button
                            variant="contained"
                            onClick={() => props.onAdd(props.name)}

                        >
                            Add {props.label}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </Root>
    )
}

const useStyles = generateStyle(theme => ({
    title: {
        fontSize: 16,
        color: '#484848',
        marginBottom: 12,
        fontWeight: 'bold',
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > .error': {
            color: theme.palette.error.main,
            marginTop: 12,
        }
    },
    line: {
        display: 'block',
        width: '100%',
        height: 1,
        backgroundColor: '#cacaca',
        margin: '16px 0px 16px',
    },
    metaContainer: {
        '& .title': {
            fontSize: 16,
            fontWeight: '500',
        },
        '& .subtitle': {
            fontWeight: '500',
        }
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    submitContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    listContainer: {
        width: '100%',
        '& > *': {
            paddingBottom: 16,
            paddingTop: 16,
            // borderBottom: '2px solid #cacaca',
        },
        '& > *:first-of-type': {
            paddingTop: 0,
        },
        '& > *:last-child': {
            borderBottom: 'none',
        }
    },
}), "MultiItem_Form")

export default MultiItemForm
