import { Grid, Theme } from '@mui/material'
import React from 'react'

/**
 * Utils
 */
import { numberToCurrency } from '../../../_utils/Helpers';
import { generateStyle } from '../../../_utils/DefaultStyles';

interface IProps {
    summaryData: ISummary
}

interface ILoanData {
    code: string;
    nominal: number;
}

export interface ISummary {
    type: string;
    is_cobroke: number;
    escrow_status: string;
    code: string;
    unit_information: string;
    full_name_agent: string;
    agent_bank_information: string;
    cobroke_agent_bank_information: string;
    client_name: string;
    transaction_amount: number;
    transaction_amount_exclude_tax: number;
    nominal: number;
    dpp_amount: number;
    ppn_amount: number;
    pph23_amount: number;
    gross_commission: number;
    office_commission_amount: number;
    office_commission_percentage: number;
    agent_pph21_amount: number;
    borrowed_commission: number;
    total_transfer_office: number;
    total_transfer_advisor: number;
    total_transfer_sum: number;
    total_credit: number | null;
    // borrowed_commission_amount: number;
    agent_commission_amount: number;
    full_name_agent_cobroke: string;
    agent_commission_percentage: number;
    cobroke_commission_amount: number;
    cobroke_commission_percentage: number;
    cobroke_pph21_amount: number;
    telesales_commission_amount: number;
    // cobroke_borrowed_commission_amount: number;
    total_transfer_advisor_2: number;
    office_percentage_compare_from_gross: number;
    agent_percentage_compare_from_gross: number;
    cobroke_percentage_compare_from_gross: number;
    paid_loan_list: ILoanData[];
    cobroke_paid_loan_list: ILoanData[];
}

const Summary = ({ summaryData, ...props }: IProps) => {
    const { Root, classes } = useStyles()

    const DialogCell = ({ name, value, bold }: { name: string, value: string, bold?: boolean }) => {
        return (
            <Grid container justifyContent={'space-between'} className={bold ? classes.boldContainer : ""}>
                <Grid item className={bold ? classes.bold : ""}>{name}</Grid>
                <Grid item className={bold ? classes.bold : ""}>{value}</Grid>
            </Grid>
        )
    }

    const DialogCell4 = ({ name, percentage, percentageof, value, bold }: { name: string, percentage: string, percentageof: string, value: string, bold?: boolean }) => {
        return (
            <Grid container className={bold ? classes.boldContainer : ""} columnSpacing={2}>
                <Grid item xs={5} >{name}</Grid>
                <Grid item xs={1.5} className={classes.right}>{percentage}</Grid>
                <Grid item xs={3} >{percentageof}</Grid>
                <Grid item xs={2.5} className={classes.right}>{value}</Grid>
            </Grid>
        )
    }

    return (
        <Root>
            <Grid container className={classes.boldContainer}>
                <Grid item xs={12} className={classes.bold}>Total Credit : {summaryData.total_credit ? `Rp. ${numberToCurrency(summaryData.total_credit)}` : '-'}</Grid>
            </Grid>
            <Grid container sx={{ fontSize: '14px' }}>
                <DialogCell name={"Kode Transaksi"} value={`${summaryData.code || '-'}`} bold />

                <DialogCell name={"Alamat Properti"} value={`${summaryData.unit_information}`} />
                <DialogCell name={"Nama Advisor"} value={`${summaryData.full_name_agent || '-'}`} />
                <DialogCell name={"No. Rekening Advisor"} value={summaryData.agent_bank_information || '-'} />

                {
                    summaryData.is_cobroke ?
                        <>
                            <DialogCell name={"Nama Advisor (Cobroke)"} value={`${summaryData.full_name_agent_cobroke || '-'}`} />
                            <DialogCell name={"No. Rekening Advisor (Cobroke)"} value={summaryData.cobroke_agent_bank_information || '-'} />
                        </> : ""
                }
                <DialogCell name={"Pembeli"} value={`${summaryData.client_name}`} />
                <DialogCell name={"Nilai Transaksi (Inc. PPN) "} value={`${numberToCurrency(summaryData.transaction_amount)}`} />
                <DialogCell name={"Nilai Transaksi (Exc. PPN) "} value={`${numberToCurrency(summaryData.transaction_amount_exclude_tax)}`} />

                <DialogCell name={"Uang Masuk"} value={`${numberToCurrency(summaryData.nominal)}`} bold />

                <DialogCell name={"DPP"} value={`${numberToCurrency(summaryData.dpp_amount)}`} />
                <DialogCell4
                    name={"PPN"}
                    percentage={"11 %"}
                    percentageof={"DPP"}
                    value={`${numberToCurrency(summaryData.ppn_amount)}`}
                />
                <DialogCell4
                    name={"PPh 23"}
                    percentage={"2 %"}
                    percentageof={"DPP"}
                    value={`${numberToCurrency(summaryData.pph23_amount)}`}
                />

                {
                    summaryData.telesales_commission_amount ?
                        <DialogCell4
                            name={"Telesales"}
                            percentage={"30 %"}
                            percentageof={"DPP - PPh 23"}
                            value={`${numberToCurrency(summaryData.telesales_commission_amount)}`}
                        /> :
                        null
                }

                <DialogCell name={summaryData.telesales_commission_amount ?  "Gross Commission (DPP-PPH23-Telesales)" : "Gross Commission (DPP-PPH23)"} value={`${numberToCurrency(summaryData.gross_commission)}`} bold />

                <DialogCell4
                    name={"Komisi Kantor"}
                    percentage={`${summaryData.office_percentage_compare_from_gross} %`}
                    percentageof={"Gross Comm."}
                    value={`${numberToCurrency(summaryData.office_commission_amount)}`}
                />
                {
                    summaryData.telesales_commission_amount ?
                        <DialogCell4
                            name={"Telesales"}
                            percentage={"30 %"}
                            percentageof={"DPP - PPh 23"}
                            value={`${numberToCurrency(summaryData.telesales_commission_amount)}`}
                        /> :
                        null
                }
                <DialogCell4
                    name={"PPN"}
                    percentage={"11 %"}
                    percentageof={"DPP"}
                    value={`${numberToCurrency(summaryData.ppn_amount)}`}
                />
                {summaryData.is_cobroke ?
                    <>
                        <DialogCell4
                            name={"PPh 21 Setor Negara Advisor 1"}
                            percentage={"2.5 %"}
                            percentageof={"Komisi Advisor 1"}
                            value={`${numberToCurrency(summaryData.agent_pph21_amount)}`}
                        />
                        <DialogCell4
                            name={"PPh 21 Setor Negara Advisor 2"}
                            percentage={"2.5 %"}
                            percentageof={"Komisi Advisor 2"}
                            value={`${numberToCurrency(summaryData.cobroke_pph21_amount)}`}
                        />
                    </>
                    :
                    <DialogCell4
                        name={"PPh 21 Setor Negara"}
                        percentage={"2.5 %"}
                        percentageof={"Komisi Advisor"}
                        value={`${numberToCurrency(summaryData.agent_pph21_amount + (summaryData.is_cobroke ? summaryData.cobroke_pph21_amount : 0))}`}
                    />
                }


                <>
                    {/* <DialogCell name={"Pengembalian Pinjaman Advisor"} value={`${numberToCurrency(summaryData.borrowed_commission_amount)}`} /> */}
                    {summaryData.paid_loan_list.map((loan) =>
                        <DialogCell4
                            name={`Pengembalian Pinjaman Advisor ${summaryData.is_cobroke ? "1" : ""}`}
                            percentage={``}
                            percentageof={loan.code}
                            value={`${numberToCurrency(loan.nominal)}`} />
                    )}
                    {summaryData.is_cobroke ?
                        <>
                            {summaryData.cobroke_paid_loan_list.map((loan) =>
                                <DialogCell4
                                    name={`Pengembalian Pinjaman Advisor 2`}
                                    percentage={``}
                                    percentageof={loan.code}
                                    value={`${numberToCurrency(loan.nominal)}`} />
                            )}
                        </>
                        : ""
                    }
                </>


                <DialogCell name={"Total Transfer Kantor"} value={`${numberToCurrency(summaryData.total_transfer_office)}`} bold />

                <DialogCell4
                    name={`Komisi Advisor ${summaryData.is_cobroke ? "1" : ""}`}
                    percentage={`${summaryData.agent_percentage_compare_from_gross} %`}
                    percentageof={"Gross Comm."}
                    value={`${numberToCurrency(summaryData.agent_commission_amount)}`}
                />
                <DialogCell4
                    name={`Potongan PPH 21 Advisor ${summaryData.is_cobroke ? "1" : ""}`}
                    percentage={`2.5 %`}
                    percentageof={"Komisi Advisor"}
                    value={`(${numberToCurrency(summaryData.agent_pph21_amount)})`}
                />
                <>
                    {/* <DialogCell name={`Pengembalian Pinjaman Advisor ${summaryData.is_cobroke ? "1" : ""}`} value={`(${numberToCurrency(summaryData.borrowed_commission_amount)})`} /> */}
                    {summaryData.paid_loan_list.map((loan) =>
                        <DialogCell4
                            name={`Pengembalian Pinjaman Advisor ${summaryData.is_cobroke ? "1" : ""}`}
                            percentage={``}
                            percentageof={loan.code}
                            value={`(${numberToCurrency(loan.nominal)})`} />
                    )}
                </>

                <DialogCell bold name={`Total Transfer Advisor ${summaryData.is_cobroke ? "1" : ""}`} value={`${numberToCurrency(summaryData.total_transfer_advisor)}`} />

                {
                    summaryData.is_cobroke ?
                        <>
                            <DialogCell4
                                name={`Komisi Advisor 2`}
                                percentage={`${summaryData.cobroke_percentage_compare_from_gross} %`}
                                percentageof={"Gross Comm."}
                                value={`${numberToCurrency(summaryData.cobroke_commission_amount)}`}
                            />
                            <DialogCell4
                                name={`Potongan PPH 21 Advisor 2`}
                                percentage={`2.5 %`}
                                percentageof={"Komisi Advisor"}
                                value={`(${numberToCurrency(summaryData.cobroke_pph21_amount)})`}
                            />

                            <>
                                {/* <DialogCell name={"Pengembalian Pinjaman Advisor 2"} value={`(${numberToCurrency(summaryData.cobroke_borrowed_commission_amount)})`} /> */}
                                {summaryData.cobroke_paid_loan_list.map((loan) =>
                                    <DialogCell4
                                        name={`Pengembalian Pinjaman Advisor 2`}
                                        percentage={``}
                                        percentageof={loan.code}
                                        value={`(${numberToCurrency(loan.nominal)})`} />
                                )}
                            </>

                            <DialogCell name={"Total Transfer Advisor 2"} value={`${numberToCurrency(summaryData.total_transfer_advisor_2)}`} bold />
                        </> : ""
                }

                <DialogCell name={"Total Uang Keluar"} value={`${numberToCurrency(summaryData.total_transfer_sum)}`} bold />
            </Grid>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    boldContainer: {
        margin: "10px auto"
    },
    bold: {
        fontWeight: 700
    },
    right: {
        paddingLeft: 0,
        textAlign: "right"
    }
}), "Summary"
)

export default Summary