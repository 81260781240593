import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import {
    Navigate,
    // Route,
} from 'react-router-dom';

interface PrivateRouteProps extends RouteComponentProps {
    component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component, ...rest } = props;
    const isSignedIn = (localStorage.getItem("jwt_token") !== null);

    return (
        isSignedIn ? <Component {...rest} /> : <Navigate replace to="/login" />
        // <Route
        //     {...rest}
        //     element={isSignedIn ? <Component {...rest} /> : <Navigate to="/login" replace />}
        // />
    );
};

export default PrivateRoute;