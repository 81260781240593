import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Dialog, DialogTitle, DialogContent, Grid, DialogActions, Button, TextField, CircularProgress } from '@mui/material'
import AsyncMultipleAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsycMultipleAutoComplete'

/**
 * Utils
 */
import { generalErrorHandler, renderToastSuccess } from '../../_utils/Helper'
import DefaultAxios from '../../_utils/DefaultAxios'

interface TelesalesLeadGroupEditProps {
    data: null | {
        id: string
        name: string
        groups: IAutoCompleteOption[]
    }
    onClose: () => void
    onSubmit: () => void
}

type State = {
    id: string
    name: string
    groups: IAutoCompleteOption[]
}

const TelesalesLeadGroupEdit = (props: TelesalesLeadGroupEditProps) => {
    const [state, setState] = useState<State>(initialState)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (props.data) {
            setState(props.data)
        } else {
            setState(initialState)
        }
    }, [props.data])

    const handleAutocomplete = (name: string, value: IAutoCompleteOption[]) => {
        setState(prev => ({
            ...prev,
            groups: value,
        }))
    }

    const handleSubmit = () => {
        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/lead-group-telesales`, {
            id: state.id,
            group_ids: state.groups.map(group => group.id)
        })
            .then(res => res.data)
            .then(() => {
                renderToastSuccess('Berhasil mengubah data lead group')
                props.onClose()
                props.onSubmit()
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Dialog
            open={props.data !== null}
            onClose={() => {
                if (!isLoading) {
                    props.onClose()
                }
            }}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth='xs'
        >
            <DialogTitle id="form-dialog-title">Edit Lead Group</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    sx={{ mt: -1 }}
                >
                    <Grid item xs={12}>
                        <TextField
                            label="Name"
                            name='name'
                            value={state.name}
                            disabled
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AsyncMultipleAutoComplete
                            value={state.groups}
                            label="Area"
                            name="area"
                            onChange={handleAutocomplete}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/lead-group`}
                            disabled={isLoading}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => { props.onClose() }}
                    color="primary"
                    disabled={isLoading}
                >
                    Batal
                </Button>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    disabled={isLoading}
                >
                    {
                        isLoading ?
                            <CircularProgress size={14} />
                            : 'Submit'
                    }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const initialState = {
    id: '',
    name: '',
    groups: [],
}

export default TelesalesLeadGroupEdit
