import React, { useReducer, useState, useEffect, Fragment } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

// Componenets
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import helpers from '../../_utils/Helpers';
import DefaultAxios from '../../_utils/DefaultAxios';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import { IValidationAlias, IValidationRules, validateData, validateOne } from '../../_utils/Validation';
import { inputNumber } from '../../_utils/Helper';

interface Props {
    listingId: string | null;
    mode: 'add' | 'edit';
}

interface IState {
    cluster_id: string;
    cluster_label: string;
    name: string;
    price: string;
    bedroom: string
    bathroom: string
    maidroom: string
    maid_bathroom: string
    property_area: string
    land_area: string
    floor: string
}

interface IErrorState {
    cluster_id: string;
    name: string;
    price: string;
    bedroom: string
    bathroom: string
    maidroom: string
    maid_bathroom: string
    property_area: string
    land_area: string
    floor: string
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const ListingDetailForm = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/listing';
    const params: any = useParams();
    const navigate = useNavigate();

    const initialState: IState = {
        cluster_id: '',
        cluster_label: '',
        name: '',
        price: '',
        bedroom: '',
        bathroom: '',
        maidroom: '',
        maid_bathroom: '',
        property_area: '',
        land_area: '',
        floor: '',
    };

    const initialErrorState: IErrorState = {
        cluster_id: '',
        name: '',
        price: '',
        bedroom: '',
        bathroom: '',
        maidroom: '',
        maid_bathroom: '',
        property_area: '',
        land_area: '',
        floor: '',
    };

    const currencyAttributes = [
        'price'
    ];

    const numberAttributes = [
        'property_area',
        'land_area',
    ]

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [isLoading, setIsLoading] = useState(false);
    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);
    const [disableInputs/* , setDisableInputs */] = useState(false);

    useEffect(() => {
        if (params.id) {
            loadData();
        }
        // loadFormData()
        // eslint-disable-next-line
    }, []);

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${params.id}`)
            .then(res => {
                const data = res.data;
                const newInputState: any = { ...initialState };

                for (let key in data) {
                    if (data[key] !== null) {
                        newInputState[key] = data[key].toString();
                    }
                }

                setInputState({ name: '', value: newInputState, type: 'REPLACE_STATE' });
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const name = target.name;
        let value: any = target.value;

        if (currencyAttributes.includes(name)) {
            value = helpers.currencyToNumber(value);
        }
        
        if (numberAttributes.includes(name)) {
            value = inputNumber(value);
        }

        setInputState({ name, value, type: 'SET_ITEM' });
        
        const { errors } = validateOne(name, value, validationRules, validationAlias)

        setErrorState({ name, value: errors[name], type: 'SET_ITEM' });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.cluster_id = String(value.id);

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const handleSubmit = () => {
        const { isValid, errors } = validateData(inputState, validationRules, validationAlias)
        setErrorState({ name: '', value: errors, type: 'REPLACE_STATE' });

        if (!isValid) {
            return;
        }

        let axios;

        if (params.id) {
            axios = DefaultAxios.patch(`${API_URL}/${params.id}`, inputState);
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        if (params.id) {

                        } else {
                            navigate('/listing');
                        }
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            label="Cluster"
                            name="cluster_id"
                            initialQuery={inputState.cluster_label}
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/cluster-detailed`}
                            iconSearch
                            errorText={errorState.cluster_id}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Nama"
                            name="name"
                            value={inputState.name}
                            onChange={handleChanged}
                            error={!!errorState.name}
                            helperText={errorState.name}
                            disabled={disableInputs}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Price"
                            name="price"
                            value={helpers.numberToCurrency(inputState.price)}
                            onChange={handleChanged}
                            error={!!errorState.price}
                            helperText={errorState.price}
                        />
                    </Grid>
                        
                    <Grid item xs={6}>
                        <TextField
                            name="bedroom"
                            select
                            label="Bedroom"
                            value={inputState.bedroom}
                            error={!!errorState.bedroom}
                            helperText={errorState.bedroom}
                            fullWidth
                            onChange={handleChanged}
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                        >
                            <option value="">Pilih</option>
                            {
                                roomsOptions.map(option =>
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                )
                            }
                        </TextField>
                    </Grid>
                        
                    <Grid item xs={6}>
                        <TextField
                            name="bathroom"
                            select
                            label="Bathroom"
                            value={inputState.bathroom}
                            error={!!errorState.bathroom}
                            helperText={errorState.bathroom}
                            fullWidth
                            onChange={handleChanged}
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                        >
                            <option value="">Pilih</option>
                            {
                                roomsOptions.map(option =>
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                )
                            }
                        </TextField>
                    </Grid>
                        
                    <Grid item xs={6}>
                        <TextField
                            name="maidroom"
                            select
                            label="Maid Room"
                            value={inputState.maidroom}
                            error={!!errorState.maidroom}
                            helperText={errorState.maidroom}
                            fullWidth
                            onChange={handleChanged}
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                        >
                            <option value="">Pilih</option>
                            {
                                roomsOptions.map(option =>
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                )
                            }
                        </TextField>
                    </Grid>
                        
                    <Grid item xs={6}>
                        <TextField
                            name="maid_bathroom"
                            select
                            label="Maid Bathroom"
                            value={inputState.maid_bathroom}
                            error={!!errorState.maid_bathroom}
                            helperText={errorState.maid_bathroom}
                            fullWidth
                            onChange={handleChanged}
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                        >
                            <option value="">Pilih</option>
                            {
                                roomsOptions.map(option =>
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                )
                            }
                        </TextField>
                    </Grid>
                        
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Property Area"
                            name="property_area"
                            value={inputNumber(inputState.property_area)}
                            onChange={handleChanged}
                            error={!!errorState.property_area}
                            helperText={errorState.property_area}
                        />
                    </Grid>
                        
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Land Area"
                            name="land_area"
                            value={inputNumber(inputState.land_area)}
                            onChange={handleChanged}
                            error={!!errorState.land_area}
                            helperText={errorState.land_area}
                        />
                    </Grid>
                        
                    <Grid item xs={6}>
                        <TextField
                            name="floor"
                            select
                            label="Floor"
                            value={inputState.floor}
                            error={!!errorState.floor}
                            helperText={errorState.floor}
                            fullWidth
                            onChange={handleChanged}
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                        >
                            <option value="">Pilih</option>
                            {
                                floorOptions.map(floor =>
                                    <option key={floor.toString()} value={floor.toString()}>{floor.toString()}</option>
                                )
                            }
                        </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
}

const validationRules: IValidationRules = {
    cluster_id: 'required',
    name: 'required',
    price: 'required',
    bedroom: 'required',
    bathroom: 'required',
    maidroom: 'required',
    maid_bathroom: 'required',
    property_area: 'required',
    land_area: 'required',
    floor: 'required',
}

const validationAlias: IValidationAlias = {
    cluster_id: 'cluster',
    maidroom: 'maid room',
    maid_bathroom: 'maid bathroom',
    property_area: 'property area',
    land_area: 'land area',
}

const roomsOptions = [
    {
        value: '0',
        label: 'None',
    },
    {
        value: '1',
        label: '1 Room',
    },
    {
        value: '2',
        label: '2 Room',
    },
    {
        value: '3',
        label: '3 Room',
    },
    {
        value: '4',
        label: '4 Room',
    },
    {
        value: '5',
        label: '5 Room',
    },
    {
        value: '6',
        label: '6 Room',
    },
]

const floorOptions = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5]

export default ListingDetailForm;