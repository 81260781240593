import { Grid, Button, Box, styled } from '@mui/material'
import React from 'react'
import background from '../Assets/Images/png/whatsappbackground.png'


/* 
* Icons & Styles
*/
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { grey } from '@mui/material/colors';
import { generateStyle } from '../_utils/DefaultStyles';
const darkgrey = grey['700']


/* 
* Interfaces
*/
interface IProps {

}

const useStyles = generateStyle(theme => ({
    corner: {
        position: 'absolute',
        left: '-10px',
        width: 0,
        height: 0,
        borderTop: '10px solid white',
        borderLeft: '10px solid transparent',
        borderRight: '5px solid transparent'
    }
}), 'Whatsapp-Preview')

const STime = styled('div')({
    padding: '0px 20px 0px 0px',
    marginTop: '-10px',
    marginBottom: '5px',
    textAlign: 'right',
    fontSize: '10pt'
})

const SCallToAction = styled(Grid)({
    display: 'flex',
    position: 'relative',
    width: '100%',
    left: 0,
    right: 0,
    borderTop: "2px solid #eee",
})

const SAttachment = styled('div')({
    margin: '10px',
    backgroundColor: '#eee',
    borderRadius: '10px',
    overflow: 'hidden',
    '& img, & video': {
        width: '100%'
    }
})

const SDocument = styled(Box)({
    display: 'flex',
    width: '100%',
    height: '80px',
    padding: '20px'
})

const initialData = {
    text: "Hello",
    buttons: [
        {
            type: 'phone',
            text: 'Call'
        },
        {
            type: 'link',
            text: 'Go to URL'
        }
    ],
    attachment: {
        // type: 'document',
        // link: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        // type: 'image',
        // link: 'https://images.unsplash.com/photo-1668365187350-05c997d09eba?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
        type: 'video',
        link: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        name: 'Nama Document.docx'

    }
}

const WhatsappPreview = (prop: IProps) => {
    const { Root, classes } = useStyles()

    const generateCallToAction = (button: any) => {
        return (
            button.length > 0 &&
            <SCallToAction flexDirection='column' justifyContent='center'>
                {button.map((data: any) => (
                    <Button
                        size='small'
                        style={{ textTransform: 'initial', }}
                        startIcon={data.type === 'phone' ? <LocalPhoneOutlinedIcon /> : <OpenInNewOutlinedIcon />}
                    >
                        {data.text}
                    </Button>
                ))}
            </SCallToAction>
        )
    }

    const generateAttachment = (attachment: any) => {
        return (
            <>
                <SAttachment>
                    {attachment.type === 'image' ?
                        <Box component='img' src={attachment.link} alt="some image" />
                        : attachment.type === 'video' ?
                            <Box component='video' src={attachment.link} controls /> :
                            <SDocument component={'div'} flexDirection='row' alignItems='center'>
                                <InsertDriveFileIcon fontSize='large' style={{ color: darkgrey }} />
                                <Box component='p' sx={{ ml: 'auto' }}>{attachment.name}</Box>
                            </SDocument>
                    }
                </SAttachment>
            </>
        )
    }

    return (
        <Root>
            <Grid container>
                <Grid item xs={12} style={{ background: `url(${background})`, padding: '20px 40px 20px 20px' }}>
                    <Box sx={{ filter: 'drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.3))', position: 'relative', maxWidth: '600px' }} component="div">
                        <div className={classes.corner} />
                        <Box sx={{
                            overflow: 'auto',
                            borderRadius: '5px',
                            background: 'white',
                        }} component="div">
                            {generateAttachment(initialData.attachment)}
                            <Box sx={{
                                padding: '20px 20px 5px',
                                position: 'relative'
                            }} dangerouslySetInnerHTML={{ __html: initialData.text as string }} component="div">
                            </Box>
                            <STime>
                                09:09
                            </STime>
                            {generateCallToAction(initialData.buttons)}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Root>
    )
}

export default WhatsappPreview