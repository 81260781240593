import React, { Fragment, useRef, useState, useContext } from 'react';
import { IconButton, Grid, FormControlLabel, TextField, MenuItem, Switch, Button, Tooltip, useMediaQuery, Theme, BottomNavigation, BottomNavigationAction, useTheme } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';
import DeleteActivityModal from './_components/DeleteActivityModal';
import ImagePreview from '../../_components/ImagePreview';
import ActivityDetail from './ActivityDetail';
import AppDataTable, { AppDataTablePageStyle } from '../../_components/_dataTable/AppDataTable';
import WhatsappPreviewModal, { IChats } from '../_lead/_components/WhatsappPreviewModal';

/**
 * Context
 */
import { PermissionContext } from "../../_contexts/PermissionContext";

/**
 * Utils
 */
import helpers from '../../_utils/Helpers';
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper';
import { leadSources } from '../../_utils/ConstData';

/**
 * Icons
 */
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoIcon from '@mui/icons-material/Photo';
import MapIcon from '@mui/icons-material/Map';
import GetAppIcon from '@mui/icons-material/GetApp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { generateStyle } from '../../_utils/DefaultStyles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

interface ActivityListProps {
    isApp?: boolean
}

const useStyles = generateStyle(theme => ({
    actionContainer: {
        display: 'flex'
    },
    filter: {
        width: 'auto',
        height: '30px',
        display: 'inline-flex',
        borderRadius: '4px',
        margin: '5px',
        fontSize: '0.8125rem',
        backgroundColor: '#009be5',
    },
    filterButton: {
        '&$selected': {
            color: 'red',
        },
        flex: 'none',
        color: '#fff',
        fontSize: '0.8125rem',
        padding: '6px 10px',
        minWidth: '50px',
        whiteSpace: 'nowrap'
    },
    filterButtonSelected: {
        color: '#fff',
        borderRadius: '4px',
        backgroundColor: '#006db3',
        fontSize: '0.8125rem',
    },
    filterButtonLabel: {
        fontSize: '0.8125rem',
    },
    appCustomFilter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    customFilter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginBottom: 8,
    }
}), "Activity_List"
)

export const activityTypes = [
    {
        key: 'showing',
        value: 'Showing'
    },
];

const initialChatsData = {
    chats: [],
    code: '',
    created_at: ''
}

const ActivityList = (props: ActivityListProps) => {
    const API_URL = process.env.REACT_APP_API_URL + '/activity';
    const permissions = useContext(PermissionContext);
    const { Root, classes } = useStyles();

    const [moreDetail, setMoreDetail] = useState(false);
    const [selectedActivityId, setSelectedActivityId] = useState('')
    const [modalDetailOpen, setModalDetailOpen] = useState(false)

    const [chatsData, setChatsData] = useState<{
        chats: IChats[],
        code: string | null,
        created_at: string
    }>(initialChatsData)

    const [selectedImage, setSelectedImage] = useState('')
    const theme = useTheme<Theme>()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))


    const [filter, setFilter] = useState({
        telesales_filter: '',
        closing_filter: ''
    })

    const handleClickExport = () => {
        DefaultAxios.get(
            API_URL + `/export/activity.xlsx${window.location.search}`,
            {
                responseType: 'blob'
            }
        )
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `activity.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
    }

    const handleWhatsappPreview = (row: any) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/wa-chats/lead`, { params: { id: row.lead_id } })
            .then(res => res.data)
            .then(data => {
                setChatsData({
                    chats: data.data,
                    code: row.code,
                    created_at: row.created_at
                })
            })
            .catch(err => generalErrorHandler(err))
    }

    const handleDetail = (id: string) => {
        setSelectedActivityId(id)
        setModalDetailOpen(true)
    }

    const handleChangeIsOnline = (id: string) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value
            renderWarningButton('Are you sure to change online status ?')
                .then(res => {
                    if (res.value) {
                        setIsLoading(true)
                        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/activity/change-is-online`, {
                            id: id,
                            is_online: value === '-' ? null : value
                        })
                            .then(res => res.data)
                            .then(res => {
                                if (res) {
                                    renderToastSuccess('Online status changed!')
                                    setReDrawDT(new Date().getTime())
                                }
                            })
                            .catch(err => generalErrorHandler(err))
                            .finally(() => setIsLoading(false))
                    }
                })
        }
    }

    const columns = [
        {
            name: 'created_at',
            label: 'Created At',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return helpers.dateFormat(data, 'DD MMM YYYY HH:mm');
            }
        },
        {
            name: 'datetime',
            label: 'Date',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return helpers.dateFormat(data, 'DD MMM YYYY HH:mm');
            }
        },
        {
            name: 'code',
            label: 'Code',
            type: 'string',
            filterable: true,
            render: (data: any, row: any) => {
                return (
                    <span onClick={() => handleDetail(row.id)} style={{ cursor: 'pointer', color: 'rgb(0, 86, 168)' }}>{data || '-'}</span>
                )
            }
        }, ...(
            permissions['whatsapp.manage'] ?
                [{
                    name: 'wa_id',
                    label: 'Whatsapp Preview',
                    type: 'string',
                    filterable: true,
                    options: [
                        {
                            key: '',
                            value: 'All',
                        },
                        {
                            key: 1,
                            value: 'Yes',
                        },
                        {
                            key: 0,
                            value: 'No',
                        },
                    ],
                    render: (data: any, row: any) => {
                        if (data) {
                            return (
                                <IconButton aria-label="preview chat" title='Preview Chat' onClick={() => handleWhatsappPreview(row)} color='primary'>
                                    <WhatsAppIcon />
                                </IconButton>
                            )

                        } else {
                            return '-'
                        }
                    }
                }]
                : []
        ),
        {
            name: 'type',
            label: 'Type',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...activityTypes
            ],
            filterable: false,
            render: (data: any) => {
                return activityTypes.find(activityType => activityType.key === data)?.value;
            }
        },
        {
            name: 'is_done',
            label: 'Done',
            options: [
                { key: '', value: 'All' },
                { key: '1', value: "Yes" },
                { key: '0', value: 'No' },
            ],
            filterable: true,
            render: (data: any) => {
                return (
                    data === 0 ? 'No' : 'Yes'
                )
            }
        },
        {
            name: 'is_online',
            label: 'Online',
            options: [
                { key: '', value: 'All' },
                { key: '1', value: "Yes" },
                { key: '0', value: 'No' },
                { key: '2', value: "Undefined" },
            ],
            filterable: true,
            render: (data: any, rows: any) => {
                return (
                    permissions['activity.change-is-online'] ?
                        <TextField
                            size='small'
                            onChange={handleChangeIsOnline(rows.id)}
                            select
                            value={data !== null ? data : '-'}
                        >
                            <MenuItem value={'-'}>Not Set</MenuItem>
                            <MenuItem value={0} >No</MenuItem>
                            <MenuItem value={1} >Yes</MenuItem>
                        </TextField>
                        :
                        <span>
                            {data === null ? '-' : data === 0 ? 'No' : 'Yes'}
                        </span>
                )
            }
        },
        {
            name: 'done_at',
            label: 'Done At',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                if (data) {
                    return helpers.dateFormat(data, 'DD MMM YYYY HH:mm');
                }

                return '-'
            }
        },
        {
            name: 'client_name',
            label: 'Nama',
            type: 'string',
            filterable: true
        },
        {
            name: 'client_phone',
            label: 'No Hp',
            type: 'string',
            filterable: true
        },
        {
            name: 'cluster_name',
            label: 'Cluster',
            type: 'string',
            filterable: true
        },
        {
            name: 'cluster_closing_name',
            label: 'Cluster Closing',
            type: 'string',
            filterable: true,
            columnStyle: {
                minWidth: '200px'
            },
            render: (data: any, row: any) => {
                return (
                    permissions['closing.view'] && data ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>{data}</span>
                            <IconButton
                                onClick={() => { window.open(`/closing/${row.closing_id}`) }}
                            >
                                <VisibilityIcon fontSize='small' color='primary' />
                            </IconButton>
                        </div> :
                        <span>{data}</span>
                )
            }
        },
        {
            name: 'agent_name',
            label: 'Agent',
            type: 'string',
            filterable: true
        },
        {
            name: 'telesales_name',
            label: 'Telesales',
            type: 'string',
            filterable: true
        },
        {
            name: 'notes',
            label: 'Notes',
            type: 'string',
            filterable: false
        },
        ...(
            moreDetail ? [
                {
                    name: 'source_label',
                    label: 'Source',
                    type: 'string',
                    defaultOption: '',
                    options: [
                        { key: '', value: 'All' },
                        ...leadSources
                    ],
                    filterable: true
                },
                {
                    name: 'utm_source',
                    label: 'UTM Source',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_medium',
                    label: 'UTM Medium',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_campaign',
                    label: 'UTM Campaign',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_content',
                    label: 'UTM Content',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'keyword',
                    label: 'Keyword',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'adsetname',
                    label: 'Adsetname',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'adgroupid',
                    label: 'Adgroupid',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'creative',
                    label: 'Creative',
                    type: 'string',
                    filterable: true
                },
            ] : []
        ),
        ...(
            permissions['activity.delete']
                ? [
                    {
                        name: 'EXTRA',
                        label: 'Action',
                        type: 'string',
                        filterable: false,
                        sortable: false,
                        render: (row: any) => {
                            return (
                                <div className={classes.actionContainer}>
                                    {
                                        row.photo_url &&
                                        <Tooltip title='Photo'>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() => setSelectedImage(row.photo_url)}
                                            >
                                                <PhotoIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {
                                        (row.latitude && row.longitude) &&
                                        <Tooltip title='Location'>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() => window.open(`https://www.google.com.sa/maps/search/${row.latitude}, ${row.longitude}`, '_blank')}
                                            >
                                                <MapIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    <Tooltip title='Delete'>
                                        <IconButton
                                            color="secondary"
                                            component="span"
                                            onClick={() => setDeletedId(row.id)}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            );
                        }
                    }
                ]
                : []
        )
    ];

    const inputDeleteReason = useRef<HTMLInputElement>(null);
    const [deletedId, setDeletedId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [reDrawDT, setReDrawDT] = useState(new Date().getTime());

    const handleDelete = () => {
        const reason = inputDeleteReason!.current!.value;

        if (!reason) {
            return;
        }

        setIsLoading(true);
        DefaultAxios
            .post(`${API_URL}/${deletedId}/delete`, { reason })
            .then(res => {
                Swal.fire({
                    title: "Activity berhasil dihapus",
                    icon: 'success',
                    timer: 1000
                })
                    .then(() => {
                        setReDrawDT(new Date().getTime());
                        setDeletedId('');
                    })
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const renderTelesalesFilter = (
        <BottomNavigation
            value={filter.telesales_filter}
            onChange={(event, newValue) => {
                setFilter(prev => ({
                    ...prev,
                    telesales_filter: newValue,
                }))
            }}
            showLabels
            className={classes.filter}
        >
            <BottomNavigationAction
                label="All"
                value={''}
                classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }}
            />
            <BottomNavigationAction
                label="Telesales"
                value={'1'}
                classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }}
            />
            <BottomNavigationAction
                label="Non Telesales"
                value={'2'}
                classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }}
            />
        </BottomNavigation>
    )

    const renderClosingFilter = (
        <BottomNavigation
            value={filter.closing_filter}
            onChange={(event, newValue) => {
                setFilter(prev => ({
                    ...prev,
                    closing_filter: newValue,
                }))
            }}
            showLabels
            className={classes.filter}
        >
            <BottomNavigationAction
                label="All"
                value={''}
                classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }}
            />
            <BottomNavigationAction
                label="Closing"
                value={'1'}
                classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }}
            />
            <BottomNavigationAction
                label="Non Closing"
                value={'0'}
                classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }}
            />
        </BottomNavigation>
    )

    const renderMoreDetailToggle = (
        permissions['activity.more-detail'] &&
        <FormControlLabel
            control={
                <Switch
                    checked={moreDetail}
                    onChange={() => { setMoreDetail(!moreDetail) }}
                    color="primary"
                />
            }
            label="More Detail"
        />
    )

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <ImagePreview
                image={selectedImage}
                onClose={() => setSelectedImage('')}
            />
            <ActivityDetail
                open={modalDetailOpen}
                onClose={() => setModalDetailOpen(false)}
                activityId={selectedActivityId}
                isMobile={isMobile}
            />
            <DeleteActivityModal
                open={deletedId !== ''}
                onClose={() => setDeletedId('')}
                inputRef={inputDeleteReason}
                onDeleteActivity={handleDelete}
            />
            <WhatsappPreviewModal
                open={!!chatsData.chats.length}
                chats={chatsData.chats}
                code={chatsData.code}
                created_at={chatsData.created_at}
                onClose={() => {
                    setChatsData(initialChatsData)
                }}
            />
            {
                props.isApp ?
                    <div style={AppDataTablePageStyle}>
                        <AppDataTable
                            url={API_URL}
                            columns={columns}
                            reDraw={reDrawDT}
                            customFilterComponent={(
                                <div className={classes.appCustomFilter}>
                                    <div>
                                        {renderTelesalesFilter}
                                        {renderClosingFilter}
                                    </div>
                                    <div>
                                        {renderMoreDetailToggle}
                                    </div>
                                </div>
                            )}
                            customParams={filter}
                        />
                    </div>
                    :
                    <>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                {
                                    (permissions['activity.export'] || permissions['activity.export-telesales']) &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        style={{ marginBottom: '20px' }}
                                        startIcon={<GetAppIcon />}
                                        onClick={handleClickExport}
                                    >
                                        Export
                                    </Button>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.customFilter}>
                                    <div>
                                        {renderTelesalesFilter}
                                        {renderClosingFilter}
                                    </div>
                                    <div>
                                        {renderMoreDetailToggle}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <DataTable
                            url={API_URL}
                            columns={columns}
                            reDraw={reDrawDT}
                            customParams={filter}
                        />
                    </>
            }
        </Root>
    );
}

export default ActivityList;