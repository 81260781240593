import { Button, TextField } from '@mui/material'
import React from 'react'
import Swal from 'sweetalert2'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../../_utils/Helper'

interface ForceAppVersionProps {
    value: string
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    loadData: () => void
    setIsLoading: (value: boolean) => void
}

const ForceAppVersion = (props: ForceAppVersionProps) => {
    const saveAppVersion = () => {
        props.setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/site-setting/force-app-version`, { value: props.value || '' })
            .then(res => res.data)
            .then(data => {
                Swal.fire({
                    title: "Berhasil menyimpan",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        props.loadData();
                    })
            })
            .catch(generalErrorHandler)
            .finally(() => {
                props.setIsLoading(false)
            })
    }

    return (
        <>
            <TextField
                value={props.value}
                onChange={props.handleChange}
                variant='outlined'
                size='small'
            />
            <Button
                variant='contained'
                color='primary'
                style={{marginLeft: 8}}
                onClick={saveAppVersion}
            >
                Submit
            </Button>
        </>
    )
}

export default ForceAppVersion
