import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Dialog, DialogTitle, DialogContent, Grid, IconButton, useTheme, Paper, Button } from '@mui/material'
import WhatsappPreview from './WhatsappPreview'
import { Theme, useMediaQuery } from '@mui/material';
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../../_utils/Helper';
import { PermissionContext } from "../../../_contexts/PermissionContext";

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { generateStyle } from '../../../_utils/DefaultStyles';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material';

type WhatsappPagination = {
    hasNext: boolean
    hasPrev: boolean
}

export type ChatsData = {
    chats: IChats[]
    code: string | null
    created_at: string
    prev_id: null | number
    next_id: null | number
}

export const INITIAL_CHATS_DATA = {
    chats: [],
    code: '',
    created_at: '',
    prev_id: null,
    next_id: null,
}

interface IProps {
    open: boolean,
    chats?: IChats[]
    code: string | null
    onClose: () => void
    created_at: string
    pagination?: WhatsappPagination
    onNextPressed?: () => void
    onPrevPressed?: () => void
}

export interface IChats {
    id: number
    chat_id: string
    whatsapp_id: number
    from_agent: number
    replied_id: string | null
    replied_text: string | null
    type: string
    text: string
    datetime: string
    file_id: number | null
    file_url: string | null
    file_type: string | null
}

const WhatsappPreviewModal = (props: IProps) => {
    const { Root, classes } = useStyles()
    const [chats, setChats] = useState<IChats[]>([])
    const theme: Theme = useTheme()
    const permissions = useContext(PermissionContext);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDownload = () => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/wa-download`, {
            params: { wa_id: chats[0].whatsapp_id },
            responseType: 'blob'
        })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `whatsapp_chat.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => generalErrorHandler(err))
    }


    useEffect(() => {
        if (props.open) {
            if (props.chats && props.chats.length) {
                setChats(props.chats)
            }

            window.addEventListener('keydown', handleKeyDown)
        } else {
            window.removeEventListener('keydown', handleKeyDown)
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
        // eslint-disable-next-line
    }, [props.open, props.chats])

    const handleKeyDown = useCallback((e: any) => {
        const event = e as React.KeyboardEvent

        switch (event.key) {
            case 'ArrowRight':
                if (props.onNextPressed) {
                    props.onNextPressed()
                }
                break
            case 'ArrowLeft':
                if (props.onPrevPressed) {
                    props.onPrevPressed()
                }
                break
        }
        // eslint-disable-next-line
    }, [props.onPrevPressed, props.onNextPressed])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="sm"
            fullWidth
            fullScreen={isMobile}
        >
            <Root>
                {
                    props.pagination ?
                        <>
                            {
                                props.pagination.hasPrev ?
                                    <div
                                        className={classes.arrowDesktop}
                                        style={{
                                            left: 'calc(50vw - 380px)',
                                        }}
                                    >
                                        <IconButton
                                            onClick={props.onPrevPressed}
                                            style={{
                                                fontSize: 64,
                                            }}
                                        >
                                            <ArrowCircleLeft htmlColor='white' fontSize='inherit' />
                                        </IconButton>
                                    </div>
                                    : null
                            }
                            {
                                props.pagination.hasNext ?
                                    <div
                                        className={classes.arrowDesktop}
                                        style={{
                                            left: 'calc(50vw + 300px)',
                                        }}
                                    >
                                        <IconButton
                                            onClick={props.onNextPressed}
                                            style={{
                                                fontSize: 64,
                                            }}
                                        >
                                            <ArrowCircleRight htmlColor='white' fontSize='inherit' />
                                        </IconButton>
                                    </div>
                                    : null
                            }
                            {
                                (props.pagination.hasPrev || props.pagination.hasNext) ?
                                    <Paper className={classes.mobilePagination} elevation={5}>
                                        {
                                            props.pagination.hasPrev ?
                                                <Button
                                                    variant='contained'
                                                    fullWidth
                                                    onClick={props.onPrevPressed}
                                                    sx={{
                                                        marginRight: 'auto',
                                                        maxWidth: '40vw',
                                                    }}
                                                >
                                                    Prev
                                                </Button>
                                                : null
                                        }
                                        {
                                            props.pagination.hasNext ?
                                                <Button
                                                    variant='contained'
                                                    fullWidth
                                                    onClick={props.onNextPressed}
                                                    sx={{
                                                        marginLeft: 'auto',
                                                        maxWidth: '40vw',
                                                    }}
                                                >
                                                    Next
                                                </Button>
                                                : null
                                        }
                                    </Paper>
                                    : null
                            }
                        </>
                        : null
                }
                <DialogTitle className={classes.titleContainer}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span>
                                {props.code ? `${props.code} - ` : ''}Whatsapp Preview
                            </span>
                            {
                                props.created_at ?
                                    <em style={{ fontSize: 14 }}>Created At : {format(new Date(props.created_at), "dd MMMM yyyy HH:mm:ss", { locale: id })}</em>
                                    : null
                            }
                        </div>
                        {
                            permissions['whatsapp.download'] &&
                            <IconButton aria-label="download chat" title='Download Chat' onClick={handleDownload} color='primary' style={{ marginLeft: 5 }}>
                                <DownloadIcon />
                            </IconButton>
                        }
                    </div>
                    <IconButton
                        className={classes.close}
                        onClick={props.onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    <Grid container className={classes.container}>
                        <Grid item xs={12}>
                            <WhatsappPreview data={chats} />
                            {
                                (props.pagination && (props.pagination.hasPrev || props.pagination.hasNext)) ?
                                    <div className={classes.mobilePaginationSpacing} />
                                    : null
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
            </Root>
        </Dialog>
    )
}


const useStyles = generateStyle(theme => ({
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    container: {
        width: '100%',
        minHeight: '100%',
    },
    close: {
        marginLeft: 'auto',
        padding: 0
    },
    arrowDesktop: {
        position: 'fixed',
        top: '50%',
        transform: 'translateY(-50%)',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    mobilePagination: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        height: 54,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 30,
        paddingRight: 30,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    mobilePaginationSpacing: {
        height: 54,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        }
    },
}), "WhatsappPreview_Modal")

export default WhatsappPreviewModal