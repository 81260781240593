import React, { useContext, useState } from 'react'

/* 
* Components
*/
import DataTable from '../../_components/_dataTable/DataTable';
import { Box, Button, Grid } from '@mui/material';
import LoadingScreen from '../../_components/LoadingScreen';
import AddPinjamanModal from './AddPinjamanModal';
import MiniButtonFilter from '../../_components/MiniButtonFilter';

/* 
* Utils
*/
import { converNumberFormat, download, generalErrorHandler, renderToastSuccess } from '../../_utils/Helper';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { format } from 'date-fns';


const ListPinjaman = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/closing-loan/outstanding';
    const permissions = useContext(PermissionContext)
    const [isDownloadLoading, setIsDownloadLoading] = useState(false)
    const [reDrawDatatable, setReDrawDatatable] = useState(0)

    const [isPaid, setIsPaid] = useState('')

    const [isModalAddOpen, setIsModalAddOpen] = useState(false)

    const handleExport = () => {
        setIsDownloadLoading(true)

        const params = window.location.search

        download(
            `${process.env.REACT_APP_API_URL}/closing-loan/outstanding/export${params}`,
            'list-pinjaman.xlsx',
            () => {
                renderToastSuccess('Berhasil export data')
                setIsDownloadLoading(false);
            },
            err => {
                generalErrorHandler(err)
                setIsDownloadLoading(false);
            }
        )
    }

    const columns = [
        {
            name: 'agent_name',
            label: 'Agent Name',
            type: 'string',
            filterable: true,
            sortable: true,
        },
        {
            name: 'closing_code',
            label: 'Closing Code',
            type: 'string',
            filterable: true,
            sortable: true,
        },
        {
            name: 'nominal',
            label: 'Nominal',
            type: 'number',
            filterable: true,
            sortable: true,
            render: (row: any) => {
                return `Rp. ${converNumberFormat(row)}`
            }
        },
        {
            name: 'type_label',
            label: 'Type',
            type: 'string',
            filterable: true,
            options: [
                {
                    key: '',
                    value: 'All',
                },
                {
                    key: 'commission_loan',
                    value: 'Pinjaman Komisi',
                },
                {
                    key: 'compliment_client',
                    value: 'Compliment Client',
                },
                {
                    key: 'contract_loan',
                    value: 'Pinjaman Akad',
                },
                {
                    key: 'other',
                    value: 'Lainnya',
                },
            ],
        },
        {
            name: 'date',
            label: 'Tanggal Pencairan',
            type: 'date',
            filterable: true,
            sortable: true,
            render: (data: any) => {
                return data ? format(new Date(data), 'dd/MM/yyyy') : '-'
            }
        },
        {
            name: 'paid_at',
            label: 'Tanggal Pelunasan',
            type: 'date',
            filterable: true,
            sortable: true,
            render: (data: any) => {
                return data ? format(new Date(data), 'dd/MM/yyyy') : '-'
            }
        },
    ];

    return (
        <>
            <LoadingScreen open={isDownloadLoading} fullScreen />
            <AddPinjamanModal
                open={isModalAddOpen}
                onClose={() => {
                    setIsModalAddOpen(false)
                }}
                onSuccess={() => {
                    setReDrawDatatable(prev => prev + 1)
                    setIsModalAddOpen(false)
                }}
            />
            <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {
                            permissions['closing-loan.create'] ?
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setIsModalAddOpen(true)}
                                    sx={{
                                        mb: 2,
                                        mr: 'auto',
                                    }}
                                >
                                    Add Data
                                </Button>
                                : null
                        }
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleExport}
                            sx={{
                                mb: 2,
                                ml: 'auto',
                            }}
                        >
                            Export
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <MiniButtonFilter
                        value={isPaid}
                        onChange={setIsPaid}
                        items={[
                            {
                                value: '',
                                label: 'All'
                            },
                            {
                                value: '1',
                                label: 'Paid'
                            },
                            {
                                value: '0',
                                label: 'Non-Paid'
                            },
                        ]}
                    />
                </Grid>
            </Grid>
            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
                customParams={{
                    is_paid: isPaid === '' ? null : isPaid,
                }}
            />
        </>
    )
}

export default ListPinjaman