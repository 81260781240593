// default
import React from 'react'
import { Card, CardContent, CardHeader, Typography, Button, IconButton, Grid } from '@mui/material';

// icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { generateStyle } from '../../../_utils/DefaultStyles';

// interfaces
interface IProps {
    index: number;
    group: IGroups;
    onAddContent: (index: number) => void;
    onDelete: (index: number) => void;
    onDeleteContent: (indexCard: number, index:number) => void;
    onEdit: (index: number) => void;
    onEditContent: (indexCard: number, index:number) => void;
}

interface IPropsHeader {
    onEdit: () => void
    onDelete: () => void
}

interface IGroups {
    label: string;
    contents: IContent[]
}

interface IContent {
    type: string;
    content: string | IContentSmall
}

type IContentSmall = { interest: string; label: string }

const useStyles = generateStyle(theme => ({
    groupCard: {
        marginBottom: "10px"
    },
    cardHeader: {
        borderBottom: "1px solid #eee"
    }
}), "GroupCard", {
    CustomParent: Card
})

const CustomCardHeader = (props: IPropsHeader) => {
    return (
        <>
            <IconButton
                size='small'
                onClick={props.onEdit}>
                <EditIcon fontSize='small' />
            </IconButton>
            <IconButton color='error'
                size='small'
                onClick={props.onDelete}>
                <DeleteIcon fontSize='small' />
            </IconButton>
        </>
    )
}

const GroupCard = (props: IProps) => {
    const {Root: SCard, classes} = useStyles()

    const createContent = (
        { type, content }: { type: string, content: string | IContentSmall }, 
        key: number, 
        onDelete: (index: number) => void, 
        onEdit: (index: number) => void
        ) => {
        if (type === 'point') {
            return (
                <Card variant="outlined" sx={{ mb: 3 }} key={key}>
                    <CardHeader
                        className={classes.cardHeader}
                        title={<Typography variant='h6'>Point</Typography>}
                        action={
                            <CustomCardHeader
                                onDelete={() => onDelete(key)}
                                onEdit={() => onEdit(key)}
                            />
                        }
                    />
                    <CardContent>
                        <Grid container>
                            {typeof content === 'object' &&
                                <>
                                    <Grid item xs={4} style={{fontWeight : 700}}>Interest</Grid>
                                    <Grid item xs={8}>: {content.interest}</Grid>
                                    <Grid item xs={4} style={{fontWeight : 700}}>Label</Grid>
                                    <Grid item xs={8}>: {content.label}</Grid>
                                </>
                            }
                        </Grid>
                    </CardContent>
                </Card >
            )
        }
        else {
            return (
                <Card variant="outlined" sx={{ mb: 3 }}>
                    <CardHeader
                        className={classes.cardHeader}
                        title={<Typography variant='h6'>FreeText</Typography>}
                        action={
                            <CustomCardHeader
                                onDelete={() => onDelete(key)}
                                onEdit={() => onEdit(key)}
                            />
                        }
                    />
                    <CardContent>
                        <Grid item xs={12}>
                            <div dangerouslySetInnerHTML={{ __html: content as string }}></div>
                        </Grid>
                    </CardContent>
                </Card>
            )
        }
    }

    return (
        <SCard variant="outlined" key={props.index} className={classes.groupCard}>
            <CardHeader
                className={classes.cardHeader}
                title={<Typography variant='h6'>{props.group.label}</Typography>}
                action={
                    <CustomCardHeader
                        onDelete={() => props.onDelete(props.index)}
                        onEdit={() => props.onEdit(props.index)}
                    />
                }
            />
            <CardContent>
                {
                    props.group.contents.length > 0 &&
                    props.group.contents.map((data, index) => {
                        return createContent(data, index, 
                            (index) => props.onDeleteContent(props.index, index),
                            (index) => props.onEditContent(props.index, index))
                    })
                }
                <Button variant='contained'
                    onClick={() => props.onAddContent(props.index)}
                >Add Content</Button>
            </CardContent>
        </SCard>
    )
}

export default GroupCard