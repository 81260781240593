import React, { useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { Grid, ButtonGroup, Button, Theme } from '@mui/material';

/**
 * Components
 */
import DistrictDetailForm from './DistrictDetailForm';
import FAQForm from '../../_components/_form/FAQForm';
import ReviewForm from '../../_components/_form/ReviewForm';

/**
 * Utils
 */
import { SnackbarProvider } from 'notistack'
import ArticleForm from '../../_components/_form/ArticleForm';
import { generateStyle } from '../../_utils/DefaultStyles';
interface Props {
    permissions?: any;
}

const useStyles = generateStyle((theme: Theme) => ({
    success: {
        backgroundColor: '#033a11'
    },
    error: {
        backgroundColor: '#c62828'
    },
    borderBottomLine: {
        borderBottom: '1px solid #eee'
    },
    fab: {
        position: 'fixed',
        bottom: '2rem',
        right: '2rem',
        display: 'none',
        zIndex: 1,
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    container: {
        paddingBottom: '50px'
    }
}), "District_Form"
);

const DistrictForm = (props: Props) => {
    const { Root, classes } = useStyles();
    const location = useLocation();
    const params: any = useParams();
    const navigate = useNavigate();

    const [type, setType] = useState(typeof params.type !== 'undefined' ? params.type : 'details');

    const renderForm = () => {
        if (params.id) {
            switch (type) {
                case 'details':
                    return <DistrictDetailForm />
                case 'faq':
                    return <FAQForm id={params.id} type="district" />
                case 'article':
                    return <ArticleForm id={params.id} type="district" />
                case 'review':
                    return <ReviewForm id={params.id} type="district" />
            }
        } else {
            return <DistrictDetailForm />
        }
    }

    const handleNavigation = (route: string) => {
        if (route !== type) {
            if (route === 'details') {
                navigate(`/district/edit/${params.id}`);
            } else {
                navigate(`/district/edit/${params.id}/${route}`);
            }
            setType(route);
        }
    }

    return (
        <Root>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                autoHideDuration={1000}
                disableWindowBlurListener
                hideIconVariant
                classes={{
                    variantSuccess: classes.success,
                    variantError: classes.error
                }}
                onEntered={() => {
                    window.navigator.vibrate([200, 100, 200])
                }}
            >
                <Grid container spacing={3} className={classes.container}>
                    <Grid item xs={12}>
                        <h1 className={classes.borderBottomLine}>District - {location.pathname === '/district/add' ? 'Add' : 'EDIT'}</h1>
                    </Grid>

                    {
                        location.pathname !== '/district/add'
                            ? <Grid item xs={12}>
                                <ButtonGroup
                                    color="primary"
                                    aria-label="outlined primary button group"
                                    fullWidth
                                >
                                    <Button
                                        variant={type === 'details' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('details')}
                                    >
                                        Details
                                    </Button>
                                    <Button
                                        variant={type === 'article' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('article')}
                                    >
                                        Article
                                    </Button>
                                    <Button
                                        variant={type === 'faq' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('faq')}
                                    >
                                        FAQ
                                    </Button>
                                    <Button
                                        variant={type === 'review' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('review')}
                                    >
                                        Review
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                            : null
                    }

                    {renderForm()}
                </Grid>
            </SnackbarProvider>
        </Root>
    );
}

export default DistrictForm;