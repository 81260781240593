
import {
  Tooltip,
  styled
} from '@mui/material';

const LightTooltip = styled(Tooltip)({
  tooltip: {
    fontSize: 14,
  },
})

export default LightTooltip;

