import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import helpers from '../../_utils/Helpers';
import DefaultAxios from '../../_utils/DefaultAxios';
import { IValidationErrors, validateData } from '../../_utils/Validation';
import { generalErrorHandler } from '../../_utils/Helper';

interface Props {
    open: boolean;
    onClose: (fetch: boolean) => void;
    groupId?: number
}

interface IState {
    id: string;
    name: string
    telesales_id: string
    telesales_label: string
}

const LeadGroupForm = ({ open, onClose, groupId }: Props) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/lead-group`;

    const [state, setState] = useState<IState>(initialState)
    const [error, setError] = useState<IValidationErrors>({})

    const [groupName, setGroupName] = useState('')

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setState(initialState)
        setError({})

        if (!open) {
            setState(initialState)
            setError({})
            setGroupName('')
        } else {
            if (groupId) {
                loadData();
            }
        }
        // eslint-disable-next-line
    }, [open])

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${groupId}`)
            .then(res => {
                const newState = { ...initialState, ...res.data };
                setState({ ...newState, clusters: res.data.cluster_array.map((cluster: any) => ({ id: cluster.id, label: cluster.name })), telesales_label: res.data.telesales_name || '' })
                setGroupName(newState.name)
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target;
        let name = target.name;
        let value = target.value;

        setState(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleSubmit = () => {
        const { isValid, errors } = validateData(state, rules)
        setError(errors)

        if (isValid) {
            let axios;

            const data = {
                name: state.name,
                // cluster_ids: state.clusters.map(cluster => cluster.id)
                telesales_id: state.telesales_id,
            }

            if (state.id) {
                axios = DefaultAxios.patch(`${API_URL}/${state.id}`, data)
            } else {
                axios = DefaultAxios.post(API_URL, data);
            }

            setIsLoading(true);
            axios
                .then(res => {
                    Swal.fire({
                        title: "Submit berhasil",
                        icon: 'success',
                        onAfterClose: () => {
                            onClose(true);
                        },
                        timer: 1000
                    })
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            fullWidth
            maxWidth="sm"
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>{groupId ? `Group - ${groupName || ''}` : 'Add Group'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginTop: 4 }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Name"
                            name="name"
                            value={state.name}
                            onChange={handleChange}
                            error={!!error.name}
                            helperText={error.name}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const initialState = {
    id: '',
    name: '',
    telesales_id: '',
    telesales_label: '',
    // clusters: [],
};

const rules = {
    name: 'required',
    // clusters: 'required|not-empty'
}

export default LeadGroupForm;