import React, { useContext, useState } from 'react';
import { Button, Switch, IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';

/**
 * Context
 */
import { PermissionContext } from "../../_contexts/PermissionContext";

/**
 * Icons
 */
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import helpers from '../../_utils/Helpers';
import { renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyles';

const useStyles = generateStyle((theme) => ({
    actionContainer: {
        display: 'flex'
    },
    addButton: {
        marginBottom: '20px'
    },
    importButton: {
        marginBottom: '20px'
    },
    errorContainer: {
        maxHeight: '200px',
        overflowY: 'auto'
    }
}), "ProjectList2"
)

const ProjectList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/project';
    const permissions = useContext(PermissionContext);
    const { Root, classes } = useStyles();
    const navigate = useNavigate();

    const columns = [
        {
            name: 'name',
            label: 'Nama',
            type: 'string',
            filterable: true
        },
        {
            name: 'is_apartment_project',
            label: 'Is Apartment',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return +data ? 'Yes' : 'No';
            }
        },
        {
            name: 'is_published',
            label: 'Is Published',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return permissions['project.publish']
                    ? <Switch
                        checked={data === 1}
                        onChange={() => handlePublishSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                    : (data === 1 ? 'Yes' : 'No')
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            sortable: false,
            render: (row: any) => {
                // const checkPermission = function (permission_type: string) {
                //     return typeof permissions[permission_type] !== "undefined" && permissions[permission_type]
                // }

                return (
                    <div className={classes.actionContainer}>
                        <IconButton
                            color="primary"
                            component="span"
                            onClick={() => navigate(`/project/edit/${row.id}`)}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>

                        <Tooltip title="See Preview">
                            <IconButton
                                size="small"
                                color="primary"
                                onClick={() => handlePreview(row.id)}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>

                        {
                            permissions['project.delete'] ?
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => handleDelete(row.id)}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton> :
                                <></>
                        }
                    </div>
                );
            }
        }
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const handlePreview = (id: string) => {
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/generate-preview-redis`, { type: 'project', id })
            .then(res => {
                window.open(`${process.env.REACT_APP_MAIN_URL}/preview/project/${id}`);
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
    }

    const handleDelete = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status ini?")
            .then((result) => {
                console.log(result)
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .delete(`${process.env.REACT_APP_API_URL}/project/${id}`)
                        .then(res => {
                            Swal.fire({
                                title: 'Delete successful',
                                icon: 'success',
                                onAfterClose: () => {
                                    setReDrawDatatable(new Date().getTime());
                                },
                                timer: 1000
                            })
                        })
                        .catch(error => {
                            helpers.generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })

    }

    const handlePublishSwitch = (project: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/toggle-publish`, { id: project.id })
                        .then(res => {
                            const checked = res.data;
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +project.id) element['is_published'] = checked ? 1 : 0
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(error => {
                            helpers.generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleImportFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            const fd = new FormData();
            fd.append('import_file', files[0]);

            setIsLoading(true);
            DefaultAxios
                .post(`${API_URL}/import`, fd)
                .then((res: { data: string[] }) => {
                    let title = 'Upload successful';
                    let message = '';
                    let icon: 'success' | 'warning' = 'success';

                    if (res.data.length) {
                        title = 'Upload with partiel error';
                        icon = 'warning';
                        message = '<ul class="' + classes.errorContainer + '">';
                        for (let m of res.data) {
                            message += `<li>${m}</li>`;
                        }
                        message += '</ul>';
                    }

                    Swal.fire({
                        title: title,
                        html: message,
                        icon: icon
                    });
                    setReDrawDatatable(new Date().getTime());
                })
                .catch(err => {
                    helpers.generalErrorHandler(err);
                })
                .finally(() => {
                    document.querySelectorAll('[type="file"]').forEach(el => {
                        (el as HTMLInputElement).value = '';
                    })
                    setIsLoading(false);
                });
        }
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Button
                variant="contained"
                color="primary"
                component="label"
                style={{ marginBottom: 24 }}
            >
                Import Data
                <input
                    type="file"
                    style={{ display: "none" }}
                    accept=".xls,.xlsx"
                    onChange={handleImportFileChanged}
                />
            </Button>
            <DataTable
                url={API_URL}
                reDraw={reDrawDatatable}
                columns={columns}
            />
        </Root>
    );
}

export default ProjectList;