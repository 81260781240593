import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { LineChart, lineElementClasses } from "@mui/x-charts/LineChart";
import { BreakdownItem, DataBreakdown } from "../EnquiryReport";

interface EnquiryChartProps {
    dataBreakdown: BreakdownItem[];
}

const EnquiryChart: React.FC<EnquiryChartProps> = ({ dataBreakdown }) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const [chartWidth, setChartWidth] = useState<number>(0);
    const [bottomMargin, setBottomMargin] = useState<number>(130);

    useEffect(() => {
        const updateChartWidth = () => {
            if (chartContainerRef.current) {
                setChartWidth(chartContainerRef.current.offsetWidth);
            }
        };

        const updateBottomMargin = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth === 1366) {
                setBottomMargin(140);
            } else if (screenWidth === 1920) {
                setBottomMargin(120);
            }
            else if (screenWidth <= 768) {
                setBottomMargin(170);
            }
            else {
                setBottomMargin(100);
            }
        };

        updateChartWidth();
        updateBottomMargin();
        window.addEventListener("resize", updateChartWidth);
        window.addEventListener("resize", updateBottomMargin);

        return () => {
            window.removeEventListener("resize", updateChartWidth);
            window.removeEventListener("resize", updateBottomMargin);
        };
    }, []);

    const labels = Object.keys(dataBreakdown[0].data) as Array<keyof DataBreakdown>;
    const colors = [
        "#ff8a65",
        "#673AB7",
        "#45d6e7",
        "#4caf50",
        "#ffc107",
        "#f44336",
        "#009688",
        "#ffeb3b",
        "#795548",
        "#2196f3",
    ];

    const series = labels.map((label, index) => {
        return {
            data: dataBreakdown.map((item) => item.data[label]),
            label,
            area: true,
            showMark: false,
            color: colors[index % colors.length],
        };
    });

    const totalSeries = {
        data: dataBreakdown.map((items) => labels.reduce((sum, label) => sum + items.data[label], 0)),
        label: "Total",
        area: true,
        showMark: false,
        color: "#009BE5",
    }

    const completeSeries = [totalSeries, ...series];

    const months = dataBreakdown.map((item) => item.breakdown);

    return (
        <div ref={chartContainerRef} style={{ width: "100%", height: "100%" }}>
            <LineChart
                width={chartWidth}
                height={550}
                margin={{ bottom: bottomMargin }}
                slotProps={{
                    legend: {
                        position: { horizontal: "middle", vertical: "bottom" }
                    }
                }}
                series={completeSeries}
                xAxis={[{ scaleType: "point", data: months }]}
                sx={{
                    [`& .${lineElementClasses.root}`]: {
                        display: "none",
                    },
                }}
            />
        </div>
    );
};

export default EnquiryChart;
