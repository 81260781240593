import React, { useEffect, useReducer, useState } from 'react';
import { Button, Dialog, Switch, Typography, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import helpers from '../../_utils/Helpers';

// Icons
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyles';

interface IState {
    id: string;
    name: string;
    is_optimize: number;
    file: File | null;
    thumbnail: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IErrorState {
    name: string;
    file: string;
}

const useStyles = generateStyle(theme => ({
    actionContainer: {
        display: 'flex'
    },
    addButton: {
        marginBottom: '20px'
    },
    switchContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
}), "AssetUploadList")

const AssetUploadList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/asset';
    const { Root, classes } = useStyles();

    const initialState = {
        id: '',
        name: '',
        is_optimize: 1,
        file: null,
        thumbnail: ''
    };

    const initialErrorState = {
        name: '',
        file: ''
    };

    const columns = [
        {
            name: 'thumbnail',
            label: 'Thumbnail',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (data: any, row: any) => {
                return <img src={data} height="100px" alt={row.name} onClick={() => setPreviewImage(data)} style={{ cursor: 'pointer' }} />
            }
        },
        {
            name: 'name',
            label: 'Name',
            type: 'string',
            filterable: true,
            sortable: false
        },
        {
            name: 'uploaded_at',
            label: 'Uploaded At',
            type: 'date',
            filterable: true,
            sortable: false
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return (
                    <div className={classes.actionContainer}>
                        <IconButton
                            color="primary"
                            component="span"
                            onClick={() => handleEdit(row.id)}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>

                        <IconButton
                            color="secondary"
                            component="span"
                            onClick={() => handleDelete(row.id)}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>

                        <IconButton
                            color="primary"
                            component="span"
                            onClick={() => {
                                navigator.clipboard.writeText(row.thumbnail);
                                Swal.fire({
                                    title: "Copied to clipboard.",
                                    icon: 'success',
                                    timer: 1000
                                })
                            }}
                        >
                            <FileCopyIcon fontSize="small" />
                        </IconButton>
                    </div>
                );
            }
        },
    ];

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [previewImage, setPreviewImage] = useState<undefined | string>(undefined);

    useEffect(() => {
        if (!modalOpen) {
            setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
            setErrorState({ name: '', value: initialErrorState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [modalOpen]);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const name = target.name;
        let value: any = target.value;
        const newState: any = { ...inputState };

        if (target.name === 'file') {
            if (target.files && target.files[0]) {
                const file = target.files[0];
                newState['thumbnail'] = URL.createObjectURL(file);
                newState['file'] = file;
            }
        } else {
            newState[name] = value;
        }

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { ...inputState };
        newState.is_optimize = event.target.checked ? 1 : 0

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    };

    const handleEdit = (id: string) => {
        setIsLoading(true);
        DefaultAxios
            .get(API_URL + '/' + id)
            .then(res => {
                const newState = {
                    ...initialState,
                    ...res.data
                };

                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
                handleModalOpen();
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleDelete = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin menghapus asset ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .delete(`${API_URL}/${id}`)
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000,
                                onAfterClose: () => {
                                    setReDrawDatatable(new Date().getTime());
                                }
                            });
                        })
                        .catch(error => {
                            helpers.generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        }

        if (!inputState.id && !inputState.file) {
            newError.file = 'File wajib diisi';
            isValid = false;
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        const fd = new FormData();
        fd.append('name', inputState.name);
        fd.append('is_optimize', inputState.is_optimize ? '1' : '0')

        let axios;

        if (inputState.id) {
            axios = DefaultAxios.post(API_URL + '/' + inputState.id, fd);
            fd.append('_method', 'patch');
        } else {
            fd.append('file', inputState.file || '');
            axios = DefaultAxios.post(API_URL, fd);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Asset submitted",
                    icon: 'success',
                    onAfterClose: () => {
                        setReDrawDatatable(new Date().getTime());
                        handleModalClose();
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    console.log(inputState)

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog
                open={!!previewImage}
                onClose={() => { setPreviewImage(undefined) }}
                scroll="body"
                maxWidth={false}
            >
                <img src={previewImage} width="100%" alt="preview" />
            </Dialog>
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={handleModalOpen}
            >
                Add New Asset
            </Button>
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Asset Upload Form</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <img src={inputState.thumbnail ? inputState.thumbnail : "https://jendela360.dev/assets/admin/img/default-thumbnail.jpg"} width="100" alt={inputState.name} />
                            {
                                !inputState.id &&
                                <>
                                    <br />
                                    <input type="file" name="file" onChange={handleChange} />
                                    {
                                        errorState.file &&
                                        <>
                                            <br />
                                            <span style={{ color: 'red' }}>{errorState.file}</span>
                                        </>
                                    }
                                </>
                            }
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Nama Asset"
                                name="name"
                                value={inputState.name}
                                onChange={handleChange}
                                error={!!errorState.name}
                                helperText={errorState.name}
                            />
                        </Grid>
                        {
                            !inputState.id &&
                            <Grid item xs={12} className={classes.switchContainer}>
                                <Typography>
                                    Optimize
                                </Typography>
                                <Switch
                                    value={true}
                                    checked={inputState.is_optimize === 1}
                                    onChange={handleSwitch}
                                />
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </Root>
    );
}

export default AssetUploadList;