import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import PICUserGroupFormModal, { PICUserGroupFormModalState } from './PicUserGroupFormModal';
import { IconButton, Tooltip } from '@mui/material';

/**
 * Utils
 */
import { generateStyle } from '../../_utils/DefaultStyles';
import { IAutoCompleteOption as IAutocompleteMultipleOption } from '../../_components/_form/AsycMultipleAutoComplete';

/**
 * Icons
 */
import { Edit } from '@mui/icons-material';
import LoadingScreen from '../../_components/LoadingScreen';

const useStyles = generateStyle(theme => ({
    actionContainer: {
        display: 'flex'
    },
    marginBottom: {
        marginBottom: "1rem"
    }
}), "PICUserGroupList")

const PICUserGroupList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/pic-user-group';
    const { Root } = useStyles()

    const [isLoading, setIsLoading] = useState(false)
    const [reDrawDatatable, setReDrawDatatable] = useState(1)
    const [targetIds, setTargetids] = useState<IAutocompleteMultipleOption[]>([]);

    // Flag for add form open or not
    const [isEditOpen, setIsEditOpen] = useState(false)
    // Contain selected data to be edited
    const [selectedRow, setSelectedRow] = useState<PICUserGroupFormModalState | null>(null)
    // Contain selected row id to open member modal

    useEffect(() => {
        setIsLoading(true);
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 500);
        return () => clearTimeout(timer);
    }, [reDrawDatatable]);

    const columns = [
        {
            name: 'name',
            label: 'Name',
            type: 'string',
            filterable: true,
            sortable: true,
        },
        {
            name: 'role_name',
            label: 'Role',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: 'pic-managerial',
                    value: 'PIC Managerial'
                },
                {
                    key: 'pic-sales',
                    value: 'PIC Sales'
                },

            ],
            filterable: true,
        },
        {
            name: 'group_names',
            label: 'Group',
            type: 'string',
            render: (data: any) => {
                return data === null ? "-" : data
            },
            filterable: true,
            sortable: true,
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => (
                <Tooltip title="Edit Group Names">
                    <IconButton
                        color="primary"
                        component="span"
                        onClick={() => setSelectedRow(row)}
                    >
                        <Edit fontSize="small" />
                    </IconButton>
                </Tooltip>
            )
        }
    ];

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <PICUserGroupFormModal
                isOpen={isEditOpen || selectedRow !== null}
                onClose={() => {
                    setIsEditOpen(false)
                    setSelectedRow(null)
                }}
                onFinish={() => {
                    setReDrawDatatable(prev => prev + 1)
                }}
                label={"Group"}
                editData={selectedRow || undefined}
                targetIds={targetIds}
                setTargetIds={setTargetids}
            />

            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </Root>
    )
}

export default PICUserGroupList
