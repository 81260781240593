// default 
import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, TextField, Button } from '@mui/material'
import { generateStyle } from '../../../_utils/DefaultStyles'

// icons & styles

// interfaces
interface IProps {
  open: boolean,
  onClose: () => void,
  onSubmit: (name: string, edit?: boolean) => void,
  edit?: boolean,
  defaultValue?: string
}

const useStyles = generateStyle(theme => ({
  form: {
    margin: "10px auto 10px"
  }
}), "AddGroupModal")

const AddGroupModal = (props: IProps) => {
  const { Root, classes } = useStyles()
  const [groupName, setGroupName] = useState('')

  const handleSubmit = () => {
    if (props.edit) {
      props.onSubmit(groupName, true)
    } else {
      props.onSubmit(groupName, false)
    }
    props.onClose()
  }

  useEffect(() => {
    if (props.edit) {
      setGroupName(props.defaultValue as string)
    } else {
      setGroupName(groupName)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Root>
        <DialogTitle>
          {props.edit ? "Edit Group" : "Add Group"}
        </DialogTitle>
        <DialogContent>
          <TextField defaultValue={props.defaultValue} label="Group Name" fullWidth className={classes.form} onChange={(e) => setGroupName(e.target.value)} />
          <Button
            variant='contained'
            onClick={handleSubmit}
          >
            {props.edit ? "Edit" : "Add"}
          </Button>
        </DialogContent>
      </Root>
    </Dialog>
  )
}

export default AddGroupModal