import React from 'react';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import { Grid } from '@mui/material';

const LogLogin = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/login-logs';

    const columns = [
        {
            name: 'datetime',
            label: 'Tanggal',
            type: 'date',
            filterable: true
        },
        {
            name: 'user_name',
            label: 'Nama User',
            type: 'string',
            filterable: true
        },
        {
            name: 'approved_by_name',
            label: 'Approved By',
            type: 'string',
            filterable: true,
            render: (data: any) => {
                return data || '-'
            }

        },
        {
            name: 'ip',
            label: 'IP',
            type: 'string',
            filterable: true
        },
        {
            name: 'success',
            label: 'Success',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            filterable: true,
            render: (data: any) => {
                return +data === 1 ? 'Yes' : 'No';
            }
        },
        {
            name: 'header',
            label: 'Header',
            type: 'string',
            filterable: true
        },
        {
            name: 'notes',
            label: 'Notes',
            type: 'string',
            filterable: true
        },
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <DataTable
                    url={API_URL}
                    columns={columns}
                />
            </Grid>
        </Grid>
    );
}

export default LogLogin;