import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Tooltip } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Context
 */
// import { PermissionContext } from "../../_contexts/PermissionContext";

//Icon
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import SimpleDataTable from '../../_components/_dataTable/SimpleDataTable';
import AgentLeadScheduleForm from './AgentLeadScheduleForm';
import { generateStyle } from '../../_utils/DefaultStyles';

interface Props {
    open: boolean;
    onClose: (fetch: boolean) => void;
    userId: string;
}

const AgentLeadScheduleList = ({ open, onClose, userId }: Props) => {
    const initialState = [
        {
            day: 1,
            day_label: "Senin",
            start_time: null,
            end_time: null
        },
        {
            day: 2,
            day_label: "Selasa",
            start_time: null,
            end_time: null
        },
        {
            day: 3,
            day_label: "Rabu",
            start_time: null,
            end_time: null
        },
        {
            day: 4,
            day_label: "Kamis",
            start_time: null,
            end_time: null
        },
        {
            day: 5,
            day_label: "Jumat",
            start_time: null,
            end_time: null
        },
        {
            day: 6,
            day_label: "Sabtu",
            start_time: null,
            end_time: null
        },
        {
            day: 7,
            day_label: "Minggu",
            start_time: null,
            end_time: null
        },
    ]

    const {Root: SDialog, classes} = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDay, setSelectedDay] = useState({
        day: 1,
        label: 'Senin'
    })
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [scheduleFormModal, setScheduleFormModal] = useState(false)
    const [scheduleData, setScheduleData] = useState<any[]>(initialState)

    const handleModalClose = (fetch: boolean) => {
        setSelectedDay({
            day: 1,
            label: 'Senin'
        });
        setScheduleFormModal(false);
        if (fetch) {
            fetchData()
        }
    }

    const handleEdit = (day: number, day_label: string) => {
        setSelectedDay({
            day: day,
            label: day_label
        })
        setScheduleFormModal(true)
    }

    const handleDelete = (day: number) => {
        renderWarningButton("Apakah anda yakin ingin menghapus schedule?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .delete(`${process.env.REACT_APP_API_URL}/user/lead-off-schedule?day=${day}&agent_id=${userId}`)
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000,
                                onAfterClose: () => {
                                    fetchData()
                                }
                            })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const columns = [
        {
            name: 'day',
            label: 'Hari',
            type: 'string',
            style: { textAlign: 'center' },
            render: (data: string, row: any) => {
                return row.day_label
            }
        },
        {
            name: 'start_time',
            label: 'Start Off Time',
            type: 'string',
            style: { textAlign: 'center' },
            render: (data: string, row: any) => {
                return data ? (data === '00:00:00' && row.end_time === '00:00:00' ? 'NONE' : data.slice(0, -3)) : '-'
            }
        },
        {
            name: 'end_time',
            label: 'End Off Time',
            type: 'string',
            style: { textAlign: 'center' },
            render: (data: string, row: any) => {
                return data ? (data === '00:00:00' && row.start_time === '00:00:00' ? 'Off Day' : data.slice(0, -3)) : '-'
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            style: { textAlign: 'center' },
            render: (row: any) => {
                return (
                    <div className={classes.actionContainer}>
                        <Tooltip title="Edit">
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => handleEdit(row.day, row.day_label)}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {
                            row.start_time || row.end_time ?
                                <Tooltip title="Delete">
                                    <IconButton
                                        color="secondary"
                                        component="span"
                                        onClick={() => handleDelete(row.day)}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                : null
                        }
                    </div>
                );
            }
        },
    ];

    const fetchData = () => {  
        setIsLoading(true)
        setScheduleData(initialState)

        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/user/lead-off-schedule?id=${userId}`)
            .then(res => {
                setScheduleData((prev) => {
                    const finalData = [...prev];
                    res.data.forEach((data: any) => {
                        const matchedItem = finalData.find(
                            (item) => item.day === data.day
                        );
                        matchedItem.start_time = data.start_time;
                        matchedItem.end_time = data.end_time;
                    });
                    return finalData;
                });
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setIsLoading(false);
                setReDrawDatatable(new Date().getTime());
            })
    }

    useEffect(() => {
        if (open) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <SDialog
            open={open}
            onClose={() => {onClose(false)}}
            fullWidth
            maxWidth="md"
        >
            <LoadingScreen open={isLoading} fullScreen />
            <AgentLeadScheduleForm
                open={scheduleFormModal}
                onClose={handleModalClose}
                day={selectedDay.day}
                day_label={selectedDay.label}
                agent_id={userId}
            />
            <DialogTitle>Jadwal Off Leads</DialogTitle>
            <DialogContent>
                <SimpleDataTable
                    reDraw={reDrawDatatable}
                    columns={columns}
                    customData={scheduleData}
                />
            </DialogContent>
        </SDialog>
    );
}

const useStyles = generateStyle(theme => ({
    actionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}), "AgentLeadSchedule_List", {
    CustomParent: Dialog,
})

export default AgentLeadScheduleList;