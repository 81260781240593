import React, { Fragment, useState, useEffect, SyntheticEvent } from 'react';
import { Grid, Button, ButtonGroup, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../LoadingScreen';
import ImageForm from './Form';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import helpers from '../../../_utils/Helpers';
import { renderWarningButton } from '../../../_utils/Helper';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

interface Props {
    API_URL: string;
}

export interface IState {
    id: string;
    caption: string;
    category: string
    alt: string;
    thumbnail: string;
    file: File | null;
    sort: number;
}

const ImageFormPage = ({ API_URL }: Props) => {

    const [formStates, setFormStates] = useState<IState[]>([]);
    const [imageStates, setImageStates] = useState<IState[]>([]);
    const [mode, setMode] = useState<'add' | 'edit'>('add');
    const [isLoading, setIsLoading] = useState(false);

    const defaultState: IState = {
        id: '',
        caption: '',
        category: '',
        alt: '',
        thumbnail: '',
        file: null,
        sort: 0
    };

    useEffect(() => {
        loadImages();
        // eslint-disable-next-line
    }, []);

    const loadImages = () => {
        setIsLoading(true);
        DefaultAxios
            .get(API_URL)
            .then(res => {
                const imageGallery = res.data;
                const newImageStates = [];

                for (let key in imageGallery) {
                    newImageStates.push({
                        id: imageGallery[key].id,
                        caption: imageGallery[key].caption,
                        category: imageGallery[key].category,
                        alt: imageGallery[key].alt,
                        thumbnail: imageGallery[key].image,
                        file: null,
                        sort: imageGallery[key].sort
                    })
                }

                setImageStates(newImageStates);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleNewData = () => {
        setMode('add');
        setFormStates([
            {
                ...defaultState
            }
        ])
        document.querySelectorAll('[type="file"]').forEach(el => {
            (el as HTMLInputElement).value = '';
        })
    }

    const handleChanged = async (e: SyntheticEvent, index: number) => {
        const oldStates: any[] = formStates.slice();
        const target = e.target as HTMLInputElement
        const files = target.files as FileList

        if (target.name === 'file') {
            if (target && files[0]) {
                try {
                    const file: File = await helpers.resizeAndResetOrientationImage(files[0])
                    oldStates[index]['thumbnail'] = URL.createObjectURL(file);
                    oldStates[index]['file'] = file;
                } catch (err) {
                    console.log(err)
                }
            }
        } else {
            oldStates[index][target.name] = target.value;
        }
        setFormStates(oldStates);
    }

    const handleSelect = (index: number, name: string, value: string | null) => {
        const oldStates: any[] = formStates.slice();
        oldStates[index][name] = value || ''
        setFormStates(oldStates);
    }

    const formAction = (action: string) => {
        const oldState = formStates.slice();
        switch (action) {
            case 'add':
                oldState.push({ ...defaultState });
                break;
            case 'remove':
                oldState.splice(oldState.length - 1, 1);
                break;
            default:
                break;
        }
        setFormStates(oldState);
    }

    const renderForms = () => {
        const formsEl = formStates.map((state, key) => {
            return (
                <Fragment key={state.id}>
                    <ImageForm form={state} onChange={handleChanged} onSelect={handleSelect} index={key}/>
                    <Grid item xs={12}>
                        <hr />
                    </Grid>
                </Fragment>
            )
        });

        const actionButtons = (
            <>
                <Grid item xs={12}>
                    {
                        mode === 'add'
                            ? <ButtonGroup disableElevation variant="contained">
                                <Button color="primary" onClick={() => formAction('add')}>+</Button>
                                <Button color="secondary" onClick={() => formAction('remove')}>-</Button>
                            </ButtonGroup>
                            : null
                    }
                    <Button
                        color="primary"
                        variant="contained"
                        sx={{float: 'right'}}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </>
        )

        return (
            <>
                {formsEl}
                {formStates.length ? actionButtons : null}
            </>
        );
    }

    const handleEditImage = (imageState: IState) => {
        setMode('edit');
        setFormStates([
            {
                ...imageState
            }
        ])
        document.querySelectorAll('[type="file"]').forEach(el => {
            (el as HTMLInputElement).value = '';
        })
    }

    const handleDeleteImage = (imageState: IState) => {
        renderWarningButton('Apakah anda yakin ingin menghapus image ini?')
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                DefaultAxios
                    .delete(`${API_URL}/${imageState.id}`)
                    .then(() => {
                        Swal.fire({
                            title: "Berhasil menghapus",
                            icon: 'success',
                            timer: 1000
                        })
                            .then(res => {
                                loadImages();
                            })
                    })
                    .catch(error => {
                        helpers.generalErrorHandler(error)
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }
        })
    }

    const checkValidation = () => {
        let isValid = true;

        formStates.map(formState => {
            if (!formState.file && mode === 'add') {
                isValid = false;
            }

            if (!formState.caption) {
                isValid = false;
            }
            
            if (!formState.category) {
                isValid = false;
            }
            return true;
        });

        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            Swal.fire({
                title: "Error",
                text: 'Please fill caption, category and file on every form',
                icon: 'error'
            });
            return;
        }

        let url;
        const fd = new FormData();

        if (mode === 'add') {
            for (let i in formStates) {
                for (let key in formStates[i]) {
                    let value = formStates[i][key as keyof IState];
                    if (value) {
                        if (key === 'file') {
                            fd.append(`param[${i}][image]`, value as File);
                        } else {
                            fd.append(`param[${i}][${key}]`, String(value));
                        }
                    }
                }
            }

            url = API_URL;
        } else {
            for (let i in formStates) {
                for (let key in formStates[i]) {
                    let value = formStates[i][key as keyof IState];
                    if (value) {
                        if (key === 'file') {
                            fd.append('image', value as File);
                        } else {
                            fd.append(`${key}`, String(value));
                        }
                    }
                }
            }

            url = API_URL + '/' + formStates[0].id;
            fd.append('_method', 'PATCH');
        }

        setIsLoading(true);
        DefaultAxios.post(url, fd)
            .then(res => {
                Swal.fire({
                    title: "Submit image berhasil",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        loadImages();
                        setFormStates([]);
                    })
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleSortImage = (imageId: string, dir: 'up' | 'down', key: number) => {
        setIsLoading(true);
        DefaultAxios
            .post(API_URL + '/' + imageId + '/sort-image', { dir })
            .then(res => {
                loadImages();
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={12}>
                <Paper style={{padding: 16, marginRight: 24}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNewData}
                            >
                                Add Image
                            </Button>
                            <hr />
                        </Grid>

                        {renderForms()}

                        <Grid item xs={12}>
                            <hr />
                        </Grid>

                        {/* {renderImages()} */}
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Caption</TableCell>
                                            <TableCell>Category</TableCell>
                                            <TableCell>Thumbnail</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {imageStates.map((imageState, key) => (
                                            <TableRow key={imageState.id}>
                                                <TableCell component="th" scope="row">
                                                    {imageState.caption}
                                                </TableCell>
                                                <TableCell>
                                                    {imageState.category}
                                                </TableCell>
                                                <TableCell>
                                                    <img src={imageState.thumbnail} height="100px" alt={imageState.caption} />
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton aria-label="delete" color="primary" onClick={() => handleEditImage(imageState)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="delete" color="secondary" onClick={() => handleDeleteImage(imageState)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => handleSortImage(imageState.id, 'up', key)}
                                                        disabled={key === 0}
                                                    >
                                                        <ArrowUpwardIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => handleSortImage(imageState.id, 'down', key)}
                                                        disabled={key === imageStates.length - 1}
                                                    >
                                                        <ArrowDownwardIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Fragment>
    );
}

export default ImageFormPage;
