import React, { useState } from 'react'
import { Dialog, DialogContent, Grid, Button, DialogTitle } from '@mui/material'

/**
 * Components
 */
import LocalDatePicker from '../../_components/_form/DatePicker'

/**
 * Utils
 */
import { format } from 'date-fns'

interface IProps {
    open: boolean,
    onClose: () => void
    handleSubmit: (dateString: string) => void
}

const ReimbursementForm = (props: IProps) => {
    const [date, setDate] = useState<Date>(new Date())
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>Date</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} sx={{py: 2}}>
                        <LocalDatePicker
                            onChange={(date, value) => setDate(date ? date : new Date())}
                            value={date}
                            label='Date'
                        />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                        <Button
                            variant='contained'
                            onClick={() => {
                                props.handleSubmit(format(date, 'yyyy-MM-dd'))
                            }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ReimbursementForm