import React, { useState } from 'react'

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import { Button, IconButton, Switch, Tooltip } from '@mui/material';

/**
 * Utils
 */
import { generateStyle } from '../../_utils/DefaultStyles';
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper';
import DefaultAxios from '../../_utils/DefaultAxios';

/**
 * Icons
 */
import { Add, Edit } from '@mui/icons-material';
import LoadingScreen from '../../_components/LoadingScreen';
import GeneralFormModal from '../../_components/_modals/GeneralFormModal';

type GeneralFormState = {
    [key: string]: string | number | null;
}

// Extended type for our specific form
type FacebookInstagramTokenFormModalState = GeneralFormState & {
    id?: number;
    name: string;
    instagram_id: string;
    url_whatsapp_id: null | number;
    url_whatsapp_name_label: string;
    page_access_token?: string;
}

const useStyles = generateStyle(theme => ({
    actionContainer: {
        display: 'flex'
    },
    addButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: "25px",
    }
}), "FacebookInstagramTokenList")

const FacebookInstagramTokenList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/fb-instagram-token';
    const { Root, classes } = useStyles()

    const [isLoading, setIsLoading] = useState(false)
    const [reDrawDatatable, setReDrawDatatable] = useState(1)

    // Flag for add form open or not
    const [isAddOpen, setIsAddOpen] = useState(false)
    // Contain selected data to be edited
    const [selectedRow, setSelectedRow] = useState<FacebookInstagramTokenFormModalState | null>(null)

    const handleActiveSwitch = (row: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status aktif ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)

                    DefaultAxios.post(`${API_URL}/toggle-active`, { id: row.id })
                        .then(() => {
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +row.id) element['is_active'] = element['is_active'] === 0 ? 1 : 0
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            })
    }

    const handleSubmit = (state: FacebookInstagramTokenFormModalState) => {
        const method = selectedRow?.id ? 'PATCH' : 'POST';
        const url = selectedRow?.id
            ? `${API_URL}/${selectedRow.id}`
            : API_URL;

        setIsLoading(true);
        DefaultAxios.request({
            method,
            url,
            data: state,
        })
            .then(res => res.data)
            .then(() => {
                renderToastSuccess(selectedRow?.id ? 'Berhasil mengubah data' : 'Berhasil menambahkan data baru');
                setIsAddOpen(false);
                setSelectedRow(null);
                setReDrawDatatable(prev => prev + 1);
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            });
    };

    const columns = [
        {
            name: 'name',
            label: 'Name',
            type: 'string',
            filterable: true,
            sortable: true,
        },
        {
            name: 'instagram_id',
            label: 'ID Instagram',
            type: 'string',
            filterable: true,
            sortable: true,
        },
        {
            name: 'url_whatsapp_name_label',
            label: 'WA Group',
            type: 'string',
            filterable: true,
            sortable: true,
        },
        {
            name: 'is_active',
            label: 'Active',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'Inactive'
                },
                {
                    key: '1',
                    value: 'Active'
                }
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return (
                    <Switch
                        checked={data === 1}
                        onChange={() => handleActiveSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                )
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => (
                <Tooltip title="Edit Facebook Instragram Token">
                    <IconButton
                        color="primary"
                        component="span"
                        onClick={() => setSelectedRow(row)}
                    >
                        <Edit fontSize="small" />
                    </IconButton>
                </Tooltip>
            )
        }
    ];

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <GeneralFormModal
                title='Facebook Instagram Token Form'
                open={isAddOpen || selectedRow !== null}
                onClose={() => {
                    setIsAddOpen(false)
                    setSelectedRow(null)
                }}
                onSubmit={handleSubmit as any}
                forms={[
                    {
                        name: 'name',
                        type: 'input',
                        defaultValue: selectedRow?.name || '',
                        label: 'Name',
                        required: true,
                        alias: 'Name',
                    },
                    {
                        name: 'instagram_id',
                        type: 'input',
                        defaultValue: selectedRow?.instagram_id || '',
                        label: 'ID Instagram',
                        required: true,
                        alias: 'ID Instagram',
                    },
                    {
                        name: 'url_whatsapp_id',
                        type: 'autocomplete',
                        label: 'WA Group',
                        required: true,
                        alias: "Wa Group",
                        defaultValue: selectedRow?.url_whatsapp_id !== null && selectedRow?.url_whatsapp_id !== undefined ? String(selectedRow.url_whatsapp_id) : '',
                        initialQuery: selectedRow?.url_whatsapp_name_label || '',
                        url: `${process.env.REACT_APP_API_URL}/autocomplete/url-whatsapp`
                    },
                    {
                        name: 'page_access_token',
                        type: 'input',
                        defaultValue: selectedRow?.page_access_token || '',
                        label: 'Page Access Token',
                        required: true,
                        alias: 'Page Access Token',
                    },
                ]}
            />

            <div className={classes.addButton}>
                <Button
                    variant="contained"
                    size="small"
                    startIcon={<Add />}
                    onClick={() => setIsAddOpen(true)}
                >
                    Add Data
                </Button>
            </div>

            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </Root>
    )
}

export default FacebookInstagramTokenList
