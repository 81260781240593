import { Grid, TextField, InputAdornment } from '@mui/material';
import React from 'react';
import DatePicker from '../../../_components/_form/DatePicker';
import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete';

interface Props {
    label: string
    name: string
    value: any
    handleChange: Function
    handleBlur?: Function
    type?: string
    error?: string
    element?: string
    transactions?: boolean
    disabled?: boolean
    startAdornment?: string
    endAdornment?: string
    inputType?: string
    url?: string
    handleAutocompleteChanged?: Function
    gridSize?: any
}

const FormCell = (props: Props) => {

    return (
        <Grid item xs={props.gridSize ? props.gridSize : 12}>
            {
                props.type === 'date' ?
                    <DatePicker
                        label={props.label}
                        format="dd/MM/yyyy"
                        value={props.value}
                        disabled={props.disabled}
                        onChange={(date: any) => props.transactions ? props.handleChange(props.name, date, props.element ? props.element : '') : props.handleChange(props.name, date)}
                        error={!!props.error}
                        helperText={props.error}
                    />
                    :
                    props.type === 'autocomplete' ?
                        <AsyncAutoComplete
                            label={props.label}
                            name={props.name}
                            initialQuery={props.value}
                            onChange={props.handleChange}
                            onInputChange={() => props.handleAutocompleteChanged}
                            url={props.url ? props.url : ''}
                            placeholder={props.label}
                            errorText={props.error}

                        />
                        :
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={props.label}
                            name={props.name}
                            value={props.value}
                            onChange={(e) => props.transactions ? props.handleChange(e, props.element ? props.element : '') : props.handleChange(e)}
                            error={!!props.error}
                            onBlur={() => props.handleBlur}
                            helperText={props.error}
                            disabled={props.disabled}
                            type={props.inputType}
                            InputProps={{
                                startAdornment: props.startAdornment ? <InputAdornment position="start">{props.startAdornment}</InputAdornment> : null,
                                endAdornment: props.endAdornment ? <InputAdornment position="end">{props.endAdornment}</InputAdornment> : null,
                            }}
                        />
            }
        </Grid>
    );
}

export default FormCell;