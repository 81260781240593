import React, { useState } from 'react';
import {
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from '@mui/material';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete';

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler, renderToastSuccess } from '../../../_utils/Helper';

 interface Props {
    leadId: string
    open: boolean;
    onClose: (arg0?: boolean) => void;
}



const ReassignAgentModal = (props: Props) => {
    const [id, setId] = useState<string | number>("");

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setId(value.id);
    }
    
    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setId("");
    }

    const handleSubmit = () => {
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/lead-unassigned/reassign-agent/${props.leadId}`, { id: id})
        .then(res => {
            renderToastSuccess("Agent berhasil di-assign")
            .then(() => {
                props.onClose(true);
            })
        })
        .catch(error => {
            generalErrorHandler(error)
        })
    }

    return (
        <Dialog open={props.open} onClose={() => { props.onClose()}} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Delete Leads</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Isi Agent yang akan di-assign ke Leads ini.
                </DialogContentText>
                <Grid item xs={12}>
                    <AsyncAutoComplete
                        label="Agent"
                        name="agent_id"
                        onChange={handleAutocomplete}
                        onInputChange={handleAutocompleteInputChanged}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/user/agent`}
                        placeholder="Agent"
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {props.onClose()}} color="primary">
                    Batal
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ReassignAgentModal;