import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, MenuItem } from '@mui/material';

import { IState, IErrorState } from './ContactList';

interface Props {
    open: boolean;
    contactTypes: { key: string, value: string }[]
    state: IState;
    errorState: IErrorState;
    onClose: () => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onSubmit: () => void;
}

const ContactForm = ({ open, contactTypes, state, errorState, onClose, onChange, onSubmit }: Props) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>User Form</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ paddingTop: 8 }}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Nama"
                            name="name"
                            value={state.name}
                            onChange={onChange}
                            error={!!errorState.name}
                            helperText={errorState.name}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Label"
                            name="label"
                            value={state.label}
                            onChange={onChange}
                            error={!!errorState.label}
                            helperText={errorState.label}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            label="Type"
                            name="type"
                            value={state.type}
                            onChange={onChange}
                        >
                            {contactTypes.map(contactType => <MenuItem key={contactType.key} value={contactType.key}>{contactType.value}</MenuItem>)}
                        </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="No Hp"
                            name="phone"
                            value={state.phone}
                            onChange={onChange}
                            error={!!errorState.phone}
                            helperText={errorState.phone}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ContactForm;