import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Box, Button, Fab, FormControlLabel, Grid, IconButton, MenuItem, Paper, Switch, TextField, Theme, Tooltip, Typography } from '@mui/material'
import PhotoPreview from '../../../PhotoPreview'
import ButtonUpload from '../../../ButtonUpload'
import ColorPicker from '../../ColorPicker'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../AsyncAutoComplete'
import LoadingScreen from '../../../LoadingScreen'

/**
 * Utils
 */
import { generateStyle } from '../../../../_utils/DefaultStyles'
import { useTheme } from '@mui/material'
import { IValidationErrors } from '../../../../_utils/Validation'
import { generalErrorHandler, getBase64, renderErrorButton, renderToastSuccess, renderWarningButton } from '../../../../_utils/Helper'
import DefaultAxios from '../../../../_utils/DefaultAxios'

/**
 * Icons
 */
import { Delete, Save, Visibility } from '@mui/icons-material'

interface V2FormProps {
    id: number
    type: string
    slug: string
    setParentHasChanged: (value: boolean) => void
}

type Section = {
    title: string
    text_color: string
    background_color: string
    icon_per_line: '' | '1' | '2'
    icons: {
        icon_id: string
        subtitle: string
        file_url: string
        name: string
    }[]
}

type FileType = {
    id?: string
    url: string
    file: File
}

type State = {
    is_dark_mode: boolean
    is_hide_icon_cariproperti: boolean
    title: string
    slp_background_image: null | FileType
    slp_icon_image: null | FileType
    header_points: {
        title: string
        subtitle: string
    }[]
    short_landing_page_sections: Section[]
    bg_gallery_color: string
    short_landing_page_galleries: FileType[]
}

const V2Form = (props: V2FormProps) => {
    const [changed, setChanged] = useState(false)
    const [slug, setSlug] = useState('')
    const { Root, classes } = useStyles()
    const theme = useTheme()

    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState<State>(INITIAL_STATE)
    const [error, setError] = useState<IValidationErrors<State>>({})

    useEffect(() => {
        props.setParentHasChanged(changed)
        // eslint-disable-next-line
    }, [changed])

    useEffect(() => {
        fetchData()

        return () => {
            setState(INITIAL_STATE)
            setSlug('')
            setChanged(false)
        }
        // eslint-disable-next-line
    }, [])

    const fetchData = () => {
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/slp`, {
            params: {
                data_id: props.id,
                type: props.type,
                version: 2,
            }
        })
            .then(res => res.data)
            .then((data: any) => {
                setState(prev => ({
                    ...prev,
                    ...data,
                    bg_gallery_color: data.bg_gallery_color || '',
                    is_dark_mode: data.is_dark_mode === 1,
                    is_hide_icon_cariproperti: data.is_hide_icon_cariproperti === 1,
                    header_points: [
                        ...data.header_points,
                        ...(Array(3 - data.header_points.length).fill(INITIAL_HEADER_POINTS))
                    ],
                    slp_background_image: data.slp_background_image_url ? {
                        file: null,
                        url: data.slp_background_image_url,
                    } : null,
                    slp_icon_image: data.slp_icon_image_url ? {
                        file: null,
                        url: data.slp_icon_image_url,
                    } : null,
                    short_landing_page_galleries: data.short_landing_page_galleries.map((gallery: any) => ({
                        file: null,
                        url: gallery.file_url,
                        id: gallery.id,
                    })),
                    short_landing_page_sections: data.short_landing_page_sections.map((section: any) => ({
                        ...section,
                        text_color: section.text_color || '',
                        background_color: section.background_color || '',
                        icon_per_line: section.icon_per_line.toString(),
                        icons: [
                            ...section.icons.map((icon: any) => ({
                                icon_id: icon.id,
                                subtitle: icon.pivot.subtitle,
                                file_url: icon.file_url,
                                name: icon.name,
                            })),
                            ...(Array(4 - section.icons.length).fill(INITIAL_ICON))
                        ]
                    })),
                }))

                setSlug(data.slug)
            })
            .catch(err => {
                generalErrorHandler(err, {
                    400: (defaultAction, errData) => {
                        if (errData.message === 'Harap isi Kode Cluster terlebih dahulu.') {
                            renderErrorButton(errData.message)
                                .then(res => res.value)
                                .then(value => {
                                    if (value) {
                                        window.location.href = `/cluster/edit/${props.id}`
                                    }
                                })
                        } else {
                            defaultAction()
                        }
                    }
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChanged(true)
        const { name, value, checked, files } = e.target

        switch (name) {
            case 'is_dark_mode':
            case 'is_hide_icon_cariproperti':
                setState(prev => ({
                    ...prev,
                    [name]: checked,
                }))
                break
            case 'slp_background_image':
            case 'slp_icon_image':
                if (!files || files.length === 0) break
                const file = files[0]

                getBase64(file)
                    .then(base64 => {
                        setState(prev => ({
                            ...prev,
                            [name]: {
                                url: base64 as string,
                                file: file,
                            }
                        }))
                    })
                    .catch(generalErrorHandler)
                break
            default:
                const actualName = name.split('.')[0]
                const part = name.split('.')[1]

                switch (actualName) {
                    case 'short_landing_page_galleries':
                        if (files && files.length) {
                            for (let index = 0; index < files.length; index++) {
                                const file = files[index]

                                getBase64(file)
                                    .then(base64 => {
                                        setState(prev => ({
                                            ...prev,
                                            [actualName]: prev[actualName].concat({
                                                url: base64 as string,
                                                file: file,
                                            })
                                        }))
                                    })
                                    .catch(generalErrorHandler)
                            }
                        } else {
                            setState(prev => ({
                                ...prev,
                                [actualName]: {
                                    ...prev[actualName],
                                    [part]: value,
                                },
                            }))
                        }
                        break
                    default:
                        setState(prev => ({
                            ...prev,
                            [name]: value
                        }))
                        break
                }
                break
        }

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleChangeWithIndex = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        setChanged(true)
        const { name, value } = e.target
        const actualName = name.split('.')[0]
        const part = name.split('.')[1]

        switch (actualName) {
            case 'header_points':
                setState(prev => ({
                    ...prev,
                    header_points: prev.header_points.map((points, jndex) => ({
                        ...points,
                        [part]: jndex === index ? value : points[part as 'title' | 'subtitle'],
                    }))
                }))
                break
            case 'short_landing_page_sections':
                setState(prev => ({
                    ...prev,
                    short_landing_page_sections: prev.short_landing_page_sections.map((section, jndex) => ({
                        ...section,
                        [part]: jndex === index ? value : section[part as 'title' | 'text_color' | 'background_color' | 'icon_per_line' | 'icons'],
                    }))
                }))
                break
        }

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleRemoveImage = (name: string, index?: number) => {
        if (index !== undefined) {
            switch (name) {
                case 'short_landing_page_galleries':
                    if (index === undefined) break

                    const selectedImage = state.short_landing_page_galleries[index]

                    if (selectedImage.id) {
                        renderWarningButton('Apakah anda yakin ingin menghapus gambar ini?')
                            .then(res => res.value)
                            .then(value => {
                                if (value) {
                                    setIsLoading(true)

                                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/slp/delete-gallery`, {
                                        id: selectedImage.id,
                                    })
                                        .then(res => res.data)
                                        .then(data => {
                                            setState(prev => ({
                                                ...prev,
                                                short_landing_page_galleries: prev.short_landing_page_galleries.filter((_, idx) => idx !== index)
                                            }))
                                        })
                                        .catch(generalErrorHandler)
                                        .finally(() => {
                                            setIsLoading(false)
                                        })
                                }
                            })
                    } else {
                        setState(prev => ({
                            ...prev,
                            short_landing_page_galleries: prev.short_landing_page_galleries.filter((_, idx) => idx !== index)
                        }))

                        setChanged(true)
                    }
                    break
            }
        } else {
            if (state[name as 'slp_icon_image' | 'slp_background_image']?.file === null) {
                renderWarningButton('Apakah anda yakin ingin menghapus gambar ini?')
                    .then(res => res.value)
                    .then(value => {
                        if (value) {
                            setIsLoading(true)

                            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/slp/delete-image`, {
                                data_id: props.id,
                                version: 2,
                                type: props.type,
                                image_type: name,
                            })
                                .then(res => res.data)
                                .then(data => {
                                    setState(prev => ({
                                        ...prev,
                                        [name]: null,
                                    }))
                                })
                                .catch(generalErrorHandler)
                                .finally(() => {
                                    setIsLoading(false)
                                })
                        }
                    })
            } else {
                setState(prev => ({
                    ...prev,
                    [name]: null,
                }))

                setChanged(true)
            }
        }
    }

    const addSection = () => {
        setChanged(true)
        setState(prev => ({
            ...prev,
            short_landing_page_sections: prev.short_landing_page_sections.concat(INITIAL_SECTION),
        }))
    }

    const removeSection = (index: number) => {
        setChanged(true)

        renderWarningButton('Apakah anda yakin ingin menghapus section ini?')
            .then(res => res.value)
            .then(value => {
                if (value) {
                    setState(prev => ({
                        ...prev,
                        short_landing_page_sections: prev.short_landing_page_sections.filter((_, idx) => idx !== index)
                    }))
                }
            })
    }

    const handleAutocompleteIcon = (sectionIndex: number, iconIndex: number, value: IAutoCompleteOption) => {
        setChanged(true)
        setState(prev => ({
            ...prev,
            short_landing_page_sections: prev.short_landing_page_sections.map((section, sctIndex) => ({
                ...section,
                icons: sctIndex === sectionIndex ? section.icons.map((icon, icnIndex) => ({
                    ...icon,
                    icon_id: icnIndex === iconIndex ? value.id.toString() : icon.icon_id,
                    file_url: icnIndex === iconIndex ? value.file_url.toString() : icon.file_url,
                    name: icnIndex === iconIndex ? value.label : icon.name,
                })) : section.icons,
            }))
        }))
    }

    const handleAutocompleteInputChangedIcon = (sectionIndex: number, iconIndex: number) => {
        setChanged(true)
        setState(prev => ({
            ...prev,
            short_landing_page_sections: prev.short_landing_page_sections.map((section, sctIndex) => ({
                ...section,
                icons: sctIndex === sectionIndex ? section.icons.map((icon, icnIndex) => ({
                    ...icon,
                    icon_id: icnIndex === iconIndex ? '' : icon.icon_id,
                    file_url: icnIndex === iconIndex ? '' : icon.file_url,
                    name: icnIndex === iconIndex ? '' : icon.name,
                })) : section.icons,
            }))
        }))
    }

    const handleChangeColor = (name: string, value: string) => {
        setChanged(true)
        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleSectionIconSubtitle = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, sectionIndex: number, iconIndex: number) => {
        setChanged(true)
        const { value } = e.target

        setState(prev => ({
            ...prev,
            short_landing_page_sections: prev.short_landing_page_sections.map((section, sctIndex) => ({
                ...section,
                icons: sctIndex === sectionIndex ?
                    section.icons.map((icon, icnIndex) => ({
                        ...icon,
                        subtitle: icnIndex === iconIndex ? value : icon.subtitle,
                    }))
                    : section.icons,
            }))
        }))
    }

    const submit = () => {
        setIsLoading(true)

        const fd = new FormData()
        fd.append('data_id', props.id.toString())
        fd.append('is_hide_icon_cariproperti', state.is_hide_icon_cariproperti ? '1' : '0')
        fd.append('is_dark_mode', state.is_dark_mode ? '1' : '0')
        fd.append('title', state.title)

        if (state.slp_background_image?.file) fd.append('slp_background_image', state.slp_background_image?.file)
        if (state.slp_icon_image?.file) fd.append('slp_icon_image', state.slp_icon_image?.file)

        state.header_points.forEach((point, index) => {
            if (point.title) fd.append(`header_points[${index}][title]`, point.title)
            if (point.subtitle) fd.append(`header_points[${index}][subtitle]`, point.subtitle)
        })

        state.short_landing_page_sections.forEach((section, index) => {
            fd.append(`short_landing_page_sections[${index}][title]`, section.title || '')
            fd.append(`short_landing_page_sections[${index}][text_color]`, section.text_color === 'custom' ? '' : section.text_color || '')
            fd.append(`short_landing_page_sections[${index}][background_color]`, section.background_color === 'custom' ? '' : section.background_color || '')
            fd.append(`short_landing_page_sections[${index}][icon_per_line]`, section.icon_per_line || '')
            section.icons.forEach((icon, jndex) => {
                if (icon.icon_id) fd.append(`short_landing_page_sections[${index}][icons][${jndex}][id]`, icon.icon_id)
                if (icon.subtitle) fd.append(`short_landing_page_sections[${index}][icons][${jndex}][subtitle]`, icon.subtitle)
            })
        })

        if (state.bg_gallery_color) fd.append('bg_gallery_color', state.bg_gallery_color === 'custom' ? '' : state.bg_gallery_color)
        state.short_landing_page_galleries.forEach((gallery) => {
            if (gallery.file) fd.append('short_landing_page_galleries[]', gallery.file)
        })

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/slp-v2`, fd)
            .then(res => res.data)
            .then(data => {
                renderToastSuccess('Berhasil menyimpan slp v2')
                setChanged(false)
                fetchData()
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const sxStyles = (theme: Theme) => ({
        warning: {
            position: 'fixed',
            top: '12px',
            left: '8px',
            right: '8px',
            transform: `translateY(${changed ? '0%' : 'calc(-100% - 12px)'})`,
            zIndex: 1300,
            backgroundColor: '#ffb723',
            margin: 0,
            color: 'white',
            padding: '8px 12px !important',
            borderRadius: '8px',
            transition: 'all 0.2s linear',
            [theme.breakpoints.up('sm')]: {
                left: '50%',
                right: 'unset',
                marginLeft: '135px',
                marginRight: 0,
                transform: `translateX(-50%)translateY(${changed ? '0%' : 'calc(-100% - 12px)'})`,
            }
        }
    })

    return (
        <Root>
            {
                changed ?
                    <Box component="div" sx={sxStyles(theme).warning as React.CSSProperties}>
                        You have unsaved changes, don't forget to submit the data!
                    </Box> :
                    null
            }
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={12}>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Toggle Dark Mode"
                                labelPlacement='start'
                                className={classes.switch}
                                control={
                                    <Switch
                                        checked={state.is_dark_mode}
                                        name='is_dark_mode'
                                        onChange={handleChange}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                            <FormControlLabel
                                label="Hide Icon CariProperti"
                                labelPlacement='start'
                                className={classes.switch}
                                control={
                                    <Switch
                                        checked={state.is_hide_icon_cariproperti}
                                        name='is_hide_icon_cariproperti'
                                        onChange={handleChange}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Title"
                                name="title"
                                value={state.title}
                                error={!!error.title}
                                helperText={error.title}
                                variant="outlined"
                                fullWidth
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.imageContainer}>
                                <Typography variant='h6' sx={{ mb: 1 }} component={'p'}>
                                    Icon Image
                                </Typography>
                                {
                                    state.slp_icon_image ?
                                        <PhotoPreview
                                            index={1}
                                            onRemoveFile={() => handleRemoveImage('slp_icon_image')}
                                            src={state.slp_icon_image.url}
                                            style={{
                                                margin: 0,
                                            }}
                                        />
                                        :
                                        <ButtonUpload
                                            onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>)}
                                            name="slp_icon_image"
                                            accept='image/x-png,image/jpeg,.webp,.svg'
                                            containerStyle={{
                                                margin: 0,
                                            }}
                                        />
                                }
                                <Typography variant='caption' sx={{ mt: 1 }}>Rekomendasi Ukuran: 300x300</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.imageContainer}>
                                <Typography variant='h6' sx={{ mb: 1 }} component={'p'}>
                                    Header Image
                                </Typography>
                                {
                                    state.slp_background_image ?
                                        <PhotoPreview
                                            index={1}
                                            onRemoveFile={() => handleRemoveImage('slp_background_image')}
                                            src={state.slp_background_image.url}
                                            style={{
                                                margin: 0,
                                            }}
                                        />
                                        :
                                        <ButtonUpload
                                            onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>)}
                                            name="slp_background_image"
                                            accept='image/x-png,image/jpeg,.webp'
                                            containerStyle={{
                                                margin: 0,
                                            }}
                                        />
                                }
                                <Typography variant='caption' sx={{ mt: 1 }}>Rekomendasi Ukuran: 300x300</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h6' component={'p'}>
                                Header Points
                            </Typography>
                        </Grid>
                        {
                            state.header_points.map((points, index) =>
                                <React.Fragment key={index}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Title"
                                            name="header_points.title"
                                            value={points.title}
                                            error={!!error.header_points}
                                            helperText={error.header_points}
                                            variant="outlined"
                                            fullWidth
                                            onChange={e => handleChangeWithIndex(e, index)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Subtitle"
                                            name="header_points.subtitle"
                                            value={points.subtitle}
                                            error={!!error.header_points}
                                            helperText={error.header_points}
                                            variant="outlined"
                                            fullWidth
                                            onChange={e => handleChangeWithIndex(e, index)}
                                        />
                                    </Grid>
                                </React.Fragment>
                            )
                        }
                        <Grid item xs={12}>
                            <Typography variant='h6' component={'p'} sx={{ mb: 2 }}>
                                Icon Sections
                            </Typography>
                        </Grid>
                        {
                            state.short_landing_page_sections.map((section, index) =>
                                <Grid item xs={12} key={index} sx={{ mb: 2 }}>
                                    <Paper sx={{ paddingLeft: 3, paddingRight: 3, paddingBottom: 3 }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Typography variant='h6' component={'p'} sx={{ marginRight: 'auto' }}>
                                                    Section {index + 1}
                                                </Typography>
                                                <Tooltip title={`Hapus section ${index + 1}`}>
                                                    <IconButton
                                                        color='error'
                                                        onClick={() => removeSection(index)}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Title"
                                                    name="short_landing_page_sections.title"
                                                    value={section.title}
                                                    error={!!error.short_landing_page_sections}
                                                    helperText={error.short_landing_page_sections}
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={e => handleChangeWithIndex(e, index)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Text Color"
                                                    name="short_landing_page_sections.text_color"
                                                    value={!['', 'gold', 'default'].includes(section.text_color) ? 'custom' : section.text_color}
                                                    onChange={e => handleChangeWithIndex(e, index)}
                                                    error={!!error.short_landing_page_sections}
                                                    helperText={error.short_landing_page_sections}
                                                >
                                                    <MenuItem value="default">Default</MenuItem>
                                                    <MenuItem value="gold">Gold</MenuItem>
                                                    <MenuItem value="custom">Custom</MenuItem>
                                                </TextField>
                                            </Grid>
                                            {
                                                section.text_color !== '' ?
                                                    <Grid item xs={12} style={{ paddingTop: 8 }}>
                                                        <ColorPicker
                                                            name='short_landing_page_sections.text_color'
                                                            value={
                                                                section.text_color === 'gold' ?
                                                                    DEFAULT_COLORS.sectionText[(index + 1) % 2 === 0 ? 'even' : 'odd'].gold
                                                                    :
                                                                    section.text_color === 'default' ?
                                                                        DEFAULT_COLORS.sectionText[(index + 1) % 2 === 0 ? 'even' : 'odd'][state.is_dark_mode ? 'dark' : 'light']
                                                                        :
                                                                        section.text_color === 'custom' ? '' : section.text_color
                                                            }
                                                            disabled={['gold', 'default'].includes(section.text_color)}
                                                            onChange={(name, value) => handleChangeWithIndex({ target: { name, value } } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index)}
                                                        />
                                                    </Grid>
                                                    : null
                                            }
                                            <Grid item xs={12}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Background Color"
                                                    name="short_landing_page_sections.background_color"
                                                    value={!['', 'default'].includes(section.background_color) ? 'custom' : section.background_color}
                                                    onChange={e => handleChangeWithIndex(e, index)}
                                                    error={!!error.short_landing_page_sections}
                                                    helperText={error.short_landing_page_sections}
                                                >
                                                    <MenuItem value="default">Default</MenuItem>
                                                    <MenuItem value="custom">Custom</MenuItem>
                                                </TextField>
                                            </Grid>
                                            {
                                                section.background_color !== '' ?
                                                    <Grid item xs={12} style={{ paddingTop: 8 }}>
                                                        <ColorPicker
                                                            name='short_landing_page_sections.background_color'
                                                            value={
                                                                section.background_color === 'default' ?
                                                                    DEFAULT_COLORS.sectionBackground[(index + 1) % 2 === 0 ? 'even' : 'odd'][state.is_dark_mode ? 'dark' : 'light']
                                                                    :
                                                                    section.background_color === 'custom' ? '' : section.background_color
                                                            }
                                                            disabled={section.background_color === 'default'}
                                                            onChange={(name, value) => handleChangeWithIndex({ target: { name, value } } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index)}
                                                        />
                                                    </Grid>
                                                    : null
                                            }
                                            <Grid item xs={12}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Icon per Line in Mobile"
                                                    name="short_landing_page_sections.icon_per_line"
                                                    value={section.icon_per_line}
                                                    onChange={e => handleChangeWithIndex(e, index)}
                                                    error={!!error.short_landing_page_sections}
                                                    helperText={error.short_landing_page_sections}
                                                >
                                                    <MenuItem value="1">1</MenuItem>
                                                    <MenuItem value="2">2</MenuItem>
                                                </TextField>
                                            </Grid><Grid item xs={12}>
                                                <Typography variant='h6' component={'p'}>
                                                    Icons
                                                </Typography>
                                            </Grid>
                                            {
                                                section.icons.map((icon, iconIndex) =>
                                                    <React.Fragment key={iconIndex}>
                                                        <Grid item xs={2}>
                                                            {
                                                                icon.file_url ?
                                                                    <img src={icon.file_url} alt="icon" width={56} height={56} />
                                                                    : '-'
                                                            }
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <AsyncAutoComplete
                                                                url={`${process.env.REACT_APP_API_URL}/autocomplete/slp-icon`}
                                                                label="Icon"
                                                                name="short_landing_page_sections.icons.icon_id"
                                                                initialQuery={icon.name}
                                                                onChange={(_: string, value: IAutoCompleteOption) => handleAutocompleteIcon(index, iconIndex, value)}
                                                                onInputChange={() => handleAutocompleteInputChangedIcon(index, iconIndex)}
                                                                placeholder="Pilih Icon"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <TextField
                                                                label="Subtitle"
                                                                name="short_landing_page_sections.icons.subtitle"
                                                                value={icon.subtitle}
                                                                error={!!error.short_landing_page_sections}
                                                                helperText={error.short_landing_page_sections}
                                                                variant="outlined"
                                                                fullWidth
                                                                onChange={e => handleSectionIconSubtitle(e, index, iconIndex)}
                                                            />
                                                        </Grid>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Grid>
                                    </Paper>
                                </Grid>
                            )
                        }
                        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                            <Button variant='contained' color='primary' onClick={addSection}>
                                Add Section
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h6' component={'p'}>
                                Gallery
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                label="Background Color"
                                name="bg_gallery_color"
                                value={!['', 'default'].includes(state.bg_gallery_color) ? 'custom' : state.bg_gallery_color}
                                onChange={handleChange}
                                error={!!error.bg_gallery_color}
                                helperText={error.bg_gallery_color}
                            >
                                <MenuItem value="default">Default</MenuItem>
                                <MenuItem value="custom">Custom</MenuItem>
                            </TextField>
                        </Grid>
                        {
                            state.bg_gallery_color !== '' ?
                                <Grid item xs={12} style={{ paddingTop: 8 }}>
                                    <ColorPicker
                                        name='bg_gallery_color'
                                        value={
                                            state.bg_gallery_color === 'default' ?
                                                DEFAULT_COLORS.galleryBackground[state.is_dark_mode ? 'dark' : 'light']
                                                :
                                                state.bg_gallery_color === 'custom' ? '' : state.bg_gallery_color
                                        }
                                        onChange={handleChangeColor}
                                        disabled={state.bg_gallery_color === 'default'}
                                    />
                                </Grid>
                                : null
                        }
                        <Grid item xs={12}>
                            <ButtonUpload
                                onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>)}
                                name="short_landing_page_galleries"
                                accept='image/x-png,image/jpeg,.webp'
                                multiple
                                containerStyle={{
                                    margin: 0,
                                    marginRight: 12,
                                    marginBottom: 12,
                                }}
                            />
                            {
                                state.short_landing_page_galleries.map((file, index) =>
                                    <PhotoPreview
                                        key={file.url}
                                        index={index}
                                        onRemoveFile={() => handleRemoveImage('short_landing_page_galleries', index)}
                                        src={file.url}
                                        style={{
                                            margin: 0,
                                            marginRight: 12,
                                            marginBottom: 12,
                                        }}
                                    />
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className={classes.fab}>
                <Tooltip title="Preview" className={classes.fabSpacing} placement="top" arrow>
                    <Fab aria-label="preview" color="primary" href={`${process.env.REACT_APP_MAIN_URL}/shortv2/${slug}`} target="_blank">
                        <Visibility />
                    </Fab>
                </Tooltip>

                <Tooltip title="Save" className={classes.fabSpacing} placement="top" arrow>
                    <Fab aria-label="Save" color="primary" onClick={submit}>
                        <Save />
                    </Fab>
                </Tooltip>
            </div>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    switch: {
        width: '100%',
        marginLeft: 0,
        '& > .MuiFormControlLabel-label': {
            marginRight: 'auto',
        },
    },
    fab: {
        position: 'fixed',
        bottom: '2rem',
        right: '2rem',
        display: 'none',
        zIndex: 1,
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    fabSpacing: {
        marginRight: '1rem',
    },
    title: {
        fontSize: 16,
        color: '#484848',
        marginBottom: 12,
        fontWeight: 'bold',
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > .error': {
            color: theme.palette.error.main,
            marginTop: 12,
        }
    },
    line: {
        display: 'block',
        width: '100%',
        height: 1,
        backgroundColor: '#cacaca',
        margin: '16px 0px 16px',
    },
    metaContainer: {
        '& .title': {
            fontSize: 16,
            fontWeight: '500',
        },
        '& .subtitle': {
            fontWeight: '500',
        }
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    submitContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: 22
    },
    listContainer: {
        width: '100%',
        '& > *': {
            paddingBottom: 16,
            paddingTop: 16,
        },
        '& > *:first-of-type': {
            paddingTop: 0,
        },
    },
    switchContainer: {
        display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 22
    },
}), 'SLP_V2_Form')

const INITIAL_ICON = {
    icon_id: '',
    subtitle: '',
    file_url: '',
    name: '',
}

const INITIAL_HEADER_POINTS = {
    title: '',
    subtitle: '',
}

const INITIAL_SECTION: Section = {
    title: '',
    text_color: '',
    background_color: '',
    icon_per_line: '',
    icons: [
        INITIAL_ICON,
        INITIAL_ICON,
        INITIAL_ICON,
        INITIAL_ICON,
    ]
}

const INITIAL_STATE: State = {
    is_dark_mode: false,
    is_hide_icon_cariproperti: false,
    title: '',
    slp_background_image: null,
    slp_icon_image: null,
    header_points: [
        INITIAL_HEADER_POINTS,
        INITIAL_HEADER_POINTS,
        INITIAL_HEADER_POINTS,
    ],
    short_landing_page_sections: [
        INITIAL_SECTION,
    ],
    bg_gallery_color: '',
    short_landing_page_galleries: []
}

const DEFAULT_COLORS = {
    sectionText: {
        odd: {
            dark: '#ffffff',
            light: '#000000',
            gold: '#ffd700',
        },
        even: {
            dark: '#000000',
            light: '#ffffff',
            gold: '#ffd700',
        },
    },
    sectionBackground: {
        odd: {
            dark: '#101F30',
            light: '#ffffff',
        },
        even: {
            dark: '#ffffff',
            light: '#035AA6',
        },
    },
    galleryBackground: {
        dark: '#1D3856',
        light: '#e9e9e9',
    },
}

export default V2Form
