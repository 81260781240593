import React, { useEffect, useState } from 'react'
import { Collapse, Divider, Switch, Typography } from '@mui/material'

interface CollapsibleProps {
    defaultOpen?: boolean
    label: string
    children: React.ReactNode
}

const Collapsible = (props: CollapsibleProps) => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (props.defaultOpen) {
            setIsOpen(true)
        }
    }, [props.defaultOpen])

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingBottom: 8,
                }}
            >
                <Typography variant='h6'>
                    {props.label}
                </Typography>
                <Switch
                    checked={isOpen}
                    onChange={e => setIsOpen(e.target.checked)}
                />
            </div>
            <Divider style={{marginBottom: 8}} />
            <Collapse
                in={isOpen}
                timeout="auto"
                unmountOnExit
                style={{
                    paddingTop: 4,
                    paddingBottom: 4,
                    marginBottom: 12,
                }}
            >
                {props.children}
            </Collapse>
        </>
    )
}

export default Collapsible
