import React, { useEffect, useReducer, useState, useContext } from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import ContactForm from './ContactForm';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import { generalErrorHandler, inputNumber, renderWarningButton } from '../../_utils/Helper';
import DefaultAxios from '../../_utils/DefaultAxios';

/**
 * Context
 */
import { PermissionContext } from "../../_contexts/PermissionContext";

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { generateStyle } from '../../_utils/DefaultStyles';

export interface IState {
    id: string;
    name: string;
    label: string;
    type: 'developer' | 'kpr_bank';
    phone: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

export interface IErrorState {
    name: string;
    label: string;
    phone: string;
}

const useStyles = generateStyle(theme => ({
    actionContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    addButton: {
        marginBottom: '20px'
    },
    importButton: {
        float: 'right'
    },
    errorContainer: {
        maxHeight: '200px',
        overflowY: 'auto'
    }
}), "Contact_List"
)
const ContactList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/contact';
    const { Root, classes } = useStyles();

    const contactTypes = [
        {
            key: 'developer',
            value: 'Developer'
        },
        {
            key: 'kpr_bank',
            value: 'KPR Bank'
        },
    ];

    const columns = [
        {
            name: 'name',
            label: 'Nama',
            type: 'string',
            filterable: true
        },
        {
            name: 'phone',
            label: 'Phone',
            type: 'string',
            filterable: true
        },
        {
            name: 'label',
            label: 'Label',
            type: 'string',
            filterable: true
        },
        {
            name: 'type',
            label: 'Type',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...contactTypes
            ],
            filterable: true,
            render: (data: any) => {
                return contactTypes.find(contactType => contactType.key === data)?.value;
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                if (permissions['contact.manage']) {
                    return (
                        <div className={classes.actionContainer}>
                            <Tooltip title="Edit">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => handleEdit(row.id)}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton
                                    color="secondary"
                                    component="span"
                                    onClick={() => handleDelete(row.id)}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    );
                } else {
                    return "";
                }
            }
        },
    ];

    const initialState: IState = {
        id: '',
        name: '',
        label: '',
        type: 'developer',
        phone: '',
    };

    const initialErrorState: IErrorState = {
        name: '',
        label: '',
        phone: '',
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);
    const permissions = useContext(PermissionContext);

    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    useEffect(() => {
        if (!modalOpen) {
            setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
            setErrorState({ name: '', value: initialErrorState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [modalOpen]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target;
        let name = target.name;
        let value = target.value;

        if (['phone'].includes(name)) {
            value = inputNumber(value);
        }

        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        }

        if (!inputState.label) {
            newError.label = 'Label wajib diisi';
            isValid = false;
        }

        if (!inputState.phone) {
            newError.phone = 'No Hp wajib diisi';
            isValid = false;
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;

        if (inputState.id) {
            axios = DefaultAxios.patch(`${API_URL}/${inputState.id}`, inputState)
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        handleModalClose();
                        setReDrawDatatable(new Date().getTime());
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleEdit = (id: string) => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${id}`)
            .then(res => {
                const newState = { ...initialState, ...res.data };
                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
                handleModalOpen();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleDelete = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin menghapus kontak ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .delete(`${API_URL}/${id}`)
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000,
                                onAfterClose: () => {
                                    setReDrawDatatable(new Date().getTime());
                                }
                            })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            {permissions['contact.manage'] &&
                <>
                    <ContactForm
                        open={modalOpen}
                        onClose={handleModalClose}
                        state={inputState}
                        contactTypes={contactTypes}
                        errorState={errorState}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.addButton}
                        startIcon={<AddIcon />}
                        onClick={handleModalOpen}
                    >
                        Add New Contact
                    </Button>

                </>
            }
            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </Root>
    );
}

export default ContactList;