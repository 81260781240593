import React, { useContext } from 'react';

/* 
* Context
*/
import { PermissionContext } from '../_contexts/PermissionContext';
import NewDashboardList from './_newDashboard/NewDashboardList';


const Dashboard = () => {
    const permission = useContext(PermissionContext)

    switch (permission['dashboard-type']) {
        case 'superadmin':
            return <NewDashboardList />
        default:
            return (
                <>
                    <h1>Work Hard, Play Hardest</h1>
                </>
            )

    }

}

export default Dashboard
