import React from 'react'

/**
 * Components
 */
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material'
import ButtonWithLoading from '../_form/ButtonWithLoading'

interface GeneralModalProps {
    title: string
    open: boolean
    onClose: () => void
    children: React.ReactNode
    buttons?: ({
        text: string
        color: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning"
        onClick: () => void
        disabled?: boolean
        isLoading?: boolean
    } | React.ReactElement)[]
}

/**
 * General Modal
 * @param props 
 * @returns a general pop up modal that can be used easily on any page
 */
const GeneralModal = (props: GeneralModalProps & Partial<DialogProps>) => {
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            {...props}
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>
                {props.title}
            </DialogTitle>
            <DialogContent
                style={{
                    paddingTop: 8, // Give space for text field in the child
                }}
            >
                {props.children}
            </DialogContent>
            {/* Only render DialogActions if there are buttons */}
            {
                props.buttons?.length ?
                    <DialogActions sx={{ py: 2, px: 3 }}>
                        {
                            props.buttons.map((button, index) =>
                                'text' in button ?
                                    <ButtonWithLoading
                                        key={index}
                                        color={button.color}
                                        onClick={button.onClick}
                                        variant='contained'
                                        isLoading={button.isLoading || false}
                                        disabled={button.disabled}
                                    >
                                        {button.text}
                                    </ButtonWithLoading>
                                    :
                                    <React.Fragment key={index}>
                                        {button}
                                    </React.Fragment>
                            )
                        }
                    </DialogActions>
                    : null
            }
        </Dialog>
    )
}

export default GeneralModal
