import React, { useRef, useState, useEffect } from 'react';
import { Grid, TextField, Button, List, ListItem, ListItemText, ListItemIcon, IconButton } from '@mui/material';
import { Pannellum } from 'pannellum-react';
import Swal from 'sweetalert2';

// Interfaces
import { IState } from './Page';

// Icons
import DeleteIcon from '@mui/icons-material/Delete';

// Assets
import defaultThumbnail from '../../../Assets/Images/png/default-thumbnail-unit.png';

/**
 * Utils
 */
import { renderWarningButton } from '../../../_utils/Helper';
import { generateStyle } from '../../../_utils/DefaultStyles';

interface Props {
    form: IState;
    images: IState[];
    onChange: Function;
    hotspots: any[];
    setHotspots: Function;
    index: number;
    onHotspotsChange: Function;
}

const useStyles = generateStyle(theme => ({
    listContainer: {
        maxHeight: '150px',
        border: '1px solid #eee',
        borderRadius: '5px',
        marginBottom: '10px',
        overflow: 'auto'
    },
    active: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff'
        }
    }
}), "Form_360Form")

const Photo360Form = (props: Props) => {
    const { form, images, hotspots, setHotspots } = props;
    const myRef = useRef<any>(null);
    const {Root, classes} = useStyles()

    const [activeHotspot, setActiveHotspot] = useState<IState>({} as IState);
    const [image360, setImage360] = useState('');
    const [initialView, setInitialView] = useState(false);
    const [tmpHotspotInfo, setTmpHotspotInfo] = useState<any>({});

    useEffect(() => {
        if (form.thumbnail_360) {
            setActiveHotspot({} as IState);
            setImage360(form.thumbnail_360 ? form.thumbnail_360 : '');
            setHotspots(form.hotspot);
        }
        // eslint-disable-next-line
    }, [form.thumbnail_360]);

    const handleAddHotspot = () => {
        if (!activeHotspot.id) {
            Swal.fire({
                title: "Please select a hotspot target first",
                icon: 'warning'
            });
            return;
        }

        const viewer = myRef.current!.getViewer();
        const newHotspotInfo = {
            yaw: viewer.getYaw(),
            pitch: viewer.getPitch(),
            text: activeHotspot.caption,
            sceneId: activeHotspot.id,
            type: 'scene'
        };
        setTmpHotspotInfo({ ...newHotspotInfo });
        setInitialView(true);
        setImage360(activeHotspot.thumbnail_360);
    }

    const handleSetInitial = () => {
        const oldHotspotInfo = { ...tmpHotspotInfo };
        const oldHotspots = hotspots.slice();
        const viewer = myRef.current!.getViewer();
        oldHotspotInfo.targetYaw = viewer.getYaw();
        oldHotspotInfo.targetPitch = viewer.getPitch();
        oldHotspots.push(oldHotspotInfo);
        props.onHotspotsChange(oldHotspots)
            .then(() => {
                setTmpHotspotInfo({ ...oldHotspotInfo });
                setInitialView(false);
                setImage360(form.thumbnail_360 ? form.thumbnail_360 : '');
                setHotspots(oldHotspots);
            });
    }

    const handleCancelInitial = () => {
        setTmpHotspotInfo({});
        setInitialView(false);
        setImage360(form.thumbnail_360 ? form.thumbnail_360 : '');
    }

    const handleDeleteHotspot = (index: number) => {
        renderWarningButton("Apakah anda yakin ingin menghapus hotspot?")
            .then((result) => {
                if (result.value) {
                    const oldHotspots = hotspots.slice();
                    oldHotspots.splice(index, 1);
                    props.onHotspotsChange(oldHotspots)
                        .then(() => {
                            setHotspots(oldHotspots);
                        });
                }
            })
    }

    return (
        <Root>
            <Grid container spacing={3}>
                {
                    form.id
                        ? <>
                            <Grid item xs={2}>
                                Panorama View
                            </Grid>
                            <Grid item xs={6}>
                                <Pannellum
                                    ref={myRef}
                                    width="100%"
                                    image={`${image360}?x-request=html`}
                                    autoLoad
                                    showZoomCtrl={false}
                                    hotspotDebug
                                >
                                    {
                                        !initialView
                                            ? hotspots.map(hotspot => {
                                                return <Pannellum.Hotspot
                                                    key={hotspot.sceneId}
                                                    type="info"
                                                    pitch={hotspot.pitch}
                                                    yaw={hotspot.yaw}
                                                    text={hotspot.text}
                                                />
                                            })
                                            : []
                                    }
                                </Pannellum>
                            </Grid>
                            <Grid item xs={2}>
                                Hotspot Target:
                                <br />
                                <List className={classes.listContainer}>
                                    {images.map(image => (
                                        <ListItem
                                            key={image.id}
                                            button
                                            className={activeHotspot.id === image.id ? classes.active : undefined}
                                            onClick={() => setActiveHotspot(image)}
                                        >
                                            <ListItemText>{image.caption}</ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                                {
                                    !initialView
                                        ? <Button
                                            // color="default"
                                            variant="contained"
                                            onClick={handleAddHotspot}
                                        >
                                            Add Hotspot
                                        </Button>
                                        : null
                                }
                                {
                                    initialView
                                        ? <>
                                            <Button
                                                // color="default"
                                                variant="contained"
                                                onClick={handleSetInitial}
                                            >
                                                Set Initial View Point
                                            </Button>
                                            <Button
                                                fullWidth
                                                color="secondary"
                                                variant="contained"
                                                onClick={handleCancelInitial}
                                            >
                                                Cancel
                                            </Button>
                                        </>
                                        : null
                                }
                            </Grid>
                            <Grid item xs={2}>
                                Hotspot List
                                <br />
                                {
                                    hotspots.length
                                        ? <List className={classes.listContainer}>
                                            {hotspots.map((hotspot, key) => (
                                                <ListItem
                                                    key={hotspot.text}
                                                    button
                                                >
                                                    <ListItemIcon>
                                                        <IconButton edge="end" aria-label="comments" color="secondary" onClick={() => handleDeleteHotspot(key)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </ListItemIcon>
                                                    <ListItemText>{hotspot.text}</ListItemText>
                                                </ListItem>
                                            ))}
                                        </List>
                                        : null
                                }
                            </Grid>
                        </>
                        : null
                }

                <Grid item xs={2}>
                    360 Photo
                </Grid>
                <Grid item xs={10}>
                    <img src={form.thumbnail_360 ? form.thumbnail_360 : defaultThumbnail} width="150" alt={form.alt} style={{ backgroundColor: '#fafafa' }} />
                    <br />
                    <input type="file" name="file_360" onChange={(e) => props.onChange(e, props.index)} />
                </Grid>

                <Grid item xs={2}>
                    Thumbnail
                </Grid>
                <Grid item xs={10}>
                    <img src={form.thumbnail ? form.thumbnail : defaultThumbnail} width="72" alt={form.alt} style={{ backgroundColor: '#fafafa' }} />
                    <br />
                    <input type="file" name="file" onChange={(e) => props.onChange(e, props.index)} />
                    <br />
                    <i>Suggested dimension : 72 x 72</i>
                </Grid>

                <Grid item xs={2}>
                    Caption
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        fullWidth
                        name="caption"
                        variant="outlined"
                        value={form.caption}
                        onChange={(e) => props.onChange(e, props.index)}
                    />
                </Grid>
            </Grid>
        </Root>
    );
}

export default Photo360Form;
