import React, { useState } from 'react'

/**
 * Components
 */
import { Button, IconButton, Modal, Paper, TextField, Theme, useTheme } from '@mui/material';

/**
 * Utils
 */
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, PickersDay, PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import styled from '@emotion/styled';
import { isSameDay, isWithinInterval } from 'date-fns';

/**
 * Icons
 */
import { Close } from '@mui/icons-material';
import { generateStyle } from '../../_utils/DefaultStyles';

export type DateRange = Date | null | moment.Moment
export type OnDatesChange = { startDate: DateRange, endDate: DateRange }

interface DateRangePickerProps {
    startDate: DateRange
    endDate: DateRange
    format?: string
    onDatesChange: ({ startDate, endDate }: OnDatesChange) => void
    onFinished: () => void
    dateMaxWidth?: number | string
    fullWidth?: boolean
    isApp?: boolean
}

moment.updateLocale('en', {
    week: {
        dow: 1,
    }
})

const DateRangePicker = (props: DateRangePickerProps) => {
    const { Root: ModalRoot, classes } = useModalStyles()
    const { Root: InputRoot, classes: inputClasses } = useStyles()
    const theme = useTheme<Theme>()

    const [isOpen, setIsOpen] = useState(false)
    const [displayedCalendar, setDisplayedCalendar] = useState<'start' | 'end'>('start')

    const handleOpen = (type: 'start' | 'end') => {
        setDisplayedCalendar(type)
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
        props.onFinished()
    }

    const renderWeekPickerDay = (
        date: Date,
        selectedDates: Array<Date | null>,
        pickersDayProps: PickersDayProps<Date>,
    ) => {
        if (!props.startDate) {
            return <PickersDay {...pickersDayProps} />;
        }

        const start = moment(props.startDate).startOf('day').toDate();
        const end = props.endDate ? moment(props.endDate).endOf('day').toDate() : start;

        const dayIsBetween = isWithinInterval(date, { start, end });
        const isFirstDay = isSameDay(date, start);
        const isLastDay = isSameDay(date, end);

        return (
            <CustomPickersDay
                {...pickersDayProps}
                disableMargin
                dayIsBetween={dayIsBetween}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
            />
        );
    };

    return (
        <InputRoot>
            <div style={{ display: 'inline-block', width: props.fullWidth ? '100%' : undefined }}>
                <div
                    className={inputClasses.inputContainer}
                    style={{
                        ...(props.isApp ? {
                            padding: '16.5px 14px',
                            border: (props.endDate || props.startDate) ? `1px solid ${theme.palette.primary.main}` : '',
                            background: (props.endDate || props.startDate) ? `#D2EBFF` : ''
                        } : {}),
                        border: isOpen ? `1px solid ${theme.palette.primary.main}` : '',
                    }}
                >
                    <span
                        className={`${inputClasses.input} ${props.startDate ? 'filled' : ''}`}
                        onClick={() => handleOpen('start')}
                        style={{
                            ...(props.isApp ? { padding: 0 } : {}),
                            color: isOpen && displayedCalendar === 'start' ? theme.palette.primary.main : undefined,
                            fontWeight: isOpen && displayedCalendar === 'start' ? '500' : '400',
                            maxWidth: props.dateMaxWidth,
                        }}
                    >
                        {props.startDate ? moment(props.startDate).format(props.format || 'DD/MM/YYYY') : 'Start'}
                    </span>
                    <span style={props.isApp ? {marginLeft: 10, marginRight: 20} : {}}>-</span>
                    <span
                        className={`${inputClasses.input} ${props.endDate ? 'filled' : ''}`}
                        onClick={() => handleOpen('end')}
                        style={{
                            ...(props.isApp ? { padding: 0 } : {}),
                            color: isOpen && displayedCalendar === 'end' ? theme.palette.primary.main : undefined,
                            fontWeight: isOpen && displayedCalendar === 'end' ? '500' : '400',
                            maxWidth: props.dateMaxWidth,
                        }}
                    >
                        {props.endDate ? moment(props.endDate).format(props.format || 'DD/MM/YYYY') : 'End'}
                    </span>
                    {
                        (props.startDate || props.endDate) &&
                        <IconButton
                            color="default"
                            onClick={() => {
                                props.onDatesChange({ startDate: null, endDate: null })
                                handleOpen('start')
                            }}
                            style={props.isApp ? {marginLeft: 'auto'} : {}}
                            size='small'
                        >
                            <Close />
                        </IconButton>
                    }
                </div>
            </div>
            <ModalRoot
                open={isOpen}
                onClose={handleClose}
            >
                <Paper className={classes.paper}>
                    <div className={classes.calendarContainer}>
                        <div className={classes.buttonsContainer}>
                            <Button color={displayedCalendar === 'start' ? undefined : 'inherit'} onClick={() => setDisplayedCalendar('start')}>
                                Start Date
                            </Button>
                            <span>-</span>
                            <Button color={displayedCalendar === 'end' ? undefined : 'inherit'} onClick={() => setDisplayedCalendar('end')}>
                                End Date
                            </Button>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {
                                displayedCalendar === 'start' ?
                                    <StaticDatePicker
                                        maxDate={props.endDate ? moment(props.endDate).toDate() : undefined}
                                        displayStaticWrapperAs="desktop"
                                        value={props.startDate}
                                        inputFormat={props.format ? props.format : "DD/MM/YYYY"}
                                        onChange={newValue => {
                                            let endDate = props.endDate

                                            if (newValue && endDate) {
                                                if (newValue.getTime() > moment(endDate).toDate().getTime()) {
                                                    endDate = newValue
                                                }
                                            }

                                            props.onDatesChange({ startDate: newValue, endDate })
                                            setDisplayedCalendar('end')
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        renderDay={renderWeekPickerDay}
                                    />
                                    :
                                    <StaticDatePicker
                                        minDate={props.startDate ? moment(props.startDate).toDate() : undefined}
                                        displayStaticWrapperAs="desktop"
                                        value={props.endDate}
                                        inputFormat={props.format ? props.format : "DD/MM/YYYY"}
                                        onChange={newValue => {
                                            let startDate = props.startDate

                                            if (newValue && startDate) {
                                                if (moment(startDate).toDate().getTime() > newValue.getTime()) {
                                                    startDate = newValue
                                                }
                                            }

                                            props.onDatesChange({ startDate, endDate: newValue })
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        renderDay={renderWeekPickerDay}
                                    />
                            }
                        </LocalizationProvider>
                        <div className={classes.footer}>
                            <Button variant='contained' onClick={handleClose}>
                                Done
                            </Button>
                        </div>
                    </div>
                    <div className={classes.presetContainer}>
                        <p className={classes.presetTitle}>
                            DATE PRESETS
                        </p>
                        <div className={classes.optionsContainer}>
                            {
                                datePresets.map((preset, index) =>
                                    <div
                                        key={index}
                                        className={classes.presetButton}
                                        onClick={() => {
                                            props.onDatesChange({ startDate: preset.from, endDate: preset.to })
                                        }}
                                    >
                                        <p className={`${classes.presetText} ${(props.startDate === preset.from && props.endDate === preset.to) ? classes.presetTextActive : ''}`}>
                                            {preset.label}
                                        </p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Paper>
            </ModalRoot>
        </InputRoot>
    )
}

export default DateRangePicker

type CustomPickerDayProps = PickersDayProps<Date> & {
    theme?: Theme
    dayIsBetween: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
    '&:not(.Mui-selected)': {
        border: 'none',
    }
})) as React.ComponentType<CustomPickerDayProps>;

const useModalStyles  = generateStyle(theme => ({
    paper: {
        width: '100%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            maxWidth: 500,
            flexDirection: 'row',
            alignItems: 'flex-start',
        }
    },
    calendarContainer: {
        borderBottom: '1px solid #e0e0e0',
        [theme.breakpoints.up('md')]: {
            borderRight: '1px solid #e0e0e0',
            borderBottom: 'none',
        }
    },
    presetContainer: {
        width: '100%',
        paddingBottom: 20,
        [theme.breakpoints.up('md')]: {
            paddingBottom: 0,
        }
    },
    presetTitle: {
        fontSize: 14,
        fontWeight: '500',
        padding: 16,
        paddingTop: 20,
        paddingBottom: 20,
        margin: 0,
    },
    optionsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        gridTemplateRows: 'repeat(4, minmax(0, 1fr))',
        gridAutoFlow: 'column',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        }
    },
    presetButton: {
        padding: '4px 16px 6px',
        cursor: 'pointer',
        transition: 'color 150ms linear',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    presetText: {
        fontSize: 14,
        margin: 0,
        fontWeight: '500',
    },
    presetTextActive: {
        color: theme.palette.primary.main,
    },
    buttonsContainer: {
        paddingLeft: 16,
        paddingTop: 16,
    },
    footer: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}), "DateRange_Picker", {
    CustomParent: Modal
})

const useStyles = generateStyle(theme => ({
    inputContainer: {
        display: 'flex',
        borderRadius: 4,
        alignItems: 'center',
        border: '1px solid #0000003b',
    },
    input: {
        fontSize: 15,
        lineHeight: '18px',
        letterSpacing: .2,
        padding: '10px 7px 10px',
        color: '#959595',
        cursor: 'pointer',
        width: '100%',
        overflow: 'hidden',
        textAlign: 'left',
        '&.filled': {
            color: '#484848',
        }
    }
}), "DateRangePicker_input")

const datePresets = [
    {
        label: 'Maximum',
        from: null,
        to: null,
    },
    {
        label: 'Today',
        from: moment(),
        to: moment(),
    },
    {
        label: 'Yesterday',
        from: moment().subtract(1, 'days'),
        to: moment().subtract(1, 'days'),
    },
    {
        label: 'Last 7 days',
        from: moment().subtract(7, 'days'),
        to: moment().subtract(1, 'days'),
    },
    {
        label: 'Last 14 days',
        from: moment().subtract(14, 'days'),
        to: moment().subtract(1, 'days'),
    },
    {
        label: 'Last 30 days',
        from: moment().subtract(30, 'days'),
        to: moment().subtract(1, 'days'),
    },
    {
        label: 'This week',
        from: moment().startOf('week'),
        to: moment().endOf('week'),
    },
    {
        label: 'Last week',
        from: moment().subtract(1, 'week').startOf('week'),
        to: moment().subtract(1, 'week').endOf('week'),
    },
    {
        label: 'This month',
        from: moment().startOf('month'),
        to: moment().endOf('month'),
    },
    {
        label: 'Last month',
        from: moment().subtract(1, 'month').startOf('month'),
        to: moment().subtract(1, 'month').endOf('month'),
    },
]