import React, { useState } from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton, Theme, Typography } from '@mui/material'

/* 
* Icons
*/
import CloseIcon from '@mui/icons-material/Close';
import slider from '../../Assets/Images/svg/slider.svg'
import warning from '../../Assets/Images/svg/warning.svg'
import { Box } from '@mui/system';

/* 
* Utils 
*/

import { generateStyle } from '../../_utils/DefaultStyles'; 

/* 
* Interfaces
*/

interface IProps {
    open: boolean,
    onClose: () => void,
    onSubmit: () => void
    customMessage?: React.ReactNode
}

const useStyles = generateStyle((theme: Theme) => ({
    header: {
        position: 'relative'
    },
    icon: {
        position: 'absolute',
        right: 6,
        top: 6,
        color: theme.palette.grey[500],
    },
    image: {
        width: '100px'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    slider: {
        display: 'flex',
        width: '100%',
        height: '64px',
        borderRadius: '32px',
        position: 'relative',
        marginTop: '10px',
        background: "#EFEFEF 0% 0% no-repeat padding-box",
    },
    sliderInput: {
        position: 'absolute',
        top: 0,
        left: 0,
        padding: "5px",
        background: 'transparent',
        margin: 0,
        appearance: 'none',
        height: '64px',
        borderRadius: '32px',
        width: '100%',
        zIndex: 1,
        pointerEvents: 'none',
        '&::-webkit-slider-thumb': {
            pointerEvents: 'auto',
            zIndex: 3,
            appearance: 'none',
            cursor: "grab",
            background: `url('${slider}')`,
            width: '54px',
            height: '54px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        },
        '&::-moz-range-thumb': {
            pointerEvents: 'auto',
            zIndex: 3,
            appearance: 'none',
            cursor: "grab",
            background: `url('${slider}')`,
            width: '54px',
            height: '54px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            border: 'none'
        }
    },
    placeholder: {
        zIndex: 1,
        margin: 'auto',
        color: '#484848'
    }
}), "Prompt_Modal"
)

const SliderModal = ({ open, onClose, onSubmit, customMessage }: IProps) => {
    const { Root, classes } = useStyles()
    const [sliderVal, setSliderVal] = useState(0)

    const handleChangeSlider = (e: any) => {
        setSliderVal(+e.target.value)
    }

    const handleUpSlider = () => {
        if (sliderVal === 100) {
            onSubmit()
            onClose()
        } else {
            setSliderVal(0)
        }
        setSliderVal(0)
    }

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={onClose}
        >
            <Root>
                <DialogTitle className={classes.header}>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        className={classes.icon}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <img src={warning} alt="warning" className={classes.image} />
                    <Box component="div" sx={{ mt: 2, p: 1 }}>
                        {
                            typeof customMessage !== 'undefined' ?
                            customMessage : 
                            <Typography>Lanjutkan ?</Typography>
                        }
                        <Box component="div" className={classes.slider}>
                            <span className={classes.placeholder} style={{ opacity: `${100 - sliderVal}%` }}>Slide to Confirm</span>
                            <input
                                type="range"
                                value={sliderVal}
                                className={classes.sliderInput}
                                onMouseUp={handleUpSlider}
                                onTouchEnd={handleUpSlider}
                                onChange={handleChangeSlider}
                                placeholder="Slide to Confirm"
                                min={0}
                                max={100}
                            />
                        </Box>
                    </Box>
                </DialogContent>
            </Root>
        </Dialog>
    )
}

export default SliderModal