import React, { useState, useEffect, useReducer } from 'react';
import {
    TextField,
    Grid,
    Card,
    Divider,
    DialogContent,
    DialogActions,
    CardContent,
    Typography,
    IconButton,
    Button,
    Dialog,
    Switch,
    DialogTitle,
    InputAdornment,
    Tooltip,
    Fab,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Theme,
    Paper
} from '@mui/material';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import TextEditor from '../../_components/_form/TextEditor';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

/**
 * Utils
 */
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import helper from '../../_utils/Helpers';
import helpers from '../../_utils/Helpers';
import DefaultAxios from '../../_utils/DefaultAxios'
import Swal from 'sweetalert2';
import { useSnackbar } from 'notistack'
import { generalErrorHandler, inputNumberTwo } from '../../_utils/Helper';

/**
 * Icon
 */
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import ListIcon from '@mui/icons-material/List';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { generateStyle } from '../../_utils/DefaultStyles';

const useStyles = generateStyle((theme: Theme) => ({
    fab: {
        position: 'fixed',
        bottom: '2rem',
        right: '2rem',
        display: 'none',
        zIndex: 1,
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    fabSpacing: {
        marginRight: '1rem',
    },
    hr: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    hr2: {
        marginTop: theme.spacing(2),
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    card: {
        marginBottom: theme.spacing(2),
    },
    groupCard: {
        marginBottom: theme.spacing(2),
        border: "dotted 3px #000"
    },
    addButton: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    deleteSectionGroup: {
        marginBottom: theme.spacing(1),
        float: 'right',
        minWidth: 'fit-content'
    },
    elementButton: {
        marginBottom: theme.spacing(2),
    },
    elementButtonText: {
        fontSize: '13px',
        textAlign: 'left'
        // fontWeight: 'bold'
    },
    modalElement: {
        padding: theme.spacing(2),
    },
    label: {
        fontSize: 14,
        color: '#484848',
        marginBottom: 12,
        fontWeight: 500,
    },
    spacer: {
        display: 'block',
        width: '100%',
        height: 16
    },
    spacerTitle: {
        display: 'block',
        width: '100%',
        height: 6
    },
    cardElementForm: {
        position: 'sticky',
        top: '88px',
        maxHeight: '68vh',
        overflow: 'auto'
    },
    mainContainer: {
        margin: 'auto'
    },
    menu: {
        zIndex: 1,
        position: 'fixed',
        bottom: '2rem',
        left: 0,
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        '& .menu-button': {
            borderRadius: '2rem',
            padding: '0.4rem 1.5rem',
            color: 'black',
            backgroundColor: 'white',
            fontWeight: 400,
            '& *': {
                textTransform: 'capitalize'
            }
        }
    },
    switchContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    switchContainer2: {
        display: "flex",
        alignItems: "center"
    },
}), "ClusterCustom_Page"
);


type SubSectionType = 'text' | 'image' | 'button' | 'whatsapp_button' | 'call_button' | 'divider' | 'video' | 'faq' | 'review' | 'iframe'
interface IUploadState {
    id: string;
    name: string;
    is_optimize: number;
    file: File | null;
    thumbnail: string;
}

interface IErrorUploadState {
    name: string;
    file: string;
}

interface IErrorUrl {
    url_sectionGroup: string;
    url_element: string;
    left_url: string;
    right_url: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}
export interface ISubSection {
    type: SubSectionType
    title: string
    /**
     * string of React.CSSProperties
     */
    css: string
    css_mobile: string
}

export interface IText extends ISubSection {
    type: 'text'
    content: string
}

export interface IImage extends ISubSection {
    type: 'image'
    url: string
}

export interface IButton extends ISubSection {
    type: 'button' | 'whatsapp_button' | 'call_button'
    url?: string
    icon?: '' | 'url' | 'whatsapp' | 'pdf'
    classname?: string
    pretext?: string
}

export interface IDivider extends ISubSection {
    type: 'divider'
}

export interface IVideo extends ISubSection {
    type: 'video'
    /**
     * Bisa menggunakan url watch maupun embed untuk YouTube
     */
    url: string
}

export interface IFrame extends ISubSection {
    type: 'iframe'
    url: string
}

export interface IFaq extends ISubSection {
    type: 'faq'
    content: SubFaq[]
}

export interface IReview extends ISubSection {
    type: 'review'
    summary: ''
    content: SubReview[]
}

interface SubFaq {
    question: string
    answer: string
}

interface SubReview {
    title: string
    rating: string
}

type SubSections = IText | IImage | IButton | IDivider | IVideo | IFaq | IReview | IFrame
type SubSectionsUnion = {
    type: SubSectionType
    title: string
    css: string
    css_mobile: string
    content?: string | []
    url?: string
    text?: string
    icon?: '' | 'url' | 'whatsapp' | 'pdf'
    summary?: string
    classname?: string
    pretext?: string
}

interface Props {
    clusterSlug: string;
    clusterId: string | null;
    customPageType: number;
}

interface IDefaultSection {
    active: number
    type: 'section' | 'group' | 'cta'
    title: string
    /**
     * url for background image
     */
    url: string
    /**
     * string of React.CSSProperties
     */
    css: string
    css_mobile: string
    image_css: string
    anchor?: string
    expanded: boolean
    button_color?: string
    left_title?: string
    left_url?: string
    left_icon?: string
    left_classname?: string
    left_pretext?: string
    right_title?: string
    right_url?: string
    right_icon?: string
    right_classname?: string
}

export interface ISection extends IDefaultSection {
    type: 'section'
    content: SubSections[]
}

export interface IGroupSection extends IDefaultSection {
    type: 'group'
    content: ISection[]
}

export interface ICta extends IDefaultSection {
    type: 'cta'
    title: string
    left_title: string
    left_url: string
    left_icon: string
    left_classname: string
    left_pretext: string
    right_title: string
    right_url: string
    right_icon: string
    right_classname: string
}
interface IGroupOrSection extends IDefaultSection {
    /**
     * if type is equal to 'section' it will contain list of subsection,
     * 
     * otherwise it will contain list of section
     */
    content: (SubSections | ISection)[]
}

interface INewElement {
    section: number | undefined
    group: number | undefined
}

interface IActiveElementForm {
    elementIndex: number
    sectionIndex: number
    groupIndex?: number
}

interface IActiveSectionGroupForm {
    groupOrSectionIndex: number
    sectionIndex?: number
}

interface BlogData {
    id: string
    blog_title: string
    blog_article: string
}

const ClusterCustomPage = (props: Props) => {
    const initialUploadState = {
        id: '',
        name: '',
        is_optimize: 0,
        file: null,
        thumbnail: ''
    };

    const initialUploadErrorState = {
        name: '',
        file: ''
    };

    const initialErrorUrl = {
        url_sectionGroup: '',
        url_element: '',
        left_url: '',
        right_url: ''
    }
    const inputReducer = (state: IUploadState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialUploadState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IUploadState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorUploadState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialUploadErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorUploadState };
        }

        return { ...state };
    };

    const errorReducerUrl = (state: IErrorUrl, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorUrl };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorUrl };
        }

        return { ...state };
    };

    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/custom-page-builder';
    const API_URL_UPLOAD = process.env.REACT_APP_API_URL + '/asset';
    const { enqueueSnackbar } = useSnackbar()
    const [uploadFor, setUploadFor] = useState('')
    const [groupIndexGlobal, setGroupIndexGlobal] = useState(0)
    const [sectionIndexGlobal, setSectionIndexGlobal] = useState<undefined | number>(undefined)
    const [elementIndexGlobal, setElementIndexGlobal] = useState(0)
    const [sectionElementIndexGlobal, setSectionElementIndexGlobal] = useState(0)
    const [groupElementIndexGlobal, setGroupElementIndexGlobal] = useState<undefined | number>(undefined)
    const [inputUploadState, setInputUploadState] = useReducer(inputReducer, initialUploadState);
    const [errorUploadState, setErrorUploadState] = useReducer(errorReducer, initialUploadErrorState);
    const [errorUrl, setErrorUrl] = useReducer(errorReducerUrl, initialErrorUrl);
    const [loading, setLoading] = useState(false);
    const [modalUpload, setModalUpload] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openNewElement, setOpenNewElement] = React.useState(false);
    const [isCustomPage, setIsCustomPage] = useState(false)
    const [openCopy, setOpenCopy] = React.useState(false);
    const [copyButton, setCopyButton] = React.useState(false);
    const [isCTA, setIsCTA] = useState(0)

    const [copyState, setCopyState] = useState({
        label: '',
        id: ''
    })
    const [newElementFlag, setNewElementFlag] = useState<INewElement>({
        section: undefined,
        group: undefined
    })
    const [blogData, setBlogData] = useState<BlogData>({
        id: '',
        blog_title: '',
        blog_article: '',
    })

    const toggleCustomPage = () => {
        setLoading(true)
        const data = {
            id: props.clusterId
        }
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/cluster/toggle-custom-page`, data)
            .then(res => res.data)
            .then((data: any) => {
                if (data) {

                }
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleCustomPageSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCustomPage(event.target.checked)
        toggleCustomPage()
    };

    const handleUploadOpen = (groupOrSectionIndex: number, sectionIndex?: number) => {
        setUploadFor('groupOrSection')
        setGroupIndexGlobal(groupOrSectionIndex)
        if (sectionIndex) {
            setSectionIndexGlobal(sectionIndex)
        }
        setModalUpload(true);
    }

    const handleUploadOpenElement = (elementIndex: number, sectionIndex: number, groupIndex?: number) => {
        setUploadFor('element')
        setElementIndexGlobal(elementIndex)
        setSectionElementIndexGlobal(sectionIndex)
        if (groupIndex) {
            setGroupElementIndexGlobal(groupIndex)
        }
        setModalUpload(true);
    }

    const handleUploadClose = () => setModalUpload(false);

    const handleChangeUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const name = target.name;
        let value: any = target.value;
        const newState: any = { ...inputUploadState };

        if (target.name === 'file') {
            if (target.files && target.files[0]) {
                const file = target.files[0];
                newState['thumbnail'] = URL.createObjectURL(file);
                newState['file'] = file;
            }
        } else {
            newState[name] = value;
        }

        setInputUploadState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleSwitchUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { ...inputUploadState };
        newState.is_optimize = event.target.checked ? 1 : 0

        setInputUploadState({ name: '', value: newState, type: 'REPLACE_STATE' });
    };

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialUploadErrorState };

        if (!inputUploadState.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        }

        if (!inputUploadState.id && !inputUploadState.file) {
            newError.file = 'File wajib diisi';
            isValid = false;
        }

        setErrorUploadState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmitUpload = () => {
        if (!checkValidation()) {
            return;
        }

        const fd = new FormData();
        fd.append('name', inputUploadState.name);
        fd.append('is_optimize', inputUploadState.is_optimize ? '1' : '0')

        let axios;

        if (inputUploadState.id) {
            axios = DefaultAxios.post(API_URL_UPLOAD + '/' + inputUploadState.id, fd);
            fd.append('_method', 'patch');
        } else {
            fd.append('file', inputUploadState.file || '');
            axios = DefaultAxios.post(API_URL_UPLOAD, fd);
        }

        setLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Asset submitted",
                    icon: 'success',
                    onAfterClose: () => {
                        handleUploadClose();
                    },
                    timer: 1000
                })
                if (uploadFor === 'groupOrSection') {
                    handleSectionGroupFormChange('url', res.data, groupIndexGlobal, sectionIndexGlobal)
                } else if (uploadFor === 'element') {
                    handleElementFormChange('url', res.data, elementIndexGlobal, sectionElementIndexGlobal, groupElementIndexGlobal)
                }
                setInputUploadState({ name: '', value: '', type: 'RESET_ITEM' });
                setUploadFor('')
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setCopyState({
            label: value.label,
            id: value.id.toString()
        })
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setCopyState({
            label: '',
            id: ''
        })
    }

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const [data, setData] = useState<IGroupOrSection[]>([])

    const [activeElementForm, setActiveElementForm] = useState<IActiveElementForm | null>(null)

    const [activeSectionGroupForm, setActiveSectionGroupForm] = useState<IActiveSectionGroupForm | null>(null)

    const handleExpandClick = (sectionIndex: number, groupIndex?: number) => {
        setData(prev => prev.map((data, index) => ({
            ...data,
            expanded: (groupIndex === undefined && index === sectionIndex) ? !data.expanded : data.expanded,
            content: (groupIndex !== undefined && index === groupIndex && data.type === 'group') ? data.content.map((content, index) => {
                if (content.type === 'section' && sectionIndex === index) {
                    return {
                        ...content,
                        expanded: !content.expanded
                    }
                } else {
                    return content
                }
            }) : data.content
        })))
    }

    const handleExpandGroupClick = (groupIndex: number) => {
        setData(prev => prev.map((data, index) => ({
            ...data,
            expanded: (index === groupIndex) ? !data.expanded : data.expanded,
        })))
    }

    // data group section
    const handleGroupSection = (groupIndex: number) => {
        setData(prev => (prev.map((groupOrSection, index) => ({
            ...groupOrSection,
            content: index === groupIndex ? groupOrSection.content.concat({
                active: 1,
                expanded: false,
                type: "section",
                title: "",
                url: "",
                css: "",
                css_mobile: "",
                image_css: "",
                anchor: "",
                content: []
            }) : groupOrSection.content
        }))))
    }

    const handleSection = () => {
        handleCloseMenu()
        setData(prev => (prev.concat({
            active: 1,
            expanded: false,
            type: "section",
            title: "",
            url: "",
            css: "",
            css_mobile: "",
            image_css: "",
            anchor: "",
            content: []
        })))
    }

    const handleGroup = () => {
        handleCloseMenu()
        setData(prev => (prev.concat({
            active: 1,
            expanded: false,
            type: "group",
            title: "",
            url: "",
            css: "",
            css_mobile: "",
            image_css: "",
            anchor: "",
            content: []
        })))
    }

    const handleCTA = () => {
        handleCloseMenu()
        setData(prev => (prev.concat({
            active: 1,
            expanded: false,
            type: "cta",
            title: "",
            url: "",
            css: "",
            css_mobile: "",
            image_css: "",
            left_title: "",
            left_url: "",
            left_icon: "",
            left_classname: "",
            left_pretext: "",
            right_title: "",
            right_url: "",
            right_icon: "",
            right_classname: "",
            content: []
        })))
    }

    const handleDeleteElement = (elementIndex: number, sectionIndex: number, groupIndex?: number) => {
        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Apakah anda yakin ingin menghapus element?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya'
        }).then((result) => {
            if (result.value) {
                setActiveSectionGroupForm(null)
                setActiveElementForm(null)

                if (groupIndex === undefined) {
                    setData(prev => (prev.map((groupOrSection, index) => {
                        const content = groupOrSection.content.slice()
                        if (index === sectionIndex) {
                            content.splice(elementIndex, 1)
                        }
                        return {
                            ...groupOrSection,
                            content,
                        }
                    })))
                } else {
                    setData(prev => prev.map((groupOrSection, index) => ({
                        ...groupOrSection,
                        content: (index === groupIndex) ? groupOrSection.content.map((elementOrSection, index) => {
                            if (elementOrSection.type === 'section') {
                                const section = elementOrSection
                                const elements = section.content
                                elements.splice(elementIndex, 1)
                                return {
                                    ...section,
                                    content: index === sectionIndex ? elements : section.content
                                }
                            } else {
                                return elementOrSection
                            }
                        }) : groupOrSection.content
                    })))
                }
            }
        })
    }

    const handleDeleteFaq = (dataIndex: number, elementIndex: number, sectionIndex: number, groupIndex?: number) => {
        setData(prev => prev.map((groupOrSection, index) => ({
            ...groupOrSection,
            content: (groupIndex !== undefined && index === groupIndex && groupOrSection.type === 'group') ? groupOrSection.content.map((content, index) => {
                if (content.type === 'section' && sectionIndex === index) {
                    return {
                        ...content,
                        content: content.content.map((element, index) => ({
                            ...element,
                            element: index === elementIndex && (element.type === 'faq' || element.type === 'review') ? element.content.splice(dataIndex, 1) : element
                        }))
                    }
                } else {
                    return content
                }
            }) : (groupIndex === undefined && index === sectionIndex && groupOrSection.type === 'section') ? groupOrSection.content.map((element, index) => ({
                ...element,
                element: index === elementIndex && (element.type === 'faq' || element.type === 'review') ? element.content.splice(dataIndex, 1) : element
            })) : groupOrSection.content
        })))
    }

    const handleDeleteSection = (type: string, sectionIndex: number, groupIndex?: number) => {
        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Apakah anda yakin ingin menghapus data?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya'
        }).then((result) => {
            if (result.value) {
                setActiveSectionGroupForm(null)
                setActiveElementForm(null)

                if (groupIndex === undefined) {
                    const oldStatesSection: any[] = data.slice();
                    oldStatesSection.splice(sectionIndex, 1)
                    setData(oldStatesSection)
                } else {
                    setData(prev => (prev.map((groupOrSection, index) => {
                        const content = groupOrSection.content.slice()
                        if (index === groupIndex) {
                            content.splice(sectionIndex, 1)
                        }
                        return {
                            ...groupOrSection,
                            content,
                        }
                    })))
                }
            }
        })
    }

    const handleDeleteGroup = (groupIndex: number) => {
        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Apakah anda yakin ingin menghapus data?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya'
        }).then((result) => {
            if (result.value) {
                setActiveSectionGroupForm(null)
                setActiveElementForm(null)
                const oldStatesGroup: any[] = data.slice();
                oldStatesGroup.splice(groupIndex, 1)
                setData(oldStatesGroup)
            }
        })
    }

    const submitCopy = () => {
        setLoading(true);
        handleCloseCopy()
        const param = {
            type: 'cluster',
            copy_id: copyState.id,
            data_id: props.clusterId,
        }
        DefaultAxios.post(`${API_URL}/copy`, param)
            .then(res => res.data)
            .then(data => {
                Swal.fire({
                    title: "Page Submitted",
                    icon: 'success',
                    timer: 1000
                })
                    .then(() => {
                        setActiveSectionGroupForm(null)
                        setActiveElementForm(null)
                        loadData()
                    })
                enqueueSnackbar('Page Submitted', { variant: 'success' })
                setActiveSectionGroupForm(null)
                setActiveElementForm(null)
                loadData()
            })
            .catch(error => {
                helper.generalErrorHandler(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const sendData = () => {
        switch (props.customPageType) {
            case 1:
                setLoading(true);
                const param = {
                    type: 'cluster',
                    data_id: props.clusterId,
                    content: data
                }
                DefaultAxios.post(`${API_URL}`, param)
                    .then(res => res.data)
                    .then(data => {
                        Swal.fire({
                            title: "Page Submitted",
                            icon: 'success',
                            timer: 1000
                        })
                            .then(() => {
                                // setActiveSectionGroupForm(null)
                                // setActiveElementForm(null)
                                loadData()
                            })
                        enqueueSnackbar('Page Submitted', { variant: 'success' })
                        // setActiveSectionGroupForm(null)
                        // setActiveElementForm(null)
                        loadData()
                    })
                    .catch(error => {
                        helper.generalErrorHandler(error)
                    })
                    .finally(() => {
                        setLoading(false)
                    })

                break
            case 2:
                setLoading(true);
                const params = {
                    id: props.clusterId,
                    blog_title: blogData.blog_title,
                    blog_article: blogData.blog_article,
                }
                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/cluster/blog-article`, params)
                    .then(res => res.data)
                    .then(data => {
                        Swal.fire({
                            title: "Page Submitted",
                            icon: 'success',
                            timer: 1000
                        })
                            .then(() => {
                                loadData()
                            })
                        enqueueSnackbar('Page Submitted', { variant: 'success' })
                        loadData()
                    })
                    .catch(error => {
                        helper.generalErrorHandler(error)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
                break
        }
    }

    const handleSubmit = () => {
        sendData()
    }

    const handleActiveSwitch = (event: React.ChangeEvent<HTMLInputElement>, sectionIndex: number, groupIndex?: number) => {
        var checked = event.target.checked ? 1 : 0
        setData(prev => prev.map((data, index) => ({
            ...data,
            active: (groupIndex === undefined && index === sectionIndex) ? checked : data.active,
            content: (groupIndex !== undefined && index === groupIndex && data.type === 'group') ? data.content.map((content, index) => {
                if (content.type === 'section' && sectionIndex === index) {
                    return {
                        ...content,
                        active: checked,
                    }
                } else {
                    return content
                }
            }) : data.content
        })))
    }

    const handleActiveGroupSwitch = (event: React.ChangeEvent<HTMLInputElement>, groupIndex: number) => {
        var checked = event.target.checked ? 1 : 0
        setData(prev => prev.map((data, index) => ({
            ...data,
            active: index === groupIndex ? checked : data.active
        })))
    }

    const handleDuplicate = (dataSection: ISection, sectionIndex: number, groupIndex?: number) => {
        let exists = data.filter(p => p.title.includes(`${dataSection.title} copy`)).length;
        let copyName = `${dataSection.title} copy1`

        var copiedData = {
            active: dataSection.active,
            content: dataSection.content,
            css: dataSection.css,
            css_mobile: dataSection.css,
            title: exists ? `${dataSection.title} copy${exists + 1}` : copyName,
            type: dataSection.type,
            url: dataSection.url,
            image_css: dataSection.image_css,
            expanded: dataSection.expanded
        }

        const newState = [...data];
        if (groupIndex === undefined) {
            // newState.push(dataSection)
            newState.splice(sectionIndex + 1, 0, copiedData)
        } else {
            // newState[groupIndex].content.push(dataSection)
            newState[groupIndex].content.splice(sectionIndex, 0, copiedData)
        }

        setData(newState);
    }

    const handleDuplicateGroup = (dataSection: IGroupSection, groupIndex: number) => {
        let exists = data.filter(p => p.title.includes(`${dataSection.title} copy`)).length;
        let copyName = `${dataSection.title} copy1`

        var copiedData = {
            active: dataSection.active,
            content: dataSection.content,
            css: dataSection.css,
            css_mobile: dataSection.css,
            title: exists ? `${dataSection.title} copy${exists + 1}` : copyName,
            type: dataSection.type,
            url: dataSection.url,
            image_css: dataSection.image_css,
            expanded: dataSection.expanded
        }

        const newState = [...data];
        newState.splice(groupIndex + 1, 0, copiedData)
        setData(newState);
    }

    const handleSectionTitleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, sectionIndex: number, groupIndex?: number) => {
        const value = e.target.value

        setData(prev => prev.map((data, index) => ({
            ...data,
            title: (groupIndex === undefined && index === sectionIndex) ? value : data.title,
            content: (groupIndex !== undefined && index === groupIndex && data.type === 'group') ? data.content.map((content, index) => {
                if (content.type === 'section' && sectionIndex === index) {
                    return {
                        ...content,
                        title: value,
                    }
                } else {
                    return content
                }
            }) : data.content
        })))
    }

    const handleGroupTitleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, groupIndex: number) => {
        const value = e.target.value

        setData(prev => prev.map((data, index) => ({
            ...data,
            title: index === groupIndex ? value : data.title,
        })))
    }

    const generateNewElement = (type: SubSectionType) => {
        switch (type) {
            case 'text':
                return {
                    type: 'text',
                    content: '',
                    css: '',
                    css_mobile: '',
                    title: '',
                } as IText
            case 'image':
                return {
                    type: 'image',
                    css: '',
                    css_mobile: '',
                    title: '',
                    url: '',
                } as IImage
            case 'button':
                return {
                    type: 'button',
                    css: '',
                    css_mobile: '',
                    text: '',
                    title: '',
                    url: '',
                    icon: '',
                    classname: '',
                } as IButton
            case 'whatsapp_button':
                return {
                    type: 'whatsapp_button',
                    css: '',
                    css_mobile: '',
                    text: '',
                    title: '',
                    icon: 'whatsapp',
                    classname: '',
                    pretext: '',
                } as IButton
            case 'call_button':
                return {
                    type: 'call_button',
                    css: '',
                    css_mobile: '',
                    text: '',
                    title: '',
                    icon: '',
                    classname: '',
                    pretext: '',
                } as IButton
            case 'divider':
                return {
                    type: 'divider',
                    css: '',
                    css_mobile: '',
                    title: '',
                } as IDivider
            case 'video':
                return {
                    type: 'video',
                    css: '',
                    css_mobile: '',
                    title: '',
                    url: '',
                } as IVideo
            case 'iframe':
                return {
                    type: 'iframe',
                    css: '',
                    css_mobile: '',
                    title: '',
                    url: '',
                } as IFrame
            case 'faq':
                return {
                    type: 'faq',
                    css: '',
                    css_mobile: '',
                    title: '',
                    url: '',
                    content: [] as SubFaq[]
                } as IFaq
            case 'review':
                return {
                    type: 'review',
                    css: '',
                    css_mobile: '',
                    title: '',
                    summary: '',
                    url: '',
                    content: [] as SubReview[]
                } as IReview
        }
    }

    // data element
    const handleElement = (data: SubSectionType) => {
        const sectionIndex = newElementFlag.section
        const groupIndex = newElementFlag.group
        let elementIndex = 0

        if (sectionIndex !== undefined) {
            setData(prev => prev.map((groupOrSection, index) => {
                let newContent = {} as (SubSections | ISection)[]

                if (groupIndex !== undefined && index === groupIndex && groupOrSection.type === 'group') {
                    newContent = groupOrSection.content.map((content, index) => {
                        if (content.type === 'section' && sectionIndex === index) {
                            elementIndex = content.content.length
                            return {
                                ...content,
                                content: content.content.concat(generateNewElement(data))
                            }
                        } else {
                            return content
                        }
                    })
                } else if (groupIndex === undefined && index === sectionIndex && groupOrSection.type === 'section') {
                    elementIndex = groupOrSection.content.length
                    newContent = groupOrSection.content.concat(generateNewElement(data))
                } else {
                    newContent = groupOrSection.content
                }

                return {
                    ...groupOrSection,
                    content: newContent
                }
            }))

            handleCloseNewElement()
            handleOpenElementForm(elementIndex, sectionIndex, groupIndex)
        }
    }

    const handleNewElement = (sectionIndex: number, groupIndex?: number) => {
        setOpenNewElement(true)
        setNewElementFlag({
            section: sectionIndex,
            group: groupIndex
        })
    }

    const handleCloseNewElement = () => {
        setNewElementFlag({
            section: undefined,
            group: undefined
        })
        setOpenNewElement(false)
    }

    const handleCloseCopy = () => {
        setOpenCopy(false)
    }

    const handleOpenElementForm = (elementIndex: number, sectionIndex: number, groupIndex?: number) => {
        setActiveSectionGroupForm(null)
        setActiveElementForm({
            elementIndex,
            sectionIndex,
            groupIndex
        })
    }

    const handleOpenSectionGroupForm = (groupOrSectionIndex: number, sectionIndex?: number) => {
        setActiveElementForm(null)
        setActiveSectionGroupForm({
            groupOrSectionIndex,
            sectionIndex
        })
    }

    const handleElementFormChange = (name: keyof SubSectionsUnion, value: string, elementIndex: number, sectionIndex: number, groupIndex?: number) => {
        setData(prev => prev.map((groupOrSection, index) => ({
            ...groupOrSection,
            content: (groupIndex !== undefined && index === groupIndex && groupOrSection.type === 'group') ? groupOrSection.content.map((content, index) => {
                if (content.type === 'section' && sectionIndex === index) {
                    return {
                        ...content,
                        content: content.content.map((element, index) => ({
                            ...element,
                            [name]: index === elementIndex ? value : element[name as keyof SubSections]
                        }))
                    }
                } else {
                    return content
                }
            }) : (groupIndex === undefined && index === sectionIndex && groupOrSection.type === 'section') ? groupOrSection.content.map((element, index) => ({
                ...element,
                [name]: index === elementIndex ? value : element[name as keyof SubSections]
            })) : groupOrSection.content
        })))

        const newError = { ...initialErrorUrl };
        if (name === 'url') {
            if (value && !value.includes("https://")) {
                newError.url_element = 'Format URL harus diisi menggunakan https://';
            }
        }

        setErrorUrl({ name: '', value: newError, type: 'REPLACE_STATE' });
    }

    const handleElementFormFaqReviewChange = (nameParent: keyof SubSectionsUnion, nameFaq: keyof SubFaq | '', nameReview: keyof SubReview | '', value: string, elementIndex: number, sectionIndex: number, groupIndex?: number, faqIndex?: number) => {
        setData(prev => prev.map((groupOrSection, index) => ({
            ...groupOrSection,
            content: (groupIndex !== undefined && index === groupIndex && groupOrSection.type === 'group') ? groupOrSection.content.map((content, index) => {
                if (content.type === 'section' && sectionIndex === index) {
                    return {
                        ...content,
                        content: content.content.map((element, index) => ({
                            ...element,
                            [nameParent]: elementIndex === index && nameParent === 'content' ?
                                element.type === 'faq' ?
                                    // ini kalau konten
                                    element[nameParent].map((qa, index) => {
                                        if (index === faqIndex) {
                                            return {
                                                ...qa,
                                                [nameFaq]: value,
                                            }
                                        } else {
                                            return qa
                                        }
                                    })
                                    : element.type === 'review' ?
                                        element[nameParent].map((rv, index) => {
                                            if (index === faqIndex) {
                                                return {
                                                    ...rv,
                                                    [nameReview]: value,
                                                }
                                            } else {
                                                return rv
                                            }
                                        }) : null
                                :
                                // ini kalau bukan konten
                                element[nameParent as keyof SubSections]
                        }))
                    }
                } else {
                    return content
                }
            }) : (groupIndex === undefined && index === sectionIndex && groupOrSection.type === 'section') ? groupOrSection.content.map((element, index) => ({
                ...element,
                [nameParent]: elementIndex === index && nameParent === 'content' ?
                    element.type === 'faq' ?
                        // ini kalau konten
                        element[nameParent].map((qa, index) => {
                            if (index === faqIndex) {
                                return {
                                    ...qa,
                                    [nameFaq]: value,
                                }
                            } else {
                                return qa
                            }
                        })
                        : element.type === 'review' ?
                            // ini kalau konten
                            element[nameParent].map((rv, index) => {
                                if (index === faqIndex) {
                                    return {
                                        ...rv,
                                        [nameReview]: value,
                                    }
                                } else {
                                    return rv
                                }
                            }) : null
                    :
                    // ini kalau bukan konten
                    element[nameParent as keyof SubSections]
            })) : groupOrSection.content
        })))
    }

    const handleFaqReview = (name: keyof SubSectionsUnion, value: string, elementIndex: number, sectionIndex: number, groupIndex?: number) => {
        setData(prev => prev.map((groupOrSection, index) => ({
            ...groupOrSection,
            content: (groupIndex !== undefined && index === groupIndex && groupOrSection.type === 'group') ? groupOrSection.content.map((content, index) => {
                if (content.type === 'section' && sectionIndex === index) {
                    return {
                        ...content,
                        content: content.content.map((element, index) => ({
                            ...element,
                            [name]: index === elementIndex ?
                                element.type === 'faq' ?
                                    element.content.concat({
                                        question: '',
                                        answer: ''
                                    }) : element.type === 'review' ?
                                        element.content.concat({
                                            title: '',
                                            rating: ''
                                        }) : null
                                : element[name as keyof SubSections]
                        }))
                    }
                } else {
                    return content
                }
            }) : (groupIndex === undefined && index === sectionIndex && groupOrSection.type === 'section') ? groupOrSection.content.map((element, index) => ({
                ...element,
                [name]: index === elementIndex ?
                    element.type === 'faq' ?
                        element.content.concat({
                            question: '',
                            answer: ''
                        }) : element.type === 'review' ?
                            element.content.concat({
                                title: '',
                                rating: ''
                            }) : null
                    : element[name as keyof SubSections]
            })) : groupOrSection.content
        })))
    }

    const handleSectionGroupFormChange = (name: keyof IDefaultSection, value: string, groupOrSectionIndex: number, sectionIndex?: number) => {
        // console.log(name, value, groupOrSectionIndex, sectionIndex)
        setData(prev => prev.map((groupOrSection, index) => ({
            ...groupOrSection,
            [name]: sectionIndex === undefined && groupOrSectionIndex === index ? value : groupOrSection[name],
            content: sectionIndex !== undefined && groupOrSectionIndex === index ?
                groupOrSection.content.map((sectionOrElement, index) => {
                    if (sectionOrElement.type === 'section' && sectionIndex === index) {
                        const section = sectionOrElement as ISection
                        return {
                            ...section,
                            [name]: value
                        }
                    } else {
                        return sectionOrElement
                    }
                })
                : groupOrSection.content
        })))

        const newError = { ...initialErrorUrl };
        if (name === 'url') {
            if (value && !value.includes("https://")) {
                newError.url_sectionGroup = 'Format URL harus diisi menggunakan https://';
            }
        } else if (name === 'left_url') {
            if (value && !value.includes("https://")) {
                newError.left_url = 'Format URL harus diisi menggunakan https://';
            }
        } else if (name === 'right_url') {
            if (value && !value.includes("https://")) {
                newError.right_url = 'Format URL harus diisi menggunakan https://';
            }
        }

        setErrorUrl({ name: '', value: newError, type: 'REPLACE_STATE' });
    }

    const renderSectionGroupForm = () => {

        if (!activeSectionGroupForm) return null

        const { groupOrSectionIndex, sectionIndex } = activeSectionGroupForm

        let activeData = {} as IGroupOrSection

        if (sectionIndex !== undefined) {
            const selectedData = data[groupOrSectionIndex].content[sectionIndex]
            if (selectedData && selectedData.type === 'section') {
                activeData = selectedData
            }
        } else {
            activeData = data[groupOrSectionIndex]
        }

        if (!activeData || Object.keys(activeData).length === 0) {
            return null
        }

        // console.log(activeData)

        return (
            <Grid item xs={8}>
                <Card variant="outlined" className={classes.cardElementForm}>
                    <CardContent>
                        <h2 style={{ marginTop: '0px' }}>Form {activeData.type === 'cta' ? 'Floating CTA' : activeData.type}</h2>
                        <span className={classes.label}>
                            Title
                        </span>
                        <TextField
                            variant="outlined"
                            placeholder="Title"
                            name="title"
                            value={activeData.title}
                            onChange={(e) => handleSectionGroupFormChange('title', e.target.value, groupOrSectionIndex, sectionIndex)}
                            fullWidth
                        />
                        <span className={classes.spacer} />

                        {
                            activeData.type !== 'cta' ?
                                <>
                                    <span className={classes.label}>
                                        Url Background Image
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Image Url"
                                        name="url"
                                        value={activeData.url}
                                        onChange={(e) => handleSectionGroupFormChange('url', e.target.value, groupOrSectionIndex, sectionIndex)}
                                        fullWidth
                                        error={!!errorUrl.url_sectionGroup}
                                        helperText={errorUrl.url_sectionGroup}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        // className={classes.addButton}
                                                        // startIcon={<AddIcon />}
                                                        onClick={() => handleUploadOpen(groupOrSectionIndex, sectionIndex)}
                                                    >
                                                        Upload Asset
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <span className={classes.spacer} />
                                    <span className={classes.label}>
                                        Image CSS
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        placeholder="CSS for image"
                                        name="image_css"
                                        value={activeData.image_css}
                                        onChange={(e) => handleSectionGroupFormChange('image_css', e.target.value, groupOrSectionIndex, sectionIndex)}
                                        fullWidth
                                    />
                                    <span className={classes.spacer} />
                                    <span className={classes.label}>
                                        Anchor
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Anchor"
                                        name="anchor"
                                        value={activeData.anchor}
                                        onChange={(e) => handleSectionGroupFormChange('anchor', e.target.value, groupOrSectionIndex, sectionIndex)}
                                        fullWidth
                                    />
                                    <span className={classes.spacer} />
                                    <span className={classes.label}>
                                        Custom CSS
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Custom CSS"
                                        name="css"
                                        value={activeData.css}
                                        onChange={(e) => handleSectionGroupFormChange('css', e.target.value, groupOrSectionIndex, sectionIndex)}
                                        fullWidth
                                    />
                                    <Grid container>
                                        <span className={classes.spacer} />
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                style={{ marginRight: 4 }}
                                                onClick={(e) => handleSectionGroupFormChange('css', activeData.css + 'text-align:center;', groupOrSectionIndex, sectionIndex)}
                                            >
                                                text-align:center;
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                style={{ marginRight: 4 }}
                                                onClick={(e) => handleSectionGroupFormChange('css', activeData.css + 'font-size:24px;', groupOrSectionIndex, sectionIndex)}
                                            >
                                                font-size:24px;
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <span className={classes.spacer} />
                                    <span className={classes.label}>
                                        Mobile CSS
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Mobile CSS"
                                        name="css_mobile"
                                        value={activeData.css_mobile}
                                        onChange={(e) => handleSectionGroupFormChange('css_mobile', e.target.value, groupOrSectionIndex, sectionIndex)}
                                        fullWidth
                                    />
                                </>
                                :
                                <>
                                    <span className={classes.label}>
                                        Left Title
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Left Title"
                                        name="left_title"
                                        value={activeData.left_title}
                                        onChange={(e) => handleSectionGroupFormChange('left_title', e.target.value, groupOrSectionIndex, sectionIndex)}
                                        fullWidth
                                    />
                                    <span className={classes.spacer} />
                                    <span className={classes.label}>
                                        Left Url
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Left Url"
                                        name="left_url"
                                        value={activeData.left_url}
                                        onChange={(e) => handleSectionGroupFormChange('left_url', e.target.value, groupOrSectionIndex, sectionIndex)}
                                        fullWidth
                                        error={!!errorUrl.left_url}
                                        helperText={errorUrl.left_url}
                                    />
                                    <span className={classes.spacer} />
                                    <span className={classes.label}>
                                        Left Pretext
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Left Pretext"
                                        name="left_pretext"
                                        value={activeData.left_pretext}
                                        onChange={(e) => handleSectionGroupFormChange('left_pretext', e.target.value, groupOrSectionIndex, sectionIndex)}
                                        fullWidth
                                    />
                                    <span className={classes.spacer} />
                                    <span className={classes.label}>
                                        Left Icon
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Hanya bisa diisi Whatsapp / Call / URL"
                                        name="left_icon"
                                        select
                                        InputLabelProps={{ shrink: true }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        value={activeData.left_icon?.toLowerCase()}
                                        onChange={(e) => handleSectionGroupFormChange('left_icon', e.target.value, groupOrSectionIndex, sectionIndex)}
                                        fullWidth
                                    >
                                        <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                                        <option aria-label="icon" value="whatsapp">Whatsapp</option>
                                        <option aria-label="icon" value="call">Call</option>
                                        <option aria-label="icon" value="url">URL</option>
                                    </TextField>
                                    <span className={classes.spacer} />
                                    <span className={classes.label}>
                                        Left Classname
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Left Classname"
                                        name="left_classname"
                                        value={activeData.left_classname}
                                        select
                                        InputLabelProps={{ shrink: true }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onChange={(e) => handleSectionGroupFormChange('left_classname', e.target.value, groupOrSectionIndex, sectionIndex)}
                                        fullWidth
                                    >
                                        <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                                        <option aria-label="classname" value="button-whatsapp-header">button-whatsapp-header</option>
                                        <option aria-label="classname" value="button-pricelist">button-pricelist</option>
                                        <option aria-label="classname" value="button-whatsapp-footer">button-whatsapp-footer</option>
                                        <option aria-label="classname" value="float-whatsapp">float-whatsapp</option>
                                        <option aria-label="classname" value="float-call">float-call</option>
                                    </TextField>
                                    <span className={classes.spacer} />
                                    <span className={classes.label}>
                                        Right Title
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Right Title"
                                        name="right_title"
                                        value={activeData.right_title}
                                        onChange={(e) => handleSectionGroupFormChange('right_title', e.target.value, groupOrSectionIndex, sectionIndex)}
                                        fullWidth
                                    />
                                    <span className={classes.spacer} />
                                    <span className={classes.label}>
                                        Right Url
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Right Url"
                                        name="right_url"
                                        value={activeData.right_url}
                                        onChange={(e) => handleSectionGroupFormChange('right_url', e.target.value, groupOrSectionIndex, sectionIndex)}
                                        fullWidth
                                        error={!!errorUrl.right_url}
                                        helperText={errorUrl.right_url}
                                    />
                                    <span className={classes.spacer} />
                                    <span className={classes.label}>
                                        Right Icon
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Hanya bisa diisi Whatsapp / Call / Url"
                                        name="right_icon"
                                        select
                                        InputLabelProps={{ shrink: true }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        value={activeData.right_icon?.toLowerCase()}
                                        onChange={(e) => handleSectionGroupFormChange('right_icon', e.target.value, groupOrSectionIndex, sectionIndex)}
                                        fullWidth
                                    >
                                        <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                                        <option aria-label="icon" value="whatsapp">Whatsapp</option>
                                        <option aria-label="icon" value="call">Call</option>
                                        <option aria-label="icon" value="url">URL</option>
                                    </TextField>
                                    <span className={classes.spacer} />
                                    <span className={classes.label}>
                                        Right Classname
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Right Classname"
                                        name="right_classname"
                                        value={activeData.right_classname}
                                        select
                                        InputLabelProps={{ shrink: true }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onChange={(e) => handleSectionGroupFormChange('right_classname', e.target.value, groupOrSectionIndex, sectionIndex)}
                                        fullWidth
                                    >
                                        <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                                        <option aria-label="classname" value="button-whatsapp-header">button-whatsapp-header</option>
                                        <option aria-label="classname" value="button-pricelist">button-pricelist</option>
                                        <option aria-label="classname" value="button-whatsapp-footer">button-whatsapp-footer</option>
                                        <option aria-label="classname" value="float-whatsapp">float-whatsapp</option>
                                        <option aria-label="classname" value="float-call">float-call</option>
                                    </TextField>
                                    <span className={classes.spacer} />
                                    <span className={classes.label}>
                                        Button Color
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Isi Hex Color (Contoh: #3096f0)"
                                        name="button_color"
                                        value={activeData.button_color}
                                        onChange={(e) => handleSectionGroupFormChange('button_color', e.target.value, groupOrSectionIndex, sectionIndex)}
                                        fullWidth
                                    />
                                    {/* <span className={classes.spacer} />
                                    <span className={classes.label}>
                                        Custom CSS
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Custom CSS"
                                        name="css"
                                        value={activeData.css}
                                        onChange={(e) => handleSectionGroupFormChange('css', e.target.value, groupOrSectionIndex, sectionIndex)}
                                        fullWidth
                                    /> */}
                                </>
                        }

                    </CardContent>
                </Card>
            </Grid>
        )
    }

    const renderElementForm = () => {
        if (!activeElementForm) return null

        const { elementIndex, sectionIndex, groupIndex } = activeElementForm

        let activeData = {} as SubSections

        if (groupIndex !== undefined) {
            const selectedData = data[groupIndex].content[sectionIndex]
            if (selectedData.type === 'section') {
                activeData = selectedData.content[elementIndex]
            }
            // console.log(selectedData)
        } else {
            const selectedData = data[sectionIndex].content[elementIndex]
            if (selectedData && selectedData.type !== 'section') {
                activeData = selectedData
            }
            // console.log(selectedData)
        }

        if (!activeData || Object.keys(activeData).length === 0) {
            return null
        }

        return (
            <Grid item xs={8}>
                <Card variant="outlined" className={classes.cardElementForm}>
                    <CardContent>
                        <span className={classes.label}>
                            Title
                        </span>
                        <TextField
                            variant="outlined"
                            placeholder={activeData.type === 'whatsapp_button' ? `Kosongkan untuk mengisi otomatis nomor PIC` : 'Title'}
                            name="title"
                            value={activeData.title}
                            onChange={(e) => handleElementFormChange('title', e.target.value, elementIndex, sectionIndex, groupIndex)}
                            fullWidth
                        />
                        <span className={classes.spacer} />
                        {
                            (activeData.type === 'text') &&
                            <>
                                <span className={classes.label}>
                                    Content
                                </span>
                                <TextEditor
                                    customPage
                                    name='content'
                                    value={activeData.content}
                                    onChange={(name: keyof SubSections, value: string) => handleElementFormChange('content', value, elementIndex, sectionIndex, groupIndex)}
                                />
                                <span className={classes.spacer} />
                            </>
                        }
                        {
                            (activeData.type === 'button' || activeData.type === 'whatsapp_button' || activeData.type === 'call_button') &&
                            <>
                                <span className={classes.label}>
                                    Icon
                                </span>
                                <TextField
                                    variant="outlined"
                                    placeholder="Hanya bisa diisi Whatsapp / URL / PDF"
                                    select
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    name="icon"
                                    value={activeData.icon?.toLowerCase()}
                                    onChange={(e) => handleElementFormChange('icon', e.target.value, elementIndex, sectionIndex, groupIndex)}
                                    fullWidth
                                >
                                    <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                                    <option aria-label="icon" value="whatsapp">Whatsapp</option>
                                    <option aria-label="icon" value="url">URL</option>
                                    <option aria-label="icon" value="pdf">PDF</option>
                                </TextField>
                                <span className={classes.spacer} />
                            </>
                        }
                        {
                            (activeData.type === 'whatsapp_button' || activeData.type === 'call_button') &&
                            <>
                                <span className={classes.label}>
                                    Pretext
                                </span>
                                <TextField
                                    variant="outlined"
                                    placeholder="Pretext"
                                    name="pretext"
                                    value={activeData.pretext}
                                    onChange={(e) => handleElementFormChange('pretext', e.target.value, elementIndex, sectionIndex, groupIndex)}
                                    fullWidth
                                />
                                <span className={classes.spacer} />
                            </>
                        }

                        {
                            ('url' in activeData && ['image', 'button', 'video', 'iframe'].includes(activeData.type)) &&
                            <>
                                <span className={classes.label}>
                                    {activeData.type === 'button' ? 'Button URL' : ""}
                                    {activeData.type === 'video' ? 'Video URL' : ""}
                                    {activeData.type === 'image' ? 'Image URL' : ""}
                                    {activeData.type === 'iframe' ? 'IFrame URL' : ""}
                                </span>
                                {
                                    activeData.type === 'image' ?
                                        <TextField
                                            variant="outlined"
                                            placeholder='Image URL'
                                            name="url"
                                            value={activeData.url}
                                            onChange={(e) => handleElementFormChange('url', e.target.value, elementIndex, sectionIndex, groupIndex)}
                                            fullWidth
                                            error={!!errorUrl.url_element}
                                            helperText={errorUrl.url_element}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            // className={classes.addButton}
                                                            // startIcon={<AddIcon />}
                                                            onClick={() => handleUploadOpenElement(elementIndex, sectionIndex, groupIndex)}
                                                        >
                                                            Upload Asset
                                                        </Button>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        : <TextField
                                            variant="outlined"
                                            placeholder={activeData.type === 'button' ? 'Button URL' : activeData.type === 'video' ? 'Video URL' : activeData.type === 'iframe' ? 'IFrame URL' : 'Image URL'}
                                            name="url"
                                            value={activeData.url}
                                            onChange={(e) => handleElementFormChange('url', e.target.value, elementIndex, sectionIndex, groupIndex)}
                                            fullWidth
                                            error={!!errorUrl.url_element}
                                            helperText={errorUrl.url_element}
                                        />
                                }

                                <span className={classes.spacer} />
                            </>
                        }
                        {
                            (activeData.type === 'button' || activeData.type === 'whatsapp_button' || activeData.type === 'call_button') &&
                            <>
                                <span className={classes.label}>
                                    Classname
                                </span>
                                <TextField
                                    variant="outlined"
                                    placeholder="Classname"
                                    name="classname"
                                    value={activeData.classname}
                                    select
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onChange={(e) => handleElementFormChange('classname', e.target.value, elementIndex, sectionIndex, groupIndex)}
                                    fullWidth
                                >
                                    <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                                    <option aria-label="classname" value="button-whatsapp-header">button-whatsapp-header</option>
                                    <option aria-label="classname" value="button-pricelist">button-pricelist</option>
                                    <option aria-label="classname" value="button-whatsapp-footer">button-whatsapp-footer</option>
                                    <option aria-label="classname" value="float-whatsapp">float-whatsapp</option>
                                    <option aria-label="classname" value="float-call">float-call</option>
                                </TextField>
                                <span className={classes.spacer} />
                            </>
                        }
                        {
                            (activeData.type === 'faq') &&
                            <>
                                <span className={classes.label}>
                                    List FAQ
                                </span>
                                <span className={classes.spacer} />
                                {
                                    activeData.content.map((faqData, faqIndex) =>
                                        <>
                                            <Card key={faqIndex} variant="outlined" className={classes.card}>
                                                <Grid container style={{ justifyContent: 'space-between', padding: 8, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                                    <Grid item style={{ alignSelf: 'center' }}>
                                                        <Typography variant="subtitle2" align="center">
                                                            #{faqIndex + 1}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item style={{ alignSelf: 'center' }}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            size="small"
                                                            onClick={() => handleDeleteFaq(faqIndex, elementIndex, sectionIndex, groupIndex)}
                                                        >
                                                            <DeleteIcon fontSize="small" />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <CardContent>
                                                    <Grid container>
                                                        <Grid item xs={1} style={{ margin: 'auto' }}>
                                                            <span className={classes.label}>
                                                                Q
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <TextField
                                                                variant="outlined"
                                                                placeholder="Question"
                                                                name="question"
                                                                value={faqData.question}
                                                                onChange={(e) => handleElementFormFaqReviewChange('content', 'question', '', e.target.value, elementIndex, sectionIndex, groupIndex, faqIndex)}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <span className={classes.spacer} />
                                                    <Grid container>
                                                        <Grid item xs={1} style={{ margin: 'auto' }}>
                                                            <span className={classes.label}>
                                                                A
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <TextField
                                                                variant="outlined"
                                                                placeholder="Answer"
                                                                name="answer"
                                                                value={faqData.answer}
                                                                onChange={(e) => handleElementFormFaqReviewChange('content', 'answer', '', e.target.value, elementIndex, sectionIndex, groupIndex, faqIndex)}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </>
                                    )
                                }
                                <Grid style={{ textAlign: 'right' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<AddIcon />}
                                        onClick={() => handleFaqReview('content', '', elementIndex, sectionIndex, groupIndex)}
                                    >
                                        Add FAQ
                                    </Button>
                                </Grid>
                                <span className={classes.spacer} />
                            </>
                        }
                        {
                            (activeData.type === 'review') &&
                            <>
                                <span className={classes.label}>
                                    List Review
                                </span>
                                <span className={classes.spacer} />
                                {
                                    activeData.content.map((reviewData, reviewIndex) =>
                                        <>
                                            <Card key={reviewIndex} variant="outlined" className={classes.card}>
                                                <Grid container style={{ justifyContent: 'space-between', padding: 8, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                                    <Grid item style={{ alignSelf: 'center' }}>
                                                        <Typography variant="subtitle2" align="center">
                                                            #{reviewIndex + 1}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item style={{ alignSelf: 'center' }}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            size="small"
                                                            // className={classes.deleteSectionGroup}
                                                            onClick={() => handleDeleteFaq(reviewIndex, elementIndex, sectionIndex, groupIndex)}
                                                        >
                                                            <DeleteIcon fontSize="small" />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <CardContent>
                                                    <span className={classes.label}>
                                                        Title
                                                    </span>
                                                    <TextField
                                                        variant="outlined"
                                                        placeholder="Title"
                                                        name="title"
                                                        value={reviewData.title}
                                                        onChange={(e) => handleElementFormFaqReviewChange('content', '', 'title', e.target.value, elementIndex, sectionIndex, groupIndex, reviewIndex)}
                                                        fullWidth
                                                    />
                                                    <span className={classes.spacer} />
                                                    <span className={classes.label}>
                                                        Rating
                                                    </span>
                                                    <TextField
                                                        variant="outlined"
                                                        placeholder="Rating"
                                                        name="rating"
                                                        value={reviewData.rating}
                                                        onChange={(e) => handleElementFormFaqReviewChange('content', '', 'rating', inputNumberTwo(e.target.value), elementIndex, sectionIndex, groupIndex, reviewIndex)}
                                                        fullWidth
                                                    />
                                                </CardContent>
                                            </Card>
                                        </>
                                    )
                                }
                                <Grid style={{ textAlign: 'right' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<AddIcon />}
                                        onClick={() => handleFaqReview('content', '', elementIndex, sectionIndex, groupIndex)}
                                    >
                                        Add Review
                                    </Button>
                                </Grid>
                                <span className={classes.spacer} />
                                <span className={classes.label}>
                                    Summary
                                </span>
                                <TextField
                                    variant="outlined"
                                    placeholder="Summary"
                                    name="summary"
                                    multiline
                                    rows={3}
                                    value={activeData.summary}
                                    onChange={(e) => handleElementFormChange('summary', e.target.value, elementIndex, sectionIndex, groupIndex)}
                                    fullWidth
                                />
                                <span className={classes.spacer} />
                            </>
                        }
                        {
                            (activeData.type !== 'faq' && activeData.type !== 'review') &&
                            <>
                                <span className={classes.label}>
                                    Custom CSS
                                </span>
                                <TextField
                                    variant="outlined"
                                    placeholder="Custom CSS"
                                    name="css"
                                    value={activeData.css}
                                    onChange={(e) => handleElementFormChange('css', e.target.value, elementIndex, sectionIndex, groupIndex)}
                                    fullWidth
                                />
                                <Grid container>
                                    <span className={classes.spacer} />
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            style={{ marginRight: 4 }}
                                            onClick={(e) => handleElementFormChange('css', activeData.css + 'text-align:center;', elementIndex, sectionIndex, groupIndex)}
                                        >
                                            text-align:center;
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            style={{ marginRight: 4 }}
                                            onClick={(e) => handleElementFormChange('css', activeData.css + 'font-size:24px;', elementIndex, sectionIndex, groupIndex)}
                                        >
                                            font-size:24px;
                                        </Button>
                                    </Grid>
                                </Grid>
                                <span className={classes.spacer} />
                                <span className={classes.label}>
                                    Mobile CSS
                                </span>
                                <TextField
                                    variant="outlined"
                                    placeholder="Mobile CSS"
                                    name="css_mobile"
                                    value={activeData.css_mobile}
                                    onChange={(e) => handleElementFormChange('css_mobile', e.target.value, elementIndex, sectionIndex, groupIndex)}
                                    fullWidth
                                />
                            </>
                        }
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    // render Section
    const renderSection = (data: ISection, sectionIndex: number, groupIndex?: number) => {
        return (
            <Draggable draggableId={sectionIndex.toString()} index={sectionIndex} key={sectionIndex}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                    >
                        <Card key={`${groupIndex}-${sectionIndex}`} variant="outlined" className={classes.card}>
                            <Grid container style={{ justifyContent: 'flex-end', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                <Grid item style={{ alignSelf: 'center', marginLeft: 8 }}>
                                    <Typography variant="subtitle2" align="center">
                                        {
                                            data.active === 1 ? 'Active' : 'Inactive'
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Switch
                                        color="primary"
                                        value={true}
                                        checked={data.active === 1 ? true : false}
                                        onChange={(e) => handleActiveSwitch(e, sectionIndex, groupIndex)}
                                    />
                                </Grid>
                            </Grid>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={2} style={{ margin: 'auto' }}>
                                        <div {...provided.dragHandleProps}>
                                            <OpenWithIcon color="primary" />
                                        </div>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Grid container style={{ justifyContent: 'flex-end' }}>
                                            <Grid item style={{ marginRight: '4px' }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    className={classes.deleteSectionGroup}
                                                    onClick={() => handleDuplicate(data, sectionIndex, groupIndex)}
                                                >
                                                    <FileCopyIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                            <Grid item style={{ marginRight: '4px' }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    className={classes.deleteSectionGroup}
                                                    onClick={() => handleOpenSectionGroupForm(groupIndex === undefined ? sectionIndex : groupIndex, groupIndex === undefined ? undefined : sectionIndex)}
                                                >
                                                    <EditIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    size="small"
                                                    className={classes.deleteSectionGroup}
                                                    onClick={() => handleDeleteSection(data.type, sectionIndex, groupIndex)}
                                                >
                                                    <DeleteIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <span className={classes.spacerTitle} />
                                <Grid container>
                                    <Grid item xs={10}>
                                        <TextField
                                            label="Section"
                                            variant="standard"
                                            name="name"
                                            value={data.title}
                                            fullWidth
                                            onChange={(e) => handleSectionTitleChange(e, sectionIndex, groupIndex)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton
                                            className={data.expanded ? classes.expandOpen : classes.expand}
                                            onClick={() => handleExpandClick(sectionIndex, groupIndex)}
                                            aria-expanded={data.expanded}
                                            aria-label="show more"
                                        >
                                            <ExpandMoreIcon fontSize="large" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Collapse in={data.expanded} timeout="auto" unmountOnExit>
                                    <Divider className={classes.hr} />
                                    <DragDropContext
                                        onBeforeCapture={onBeforeCapture}
                                        onBeforeDragStart={onBeforeDragStart}
                                        onDragStart={onDragStart}
                                        onDragUpdate={onDragUpdate}
                                        onDragEnd={(result, provided) => onDragEndElement(result, provided, sectionIndex, groupIndex)}
                                    >
                                        <Droppable droppableId="droppable-3" type="Element">
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    // style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey' }}
                                                    {...provided.droppableProps}
                                                >
                                                    {
                                                        data.content.map((item, index) =>
                                                            <Draggable draggableId={index.toString()} index={index} key={index}>
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <Grid key={index} container spacing={2} marginBottom={1}>
                                                                            <Grid item xs={9}>
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    className={classes.elementButtonText}
                                                                                    style={{ justifyContent: 'left' }}
                                                                                    onClick={() => handleOpenElementForm(index, sectionIndex, groupIndex)}
                                                                                >
                                                                                    {item.type === 'whatsapp_button' ? 'WA Button' : item.type === 'call_button' ? 'Call Button' : item.type}: {item.title}
                                                                                </Button>
                                                                            </Grid>
                                                                            <Grid item xs={3} style={{ margin: 'auto' }}>
                                                                                <Grid container>
                                                                                    <Grid {...provided.dragHandleProps} item xs={6} >
                                                                                        <OpenWithIcon color="primary" fontSize="small" />
                                                                                    </Grid>
                                                                                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                                                                                        <DeleteIcon color="secondary" fontSize="small" style={{ cursor: 'pointer' }} onClick={() => handleDeleteElement(index, sectionIndex, groupIndex)} />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>


                                                                        </Grid>
                                                                        {/* <Divider className={classes.hr2} /> */}

                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    }
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <Grid container spacing={2}>
                                        <Grid>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                fullWidth
                                                className={classes.addButton}
                                                startIcon={<AddIcon />}
                                                onClick={() => handleNewElement(sectionIndex, groupIndex)}
                                            >
                                                Add new element
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </CardContent>
                        </Card>
                    </div>
                )}
            </Draggable>
        )
    }

    // render Group
    const renderGroup = (data: IGroupSection, groupIndex: number) => {
        return (
            <Draggable draggableId={groupIndex.toString()} index={groupIndex} key={groupIndex}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}

                    >
                        <Card key={groupIndex} className={classes.groupCard}>
                            <Grid container style={{ justifyContent: 'flex-end', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                <Grid item style={{ alignSelf: 'center', marginLeft: 8 }}>
                                    <Typography variant="subtitle2" align="center">
                                        {
                                            data.active === 1 ? 'Active' : 'Inactive'
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Switch
                                        color="primary"
                                        value={true}
                                        checked={data.active === 1 ? true : false}
                                        onChange={(e) => handleActiveGroupSwitch(e, groupIndex)}
                                    />
                                </Grid>
                            </Grid>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={2} style={{ margin: 'auto' }}>
                                        <div {...provided.dragHandleProps}>
                                            <OpenWithIcon color="primary" />
                                        </div>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Grid container style={{ justifyContent: 'flex-end' }}>
                                            <Grid item style={{ marginRight: '4px' }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    className={classes.deleteSectionGroup}
                                                    onClick={() => handleDuplicateGroup(data, groupIndex)}
                                                >
                                                    <FileCopyIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                            <Grid item style={{ marginRight: '4px' }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    className={classes.deleteSectionGroup}
                                                    onClick={() => handleOpenSectionGroupForm(groupIndex)}
                                                >
                                                    <EditIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    size="small"
                                                    className={classes.deleteSectionGroup}
                                                    onClick={() => handleDeleteGroup(groupIndex)}
                                                >
                                                    <DeleteIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <span className={classes.spacerTitle} />
                                <Grid container>
                                    <Grid item xs={10}>
                                        <TextField
                                            label="Group Section"
                                            variant="standard"
                                            name="name"
                                            value={data.title}
                                            fullWidth
                                            onChange={(e) => handleGroupTitleChange(e, groupIndex)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton
                                            className={data.expanded ? classes.expandOpen : classes.expand}
                                            onClick={() => handleExpandGroupClick(groupIndex)}
                                            aria-expanded={data.expanded}
                                            aria-label="show more"
                                        >
                                            <ExpandMoreIcon fontSize="large" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Collapse in={data.expanded} timeout="auto" unmountOnExit>
                                    <Divider className={classes.hr} />
                                    <DragDropContext
                                        onBeforeCapture={onBeforeCapture}
                                        onBeforeDragStart={onBeforeDragStart}
                                        onDragStart={onDragStart}
                                        onDragUpdate={onDragUpdate}
                                        onDragEnd={(result, provided) => onDragEndGroup(result, provided, groupIndex)}
                                    >
                                        <Droppable droppableId="droppable-2" type="GROUPSECTION">
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    // style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey' }}
                                                    {...provided.droppableProps}
                                                >
                                                    {
                                                        data.content.map((content, index) => renderSection(content, index, groupIndex))
                                                    }
                                                    {/* <Divider className={classes.hr2} /> */}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <Grid container spacing={2}>
                                        <Grid>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                startIcon={<AddIcon />}
                                                fullWidth
                                                className={classes.addButton}
                                                onClick={() => { handleGroupSection(groupIndex) }}
                                            >
                                                Add new section
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </CardContent>
                        </Card>
                    </div>
                )}
            </Draggable>
        )
    }

    const renderCTA = (data: ICta, ctaIndex: number) => {
        if (ctaIndex) {
            setIsCTA(1)
        } else {
            setIsCTA(0)
        }
        return (
            <Draggable draggableId={ctaIndex.toString()} index={ctaIndex} key={ctaIndex}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}

                    >
                        <Card key={ctaIndex} variant="outlined" className={classes.card}>
                            <Grid container style={{ justifyContent: 'flex-end', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                <Grid item style={{ alignSelf: 'center', marginLeft: 8 }}>
                                    <Typography variant="subtitle2" align="center">
                                        {
                                            data.active === 1 ? 'Active' : 'Inactive'
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Switch
                                        color="primary"
                                        value={true}
                                        checked={data.active === 1 ? true : false}
                                        onChange={(e) => handleActiveGroupSwitch(e, ctaIndex)}
                                    />
                                </Grid>
                            </Grid>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={2} style={{ margin: 'auto' }}>
                                        <div {...provided.dragHandleProps}>
                                            <OpenWithIcon color="primary" />
                                        </div>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Grid container style={{ justifyContent: 'flex-end' }}>
                                            <Grid item style={{ marginRight: '4px' }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    className={classes.deleteSectionGroup}
                                                    onClick={() => handleOpenSectionGroupForm(ctaIndex)}
                                                >
                                                    <EditIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    size="small"
                                                    className={classes.deleteSectionGroup}
                                                    onClick={() => handleDeleteGroup(ctaIndex)}
                                                >
                                                    <DeleteIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <TextField
                                            label="Floating CTA"
                                            variant="standard"
                                            name="name"
                                            value={data.title}
                                            fullWidth
                                            onChange={(e) => handleGroupTitleChange(e, ctaIndex)}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </div>
                )}
            </Draggable>
        )
    }

    const renderData = () => {
        return data.map((groupOrSection, index) => {
            if (groupOrSection.type === 'section') {
                const section = groupOrSection as ISection
                return renderSection(section, index)
            } else if (groupOrSection.type === 'group') {
                const group = groupOrSection as IGroupSection
                return renderGroup(group, index)
            } else {
                const cta = groupOrSection as ICta
                return renderCTA(cta, index)
            }
        })
    }

    const onBeforeCapture = () => {
        /*...*/
    };
    const onBeforeDragStart = () => {
        /*...*/
    };
    const onDragStart = () => {
        /*...*/
    };
    const onDragUpdate = () => {
        /*...*/
    };

    const reorder = (list: IGroupOrSection[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const reorderGroup = (list: (IText | IImage | IButton | IDivider | IVideo | IFaq | IReview | ISection | IFrame)[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: any, provided: any) => {
        // the only one that is required
        // eslint-disable-next-line
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        const items = reorder(
            data,
            result.source.index,
            result.destination.index
        );

        setData(items);
    };

    const onDragEndGroup = (result: any, provided: any, groupIndex: number) => {
        // the only one that is required
        // eslint-disable-next-line
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        const items = reorderGroup(
            data[groupIndex].content,
            result.source.index,
            result.destination.index
        );

        setData(prev => prev.map((data, index) => ({
            ...data,
            content: index === groupIndex ? items : data.content
        })));
    };

    const onDragEndElement = (result: any, provided: any, sectionIndex: number, groupIndex?: number) => {
        // the only one that is required
        // eslint-disable-next-line
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }
        let itemsToBeOrdered = [] as SubSections[]
        if (groupIndex !== undefined) {
            const group = data[groupIndex]
            if (group.type === 'group') {
                const content = group.content as ISection[]
                itemsToBeOrdered = content[sectionIndex].content
            } else {
                return
            }
        } else {
            const section = data[sectionIndex]
            if (section.type === 'section') {
                itemsToBeOrdered = section.content as SubSections[]
            } else {
                return
            }
        }
        const items = reorderGroup(
            itemsToBeOrdered,
            result.source.index,
            result.destination.index
        ) as SubSections[]
        setData(prev => prev.map((groupOrSection, index) => {
            let newContent = {} as (SubSections | ISection)[]
            if (groupIndex !== undefined && index === groupIndex && groupOrSection.type === 'group') {
                newContent = groupOrSection.content.map((content, index) => {
                    if (content.type === 'section' && sectionIndex === index) {
                        return {
                            ...content,
                            content: items
                        }
                    } else {
                        return content
                    }
                })
            } else if (groupIndex === undefined && index === sectionIndex && groupOrSection.type === 'section') {
                newContent = items
            } else {
                newContent = groupOrSection.content
            }
            return {
                ...groupOrSection,
                content: newContent
            }
        }))
    };

    const loadData = () => {
        setLoading(true);
        DefaultAxios
            .get(`${API_URL}?type=cluster&id=${props.clusterId}`)
            .then(res => res.data)
            .then(data => {
                const content = data.content;
                setData(content);
                setIsCustomPage(data.is_custom_page ? true : false)
                if (content.length) {
                    setCopyButton(false)
                } else {
                    setCopyButton(true)
                }

                DefaultAxios.get(`${process.env.REACT_APP_API_URL}/cluster/blog-article/${data.data_id}`)
                    .then((article_data: any) => {
                        // console.log(article_data.data)
                        setBlogData(prev => ({
                            ...prev,
                            blog_title: article_data.data.blog_title,
                            blog_article: article_data.data.blog_article,
                        }))
                    })
            })
            .catch(error => {
                setCopyButton(false)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEditorChanged = (name: string, value: string) => {
        setBlogData(prev => ({
            ...prev,
            blog_article: value
        }))
    }

    const handleBlogTitleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setBlogData(prev => ({
            ...prev,
            blog_title: value
        }))
    }

    // console.log(data)
    // console.log(groupIndexGlobal, sectionIndexGlobal)

    return (
        <Grid item xs={12}>
            <Root>
                <LoadingScreen open={loading} fullScreen />
                {
                    props.customPageType === 1 &&
                    <>
                        <Dialog
                            open={modalUpload}
                            onClose={handleUploadClose}
                            fullWidth
                            maxWidth="md"
                        >
                            <Root>
                                <DialogTitle>Asset Upload Form</DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <img src={inputUploadState.thumbnail ? inputUploadState.thumbnail : "https://jendela360.dev/assets/admin/img/default-thumbnail.jpg"} width="100" alt={inputUploadState.name} />
                                            {
                                                !inputUploadState.id &&
                                                <>
                                                    <br />
                                                    <input type="file" name="file" onChange={handleChangeUpload} />
                                                    {
                                                        errorUploadState.file &&
                                                        <>
                                                            <br />
                                                            <span style={{ color: 'red' }}>{errorUploadState.file}</span>
                                                        </>
                                                    }
                                                </>
                                            }
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Nama Asset"
                                                name="name"
                                                value={inputUploadState.name}
                                                onChange={handleChangeUpload}
                                                error={!!errorUploadState.name}
                                                helperText={errorUploadState.name}
                                            />
                                        </Grid>

                                        <Grid item xs={12} className={classes.switchContainer}>
                                            <Typography>
                                                Optimize
                                            </Typography>
                                            <Switch
                                                value={true}
                                                checked={inputUploadState.is_optimize === 1}
                                                onChange={handleSwitchUpload}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        color="primary"
                                        onClick={handleSubmitUpload}
                                    >
                                        Submit
                                    </Button>
                                </DialogActions>
                            </Root>
                        </Dialog>
                        <Dialog
                            open={openNewElement}
                            onClose={handleCloseNewElement}
                            closeAfterTransition
                        // TransitionComponent={Transition}
                        >
                            <Root>
                                <DialogTitle>
                                    <Typography variant="h5" align="center">
                                        Select element component
                                    </Typography>
                                </DialogTitle>
                                <Grid className={classes.modalElement}>
                                    <Grid >
                                        <Button variant="outlined" fullWidth className={classes.elementButton} onClick={() => handleElement('text')}>
                                            Text
                                        </Button>
                                        <Button variant="outlined" fullWidth className={classes.elementButton} onClick={() => handleElement('image')}>
                                            Image
                                        </Button>
                                        <Button variant="outlined" fullWidth className={classes.elementButton} onClick={() => handleElement('divider')}>
                                            Divider
                                        </Button>
                                        <Button variant="outlined" fullWidth className={classes.elementButton} onClick={() => handleElement('button')}>
                                            Button
                                        </Button>
                                        <Button variant="outlined" fullWidth className={classes.elementButton} onClick={() => handleElement('whatsapp_button')}>
                                            Whatsapp Button
                                        </Button>
                                        <Button variant="outlined" fullWidth className={classes.elementButton} onClick={() => handleElement('call_button')}>
                                            Call Button
                                        </Button>
                                        <Button variant="outlined" fullWidth className={classes.elementButton} onClick={() => handleElement('video')}>
                                            Video
                                        </Button>
                                        <Button variant="outlined" fullWidth className={classes.elementButton} onClick={() => handleElement('iframe')}>
                                            Iframe
                                        </Button>
                                        <Button variant="outlined" fullWidth className={classes.elementButton} onClick={() => handleElement('faq')}>
                                            FAQ
                                        </Button>
                                        <Button variant="outlined" fullWidth className={classes.elementButton} onClick={() => handleElement('review')}>
                                            Review
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Root>
                        </Dialog>
                        <Dialog
                            open={openCopy}
                            onClose={handleCloseCopy}
                            closeAfterTransition
                            fullWidth
                            maxWidth="sm"
                        // TransitionComponent={Transition}
                        >
                            <Root>
                                <DialogTitle>
                                    <Typography variant="h5" align="center">
                                        Copy Cluster Custom Page
                                    </Typography>
                                </DialogTitle>
                                <Grid className={classes.modalElement}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <AsyncAutoComplete
                                                label="Cluster Custom Page"
                                                name="label"
                                                initialQuery={copyState.label}
                                                onChange={handleAutocomplete}
                                                onInputChange={handleAutocompleteInputChanged}
                                                url={`${process.env.REACT_APP_API_URL}/autocomplete/cluster?custom_page=1`}
                                                iconSearch
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                className={classes.deleteSectionGroup}
                                                onClick={submitCopy}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Root>
                        </Dialog>
                        <Grid container spacing={3} className={classes.mainContainer}>
                            <Grid item xs={12} className={classes.switchContainer2} marginTop={2}>
                                <Switch
                                    color="primary"
                                    value={true}
                                    checked={isCustomPage}
                                    onChange={handleCustomPageSwitch}
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                                <Typography>
                                    Custom Page Active
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <DragDropContext
                                    onBeforeCapture={onBeforeCapture}
                                    onBeforeDragStart={onBeforeDragStart}
                                    onDragStart={onDragStart}
                                    onDragUpdate={onDragUpdate}
                                    onDragEnd={onDragEnd}
                                >
                                    <Droppable droppableId="droppable-1" type="SECTION">
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                // style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey' }}
                                                {...provided.droppableProps}
                                            >
                                                {
                                                    renderData()
                                                }
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                <div className={classes.menu}>
                                    <Button
                                        variant="contained"
                                        // color="default"
                                        className="menu-button"
                                        startIcon={<ListIcon />}
                                        onClick={handleOpenMenu}
                                    >
                                        Add Section
                                    </Button>
                                </div>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseMenu}
                                >
                                    <MenuItem onClick={handleSection}>
                                        <ListItemIcon>
                                            <AddIcon color="primary" fontSize="medium" />
                                        </ListItemIcon>
                                        <ListItemText primary="Add new Section" />
                                    </MenuItem>
                                    <MenuItem onClick={handleGroup}>
                                        <ListItemIcon>
                                            <AddIcon color="primary" fontSize="medium" />
                                        </ListItemIcon>
                                        <ListItemText primary="Add new Group Section" />
                                    </MenuItem>
                                    <MenuItem disabled={isCTA === 1} onClick={handleCTA}>
                                        <ListItemIcon>
                                            <AddIcon color="primary" fontSize="medium" />
                                        </ListItemIcon>
                                        <ListItemText primary="Add new CTA" />
                                    </MenuItem>
                                </Menu>
                            </Grid>
                            {
                                activeElementForm ? renderElementForm() : null
                            }
                            {
                                activeSectionGroupForm ? renderSectionGroupForm() : null
                            }
                        </Grid>
                    </>
                }

                {
                    props.customPageType === 2 &&
                    <Grid item xs={12}>
                        <Paper style={{ padding: 16, marginRight: 24 }}>
                            <Grid container spacing={3} className={classes.mainContainer}>
                                <Grid item xs={12}>
                                    <h3>Blog Title</h3>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Title"
                                        label="Title"
                                        name="blog_title"
                                        value={blogData.blog_title}
                                        onChange={handleBlogTitleChanged}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <h3>Blog Article</h3>
                                    <TextEditor
                                        name="blog_article"
                                        value={blogData.blog_article}
                                        onChange={handleEditorChanged}
                                        imagesUpload
                                        customPage
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                }

                <div className={classes.fab}>
                    {
                        props.customPageType === 1 &&
                            copyButton && data.length === 0 ?
                            <Tooltip title="Copy" className={classes.fabSpacing} placement="top" arrow>
                                <Fab aria-label="Copy" color="primary" onClick={() => setOpenCopy(true)}>
                                    <FileCopyIcon />
                                </Fab>
                            </Tooltip>
                            : null
                    }

                    <Tooltip title="Preview" className={classes.fabSpacing} placement="top" arrow>
                        <Fab aria-label="preview" color="primary" href={`${process.env.REACT_APP_MAIN_URL}/${props.clusterSlug}`} target="_blank">
                            <VisibilityIcon />
                        </Fab>
                    </Tooltip>

                    <Tooltip title="Save" className={classes.fabSpacing} placement="top" arrow>
                        <Fab aria-label="Save" color="primary" onClick={handleSubmit}>
                            <SaveIcon />
                        </Fab>
                    </Tooltip>
                </div>
            </Root>
        </Grid>
    )
}

export default ClusterCustomPage