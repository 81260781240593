import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Dialog, DialogTitle, DialogContent, Grid, Button, IconButton } from '@mui/material'
import LoadingScreen from '../../_components/LoadingScreen'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete'

/**
 * Utils
 */
import Swal from 'sweetalert2'
import { z } from 'zod'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, renderToastSuccess } from '../../_utils/Helper'
import { ErrorType, getFormError, zodValidate } from '../../_utils/ZodHelper'

/**
 * Icons
 */
import { Delete } from '@mui/icons-material'

interface AgentGroupDetailModalProps {
    id: number | null
    onClose: () => void
    onRefresh: () => void
}

const formSchema = z.object({
    agent_id: z.string().min(1, { message: "Agent is required" }),
    agent_label: z.string(),
})

type FormState = z.infer<typeof formSchema>

export interface Group {
    id: number
    leader_id: string
    leader_name: string
    name: string
    members: {
        id: number
        name: string
    }[]
}

const AgentGroupDetailModal = (props: AgentGroupDetailModalProps) => {
    const [state, setState] = useState<Group>(initialState)

    const [formState, setFormState] = useState<FormState>(initialFormState)
    const [error, setError] = useState<ErrorType<FormState>>({})

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (props.id === null) {
            setState(initialState)
            setFormState(initialFormState)
            setError({})
            props.onRefresh()
        } else {
            fetchData()
        }
        // eslint-disable-next-line
    }, [props.id])

    const fetchData = () => {
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/agent-group/${props.id || ''}`)
            .then(res => res.data)
            .then(data => {
                setState(data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setFormState(prev => ({
            ...prev,
            agent_id: value.id as string,
            agent_label: value.label,
        }))

        setError(prev => ({
            ...prev,
            agent_id: undefined,
        }))
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setFormState(prev => ({
            ...prev,
            agent_id: '',
            agent_label: '',
        }))

        setError(prev => ({
            ...prev,
            agent_id: undefined,
        }))
    }

    const submit = () => {
        zodValidate(formSchema, formState,
            () => {
                setError({})
                setIsLoading(true)

                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/agent-group/add-member`, {
                    group_id: state.id,
                    id: formState.agent_id,
                })
                    .then(res => res.data)
                    .then(data => {
                        renderToastSuccess('Berhasil menambahkan agent')
                        fetchData()
                        setFormState(initialFormState)
                    })
                    .catch(generalErrorHandler)
                    .finally(() => {
                        setIsLoading(false)
                    })
            }, setError
        )
    }

    const removeAgent = (id: number) => {
        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Apakah anda yakin ingin menghapus agent dari group ini?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya'
        }).then((result) => {
            if (result.value) {
                setIsLoading(true)

                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/agent-group/delete-member`, { id })
                    .then(res => res.data)
                    .then(data => {
                        renderToastSuccess('Berhasil menghapus agent')
                        fetchData()
                    })
                    .catch(generalErrorHandler)
                    .finally(() => {
                        setIsLoading(false)
                    })
            }
        })
    }

    return (
        <Dialog
            open={props.id !== null}
            onClose={props.onClose}
            fullWidth
            maxWidth="xs"
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>{state.name}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ marginTop: 0 }}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={9}>
                                <AsyncAutoComplete
                                    url={`${process.env.REACT_APP_API_URL}/autocomplete/user/agent`}
                                    label="Add Agent"
                                    name="agent_id"
                                    initialQuery={formState.agent_label}
                                    onChange={handleAutocomplete}
                                    onInputChange={handleAutocompleteInputChanged}
                                    placeholder="Masukan Nama Agent"
                                    errorText={getFormError(error.agent_id)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    color="primary"
                                    onClick={submit}
                                    variant='contained'
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                Name
                            </Grid>
                            <Grid item xs={3}>
                                Action
                            </Grid>
                            {
                                state.members.length ?
                                    state.members.map(member =>
                                        <React.Fragment key={member.id}>
                                            <Grid item xs={9}>
                                                {member.name}
                                            </Grid>
                                            <Grid item xs={3}>
                                                <IconButton
                                                    color='error'
                                                    style={{ marginLeft: 'auto', marginBottom: 'auto' }}
                                                    onClick={() => removeAgent(member.id)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Grid>
                                        </React.Fragment>
                                    )
                                    :
                                    <Grid item xs={12}>
                                        Belum ada member
                                    </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

const initialState = {
    id: -1,
    leader_id: '',
    leader_name: '',
    name: '',
    members: [],
}

const initialFormState = {
    agent_id: '',
    agent_label: '',
}

export default AgentGroupDetailModal
