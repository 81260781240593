import React, { Fragment, useContext, useState } from 'react';
import { Button, Link, Switch } from '@mui/material';
import { Link as RouterLink, useNavigate } from "react-router-dom";

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Context
 */
import { PermissionContext } from "../../_contexts/PermissionContext";

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import helpers from '../../_utils/Helpers';
import { renderWarningButton } from '../../_utils/Helper';


const ListingList = () => {
	const API_URL = `${process.env.REACT_APP_API_URL}/listing`;
	const permissions = useContext(PermissionContext);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const handlePublished = (listing: any, setRows: Function, rows: any[]) => {
		renderWarningButton("Apakah anda yakin ingin mengubah status publish?")
			.then((result) => {
				if (result.value) {
					setIsLoading(true);
					DefaultAxios.post(`${API_URL}/toggle-published`, { id: listing.id })
						.then(res => {
							const newRows = rows.map((element: any) => {
								if (+element.id === +listing.id) element['status'] = res.data ? 1 : 0;
								return element
							})
							setRows(newRows);
						})
						.catch(err => {
							helpers.generalErrorHandler(err);
						})
						.finally(() => {
							setIsLoading(false);
						})
				}
			})
	}

	return (
		<Fragment>
			<LoadingScreen open={isLoading} fullScreen />
			{
				permissions['listing.add'] &&
				<>
					<Button
						variant="contained"
						color="primary"
						component="label"
						style={{ marginBottom: 24 }}
						onClick={() => navigate('/listing/add')}
					>
						Add Data
					</Button>
				</>
			}

			<DataTable
				url={API_URL}
				columns={[
					{
						name: 'name',
						label: 'Nama',
						type: 'string',
						filterable: true,
						render: (data: any, row: any) => {
							return (
								<>
									<Link
										component={RouterLink}
										to={`/listing/edit/${row.id}`}
									>
										{data}
									</Link>
								</>
							);
						}
					},
					{
						name: 'cluster_name',
						label: 'Cluster',
						type: 'string',
						filterable: true
					},
					{
						name: 'status',
						label: 'Is Published',
						filterable: true,
						sortable: false,
						options: [
							{
								key: '',
								value: 'All'
							},
							{
								key: '0',
								value: 'Not Published'
							},
							{
								key: '1',
								value: 'Published'
							}
						],
						defaultOption: '',
						render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
							return <Switch
								checked={data === 1}
								onChange={() => handlePublished(row, setRows, rows)}
								color="secondary"
							/>
						}
					},
				]}
			/>
		</Fragment>
	);
}

export default ListingList;