import { z, ZodFormattedError } from "zod"

export type ErrorType<T> = Partial<ZodFormattedError<T>>

export const getFormError = (errorValue?: { _errors: string[] }) => {
    if (!errorValue) return ''

    if (errorValue._errors.length === 0) return ''

    return errorValue._errors[0]
}

export const zodValidate = (schema: z.Schema, data: any, onSuccess: () => void, onError: (errors: Partial<ZodFormattedError<any>>) => void) => {
    const result = schema.safeParse(data)

    if (!result.success) {
        const errors = result.error.format()
        onError(errors)
        return;
    }

    onSuccess()
}
