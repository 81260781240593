import * as React from 'react';
// import {
//     Route,
// } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import { RouteComponentProps } from '@reach/router';

interface MobileAppRouteProps extends RouteComponentProps {
    component: any;
}

const MobileAppRoute = (props: MobileAppRouteProps) => {
    const { component: Component, ...rest } = props;
    const [isSignedIn, setIsSignedIn] = React.useState(false)
    
    React.useEffect(() => {
        const interval = setInterval(() => {
            setIsSignedIn(prev => prev || !!localStorage.getItem('jwt_token'))
        }, 250)

        return () => {
            clearInterval(interval)
        }
    }, [])
    
    return (
        isSignedIn ? <Component {...rest} /> : <LoadingScreen fullScreen open />
        // <Route
        //     {...rest}
        //     element={isSignedIn ? <Component {...rest} /> : <LoadingScreen fullScreen open />}
        // />
    );
};

export default MobileAppRoute;