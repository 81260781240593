import React, { Fragment } from 'react';
import { Button, Link } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// Components
import DataTable from '../../../_components/_dataTable/DataTable';

interface Props {
    permissions?: any;
}

const CategoryList = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/blog-categories';
    // const classes = useStyles();
    const navigate = useNavigate();

    const columns = [
        {
            name: 'title',
            label: 'Title',
            type: 'string',
            filterable: true,
            render: (data: any, row: any) => {
                return (
                    <>
                        <Link
                            component={RouterLink}
                            to={`/category/edit/${row.id}`}
                        >
                            {data}
                        </Link>
                    </>
                );
            }
        },
        {
            name: 'slug',
            label: 'Slug',
            type: 'string',
            filterable: true
        },
    ];

    // const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    return (
        <Fragment>
            <Button
                variant="contained"
                color="primary"
                component="label"
                style={{ marginBottom: 24 }}
                onClick={() => navigate(`/category/add`)}
            >
                Add Data
            </Button>
            <DataTable
                url={API_URL}
                // reDraw={reDrawDatatable}
                columns={columns}
            />
        </Fragment>
    );
}

export default CategoryList;