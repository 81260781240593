import React, { useState } from 'react';
import { Grid, IconButton, Theme, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';

/**
 * Components
 */
import LightTooltip from '../../_components/LightTooltip';
import ClusterPlacementModal from "../_cluster/ClusterPlacementModal";
import DateRangePicker, { DateRange } from '../../_components/_form/DateRangePicker';

/**
 * Icons
 */
import GroupIcon from '@mui/icons-material/Group';

/**
 * Utils
 */
import DataTable from '../../_components/_dataTable/DataTable';

const formatDate = (date: DateRange) => {
    if (!date) return ''

    return moment(date).format('DD/MM/YYYY')
}

const ClusterShowingReport = () => {
    const theme = useTheme<Theme>()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const API_URL = `${process.env.REACT_APP_API_URL}/cluster-showing-report`;

    const [redraw, setRedraw] = useState(0);
    const [clusterPlacementModalValue, setClusterPlacementModalValue] = useState(0);

    const initialDateFilter = {
        date_start: moment().subtract(1, 'weeks'),
        date_end: moment()
    };

    const columns = [
        {
            name: 'name',
            label: 'Cluster Name',
            type: 'string',
            filterable: true,
        },
        {
            name: 'cluster_placement_member',
            label: 'PIC',
            type: 'string',
            filterable: true,
            sortable: false,
            render: (data: string, row: any) => {
                let text: any = "";
                if (data) {
                    if (data.includes(",")) {
                        let new_data = data.split(",");

                        text = new_data.map((advisor_data, key) => {
                            if (advisor_data.includes('IS_NOT_ACTIVE')) {
                                let advisor_name = advisor_data.replace('IS_NOT_ACTIVE', '');

                                return (
                                    <span style={{ textDecoration: "line-through", color: "red" }}>
                                        {advisor_name}{key + 1 === new_data.length ? "" : ","}
                                    </span>
                                )
                            } else {
                                return (
                                    <span>{advisor_data}{key + 1 === new_data.length ? "" : ","}</span>
                                )
                            }
                        })
                    } else {
                        if (data.includes('IS_NOT_ACTIVE')) {
                            const advisor_name = data.replace('IS_NOT_ACTIVE', '');
                            text = (
                                <span style={{ textDecoration: "line-through", color: "red" }}>
                                    {advisor_name}
                                </span>
                            )
                        } else {
                            text = <span>{data}</span>
                        }
                    }
                } else {
                    text = "-";
                }

                return (
                    <>
                        {text}
                        {
                            <LightTooltip title="Edit Cluster Placement">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => setClusterPlacementModalValue(row.id)}
                                >
                                    <GroupIcon fontSize="small" />
                                </IconButton>
                            </LightTooltip>
                        }
                    </>
                )
            }
        },
        {
            name: 'counter',
            label: 'Showing',
            type: 'string',
            filterable: false,
            render: (data: any) => {
                return data || 0;
            }
        },
    ];

    const footerColumns = [
        {
            name: 'name',
            render: () => {
                return 'Total';
            }
        },
        {
            name: 'counter',
            render: (data: any[]) => {
                return data.reduce((a, b) => {
                    return a + (b || 0);
                }, 0);
            }
        },
    ];

    const [dateFilter, setDateFilter] = useState<{ date_start: DateRange, date_end: DateRange }>(initialDateFilter);
    const [customParams, setCustomParams] = useState({
        created_at: `${formatDate(dateFilter.date_start)} - ${formatDate(dateFilter.date_end)}`
    });

    const handleDateChanged = (dates: { startDate: DateRange, endDate: DateRange }) => {
        let newFilter = {
            date_start: dates.startDate,
            date_end: dates.endDate
        }
        setDateFilter(newFilter);
    }

    return (
        <Grid container spacing={3}>
            <ClusterPlacementModal
                isOpen={clusterPlacementModalValue !== 0 ? true : false}
                onClose={() => {
                    setRedraw(redraw + 1);
                    setClusterPlacementModalValue(0);
                }}
                cluster_id={clusterPlacementModalValue}
            />

            {
                !isMobile &&
                <Grid item xs={12} md={9} />
            }
            <Grid item xs={12} md={3} style={{ textAlign: 'end' }}>
                <DateRangePicker
                    startDate={dateFilter.date_start}
                    endDate={dateFilter.date_end}
                    onDatesChange={handleDateChanged}
                    onFinished={() => setCustomParams({
                        created_at: `${formatDate(dateFilter.date_start)} - ${formatDate(dateFilter.date_end)}`
                    })}
                    fullWidth
                />

            </Grid>

            <Grid item xs={12}>
                <DataTable
                    reDraw={redraw}
                    url={API_URL}
                    columns={columns}
                    customParams={customParams}
                    footerColumns={footerColumns}
                />
            </Grid>
        </Grid>
    );
}

export default ClusterShowingReport;