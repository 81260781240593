import React, { useState } from 'react'

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import URLPlacementFormModal, { URLPlacementFormModalState } from './URLPlacementFormModal';
import { Button, IconButton, Switch, Tooltip } from '@mui/material';
import URLPlacementMemberModal from './URLPlacementMemberModal';

/**
 * Utils
 */
import { generateStyle } from '../../_utils/DefaultStyles';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';
import DefaultAxios from '../../_utils/DefaultAxios';

/**
 * Icons
 */
import { Add, Edit, Group } from '@mui/icons-material';
import LoadingScreen from '../../_components/LoadingScreen';

const useStyles = generateStyle(theme => ({
    actionContainer: {
        display: 'flex'
    },
    marginBottom: {
        marginBottom: "1rem"
    }
}), "URLPlacementList")

const URLPlacementList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/url-whatsapp';
    const { Root, classes } = useStyles()

    const [isLoading, setIsLoading] = useState(false)
    const [reDrawDatatable, setReDrawDatatable] = useState(1)

    // Flag for add form open or not
    const [isAddOpen, setIsAddOpen] = useState(false)
    // Contain selected data to be edited
    const [selectedRow, setSelectedRow] = useState<URLPlacementFormModalState | null>(null)
    // Contain selected row id to open member modal
    const [selectedMemberRow, setSelectedMemberRow] = useState<number | null>(null)

    const handleActiveSwitch = (row: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status aktif ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)

                    DefaultAxios.post(`${API_URL}/toggle-active`, { id: row.id })
                        .then(() => {
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +row.id) element['is_active'] = element['is_active'] === 0 ? 1 : 0
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            })
    }

    const columns = [
        {
            name: 'slug',
            label: 'Slug',
            type: 'string',
            filterable: true,
            sortable: true,
        },
        {
            name: 'group_name',
            label: 'Group',
            type: 'string',
            filterable: true,
            sortable: true,
        },
        {
            name: 'placement_member',
            label: 'Member',
            type: 'string',
            filterable: true,
            sortable: true,
            render: (data: string, row: any) => {
                let text: any = "";
                if (data) {
                    if (data.includes(",")) {
                        let new_data = data.split(",");

                        text = new_data.map((advisor_data, key) => {
                            if (advisor_data.includes('IS_NOT_ACTIVE')) {
                                let advisor_name = advisor_data.replace('IS_NOT_ACTIVE', '');

                                return (
                                    <span style={{ textDecoration: "line-through", color: "red" }}>
                                        {advisor_name}{key + 1 === new_data.length ? "" : ","}
                                    </span>
                                )
                            } else {
                                return (
                                    <span>{advisor_data}{key + 1 === new_data.length ? "" : ","}</span>
                                )
                            }
                        })
                    } else {
                        if (data.includes('IS_NOT_ACTIVE')) {
                            const advisor_name = data.replace('IS_NOT_ACTIVE', '');
                            text = (
                                <span style={{ textDecoration: "line-through", color: "red" }}>
                                    {advisor_name}
                                </span>
                            )
                        } else {
                            text = <span>{data}</span>
                        }
                    }
                } else {
                    text = "-";
                }

                return (
                    <>
                        {text}
                        <Tooltip title="Edit Member">
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => setSelectedMemberRow(row.id)}
                            >
                                <Group fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            }
        },
        {
            name: 'is_active',
            label: 'Active',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'Inactive'
                },
                {
                    key: '1',
                    value: 'Active'
                }
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return (
                    <Switch
                        checked={data === 1}
                        onChange={() => handleActiveSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                )
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => (
                <Tooltip title="Edit URL Placement">
                    <IconButton
                        color="primary"
                        component="span"
                        onClick={() => setSelectedRow(row)}
                    >
                        <Edit fontSize="small" />
                    </IconButton>
                </Tooltip>
            )
        }
    ];

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />

            <URLPlacementFormModal
                isOpen={isAddOpen || selectedRow !== null}
                onClose={() => {
                    setIsAddOpen(false)
                    setSelectedRow(null)
                }}
                onFinish={() => {
                    setReDrawDatatable(prev => prev + 1)
                }}
                editData={selectedRow || undefined}
            />

            <URLPlacementMemberModal
                isOpen={!!selectedMemberRow}
                id={selectedMemberRow || undefined}
                onClose={refresh => {
                    setSelectedMemberRow(null)
                    if (refresh) setReDrawDatatable(prev => prev + 1)
                }}
            />

            <Button
                variant="contained"
                size="small"
                startIcon={<Add />}
                className={classes.marginBottom}
                onClick={() => setIsAddOpen(true)}
            >
                Add Data
            </Button>

            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </Root>
    )
}

export default URLPlacementList
