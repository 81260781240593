import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, Button, Grid } from '@mui/material'
import LocalDatePicker from '../../../_components/_form/DatePicker'

interface IProps {
    open: boolean,
    onClose: () => void
    initialDate: string
    onSubmit: (date: Date) => void
}

const EditDateModal = (props: IProps) => {
    const [date, setDate] = useState<Date>(new Date())

    useEffect(() => {
        setDate(new Date(props.initialDate))
        return () => setDate(new Date())
    }, [props.open, props.initialDate])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>Edit Date</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <LocalDatePicker
                            onChange={(date) => date ? setDate(date) : null}
                            value={date}
                            label='Tanggal'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            style={{ float: 'right' }}
                            disabled={!date}
                            onClick={() => props.onSubmit(date)}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default EditDateModal