import React, { useState } from 'react';
import { Dialog, Grid, Divider, TableContainer, Paper, Chip, styled } from '@mui/material';

/**
 * Asset
 */
import pdfPng from '../Assets/Images/png/pdf.png'
import docPng from '../Assets/Images/png/doc.png';
import videoPng from '../Assets/Images/png/video.png';

interface Props {
    files: IFile[]
}

export interface IFile {
    id: string;
    created_at: string;
    filepath: string;
    name: string;
    type: string;
}

const Root = styled('div')({
    width: '100%',
    '& .MuiTextField-root': {
        backgroundColor: '#fff'
    }
})

const fileTypes = [
    {
        value: 'payment_proof',
        label: 'Bukti Pembayaran'
    },
    {
        value: 'sales_order',
        label: 'Surat Pemesanan'
    },
    {
        value: 'ktp_customer',
        label: 'KTP Customer'
    },
    {
        value: 'npwp',
        label: 'NPWP'
    },
    {
        value: 'other',
        label: 'Lainnya'
    }
];

const FileList = (props: Props) => {
    const { files } = props;

    const [previewImage, setPreviewImage] = useState<undefined | string>(undefined);

    const getLabel = (value: string) => {
        let type = fileTypes.find(item => item.value === value)
        return type?.label ? type.label : '-'
    }

    const handlePreviewFile = (src: string, isImage: boolean) => {
        if (isImage) {
            setPreviewImage(src);
        } else {
            window.open(src);
        }
    }

    const renderFilesNew = () => {
        return files.map((file, key) => {
            let src = '';
            let isImage = false;
            let filepath = file.filepath;

            let explode = filepath.split('?');
            const match = /(\.\w+$)/g.exec(explode[0]);

            if (match) {
                if (match[1] === '.pdf' || match[1] === '.PDF') {
                    src = pdfPng;
                } else if (match[1] === '.doc' || match[1] === '.docx') {
                    src = docPng;
                } else if (['.flv', '.mp4', '.m3u8', '.ts', '.3gp', '.mkv', '.mov', '.avi', '.wmv'].includes(match[1])) {
                    src = videoPng;
                } else {
                    src = filepath;
                    isImage = true;
                }
            }

            return (
                <>
                    <Grid container sx={{ py: 1 }} key={filepath}>
                        <Grid item md={4}>
                            <img height={50} width={50} src={src} alt="file" onClick={() => handlePreviewFile(filepath, isImage)} />
                        </Grid>
                        <Grid item md={8}>
                            <span
                                className="file-name"
                                onClick={() => handlePreviewFile(filepath, isImage)}
                            >
                                {file.name}
                                <br />
                                <Chip color="primary" label={getLabel(file.type)} size="small" />
                            </span>
                        </Grid>
                    </Grid>

                    <Divider />
                </>
            )
        })
    }

    return (
        <Root>
            <Dialog
                open={!!previewImage}
                onClose={() => { setPreviewImage(undefined) }}
                scroll="body"
                maxWidth={false}
            >
                <img src={previewImage} width="100%" alt="preview" />
            </Dialog>

            <TableContainer component={Paper} elevation={0}>
                {renderFilesNew()}
            </TableContainer>
        </Root>
    );
}

export default FileList;