import React from 'react';
import { createRoot } from 'react-dom/client'

import './index.css';
import * as Sentry from "@sentry/react";
import { defaultIntegrations } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';
import * as serviceWorker from './serviceWorker';

import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import 'date-fns';
import { enUS } from 'date-fns/locale'
import setDefaultOptions from 'date-fns/setDefaultOptions'
import { pink } from '@mui/material/colors';

setDefaultOptions({
    locale: enUS,
    weekStartsOn: 1,
})

if (process.env.REACT_APP_PUBLIC_SENTRY_DSN) {
    let apidomain = process.env.REACT_APP_API_URL ? (new URL(process.env.REACT_APP_API_URL)).hostname : 'localhost';

    Sentry.init({
        dsn: process.env.REACT_APP_PUBLIC_SENTRY_DSN,
        environment: process.env.REACT_APP_ENV,
        release: process.env.REACT_APP_SENTRY_RELEASE,
        integrations: [
            ...defaultIntegrations,
            new Integrations.BrowserTracing({
                tracingOrigins: [apidomain, /^\//],
            }),
        ],
        autoSessionTracking: true,
        tracesSampleRate: 1.0,
    });
    if (localStorage.getItem('user_id')) {
        Sentry.setUser({
            id: localStorage.getItem('user_id') ?? undefined
        })
    }
}

declare module '@mui/material' {
    interface Palette {
        custom: string
    }

    interface PaletteOptions {
        custom?: any
    }
}

const theme = createTheme({
    palette: {
        primary: {
            light: '#63ccff',
            main: 'rgb(0, 86, 168)',
            dark: '#ab0204',
            contrastText: '#fff',
        },
        secondary: pink,
        background: {
            default: '#fff'
        },
        custom: '#ababab'
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline',
                    }
                },
            }
        }
    },
});

const container = document.getElementById('root')

const root = createRoot(container!)
root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <App />
            </LocalizationProvider >
        </ThemeProvider>
    </StyledEngineProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
