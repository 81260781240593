import React from 'react';
import { CircularProgress, styled } from '@mui/material';

interface Props {
    open: boolean;
    fullScreen?: boolean;
}

const Root = styled('div')({
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    opacity: 0.3,
})

const SCircularProgress = styled(CircularProgress)({
    top: '50%',
    position: 'absolute',
    left: 'calc(50% - 20px)'
})

const LoadingScreen = (props: Props) => {
    return (
        <Root
            style={{
                display: props.open ? 'block' : 'none',
                position: props.fullScreen ? 'fixed' : 'absolute',
                zIndex: props.fullScreen ? 1301 : 1099
            }}
        >
            <SCircularProgress />
        </Root>
    );
}

LoadingScreen.defaultProps = {
    fullScreen: false
};

export default LoadingScreen;