import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Theme } from '@mui/material'

/**
 * Utils
 */
import { FunnelReportItem } from '../FunnelReport'
import { format } from 'date-fns'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../../_utils/Helper'

/**
 * Icons
 */
import { ChevronLeft, Person, Place } from '@mui/icons-material'
import { generateStyle } from '../../../_utils/DefaultStyles'
import { convertNumber } from '../../../_utils/Helpers'

interface FunnelReportClosingListProps {
    item: null | FunnelReportItem
    onClose: () => void
    isMobile: boolean
    date: null | string
    title: string
    type: string
}

type Item = {
    utj_date: string
    client_name: string
    cluster_name: string
    status_label: string
    transaction_amount: number
}

type ItemShowing = {
    clusters: string
    datetime: string
}

const FunnelReportClosingList = (props: FunnelReportClosingListProps) => {
    const { Root, classes } = useStyles()
    const [isLoading, setIsLoading] = useState(false)

    const [items, setItems] = useState<Item[]>([])
    const [itemShowings, setItemShowings] = useState<ItemShowing[]>([])

    const handleFetchData = (type: string) => {
        if (type === 'showing') {
            fetchDataShowing()
        } else {
            fetchDataClosing()
        }
    }

    useEffect(() => {
        if (props.item?.id && props.type) {
            handleFetchData(props.type)
        }

        return () => {
            setItems([])
            setItemShowings([])
        }
        // eslint-disable-next-line
    }, [props.item?.id, props.type])

    const fetchDataClosing = () => {
        if (!props.item?.id) return
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/funnel-report/agent-closing`, {
            params: {
                agent_id: props.item.id,
                date: props.date,
                type: props.type === 'utj_refundable' ? 0 : props.type === 'utj_non_refundable' ? 1 : 2
            }
        })
            .then(res => res.data)
            .then(data => {
                setItems(data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const fetchDataShowing = () => {
        if (!props.item?.id) return
        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/funnel-report/agent-showing`, {
            params: {
                agent_id: props.item.id,
                date: props.date,
            }
        })
            .then(res => res.data)
            .then(data => {
                setItemShowings(data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const renderAgentShowing = (item: ItemShowing) => (
        <div className={classes.showing}>
            <span style={{ width: '50%' }}>{item.clusters}</span>
            <span>{format(new Date(item.datetime), 'dd MMMM yyyy')}</span>
        </div>
    )

    const renderAgentClosing = (item: Item) => (
        <div className={classes.card}>
            <div className={classes.date}>
                {item.utj_date ? format(new Date(item.utj_date), 'EEEE, dd MMM yyyy') : '-'}
            </div>
            <div className={classes.bottomContainer}>
                <div className={classes.status}>
                    {item.status_label}
                </div>
                <div>
                    <div className={classes.dataContainer}>
                        <Person fontSize='small' className={classes.icon} />
                        <div className={classes.data}>{item.client_name || '-'}</div>
                    </div>
                    <div className={classes.dataContainer}>
                        <Place fontSize='small' className={classes.icon} />
                        <div className={classes.data}>{item.cluster_name || '-'}</div>
                    </div>
                </div>
            </div>
            <div className={classes.transactionArea}>
                <div style={{ width: '50%' }}>
                </div>
                <div>
                    <div>Transaction</div>
                    <span>
                        {item.transaction_amount ? convertNumber(String(item.transaction_amount), "Rp. ") : 'Rp. -'}
                    </span>
                </div>
            </div>
        </div>
    )

    const renderItem = useMemo(() => {
        const loadingData = <div className={classes.card} style={{ textAlign: 'center' }}>
            <CircularProgress size={32} />
        </div>

        const noData = <div className={classes.card}>
            Maaf, list {props.type === 'showing' ? 'showing' : 'closing'} tidak ditemukan
        </div>

        const defaultRender = props.type === 'showing' ? itemShowings.map((item) => renderAgentShowing(item)) : items.map((item) => renderAgentClosing(item))

        if (isLoading) {
            return loadingData
        } else {
            if (items.length || itemShowings.length) {
                return defaultRender
            } else {
                return noData
            }
        }

        // eslint-disable-next-line
    }, [items, itemShowings, props.type, isLoading])

    return (
        <Dialog
            open={!!props.item}
            onClose={props.onClose}
            fullWidth
            maxWidth="xs"
            fullScreen={props.isMobile}
        >
            <Root>
                <DialogTitle className={classes.appBar}>
                    <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <Grid>
                            <IconButton aria-label="back" onClick={() => props.onClose()} style={{ marginLeft: -8, marginRight: 2, color: '#fff' }}>
                                <ChevronLeft />
                            </IconButton>
                            <span>{props.title}</span>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ padding: 0 }}>
                    {renderItem}
                </DialogContent>
            </Root>
        </Dialog>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingTop: 10,
        paddingBottom: 10,
        marginBottom: 0,
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
    },
    card: {
        padding: 16,
        paddingTop: 8,
        paddingBottom: 8,
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: 'solid',
    },
    date: {
        fontSize: 12,
        marginBottom: 8,
    },
    bottomContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    showing: {
        padding: 20,
        paddingTop: 12,
        paddingBottom: 12,
        borderBottom: '1px solid #ddd',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    status: {
        padding: 4,
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: 'black',
        borderStyle: 'solid',
        marginRight: 16,
        width: 'calc(50% - 16px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 14,
    },
    dataContainer: {
        display: 'flex',
        alignItems: 'start',
        marginBottom: 4,
    },
    icon: {
        marginRight: 8,
    },
    data: {
        fontSize: 14,
    },
    transactionArea: {
        marginTop: 5,
        borderRadius: 6,
        paddingTop: 8,
        paddingBottom: 8,
        background: '#f6f6f6',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 14,
        '& * span': {
            fontWeight: "700",
            fontSize: 14
        }
    },
}), "FunnelReportClosing_List"
);

export default FunnelReportClosingList
