import { Grid, MenuItem, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

/**
 * Components
*/
import DateRangePicker, { DateRange } from '../../_components/_form/DateRangePicker'

/**
 * Context
 */
import { PermissionContext } from '../../_contexts/PermissionContext'

/**
 * Utils
*/
import { addDays } from 'date-fns'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../_utils/Helper'
import AdsMainTable from './_components/AdsMainTable'

type Props = {}

export const initialState = {
    startDate: addDays(new Date(), -7),
    endDate: addDays(new Date(), -1),
    group_id: null,
    search: '',
    fetch: 0
}

export interface IState {
    [key: string]: any
    startDate: DateRange,
    endDate: DateRange,
    group_id: string | null,
    search: string
    fetch: number
}

export interface IFacebookAds {
    id: number,
    name: string
    leads_warm_and_hot_count: number
    client_score_average: number
    cluster_price_average: number
    cpl: number
    leads_count: number
    showing_count: number
    closing_count: number
}


const DashboardAds = (props: Props) => {
    const [state, setState] = useState<IState>(initialState)
    const [groups, setGroups] = useState<{ id: number, name: string }[]>([]);
    const permissions = useContext(PermissionContext)
    const [redraw, setRedraw] = useState(0)

    const fetchGroups = () => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/lead-group/all`)
            .then(res => res.data)
            .then(data => {
                setGroups(data)
            })
            .catch(generalErrorHandler)
    }

    const handleDateChanged = (dates: { startDate: DateRange, endDate: DateRange }) => {
        setState(prev => ({
            ...prev,
            ...dates,
            fetch: 0
        }));
    }

    useEffect(() => {
        fetchGroups()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Grid container rowSpacing={3}>
                <Grid item xs={12} md={6}>
                    <Typography variant='h5'>Campaign Dashboard</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={{ md: 1, xs: 2 }} >
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant="outlined"
                                label="Search Cluster"
                                name="search"
                                value={state.search}
                                onChange={(e) => {
                                    setState(prev => ({ ...prev, search: e.target.value, fetch: prev.fetch + 1 }))
                                }}
                                fullWidth
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <DateRangePicker
                                startDate={state.startDate}
                                endDate={state.endDate}
                                onDatesChange={handleDateChanged}
                                format="DD/M/Y"
                                onFinished={() => setRedraw(new Date().getTime())}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            {
                                permissions['report.filter-group'] &&
                                <div style={{ marginLeft: 'auto', minWidth: 100 }}>
                                    <TextField
                                        select
                                        label="Group"
                                        name="group_id"
                                        value={state.group_id || 'all'}
                                        onChange={(e) => {
                                            setState(prev => ({ ...prev, group_id: e.target.value }))
                                        }}
                                        fullWidth
                                        size='small'
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {groups.map(group => <MenuItem key={group.id.toString()} value={group.id.toString()}>{group.name}</MenuItem>)}
                                    </TextField>
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <AdsMainTable
                        filter={state}
                        url='/facebook-ads'
                        redraw={redraw}
                        isDashboard
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default DashboardAds