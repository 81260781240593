import React, { useReducer, useState, useEffect } from 'react'
import moment from 'moment';
import { Box, Chip, Grid, Button, TextField, Typography, FormGroup, FormControlLabel, Checkbox, Card, CardHeader, CardContent, InputAdornment, Divider } from '@mui/material'

/* 
* Components
*/
import AsyncAutoComplete from '../../_components/_form/AsyncAutoComplete'
import DateRangePicker, { DateRange } from '../../_components/_form/DateRangePicker'
import WhatsappPreview from './_components/WhatsappPreview';

/* 
* Icons & Styles
*/
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

/* 
* Utils
*/
import { generalErrorHandler, inputNumber } from '../../_utils/Helper';
import DefaultAxios from '../../_utils/DefaultAxios';
import { convertNumber } from '../../_utils/Helpers';
import PromptModal from './_components/PromptModal';
import Swal from 'sweetalert2';
import LoadingScreen from '../../_components/LoadingScreen';
import { generateStyle } from '../../_utils/DefaultStyles';

/* 
* Interfaces
*/

interface IStateProps {
    [name: string]: any
    start_date: string
    end_date: string
    cluster_ids: number[] | null
    statuses: string[] | null
    categories: string[] | null
    lowest_price: number
    highest_price: number
    excluded_cluster_ids: number[] | null
    excluded_lowest_price: number
    excluded_highest_price: number
    is_ignore_recent_broadcasted_users: number | boolean
    is_exclude_30_days_same_broadcast: number | boolean
    template_id: string | null
    variables: string[] | null
    template_fetch_id?: number | null
}

interface IErrorText {
    template_id: string
}

interface IAction {
    type: string,
    name: string,
    payload: any
}

export interface ITemplate {
    id: number
    qontak_template_id: string
    name: string
    category: string
    body: string
    variables: number
    media: string | null
    media_type: string | null
    buttons: Object[]
    created_at?: string
    updated_at?: string
}

/* 
* Initialization
*/

const formatDate = (date: DateRange) => {
    if (!date) return ''
    return moment(date).format('YYYY-MM-DD')
}

const initialDateFilter = {
    date_start: null,
    date_end: null
}

const initialState = {
    start_date: formatDate(initialDateFilter.date_start) || "",
    end_date: formatDate(initialDateFilter.date_end) || "",
    cluster_ids: null,
    statuses: null,
    categories: null,
    lowest_price: 0,
    highest_price: 0,
    excluded_cluster_ids: null,
    excluded_lowest_price: 0,
    excluded_highest_price: 0,
    is_ignore_recent_broadcasted_users: 0,
    is_exclude_30_days_same_broadcast: 1,
    template_id: null,
    variables: [],
    template_fetch_id: null
}

const initialErrorState = {
    template_id: ""
}

const initialTemplate = {
    id: 0,
    qontak_template_id: "",
    name: "",
    category: "",
    body: "this message will show here",
    variables: 1,
    media: null,
    media_type: null,
    buttons: []
}

/* 
* Styles
*/
const useStyles = generateStyle(theme => ({
    inputBroadcast: {
        marginBottom: '30px',
        padding: '24px 24px 24px 0'
    },
    previewBroadcast: {
        backgroundColor: '#eee',
        padding: '16px',
        height: '100%',
        display: 'flex',
    },
    whatsappPreview: {
        alignSelf: 'start',
        position: 'sticky',
        top: '104px'
    },
    tick: {
        height: '24px'
    },
    backgroudWhite: {
        backgroundColor: 'white'
    },
    outlined: {
        border: '1px solid rgba(0, 0, 0, 0.12)'
    },
    cardContents: {
        WebkitTransition: ' height 300ms cubic- bezier(0.4, 0, 0.2, 1) 0ms',
        transition: 'height 300ms cubic - bezier(0.4, 0, 0.2, 1) 0ms'
    },
    attachment: {
        width: '100%',
        background: '#eee',
        display: 'flex',
        flexDirection: 'column'
    },
    datePicker: {
        display: 'flex',
        height: '56px',
        flexGrow: 1,
        padding: 0,
        '&& > div': {
            padding: '0 !important'
        },
        '&& div': {
            height: '56px',
            padding: '9px'
        }
    }
}), "FormBroadcast")

const formatBytes = (bytes: number, decimals: number = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const inputReducer = (state: IStateProps, action: IAction) => {
    switch (action.type) {
        case 'SET_ITEM':
            return {
                ...state,
                [action.name]: action.payload
            }
        case 'RESET_ITEM':
            return {
                ...state,
                [action.name]: null
            }
        default:
            return state
    }
}

const inputErrorReducer = (state: IErrorText, action: IAction) => {
    if (action.type === 'SET_ITEM') {
        return {
            ...state,
            [action.name]: action.payload
        }
    } else if (action.type === 'RESET_ITEM') {
        return { ...initialErrorState };
    } else if (action.type === 'REPLACE_STATE') {
        const newState = action.payload;
        return { ...newState as IErrorText };
    }

    return { ...state } as IErrorText;
}

const FormBroadcast = () => {
    const { Root, classes } = useStyles()
    const [dateFilter, setDateFilter] = useState<{ date_start: DateRange | null, date_end: DateRange | null }>(initialDateFilter);
    const [state, setState] = useReducer(inputReducer, initialState)
    const [errorState, setErrorState] = useReducer(inputErrorReducer, initialErrorState)
    const [exclude, setExclude] = useState<boolean>(false)
    const [clusters, setClusters] = useState<{ id: number, label: string }[]>([])
    const [excludedClusters, setExcludedClusters] = useState<{ id: number, label: string }[] | null>([])
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [template, setTemplate] = useState<ITemplate>(initialTemplate)
    const [loading, setLoading] = useState(false)
    const [media, setMedia] = useState<{ file: File | null, preview: string | null }>({ file: null, preview: null })
    const [leads, setLeads] = useState<number>(0)
    const [loadScreen, setLoadScreen] = useState(false)

    const URL = process.env.REACT_APP_API_URL + '/qontak/' + state.template_fetch_id
    const fetchTemplate = () => {
        setLoading(true)
        DefaultAxios
            .get(URL)
            .then(res => {
                setTemplate(res.data)
                setState({ type: 'SET_ITEM', name: 'template_id', payload: res.data.qontak_template_id })
                generateVariables(res.data.variables)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const fetchLeads = () => {
        const URL = process.env.REACT_APP_API_URL + '/qontak/target'
        DefaultAxios
            .get(URL, { params: state })
            .then(res => {
                setLeads(res.data as number)
                if (res.data === 0) {
                    Swal.fire('Information', 'Tidak ada Leads yang ditemukan', 'info')
                } else {
                    setOpenConfirmation(true)
                }
            })
            .catch(err => {
                generalErrorHandler(err)
            })
            .finally(() => {
            })
    }

    useEffect(() => {
        if (state.template_fetch_id) {
            fetchTemplate()
            setMedia({ file: null, preview: null })
        }
        // eslint-disable-next-line
    }, [state.template_fetch_id])

    useEffect(() => {
        if (!exclude) {
            const names = ["excluded_cluster_ids", "excluded_lowest_price", "excluded_highest_price"]
            names.forEach((name) => {
                setState({ type: 'RESET_ITEM', name, payload: null })
            })
            setState({ type: 'SET_ITEM', name: 'is_ignore_recent_broadcasted_users', payload: 0 })
            setExcludedClusters(null)
        }
    }, [exclude])

    const generateVariables = (variables: number) => {
        const variable = 'firstName'
        let newArr = []
        for (let i = 0; i < variables; i++) {
            newArr.push(variable)
        }
        setState({ type: 'SET_ITEM', name: 'variables', payload: newArr })
    }

    const checkValidation = () => {
        let isValid = true
        let newError = { ...initialErrorState } as IErrorText

        if (!state.template_id) {
            newError.template_id = 'Template id wajib diisi';
            isValid = false;
        }

        setErrorState({ name: '', payload: newError, type: "REPLACE_STATE" })

        return isValid
    }

    const generateFd = (state: IStateProps) => {
        const data = new FormData()
        for (let key in state) {
            if (state[key] === null) {
                data.append(key, '')
            } else {
                data.append(key, state[key])
            }

            if (typeof state[key] === 'object') {
                for (let index in state[key]) {
                    data.append(`${key}[${index}]`, state[key][index])
                }
            }
        }
        return data
    }

    /* handle export csv */
    const handleExport = () => {
        const URL = process.env.REACT_APP_API_URL + '/qontak/export'
        delete state.template_fetch_id
        setLoadScreen(true)
        DefaultAxios
            .get(URL, { params: state, responseType: 'blob' })
            .then((res) => {
                setLoadScreen(false)
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Contact.csv`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                setLoadScreen(false)
                generalErrorHandler(err)
            })
    }


    const handleSubmit = () => {
        const URL = process.env.REACT_APP_API_URL + '/qontak/broadcast'
        delete state.template_fetch_id
        const data = generateFd(state)
        if (media.file) {
            data.append('media', media.file)
        }
        setLoadScreen(true)
        DefaultAxios
            .post(URL, data)
            .then((res) => {
                setLoadScreen(false)
                Swal.fire("Success", "Broadcast Sent!", "success")
            })
            .catch(err => {
                setLoadScreen(false)
                generalErrorHandler(err)
            })
    }

    const handleDateChanged = (dates: { startDate: DateRange, endDate: DateRange }) => {
        const newdateFilter = {
            date_start: dates.startDate,
            date_end: dates.endDate
        }
        setDateFilter(prevDate => newdateFilter)
        setState({ type: 'SET_ITEM', name: 'start_date', payload: formatDate(newdateFilter.date_start) })
        setState({ type: 'SET_ITEM', name: 'end_date', payload: formatDate(newdateFilter.date_end) })
    }

    const handleChangeTemplate = (name: string, value: any) => {
        setState({ type: 'SET_ITEM', name, payload: value.id })
    }

    const handleChangeClusters = (name: string, value: any) => {
        setClusters(prev => {
            let clusters = prev ? [...prev] : []
            if (!clusters.some((cluster) => cluster.id === value.id)) {
                clusters.push(value)
            }
            setState({ type: 'SET_ITEM', name, payload: clusters.map((data) => data.id) })
            return clusters
        })
    }

    const handleChangeExcludedClusters = (name: string, value: any) => {
        setExcludedClusters(prev => {
            let clusters = prev ? [...prev] : []
            if (!clusters.some((cluster) => cluster.id === value.id)) {
                clusters.push(value)
            }
            setState({ type: 'SET_ITEM', name, payload: clusters.map((data) => data.id) })
            return clusters
        })
    }

    const handleDeleteCluster = (id: number) => {
        const newCluster = clusters.filter((cluster) => cluster.id !== id)
        setState({ type: 'SET_ITEM', name: 'cluster_ids', payload: newCluster.map(data => data.id) })
        setClusters(newCluster)
    }

    const handleDeleteExtendedCluster = (id: number) => {
        if (excludedClusters && excludedClusters.length > 0) {
            const newCluster = excludedClusters.filter((cluster) => cluster.id !== id)
            setState({ type: 'SET_ITEM', name: 'excluded_cluster_ids', payload: newCluster.map(data => data.id) })
            setExcludedClusters(newCluster)
        }
    }

    const handleChangePrice = (e: any) => setState({ type: 'SET_ITEM', name: e.target.name, payload: inputNumber(e.target.value) })

    const handelCheckbox = (e: any) => {
        const name: string = e.target.name
        const value: string = e.target.value
        const checked: boolean = e.target.checked
        if (name === 'statuses' || name === 'categories') {
            if (checked) {
                if (state[name] !== null) {
                    const newArr = state[name]
                    newArr?.push(value)
                    setState({ type: "SET_ITEM", name: e.target.name as string, payload: newArr })
                } else {
                    setState({ type: "SET_ITEM", name: e.target.name as string, payload: [value] })
                }
            } else {
                const newArr = state[name]?.filter(prev => prev !== value)
                setState({ type: "SET_ITEM", name: e.target.name as string, payload: newArr })
            }
        }
    }

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files && files.length > 0) {
            const preview = window.URL.createObjectURL(files[0])
            setMedia({
                file: files[0],
                preview: preview
            })
        }
        e.target.value = ""
    }

    const handleSendBroadcast = () => {
        if (!checkValidation()) {
            Swal.fire('Error', 'Template id wajib diisi', 'error')
            return
        }

        return fetchLeads()
    }

    const handleIsIgnore = (e: any) => setState({ type: "SET_ITEM", name: e.target.name, payload: e.target.checked ? 1 : 0 })

    const generateCustomCheckbox = (name: string, value: string, label: string) => {
        return <FormControlLabel name={name} value={value} onChange={handelCheckbox} className={classes.tick} control={<Checkbox />} label={label} />
    }

    const generateTextField = (name: string, label: string) => {
        return <TextField
            label={label}
            name={name}
            InputProps={{
                startAdornment: <InputAdornment position="start">Rp.</InputAdornment>,
            }}
            onChange={handleChangePrice}
            value={state[name] ? convertNumber(`${state[name]}`) : ''}
            fullWidth
        />
    }

    return (
        <Root>
            <Grid container>
                <LoadingScreen open={loadScreen} fullScreen />
                <Grid item xs={7} className={classes.inputBroadcast}>
                    <Grid container>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <AsyncAutoComplete
                                url={process.env.REACT_APP_API_URL + '/autocomplete/broadcast-template'}
                                label="Template"
                                name="template_fetch_id"
                                onChange={handleChangeTemplate}
                                errorText={errorState.template_id}
                            />
                        </Grid>
                        {/* <Typography variant='h6'>Variabel Mapping</Typography>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <Select
                            defaultValue='1'
                        >
                            <MenuItem value="1">FirstName</MenuItem>
                        </Select>
                    </Grid> */}
                        <Typography variant='h6'>Date Leads</Typography>
                        <Grid item xs={12} sx={{ mb: 2 }} className={classes.datePicker}>
                            <DateRangePicker
                                startDate={dateFilter.date_start}
                                endDate={dateFilter.date_end}
                                onDatesChange={handleDateChanged}
                                onFinished={() => {
                                    return dateFilter
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ my: 2 }}>
                            <AsyncAutoComplete
                                iconSearch
                                url={process.env.REACT_APP_API_URL + '/autocomplete/cluster'}
                                name="cluster_ids"
                                label="Cluster"
                                onChange={handleChangeClusters}
                                withoutQuery
                            />
                            <Box sx={{ mt: 1 }} display="flex" gap={1} flexWrap="wrap">
                                {
                                    clusters.length > 0 &&
                                    clusters.map((cluster) => (
                                        <Chip label={cluster.label} onDelete={() => handleDeleteCluster(cluster.id)} />
                                    )
                                    )
                                }
                            </Box>
                        </Grid>
                        <Typography variant='h6'>Average Price</Typography>
                        <Grid item xs={12} sx={{ mb: 2, pt: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    {generateTextField("lowest_price", "Lowest Price")}
                                </Grid>
                                <Grid item xs={6}>
                                    {generateTextField("highest_price", "Highest Price")}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <Grid container display="flex" alignItems="flex-end" sx={{ my: 1 }}>
                                        <Grid item xs={3} display="flex" flexDirection="column">
                                            <Typography sx={{ my: 1 }} fontWeight={700}>Status</Typography>
                                            {generateCustomCheckbox("statuses", "cold", "Cold")}
                                            {generateCustomCheckbox("statuses", "warm", "Warm")}
                                            {generateCustomCheckbox("statuses", "hot", "Hot")}
                                        </Grid>
                                        <Grid item xs={4} display="flex" flexDirection="column">
                                            {generateCustomCheckbox("statuses", "showing", "Showing")}
                                            {generateCustomCheckbox("statuses", "booking_fee", "Booking Fee")}
                                            {generateCustomCheckbox("statuses", "closing", "Closing")}
                                        </Grid>
                                        <Grid item xs={4} display="flex" flexDirection="column">
                                            <Typography sx={{ my: 1 }} fontWeight={700}>Tipe Properti</Typography>
                                            {generateCustomCheckbox("categories", "residential", "Residential")}
                                            {generateCustomCheckbox("categories", "apartment", "Apartment")}
                                            {generateCustomCheckbox("categories", "shophouse", "Shop House")}
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </Grid>
                        </Grid>
                        {template.media_type &&
                            <Grid item xs={12}>
                                <Grid container className={classes.attachment} sx={{ p: 2 }}>
                                    <Typography variant='h6'>Attachment</Typography>
                                    <Typography variant='subtitle2'>Browse (Photo, Document, or Video) to attach, and send as a broadcast.</Typography>
                                    <Grid item sx={{ my: 1 }}>
                                        <Button
                                            startIcon={<AttachFileIcon />}
                                            variant="outlined"
                                            sx={{ background: 'white' }}
                                            component="label"
                                        >
                                            Upload File
                                            <input
                                                type="file"
                                                accept={`${template.media_type}/*`}
                                                onChange={handleChangeFile}
                                                hidden
                                            />
                                        </Button>
                                    </Grid>
                                    <span>
                                        <i>{media.file ? `${media.file.name} (${formatBytes(media.file.size)})` : null}</i>
                                    </span>
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={12} sx={{ mb: 2, pt: 1 }}>
                            <Card variant='outlined'>
                                <CardHeader
                                    sx={{ py: 1 }}
                                    title={<Typography variant='h6'>Exclude</Typography>}
                                    action={<Checkbox checked={exclude}
                                        onChange={() => setExclude(!exclude)}
                                    />}

                                />
                                {exclude && (
                                    <>
                                        <Divider />
                                        <CardContent
                                            className={classes.cardContents}
                                        >
                                            <Grid container>
                                                <Grid item xs={12} sx={{ mb: 2 }}>
                                                    <AsyncAutoComplete
                                                        iconSearch
                                                        url={process.env.REACT_APP_API_URL + '/autocomplete/cluster'}
                                                        name="excluded_cluster_ids"
                                                        label="Cluster"
                                                        onChange={handleChangeExcludedClusters}
                                                        withoutQuery
                                                    />
                                                    <Box sx={{ mt: 1 }} display="flex" gap={1} flexWrap="wrap">
                                                        {
                                                            excludedClusters &&
                                                            excludedClusters.length > 0 &&
                                                            excludedClusters.map((cluster) => (
                                                                <Chip
                                                                    color='warning'
                                                                    label={cluster.label}
                                                                    onDelete={() => handleDeleteExtendedCluster(cluster.id)}
                                                                />
                                                            ))
                                                        }
                                                    </Box>
                                                </Grid>
                                                <Typography fontWeight={700}>Average Price</Typography>
                                                <Grid item xs={12} sx={{ mb: 2, pt: 1 }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            {generateTextField("excluded_lowest_price", "Lowest Price")}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {generateTextField("excluded_highest_price", "Highest Price")}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        onChange={handleIsIgnore}
                                                        className={classes.tick}
                                                        checked={!!state.is_ignore_recent_broadcasted_users}
                                                        name="is_ignore_recent_broadcasted_users"
                                                        control={<Checkbox />}
                                                        label="Exclude people who already received any broadcast in past 7 days"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        onChange={handleIsIgnore}
                                                        className={classes.tick}
                                                        defaultChecked={!!state.is_exclude_30_days_same_broadcast}
                                                        checked={!!state.is_exclude_30_days_same_broadcast}
                                                        name="is_exclude_30_days_same_broadcast"
                                                        control={<Checkbox />}
                                                        label="Exclude people who already received the same broadcast in past 30 days."
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </>
                                )
                                }
                            </Card>
                        </Grid>
                        {
                            openConfirmation ?
                                <PromptModal
                                    open={openConfirmation}
                                    leads={+leads}
                                    onClose={() => setOpenConfirmation(false)}
                                    onSubmit={handleSubmit} /> :
                                null
                        }
                        <Grid item xs={12} sx={{ mb: 1 }}>
                            <Grid display="flex">

                                <Button
                                    startIcon={<SendIcon />}
                                    variant="contained"
                                    onClick={handleSendBroadcast}
                                >
                                    Send Broadcast
                                </Button>

                                <Button
                                    sx={{ ml: "auto" }}
                                    startIcon={<ExitToAppIcon />}
                                    variant="contained"
                                    onClick={handleExport}
                                >
                                    Export
                                </Button>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5}>
                    <Grid container className={classes.previewBroadcast}>
                        <Grid item xs={12} className={classes.whatsappPreview}>
                            <Typography variant='h6' sx={{ mb: 1 }}>Message Preview</Typography>
                            <WhatsappPreview
                                preview={media && media.preview as string}
                                loading={loading}
                                data={template}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Root>
    )
}

export default FormBroadcast