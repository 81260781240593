// default
import React, { useState } from 'react'
import { Button, IconButton, Link, Tooltip } from '@mui/material'

// components
import DataTable from '../../_components/_dataTable/DataTable';

// utils
import DefaultAxios from '../../_utils/DefaultAxios';

// icons
import AddIcon from '@mui/icons-material/Add';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { generateStyle } from '../../_utils/DefaultStyles';

const useStyles = generateStyle(theme => ({
  actionContainer: {
    display: 'flex'
  },
  marginBottom: {
    marginBottom: "1rem"
  }
}), "BankList")


const BankList = () => {
  const API_URL = process.env.REACT_APP_API_URL + '/kpr-interest';
  const { Root, classes } = useStyles()
  const navigate = useNavigate()
  const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

  const handleSort = (id: number, dir: "up" | "down") => {
    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/kpr-interest/sort`, { id: id, dir: dir })
      .then(res => {
        setReDrawDatatable(new Date().getTime());
      })
      .catch(err => {
        console.log(err);
      });
  }

  const columns = [
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      filterable: true,
      sortable: true,
      render: (data: any, row: any) => {
        return (
          <Link
            component={RouterLink}
            to={`/bank-interest/edit/${row.id}`}
          >
            {data}
          </Link>
        )
      }
    },
    {
      name: 'EXTRA',
      label: 'Action',
      type: 'string',
      filterable: false,
      sortable: false,
      render: (row: any) => {
        return <div className={classes.actionContainer}>
          <Tooltip title="Send Up">
            <IconButton
              disabled={row.sort === 1}
              color="primary"
              component="span"
              onClick={() => handleSort(row.id, "up")}
            >
              <ArrowCircleUpIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Send Down">
            <IconButton
              color="primary"
              component="span"
              onClick={() => handleSort(row.id, "down")}
            >
              <ArrowCircleDownIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      }
    }
  ];

  return (
    <Root>
      <Button
        variant="contained"
        size="small"
        startIcon={<AddIcon />}
        className={classes.marginBottom}
        onClick={() => navigate('/bank-interest/add')}
      >
        Add Bank
      </Button>

      <DataTable
        url={API_URL}
        columns={columns}
        reDraw={reDrawDatatable}
      />
    </Root>
  )
}

export default BankList