import React, { useState } from 'react';
import {
    Chip,
    IconButton,
} from '@mui/material';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';
import WhatsappPreviewModal, { ChatsData, INITIAL_CHATS_DATA } from '../_lead/_components/WhatsappPreviewModal';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderErrorButton } from '../../_utils/Helper';
import { format } from 'date-fns';

/**
 * Icons
 */
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


export interface IOption {
    key: string;
    value: string;
}

const CSChatsList = () => {
    const API_URL = `${process.env.REACT_APP_API_URL}/customer-service-chat`;
    const [chatsData, setChatsData] = useState<ChatsData>(INITIAL_CHATS_DATA)
    const [isLoading, setIsLoading] = useState(false);

    const columns = [
        {
            name: 'datetime',
            label: 'Datetime',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return data ? format(new Date(data), 'dd MMMM yyyy HH:mm') : '-'
            }
        },
        {
            name: 'phone',
            label: 'Phone',
            type: 'string',
            filterable: true,
            render: (data: any) => {
                return data || '-'
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            render: (row: any) => {
                return (
                    <IconButton aria-label="preview chat" title='Preview Chat' onClick={() => handleWhatsappPreview(row.phone)} color='primary'>
                        <WhatsAppIcon />
                    </IconButton>
                )
            }
        },
        {
            name: 'response_time_score',
            label: 'Response Time Score',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All',
                },
                {
                    key: 0,
                    value: 'Bad  (<60)',
                },
                {
                    key: 1,
                    value: 'OK  (60-69)',
                },
                {
                    key: 2,
                    value: 'Good  (>=70)',
                },
            ],
            filterable: true,
            render: (data: any) => {
                if (data === null) return '-'

                let color: "primary" | "success" | "warning" | "error" = "primary";

                if (data >= 70) {
                    color = "success";
                } else if (data >= 60) {
                    color = "warning";
                } else {
                    color = "error";
                }

                return <Chip
                    color={color}
                    label={data}
                />;
            }
        },
    ];

    const handleWhatsappPreview = (phone: number) => {
        setIsLoading(true)

        let params = window.location.search

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/customer-service-chat/getwa${params || ''}`, { params: { wa_phone: phone } })
            .then(res => res.data)
            .then(data => {
                if (data.data.length === 0) {
                    renderErrorButton('Tidak ada Chat untuk percakapan ini')
                    setChatsData(INITIAL_CHATS_DATA)
                } else {
                    setChatsData({
                        chats: data.data,
                        code: data.code,
                        created_at: data.created_at,
                        next_id: data.next_id,
                        prev_id: data.prev_id,
                    })
                }
            })
            .catch(err => generalErrorHandler(err))
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            <WhatsappPreviewModal
                open={!!chatsData.chats.length}
                chats={chatsData.chats}
                code={chatsData.code}
                created_at={chatsData.created_at}
                onClose={() => {
                    setChatsData(INITIAL_CHATS_DATA)
                }}
                pagination={{
                    hasNext: chatsData.next_id !== null,
                    hasPrev: chatsData.prev_id !== null,
                }}
                onNextPressed={() => {
                    if (chatsData.next_id !== null) handleWhatsappPreview(chatsData.next_id)
                }}
                onPrevPressed={() => {
                    if (chatsData.prev_id !== null) handleWhatsappPreview(chatsData.prev_id)
                }}
            />

            <LoadingScreen open={isLoading} fullScreen />

            <DataTable
                url={API_URL}
                columns={columns}
                tableContainerMaxHeight='calc(100vh - 230px)'
            />
        </>
    );
}

export default CSChatsList;