import React, { useState, useEffect } from 'react';
import { Grid, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, MenuItem, Toolbar, IconButton, Typography, AppBar, Theme } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import ClusterCategoryTable from './ClusterCategoryTable';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import helpers from '../../_utils/Helpers';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import { renderWarningButton } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyles';

export interface IListing {
    id: string;
    name: string;
}

export interface ICategory {
    id: string;
    name: string;
    listings: IListing[];
}

const useStyles = generateStyle((theme: Theme) => ({
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    gridContainer: {
        padding: '25px'
    }
}), "ClusterCategory_List"
)

const ClusterCategoryList = () => {
    const params: any = useParams();
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL + '/cluster/' + params.id + '/category';
    const { Root, classes } = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
    const [addListingModalOpen, setAddListingModalOpen] = useState(false);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [addCategoryState, setAddCategoryState] = useState('');
    const [addListingState, setAddListingState] = useState('');
    const [errorAddCategoryState, setErrorAddCategoryState] = useState('');
    const [errorAddListingState, setErrorAddListingState] = useState('');
    const [availableListings, setAvailableListings] = useState<IListing[]>([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        setIsLoading(true);
        DefaultAxios
            .get(API_URL)
            .then(res => {
                const categories = res.data.categories;
                for (const [key, value] of Object.entries<any>(categories)) {
                    categories[key]['listings'] = value.listings ? JSON.parse(value.listings) : [];
                }
                setCategories(categories);
                setAvailableListings(res.data.listings);
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const FormToolbar = () => {
        return (
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleBack} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Cluster Category
                </Typography>
            </Toolbar>
        )
    }

    const handleBack = () => {
        navigate(`/cluster/detail/${params.id}`);
    }

    const handleAddCategory = () => {
        setAddCategoryModalOpen(true);
    };

    const handleClose = () => {
        setAddCategoryState('');
        setAddListingState('');
        setErrorAddCategoryState('');
        setErrorAddListingState('');
        setAddCategoryModalOpen(false);
        setAddListingModalOpen(false);
    };

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'category') {
            setAddCategoryState(e.target.value);
        } else {
            setAddListingState(e.target.value);
        }
    }

    const handleListingModal = (categoryId: string) => {
        setSelectedCategory(categoryId);
        setAddListingModalOpen(true);
    }

    const handleDeleteListing = (categoryId: string, listingId: string) => {
        renderWarningButton("Apakah anda yakin ingin menghapus listing ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(API_URL + '/delete-listing', { categoryId, listingId })
                        .then((res) => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000
                            })
                                .then(() => {
                                    const oldCategories = categories.slice();

                                    const categoryKey = oldCategories.findIndex(category => {
                                        return category.id === categoryId;
                                    });

                                    oldCategories[categoryKey]['listings'] = res.data;
                                    setCategories(oldCategories);
                                })
                        })
                        .catch(error => {
                            helpers.generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleSortCategory = (categoryId: string, dir: 'up' | 'down', key: number) => {
        setIsLoading(true);
        DefaultAxios
            .post(API_URL + '/sort-category', { categoryId, dir })
            .then(res => {
                const oldIndex = key;
                const newIndex = dir === 'up' ? key - 1 : key + 1;

                const oldCategories = categories.slice();

                const tmp = { ...oldCategories[newIndex] };
                oldCategories[newIndex] = { ...oldCategories[oldIndex] };
                oldCategories[oldIndex] = { ...tmp };

                setCategories(oldCategories);
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleSortListing = (categoryId: string, listingId: string, dir: 'up' | 'down', key: number) => {
        setIsLoading(true);
        DefaultAxios
            .post(API_URL + '/sort-listing', { categoryId, listingId, dir })
            .then(res => {
                const oldIndex = key;
                const newIndex = dir === 'up' ? key - 1 : key + 1;

                const oldCategories = categories.slice();
                const categoryIndex = oldCategories.findIndex(item => item.id === categoryId);

                if (categoryIndex > -1) {
                    const category = oldCategories[categoryIndex];
                    const oldListings = category.listings.slice();

                    const tmp = { ...oldListings[newIndex] };
                    oldListings[newIndex] = { ...oldListings[oldIndex] };
                    oldListings[oldIndex] = { ...tmp };

                    oldCategories[categoryIndex]['listings'] = oldListings;
                    setCategories(oldCategories);
                }
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleEditCategory = (categoryId: string, name: string) => {
        setIsLoading(true);
        DefaultAxios
            .put(API_URL + '/' + categoryId, { name })
            .then(res => {
                const oldCategories = categories.slice();

                const categoryKey = oldCategories.findIndex(category => {
                    return category.id === categoryId;
                });

                oldCategories[categoryKey]['name'] = name;
                setCategories(oldCategories);
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleDeleteCategory = (categoryId: string) => {
        renderWarningButton("Apakah anda yakin ingin menghapus category ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .delete(API_URL + '/' + categoryId)
                        .then((res) => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000
                            })
                                .then(() => {
                                    const oldCategories = categories.slice();

                                    const categoryKey = oldCategories.findIndex(category => {
                                        return category.id === categoryId;
                                    });

                                    oldCategories.splice(categoryKey, 1);
                                    setCategories(oldCategories);
                                })
                        })
                        .catch(error => {
                            helpers.generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const renderCategoryTable = () => {
        return categories.map((category, key) => {
            return <Grid item xs={12} key={category.id}>
                <ClusterCategoryTable
                    category={category}
                    onAddListingModalOpen={handleListingModal}
                    onDeleteListing={handleDeleteListing}
                    isTop={key === 0}
                    isBottom={key === categories.length - 1}
                    onSortCategory={(categoryId: string, dir: 'up' | 'down') => handleSortCategory(categoryId, dir, key)}
                    onEditCategory={handleEditCategory}
                    onDeleteCategory={handleDeleteCategory}
                    onSortListing={(categoryId: string, listingId: string, dir: 'up' | 'down', listingKey: number) => handleSortListing(categoryId, listingId, dir, listingKey)}
                />
            </Grid>
        });
    }

    const handleSubmitCategory = () => {
        if (!addCategoryState) {
            setErrorAddCategoryState('Nama Category harus diisi');
            return;
        } else {
            setErrorAddCategoryState('');
        }

        setIsLoading(true);
        DefaultAxios
            .post(API_URL, { name: addCategoryState })
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        const oldCategories = categories.slice();

                        oldCategories.push({
                            id: res.data,
                            name: addCategoryState,
                            listings: []
                        });

                        setCategories(oldCategories);
                        handleClose();
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleSubmitListing = () => {
        if (!addListingState) {
            setErrorAddListingState('Nama Listing harus diisi');
            return;
        } else {
            setErrorAddListingState('');
        }

        DefaultAxios
            .post(API_URL + '/submit-listing', { categoryId: selectedCategory, listingId: addListingState })
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        const oldCategories = categories.slice();

                        const categoryKey = oldCategories.findIndex(category => {
                            return category.id === selectedCategory;
                        });

                        oldCategories[categoryKey]['listings'].push(res.data);
                        setCategories(oldCategories);
                        handleClose();
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Dialog
            fullScreen
            open={true}
        >
            <Root>
                <LoadingScreen open={isLoading} fullScreen />
                <AppBar className={classes.appBar}>
                    <FormToolbar></FormToolbar>
                </AppBar>
                <Dialog
                    fullWidth
                    open={addCategoryModalOpen}
                    onClose={handleClose}
                    maxWidth="sm"
                >
                    <DialogTitle id="form-dialog-title">Add Category</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            fullWidth
                            variant="outlined"
                            label="Nama Category"
                            name="category"
                            value={addCategoryState}
                            onChange={handleChanged}
                            error={!!errorAddCategoryState}
                            helperText={errorAddCategoryState}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmitCategory} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth
                    open={addListingModalOpen}
                    onClose={handleClose}
                    maxWidth="sm"
                >
                    <DialogTitle id="form-dialog-title">Add Listing</DialogTitle>
                    <DialogContent>
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            label="Nama Listing"
                            name="listing"
                            value={addListingState}
                            onChange={handleChanged}
                            error={!!errorAddListingState}
                            helperText={errorAddListingState}
                        >
                            {availableListings.map(listing => {
                                return <MenuItem key={listing.id} value={listing.id}>{listing.name}</MenuItem>
                            })}
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmitListing} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12} style={{ paddingLeft: 0 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddCategory}
                        >
                            Add Category
                        </Button>
                    </Grid>
                    {renderCategoryTable()}
                </Grid>
            </Root>
        </Dialog>
    );
}

export default ClusterCategoryList;