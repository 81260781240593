import React, { Fragment, useState } from 'react';
import { Button, Switch, Link } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';

// Icons
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import helpers from '../../_utils/Helpers';
import { renderWarningButton } from '../../_utils/Helper';

const KotaMandiri = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/kota-mandiri';
    // const classes = useStyles();
    const navigate = useNavigate();

    const columns = [
        {
            name: 'name',
            label: 'Nama',
            type: 'string',
            filterable: true,
            render: (data: any, row: any) => {
                return (
                    <>
                        <Link
                            component={RouterLink}
                            to={`/mandiri/edit/${row.id}`}
                        >
                            {data}
                        </Link>
                    </>
                );
            }
        },
        {
            name: 'status',
            label: 'Is Published',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return (
                    <Switch
                        checked={data === 1}
                        onChange={() => handlePublishSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                )
            }
        },
    ];

    const [isLoading, setIsLoading] = useState(false);
    // const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const handlePublishSwitch = (area: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status ini?")
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                DefaultAxios
                    .post(`${API_URL}/toggle-publish`, { id: area.id })
                    .then(res => {
                        const checked = res.data;
                        const newRows = rows.map((element: any) => {
                            if (+element.id === +area.id) element['status'] = checked ? 1 : 0
                            return element
                        })
                        setRows(newRows);
                    })
                    .catch(error => {
                        helpers.generalErrorHandler(error)
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }
        })
    }

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            <Button
                variant="contained"
                color="primary"
                component="label"
                style={{ marginBottom: 24 }}
                onClick={() => navigate(`/mandiri/add`)}
            >
                Add Data
            </Button>
            <DataTable
                url={API_URL}
                // reDraw={reDrawDatatable}
                columns={columns}
            />
        </Fragment>
    );
}

export default KotaMandiri;