import React from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField,
    // MenuItem 
} from '@mui/material';

/**
 * Components
 */
import { IState, IErrorState } from './DeveloperList';

interface Props {
    open: boolean;
    state: IState;
    errorState: IErrorState;
    onClose: () => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onSubmit: () => void;
}

const DeveloperForm = ({ open, state, errorState, onClose, onChange, onSubmit }: Props) => {
    // const statuses = [
    //     { key: "warm", value: "Response Belum Ada Rencana Showing"},
    //     { key: "hot", value: "Response dan Ada Rencana Showing"},
    //     { key: "cold", value: "No Response / Tidak Relevan"},
    // ];

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>Developer Form</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ paddingTop: 8 }}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Nama Developer"
                            name="name"
                            value={state.name}
                            onChange={onChange}
                            error={!!errorState.name}
                            helperText={errorState.name}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeveloperForm;