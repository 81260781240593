import React, { CSSProperties, useEffect, useState, useContext, Key } from 'react'
import { Grid, Typography, Box, Button, TextField, MenuItem, useMediaQuery, Theme, useTheme, Tooltip } from '@mui/material';

/* 
* Components
*/
import DataTable from '../../_components/_dataTable/DataTable';
import moment from 'moment';

/* 
* Icons and Style
*/
import DuplicateIcon from '../../Assets/Images/svg/duplicate-leads-icon.svg';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
/* 
* Utils
*/
import DefaultAxios from '../../_utils/DefaultAxios';
import { leadMts } from '../../_utils/ConstData';
import { generalErrorHandler, renderMTS } from '../../_utils/Helper';
import { numberToCurrency } from '../../_utils/Helpers';
import { ChevronLeft } from '@mui/icons-material'
import { inputAgentMtsWithUndefined } from '../../_utils/ConstData';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyles';

const useStyle = generateStyle(theme => ({
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    table: {
        borderRadius: '15px',
        border: '1px solid #989898',
        width: '100%',
        overflow: 'hidden'
    },
    tableMobile: {
        width: '100%',
        // border: '1px solid #989898',
        backgroundColor: '#fff',
        overflow: 'hidden',
    },
    buttonDetail: {
        textTransform: 'initial',
        '&& svg': {
            transform: 'scaleX(-1)'
        }
    },
    breakdownWrapper: {
        overflow: 'auto',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            height: 5,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: '10px',
        },

        '&::-webkit-scrollbar-thumb': {
            background: '#C2C2C2',
            borderRadius: '10px',
        }
    },
    breakdown: {
        marginRight: 24,
        marginTop: 12,
        marginBottom: 16,
        display: 'inline-block',
        textAlign: 'center',
        textDecoration: 'none',
    },
    score: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 50,
        height: 50,
        textAlign: 'center'
    },
    seeDetail: {
        borderStyle: 'solid',
        borderWidth: '0px 0px 1px 0px',
        borderColor: '#C2C2C2'
    },
    container: {
        boxSizing: 'border-box',
        width: 'calc(100% + 16px)',
        marginLeft: '-8px'
    }
}), "NewDashBoard_List")

/* 
* Interfaces 
*/

interface IColumn {
    filterable?: boolean;
    sortable?: boolean
    name: string;
    label: string | React.ReactElement;
    type?: string;
    render?: Function;
    style?: CSSProperties;
    options?: any[] | Object;
    defaultOption?: string;
}

interface ICustomTableProps {
    url: string
    customParams?: Object
    columns: IColumn[]
    type: string
    customData?: any[]
    resData?: boolean
    noDetails?: boolean
}

const NewDashboardList = () => {
    const permissions = useContext(PermissionContext)
    const theme: Theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const closingColumn = [
        {
            name: 'cluster_name', label: 'Cluster',
            style: { position: isMobile ? 'sticky' : undefined, left: 0, backgroundColor: '#fff', zIndex: 99, boxShadow: isMobile ? '3px 0px 10px -2px rgba(0, 0, 0, 0.3)' : '' } as React.CSSProperties,
            columnStyle: { position: isMobile ? 'sticky' : undefined, left: 0, backgroundColor: '#fff', zIndex: 99, boxShadow: isMobile ? '3px 0px 10px -2px rgba(0, 0, 0, 0.3)' : '' } as React.CSSProperties,
        },
        { name: 'transaction_amount', label: 'Value', render: (data: number) => `Rp ${numberToCurrency(data)}` },
        { name: 'agent_name_full', label: 'Agent', },
        {
            name: 'mts', label: 'MTS', render: (data: any, row: any) => {
                let label = "-";

                if (data) {
                    for (let index = 0; index < leadMts.length; index++) {
                        if (leadMts[index].key === data) {
                            label = leadMts[index].value;
                            break;
                        }
                    }
                }

                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {label}
                        {row.duplicate_type &&
                            <Tooltip title="Leads ini Duplikat">
                                <img src={DuplicateIcon} alt="warning" style={{ width: '18px', marginLeft: "5px" }} />
                            </Tooltip>
                        }
                    </div>
                )
            }
        },
        {
            name: 'adsetname', label: 'Adset', render: (data: any, row: any) => {
                return (
                    <span>{data ? data : '-'}</span>
                )
            }
        },
        { name: 'lead_cluster_name', label: '1St Cluster Ask', style: { minWidth: isMobile ? '160px' : undefined } },
    ]

    const agentColumn = [
        { name: 'full_name', label: 'Agent' },
        {
            name: 'osa_report', label: 'Score',
            columnStyle: { paddingTop: '0px', paddingBottom: '0px' },
            render: (data: any, row: any) => {
                return (
                    <div className={classes.score} style={{ backgroundColor: Number(data) >= 70 ? '#DBF7E2' : '#F7D7CE' }}><span>{data}</span></div>
                )
            }
        },
        { name: 'leads_count', label: 'Leads', },
    ]

    const clusterColumn = [
        {
            name: 'name', label: 'Cluster',
            render: (data: any, row: any) => {
                return (
                    permissions['report.filter-group'] ?
                        <span onClick={() => handle1stChild(row.id, row.name)} style={{ cursor: 'pointer', color: 'rgb(0, 86, 168)' }}>{data}</span>
                        : data
                )
            }
        },
        { name: 'leads_count', label: 'Leads', },
        {
            name: 'score', label: 'Score', columnStyle: { paddingTop: '0px', paddingBottom: '0px' },
            render: (data: any, row: any) => {
                return (
                    <div className={classes.score} style={{ backgroundColor: Number(data) >= 70 ? '#DBF7E2' : '#F7D7CE' }}><span>{data}</span></div>
                )
            }
        },
    ]

    const cluster1stChildColumn = [
        {
            name: 'mts', label: 'Source (MTS)',
            render: (data: any, row: any) => {
                return (
                    <span onClick={() => handle2ndChild(row.id, row.mts)} style={{ cursor: 'pointer', color: 'rgb(0, 86, 168)' }}>{renderMTS(data)}</span>
                )
            }
        },
        { name: 'leads_count', label: 'Lead', },
        {
            name: 'score', label: 'Score', columnStyle: { paddingTop: '0px', paddingBottom: '0px' },
            render: (data: any, row: any) => {
                return (
                    <div className={classes.score} style={{ backgroundColor: Number(data) >= 70 ? '#DBF7E2' : '#F7D7CE' }}><span>{data}</span></div>
                )
            }
        },
    ]

    const cluster2ndChildColumn = [
        { name: 'adsetname', label: 'Adset', render: (data: any, row: any) => data ? data : "[Empty]" },
        { name: 'leads_count', label: 'Lead', },
        {
            name: 'score', label: 'Score', columnStyle: { paddingTop: '0px', paddingBottom: '0px' },
            render: (data: any, row: any) => {
                return (
                    <div className={classes.score} style={{ backgroundColor: Number(data) >= 70 ? '#DBF7E2' : '#F7D7CE' }}><span>{data}</span></div>
                )
            }
        },
    ]

    const [groupId, setGroupId] = useState('default');
    const [groupName, setGroupName] = useState('Tangerang')
    const [groups, setGroups] = useState<{ id: number, name: string }[]>([]);
    const [page, setPage] = useState(1)

    const [closingData, setClosingData] = useState<any[]>([])
    const [agentData, setAgentData] = useState<any[]>([])
    const [clusterData, setClusterData] = useState<any[]>([])

    const [mts, setMts] = useState('')
    const [mtsLabel, setMtsLabel] = useState('')

    const { Root, classes } = useStyle()

    const [selectedCluster, setSelectedCluster] = useState({
        id: '',
        name: ''
    })

    const [closingBreakdown, setClosingBreakdown] = useState({
        'instagram': 0,
        'seo': 0,
        'google_ads': 0,
        'facebook_ads': 0,
        'fb-leads': 0,
        'facebook-dm': 0,
        'whatsapp': 0,
        'input-agent': 0,
        'direct': 0,
        'call': 0,
        'tiktok': 0,
        'reference_agent': 0,
        'reference_cp': 0,
        'others': 0,
        'undefined': 0
    })

    const handle1stChild = (id: string, name: string) => {
        setSelectedCluster({
            id: id,
            name: name
        })
        setPage(2)
    }

    const handle2ndChild = (id: string, name: string) => {
        setMts(name)
        setMtsLabel(renderMTS(name))
        setPage(3)
    }

    const CustomTable = ({ url, customParams, columns, type, customData, resData, noDetails }: ICustomTableProps) => {
        return (
            <Box className={isMobile ? classes.tableMobile : classes.table}>
                <DataTable
                    url={process.env.REACT_APP_API_URL + url}
                    customParams={customParams}
                    columns={columns}
                    isHeaderSticky={false}
                    tableContainerMaxHeight="auto"
                    customData={isMobile ? customData?.slice(0, 10) : customData}
                    noPagination
                    resData={resData ? resData : false}
                />
                {
                    (!noDetails && !isMobile) &&
                    <Box className={isMobile ? classes.seeDetail : ''} sx={{ p: 2, textAlign: isMobile ? 'center' : 'left' }}>
                        <Button
                            className={classes.buttonDetail}
                            startIcon={<ReplyIcon />}
                            onClick={() => handleSeeDetail(type)}
                        >
                            See Details
                        </Button>
                    </Box>
                }
            </Box>
        )
    }

    const handleSeeDetail = (type: string) => {
        if (type === 'closing') {
            window.open(`/closing?is_dm=1&group_name=${groupName}&created_at=${moment().subtract(30, 'days').format('DD/MM/YYYY')} - ${moment().subtract(1, 'days').format('DD/MM/YYYY')}`, "_blank");
        } else if (type === 'agent') {
            window.open(`/leads-report/${groupId === 'default' ? '0' : groupId}`, "_blank");
        }
    }

    const fetchGroups = () => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/lead-group/all`)
            .then(res => res.data)
            .then(data => {
                setGroups(data)
            })
            .catch(generalErrorHandler)
    }

    const fetchData = () => {
        const data = {
            group_id: groupId !== 'default' ? Number(groupId) : 0
        }
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/superadmin-dashboard`, { params: data })
            .then(res => {
                setClosingData(res.data.closings)
                setAgentData(res.data.agents)
                setClusterData(res.data.clusters)
                setClosingBreakdown(res.data.closing_breakdown)
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                // setIsLoading(false);
            })
    }

    useEffect(() => {
        fetchGroups()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [groupId])

    const renderIcon = (type: string) => {
        if (type === 'agent') {
            return (
                <PermIdentityOutlinedIcon fontSize={isMobile ? 'medium' : 'large'} />
            )
        } else if (type === 'cluster') {
            return (
                <AssignmentTurnedInOutlinedIcon fontSize={isMobile ? 'medium' : 'large'} />
            )
        } else {
            return (
                <ChevronLeft onClick={() => setPage(type === 'cluster-1st-child' ? 1 : 2)} fontSize={isMobile ? 'medium' : 'large'} style={{ cursor: 'pointer' }} />
            )
        }
    }

    const renderLabel = (type: string) => {
        var labelText = ''
        if (type === 'agent') {
            labelText = "Agent Score"
        } else if (type === 'cluster') {
            labelText = "Client Score"
        } else if (type === 'cluster-1st-child') {
            labelText = `Leads Score > ${selectedCluster.name}`
        } else {
            labelText = `Leads Score > ${selectedCluster.name} > ${mtsLabel}`
        }
        return (labelText)
    }

    const renderTable = (url: string, columns: any[], type: string, customData: any[] | undefined, noDetails?: boolean, resData?: boolean) => {
        return (
            <Grid item xs={12} md={6}>
                <Grid container alignItems='flex-end' sx={{ px: isMobile ? '8px' : undefined }}>
                    <Grid item xs={type !== 'cluster-1st-child' && type !== 'cluster-2nd-child' ? 6 : 12} className={classes.title}>
                        {renderIcon(type)}
                        <Typography variant={type !== 'agent' && type !== 'cluster' && isMobile ? 'subtitle2' : 'h5'} sx={{ ml: 1 }}>{renderLabel(type)}</Typography>
                    </Grid>
                    {
                        type !== 'cluster-1st-child' && type !== 'cluster-2nd-child' &&
                        <Grid item xs={6} sx={{ m: 'auto' }} textAlign="right">Last 7 Days</Grid>
                    }
                </Grid>
                <Grid container sx={{ mt: 2, mb: 3 }}>
                    <CustomTable
                        url={url}
                        columns={columns}
                        type={type}
                        customData={customData}
                        noDetails={noDetails || false}
                        resData={resData || false}
                    />
                </Grid>
            </Grid>
        )
    }

    const renderBreakdown = (value: number, label: string) => {
        return (
            <span key={label as Key} className={classes.breakdown}>{label}: <strong>{value || 0}%</strong></span>
        )
    }

    useEffect(() => {
        if (isMobile) {
            document.body.style.backgroundColor = '#F7F7F7';
        }
    }, [isMobile])

    return (
        <Root>
            <Grid container className={isMobile ? classes.container : ''}>
                {
                    page === 1 ?
                        <>
                            {permissions['report.filter-group'] ?
                                isMobile ?
                                    <Grid item xs={12} sx={{ mt: 2, px: isMobile ? '8px' : undefined }}>
                                        <TextField
                                            select
                                            variant="outlined"
                                            label="Group"
                                            name="group_id"
                                            value={groupId}
                                            onChange={(e) => {
                                                setGroupId(e.target.value)
                                            }}
                                            fullWidth
                                            style={{ backgroundColor: '#fff' }}
                                        >
                                            <MenuItem value='default'>All</MenuItem>
                                            {groups.map(group => <MenuItem key={group.id.toString()} value={group.id.toString()}><span onClick={() => setGroupName(group.name)}>{group.name}</span></MenuItem>)}
                                        </TextField>
                                    </Grid>
                                    :
                                    <div style={{ marginLeft: 'auto', minWidth: 100 }}>
                                        <TextField
                                            select
                                            variant="outlined"
                                            label="Group"
                                            name="group_id"
                                            value={groupId}
                                            onChange={(e) => {
                                                setGroupId(e.target.value)
                                            }}
                                            fullWidth
                                        >
                                            <MenuItem value='default'>All</MenuItem>
                                            {groups.map(group => <MenuItem key={group.id.toString()} value={group.id.toString()}><span onClick={() => setGroupName(group.name)}>{group.name}</span></MenuItem>)}
                                        </TextField>
                                    </div>
                                :
                                ""
                            }

                            <Grid container sx={{ mt: isMobile ? 4 : 2, px: isMobile ? '8px' : undefined }}>
                                <Grid item xs={6} className={classes.title}>
                                    <AssignmentTurnedInOutlinedIcon fontSize={isMobile ? 'medium' : 'large'} />
                                    <Typography variant='h5' sx={{ ml: 1 }}>Closing</Typography>
                                </Grid>
                                <Grid item xs={6} textAlign='right' sx={{ m: 'auto' }}>Last 30 Days</Grid>
                            </Grid>

                            {permissions['report.filter-group'] &&
                                <Grid container style={{ display: 'inline-grid' }} sx={{ backgroundColor: '#fff', mt: isMobile ? 2 : 0, px: isMobile ? '8px' : undefined }}>
                                    <div className={classes.breakdownWrapper}>
                                        {inputAgentMtsWithUndefined.map((data) => {
                                            type Objectkey = keyof typeof closingBreakdown;
                                            const key = data.key as Objectkey;
                                            return renderBreakdown(closingBreakdown[key], data.value)
                                        })}
                                    </div>
                                </Grid>
                            }

                            <Grid container sx={{ mt: 2, mb: 3 }}>
                                <CustomTable
                                    url={`/superadmin-dashboard/closing?group_id=${groupId === 'default' ? '0' : groupId}`}
                                    customParams={{ rowsPerPage: 25 }}
                                    columns={closingColumn}
                                    type='closing'
                                    customData={closingData}
                                />
                            </Grid>
                            <Grid container spacing={isMobile ? 0 : 10}>
                                {renderTable(`/superadmin-dashboard/agent?group_id=${groupId === 'default' ? '0' : groupId}`, agentColumn, 'agent', agentData, false, false)}
                                {renderTable(`/superadmin-dashboard/cluster?group_id=${groupId === 'default' ? '0' : groupId}`, clusterColumn, 'cluster', clusterData, true, false)}
                            </Grid>
                        </>
                        :
                        page === 2 ?
                            renderTable(`/superadmin-dashboard/cluster/${selectedCluster.id}/breakdown`, cluster1stChildColumn, 'cluster-1st-child', undefined, true, true)
                            :
                            renderTable(`/superadmin-dashboard/cluster/${selectedCluster.id}/breakdown/adset?mts=${mts}`, cluster2ndChildColumn, 'cluster-2nd-child', undefined, true, true)

                }

            </Grid >
        </Root>
    )
}

export default NewDashboardList