
import React from 'react';
import {
    AppBar,
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Toolbar,
    Typography,
    Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useNavigate } from 'react-router-dom';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import { generateStyle } from '../../_utils/DefaultStyles';

interface IProps {
    open: boolean
    title: string
    isLoading: boolean
    appBar?: React.ReactElement
    onCloseModal: Function
    showEditButton?: boolean
    editButtonLink?: string | Function
    children: React.ReactElement
}

const useStyles = generateStyle(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        backgroundColor: "#f3f3f3",
    },
}), "Modal_Detail", {
    CustomParent: Dialog
})

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalDetail = ({
    title,
    open,
    isLoading,
    appBar,
    onCloseModal,
    showEditButton,
    editButtonLink,
    children
}: IProps) => {
    const navigate = useNavigate();
    const {Root: SDialog, classes} = useStyles()

    return (
        <SDialog
            fullScreen
            TransitionComponent={Transition}
            open={open}
        >
            {appBar ?
                appBar
                :
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => onCloseModal()} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {title}
                        </Typography>
                        {showEditButton &&
                            (
                                typeof editButtonLink === "string" ?
                                    <Button autoFocus color="inherit" onClick={() => navigate(editButtonLink ? editButtonLink : "")}>
                                        Edit
                                    </Button>
                                    :
                                    typeof editButtonLink === "function" &&
                                        <Button autoFocus color="inherit" onClick={() => editButtonLink()}>
                                            Edit
                                        </Button>
                            )
                        }
                    </Toolbar>
                </AppBar>
            }


            {isLoading ?
                <LoadingScreen open={isLoading} />
                :
                <DialogContent style={{ backgroundColor: "#f5f7fb" }}>
                    <Grid container spacing={3}>
                        {children}
                    </Grid>
                </DialogContent>
            }
        </SDialog>
    );
}

export default ModalDetail;