import React, { useState } from 'react';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import LoadingScreen from '../../_components/LoadingScreen';
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';

const GenerateLeadsTest = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        type: '',
        jumlah: ''
    })

    const onClickGenerate = (url: string) => {
        setIsLoading(true);

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/generate-test/${url}`)
            .then(res => {
                alert('success');
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const onClickGenerateLeads = () => {
        setIsLoading(true);

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/generate-test/leads`, state)
            .then(res => {
                alert('success');
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    return (
        <Grid container spacing={3}>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onClickGenerate('agent')}
                >
                    Generate Agent
                </Button>
            </Grid>
            <Grid item xs={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onClickGenerate('cluster')}
                >
                    Generate Cluster
                </Button>
            </Grid>
            <Grid item xs={4}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onClickGenerate('additional-junior')}
                >
                    Generate Tambahan Junior
                </Button>
            </Grid>
            <Grid item xs={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onClickGenerate('pause-agent')}
                >
                    Pause Agent
                </Button>
            </Grid>
            <Grid item xs={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onClickGenerate('lead-22')}
                >
                    Generate 22 Leads
                </Button>
            </Grid>
            <Grid item xs={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onClickGenerate('reset')}
                >
                    Reset
                </Button>
            </Grid>

            <Grid item xs={4}>
                <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="Level"
                    name="type"
                    value={state.type}
                    onChange={handleChange}
                >
                    <MenuItem value="">Pilih Level</MenuItem>
                    <MenuItem value="easy">Easy</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="hard">Hard</MenuItem>
                </TextField>
            </Grid>

            <Grid item xs={4}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Jumlah"
                    name="jumlah"
                    value={state.jumlah}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClickGenerateLeads}
                >
                    Generate Leads
                </Button>
            </Grid>
        </Grid>
    );
}

export default GenerateLeadsTest;