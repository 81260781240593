import React, { useReducer, useEffect } from 'react';
import { MentionsInput, Mention } from 'react-mentions'

// Utils
import helpers from '../_utils/Helpers';

// Asset
// import defaultPhoto from '../Assets/Images/png/default-photo.png';

// CSS
import './css/mention-style.css';
import { Button } from '@mui/material';
import { generateStyle } from '../_utils/DefaultStyles';

interface Props {
    comments: IComment[];
    users: IUser[],
    onReply: Function;
}

export interface IMentionData {
    id: string;
    display: string;
    index: number;
    plainTextIndex: number;
    type: string;
}

interface IState {
    value: string;
    plainValue: string;
    mentionData: IMentionData[];
}

interface IAction {
    name: string,
    value: any,
    type: string
}

export interface IComment {
    // avatar: string;
    comment: string;
    name: string;
    created_at: string;
}

export interface IUser {
    id: string;
    display: string;
}

const useStyles = generateStyle((theme) => ({
    root: {
        paddingBottom: '20px',
        borderBottom: '1px solid #aaa',
        margin: '10px 0'
    },
    container: {
        display: 'inline-block',
        '& > div': {
            backgroundColor: '#f8f8f8',
            padding: '10px',
            border: '#f2e6e6 solid 2px',
            borderRadius: '5px',
            fontSize: '12px',
            whiteSpace: 'pre-wrap'
        },
        '& > label': {
            fontWeight: '700',
            fontSize: '13px',
            textTransform: 'capitalize'
        },
        '& > span': {
            fontWeight: '400',
            fontSize: '10px',
            color: '#989898',
            marginLeft: '5px'
        }
    }
}), "CommentList")

const CommentList = (props: Props) => {
    const { comments, users, onReply } = props;
    const { Root, classes } = useStyles()

    const initialState = {
        value: '',
        plainValue: '',
        mentionData: []
    };

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    useEffect(() => {
        setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
        // eslint-disable-next-line
    }, [comments]);

    const handleChange = (e: any, newValue: string, newPlainTextValue: string, mentions: IMentionData[]) => {
        const newState = { ...inputState };

        newState.value = newValue;
        newState.plainValue = newPlainTextValue;
        newState.mentionData = mentions;

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleReply = () => {
        if (!inputState.value.trim()) {
            return;
        }

        const mentionData = [];

        for (let data of inputState.mentionData) {
            mentionData.push({
                name: data.display,
                uid: data.id
            });
        }
        onReply(inputState.plainValue, mentionData);
    }

    const renderComments = () => {
        return comments.map((comment, key: number) =>
            <div className={classes.root}>
                {/* <ImageContainer>
                    <img src={comment.avatar ? comment.avatar : defaultPhoto} alt={comment.name} />
                </ImageContainer> */}
                <div className={classes.container}>
                    <label>
                        {comment.name}
                        <span>{helpers.dateFormat(comment.created_at, 'DD MMM YYYY [at] HH mm')}</span>
                    </label>
                    <div dangerouslySetInnerHTML={{ __html: comment.comment }}></div>
                </div>
            </div>
        )
    }

    return (
        <Root>
            <div style={{ width: '100%' }}>
                {renderComments()}
                <div>
                    <MentionsInput
                        value={inputState.value}
                        onChange={handleChange}
                        markup="@{{__type__||__id__||__display__}}"
                        placeholder="Write comment"
                        className="mentions"
                        style={{ backgroundColor: '#fff' }}
                    >
                        <Mention
                            type="user"
                            trigger="@"
                            data={users}
                            className="mentions__mention"
                        />
                    </MentionsInput>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ float: 'right' }}
                        onClick={handleReply}
                    >
                        Reply
                    </Button>
                </div>
            </div>
        </Root>
    );
}

export default CommentList;