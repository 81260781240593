import React, { useState } from 'react'
import {
    Button,
    Grid,
    Paper,
    ButtonGroup
} from '@mui/material'

/**
 * Components
 */
import V1Form from './components/V1Form'
import V2Form from './components/V2Form'
import { renderWarningButton } from '../../../_utils/Helper'

interface IProps {
    id: number
    type: string
    slug: string
}

const slpVersionOptions = [
    { label: 'Version 1', value: 1 },
    { label: 'Version 2', value: 2 },
]

const SLPForm = (props: IProps) => {
    const [slpVersion, setSLPVersion] = useState(1)
    const [hasChanged, setHasChanged] = useState(false)

    return (
        <Grid item xs={12}>
            <Paper style={{ padding: 16, marginRight: 24 }}>
                <Grid container>
                    <Grid item xs={12} md={4} sx={{ mb: 3 }}>
                        <ButtonGroup>
                            {
                                slpVersionOptions.map((version) => (
                                    <Button
                                        key={version.value}
                                        onClick={() => {
                                            if (hasChanged) {
                                                renderWarningButton(`Ada perubahan yang belum disimpan di SLP Versi ${slpVersion}, lanjut pindah form?`)
                                                    .then(res => res.value)
                                                    .then(value => {
                                                        if (value) {
                                                            setSLPVersion(version.value)
                                                        }
                                                    })
                                            } else {
                                                setSLPVersion(version.value)
                                            }
                                        }}
                                        variant={slpVersion === version.value ? 'contained' : 'outlined'}
                                    >
                                        {version.label}
                                    </Button>
                                ))
                            }
                        </ButtonGroup>
                    </Grid>
                </Grid>
                {
                    slpVersion === 1 ?
                        <V1Form {...props} setParentHasChanged={setHasChanged} />
                        :
                        <V2Form {...props} setParentHasChanged={setHasChanged} />
                }
            </Paper>
        </Grid>
    )
}



export default SLPForm
