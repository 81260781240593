import React, { useCallback, useEffect, useState } from 'react'
import { Button, Divider, Grid, IconButton, Paper, TextField, Theme, Box } from '@mui/material'
import Swal from 'sweetalert2'

import LoadingScreen from '../LoadingScreen'

import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, inputNumberTwo } from '../../_utils/Helper'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../_utils/Validation'

import CancelIcon from '@mui/icons-material/Cancel';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { generateStyle } from '../../_utils/DefaultStyles'
import { useTheme } from '@mui/material'

interface IProps {
    id: number
    type: string
}

interface IReviewContent {
    title: string
    rating: string
}

interface IMultipleError {
    [key: number]: IValidationErrors
}

const useStyles = generateStyle(theme => ({
    title: {
        fontSize: 16,
        color: '#484848',
        marginBottom: 12,
        fontWeight: 'bold',
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > .error': {
            color: theme.palette.error.main,
            marginTop: 12,
        }
    },
    line: {
        display: 'block',
        width: '100%',
        height: 1,
        backgroundColor: '#cacaca',
        margin: '16px 0px 16px',
    },
    metaContainer: {
        '& .title': {
            fontSize: 16,
            fontWeight: '500',
        },
        '& .subtitle': {
            fontWeight: '500',
        }
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    submitContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    listContainer: {
        width: '100%',
        '& > *': {
            paddingBottom: 16,
            paddingTop: 16,
            // borderBottom: '2px solid #cacaca',
        },
        '& > *:first-of-type': {
            paddingTop: 0,
        },
        '& > *:last-child': {
            // borderBottom: 'none',
        }
    },
}), "ReviewForm", {
    CustomParent: Grid,
})

const ReviewForm = (props: IProps) => {
    const url = `${process.env.REACT_APP_API_URL}/review`;
    const [changed, setChanged] = useState(false)
    const { Root, classes } = useStyles()
    const theme = useTheme()

    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState<IReviewContent[]>([])
    const [reviewSummary, setReviewSummary] = useState('')
    const [errors, setErrors] = useState<IMultipleError>({})

    const validationRules: IValidationRules = {
        title: 'required',
        rating: 'required',
    }

    const validationAlias: IValidationAlias = {
        title: 'Title',
        rating: 'Rating',
    }

    const fetchData = useCallback(() => {
        setIsLoading(true)
        DefaultAxios.get(url, {
            params: {
                id: props.id,
                type: props.type
            }
        })
            .then(res => res.data)
            .then((data: any) => {
                if (data) {
                    setState(data.content)
                    setReviewSummary(data.summary)
                }
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [props.id, props.type, url])

    useEffect(() => {
        if (url) {
            fetchData()
        }
    }, [fetchData, url])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value } = e.target

        setState(prev => (prev.map((val, idx) => ({
            ...val,
            [name]: index === idx ? name === 'rating' ? inputNumberTwo(value) : value : val[name as keyof IReviewContent]
        }))))

        setErrors(prev => ({
            ...prev,
            [index]: {
                ...prev[index],
                [name]: '',
            }
        }))

        setChanged(true)
    }

    const handleReviewSummary = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setReviewSummary(value)
        setChanged(true)
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>, index: number) => {
        const { name, value } = e.target

        const { errors } = validateData({ [name]: value }, validationRules, validationAlias)

        setErrors(prev => ({
            ...prev,
            [index]: {
                ...prev[index],
                ...errors
            }
        }))
    }

    const addArticle = () => {
        const currentData = [...state]
        let isTotalValid = true

        currentData.forEach((data, index) => {
            const { isValid, errors } = validateData(data, validationRules, validationAlias)
            if (!isValid) isTotalValid = false

            setErrors(prev => ({
                ...prev,
                [index]: errors
            }))
        })

        if (isTotalValid) {
            setState(prev => (prev.concat({
                title: '',
                rating: '',
            })))
        } else {
            window.scrollTo({
                top: 0,
            })
        }

        setChanged(true)
    }

    const deleteArticle = (index: number) => {
        const currentData = [...state]
        currentData.splice(index, 1)
        setState(currentData)

        setErrors(prev => ({
            ...prev,
            [index]: {}
        }))

        setChanged(true)
    }

    const moveUp = (index: number) => {
        const currentData = [...state]

        const tempValue = currentData[index - 1]
        currentData[index - 1] = currentData[index]
        currentData[index] = tempValue

        setState(currentData)

        setErrors(prev => ({
            ...prev,
            [index - 1]: prev[index],
            [index]: prev[index - 1]
        }))

        setChanged(true)
    }

    const moveDown = (index: number) => {
        const currentData = [...state]

        const tempValue = currentData[index + 1]
        currentData[index + 1] = currentData[index]
        currentData[index] = tempValue

        setState(currentData)

        setErrors(prev => ({
            ...prev,
            [index + 1]: prev[index],
            [index]: prev[index + 1]
        }))

        setChanged(true)
    }

    const submit = () => {
        const currentData = [...state]
        let isTotalValid = true

        currentData.forEach((data, index) => {
            const { isValid, errors } = validateData(data, validationRules, validationAlias)
            if (!isValid) isTotalValid = false

            setErrors(prev => ({
                ...prev,
                [index]: errors
            }))
        })

        if (isTotalValid) {
            setIsLoading(true)

            DefaultAxios.post(url, {
                id: props.id,
                type: props.type,
                summary: reviewSummary,
                content: state
            })
                .then(() => {
                    setChanged(false)
                    Swal.fire({
                        title: "Review berhasil disimpan",
                        icon: 'success',
                        timer: 1000
                    })
                        .then(() => {
                            fetchData()
                        })
                })
                .catch(error => {
                    generalErrorHandler(error)
                })
                .finally(() => {
                    setIsLoading(false)
                })

        } else {
            window.scrollTo({
                top: 0,
            })
        }
    }

    const styles = (theme: Theme) => ({
        warning: {
            position: 'fixed',
            top: '12px',
            left: '8px',
            right: '8px',
            transform: `translateY(${changed ? '0%' : 'calc(-100% - 12px)'})`,
            zIndex: 1300,
            backgroundColor: '#ffb723',
            margin: 0,
            color: 'white',
            padding: '8px 12px !important',
            borderRadius: '8px',
            transition: 'all 0.2s linear',
            [theme.breakpoints.up('sm')]: {
                left: '50%',
                right: 'unset',
                marginLeft: '135px',
                marginRight: 0,
                transform: `translateX(-50%)translateY(${changed ? '0%' : 'calc(-100% - 12px)'})`,
            }
        }
    })

    const renderForm = () => {
        return (
            <>
                <Box component="div" sx={styles(theme).warning as React.CSSProperties}>
                    You have unsaved changes, don't forget to submit the data!
                </Box>
                {
                    state.map((value, index) =>
                        <Grid container key={`${index}`} spacing={3} alignItems="center">
                            <Grid item xs={12} sm={10}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <span className={classes.title}>
                                            Review {index + 1}
                                        </span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            placeholder="Title"
                                            label="Title"
                                            name="title"
                                            value={value.title}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, index)}
                                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleBlur(e, index)}
                                            error={!!errors[index]?.title}
                                            helperText={errors[index]?.title}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            placeholder="Rating"
                                            label="Rating"
                                            name="rating"
                                            value={value.rating}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, index)}
                                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleBlur(e, index)}
                                            error={!!errors[index]?.rating}
                                            helperText={errors[index]?.rating}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3} style={{ display: 'flex' }}>
                                        <IconButton
                                            color={index === 0 ? 'default' : 'primary'}
                                            style={{ marginLeft: 'auto', marginBottom: 'auto' }}
                                            onClick={() => moveUp(index)}
                                            disabled={index === 0}
                                        >
                                            <ArrowUpwardIcon fontSize="large" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={3} style={{ display: 'flex' }}>
                                        <IconButton
                                            color={index === state.length - 1 ? 'default' : 'primary'}
                                            style={{ marginLeft: 'auto', marginBottom: 'auto' }}
                                            onClick={() => moveDown(index)}
                                            disabled={index === state.length - 1}
                                        >
                                            <ArrowDownwardIcon fontSize="large" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={3} style={{ display: 'flex' }}>
                                        <IconButton
                                            color="secondary"
                                            style={{ marginLeft: 'auto', marginBottom: 'auto' }}
                                            onClick={() => deleteArticle(index)}
                                        >
                                            <CancelIcon fontSize="large" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                index !== (state.length - 1) &&
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            }
                        </Grid>
                    )
                }
            </>
        )
    }

    return (
        <Root item xs={12} >
            <LoadingScreen open={isLoading} fullScreen />
            <Paper style={{ padding: 16, marginRight: 24 }}>
                <div className={classes.listContainer}>
                    {renderForm()}
                </div>
                <Grid
                    container
                    spacing={3}
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <div className={classes.flexCenter}>
                            <Button
                                variant="contained"
                                onClick={addArticle}

                            >
                                Add Review
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                <Grid style={{ margin: '24px 0' }}>
                    <TextField
                        variant="outlined"
                        placeholder="Summary"
                        label="Summary"
                        name="summary"
                        value={reviewSummary}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleReviewSummary(e)}
                        // onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleBlur(e, index)}
                        // error={!!errors[index]?.title}
                        // helperText={errors[index]?.title}
                        multiline
                        rows={3}
                        fullWidth
                    />
                </Grid>
                {
                    changed &&
                    <Grid container spacing={3}>
                        <Grid
                            item
                            xs={12}
                        >
                            <div className={classes.submitContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={submit}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                }
            </Paper>
        </Root >
    )
}



export default ReviewForm
