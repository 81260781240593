import React, { useState } from 'react';
import {
    Grid,
    Tooltip,
    IconButton,
    Theme,
    useMediaQuery,
    useTheme
} from '@mui/material';
import moment from 'moment';

/**
 * Components
 */
// import LightTooltip from '../../_components/LightTooltip';
import UserPlacementModal from "../_user/_components/UserPlacementModal";
import DataTable from '../../_components/_dataTable/DataTable';
import DateRangePicker, { DateRange } from '../../_components/_form/DateRangePicker';

/**
 * Icons
 */
// import InfoIcon from '@mui/icons-material/Info';
import GroupIcon from '@mui/icons-material/Group';

const formatDate = (date: DateRange) => {
    if (!date) return ''

    return moment(date).format('DD/MM/YYYY')
}

const PicLeadReport = () => {
    const theme = useTheme<Theme>()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const API_URL = `${process.env.REACT_APP_API_URL}/pic-lead-report`;

    const [redraw, setRedraw] = useState(0);
    const [userPlacementValue, setUserPlacementValue] = useState({
        id: "",
        name: ""
    });

    const initialDateFilter = {
        date_start: moment().subtract(1, 'weeks'),
        date_end: moment()
    };

    const columns = [
        {
            name: 'name',
            label: 'Nama',
            type: 'string',
            filterable: true,
        },
        {
            name: 'cluster_placement_member',
            label: 'Placement',
            type: 'string',
            filterable: true,
            render: (data: string, row: any) => {
                return (
                    <>
                        {data}
                        {
                            <Tooltip title="Edit Cluster Placement">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => setUserPlacementValue({
                                        id: row.id,
                                        name: row.name
                                    })}
                                >
                                    <GroupIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                    </>

                )
            }
        },
        {
            name: 'leads_report',
            label: 'Leads',
            type: 'string',
            filterable: false,
            render: (data: any) => {
                return data || 0;
            }
        },
        {
            name: 'average_daily_leads',
            label: 'Average Daily Leads',
            type: 'string',
            filterable: false,
            render: (data: any) => {
                return data || 0;
            }
        },
        // {
        //     name: 'predicted_daily_leads',
        //     label: (
        //         <>
        //             Predicted Daily Leads Agent
        //             <LightTooltip title="Total leads predicted to be received by specific agent every day" placement="top">
        //                 <InfoIcon fontSize="small" style={{ marginLeft: "5px" }}/>
        //             </LightTooltip>
        //         </>
        //     ),
        //     type: 'string',
        //     filterable: false,
        //     render: (data: any) => {
        //         return data || 0;
        //     }
        // },
    ];

    const [dateFilter, setDateFilter] = useState<{ date_start: DateRange, date_end: DateRange }>(initialDateFilter);
    const [customParams, setCustomParams] = useState({
        created_at: `${formatDate(dateFilter.date_start)} - ${formatDate(dateFilter.date_end)}`
    });

    const handleDateChanged = (dates: { startDate: DateRange, endDate: DateRange }) => {
        let newFilter = {
            date_start: dates.startDate,
            date_end: dates.endDate
        }
        setDateFilter(newFilter);
    }

    return (
        <Grid container spacing={3}>
            <UserPlacementModal
                isOpen={userPlacementValue.id !== "" ? true : false}
                onClose={() => {
                    setRedraw(redraw + 1);
                    setUserPlacementValue({
                        id: "",
                        name: ""
                    });
                }}
                user_name={userPlacementValue.name}
                user_id={userPlacementValue.id}
            />

            {
                !isMobile &&
                <Grid item xs={12} md={9} />
            }
            <Grid item xs={12} md={3} style={{ textAlign: 'end' }}>
                <DateRangePicker
                    startDate={dateFilter.date_start}
                    endDate={dateFilter.date_end}
                    onDatesChange={handleDateChanged}
                    onFinished={() => setCustomParams({
                        created_at: `${formatDate(dateFilter.date_start)} - ${formatDate(dateFilter.date_end)}`
                    })}
                    fullWidth
                />

            </Grid>

            <Grid item xs={12}>
                <DataTable
                    reDraw={redraw}
                    url={API_URL}
                    columns={columns}
                    customParams={customParams}
                />
            </Grid>
        </Grid>
    );
}

export default PicLeadReport;