import React, {
    // useState 
} from 'react';
import {
    useLocation,
    useParams,
    // useNavigate 
} from 'react-router-dom'
import {
    Grid,
    // ButtonGroup, 
    // Button 
} from '@mui/material';

// Components
import ProjectDetailForm from './ProjectDetailForm';
import ProjectImageForm from './ProjectImageForm';
import Project360PhotoForm from './Project360PhotoForm';

interface Props {
    permissions?: any;
}

const ProjectForm = (props: Props) => {
    const location = useLocation();
    const params: any = useParams();
    // const navigate = useNavigate();

    // const [type, setType] = useState(typeof params.type !== 'undefined' ? params.type : 'details');
    const type = 'details';

    const renderForm = () => {
        if (params.id) {
            if (type === 'details') {
                return <ProjectDetailForm
                    projectId={params.id}
                    mode="edit"
                />
            } else if (type === 'image') {
                return <ProjectImageForm
                    projectId={params.id}
                />
            } else if (type === '360-photo') {
                return <Project360PhotoForm
                    projectId={params.id}
                />
            }
        } else {
            return <ProjectDetailForm
                projectId={null}
                mode="add"
            />
        }
    }

    // const handleNavigation = (route: string) => {
    //     if (route !== type) {
    //         if (route === 'details') {
    //             navigate(`/project/edit/${params.id}`);
    //         } else {
    //             navigate(`/project/edit/${params.id}/${route}`);
    //         }
    //         setType(route);
    //     }
    // }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <h1 style={{ borderBottom: '1px solid #eee' }}>Project - {location.pathname === '/project/add' ? 'Add' : 'EDIT'}</h1>
            </Grid>

            {/* {
                location.pathname !== '/project/add'
                    ? <Grid item xs={12}>
                        <ButtonGroup
                            color="primary"
                            aria-label="outlined primary button group"
                            fullWidth
                        >
                            <Button
                                variant={type === 'details' ? 'contained' : 'outlined'}
                                onClick={() => handleNavigation('details')}
                            >
                                Details
                            </Button>
                            <Button
                                variant={type === 'image' ? 'contained' : 'outlined'}
                                onClick={() => handleNavigation('image')}
                            >
                                Images
                            </Button>
                            <Button
                                variant={type === '360-photo' ? 'contained' : 'outlined'}
                                onClick={() => handleNavigation('360-photo')}
                            >
                                360 Photo
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    : null
            } */}

            {renderForm()}
        </Grid>
    );
}

export default ProjectForm;