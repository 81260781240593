import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Utils
 */
import { generalErrorHandler, renderWarningButton } from '../../../_utils/Helper';
import DefaultAxios from '../../../_utils/DefaultAxios';

interface Props {
    open: boolean;
    onClose: () => void;
    closingId: string;
    status: string;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    cancelCallback: () => void;
}

const CancelClosingModal = (props: Props) => {
    const canceledReasonsDraft = [
        'Gagal UTJ Kantor',
        'Gagal UTJ Developer',
    ];

    const canceledReasonsNotDraft = [
        'Gagal Setelah SP',
        'Gagal Cicilan',
        'Gagal Lainnya',
    ];

    const [state, setState] = useState({
        canceled_reason: '',
        canceled_notes: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
console.log(target);
        if (target) {
            setState(prevState => ({
                ...prevState,
                [target.name]: target.value,
            }));
        }
    }

    const handleSubmit = () => {
        renderWarningButton("Apakah anda yakin ingin membatalkan closing ini?")
            .then((result) => {
                if (result.value) {
                    props.setIsLoading(true);
                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/closing/${props.closingId}/cancel`, state)
                        .then(res => {
                            Swal.fire({
                                title: "Berhasil membatalkan closing",
                                icon: 'success',
                                timer: 1000,
                                onAfterClose: props.cancelCallback,
                            });
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            props.setIsLoading(false);
                        })

                }
            })
    }

    return (
        <Dialog fullWidth maxWidth="sm" open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Cancel Closing</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            label="Alasan Gagal"
                            name="canceled_reason"
                            value={state.canceled_reason}
                            onChange={handleChange}
                        >
                            {
                                (props.status === 'draft' ? canceledReasonsDraft : canceledReasonsNotDraft).map((reason, idx) => <MenuItem value={reason} key={idx}>{reason}</MenuItem>)
                            }
                        </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Notes"
                            name="canceled_notes"
                            value={state.canceled_notes}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Batal
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CancelClosingModal;