import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

interface StatementCancelFormProps {
    isOpen: boolean
    onClose: () => void
    handleSubmitCancel: (reason: string) => void
}

const StatementCancelForm = (props: StatementCancelFormProps) => {
    const [reason, setReason] = useState('')

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReason(e.target.value)
    }

    useEffect(() => {
        return () => setReason('')
    }, [props.isOpen])

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Cancel Reason</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ marginTop: 0 }}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Isi Alasan"
                            name="reason"
                            value={reason}
                            multiline
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            sx={{ float: 'right' }}
                            color="primary"
                            variant='contained'
                            disabled={!reason}
                            onClick={() => props.handleSubmitCancel(reason)}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default StatementCancelForm
