import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';

/**
 * Components
 */
import AsyncMultipleAutoComplete, { IAutoCompleteOption } from '../_form/AsycMultipleAutoComplete';

interface Props {
    open: boolean;
    onClose: () => void;
    label: string;
    url: string;
    targetIds: IAutoCompleteOption[];
    setTargetIds: Function;
    onSubmit: (is_also_copy_percentage: boolean) => void;
}

const CopyPlacementModal = (props: Props) => {
    const [isCopyPercentage, setIsCopyPercentage] = useState(false)

    const handleAutoCompleteMultipleChange = (name: string, value: IAutoCompleteOption[]) => {
        props.setTargetIds(value);
    }

    useEffect(() => {
        if (!props.open) setIsCopyPercentage(false)
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Copy Placement</DialogTitle>
            <DialogContent>
                <AsyncMultipleAutoComplete
                    label={props.label}
                    url={props.url}
                    name="target_id"
                    value={props.targetIds}
                    onChange={handleAutoCompleteMultipleChange}
                />
                <FormControlLabel
                    sx={{ marginTop: 1 }}
                    checked={isCopyPercentage}
                    onChange={() => setIsCopyPercentage(prev => !prev)}
                    control={<Checkbox />}
                    label="Also Copy the Percentage"
                />
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => props.onSubmit(isCopyPercentage)}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CopyPlacementModal;