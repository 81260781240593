import React, { useState, useReducer, useEffect } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DefaultAxios from '../../_utils/DefaultAxios';
import helpers from '../../_utils/Helpers';
import { generalErrorHandler, renderSuccessButton } from '../../_utils/Helper';

export interface IState {
    bukan_kata_sandi_lama: string;
    bukan_kata_sandi: string;
    bukan_kata_sandi_confirmation: string;
}

export interface IAction {
    name: string,
    value: any,
    type: string
}

export const API_URL = `${process.env.REACT_APP_API_URL}/profile/change-password`;

const ProfileSetting = () => {
    const [isLoading, setIsLoading] = useState(false);

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                bukan_kata_sandi_lama: '',
                bukan_kata_sandi: '',
                bukan_kata_sandi_confirmation: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        bukan_kata_sandi_lama: '',
        bukan_kata_sandi: '',
        bukan_kata_sandi_confirmation: '',
    });

    useEffect(() => {
        return () => {
            if (localStorage.getItem('should-change-password')) {
                localStorage.removeItem('should-change-password');
            }
        }
    }, []);

    const handleInputChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;
            setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
        }
    }

    const submitForm = () => {
        let axios;
        axios = DefaultAxios.post(`${API_URL}`, inputState);

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Password Changed",
                    icon: 'success',
                    timer: 1000
                });
            })
            .finally(() => {
                setIsLoading(false);
            })
            .catch(error => {
                helpers.generalErrorHandler(error);
                setIsLoading(false);
            });
    }

    const handleResetAccess = () => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/profile/reset-permission`)
            .then(res => res.data)
            .then(res => {
                localStorage.setItem('reset_permission_timer', String(0))
                renderSuccessButton('Reset Berhasil')
                    .then(() => {
                        window.location.reload()
                    })
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Grid container spacing={3}>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={12}>
                <h1 style={{ borderBottom: '1px solid #eee' }}>Profile Setting</h1>
            </Grid>

            {
                localStorage.getItem('should-change-password') &&
                <Grid item xs={12}>
                    <h3 style={{ color: 'red' }}>Please change your password before continue access the cms</h3>
                </Grid>
            }

            <Grid item xs={6}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Old Password"
                            type="password"
                            name="bukan_kata_sandi_lama"
                            value={inputState.bukan_kata_sandi_lama}
                            onChange={handleInputChanged}
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Password"
                            type="password"
                            name="bukan_kata_sandi"
                            value={inputState.bukan_kata_sandi}
                            onChange={handleInputChanged}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Confirm Password"
                            type="password"
                            name="bukan_kata_sandi_confirmation"
                            value={inputState.bukan_kata_sandi_confirmation}
                            onChange={handleInputChanged}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={submitForm}
                        >
                            Submit
                        </Button>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs={12}>
                <h1 style={{ borderBottom: '1px solid #eee' }}>Reset Access</h1>
            </Grid>

            <Grid item xs={6}>
                <Button variant='contained' onClick={handleResetAccess}>
                    Reset Access
                </Button>
            </Grid>
        </Grid>
    );
}

export default ProfileSetting;