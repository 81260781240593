import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';
import StatementAssignForm from './_components/StatementAssignForm';
import StatementCancelForm from './_components/StatementCancelForm';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { numberToCurrency } from '../../_utils/Helpers';
import { generalErrorHandler } from '../../_utils/Helper';
import moment from 'moment';


interface IProps {
}
export interface IBankAccountStatement {
    id: number
    is_assigned_jendela: boolean | number
    bank_source_number: string
    duplicate_of: string
    type: string
    is_other: boolean | number
    unique_code: string
    date: string
    amount: string
    trailer: string
    description: string
    mapped_by: string
    mapped_at: string
    created_at: string
    updated_at: string
    deleted_at: string
    deleted_reason: string
    import_id: string
    mapping_type: string
    code: string
}

export const escrowTransactionType = [
    { value: 'office_commission', label: 'Komisi Kantor' },
    { value: 'ppn', label: 'PPN' },
    { value: 'pph21', label: 'PPH21 Setor Negara Advisor 1' },
    { value: 'pph21_cobroke', label: 'PPH21 Setor Negara Advisor 2' },
    { value: 'return_loan', label: 'Pengembalian Pinjaman Advisor 1' },
    { value: 'return_loan_cobroke', label: 'Pengembalian Pinjaman Advisor 2' },
    { value: 'agent_commission', label: 'Komisi Advisor 1' },
    { value: 'agent_cobroke_commission', label: 'Komisi Advisor 2' },
    { value: 'telesales_commission', label: 'Telesales' }

]

export const API_URL = process.env.REACT_APP_API_URL + '/bank-account-statement';

const StatementList = (props: IProps) => {
    const [state, setState] = useState<IBankAccountStatement>(initialState)

    const [modalState, setModalState] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());


    const columns = [
        {
            name: 'date',
            label: 'Tanggal',
            type: 'date',
            filterable: true,
            render: (data: any, row: any) => {
                return (
                    `${moment(data).format("DD MMMM YYYY")}`
                )
            }
        },
        {
            name: 'trailer',
            label: 'Keterangan',
            type: 'string',
            filterable: true
        },
        {
            name: 'amount_cr',
            label: 'CR',
            type: 'number',
            filterable: true,
            render: (data: number | null, row: any) => (
                data ? numberToCurrency(Math.floor(data), "Rp.") : "-"
            )
        },
        {
            name: 'amount_db',
            label: 'DB',
            type: 'number',
            filterable: true,
            render: (data: number | null, row: any) => (
                data ? numberToCurrency(Math.floor(data), "Rp.") : "-"
            )
        },
        {
            name: 'mapping_type',
            label: 'Jenis Data',
            type: 'string',
            filterable: false
        },
        {
            name: 'code',
            label: 'Kode Mapping',
            type: 'string',
            filterable: true,
            render: (data: string) => (
                data || '-'
            )
        },
        {
            name: 'mapped_at',
            label: 'Action',
            filterable: false,
            render: (data: string, row: any) => (
                !row.mapped_at ?
                    row.type === 'DB' ?
                    null :
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        onClick={() => assignStatement(row)}
                    >
                        Assign
                    </Button>
                    :
                    !(['partial', 'full', 'auto'].some(value => row.mapping_type.includes(value))) &&
                    <Button
                        size='small'
                        variant='contained'
                        color='secondary'
                        onClick={() => cancelStatement(row)}
                    >
                        Cancel
                    </Button>
            )
        }
    ];

    useEffect(() => {
        if (!modalState) {
            setState(initialState)
        }
        // eslint-disable-next-line
    }, [modalState]);

    const assignStatement = (data: any) => {
        setState(prev => ({
            ...prev,
            id: data.id,
            type: data.type,
            date: data.date,
            trailer: data.trailer,
            amount: data.amount,
        }))
        setModalState(1)
    }

    useEffect(() => {
        if (modalState === 0) {
            setState(initialState)
        }
    }, [modalState])

    const handleSubmit = (data: any) => {
        // const { isValid, errors } = validateData(state, validationRules, validationAlias)
        // setError(errors)

        // if (!isValid) return

        setIsLoading(true);
        DefaultAxios.patch(`${API_URL}/${state.id}`, data)
            .then(res => {
                Swal.fire({
                    title: "Statement Assigned",
                    icon: 'success',
                    onAfterClose: () => {
                        setReDrawDatatable(new Date().getTime());
                        setModalState(0);
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const cancelStatement = (data: any) => {
        setState(prev => ({
            ...prev,
            id: data.id,
            type: data.type,
            date: data.date,
            trailer: data.trailer,
            amount: data.amount,
        }))
        setModalState(2)
    }

    const handleSubmitCancel = (reason: string) => {
        setIsLoading(true);
        DefaultAxios.post(`${API_URL}/${state.id}/cancel`, {
            reason
        })
            .then(res => {
                Swal.fire({
                    title: "Statement Canceled",
                    icon: 'success',
                    onAfterClose: () => {
                        setReDrawDatatable(new Date().getTime());
                        setModalState(0);
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const exportData = () => {
        setIsLoading(true)

        DefaultAxios
            .get(API_URL+'/export', { responseType: 'blob' })
            .then((res) => {
                setIsLoading(false)
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `bank-account-statement.xls`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                setIsLoading(false)
                generalErrorHandler(err)
            })
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />

            <StatementCancelForm
                isOpen={modalState === 2}
                onClose={() => setModalState(0)}
                handleSubmitCancel={handleSubmitCancel}
            />

            <StatementAssignForm
                id={state.id}
                isOpen={modalState === 1}
                onClose={() => setModalState(0)}
                handleSubmit={handleSubmit}
            />

            <Button
                variant='contained'
                color='primary'
                onClick={() => {
                    setReDrawDatatable(new Date().getTime());
                }}
            >
                Refresh
            </Button>

            <Button
                color="primary"
                variant="contained"
                onClick={exportData}
                style={{ float: 'right' }}
            >
                Export
            </Button>

            <br /><br />

            <DataTable
                url={process.env.REACT_APP_API_URL + '/bank-account-statement'}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </>
    );
}

export default StatementList;

const initialState: IBankAccountStatement = {
    id: 0,
    is_assigned_jendela: 0,
    bank_source_number: "",
    duplicate_of: "",
    type: '',
    is_other: 0,
    unique_code: "",
    date: "",
    amount: "",
    trailer: "",
    description: "",
    mapped_by: "",
    mapped_at: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    deleted_reason: "",
    import_id: "",
    mapping_type: "",
    code: ''
}