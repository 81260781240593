import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, Table, TableHead, TableBody, TableRow, TableCell, Button, Divider, Grid, Typography } from '@mui/material'
import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete'
import Swal from 'sweetalert2'

/* 
* Utils
*/
import { numberToCurrency } from '../../../_utils/Helpers'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, renderWarningButton } from '../../../_utils/Helper'

/* 
* interfaces
*/
interface IProps {
    closing_id: string,
    open: boolean,
    onClose: () => void,
    is_cobroke: number
}

interface IData {
    agent_name: string,
    code: string
    id: number
    nominal: number
    type: number
}

const toastMixin = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true
});

const ListPinjamanModal = (props: IProps) => {
    const [agents, setAgents] = useState<IData[]>([])
    const [agent, setAgent] = useState<IData>()
    const [cobrokeAgent, setCobrokeAgent] = useState<IData>()
    const [cobrokeAgents, setCobrokeAgents] = useState<IData[]>([])
    const [remainingLoan, setRemainingLoan] = useState(0)
    const [remainingCobrokeLoan, setRemainingCobrokeLoan] = useState(0)
    const [count, setCount] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetchTableData()
        // eslint-disable-next-line
    }, [])

    const fetchTableData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/closing-loan/${props.closing_id}`)
            .then(res => {
                setAgents(res.data.loan_lists)
                setCobrokeAgents(res.data.cobroke_loan_lists)
                setRemainingLoan(res.data.remaining_loan)
                setRemainingCobrokeLoan(res.data.remaining_cobroke_loan)
                setIsLoading(false)
            })
            .catch(err => {
                generalErrorHandler(err)
                props.onClose();
            })
    }

    // const data = {
    //     closing_id: "CNJIAKWK",
    //     exclude: [1, 2, 3, 4],
    //     type: 0
    // }

    const generateParam = (data: any) => {
        let string = ``
        for (let key in data) {
            if (typeof data[key] === 'object') {
                let substring = `${key}=${Object.values(data[key])}`
                string += substring
            } else {
                string += `${key}=${data[key]}`
            }
            string += `&`
        }
        return string
    }

    const generateTable = (data: IData[]) => {
        return (
            <Table
                sx={{ mb: 2 }}
                size="small"
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Kode Closing</TableCell>
                        <TableCell>Nama Agent</TableCell>
                        <TableCell>Nominal</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.length > 0 ?
                        data.map((row) =>
                        (
                            <TableRow>
                                <TableCell>{row.code}</TableCell>
                                <TableCell>{row.agent_name}</TableCell>
                                <TableCell>Rp. {numberToCurrency(row.nominal)}</TableCell>
                                <TableCell>
                                    <Button
                                        variant='outlined'
                                        size='small'
                                        color="secondary"
                                        onClick={() => handleCancel(row.id, `${row.type}`)}
                                    >
                                        Cancel
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )) : (
                            <TableRow>
                                <TableCell colSpan={4} align='center'>List tidak ditemukan</TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        )
    }

    const handleChangeAgent = (name: string, value: IData) => {
        if (name === 'agent') {
            setAgent({
                ...value,
                type: +value.type
            })
        }else {
            setCobrokeAgent({
                ...value,
                type: +value.type
            })
        }
    }

    const handleAddAgent = (data: IData) => {
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-loan`, { ...data as IData, closing_id: props.closing_id })
            .then(res => {
                if (res.data) {
                    toastMixin.fire({
                        icon: 'success',
                        title: 'Loan Added!'
                    })
                    fetchTableData()
                }
            })
            .catch(err => {
                generalErrorHandler(err)
            })
            .finally(() => {
                setCount(count => count + 1)
            })
    }

    const handleCancel = (id: number, type: string) => {
        renderWarningButton('Apakah anda yakin ingin menghapus pinjaman ini?')
            .then((result) => {
                if (result.value) {
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-loan/cancel`, { id, type })
                        .then(res => {
                            if (res.data) {
                                toastMixin.fire({
                                    icon: 'success',
                                    title: 'Load Deleted!'
                                })
                                fetchTableData()
                            }
                        })
                        .catch(err => {
                            generalErrorHandler(err)
                        })
                }
            })
    }

    const createLoanContent = (
        type: 'agent' | 'cobroke_agent',
        remaining: number,
        datas: IData[],
        data: IData,
    ) => {
        return (
            <>
                {!!props.is_cobroke && <Typography variant='h6'>Advisor {type === 'agent' ? 'Utama' : 'Cobroke'}</Typography>}
                <Typography variant='subtitle2' sx={{ mb: 2 }}>Nominal yang belum diassign : Rp. {numberToCurrency(remaining)}</Typography>
                {generateTable(datas)}
                <AsyncAutoComplete
                    key={count}
                    url={`${process.env.REACT_APP_API_URL}/autocomplete/closing-loan?${generateParam({
                        type: (type === 'agent' ? 0 : 1),
                        closing_id: props.closing_id,
                        exclude: datas.map(data => data.id)
                    })}`}
                    name={type}
                    label=""
                    onChange={handleChangeAgent}
                    initialQuery={""}
                />
                <Grid container justifyContent="right">
                    <Button
                        variant='contained'
                        sx={{ mt: 1 }}
                        onClick={() => handleAddAgent(data as IData)}
                    >
                        Submit
                    </Button>
                </Grid>
            </>
        )
    }

    return (
        isLoading ? 
        <></> :
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Pinjaman Advisor</DialogTitle>
            <Divider />
            <DialogContent>
                {createLoanContent('agent', remainingLoan, agents, agent as IData)}
                <Divider orientation='horizontal' sx={{ my: 2 }} />
                {!!props.is_cobroke && createLoanContent('cobroke_agent', remainingCobrokeLoan, cobrokeAgents, cobrokeAgent as IData)}
            </DialogContent>
        </Dialog>
    )
}

export default ListPinjamanModal