import React, { useEffect, useState, useReducer } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Button, Grid, TextField } from '@mui/material'
import Swal from 'sweetalert2'
import LoadingScreen from '../../../_components/LoadingScreen'
import TextEditor from '../../../_components/_form/TextEditor'

import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../../_utils/Helper'
import helpers from '../../../_utils/Helpers'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete';
import Autocomplete from '@mui/material/Autocomplete';
import { generateStyle } from '../../../_utils/DefaultStyles'

interface IProps {
    id?: string
    permissions?: any
}

interface IState {
    slug: string
    category_id: string
    category_label: string
    featured_image: File | null
    featured_image_url: string
    meta_title: string
    meta_description: string
    title: string
    body: string
    tags: any
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IListTag {
    id: number;
    name: string;
}

const ArticleForm = (props: IProps) => {
    const navigate = useNavigate();
    const params: any = useParams();
    const { Root, classes } = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [tagList, setTagList] = useState<Array<IListTag>>([]);
    const initialState = {
        slug: '',
        category_id: '',
        category_label: '',
        featured_image: null,
        featured_image_url: '',
        meta_title: '',
        meta_description: '',
        title: '',
        body: '',
        tags: []
    }

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    // Error Reducer

    const loadFormData = (state?: IState) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/autocomplete/tag`)
            .then(res => {
                setTagList(res.data);
            });
    }

    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    const handleEditorChange = (name: string, value: string) => {
        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        if (['featured_image'].includes(name)) {
            if (target && target.files) {
                value = target.files[0];
            } else {
                value = null;
            }
        }

        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const handleAutocompleteCategory = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.category_id = value.id.toString();
        newState.category_label = value.label;

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteTags = (value: any) => {
        setInputState({ name: 'tags', value: value, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const fetchData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/blogs/${params.id}`, {
            params: {
                data_id: props.id,
            }
        })
            .then(res => res.data)
            .then(data => {
                const resData = data;
                const newState: any = {};

                for (let [key, value] of Object.entries(resData)) {
                    newState[key] = value;
                    // if (booleanAttributes.includes(key)) {
                    //     newState[key] = !!value;
                    // } else {

                    // }
                }

                loadFormData(newState);
                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
                // setInputState(data)
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const newTag = (e: any) => {
        if (e.keyCode === 13 && e.target.value) {
            let val = {
                id: 'new',
                name: e.target.value
            }
            let oldValue = inputState.tags
            let newValue = oldValue.concat(val)
            setInputState({ name: 'tags', value: newValue, type: 'SET_ITEM' });
        }
    }

    const submit = () => {

        const fd = new FormData();

        for (let [key, value] of Object.entries(inputState)) {
            if (value) {
                if (['featured_image'].includes(key)) {
                    fd.append(key, value as File);
                } else if (['tags'].includes(key)) {
                    fd.append(key, JSON.stringify(value) as string);
                } else {
                    fd.append(key, value as string);
                }
            }
        }

        let axios;
        if (params.id) {
            fd.append('_method', 'PATCH');
            axios = DefaultAxios.post(`${process.env.REACT_APP_API_URL}/blogs/${params.id}`, fd);
        } else {
            axios = DefaultAxios.post(`${process.env.REACT_APP_API_URL}/blogs`, fd);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        if (params.id) {
                            fetchData()
                        } else {
                            navigate('/article');
                        }
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        if (params.id) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])
    console.log(inputState)
    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Title"
                        name="title"
                        value={inputState.title}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Slug"
                        name="slug"
                        value={inputState.slug}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Meta Title"
                        name="meta_title"
                        value={inputState.meta_title}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Meta Description"
                        name="meta_description"
                        value={inputState.meta_description}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AsyncAutoComplete
                        label="Category"
                        name="category_id"
                        initialQuery={inputState.category_label}
                        onChange={handleAutocompleteCategory}
                        onInputChange={handleAutocompleteInputChanged}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/blog-categories`}
                        placeholder="Category"
                    />
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.imageContainer}>
                        <h3>Featured Image</h3>
                        {
                            inputState.featured_image_url ?
                                <>
                                    <img src={inputState.featured_image_url} style={{ width: "350px", height: "130px" }} alt="featured" />
                                    <br></br>
                                </>
                                : null
                        }
                        <input type="file" name="featured_image" onChange={handleChange} style={{ marginBottom: '10px' }} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.highlightContainer}>
                        <h3>Article</h3>
                        <TextEditor
                            name="body"
                            value={inputState.body}
                            onChange={handleEditorChange}
                            imagesUpload
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        value={inputState.tags}
                        options={tagList}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                            handleAutocompleteTags(newValue)
                        }}
                        // TODO: might not work
                        // Should be working now, but leave the the comment above to search for it easier later if problems occurs
                        isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                label="Tags"
                                style={{ marginTop: '30px' }}
                                placeholder="Choose Tags"
                                onKeyDown={newTag}
                            // onKeyPress={onKeyPress}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={submit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Root>
    );
}

const useStyles = generateStyle(theme => ({
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > .label': {
            fontSize: 16,
            color: '#484848',
            marginBottom: 12,
        },
        '& > .error': {
            color: theme.palette.error.main,
            marginTop: 12,
        }
    },
    line: {
        display: 'block',
        width: '100%',
        height: 1,
        backgroundColor: '#cacaca',
        margin: '16px 0px 16px',
    },
    metaContainer: {
        '& .title': {
            fontSize: 16,
            fontWeight: '500',
        },
        '& .subtitle': {
            fontWeight: '500',
        }
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    submitContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    listContainer: {
        '& > *': {
            paddingBottom: 16,
            paddingTop: 16,
            borderBottom: '2px solid #cacaca',
        },
        '& > *:first-of-type': {
            paddingTop: 0,
        },
        '& > *:last-child': {
            borderBottom: 'none',
        }
    },
    highlightContainer: {
        marginBottom: 16,
        '& > h3': {
            marginTop: 0,
        },
        '& > .error': {
            marginTop: 8,
            color: 'red',
            fontSize: 14,
            display: 'block',
        },
    },
}), "Article_Form"
)

export default ArticleForm
