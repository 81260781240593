// import DefaultAxios from "./DefaultAxios";
import Swal from 'sweetalert2';
import moment from 'moment';
import DefaultAxios from './DefaultAxios';

export const renderWarningButton = (label?: string, title = 'Apakah Anda Yakin?') => {
    return Swal.fire({
        title: title,
        text: label,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya'
    })
}

export const renderErrorButton = (label?: string, title = 'Data Error') => {
    return Swal.fire({
        title: title,
        text: label,
        icon: 'error',
        confirmButtonText: 'OK'
    })
}

export const renderSuccessButton = (label: string, onClose?: () => void) =>
    Swal.fire({
        title: label,
        icon: 'success',
        onAfterClose: onClose,
    })

export const renderInfoButton = (label: string, body?: string, onClose?: () => void) =>
    Swal.fire({
        title: label,
        html: body,
        icon: 'info',
        onAfterClose: onClose,
    })

export function converNumberFormat(number_to_convert: string) {
    let converted_number = number_to_convert.toString();
    converted_number = converted_number.replace('.00', '');
    let number = converted_number.replace(/[^\d]/g, '');
    let num = number.replace(/,/gi, "");
    let num2 = num.split(/(?=(?:\d{3})+$)/).join(",");
    return num2;
}

export function isMobile() {
    if (window.matchMedia("only screen and (max-width: 760px)").matches) {
        return true;
    } else {
        return false;
    }
}

export function convertNumber(number: string, prefix?: string, with_decimal?: boolean) {
    let numberString;
    let decimal;

    //With Decimal
    if (with_decimal) {
        number = Number(number).toFixed(2);

        const exploded_number = number.split(".");
        numberString = parseInt(exploded_number[0].replace(/[^,\d]/g, '')).toString();
        decimal = typeof exploded_number[1] !== 'undefined' ? exploded_number[1] : "";
    } else {
        numberString = parseInt(number.replace(/[^,\d]/g, '')).toString();
    }

    const split = numberString.split(',')
    const sisa = split[0].length % 3
    let rupiah = split[0].substr(0, sisa)
    const ribuan = split[0].substr(sisa).match(/\d{3}/gi)

    if (ribuan) {
        const separator = sisa ? '.' : ''
        rupiah = rupiah + separator + ribuan.join('.')
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
    let output = prefix ? prefix : ''

    if (with_decimal) {
        return rupiah ? `${output}${rupiah},${decimal}` : '';
    } else {
        return rupiah ? output + rupiah : ''
    }
}

export function inputNumberTwo(number: string) {
    const numberString = number.replace(/[^,\d]/g, '').toString()
    if (numberString.indexOf(",") === -1) {
        return numberString.substr(0, 1)
    } else {
        return numberString.substr(0, 3);
    }
}

export function generalErrorHandler(
    error: any,
    overrides?: {
        [key: number]: (
            defaultAction: () => void,
            errorData?: any
        ) => void,
    }
) {
    if (typeof error.response === 'undefined') {
        Swal.fire({
            title: "Server Error",
            html: "Error pada server. Harap hubungi Admin",
            icon: 'error',
        });
        return;
    }

    let errorData = error.response.data;

    if (!(errorData instanceof Blob)) {
        errorData = new Blob([JSON.stringify(errorData)], { type: "application/json" });
    }

    let reader = new FileReader();

    reader.onload = () => {
        errorData = JSON.parse(String(reader.result));

        if (typeof error.response.status === 'undefined') {
            Swal.fire({
                title: "Script Error",
                html: "Error pada script. Harap hubungi Admin",
                icon: 'error',
            });
        } else if (error.response.status === 422) {
            let error_text = `<p>${errorData.message}</p>`;

            if (typeof errorData.errors !== 'undefined') {
                const error_lists = errorData.errors;
                for (var k in error_lists) {
                    error_text += `<p>${error_lists[k]}</p>`;
                }
            }

            Swal.fire({
                title: "Validasi Error",
                html: error_text,
                icon: 'error',
            });
        } else if (error.response.status === 400) {
            if (overrides && overrides[400]) {
                overrides[400](
                    () => {
                        Swal.fire({
                            title: "Data Error",
                            html: errorData.message,
                            icon: 'error',
                        });
                    },
                    errorData)
            } else {
                Swal.fire({
                    title: "Data Error",
                    html: errorData.message,
                    icon: 'error',
                });
            }
        } else if (error.response.status === 404) {
            Swal.fire({
                title: "Data Error",
                html: errorData.message,
                icon: 'error',
            });
        } else if (error.response.status === 500 || error.response.status === 405) {
            Swal.fire({
                title: "Server Error",
                html: "Error pada server. Harap hubungi Admin",
                icon: 'error',
            });
        } else if (error.response.status === 412) {
            Swal.fire({
                title: "Data Error",
                html: errorData,
                icon: 'error',
            });
        }
    };

    reader.readAsText(errorData);
}

export function convertPercentangeWithDecimal(number: string, max_number?: number) {
    if (typeof max_number === 'undefined') {
        max_number = 100;
    }

    if (number === "") {
        return "";
    }

    if ((number.match(/[.]/g) || []).length === 1) {
        if (number[number.length - 1] === '.') {
            const max_data = number.replace('.', '');

            if (parseInt(max_data) >= max_number) {
                return max_number;
            }
            return number;
        } else {
            const number_splitted = number.split('.');
            console.log(number_splitted[1].length);

            if (number_splitted[1].length === 1) {
                return parseFloat(number).toFixed(1);
            } else if (number_splitted[1].length === 2) {
                return parseFloat(number).toFixed(2);
            } else {
                return false;
            }
        }
    } else if ((number.match(/[.]/g) || []).length > 1) {
        return false;
    }

    if (isNaN(parseFloat(number))) {
        return false;
    } else {
        let new_value = parseFloat(number);
        new_value = new_value > max_number ? max_number : new_value;
        return new_value;
    }
}

export function inputNumber(number: string, min?: number) {
    const numberString = number.replace(/[^,\d]/g, '').toString()
    if (min !== undefined && min !== null && numberString) {
        if (Number(numberString) < min) {
            return min.toString()
        }
    }
    return numberString ? numberString : ''
}

export function validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    return re.test(String(email).toLowerCase());
}

export const checkNotification = () => new Promise((resolve, reject) => {
    // let url = process.env.REACT_APP_API_URL + '/notification'
    // DefaultAxios.get(url)
    //     .then(res => res.data)
    //     .then(res => {
    //         let isUnread = false
    //         if (!isUnread) {
    //             isUnread = res.some((element: any) => element.read_at === null)
    //         }
    //         localStorage.setItem('isUnread', isUnread ? 'true' : 'false')
    //         resolve(isUnread)
    //     })
    //     .catch(err => {
    //         console.log(err)
    //         reject()
    //     })
})

export const getMonth = (monthNum: number) => {
    switch (monthNum) {
        case 0:
            return 'January'
        case 1:
            return 'February'
        case 2:
            return 'March'
        case 3:
            return 'April'
        case 4:
            return 'May'
        case 5:
            return 'June'
        case 6:
            return 'July'
        case 7:
            return 'August'
        case 8:
            return 'September'
        case 9:
            return 'October'
        case 10:
            return 'November'
        case 11:
            return 'December'
    }
}

export const copyToClipboard = (content: string) => {
    // Create a dummy input to copy the string array inside it
    let dummy = document.createElement("input") as HTMLInputElement

    // Add it to the document
    document.body.appendChild(dummy);

    // Output the array into it
    dummy.value = content;

    // Select it
    dummy.select();

    // Copy its contents
    document.execCommand("copy");

    // Remove it as its not needed anymore
    document.body.removeChild(dummy);
}

export const getBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = () => resolve(reader.result)

        reader.onerror = error => reject(error)
    })
}

export const dateFormat = (date: string | Date | moment.Moment, format = 'DD/MM/YYYY'): string => {
    if (date === '') {
        return '';
    }
    const d = moment(date).format(format);
    return d;
}

export const renderMTS = (value: string) => {
    var name = ''
    switch (value) {
        case 'facebook_ads':
            name = 'Facebook Ads'
            break;
        case 'facebook-dm':
            name = 'Facebook DM'
            break;
        case 'instagram':
            name = 'Instagram'
            break;
        case 'instagram-dm':
            name = 'Instagram'
            break;
        case 'whatsapp':
            name = 'Whatsapp'
            break;
        case 'gdn':
            name = 'GDN'
            break;
        case 'google_ads':
            name = 'Google Ads'
            break;
        case 'others':
            name = 'Others'
            break;
        case 'seo':
            name = 'SEO'
            break;
        case 'input-agent':
            name = 'Input Agent'
            break;
        case 'direct':
            name = 'Direct'
            break;
        case 'call':
            name = 'Call'
            break;
        case 'tiktok':
            name = 'TikTok'
            break;
        case 'reference_agent':
            name = 'Reference Agent'
            break;
        case 'reference_cp':
            name = 'Reference CP'
            break;
        case 'fb-leads':
            name = 'Facebook Leads'
            break;
        default:
            name = '[Empty]'
            break;
    }

    return (name)
}

export const renderToastSuccess = (title: string) =>
    Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: true,
        timer: 8000,
        timerProgressBar: true
    }).fire({
        icon: 'success',
        title: title
    })

export function convertIndonesiaPhoneNumber(number: string) {
    const first_two_character = number.substring(0, 2);

    if (first_two_character === "08") {
        number = number.replace(/^08/g, "628");
    }

    return number.replace(/[^\d]/, '');
}

export const dateFormatRange = (date: string, format = 'DD/MM/YYYY'): string => {
    if (date === '') {
        return '';
    }
    let newDate = [];
    let dates = date.split(' - ');

    for (let d of dates) {
        let dd = moment(d);

        newDate.push(dd.isValid() ? dd.format(format) : '');
    }

    return newDate.join(' - ');
}

export const queryParamsToParams = (): any => {
    const param: any = {};
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.forEach((value: string, key: string) => {
        if (value.includes(' - ')) {
            let isDate = false;
            let split = value.split(' - ');

            for (let s of split) {
                let d = moment(s);

                if (d.isValid()) {
                    isDate = true;
                }
            }

            if (isDate) {
                param[key] = dateFormatRange(value);
            } else {
                param[key] = value;
            }
        } else {
            param[key] = value;
        }
    });

    return param;
}

export const download = (url: string, fileName: string, onFinish?: () => void, onError?: (err: any) => void) => {
    DefaultAxios.get(url, {
        responseType: 'blob'
    })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(err => {
            if (err && err.response && err.response.status === 410) {
                // Do Nothing
            } else {
                generalErrorHandler(err)
                if (onError) onError(err)
            }
        })
        .finally(onFinish)
}

export const alphaNumeric = (text: string) => text.replace(/[^a-z0-9 ]/gi, '')

export const openLink = (url: string, isNewTab?: boolean) => {
    const link = document.createElement('a')
    link.href = url
    if (isNewTab) {
        link.setAttribute('target', `_blank`)
        link.setAttribute('rel', `noopener norefferrer`)
    }
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
