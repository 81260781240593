import React, { useRef } from 'react';
import { Grid, TextField } from '@mui/material';

// Interfaces
import { IState } from './Page';
import AutoComplete from '@mui/material/Autocomplete';

// Assets
import defaultThumbnail from '../../../Assets/Images/png/default-thumbnail-unit.png';
import { generateStyle } from '../../../_utils/DefaultStyles';

interface Props {
    form: IState;
    onChange: Function;
    onSelect: Function
    index: number;
}

const useStyles = generateStyle(theme => ({
    thumbnail: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '50%'
        }
    }
}), 'Form')

const ImageForm = (props: Props) => {
    const {Root, classes} = useStyles()
    const inputRef = useRef<HTMLInputElement>(null)

    const openUpload = () => {
        inputRef.current?.click()
    }

    const captionsOptions = [
        'Master Bedroom',
        '2nd Bedroom',
        '3rd Bedroom',
        'Living Room',
        'Kitchen',
        'Balcony',
        'Swimming Pool',
        'Jogging Track',
        'Masterplan Project',
        'Denah Ruko',
        'Siteplan Cluster',
        'Dining Room',
        'Master Bathroom',
        "2nd Bathroom",
        "3rd Bathroom",
        "Backyard",
        "Frontyard",
        // "Balcony",
        "Living Room 1st Floor",
        "Living Room 2nd Floor"
    ]

    return (
        <Root>
            <Grid container spacing={2} style={{ marginLeft: 4, marginRight: 4 }}>
                <Grid item xs={12} md={2}>
                    {
                        props.form.thumbnail ?
                            <img onClick={openUpload} src={props.form.thumbnail} width="100" alt={props.form.alt} style={{ width: '100%', minHeight: 136, objectFit: 'cover', cursor: 'pointer' }} />
                            :
                            <img onClick={openUpload} src={defaultThumbnail} alt={props.form.alt} style={{ cursor: 'pointer', backgroundColor: '#fafafa' }} className={classes.thumbnail} />
                    }
                    <input ref={inputRef} type="file" name="file" style={{ width: 0, height: 0 }} onChange={(e) => props.onChange(e, props.index)} />
                </Grid>
                <Grid item xs={12} md={10}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {/* <TextField
                                fullWidth
                                name="caption"
                                variant="outlined"
                                value={props.form.caption}
                                onChange={(e) => props.onChange(e, props.index)}
                                placeholder="Max 50 karakter"
                                label="Caption"
                            /> */}
                            <AutoComplete
                                freeSolo
                                options={captionsOptions}
                                onChange={(e: React.ChangeEvent<{}>, newValue: string | null) => props.onSelect(props.index, 'caption', newValue)}
                                value={props.form.caption}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        name="caption"
                                        variant="outlined"
                                        // value={props.form.caption}
                                        onChange={(e) => props.onChange(e, props.index)}
                                        placeholder="Max 50 karakter"
                                        label="Caption"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="category"
                                select
                                label="Category"
                                value={props.form.category}
                                fullWidth
                                onChange={(e) => props.onChange(e, props.index)}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                            >
                                <option value="">Pilih Category</option>
                                {
                                    categoryOptions.map(option =>
                                        <option value={option.value} key={option.value}>{option.label}</option>
                                    )
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="alt"
                                variant="outlined"
                                value={props.form.alt}
                                onChange={(e) => props.onChange(e, props.index)}
                                label="Alt"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <i>Recommended dimension : 1500 x 1000 or larger with the same ratio</i>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Root>
    );
}

const categoryOptions = [
    {
        value: 'cluster_area',
        label: 'Cluster Area',
    },
    {
        value: 'facilities',
        label: 'Facilities',
    },
    {
        value: 'unit_type',
        label: 'Unit Type',
    },
    {
        value: 'siteplan',
        label: 'Siteplan',
    },
    {
        value: 'masterplan',
        label: 'Masterplan',
    }
]

export default ImageForm;
