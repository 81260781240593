import React, { useEffect, useState } from 'react'
import { Button, Grid } from '@mui/material'
import Swal from 'sweetalert2'
import LoadingScreen from '../../_components/LoadingScreen'
import TextEditor from '../../_components/_form/TextEditor'

import DefaultAxios from '../../_utils/DefaultAxios'
import helpers from '../../_utils/Helpers'
import { Theme } from '@mui/material';
import { generateStyle } from '../../_utils/DefaultStyles'

const UserGuide = () => {
    const { Root, classes } = useStyles()
    const [isLoading, setIsLoading] = useState(false)

    const [inputState, setInputState] = useState({
        article: ''
    });

    const handleEditorChange = (name: string, value: string) => {
        setInputState({
            article: value
        })
    }

    const fetchData = () => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/site-setting/user-guide`)
            .then(res => {
                setInputState({
                    article: res.data || ''
                })
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const submit = () => {
        setIsLoading(true);
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/site-setting/user-guide`, { value: inputState.article })
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        fetchData()
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className={classes.highlightContainer}>
                        <h3>Article</h3>
                        <TextEditor
                            name="article"
                            value={inputState.article}
                            onChange={handleEditorChange}
                            imagesUpload
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={submit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Root>
    );
}

const useStyles = generateStyle((theme: Theme) => ({
    submitContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    highlightContainer: {
        marginBottom: 16,
        '& > h3': {
            marginTop: 0,
        },
        '& > .error': {
            marginTop: 8,
            color: 'red',
            fontSize: 14,
            display: 'block',
        },
    },
}), "UserGuide_Form"
)

export default UserGuide
