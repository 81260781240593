import React, { useCallback } from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'

interface IProps extends DropzoneOptions {
    onChange: Function
    name: string
}

interface IStyles {
    [key: string]: React.CSSProperties
}

const DropZone = (props: IProps) => {
    const onDrop = useCallback((file: any) => {
        props.onChange(file, props.name)

    // eslint-disable-next-line
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onDrop,
        accept: props.accept,
        maxFiles: props.maxFiles,
        multiple: props.multiple
    })

    return (
        <div {...getRootProps()} style={styles.container}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p style={{textAlign: 'center'}}>Drop the image here ...</p> :
                    <p style={{textAlign: 'center'}}>Drag n Drop an image file or click to select an image file</p>
            }
        </div>
    )
}

const styles: IStyles = {
    container: {
        backgroundColor: '#eee',
        border: '2px dashed #707070',
        padding: '10px',
        borderRadius: '10px',
        cursor: 'pointer',
        color: '#707070',

    }
}

export default DropZone