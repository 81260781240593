import React, { useEffect, useReducer, useState } from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';
import DeveloperForm from './DeveloperForm';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { generateStyle } from '../../_utils/DefaultStyles';

export interface IState {
    id: string;
    name: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

export interface IErrorState {
    name: string;
}

const useStyles = generateStyle(theme => ({
    actionContainer: {
        display: 'flex'
    },
    addButton: {
        marginBottom: '20px'
    },
}), "Developer_List")

const DeveloperList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/developer';
    const { Root, classes } = useStyles();

    const columns = [
        {
            name: 'name',
            label: 'Name',
            type: 'string',
            filterable: true,
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return <div className={classes.actionContainer}>
                    <Tooltip title="Edit">
                        <IconButton
                            color="primary"
                            component="span"
                            onClick={() => handleEdit(row.id)}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
            }
        }
    ];

    const initialState = {
        id: '',
        name: ''
    };

    const initialErrorState = {
        name: ''
    };

    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [reDrawDT, setReDrawDT] = useState(new Date().getTime());

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    useEffect(() => {
        if (!modalOpen) {
            setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
            setErrorState({ name: '', value: initialErrorState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [modalOpen]);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const handleEdit = (id: string) => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${id}`)
            .then(res => {
                const newState = { ...initialState, ...res.data };
                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
                handleModalOpen();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target;
        let name = target.name;
        let value = target.value;

        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.name) {
            newError.name = 'Nama Developer wajib diisi';
            isValid = false;
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;

        if (inputState.id) {
            axios = DefaultAxios.patch(`${API_URL}/${inputState.id}`, inputState)
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        handleModalClose();
                        setReDrawDT(new Date().getTime());
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />

            <DeveloperForm
                open={modalOpen}
                onClose={handleModalClose}
                state={inputState}
                errorState={errorState}
                onChange={handleChange}
                onSubmit={handleSubmit}
            />

            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={handleModalOpen}
            >
                Add New Developer
            </Button>

            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDT}
            />
        </Root>
    );
}

export default DeveloperList;