import { CircularProgress, MenuItem, Switch, TextField, Theme } from '@mui/material'
import { Sort } from '@mui/icons-material'
import moment from 'moment'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import DataNA from '../../_components/DataNA'
import LoadingCard from '../../_components/_cards/LoadingCard'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../_utils/Helper'
import LeadReportCard from './_components/LeadReportCard'
import DateRangePicker, { DateRange } from '../../_components/_form/DateRangePicker'
import { PermissionContext } from "../../_contexts/PermissionContext";
import { useParams } from 'react-router-dom'
import { isToday, isYesterday } from 'date-fns'
import { generateStyle } from '../../_utils/DefaultStyles'

interface IState {
    startDate: DateRange
    endDate: DateRange
}

interface IClusterData {
    total: number
    recycled: number
}

export type IReportCluster = {
    label: string
    data: IClusterData
}

export interface LeadReportItem {
    [key: string]: any
    id: string
    name: string
    clusters: IReportCluster[]
    total_response_time?: number
    avatar_url: string
}

const formatDate = (date: DateRange) => {
    if (!date) return ''

    return moment(date).format('DD/M/Y')
}

const LeadsReport = () => {
    const { Root, classes } = useStyles()

    const ListRef = useRef<HTMLDivElement>(null)

    const [state, setState] = useState<IState>({
        startDate: moment().subtract(7, 'days'),
        endDate: moment().subtract(1, 'days'),
    })

    const permissions = useContext(PermissionContext);

    const [isFirstInit, setIsFirstInit] = useState(true)
    const [list, setList] = useState<LeadReportItem[]>([])
    const [sort, setSort] = useState('default');
    const [groupId, setGroupId] = useState('default');
    const [groups, setGroups] = useState<{ id: number, name: string }[]>([]);
    const [tableSort, setTableSort] = useState<{
        sort: string,
        order: '' | 'asc' | 'desc'
    }>({
        sort: '',
        order: ''
    })
    const params: any = useParams()

    const [totalAgentActive, setTotalAgentActive] = useState({
        active: 0,
        paused: 0
    })

    const [isDetail, setIsDetail] = useState(false)

    const [flags, setFlags] = useState({
        isInit: true,
        hasMore: true,
        isLoading: true,
        isRefreshing: false,
        isChanged: false,
    })

    const handleDateChanged = (dates: { startDate: DateRange, endDate: DateRange }) => {
        setState(prev => ({
            ...prev,
            ...dates
        }));
    }

    useEffect(() => {
        if (params.group_id) {
            setGroupId(params.group_id)
        }
    }, [params.group_id])

    useEffect(() => {
        fetchData(true)
        fetchGroups()

        return () => {
            setTotalAgentActive(prev => ({
                active: 0,
                paused: 0
            }))
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (flags.isRefreshing) fetchData(true)
        // eslint-disable-next-line 
    }, [flags.isRefreshing])

    useEffect(() => {
        if (!isFirstInit) {
            fetchData(true)
        }
        // eslint-disable-next-line
    }, [sort]);

    useEffect(() => {
        if (!isFirstInit) {
            fetchData(true)
        }
        // eslint-disable-next-line
    }, [groupId]);

    const processClustersData = (data: any) => (
        Object.entries(data).map((value2: any) => ({
            label: value2[0],
            data: value2[1],
        }))
    )

    const fetchGroups = () => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/lead-group/all`)
            .then(res => res.data)
            .then(data => {
                setGroups(data)
            })
            .catch(generalErrorHandler)
    }

    const fetchData = (isFiltered?: boolean) => {
        let initialGroupId: null | string = null

        setFlags(prev => ({
            ...prev,
            isLoading: true,
        }))

        if (isFiltered) {
            ListRef.current?.scrollTo({ top: 0 })
            setList([])
        }

        if (isFirstInit && !params.group_id) {
            const fetchGroupId = localStorage.getItem(`selected_group_leads_report`)

            if (fetchGroupId && fetchGroupId !== 'default') {
                initialGroupId = fetchGroupId
                setGroupId(initialGroupId)
            }
        }

        const data = {
            created_at: (state.startDate || state.endDate) ? `${state.startDate ? formatDate(state.startDate) : ''}-${state.endDate ? formatDate(state.endDate) : ''}` : null,
            date: (state.startDate || state.endDate) ? `${state.startDate ? formatDate(state.startDate) : ''}-${state.endDate ? formatDate(state.endDate) : ''}` : null,
            sort: sort === 'default' ? null : sort,
            group_id: initialGroupId || (groupId !== 'default' ? groupId : null),
        }

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/report-lead`, { params: data })
            .then(res => res.data)
            .then((res: any) => {
                let items: any = []

                items = res.data.map((item: any) => ({
                    id: item.id,
                    name: item.agent_name,
                    total_response_time: item.total_response_time,
                    total_osa_score: item.total_osa_score,
                    clusters: processClustersData(item.cluster)
                }))

                const hasMore = false

                setList(isFiltered ? items : list.concat(items))

                setTotalAgentActive(prev => ({
                    active: res.total_active ? res.total_active : 0,
                    paused: res.total_paused ? res.total_paused : 0
                }))

                setFlags(prev => ({
                    ...prev,
                    isLoading: false,
                    isRefreshing: false,
                    isInit: false,
                    isChanged: false,
                    hasMore,
                }))

                setIsFirstInit(false)
            })
            .catch((err) => {
                generalErrorHandler(err)
            })
    }

    const total = useMemo(() => list.length === 0 ? 0 : list.reduce((prev, current) => prev + (current.clusters.length === 0 ? 0 : current.clusters.reduce((prev1, current1) => prev1 + current1.data.total, 0)), 0), [list])
    const totalRecycled = useMemo(() => list.length === 0 ? 0 : list.reduce((prev, current) => prev + (current.clusters.length === 0 ? 0 : current.clusters.reduce((prev1, current1) => prev1 + current1.data.recycled, 0)), 0), [list])
    const totalAgent = useMemo(() => list.length, [list])

    const ListHeaderComponent = (
        <>
            <div className={classes.selectsContainer}>
                <div>
                    <TextField
                        select
                        variant="outlined"
                        label="Sort"
                        name="sort"
                        value={sort}
                        onChange={(e) => setSort(e.target.value)}
                    >
                        <MenuItem value='default'>Alphabetical</MenuItem>
                        <MenuItem value='least-leads'>Least Leads</MenuItem>
                        <MenuItem value='most-leads'>Most Leads</MenuItem>
                        <MenuItem value='least-responsive'>Least Responsive</MenuItem>
                        <MenuItem value='most-responsive'>Most Responsive</MenuItem>
                    </TextField>
                </div>
                {
                    permissions['report.filter-group'] &&
                    <div style={{ marginLeft: 'auto', minWidth: 100 }}>
                        <TextField
                            select
                            variant="outlined"
                            label="Group"
                            name="group_id"
                            value={groupId}
                            onChange={(e) => {
                                setGroupId(e.target.value)
                                localStorage.setItem(`selected_group_leads_report`, e.target.value)
                            }}
                            fullWidth
                        >
                            <MenuItem value='default'>All</MenuItem>
                            {groups.map(group => <MenuItem key={group.id.toString()} value={group.id.toString()}>{group.name}</MenuItem>)}
                        </TextField>
                    </div>
                }

            </div>
            <div className={classes.date}>
                <div className={classes.switchContainer}>
                    <span className={classes.switchText}>
                        Details
                    </span>
                    <Switch
                        value={isDetail}
                        onChange={e => setIsDetail(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color="primary"
                    />
                </div>
                <div style={{ marginLeft: 'auto' }} className={classes.datePickerContainer}>
                    <DateRangePicker
                        startDate={state.startDate}
                        endDate={state.endDate}
                        onDatesChange={handleDateChanged}
                        format="DD/M/Y"
                        onFinished={() => fetchData(true)}
                        fullWidth
                    />
                </div>
            </div>
        </>
    )

    const sortedList = useMemo(() => {
        switch (tableSort.order) {
            case 'asc':
                return list.sort((a, b) => {
                    let aAmount = a[tableSort.sort]
                    let bAmount = b[tableSort.sort]

                    if (Array.isArray(a[tableSort.sort])) {
                        aAmount = a[tableSort.sort].reduce((prev: any, current: any) => prev + current.data.total, 0)
                        bAmount = b[tableSort.sort].reduce((prev: any, current: any) => prev + current.data.total, 0)
                    }

                    return aAmount - bAmount
                })
            case 'desc':
                return list.sort((a, b) => {
                    let aAmount = a[tableSort.sort]
                    let bAmount = b[tableSort.sort]

                    if (Array.isArray(a[tableSort.sort])) {
                        aAmount = a[tableSort.sort].reduce((prev: any, current: any) => prev + current.data.total, 0)
                        bAmount = b[tableSort.sort].reduce((prev: any, current: any) => prev + current.data.total, 0)
                    }

                    return bAmount - aAmount
                })
            default:
                return list
        }
    }, [list, tableSort])

    return (
        <Root>
            <div className={classes.root}>
                {ListHeaderComponent}
                {
                    isDetail ?
                        <div className={classes.detailRoot}>
                            {
                                flags.isLoading ?
                                    <>
                                        <LoadingCard />
                                        <LoadingCard />
                                        <LoadingCard />
                                    </>
                                    :
                                    list.length === 0 ?
                                        <>
                                            <div />
                                            <DataNA
                                                type="leads"
                                                init={false}
                                                navigate={() => { }}
                                                renderBody={(titleStyle, subTitleStyle) =>
                                                    <>
                                                        <h5 style={titleStyle}>Maaf, lead tidak ditemukan</h5>
                                                        <p style={subTitleStyle}>Silahkan atur rentang tanggal</p>
                                                    </>
                                                }
                                            />
                                        </>
                                        :
                                        list.map(item =>
                                            <LeadReportCard key={item.id} {...item} />
                                        )
                            }
                        </div>
                        :
                        <div className={classes.table}>
                            <div className={`${classes.tableCell} ${classes.tableHeader} ${classes.cellWhite}`} style={{ padding: 0 }}>
                                <span className={`${classes.tableHeaderText}`} style={{ padding: '17px 16px', width: '25%' }}>
                                    Agent
                                </span>
                                <span
                                    className={`${classes.tableHeaderText} ${classes.sortable}`}
                                    style={{ padding: '17px 16px', justifyContent: 'center', width: '25%' }}
                                    onClick={() => {
                                        setTableSort(prev => {
                                            if (prev.sort !== 'total_osa_score') {
                                                return {
                                                    sort: 'total_osa_score',
                                                    order: 'desc',
                                                }
                                            }

                                            return {
                                                sort: 'total_osa_score',
                                                order: prev.order === '' ? 'desc' : prev.order === 'desc' ? 'asc' : ''
                                            }
                                        })
                                    }}
                                >
                                    Score
                                    {
                                        tableSort.sort !== 'total_osa_score' ?
                                            <Sort fontSize='small' className={`sort-icon`} /> :
                                            <Sort fontSize='small' className={`sort-icon ${tableSort.order}`} />
                                    }
                                </span>
                                <span
                                    className={`${classes.tableHeaderText} ${classes.sortable}`}
                                    style={{ padding: '17px 16px', justifyContent: 'center', width: '25%' }}
                                    onClick={() => {
                                        setTableSort(prev => {
                                            if (prev.sort !== 'total_response_time') {
                                                return {
                                                    sort: 'total_response_time',
                                                    order: 'desc',
                                                }
                                            }

                                            return {
                                                sort: 'total_response_time',
                                                order: prev.order === '' ? 'desc' : prev.order === 'desc' ? 'asc' : ''
                                            }
                                        })
                                    }}
                                >
                                    Speed
                                    {
                                        tableSort.sort !== 'total_response_time' ?
                                            <Sort fontSize='small' className={`sort-icon`} /> :
                                            <Sort fontSize='small' className={`sort-icon ${tableSort.order}`} />
                                    }
                                </span>
                                <span
                                    className={`${classes.tableHeaderText} ${classes.right} ${classes.sortable}`}
                                    style={{ padding: '17px 16px', width: '25%', justifyContent: 'flex-end' }}
                                    onClick={() => {
                                        setTableSort(prev => {
                                            if (prev.sort !== 'clusters') {
                                                return {
                                                    sort: 'clusters',
                                                    order: 'desc',
                                                }
                                            }

                                            return {
                                                sort: 'clusters',
                                                order: prev.order === '' ? 'desc' : prev.order === 'desc' ? 'asc' : ''
                                            }
                                        })
                                    }}
                                >
                                    Leads
                                    {
                                        tableSort.sort !== 'clusters' ?
                                            <Sort fontSize='small' className={`sort-icon`} /> :
                                            <Sort fontSize='small' className={`sort-icon ${tableSort.order}`} />
                                    }
                                </span>
                            </div>
                            {
                                flags.isLoading ?
                                    <div className={`${classes.tableLoading} ${classes.cellLightBlue}`}>
                                        <CircularProgress size={32} />
                                    </div>
                                    :
                                    list.length === 0 ?
                                        <div className={`${classes.tableCell} ${classes.cellLightBlue}`}>
                                            <span className={`${classes.cellText}`}>
                                                Maaf, lead tidak ditemukan
                                            </span>
                                        </div>
                                        :
                                        sortedList.map((item, index) =>
                                            <div key={item.id} className={`${classes.tableCell} ${index % 2 === 0 ? classes.cellLightBlue : classes.cellWhite}`}>
                                                <div className={`${classes.cellText}`} style={{ width: '25%' }}>
                                                    {item.name}
                                                </div>
                                                <div
                                                    className={`${classes.cellText}`}
                                                    style={{
                                                        width: '25%',
                                                        textAlign: 'center',
                                                        color: (item.total_osa_score && item.total_osa_score >= 75) ? '#39AF01' : '#AF1500',
                                                    }}
                                                >
                                                    {item.total_osa_score ? item.total_osa_score > 100 ? '100' : item.total_osa_score : 'N/A'}
                                                </div>
                                                <div
                                                    className={`${classes.cellText}`}
                                                    style={{
                                                        width: '25%',
                                                        textAlign: 'center',
                                                        color: (item.total_response_time && item.total_response_time >= 75) ? '#39AF01' : '#AF1500',
                                                    }}
                                                >
                                                    {item.total_response_time ? item.total_response_time : 'N/A'}
                                                </div>
                                                <div className={`${classes.cellText} ${classes.right}`} style={{ width: '25%' }}>
                                                    {item.clusters.reduce((prev, current) => prev + current.data.total, 0)} {permissions['lead-report.see-recycled'] && (item.clusters.reduce((prev, current) => prev + current.data.recycled, 0) ? `(♻️ ${item.clusters.reduce((prev, current) => prev + current.data.recycled, 0)})` : "")}
                                                </div>
                                            </div>
                                        )
                            }
                            <div className={`${classes.tableCell} ${classes.cellDarkBlue} ${classes.cellText}`}>
                                <div>
                                    <span className={`${classes.tableFooterText} ${classes.tableFooterTextLeft}`}>
                                        Total Active ({totalAgent})
                                    </span>
                                    {
                                        state.startDate && state.endDate ?
                                            (isToday(state.startDate.valueOf()) && isToday(state.endDate.valueOf())) || (isYesterday(state.startDate.valueOf()) && isYesterday(state.endDate.valueOf())) ?
                                                <div>
                                                    <span className={`${classes.tableFooterSubtext}`}>
                                                        Not Paused ({totalAgentActive.active}) | Paused ({totalAgentActive.paused})
                                                    </span>
                                                </div>
                                                : null
                                            : null
                                    }
                                </div>
                                {
                                    flags.isLoading ?
                                        <CircularProgress size={16} />
                                        :
                                        <span className={`${classes.tableFooterText} ${classes.right}`}>
                                            {total} {permissions['lead-report.see-recycled'] && (totalRecycled ? `(♻️ ${totalRecycled})` : "")}
                                        </span>
                                }

                            </div>
                        </div>
                }
            </div>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        // backgroundColor: '#DBEAFE4D',
        padding: '0px 16px 32px',
    },
    detailRoot: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        gap: '20px',

        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        },
    },
    switchContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#E9F0F5',
        padding: '6px 20px',
        borderRadius: 10,

        [theme.breakpoints.down('md')]: {
            marginBottom: 16,
        },
    },
    switchText: {
        fontSize: 18,
        lineHeight: 1,
        marginRight: 4,
        fontWeight: 500,
    },
    date: {
        display: 'flex',
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        padding: '16px 8px 8px',
        marginBottom: 20,

        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    dateButton: {
        backgroundColor: "#EBF3F6",
    },
    dateText: {
        color: 'black',
        fontSize: 18,
    },
    footer_root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 16px',
        marginTop: 8,
    },
    footer_leads: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    footer_text: {
        fontSize: 26,
        color: 'black',
        fontWeight: 700,
    },
    footer_label: {
        fontSize: 18,
        color: 'black',
        marginLeft: 8,
    },
    table: {
        boxShadow: '0px 3px 8px #52575D30',
        backgroundColor: 'white',
    },
    tableCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tableLoading: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 130,
    },
    tableHeader: {
        borderBottomColor: theme.palette.primary.main,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
    },
    tableHeaderText: {
        fontWeight: 700,
        fontSize: 16,
        color: theme.palette.primary.main,
    },
    tableFooterText: {
        fontWeight: 700,
        fontSize: 16,
        color: '#363636',
    },
    tableFooterSubtext: {
        fontWeight: 400,
        fontSize: 14,
        color: '#363636',
    },
    tableFooterTextLeft: {
        marginRight: 'auto',
    },
    cellText: {
        fontSize: 16,
        color: '#3D3D3D',
        padding: '17px 16px',
    },
    right: {
        textAlign: 'right',
    },
    canter: {
        textAlign: 'center',
    },
    cellWhite: {
        backgroundColor: 'white',
    },
    cellLightBlue: {
        backgroundColor: '#bcd6eb40',
    },
    cellDarkBlue: {
        backgroundColor: '#035aa633',
    },
    sortable: {
        transition: 'background-color 200ms linear',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',

        '& > .sort-icon': {
            marginLeft: 8,
            color: '#aaaaaa',

            '&.desc': {
                color: 'unset',
            },

            '&.asc': {
                color: 'unset',
                transform: 'rotate(180deg)',
            },
        },

        '&:hover': {
            backgroundColor: '#eeeeee',
        },
    },
    datePickerContainer: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            maxWidth: 220,
        }
    },
    selectsContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
}), "LeadReport"
)

export default LeadsReport