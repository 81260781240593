import React, { useState } from 'react';
import {
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Button,
    Dialog, DialogContent, DialogTitle, DialogActions, Grid, Typography,
} from '@mui/material';

/**
 * Components
 */
import TextArea from '../../../_components/_form/TextArea';

/**
 * Utils
 */
import { format } from 'date-fns';
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generateStyle } from '../../../_utils/DefaultStyles';
import { numberToCurrency } from '../../../_utils/Helpers';
import { renderErrorButton, renderSuccessButton } from '../../../_utils/Helper';

interface Props {
    permissions: any;
    closingId: string;
    data: any;
    onDelete: Function
    agent_name: string
    cobroke_name: string
    is_cobroke: number
}

interface IDetail {
    id: number
    closing_code: string
    agent_id: string
    created_at: string
    type: string
    released_date: string
    released_at: string
    nominal: number
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        width: '100%',
        '& .MuiTextField-root': {
            backgroundColor: '#fff'
        }
    },
    buttonContainer: {
        // justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '16px',
        marginBottom: 8
    },
    buttonContainerDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    buttonContainerMobile: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
}), "PembayaranList");

const PembayaranList = (props: Props) => {
    const { Root, classes } = useStyles();
    // const [agent, setAgent] = useState({
    //     id: '',
    //     label: ''
    // })
    // const [cobrokeAgent, setCobrokeAgent] = useState({
    //     id: '',
    //     label: ''
    // })
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false)
    const [errorReason, setErrorReason] = useState('')
    const [deleteState, setDeleteState] = useState({
        id: '',
        deleted_reason: ''
    })

    // const handleModalDeleteOpen = (id: string) => {
    //     setModalDeleteOpen(true);
    //     setDeleteState({
    //         id: id,
    //         deleted_reason: ''
    //     })
    // }

    const handleModalDeleteClose = () => {
        setModalDeleteOpen(false);
        setDeleteState({
            id: '',
            deleted_reason: ''
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, deletedState?: boolean) => {
        let name = e.target.name;
        let value: any = e.target.value;

        if (deletedState) {
            setDeleteState(prev => ({
                ...prev,
                [name]: value,
            }))
        }
    }

    const handleDelete = () => {
        if (!deleteState.deleted_reason) {
            setErrorReason('Field Deleted Reason is required')
        } else {
            setErrorReason('')
            setModalDeleteOpen(false)
            const data = {
                id: deleteState.id,
                deleted_reason: deleteState.deleted_reason
            }
            DefaultAxios
                .post(`${process.env.REACT_APP_API_URL}/closing-loan/cancel`, data)
                .then(() => {
                    renderSuccessButton('Berhasil Dihapus')
                    .then(() => {
                        props.onDelete();
                    });
                })
                .catch((res) => {
                    renderErrorButton(res.data.message)
                })
        }
    }

    const renderTable = (data: any, name: string, id: string, balance: any, number: number,) => {
        const renderType = (type: string) => {
            let finalType = '';

            switch (type) {
                case 'commission_loan':
                    finalType = 'Pinjaman Komisi'
                    break;
                case 'compliment_client':
                    finalType = 'Compliment Client'
                    break;
                default:
                    finalType = 'Lainnya'
                    break;
            }

            return finalType
        }
        return (
            <>
                <Typography variant="subtitle2" sx={{ ml: 2 }} >Advisor {props.is_cobroke === 1 ? number : ''}: {name}</Typography>
                {/* <Typography variant="subtitle2" sx={{ ml: 2 }} >{balance === false ? 'Harap buat Komisi Developer' : `Saldo Tersedia: Rp ${numberToCurrency(balance)}`} </Typography> */}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Kode Closing</TableCell>
                            <TableCell>Jenis Pinjaman</TableCell>
                            <TableCell>Nominal</TableCell>
                            <TableCell>Tanggal Release Pinjaman</TableCell>
                            {/* <TableCell>Action</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data && data.length > 0 ?
                                data.map((row: IDetail, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell>{row.closing_code}</TableCell>
                                        <TableCell>{renderType(row.type)}</TableCell>
                                        <TableCell>{row.nominal ? `Rp ${numberToCurrency(row.nominal)}` : '-'}</TableCell>
                                        <TableCell>{row.released_at ? format(new Date(row.released_at), 'dd-MMM-yyyy') : '-'}</TableCell>
                                    </TableRow>
                                ))
                                :
                                <TableRow>
                                    <TableCell colSpan={4} align='center'>List tidak ditemukan</TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </>
        )
    }

    return (
        <Root>
            <div className={classes.root}>
                <Dialog
                    open={modalDeleteOpen}
                    onClose={handleModalDeleteClose}
                    fullWidth
                >
                    <DialogTitle>Deleted Reason</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <TextArea
                                    // label="Notes"
                                    name="deleted_reason"
                                    value={deleteState.deleted_reason}
                                    onChange={(e: any) => handleChange(e, true)}
                                    multiline
                                    error={!!errorReason}
                                    helperText={errorReason}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleModalDeleteClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleDelete} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <TableContainer component={Paper} elevation={0}>
                    <Grid sx={{ mb: 4, mt: 2 }}>
                        {renderTable(props.data.loan_lists, props.agent_name, props.data.agent_id, props.data.remaining_balance, 1)}
                    </Grid>
                    {
                        props.is_cobroke === 1 &&
                        <Grid>
                            {renderTable(props.data.loan_lists_2, props.cobroke_name, props.data.cobroke_agent_id, props.data.remaining_balance_2, 2)}
                        </Grid>
                    }
                </TableContainer>
            </div>
        </Root>
    );
}

export default PembayaranList;