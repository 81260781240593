import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, Button, Grid } from '@mui/material'
import TextArea from '../../../_components/_form/TextArea'

interface IProps {
    open: boolean,
    onClose: () => void
    onSubmit: (reason: string) => void
}

const RejectReasonModal = (props: IProps) => {
    const [reason, setReason] = useState('')

    useEffect(() => {
        return () => setReason('')
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>Alasan Reject</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <TextArea
                            value={reason}
                            name='reason'
                            rows={3}
                            onChange={(e: any) => setReason(e.target.value)}
                            label="Reason"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            style={{ float: 'right' }}
                            disabled={!reason}
                            onClick={() => [props.onClose(), props.onSubmit(reason)]}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default RejectReasonModal