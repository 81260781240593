import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { Grid, ButtonGroup, Button, Theme } from '@mui/material';

/**
 * Components
 */
import ClusterDetailForm from './ClusterDetailForm';
import ClusterCustomPage from './ClusterCustomPage';
import ImageFormPage from '../../_components/_form/ImageForm/Page';
import FAQForm from '../../_components/_form/FAQForm';
import ReviewForm from '../../_components/_form/ReviewForm';
import NewArticleForm from '../../_components/_form/NewArticleForm';
import SLPForm from '../../_components/_form/SLPForm/SLPForm';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { SnackbarProvider } from 'notistack';
import helper from '../../_utils/Helpers';
import { generateStyle } from '../../_utils/DefaultStyles';
interface Props {
    permissions?: any;
}

const useStyles = generateStyle((theme: Theme) => ({
    success: {
        backgroundColor: '#033a11'
    },
    error: {
        backgroundColor: '#c62828'
    },
    borderBottomLine: {
        borderBottom: '1px solid #eee'
    },
    fab: {
        position: 'fixed',
        bottom: '2rem',
        right: '2rem',
        display: 'none',
        zIndex: 1,
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    container: {
        paddingBottom: '50px',
        [theme.breakpoints.up('md')]: {
            width: 'calc(100% + 48px)',
        }
    },
    whiteBackground: {
        backgroundColor: 'white',
    },
    rightPadding: {
        paddingRight: 24,
    },
}), "Cluser_Formx"
);

const ClusterForm = (props: Props) => {
    const { Root, classes } = useStyles();
    const location = useLocation();
    const params: any = useParams();
    const navigate = useNavigate();
    const [clusterState, setClusterState] = useState<{ name: string, slug: string, is_custom_page: number, page_type: number }>({ name: "", slug: "", is_custom_page: 0, page_type: 0 });

    const [type, setType] = useState(typeof params.type !== 'undefined' ? params.type : 'details');

    useEffect(() => {
        if (params.id) {
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/cluster/title-data`, { params: { id: params.id } })
                .then(res => res.data)
                .then(data => {
                    setClusterState({
                        name: data.name,
                        slug: data.slug,
                        is_custom_page: data.is_custom_page,
                        page_type: data.page_type
                    });
                })
                .catch(error => {
                    helper.generalErrorHandler(error)
                })
        }
    }, [params.id])

    const renderForm = () => {
        if (params.id) {
            switch (type) {
                case 'details':
                    return <ClusterDetailForm
                        clusterId={params.id}
                        mode="edit"
                    />
                case 'image':
                    return <ImageFormPage
                        API_URL={`${process.env.REACT_APP_API_URL}/cluster/${params.id}/image`}
                    />
                // case '360-photo':
                //     return <Photo360FormPage
                //         API_URL={`${process.env.REACT_APP_API_URL}/cluster/${params.id}/360-photo`}
                //     />
                case 'custom-page':
                    return <ClusterCustomPage
                        clusterId={params.id}
                        clusterSlug={clusterState.slug}
                        customPageType={1}
                    />
                case 'blog':
                    return <ClusterCustomPage
                        clusterId={params.id}
                        clusterSlug={clusterState.slug}
                        customPageType={2}
                    />
                case 'faq':
                    return <FAQForm
                        id={params.id}
                        type="cluster"
                    />
                case 'article':
                    return (
                        <NewArticleForm
                            id={params.id}
                            url={`${process.env.REACT_APP_API_URL}/article-default`}
                        />
                    )
                case 'review':
                    return <ReviewForm
                        id={params.id}
                        type="cluster"
                    />
                case 'slp':
                    return <SLPForm
                        id={params.id}
                        type='cluster'
                        slug={clusterState.slug}
                    />
            }
        } else {
            return <ClusterDetailForm
                clusterId={null}
                mode="add"
            />
        }
    }

    const handleNavigation = (route: string) => {
        if (route !== type) {
            if (route === 'details') {
                navigate(`/cluster/edit/${params.id}`);
            } else {
                navigate(`/cluster/edit/${params.id}/${route}`);
            }
            setType(route);
        }
    }

    return (
        <Root>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                autoHideDuration={1000}
                disableWindowBlurListener
                hideIconVariant
                classes={{
                    variantSuccess: classes.success,
                    variantError: classes.error
                }}
                onEntered={() => {
                    window.navigator.vibrate([200, 100, 200])
                }}
            >
                <Grid container spacing={3} className={classes.container}>
                    <Grid item xs={12} className={`${classes.whiteBackground} ${classes.rightPadding}`}>
                        <h1 className={classes.borderBottomLine}>Cluster - {location.pathname === '/cluster/add' ? 'Add' : clusterState.name}</h1>
                    </Grid>

                    {
                        location.pathname !== '/cluster/add'
                            ? <Grid item xs={12} className={`${classes.whiteBackground} ${classes.rightPadding}`}>
                                <ButtonGroup
                                    color="primary"
                                    aria-label="outlined primary button group"
                                    fullWidth
                                >
                                    <Button
                                        variant={type === 'details' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('details')}
                                    >
                                        Details
                                    </Button>
                                    <Button
                                        variant={type === 'image' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('image')}
                                    >
                                        Images
                                    </Button>
                                    {/* <Button
                                    variant={type === '360-photo' ? 'contained' : 'outlined'}
                                    onClick={() => handleNavigation('360-photo')}
                                >
                                    360 Photo
                                </Button> */}
                                    <Button
                                        variant={type === 'faq' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('faq')}
                                    >
                                        FAQ
                                    </Button>
                                    <Button
                                        variant={type === 'review' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('review')}
                                    >
                                        Review
                                    </Button>
                                    <Button
                                        variant={type === 'slp' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('slp')}
                                    >
                                        SLP
                                    </Button>
                                    <Button
                                        variant={type === 'custom-page' ? 'contained' : 'outlined'}
                                        onClick={() => handleNavigation('custom-page')}
                                    >
                                        Custom Page
                                    </Button>
                                    {clusterState.page_type === 1 ?
                                        <Button
                                            variant={type === 'blog' ? 'contained' : 'outlined'}
                                            onClick={() => handleNavigation('blog')}
                                        >
                                            Blog Article
                                        </Button>
                                        :
                                        <Button
                                            variant={type === 'article' ? 'contained' : 'outlined'}
                                            onClick={() => handleNavigation('article')}
                                        >
                                            Article
                                        </Button>
                                    }
                                </ButtonGroup>
                            </Grid>
                            : null
                    }
                    <Grid item xs={12} className={classes.whiteBackground} style={{ height: 16 }} />
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {renderForm()}
                        </Grid>
                    </Grid>
                </Grid>
            </SnackbarProvider>
        </Root>
    );
}

export default ClusterForm;