import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import helpers from '../../_utils/Helpers';
import { z } from 'zod'
import { ErrorType, getFormError, zodValidate } from '../../_utils/ZodHelper';

// Icons
import AddIcon from '@mui/icons-material/Add';
import { generateStyle } from '../../_utils/DefaultStyles';

const stateSchema = z.object({
    version: z
        .string()
        .min(1, { message: "Version is required" }),
    notes: z.string(),
    apk: z
        .any()
        .refine(file => file !== null, { message: 'APK File is required' }),
})

type State = z.infer<typeof stateSchema>

const ReleaseAppList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/release-app';
    const {Root, classes} = useStyles();

    const columns = [
        {
            name: 'version',
            label: 'Versi',
            type: 'string',
            filterable: false,
            sortable: false
        },
        {
            name: 'increment',
            label: 'Release',
            type: 'string',
            filterable: false,
            sortable: false
        },
        {
            name: 'notes',
            label: 'Notes',
            type: 'string',
            filterable: false,
            sortable: false
        },
    ];

    const [reDrawDatatable, setReDrawDatatable] = useState(0)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState<State>(initialState)
    const [error, setError] = useState<ErrorType<State>>({})

    useEffect(() => {
        if (!isModalOpen) {
            setState(initialState)
            setError({})
        }
        // eslint-disable-next-line
    }, [isModalOpen]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const name = target.name;
        let value = target.value;

        if (target.files && target.files[0]) {
            const file = target.files[0];

            setState(prev => ({
                ...prev,
                apk: file,
            }))
        } else {
            setState(prev => ({
                ...prev,
                [name]: value,
            }))
        }

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleSubmit = () => {
        zodValidate(stateSchema, state,
            () => {
                setError({})

                const fd = new FormData();
                fd.append('version', state.version);
                if (state.notes !== '') {
                    fd.append('notes', state.notes);
                }

                if (state.apk) {
                    fd.append('apk', state.apk);
                }

                setIsLoading(true);
                DefaultAxios.post(API_URL, fd)
                    .then(res => {
                        Swal.fire({
                            title: "APK submitted",
                            icon: 'success',
                            onAfterClose: () => {
                                setReDrawDatatable(prev => prev + 1);
                                setIsModalOpen(false)
                            },
                            timer: 1000
                        })
                    })
                    .catch(err => {
                        helpers.generalErrorHandler(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            },
            setError
        )
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() => {
                    setIsModalOpen(true)
                }}
            >
                Add New App Release
            </Button>
            <Dialog
                open={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false)
                }}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Add New App Release</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} style={{ marginTop: 0 }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Versi"
                                name="version"
                                value={state.version}
                                onChange={handleChange}
                                error={getFormError(error.version) !== ''}
                                helperText={getFormError(error.version)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Notes"
                                name="notes"
                                value={state.notes}
                                onChange={handleChange}
                                error={getFormError(error.notes) !== ''}
                                helperText={getFormError(error.notes)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <div style={{ marginBottom: 4, fontSize: 14, fontWeight: '500' }}>APK File</div>
                            <input type="file" name="apk" accept='.apk' onChange={handleChange} />
                            {
                                getFormError(error.apk) !== '' &&
                                <>
                                    <br />
                                    <span style={{ fontSize: 12, color: 'red' }}>{getFormError(error.apk)}</span>
                                </>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </Root>
    );
}

const initialState = {
    version: '',
    notes: '',
    apk: null,
};

const useStyles = generateStyle(theme => ({
    actionContainer: {
        display: 'flex'
    },
    addButton: {
        marginBottom: '20px'
    },
    switchContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
}), "ReleaseApp_List")

export default ReleaseAppList;