import React from "react";
import { MenuItem, TextField, Grid } from "@mui/material";
import { GroupItem, MonthYearOption } from "../EnquiryReport";

interface FilterDateProps {
	selectedFilter: "daily" | "weekly" | "monthly";
	setSelectedFilter: (filter: "daily" | "weekly" | "monthly") => void;
	selectedMonth: MonthYearOption;
	dateYearOptions: MonthYearOption[];
	handleSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleGroup: (e: React.ChangeEvent<HTMLInputElement>) => void;
	valueGroup: string | number | null;
	dataGroup: GroupItem[];
}

const FilterDate: React.FC<FilterDateProps> = ({
	selectedFilter,
	setSelectedFilter,
	selectedMonth,
	dateYearOptions,
	handleSelect,
	dataGroup,
	handleGroup,
	valueGroup,
}) => {

	return (
		<Grid container spacing={2} marginTop={{ xs: 1, sm: 2, md: -1, lg: -1 }} marginBottom={4}>
			<Grid item xs={12} sm={2} >
				<TextField
					fullWidth
					select
					label="Breakdown"
					variant="outlined"
					name="breakdown"
					value={selectedFilter}
					onChange={(e) =>
						setSelectedFilter(e.target.value as "daily" | "weekly" | "monthly")
					}
				>
					<MenuItem value="daily">Daily</MenuItem>
					<MenuItem value="weekly">Weekly</MenuItem>
					<MenuItem value="monthly">Monthly</MenuItem>
				</TextField>
			</Grid>
			<Grid item xs={12} sm={2} >
				<TextField
					fullWidth
					select
					label="Time"
					variant="outlined"
					name="selectedMonth"
					value={selectedMonth.display}
					onChange={handleSelect}
				>
					{dateYearOptions.map((dateYear) => (
						<MenuItem key={dateYear.display} value={dateYear.display}>
							{dateYear.display}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid item xs={false} sm={6} />
			<Grid item xs={12} sm={2} alignContent="end" alignSelf="end" justifyContent="flex-end" marginTop={-2}>
				<TextField
					fullWidth
					select
					label="Group"
					variant="outlined"
					name="group"
					value={valueGroup === null ? "default" : valueGroup}
					onChange={handleGroup}
				>
					<MenuItem value="default">All</MenuItem>
					{dataGroup.length > 0 &&
						dataGroup.map((grup) => (
							<MenuItem key={grup?.id} value={grup?.id}>
								{grup?.name}
							</MenuItem>
						))}
				</TextField>
			</Grid>
		</Grid>
	);
};



export default FilterDate;
