import React, { useContext, useEffect, useState } from 'react'
import { Grid, Button, Chip, capitalize } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

/**
 * Componenst
*/
import DataTable from '../../_components/_dataTable/DataTable'
import ReimbursementForm from './ReimbursementForm'
import LoadingScreen from '../../_components/LoadingScreen'
import ReimbursementModalDetail from './ReimbursementModalDetail'
import CheckProcessedModal from './_components/CheckProcessedModal'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { convertNumber, generalErrorHandler, renderWarningButton } from '../../_utils/Helper'
import Swal from 'sweetalert2'
import { format } from 'date-fns'

/**
 * Contexts
 */
import { PermissionContext } from '../../_contexts/PermissionContext'


const statusOptions = [
    { value: 'Draft', key: 'draft' },
    { value: 'Requested', key: 'requested' },
    { value: 'Approved', key: 'approved' },
    { value: 'Processed', key: 'processed' },
    { value: 'Released', key: 'released' },
    { value: 'Rejected', key: 'rejected' },
]

const ReimbursementList = () => {
    const [reDrawDT, setReDrawDT] = useState(new Date().getTime())

    const permissions = useContext(PermissionContext)

    const [addFormModal, setAddFormModal] = useState(false)
    const [processedModal, setProcessedModal] = useState(false)
    const [reimbursementCode, setReimbursementCode] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    const params = useParams<{ code: string }>()

    const column = [
        {
            name: 'code',
            label: 'Kode',
            type: 'string',
            filterable: true,
            render: (data: any, row: any) => {
                return (
                    // <Link
                    //     to={`/reimbursement/${data}`}
                    // >
                    //     {data}
                    // </Link>
                    <span onClick={() => { setReimbursementCode(row.code); window.history.pushState('', '', `/reimbursement/${row.code}`); }} style={{ cursor: 'pointer', color: 'rgb(0, 86, 168)' }}>{data}</span>
                )
            }
        },
        {
            name: 'date',
            label: 'Tanggal',
            type: 'date',
            filterable: true,
            render: (data: string) => {
                return format(new Date(data), 'dd/MM/yyyy')
            }

        },
        {
            name: 'status',
            label: 'Status',
            type: 'options',
            filterable: true,
            options: [
                { value: 'All', key: '' },
                ...statusOptions
            ],
            render: (data: string) => {
                return renderChip(data)
            }
        },
        {
            name: 'total',
            label: 'Total',
            type: 'number',
            filterable: true,
            render: (data: any) => {
                return `Rp. ${convertNumber(`${data}`)}`
            }
        },
        {
            name: 'requested_by_name',
            label: 'Requested By',
            type: 'string',
            filterable: true
        },
        {
            name: 'approved_by_name',
            label: 'Approved By',
            type: 'string',
            filterable: true
        },
        {
            name: 'processed_by_name',
            label: 'Processed By',
            type: 'string',
            filterable: true
        },
    ]

    const handleAddItem = () => {
        setAddFormModal(true)
    }

    const handleCheckRelease = (proof: File) => {
        renderWarningButton('Apakah anda yakin untuk menandai release processed?')
            .then(res => {
                if (res.value) {
                    setIsLoading(true)

                    let fd = new FormData()
                    fd.append('proof', proof)

                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/reimbursement/release`, fd)
                        .then(res => res.data)
                        .then(res => {
                            Swal.fire('Success', 'Processed reimbursement released', 'success')
                            setProcessedModal(false)
                            setReDrawDT(new Date().getTime())
                        })
                        .catch(err => generalErrorHandler(err))
                        .finally(() => setIsLoading(false))

                }
            })

    }

    const handleExport = () => {
        renderWarningButton('Apakah anda yakin untuk meng-export data ?')
            .then(res => {
                if (res.value) {
                    setIsLoading(true)
                    const URL = `${process.env.REACT_APP_API_URL}/reimbursement/export`
                    DefaultAxios.get(URL, { responseType: 'blob' })
                        .then((res) => {
                            const url = window.URL.createObjectURL(new Blob([res.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `Reimbursement.xls`);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        })
                        .catch(err => {
                            generalErrorHandler(err)
                        })
                        .finally(() => setIsLoading(false))
                }
            })
    }

    const handleAddReimburesement = (date: string) => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/reimbursement`, {
            date: date
        })
            .then(res => res.data)
            .then(res => {
                if (res) {
                    Swal.fire('Success', 'Reimbursement Added!', 'success')
                    setAddFormModal(false)
                    setReDrawDT(new Date().getTime())
                }
            })
            .catch(err => generalErrorHandler(err))
            .finally(() => setIsLoading(false))
    }

    const renderChip = (status: string) => {
        let colorChip: 'default' | 'primary' | 'success' | 'error' = 'default'
        switch (status) {
            case 'draft':
                colorChip = 'default'
                break;
            case 'requested':
                colorChip = 'default'
                break;
            case 'approved':
                colorChip = 'primary'
                break;
            case 'processed':
                colorChip = 'primary'
                break;
            case 'released':
                colorChip = 'success'
                break;
            case 'rejected':
                colorChip = 'error'
                break;
        }
        return (
            <Chip
                variant="filled"
                color={status !== 'requested' ? colorChip : "default"}
                label={capitalize(status)}
                style={status === 'requested' ? { backgroundColor: '#F5E8C4' } : {}}
            />
        )
    }

    useEffect(() => {
        if (params.code) {
            setReimbursementCode(params.code);
        } else {
            setReimbursementCode("");
        }
    }, [params.code]);

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />

            <ReimbursementForm
                open={addFormModal}
                onClose={() => {
                    setAddFormModal(false)
                    setReDrawDT(new Date().getTime())
                }}
                handleSubmit={(date) => handleAddReimburesement(date)}
            />

            <CheckProcessedModal
                open={processedModal}
                onClose={() => setProcessedModal(false)}
                onSubmit={handleCheckRelease}
            />

            {
                reimbursementCode &&
                <ReimbursementModalDetail
                    open={reimbursementCode !== ""}
                    reimbursementCode={reimbursementCode}
                    onClose={(refresh) => {
                        setReimbursementCode("")
                        navigate('/reimbursement')
                        window.history.pushState({}, 'system reimbursement', '/reimbursement')

                        if (refresh) {
                            setReDrawDT(new Date().getTime())
                        }
                    }}
                />
            }

            <Grid container>
                <Grid item xs={12} sx={{ py: 1, display: 'flex', gap: 1 }}>
                    {
                        permissions['reimbursement.create'] ?
                            <Button
                                variant='contained'
                                href=''
                                onClick={handleAddItem}
                            >
                                Add Data
                            </Button>
                            : null
                    }
                    {
                        permissions["reimbursement.release"] ?
                            <>
                                <Button
                                    variant='contained'
                                    href=''
                                    sx={{ ml: 'auto' }}
                                    onClick={() => setProcessedModal(true)}
                                >
                                    Tandai Release Processed
                                </Button>
                                <Button
                                    variant='contained'
                                    href=''
                                    onClick={handleExport}
                                >
                                    Export Processed
                                </Button>
                            </>
                            : null
                    }
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        url={`${process.env.REACT_APP_API_URL}/reimbursement`}
                        reDraw={reDrawDT}
                        columns={column}
                        customParamsInQueryParam
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default ReimbursementList