import React from 'react';
import { FormControl, MenuItem, Select, Theme } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Utils
 */
import { inputAgentMts } from '../../../_utils/ConstData';
import { generalErrorHandler, renderWarningButton } from '../../../_utils/Helper';
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generateStyle } from '../../../_utils/DefaultStyles';

interface Props {
    id: number;
    mts: string;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    submitCallback: () => void
}

const useStyles = generateStyle((theme: Theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: "100%",
    },
}), "InputAgentMts_Select"
);

const InputAgentMtsSelect = (props: Props) => {
    const { Root, classes } = useStyles();

    const handleInputAgentMtsChange = (id: number, mts: string) => {
        renderWarningButton("Apakah anda yakin ingin mengganti mts ini?")
            .then((result) => {
                if (result.value) {
                    props.setIsLoading(true);
                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/lead/change-mts`, { lead_id: id, mts })
                        .then(res => {
                            Swal.fire({
                                title: "Berhasil mengubah mts",
                                icon: 'success',
                                timer: 1000,
                                onAfterClose: props.submitCallback
                            });
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            props.setIsLoading(false);
                        })

                }
            })
    }

    return (
        <Root>
            <FormControl className={classes.formControl}>
                <Select
                    variant="outlined"
                    value={props.mts}
                    onChange={(e) => handleInputAgentMtsChange(props.id, e.target.value as string)}
                    autoWidth
                >
                    {inputAgentMts.map(data => {
                        return (
                            <MenuItem value={data.key} key={data.key}>{data.value}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Root>
    );
}

export default InputAgentMtsSelect;