import React, { useState, useReducer, useRef, RefObject, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

// Material UI
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link, FormGroup, Paper } from '@mui/material';
// import Autocomplete from '@mui/material/Autocomplete';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import helpers from '../../_utils/Helpers';
import { inputNumber, renderWarningButton } from '../../_utils/Helper';
import Collapsible from '../../_components/Collapsible';
import MultiItemForm from '../../_components/_form/MultiItemForm';
import { generateStyle } from '../../_utils/DefaultStyles';

interface Props {
    clusterId: string | null;
    mode: 'add' | 'edit';
}

interface IState {
    code: string
    form_id: number | null;
    developer_id: string;
    developer_name: string;
    district_id: string;
    district_name: string;
    kota_mandiri_id: string;
    kota_mandiri_name: string;
    kawasan_id: string;
    kawasan_name: string;
    name: string;
    slug: string;
    category: string;
    address: string;
    latitude: string;
    longitude: string;
    meta_title: string;
    meta_description: string;
    wa_pretext: string;
    lowest_price: string;
    highest_price: string;
    min_bedroom: string;
    max_bedroom: string;
    f_pool: boolean;
    f_club_house: boolean;
    f_gym: boolean;
    f_tennis_court: boolean;
    f_basket_court: boolean;
    f_jogging_track: boolean;
    f_security: boolean;
    f_playground: boolean;
    tags: any;
    article: string;
    promotion_banner_filepath: {
        name: string;
        file: File | null;
    },
    promotion_banners: {
        id: string;
        caption: string;
        image: string;
    }[],
    level: string;
    group_id: string;
    group_name: string;
    promos: string[]
    payment_types: string[]
}

interface IErrorState {
    developer_id: string;
    district_id: string;
    name: string;
    slug: string;
    address: string;
    latitude: string;
    longitude: string;
    promotion_banner_caption: string;
    group_id: string;
    promos: string[]
    payment_types: string[]
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IRefs {
    [key: string]: RefObject<HTMLInputElement>
}

const useStyles = generateStyle((theme) => ({
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    collapse: {
        paddingLeft: '12px',
        paddingRight: '12px',
        width: '100%',
        '& .MuiCollapse-wrapper': {
            paddingTop: '12px',
            paddingBottom: '12px'
        }
    },
    formContainer: {
        marginTop: '10px',
        marginLeft: '20px',
        marginRight: '20px',
        display: "flex"
    },
    fileList: {
        listStyle: 'none',
        padding: 0,
        '& li': {
            display: 'flex'
        }
    },
    filePreview: {
        width: '65px',
        height: '65px',
        overflow: 'hidden',
        float: 'left',
        '& img': {
            width: '100%'
        }
    },
    fileDetail: {
        width: '100%',
        marginLeft: '10px',
        '& p': {
            margin: 0
        }
    }
}), "ClusterDetail_Form"
);

const ClusterDetailForm = (props: Props) => {
    const { Root, classes } = useStyles()
    const API_URL = process.env.REACT_APP_API_URL + '/cluster';
    const params: any = useParams();
    const navigate = useNavigate();

    const booleanAttributes = [
        'f_pool',
        'f_club_house',
        'f_gym',
        'f_tennis_court',
        'f_basket_court',
        'f_jogging_track',
        'f_security',
        'f_playground',
    ];

    const refs: IRefs = {
        developer_id: useRef<HTMLInputElement>(null),
        district_id: useRef<HTMLInputElement>(null),
        name: useRef<HTMLInputElement>(null),
        address: useRef<HTMLInputElement>(null),
        latitude: useRef<HTMLInputElement>(null),
        longitude: useRef<HTMLInputElement>(null),
        promotion_banner_caption: useRef<HTMLInputElement>(null),
        group_id: useRef<HTMLInputElement>(null),
    }

    const initialState = {
        code: '',
        form_id: 0,
        developer_id: '',
        developer_name: '',
        district_id: '',
        district_name: '',
        kota_mandiri_id: '',
        kota_mandiri_name: '',
        kawasan_id: '',
        kawasan_name: '',
        name: '',
        slug: '',
        category: '',
        address: '',
        latitude: '',
        longitude: '',
        meta_title: '',
        meta_description: '',
        wa_pretext: '',
        lowest_price: '',
        highest_price: '',
        min_bedroom: '',
        max_bedroom: '',
        f_pool: false,
        f_club_house: false,
        f_gym: false,
        f_tennis_court: false,
        f_basket_court: false,
        f_jogging_track: false,
        f_security: false,
        f_playground: false,
        tags: [],
        article: '',
        promotion_banner_filepath: {
            name: '',
            file: null
        },
        promotion_banners: [],
        level: '',
        group_id: '',
        group_name: '',
        promos: [],
        payment_types: [],
    };

    const initialErrorState = {
        developer_id: '',
        district_id: '',
        name: '',
        slug: '',
        address: '',
        latitude: '',
        longitude: '',
        promotion_banner_caption: '',
        group_id: '',
        promos: [],
        payment_types: [],
    };

    // Input Reducer
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    // Error Reducer
    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);
    // const [projectList, setProjectList] = useState<Array<IListProject>>([]);
    // const [tagList, setTagList] = useState<Array<IListTag>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [warningImage, setWarningImage] = useState(false);

    useEffect(() => {
        if (params.id) {
            loadData();
        } else {
            loadFormData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${params.id}`)
            .then(res => {
                const resData = res.data;
                const newState: any = {};

                for (let [key, value] of Object.entries(resData)) {
                    if (booleanAttributes.includes(key)) {
                        newState[key] = !!value;
                    } else {
                        newState[key] = value;
                    }
                }

                newState.promotion_banner_filepath = {
                    name: '',
                    file: null
                };

                newState.promos = resData.promos || []
                newState.payment_types = resData.payment_types || []
                loadFormData(newState);
                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const loadFormData = (state?: IState) => {
        // DefaultAxios.get(`${process.env.REACT_APP_API_URL}/cluster/form-data`)
        //     .then(res => {
        //         setProjectList(res.data.project);
        //         // setTagList(res.data.tags);
        //     });
    }

    const scrollToRef = (ref: RefObject<HTMLInputElement>) => {
        if (ref.current) window.scrollTo({ top: ref.current.offsetTop - 64, behavior: 'smooth' });
    }

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        if (name === 'promotion_banner_file') {
            const oldBanner = { ...inputState.promotion_banner_filepath };
            if (target.files && target.files[0]) {
                oldBanner.file = target.files[0];
                checkImageDimension(target.files[0]);
            } else {
                oldBanner.file = null;
                setWarningImage(false);
            }
            value = oldBanner;
            name = 'promotion_banner_filepath';
        } else if (name === 'promotion_banner_caption') {
            const oldBanner = { ...inputState.promotion_banner_filepath };
            oldBanner.name = target.value;
            value = oldBanner;
            name = 'promotion_banner_filepath';
        } else if (name === 'lowest_price' || name === 'highest_price') {
            value = value ? helpers.currencyToNumber(value) : '';
        } else if (name === 'min_bedroom' || name === 'max_bedroom') {
            value = value ? inputNumber(value) : ''
        } else if (name === 'form_id') {
            value = value ? helpers.currencyToNumber(value) : null;
        }

        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name, value: value.id, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const checkImageDimension = (file: File) => {
        let img = new Image();
        var objectUrl = URL.createObjectURL(file);
        img.onload = (e: { target: any }) => {
            if (e.target) {
                setWarningImage(+e.target.width !== 460 || +e.target.height !== 500);
                URL.revokeObjectURL(objectUrl);
            }
        };
        img.src = objectUrl;
    }

    const checkValidation = () => {
        let isValid = true;
        let firstError = '';
        const newError = { ...initialErrorState } as IErrorState;

        if (!inputState.group_id) {
            if (!firstError) firstError = 'group_id';
            newError.group_id = 'Cluster Leads Group wajib diisi';
            isValid = false;
        }

        if (!inputState.developer_id) {
            if (!firstError) firstError = 'developer_id';
            newError.developer_id = 'Developer wajib diisi';
            isValid = false;
        }

        if (!inputState.district_id) {
            if (!firstError) firstError = 'district_id';
            newError.district_id = 'District wajib diisi';
            isValid = false;
        }

        if (!inputState.name) {
            if (!firstError) firstError = 'name';
            newError.name = 'Name wajib diisi';
            isValid = false;
        }

        if (!inputState.latitude) {
            if (!firstError) firstError = 'latitude';
            newError.latitude = 'Latitude wajib diisi';
            isValid = false;
        } else if (!helpers.isValidLatLng(inputState.latitude)) {
            if (!firstError) firstError = 'latitude';
            newError.latitude = 'Format latitude tidak valid';
            isValid = false;
        }

        if (!inputState.longitude) {
            if (!firstError) firstError = 'longitude';
            newError.longitude = 'Longitude wajib diisi';
            isValid = false;
        } else if (!helpers.isValidLatLng(inputState.longitude)) {
            if (!firstError) firstError = 'longitude';
            newError.longitude = 'Format longitude tidak valid';
            isValid = false;
        }

        if (inputState.promotion_banner_filepath.file && !inputState.promotion_banner_filepath.name) {
            newError.promotion_banner_caption = 'Promotion Banner Caption harus diisi apabila ingin menambahkan Promotion Banner';
            isValid = false;
            if (!firstError) firstError = 'promotion_banner_caption';
        } else {
            newError.promotion_banner_caption = '';
        }

        if (inputState.promos.length > 0) {
            inputState.promos.forEach((promo, index) => {
                if (!promo) {
                    isValid = false
                    newError.promos[index] = "Promo harus diisi"
                }
            })
        }

        if (inputState.payment_types.length > 0) {
            inputState.payment_types.forEach((promo, index) => {
                if (!promo) {
                    isValid = false
                    newError.payment_types[index] = "Cara bayar harus diisi"
                }
            })
        }

        if (firstError) scrollToRef(refs[firstError]);
        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });

        return isValid;
    }

    const facilities = {
        'f_pool': 'Pool',
        'f_club_house': 'Clubhouse',
        'f_gym': 'Gym',
        'f_tennis_court': 'Tennis Court',
        'f_basket_court': 'Basket Court',
        'f_jogging_track': 'Jogging Track',
        'f_security': 'Security',
        'f_playground': 'Playground',
    }

    const handleChangeFacilities = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const name = target.name;
        let value: any = target.checked;

        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }


    const renderOptions = (odd: boolean) => {
        var rows: any = [];
        let i = 0;

        const orderedFacilities: { [k: string]: string } = Object.entries(facilities)
            .sort(([, a], [, b]) => (a < b) ? -1 : (a > b) ? 1 : 0)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

        for (let [key, value] of Object.entries(orderedFacilities)) {
            const condition = odd ? i % 2 !== 0 : i % 2 === 0;
            if (condition) {
                let checked = false;
                let key_fac = key;
                for (let [key, value] of Object.entries(inputState)) {
                    if (key_fac === key) {
                        checked = value as boolean;
                    }
                }
                rows.push(<div style={{ display: "block", width: "100%", marginLeft: '20px' }} key={key}><FormControlLabel
                    control={<Checkbox
                        checked={checked}
                        onChange={handleChangeFacilities}
                        name={key} />}
                    label={value}
                /></div>)
            }
            i++;
        }
        return rows;
    }

    // const renderOptionsApartment = (leftOver: number) => {
    //     var rows: any = [];
    //     let i = 0;

    //     const orderedFacilities: { [k: string]: string } = Object.entries(apartmentFacilities)
    //         .sort(([, a], [, b]) => (a < b) ? -1 : (a > b) ? 1 : 0)
    //         .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    //     for (let [key, value] of Object.entries(orderedFacilities)) {
    //         const condition = i % 3 === leftOver;
    //         if (condition) {
    //             let checked = false;
    //             let key_fac = key;
    //             for (let [key, value] of Object.entries(inputState)) {
    //                 if (key_fac === key) {
    //                     checked = value as boolean;
    //                 }
    //             }
    //             rows.push(<div key={key} style={{ display: "block", width: "100%", marginLeft: '20px' }}><FormControlLabel
    //                 control={<Checkbox
    //                     checked={checked}
    //                     onChange={handleChangeFacilities}
    //                     name={key} />}
    //                 label={value}
    //             /></div>)
    //         }
    //         i++;
    //     }
    //     return rows;
    // }

    // const handleChangeTag = (value: any)  => {
    //     setInputState({ name: 'tags', value: value, type: 'SET_ITEM' });
    // }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;
        const data: any = { ...inputState };
        const fd = new FormData();

        new Promise((resolve, _) => {
            if (!data.kota_mandiri_id || !data.kawasan_id) {
                renderWarningButton("Anda tidak mengisi Kawasan / Kota Mandiri, lanjutkan?")
                    .then((result) => {
                        resolve(result.value);
                    })
            } else {
                resolve(true)
            }
        })
            .then(result => {
                if (result) {
                    // if (data.category !== 'apartment') {
                    //     for (let key in apartmentFacilities) {
                    //         data[key] = false;
                    //     }
                    // }

                    for (let [key, value] of Object.entries(data)) {
                        switch (key) {
                            case 'promotion_banner_filepath':
                                if (data.promotion_banner_filepath.file) {
                                    fd.append('promotion_banner_filepath', data.promotion_banner_filepath.file);
                                    fd.append('promotion_banner_filename', data.promotion_banner_filepath.name);
                                }
                                break
                            case 'promos':
                            case 'payment_types':
                                (value as string[]).forEach(element => {
                                    fd.append(`${key}[]`, element)
                                })
                                break
                            default:
                                if (value === null) {
                                    fd.append(key, '');
                                } else {
                                    fd.append(key, value as string);
                                }
                                break
                        }
                    }

                    if (params.id) {
                        fd.append('_method', 'PATCH');
                        axios = DefaultAxios.post(`${API_URL}/${params.id}`, fd);
                    } else {
                        axios = DefaultAxios.post(API_URL, fd);
                    }

                    setIsLoading(true);
                    axios
                        .then(res => {
                            Swal.fire({
                                title: "Submit data berhasil",
                                icon: 'success',
                                onAfterClose: () => {
                                    if (params.id) {
                                        // loadData();
                                        window.location.reload();
                                    } else {
                                        navigate('/cluster');
                                    }
                                },
                                timer: 1000
                            });
                        })
                        .catch(err => {
                            helpers.generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            });
    }

    // const titleCase = (value: string) => {
    //     var sentence = value.toLowerCase().trim().split(" ");
    //     for(var i = 0; i< sentence.length; i++){
    //        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    //     }
    //     return sentence.join(" ");
    // }

    // const newTag = (e: any) => {
    //     if (e.keyCode === 13 && e.target.value) {
    //         let val = {
    //             id: 'new',
    //             name: e.target.value
    //         }
    //         let oldValue = inputState.tags
    //         let newValue = oldValue.concat(val)
    //         setInputState({ name: 'tags', value: newValue, type: 'SET_ITEM' });
    //     }
    // }

    const renderPromotionBanners = () => {
        return inputState.promotion_banners.map(promotion_banner => {
            let src = promotion_banner.image;

            return (
                <li key={promotion_banner.id}>
                    <div className={classes.filePreview}>
                        <img src={src} alt={promotion_banner.caption} />
                    </div>
                    <div className={classes.fileDetail}>
                        <p>{promotion_banner.caption}</p>
                        <Link
                            component="button"
                            color="secondary"
                            onClick={handleDeleteAsset}
                        >
                            Delete
                        </Link>
                    </div>
                </li>
            );
        });
    }

    const handleDeleteAsset = () => {
        renderWarningButton("Apakah anda yakin ingin menghapus asset ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/${params.id}/delete-promotion-banner`)
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000
                            })
                                .then(res => {
                                    loadData();
                                })
                        })
                        .catch(error => {
                            helpers.generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleAddMultiForm = (name: string) => {
        const multiValue = [...(inputState[name as keyof IState] as string[])]
        multiValue.push('')

        setInputState({ name: name, value: multiValue, type: 'SET_ITEM' });
    }

    const handleDeleteMultiForm = (name: string, index: number) => {
        let multiValue = [...(inputState[name as keyof IState] as string[])]
        let multiError = [...(errorState[name as keyof IErrorState] as string[])]

        multiValue = multiValue.filter((_, valIndex) => valIndex !== index)
        multiError = multiError.filter((_, valIndex) => valIndex !== index)

        setInputState({ name: name, value: multiValue, type: 'SET_ITEM' });
        setErrorState({ name: name, value: multiError, type: 'SET_ITEM' });
    }

    const handleChangeMultiForm = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value } = e.target

        const multiValue = [...(inputState[name as keyof IState] as string[])]
        multiValue[index] = value

        setInputState({ name: name, value: multiValue, type: 'SET_ITEM' });
    }

    const handleMoveMultiForm = (name: string, index: number, direction: 'up' | 'down') => {
        const multiValue = [...(inputState[name as keyof IState] as string[])]
        const multiError = [...(errorState[name as keyof IErrorState] as string[])]

        switch (direction) {
            case 'up':
                const upValue = multiValue[index - 1]
                multiValue[index - 1] = multiValue[index]
                multiValue[index] = upValue

                const upError = multiError[index - 1]
                multiError[index - 1] = multiError[index]
                multiError[index] = upError
                break
            case 'down':
                const downValue = multiValue[index + 1]
                multiValue[index + 1] = multiValue[index]
                multiValue[index] = downValue

                const downError = multiError[index + 1]
                multiError[index + 1] = multiError[index]
                multiError[index] = downError
                break
        }

        setInputState({ name: name, value: multiValue, type: 'SET_ITEM' });
        setErrorState({ name: name, value: multiError, type: 'SET_ITEM' });
    }

    return (
        <Grid item xs={12}>
            <Root>
                <LoadingScreen open={isLoading} fullScreen />
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Paper style={{ padding: 16, marginRight: 24 }}>
                            <Collapsible label='Basic Information' defaultOpen>
                                <Grid container spacing={3}>
                                    {
                                        params.id &&
                                        <Grid item xs={12}>
                                            <TextField
                                                id="outlined-select-currency-native"
                                                name="level"
                                                select
                                                label="Level"
                                                value={inputState.level ?? ""}
                                                fullWidth
                                                onChange={handleChanged}
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                            >
                                                <option aria-label="None" value="">None</option>
                                                <option aria-label="Level" value="easy">Easy</option>
                                                <option aria-label="Level" value="medium">Medium</option>
                                                <option aria-label="Level" value="hard">Hard</option>
                                            </TextField>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Code"
                                            name="code"
                                            value={inputState.code ?? ''}
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Facebook Form ID"
                                            name="form_id"
                                            value={inputState.form_id ?? ''}
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={12} ref={refs.group_id}>
                                        <AsyncAutoComplete
                                            label="Cluster Leads Group"
                                            name="group_id"
                                            initialQuery={inputState.group_name}
                                            onChange={handleAutocomplete}
                                            onInputChange={handleAutocompleteInputChanged}
                                            url={`${process.env.REACT_APP_API_URL}/autocomplete/lead-group`}
                                            iconSearch
                                            errorText={errorState.group_id}
                                        />
                                    </Grid>
                                    <Grid item xs={12} ref={refs.developer_id}>
                                        <AsyncAutoComplete
                                            label="Developer"
                                            name="developer_id"
                                            initialQuery={inputState.developer_name}
                                            onChange={handleAutocomplete}
                                            onInputChange={handleAutocompleteInputChanged}
                                            url={`${process.env.REACT_APP_API_URL}/autocomplete/developer`}
                                            iconSearch
                                            errorText={errorState.developer_id}
                                        />
                                    </Grid>
                                    <Grid item xs={12} ref={refs.district_id}>
                                        <AsyncAutoComplete
                                            label="District"
                                            name="district_id"
                                            initialQuery={inputState.district_name}
                                            onChange={handleAutocomplete}
                                            onInputChange={handleAutocompleteInputChanged}
                                            url={`${process.env.REACT_APP_API_URL}/autocomplete/district`}
                                            iconSearch
                                            errorText={errorState.district_id}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AsyncAutoComplete
                                            label="Kota Mandiri"
                                            name="kota_mandiri_id"
                                            initialQuery={inputState.kota_mandiri_name}
                                            onChange={handleAutocomplete}
                                            onInputChange={handleAutocompleteInputChanged}
                                            url={`${process.env.REACT_APP_API_URL}/autocomplete/kota-mandiri`}
                                            iconSearch
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AsyncAutoComplete
                                            label="Kawasan"
                                            name="kawasan_id"
                                            initialQuery={inputState.kawasan_name}
                                            onChange={handleAutocomplete}
                                            onInputChange={handleAutocompleteInputChanged}
                                            url={`${process.env.REACT_APP_API_URL}/autocomplete/kawasan`}
                                            iconSearch
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Nama"
                                            name="name"
                                            value={inputState.name ?? ''}
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChanged}
                                            error={!!errorState.name}
                                            helperText={errorState.name}
                                            ref={refs.name}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Slug"
                                            name="slug"
                                            value={inputState.slug ?? ""}
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChanged}
                                            error={!!errorState.slug}
                                            helperText={errorState.slug}
                                            ref={refs.slug}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Address"
                                            name="address"
                                            value={inputState.address ?? ""}
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChanged}
                                            error={!!errorState.address}
                                            helperText={errorState.address}
                                            ref={refs.address}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12}>
                                <TextField
                                    id="outlined-select-currency-native"
                                    name="project_id"
                                    select
                                    label="Select project"
                                    value={inputState.project_id}
                                    fullWidth
                                    onChange={handleChanged}
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="outlined"
                                >
                                    <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                                    {projectList.map(data => {
                                        return (
                                            <option value={data.id} key={data.id}>{data.name}</option>
                                        )
                                    })}
                                </TextField>
                            </Grid> */}

                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-select-currency-native"
                                            name="category"
                                            select
                                            label="Kategori"
                                            value={inputState.category ?? ""}
                                            fullWidth
                                            onChange={handleChanged}
                                            InputLabelProps={{ shrink: true }}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            variant="outlined"
                                        >
                                            <option aria-label="None" value="">Silahkan Pilih Opsi</option>
                                            <option aria-label="category" value="residential">Residential</option>
                                            <option aria-label="category" value="apartment">Apartment</option>
                                            <option aria-label="category" value="shophouse">Shophouse</option>
                                        </TextField>
                                    </Grid>

                                    {/* <Collapse in={inputState.is_apartment_project} className={classes.collapse}> */}
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Latitude"
                                                    name="latitude"
                                                    value={inputState.latitude ?? ""}
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={handleChanged}
                                                    error={!!errorState.latitude}
                                                    helperText={errorState.latitude}
                                                    ref={refs.latitude}
                                                    placeholder="Ex: -6.198049"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Longitude"
                                                    name="longitude"
                                                    value={inputState.longitude ?? ""}
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={handleChanged}
                                                    error={!!errorState.longitude}
                                                    helperText={errorState.longitude}
                                                    ref={refs.longitude}
                                                    placeholder="Ex: 106.7626502"
                                                />
                                            </Grid>

                                        </Grid>
                                        {/* <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        value={inputState.tags}
                                        options={tagList}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, newValue) => {
                                            handleChangeTag(newValue)
                                        }}
                                        getOptionSelected={(option: any, value: any) => option.id === value.id}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            variant="outlined"
                                            label="Category"
                                            style={{marginTop: '30px'}}
                                            placeholder="Choose Category"
                                            onKeyDown={newTag}
                                            helperText="Press enter to add new category. Notes: new category will save after submit form"
                                        />
                                        )}
                                    />
                                </Grid> */}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Meta Title"
                                            name="meta_title"
                                            value={inputState.meta_title ?? ""}
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChanged}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Meta Description"
                                            name="meta_description"
                                            value={inputState.meta_description ?? ""}
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChanged}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Lowest Price (Rp)"
                                            name="lowest_price"
                                            value={helpers.numberToCurrency(inputState.lowest_price)}
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChanged}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Highest Price (Rp)"
                                            name="highest_price"
                                            value={helpers.numberToCurrency(inputState.highest_price)}
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChanged}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Min Bedroom"
                                            name="min_bedroom"
                                            value={inputState.min_bedroom ?? ""}
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChanged}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Max Bedroom"
                                            name="max_bedroom"
                                            value={inputState.max_bedroom ?? ""}
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChanged}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <h3>Upload Promotion Banner</h3>
                                        {
                                            inputState.promotion_banners.length
                                                ? <ul className={classes.fileList}>
                                                    {renderPromotionBanners()}
                                                </ul>
                                                : null
                                        }
                                        <input type="file" name="promotion_banner_file" onChange={handleChanged} style={{ marginBottom: '10px' }} />
                                        <br />
                                        <i>Recommended dimension : 460 x 500</i>
                                        {
                                            warningImage &&
                                            <>
                                                <br />
                                                <i style={{ color: 'red' }}>
                                                    Gambar yang di upload tidak memiliki dimensi yang sama dengan yang direkomendasikan.
                                                    <br />
                                                    Hal ini akan menyebabkan gambar terpotong atau tidak sesuai yang diharapkan saat tampil di halaman utama
                                                </i>
                                                <br />
                                                &nbsp;
                                            </>
                                        }
                                        <TextField
                                            label="Promotion Banner Caption"
                                            name="promotion_banner_caption"
                                            value={inputState.promotion_banner_filepath.name ?? ""}
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChanged}
                                            error={!!errorState.promotion_banner_caption}
                                            helperText={errorState.promotion_banner_caption}
                                            ref={refs.promotion_banner_caption}
                                        />
                                    </Grid>
                                </Grid>
                            </Collapsible>
                            <Collapsible label='Facilities'>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <FormGroup>
                                            {renderOptions(false)}
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormGroup>
                                            {renderOptions(true)}
                                        </FormGroup>
                                    </Grid>

                                    {/* {
                                (inputState.category === 'apartment') ?
                                    <>
                                        <Grid item xs={12}>
                                            <Typography>
                                                <strong>Apartment Facilities</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                {renderOptionsApartment(0)}
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                {renderOptionsApartment(1)}
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                {renderOptionsApartment(2)}
                                            </FormGroup>
                                        </Grid>
                                    </>
                                    : <></>
                            } */}

                                    {/* <Grid item xs={12}>
                                <h3>Article</h3>
                                <TextEditor
                                    name="article"
                                    value={inputState.article}
                                    onChange={handleEditorChanged}
                                    imagesUpload
                                />
                            </Grid> */}
                                </Grid>
                            </Collapsible>
                            <Collapsible label='Promos'>
                                <MultiItemForm
                                    name='promos'
                                    label='Promos'
                                    errors={errorState.promos}
                                    list={inputState.promos}
                                    onAdd={handleAddMultiForm}
                                    onDelete={handleDeleteMultiForm}
                                    onChange={handleChangeMultiForm}
                                    onMove={handleMoveMultiForm}
                                />
                            </Collapsible>
                            <Collapsible label='Cara Bayar'>
                                <MultiItemForm
                                    name='payment_types'
                                    label='Cara Bayar'
                                    errors={errorState.payment_types}
                                    list={inputState.payment_types}
                                    onAdd={handleAddMultiForm}
                                    onDelete={handleDeleteMultiForm}
                                    onChange={handleChangeMultiForm}
                                    onMove={handleMoveMultiForm}
                                />
                            </Collapsible>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Root>
        </Grid>
    );
}

export default ClusterDetailForm;