import { Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import DataTable from '../../_components/_dataTable/DataTable'
import UserBankForm from './_component/UserBankForm'

interface IProps {

}

const column = [
    { name: 'user_name_label', label: 'Name', filterable: true, sortable: true },
    { name: 'bank_type_label', label: 'Bank', filterable: true, sortable: true },
    { name: 'behalf_name', label: 'Rekening Atas Nama', filterable: true, sortable: true },
    { name: 'number', label: 'No. Rekening', filterable: true, sortable: true },
]

const UserBankList = (props: IProps) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/user-bank`
    const [reDrawTable, setReDrawTable] = useState(new Date().getTime())
    const [addModal, setAddModal] = useState(false)

    const handleAddUser = () => {
        setAddModal(true)
    }
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Button
                        variant='contained'
                        onClick={handleAddUser}
                    >
                        Add User
                    </Button>
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                    <DataTable
                        columns={column}
                        url={API_URL}
                        reDraw={reDrawTable}
                    />
                </Grid>
            </Grid>

            <UserBankForm
                open={addModal}
                onClose={(refresh: boolean) => {
                    setAddModal(false)

                    if (refresh) setReDrawTable(new Date().getTime())
                }}
            />
        </>
    )
}

export default UserBankList