import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import helpers from '../../_utils/Helpers';

interface IUser {
    id: string;
    name: string;
}

const ForceLogin = () => {
    const [users, setUsers] = useState<IUser[]>([]);
    const [selectedUser, setSelectedUser] = useState('');

    useEffect(() => {
        DefaultAxios.get(process.env.REACT_APP_API_URL + '/user/force')
            .then(res => {
                setUsers(res.data);
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            });
    }, []);

    const handleForceLogin = () => {
        if (!selectedUser) {
            return;
        }

        DefaultAxios.post(process.env.REACT_APP_API_URL + '/user/force', { id: selectedUser })
            .then(res => {
                localStorage.setItem("jwt_token", res.data.access_token);
                localStorage.setItem("user_id", res.data.user_id); // For notification
                localStorage.setItem("role", res.data.role);
                Swal.fire({
                    title: "Login Berhasil",
                    icon: 'success',
                    timer: 1000,
                    onAfterClose: () => {
                        window.location.href = '/';
                    }
                });
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            });
    }

    return (
        <>
            <h1>FORCE LOGIN (SUPERUSER ONLY)</h1>

            <select 
                style={{ minWidth: '200px' }}
                onChange={(e) => setSelectedUser(e.target.value)}
            >
                <option value="">-- Select User --</option>
                {users.map(user => <option key={user.id} value={user.id}>{user.name}</option>)}
            </select>

            <button onClick={handleForceLogin}>Force Login</button>
        </>
    );
}

export default ForceLogin;