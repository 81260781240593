import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, Button, Grid } from '@mui/material'
import DropZone from '../../../_components/DropZone'
import PhotoPreview from '../../../_components/PhotoPreview'

interface IProps {
    open: boolean,
    onClose: () => void
    onSubmit: (proof: File) => void
}

const CheckProcessedModal = (props: IProps) => {
    const [proof, setProof] = useState<{
        file: File | null,
        preview: string | null
    }>({
        file: null,
        preview: null
    })
    const handleChangeFile = (files: FileList, name: string) => {
        setProof({
            file: files && files[0],
            preview: window.URL.createObjectURL(files[0])
        })
    }

    const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
        const items = event.clipboardData?.items;
        if (items?.length) {
            const file = items[0].getAsFile()
            if (file && file.type.includes('image')) {
                setProof(prev => ({
                    ...prev,
                    file: file,
                    preview: window.URL.createObjectURL(file)
                }))
            }
        }
    };

    useEffect(() => {
        return () => setProof({
            file: null,
            preview: null
        })
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle sx={{paddingBottom: 0}}>Silahkan Upload Bukti</DialogTitle>
            <DialogContent onPaste={handlePaste}>
                <Grid item xs={12}>
                    <span style={{fontSize: 14}}>Max size 8MB</span>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                        {
                            proof && proof.preview ?
                                <PhotoPreview
                                    onRemoveFile={() => setProof({
                                        file: null,
                                        preview: null
                                    })}
                                    src={proof.preview}
                                    index={1}
                                    style={{ width: '100%', margin: 0 }}
                                />
                                :
                                <DropZone
                                    name='proof'
                                    onChange={handleChangeFile}
                                    accept={{
                                        'image/*': [],
                                    }}
                                    multiple={false}
                                />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            disabled={!proof.file}
                            style={{ float: 'right' }}
                            onClick={() => props.onSubmit(proof.file as File)}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default CheckProcessedModal