import React, { useState } from 'react'
import { Button, Grid, capitalize } from '@mui/material'
import DataTable from '../../_components/_dataTable/DataTable'
import { format } from 'date-fns'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, renderSuccessButton, renderWarningButton } from '../../_utils/Helper'
import LoadingScreen from '../../_components/LoadingScreen'
import { id } from 'date-fns/locale'

type Props = {}


const LeadPauseRequestList = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [reDrawDataTable, setReDrawDatatable] = useState(new Date().getTime())

    const leadpauseColumn = [
        { name: 'requested_at', label: 'Requested At', filterable: true, type: 'date', render: (data: any) => format(new Date(data), 'dd MMM yyyy', { locale: id }) },
        { name: 'requester_name', label: 'Requester', filterable: true, type: 'string' },
        {
            name: 'status',
            label: 'Status',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'On Process'
                },
                {
                    key: '1',
                    value: 'Approved'
                },
                {
                    key: '2',
                    value: 'Rejected'
                },
            ],
            filterable: true,
            type: 'string'
        },
        { name: 'supervisor_name', label: 'Approver', filterable: true, type: 'string' },
        {
            name: 'EXTRA',
            label: 'Date',
            columnStyle: { minWidth: '180px' },
            filterable: false,
            type: 'string',
            render: (rows: any) => `${format(new Date(rows.start_date), 'dd MMMM yyyy', { locale: id })} - \n ${format(new Date(rows.start_date), 'dd MMMM yyyy', { locale: id })}`
        },
        { name: 'notes', label: 'Notes', filterable: true, type: 'string' },
        {
            name: 'EXTRA',
            label: 'Approved / Rejected At',
            filterable: false,
            type: 'string',
            render: (rows: any) => (
                rows.approved_at ?
                    format(new Date(rows.approved_at), 'dd MMM yyyy', { locale: id }) :
                    rows.rejected_at ?
                        format(new Date(rows.rejected_at), 'dd MMM yyyy', { locale: id }) :
                        '-'
            )
        },
        {
            name: 'EXTRA',
            label: 'ACTION',
            filterable: false,
            type: 'string',
            render: (rows: any) => (
                <div style={{ display: 'flex', gap: 10 }}>
                    {
                        (!rows.approved_at && !rows.rejected_at) ?
                            <>
                                {renderButtonAction('approve', rows)}
                                {renderButtonAction('reject', rows)}
                            </>
                            : null
                    }
                </div>
            )
        },
    ]

    const renderButtonAction = (type: 'approve' | 'reject', rows: any) => {
        const handleAction = () => {
            renderWarningButton(`Apakah anda yakin untuk me-${type} request ini ?`)
                .then(res => {
                    if (res.dismiss) return

                    setIsLoading(true)
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/lead-pause-request/${type}`, {
                        id: rows.id
                    })
                        .then(res => {
                            renderSuccessButton(`${capitalize(type)} Success!`)
                            setReDrawDatatable(new Date().getTime())
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false)
                        })

                })

        }

        return (
            <Button
                variant='contained'
                color={type === 'approve' ? 'primary' : 'secondary'}
                onClick={handleAction}
            >
                {capitalize(type)}
            </Button>
        )

    }

    return (
        <Grid container>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={12}>
                <DataTable
                    columns={leadpauseColumn}
                    url={`${process.env.REACT_APP_API_URL}/lead-pause-request`}
                    // customData={dummyData}
                    reDraw={reDrawDataTable}
                />
            </Grid>
        </Grid>
    )
}


export default LeadPauseRequestList