import React from 'react'
import { Grid, Button, Box } from '@mui/material'
import background from '../../../Assets/Images/png/whatsappbackground.png'


/* 
* Icons & Styles
*/
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { grey } from '@mui/material/colors';
import noimage from '../../../Assets/Images/jpg/noimage.jpg'

/* 
Utils
*/
import LoadingScreen from '../../../_components/LoadingScreen';
import { ITemplate } from '../FormBroadcast';
import { generateStyle } from '../../../_utils/DefaultStyles';

/* 
* Interfaces
*/
interface IProps {
    data: ITemplate
    loading: boolean
    preview: string
}

const darkgrey = grey['700']
const useStyles = generateStyle(theme => ({
    preview: {
        background: `url(${background})`,
        padding: '20px 40px 20px 20px'
    },
    baloon: {
        filter: 'drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.3))',
        position: 'relative',
        maxWidth: '600px'
    },
    corner: {
        position: 'absolute',
        left: '-5px',
        width: 0,
        height: 0,
        borderTop: '10px solid white',
        borderLeft: '10px solid transparent',
        borderRight: '5px solid transparent'
    },
    body: {
        overflow: 'auto',
        borderRadius: '5px',
        background: 'white',
    },
    text: {
        padding: '10px 20px 5px',
        position: 'relative',
        whiteSpace: 'pre-line'
    },
    time: {
        padding: '0px 20px 0px 0px',
        marginTop: '-10px',
        marginBottom: '5px',
        textAlign: 'right',
        fontSize: '9pt'
    },
    callToAction: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        left: 0,
        right: 0,
        borderTop: "2px solid #eee",
    },
    buttonItems: {
        textTransform: 'initial',
    },
    attachment: {
        margin: '10px 10px 0px',
        backgroundColor: '#eee',
        borderRadius: '10px',
        overflow: 'hidden',
        '&& > img, && > video': {
            width: '100%'
        }
    },
    document: {
        display: 'flex',
        width: '100%',
        height: '80px',
        padding: '20px'
    },
    documentIcon: {
        color: darkgrey
    }
}), "WhatsAppPreview")



const WhatsappPreview = ({ data, loading, preview }: IProps) => {
    const { Root, classes } = useStyles()

    const generateCallToAction = (button: any) => {
        return (
            button.length > 0 &&
            <Grid className={classes.callToAction} flexDirection='column' justifyContent='center'>
                {button.map((data: any) => (
                    <Button
                        size='small'
                        className={classes.buttonItems}
                        startIcon={data.type === 'PHONE_NUMBER' ? <LocalPhoneOutlinedIcon /> : <OpenInNewOutlinedIcon />}
                    >
                        {data.text}
                    </Button>
                ))}
            </Grid>
        )
    }

    const generateAttachment = (
        media_type: string | null,
        media: string | null
    ) => {
        if (!media_type) {
            return null
        }
        return (
            <>
                <Box component='div' className={classes.attachment}>
                    {media_type === 'image' ?
                        <Box component='img' src={preview ? preview : noimage} alt="attachment" />
                        : media_type === 'video' ?
                            <Box component='video' src={preview ? preview : noimage} controls />
                            :
                            <Grid component='div' className={classes.document} flexDirection='row' alignItems='center'>
                                <InsertDriveFileIcon fontSize='large' className={classes.documentIcon} />
                                <Box component='p' sx={{ ml: 'auto' }}>Document</Box>
                            </Grid>
                    }
                </Box>
            </>
        )
    }

    return (
        <Root>
            <Grid container>
                {loading ?
                    <LoadingScreen
                        open={loading}
                    />
                    :
                    <Grid item xs={12} className={classes.preview}>
                        <Box className={classes.baloon} component="div">
                            <Box className={classes.corner} component="div" />
                            <Box className={classes.body} component="div">
                                {generateAttachment(data.media_type, data.media)}
                                <Box className={classes.text} dangerouslySetInnerHTML={{ __html: data.body as string }} component="div">
                                </Box>
                                <Box className={classes.time} component="div">
                                    09:09
                                </Box>
                                {generateCallToAction(data.buttons)}
                            </Box>
                        </Box>
                    </Grid>
                }
            </Grid>
        </Root>
    )
}

export default WhatsappPreview