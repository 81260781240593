import React, { useState, useEffect } from 'react';
import {
    Button,
    Typography,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Theme,
    Grid,
    Chip,
    FormControl,
    NativeSelect,
    Switch
} from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';
import PublishIcon from '@mui/icons-material/Publish';
import GetAppIcon from '@mui/icons-material/GetApp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import ClearAllIcon from '@mui/icons-material/ClearAll';

/**
 * Transition
 */
import { TransitionProps } from '@mui/material/transitions';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import { IAutoCompleteOption as IAutocompleteMultipleOption } from '../../_components/_form/AsycMultipleAutoComplete';
import LoadingScreen from '../../_components/LoadingScreen';
import CopyPlacementModal from '../../_components/_modals/CopyPlacementModal';
import { generateStyle } from '../../_utils/DefaultStyles';

interface IProps {
    isOpen: boolean;
    cluster_id: number;
    onClose: Function;
}

interface ICluster {
    id: number;
    name: string;
}

interface IListAdvisor {
    id: string;
    name: string;
    is_priority: number;
    leads_percentage: number;
    limit_leads_per_day_after_percentage: number;
    cluster_id: number;
    pic_id: number;
    round_robin_flag: number;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = generateStyle((theme: Theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    table: {
        minWidth: 650,
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    containerButton: {
        gap: "5px",
        height: '100%'
    },
    button: {
        height: "100%"
    },
    buttonIconContainer: {
        justifyContent: 'flex-start',
        gap: '5px'
    }
}), "ClusterPlacementModal");

const toastMixin = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true
});

export default function ClusterPlacementModal({ isOpen, cluster_id, onClose }: IProps) {
    const { Root, classes } = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [cluster, setCluster] = useState<ICluster>({ id: 0, name: "" });
    const [listPlacement, setListPlacement] = useState<Array<IListAdvisor>>([]);
    const [selectedMember, setSelectedMember] = useState(0);
    const [defaultLimit, setDefaultLimit] = useState(0)
    const [copyPlacementModalOpen, setCopyPlacementModalOpen] = useState(false);
    const [copyPlacementTargetIds, setCopyPlacementTargetIds] = useState<IAutocompleteMultipleOption[]>([]);

    const handleCopyPlacementModalOpen = () => setCopyPlacementModalOpen(true);
    const handleCopyPlacementModalClose = () => setCopyPlacementModalOpen(false);

    const addAdvisor = () => {
        if (selectedMember === 0) {
            alert('No Advisor Selected');
        } else {
            renderWarningButton("Apakah anda yakin ingin menambah advisor ini?")
                .then((result) => {
                    if (result.value) {
                        setIsLoading(true);
                        /**
                         * Add To List, send pic_id + cluster_id
                         */
                        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/cluster/placement`, {
                            cluster_id: cluster.id,
                            pic_id: selectedMember
                        })
                            .then(res => {
                                loadData();
                            })
                            .catch(err => {
                                generalErrorHandler(err);
                            })
                            .finally(() => {
                                setIsLoading(false);
                            })
                    }
                });
        }
    }

    const handleDeleteAllPlacement = () => {
        renderWarningButton("Apakah anda yakin ingin menghapus semua cluster placement ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/cluster/placement/delete-all`, {
                        cluster_id: cluster.id,
                    })
                        .then(res => {
                            loadData();
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            });
    }

    const handleAutoAssignActiveAgent = () => {
        renderWarningButton("Apakah anda yakin ingin meng-assign semua aktif agent ke cluster ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/cluster/placement/auto-assign-active-agent`, {
                        cluster_id: cluster.id,
                    })
                        .then(res => {
                            loadData();
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            });
    }

    const handleClose = () => {
        onClose();
    }

    const deleteMember = (cluster_id: number, pic_id: number) => {
        renderWarningButton("Apakah anda yakin ingin menghapus member ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    let data = {
                        cluster_id: cluster_id,
                        pic_id: pic_id
                    }
                    /**
                     * Delete from List
                     */
                    DefaultAxios
                        .delete(`${process.env.REACT_APP_API_URL}/cluster/placement/${cluster_id}`, { params: data })
                        .then(res => {
                            loadData();
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                }
            });
    }

    const createMenuItemList = (max: number, interval: number, start: number = 10) => {
        let list = []
        for (let value = start; value <= max; value += interval) {
            list.push(<option value={value}>{value} %</option>)
        }
        return list
    }

    useEffect(() => {
        if (cluster_id !== 0) {
            loadData();
        }
        // eslint-disable-next-line
    }, [cluster_id]);

    useEffect(() => {
        if (!copyPlacementModalOpen) {
            setCopyPlacementTargetIds([]);
        }
    }, [copyPlacementModalOpen])

    const loadData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/cluster/placement`, { params: { id: cluster_id } })
            .then(res => {
                setCluster(res.data.cluster as ICluster);
                setListPlacement(res.data.cluster_placements as Array<IListAdvisor>)
                setDefaultLimit(res.data.default_limit_per_day)
                setSelectedMember(0)
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setSelectedMember(value.id as number);
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setSelectedMember(0);
    }

    const handleSortPlacement = (placementId: string, dir: 'up' | 'down' | 'top' | 'bottom', key: number) => {
        setIsLoading(true);
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/cluster/placement/sort`, { dir, id: placementId })
            .then(res => {
                loadData();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleChangeTogglePrioriy = (row: IListAdvisor) => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/cluster/placement/toggle-priority`, {
            id: row.id
        })
            .then(res => res.data)
            .then(res => {
                toastMixin.fire('Priority Changed!', '', 'success')
                loadData()
            })
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    const handleChangePercentage = (value: number, id: number) => {
        setIsLoading(true)
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/cluster/placement/set-leads-percentage`, {
                id: id,
                leads_percentage: value
            })
            .then(res => {
                toastMixin.fire({
                    icon: 'success',
                    title: 'Leads Percentage Berhasil Diganti'
                })
                loadData();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleCopyPlacementSubmit = (is_also_copy_percentage: boolean) => {
        if (copyPlacementTargetIds.length === 0) {
            return;
        }

        renderWarningButton("Apakah anda yakin ingin meng-copy placement ini?")
            .then((result) => {
                if (result.value) {
                    const targetIds = copyPlacementTargetIds.map(item => item.id);

                    setIsLoading(true);
                    DefaultAxios.post(process.env.REACT_APP_API_URL + '/cluster/placement/copy-placement-cluster', {
                        cluster_id_source: cluster_id,
                        cluster_id_target: targetIds,
                        is_also_copy_percentage: is_also_copy_percentage ? 1 : 0,
                    })
                        .then(res => {
                            Swal.fire({
                                title: "Copy placement berhasil",
                                icon: 'success',
                                timer: 1000,
                                onAfterClose: handleCopyPlacementModalClose
                            })
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    return (
        <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
            <Root>

                <LoadingScreen open={isLoading} fullScreen />
                <CopyPlacementModal
                    open={copyPlacementModalOpen}
                    onClose={handleCopyPlacementModalClose}
                    label="Cluster"
                    url={
                        process.env.REACT_APP_API_URL +
                        '/autocomplete/cluster-detailed?except_ids=' +
                        [cluster_id, ...copyPlacementTargetIds.map(i => i.id)].join(',')
                    }
                    targetIds={copyPlacementTargetIds}
                    setTargetIds={setCopyPlacementTargetIds}
                    onSubmit={handleCopyPlacementSubmit}
                />
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Cluster Placement - {cluster.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.content}>
                    <Grid container sx={{ mt: 2 }} spacing={1}>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <AsyncAutoComplete
                                        url={`${process.env.REACT_APP_API_URL}/autocomplete/pic`}
                                        label="Tambah PIC"
                                        name="pic_id"
                                        initialQuery={''}
                                        onChange={handleAutocomplete}
                                        onInputChange={handleAutocompleteInputChanged}
                                        placeholder="Masukan Nama PIC"
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        className={classes.button}
                                        color="primary"
                                        variant="contained"
                                        disabled={selectedMember === 0}
                                        onClick={addAdvisor}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Grid container className={classes.containerButton} display={{ md: 'flex', xs: 'none' }}>
                                <Grid item>
                                    <Button
                                        className={classes.button}

                                        color="primary"
                                        variant="contained"
                                        onClick={handleCopyPlacementModalOpen}
                                    >
                                        Copy Placement
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        className={classes.button}
                                        color="primary"
                                        variant="contained"
                                        onClick={handleAutoAssignActiveAgent}
                                    >
                                        Auto Assign Active Agent
                                    </Button>
                                </Grid>
                                <Grid item sx={{ ml: "auto" }}>
                                    <Button
                                        className={classes.button}
                                        sx={{ ml: "auto" }}
                                        color="secondary"
                                        variant="contained"
                                        onClick={handleDeleteAllPlacement}
                                    >
                                        Delete All Placement
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container display={{ md: 'none', xs: 'flex' }} className={classes.buttonIconContainer}>
                                <Chip
                                    label="Copy Placement"
                                    color="primary"
                                    onClick={handleCopyPlacementModalOpen}
                                    icon={<CopyAllIcon fontSize='small' />} />
                                <Chip
                                    label="Auto Assign Active Agent"
                                    color="primary"
                                    onClick={handleAutoAssignActiveAgent}
                                    icon={<AutoModeIcon fontSize='small' />} />
                                <Chip
                                    label="Delete All Placement"
                                    color="secondary"
                                    onClick={handleDeleteAllPlacement}
                                    icon={<ClearAllIcon fontSize='small' />} />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* } */}

                    <TableContainer component={Paper} sx={{ mt: 5 }}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nama</TableCell>
                                    <TableCell>Toggle Priority</TableCell>
                                    <TableCell>Antrian Leads</TableCell>
                                    <TableCell>Leads Percentage</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listPlacement.map((row, key) => (
                                    <TableRow key={row.pic_id}>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Switch checked={!!row.is_priority} onChange={(e, checked) => handleChangeTogglePrioriy(row)}/>
                                        </TableCell>
                                        <TableCell>
                                            {+row.round_robin_flag === 1 ? 'Yes' : 'No'}
                                        </TableCell>
                                        <TableCell>
                                            <Grid container sx={{ alignItems: 'center' }}>
                                                <FormControl>
                                                    <NativeSelect
                                                        defaultValue={row.leads_percentage || 100}
                                                        inputProps={{
                                                            name: 'lead_percentage',
                                                            id: 'lead_percentage',
                                                        }}
                                                        onChange={(e) => handleChangePercentage(+e.target.value, +row.id)}
                                                    >
                                                        {createMenuItemList(200, 10, 10)}
                                                    </NativeSelect>
                                                </FormControl>
                                                <span style={{ marginLeft: 10 }}>{row.limit_leads_per_day_after_percentage ? row.limit_leads_per_day_after_percentage : defaultLimit} per day</span>
                                            </Grid>

                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                onClick={() => deleteMember(row.cluster_id, row.pic_id)}
                                            >
                                                Delete
                                            </Button>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleSortPlacement(row.id, 'top', key)}
                                                disabled={key === 0}
                                            >
                                                <PublishIcon />
                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleSortPlacement(row.id, 'up', key)}
                                                disabled={key === 0}
                                            >
                                                <ArrowUpwardIcon />
                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleSortPlacement(row.id, 'down', key)}
                                                disabled={key === listPlacement.length - 1}
                                            >
                                                <ArrowDownwardIcon />
                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleSortPlacement(row.id, 'bottom', key)}
                                                disabled={key === listPlacement.length - 1}
                                            >
                                                <GetAppIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Root>
        </Dialog>
    )
}