import React from 'react';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';

/**
 * Utils
 */
import helpers from '../../_utils/Helpers';

/**
 * Icons
 */
import { generateStyle } from '../../_utils/DefaultStyles';


const useStyles = generateStyle(theme => ({
    actionContainer: {
        display: 'flex'
    },
    filter: {
        width: 'auto',
        height: '30px',
        display: 'inline-flex',
        borderRadius: '4px',
        margin: '5px',
        fontSize: '0.8125rem',
        backgroundColor: '#009be5',
    },
    filterButton: {
        '&$selected': {
            color: 'red',
        },
        flex: 'none',
        color: '#fff',
        fontSize: '0.8125rem',
        padding: '6px 10px',
        minWidth: '50px',
        whiteSpace: 'nowrap'
    },
    filterButtonSelected: {
        color: '#fff',
        borderRadius: '4px',
        backgroundColor: '#006db3',
        fontSize: '0.8125rem',
    },
    filterButtonLabel: {
        fontSize: '0.8125rem',
    },
    appCustomFilter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    customFilter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginBottom: 8,
    }
}), "Activity_List"
)

export const activityTypes = [
    {
        key: 'priority_assigned',
        value: 'Priority_Assigned'
    },
    {
        key: 'priority',
        value: 'Priority'
    },
    {
        key: 'backup_placement',
        value: 'Backup_Placement'
    },
    {
        key: 'global',
        value: 'Global'
    },
    {
        key: 'redirect',
        value: 'Redirect'
    }
];

const LeadDirectList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/lead-direct';
    const { Root } = useStyles();

    const columns = [
        {
            name: 'created_at',
            label: 'Created At',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return data ? helpers.dateFormat(data, 'DD MMMM YYYY HH:mm') : '-';
            }
        },
        {
            name: 'phone',
            label: 'Phone',
            type: 'string',
            filterable: true
        },
        {
            name: 'type',
            label: 'Type',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...activityTypes
            ],
            filterable: true,
            render: (data: any) => {
                return activityTypes.find(activityType => activityType.key === data)?.value;
            }
        },
        {
            name: 'cluster_name',
            label: 'Cluster',
            type: 'string',
            filterable: true
        },
        {
            name: 'lead_code',
            label: 'Kode Lead',
            type: 'string',
            filterable: true,
            render: (data: string) => {
                return data ? data : '-';
            }
        },
        {
            name: 'utm_content',
            label: 'UTM Content',
            type: 'string',
            filterable: true,
        },
        {
            name: 'adsetname',
            label: 'Adsetname',
            type: 'string',
            filterable: true,
        },
        {
            name: 'priority_assigned_agent_name_label',
            label: 'Priority Agent',
            type: 'string',
            filterable: true,
            render: (data: any) => {
                return data ? data : '-';
            }
        },
        {
            name: 'claimed_at',
            label: 'Claimed At',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return data ? helpers.dateFormat(data, 'DD MMMM YYYY HH:mm') : '-';
            }
        },
        {
            name: 'claimed_by_label',
            label: 'Claimed By',
            type: 'string',
            filterable: true,
            render: (data: string) => {
                return data ? data : '-';
            }
        },
        {
            name: 'expired_at',
            label: 'Expired At',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return data ? helpers.dateFormat(data, 'DD MMMM YYYY') : '-';
            }
        },
    ];

    return (
        <Root>
            <DataTable
                url={API_URL}
                columns={columns}
            />
        </Root>
    );
}

export default LeadDirectList;