import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    Button,
    IconButton,
    Grid,
    FormControlLabel,
    Switch,
    Typography,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    useMediaQuery,
    Chip,
    Theme,
    Tooltip,
    BottomNavigation,
    BottomNavigationAction,
    useTheme
} from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';
import DeleteLeadModal from './_components/DeleteLeadModal';
import ReassignAgentModal from './_components/ReassignAgentModal';
import LeadForm from './LeadForm';
import NewLeadForm from './NewLeadForm';
import InputAgentMtsSelect from './_components/InputAgentMtsSelect';
import AppDataTable, { AppDataTablePageStyle } from '../../_components/_dataTable/AppDataTable';
import WhatsappPreviewModal, { ChatsData, INITIAL_CHATS_DATA } from './_components/WhatsappPreviewModal';


/**
 * Context
 */
import { PermissionContext } from "../../_contexts/PermissionContext";

/**
 * Utils
 */
import helpers, { numberToCurrency } from '../../_utils/Helpers';
import DefaultAxios from '../../_utils/DefaultAxios';
import { convertIndonesiaPhoneNumber, generalErrorHandler, inputNumber, renderErrorButton } from '../../_utils/Helper';
import { leadSources, leadMts } from '../../_utils/ConstData';
import { format } from 'date-fns';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import GetAppIcon from '@mui/icons-material/GetApp';
import LeadDetail from './LeadDetail';
import { WhatsApp } from '@mui/icons-material';
import DuplicateIcon from '../../Assets/Images/svg/duplicate-leads-icon.svg';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { generateStyle } from '../../_utils/DefaultStyles';


interface Props {
    isApp?: boolean;
    is_unassigned?: boolean;
}

export interface IOption {
    key: string;
    value: string;
}

interface SearchedItem {
    agent: null | string
    agent_name: null | string
    agent_phone: null | string
    client_name: null | string
    cluster: null | string
    cluster_name: null | string
    created_at: null | string
    notes: null | string
}

const useStyles = generateStyle(theme => ({
    filter: {
        width: 'auto',
        height: '30px',
        display: 'inline-flex',
        borderRadius: '4px',
        margin: '5px',
        fontSize: '0.8125rem',
        backgroundColor: '#009be5',
    },
    filterButton: {
        '&$selected': {
            color: 'red',
        },
        flex: 'none',
        color: '#fff',
        fontSize: '0.8125rem',
        padding: '6px 10px',
        minWidth: '50px',
        whiteSpace: 'nowrap'
    },
    filterButtonSelected: {
        color: '#fff',
        borderRadius: '4px',
        backgroundColor: '#006db3',
        fontSize: '0.8125rem',
    },
    filterButtonLabel: {
        fontSize: '0.8125rem',
    },
    actionContainer: {
        display: 'flex'
    },
    addButton: {
        marginBottom: '20px'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
}), "LeadList")

const LeadList = (props: Props) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/${props.is_unassigned ? 'lead-unassigned' : 'lead'}`;
    const permissions = useContext(PermissionContext);
    const { Root, classes } = useStyles();
    const theme = useTheme<Theme>()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [moreDetail, setMoreDetail] = useState(false);
    const [chatsData, setChatsData] = useState<ChatsData>(INITIAL_CHATS_DATA)

    const [filter, setFilter] = useState({
        freshness: '1',
    })
    const [showBreakdown, setShowBreakdown] = useState(false)
    const breakdownFilter = useMemo(() => ({
        is_breakdown: showBreakdown ? '1' : '0'
    }), [showBreakdown])

    const columns = [
        {
            name: 'created_at',
            label: 'Date',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return helpers.dateFormat(data, 'DD MMM YYYY HH:mm:ss');
            }
        },
        {
            name: 'code',
            label: 'Kode',
            type: 'string',
            filterable: true,
            render: (data: any, row: any) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span onClick={() => handleDetail(row.id)} style={{ cursor: 'pointer', color: 'rgb(0, 86, 168)' }}>
                            {data}

                        </span>
                        {row.duplicate_type &&
                            <Tooltip title="Leads ini Duplikat">
                                <img src={DuplicateIcon} alt="warning" style={{ width: '18px', marginLeft: "5px" }} />
                            </Tooltip>
                        }
                    </div>
                )
            }
        }, ...(
            permissions['whatsapp.manage'] ?
                [{
                    name: 'wa_id',
                    label: 'Whatsapp Preview',
                    type: 'string',
                    filterable: true,
                    options: [
                        {
                            key: '',
                            value: 'All',
                        },
                        {
                            key: 1,
                            value: 'Yes',
                        },
                        {
                            key: 0,
                            value: 'No',
                        },
                    ],
                    render: (data: any, row: any) => {
                        if (data) {
                            return (
                                <IconButton aria-label="preview chat" title='Preview Chat' onClick={() => handleWhatsappPreview(row.id)} color='primary'>
                                    <WhatsAppIcon />
                                </IconButton>
                            )

                        } else {
                            return '-'
                        }
                    }
                }]
                : []
        ),
        {
            name: 'client_name',
            label: 'Nama Client',
            type: 'string',
            filterable: true,
        },
        {
            name: 'status_label',
            label: 'Status',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All',
                },
                {
                    key: 'undefined',
                    value: 'New',
                },
                {
                    key: 'no_response',
                    value: 'No Response',
                },
                {
                    key: 'warm',
                    value: 'Warm',
                },
                {
                    key: 'hot',
                    value: 'Hot',
                },
                {
                    key: 'booking_fee',
                    value: 'Booking Fee',
                },
                {
                    key: 'closing',
                    value: 'Closing',
                },
                {
                    key: 'cold',
                    value: 'Cold',
                },
                {
                    key: 'excluded',
                    value: 'Excluded',
                },
            ],
            filterable: true,
            render: (data: any, row: any) => {
                return (
                    data === 'undefined' ? (row.status === 'showing' ? 'Showing' : 'New') : data
                )
            }
        },
        {
            name: 'objective_score_agent',
            label: 'Objective Score Agent',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All',
                },
                {
                    key: 0,
                    value: 'Bad  (<60)',
                },
                {
                    key: 1,
                    value: 'OK  (60-69)',
                },
                {
                    key: 2,
                    value: 'Good  (>=70)',
                },
            ],
            filterable: true,
            render: (data: any) => {
                if (data === null) return '-'

                let color: "primary" | "success" | "warning" | "error" = "primary";

                if (data >= 70) {
                    color = "success";
                } else if (data >= 60) {
                    color = "warning";
                } else {
                    color = "error";
                }

                return <Chip
                    color={color}
                    label={data}
                />;
            }
        },
        {
            name: 'objective_score',
            label: 'Objective Score Client',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All',
                },
                {
                    key: 0,
                    value: 'Bad  (<60)',
                },
                {
                    key: 1,
                    value: 'OK  (60-69)',
                },
                {
                    key: 2,
                    value: 'Good  (>=70)',
                },
            ],
            filterable: true,
            render: (data: any) => {
                if (data === null) return '-'

                let color: "primary" | "success" | "warning" | "error" = "primary";

                if (data >= 70) {
                    color = "success";
                } else if (data >= 60) {
                    color = "warning";
                } else {
                    color = "error";
                }

                return <Chip
                    color={color}
                    label={data}
                />;
            }
        },
        {
            name: 'client_phone',
            label: 'No Hp',
            type: 'string',
            filterable: true
        },
        {
            name: 'cluster_level',
            label: 'Level Cluster',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: 'none',
                    value: 'None'
                },
                {
                    key: 'easy',
                    value: 'Easy'
                },
                {
                    key: 'medium',
                    value: 'Medium'
                },
                {
                    key: 'hard',
                    value: 'Hard'
                },
            ],
            filterable: true,
            render: (data: any) => {
                return data ? data.charAt(0).toUpperCase() + data.slice(1) : 'None';
            }
        },
        {
            name: 'cluster_group_name',
            label: 'Cluster Group',
            type: 'string',
            filterable: true
        },
        {
            name: 'cluster_name',
            label: 'Cluster',
            type: 'string',
            filterable: true,
            render: (data: any, row: any) => {
                return row.cluster_id ? data : row.other_cluster_name
            }
        },
        {
            name: 'mts_label',
            label: 'Manual Tracking Source',
            type: 'string',
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                ...leadMts,
            ],
            filterable: true,
            sortable: false,
            render: (data: string, row: any) => {
                if (row.source === 'input-agent' && permissions['lead.change-mts']) {
                    return <InputAgentMtsSelect
                        id={row.id}
                        mts={row.mts}
                        setIsLoading={setIsLoading}
                        submitCallback={() => {
                            setReDrawDT(new Date().getTime());
                        }}
                    />
                } else {
                    return data === 'undefined' ? '' : data;
                }
            }
        },
        {
            name: 'page_source',
            label: 'Page Source',
            type: 'string',
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: '1', value: 'Short' },
                { key: '2', value: 'Custom Page' },
                { key: '3', value: 'External (EMD)' },
            ],
            filterable: true,
            sortable: false,
            render: (data: any, row: any) => {
                switch (data) {
                    case 0:
                        return "Normal"
                    case 1:
                        return "Short"
                    case 2:
                        return "Custom"
                    default:
                        return "UNDEFINED"
                }
            }
        },
        {
            name: 'source_label',
            label: 'Source',
            type: 'string',
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                ...leadSources
            ],
            filterable: true,
            sortable: false,
        },
        {
            name: 'agent_name',
            label: 'Agent',
            type: 'string',
            filterable: true
        },
        ...(
            moreDetail ? [
                {
                    name: 'duplicated_by_name',
                    label: 'Duplicated By',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'placement_type',
                    label: 'Placement Type',
                    type: 'string',
                    filterable: true,
                    render: (data: any) => {
                        return data || '-';
                    }
                },
                {
                    name: 'overflow_level',
                    label: 'Overflow Level',
                    type: 'string',
                    filterable: true,
                    render: (data: any) => {
                        return data || '-';
                    }
                },
                {
                    name: 'utm_source',
                    label: 'UTM Source',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_medium',
                    label: 'UTM Medium',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_campaign',
                    label: 'UTM Campaign',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'utm_content',
                    label: 'UTM Content',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'referrer',
                    label: 'Referrer',
                    type: 'string',
                    filterable: true,
                },
                {
                    name: 'keyword',
                    label: 'Keyword',
                    type: 'string',
                    filterable: true
                },
                // {
                //     name: 'gclid',
                //     label: 'Gclid',
                //     type: 'string',
                //     filterable: true
                // },
                // {
                //     name: 'fbclid',
                //     label: 'FBClid',
                //     type: 'string',
                //     filterable: true
                // },
                {
                    name: 'adsetname',
                    label: 'Adsetname',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'adgroupid',
                    label: 'Adgroupid',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'creative',
                    label: 'Creative',
                    type: 'string',
                    filterable: true
                },
                {
                    name: 'lead_value',
                    label: 'Lead Value',
                    type: 'number',
                    filterable: true,
                    style: { minWidth: 120 },
                    render: (data: number) => {
                        return data ? numberToCurrency(data, 'Rp. ') : '-'
                    }
                },
                {
                    name: 'response_time_in_minutes',
                    label: 'Response Time',
                    type: 'number',
                    filterable: true,
                    render: (data: any) => {
                        return data ? `${data} Menit` : '-';
                    }
                },
                {
                    name: 'is_client_chat_first',
                    label: 'Client Chat Duluan',
                    type: 'string',
                    filterable: true,
                    options: [
                        {
                            key: '',
                            value: 'All'
                        },
                        {
                            key: '1',
                            value: 'Yes'
                        },
                        {
                            key: '0',
                            value: 'No'
                        }
                    ],
                    render: (data: any) => {
                        return data === 1 ? 'Yes' : 'No'
                    }
                },
            ] : []
        ),
        {
            name: 'notes',
            label: 'Notes',
            type: 'string',
            filterable: false,
            style: { minWidth: 300 },
        },
        // {
        //     name: 'placement_log',
        //     label: 'Placement Log',
        //     type: 'string',
        //     filterable: false,
        //     render: (data: any) => {
        //         return (
        //             <div className={classes.actionContainer}>
        //                 <Tooltip title="View">
        //                     <IconButton 
        //                         color="primary" 
        //                         onClick={() => {
        //                             setSelectedLog(data);
        //                             handleModalLogOpen();
        //                         }}
        //                     >
        //                         <VisibilityIcon />
        //                     </IconButton>
        //                 </Tooltip>
        //             </div>
        //         );
        //     }
        // },
        ...(
            permissions['lead.delete']
                ? [
                    {
                        name: 'EXTRA',
                        label: 'Action',
                        type: 'string',
                        filterable: false,
                        sortable: false,
                        render: (row: any) => {
                            return (
                                <div className={classes.actionContainer}>
                                    {row.agent_name === 'CP Admin' &&
                                        <IconButton
                                            color="primary"
                                            component="span"
                                            title='Reassign Agent'
                                            onClick={() => { setReassignId(row.id) }}
                                        >
                                            <PersonAddAlt1Icon />
                                        </IconButton>
                                    }
                                    <IconButton
                                        color="secondary"
                                        component="span"
                                        onClick={() => setDeletedId(row.id)}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            );
                        }
                    }
                ]
                : []
        )
    ];

    const inputDeleteReason = useRef<HTMLInputElement>(null);
    const [leadId, setLeadId] = useState('');
    const [reassignId, setReassignId] = useState('');
    const [deletedId, setDeletedId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalDetailOpen, setModalDetailOpen] = useState(false);
    // const [modalLogOpen, setModalLogOpen] = useState(false);
    // const [selectedLog, setSelectedLog] = useState({});
    const [reDrawDT, setReDrawDT] = useState(new Date().getTime());
    const [fetchCount, setFetchCount] = useState(0)
    const defaultValueInformation = {
        total: 0,
        percentage: 0
    };
    const [leadsInformation, setLeadsInformation] = useState<any>({
        closing: defaultValueInformation,
        booking_fee: defaultValueInformation,
        hot: defaultValueInformation,
        warm: defaultValueInformation,
        cold: defaultValueInformation,
        bodong: defaultValueInformation,
        score_agent: 0,
        score_client: 0,
        lead_value: 0,
    });

    const [isNewLeadSystem, setIsNewLeadSystem] = useState(false);

    const [searchValue, setSearchValue] = useState('')
    const [searchedItems, setSearchedItems] = useState<SearchedItem[]>([])

    useEffect(() => {
        DefaultAxios.get(process.env.REACT_APP_API_URL + '/site-setting/is-new-lead-system')
            .then(res => {
                setIsNewLeadSystem(!!res.data);
            })
    }, []);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalDetailOpen = () => setModalDetailOpen(true);

    const handleModalClose = (fetch: boolean) => {
        // setLeadId('');
        setModalOpen(false);
        if (fetch) {
            setFetchCount(fetchCount + 1)
            setReDrawDT(new Date().getTime());
        }
    }

    const handleModalDetailClose = () => {
        setLeadId('');
        setModalDetailOpen(false);
    }

    // const handleModalLogOpen = () => setModalLogOpen(true);
    // const handleModalLogClose = () => setModalLogOpen(false);

    const handleEdit = (id: string) => {
        setLeadId(id);
        handleModalOpen();
    }

    const handleDetail = (id: string) => {
        setLeadId(id);
        handleModalDetailOpen();
    }

    const handleDelete = () => {
        const reason = inputDeleteReason!.current!.value;

        if (!reason) {
            return;
        }

        setIsLoading(true);
        DefaultAxios
            .post(`${API_URL}/${deletedId}/delete`, { reason })
            .then(res => {
                Swal.fire({
                    title: "Leads berhasil dihapus",
                    icon: 'success',
                    timer: 1000
                })
                    .then(() => {
                        setReDrawDT(new Date().getTime());
                        setDeletedId('');
                    })
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleClickExport = () => {
        DefaultAxios.get(
            API_URL + `/export/leads.xlsx${window.location.search}`,
            {
                responseType: 'blob'
            }
        )
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `leads.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
    }

    const handleSearch = () => {
        if (searchValue) {
            setIsLoading(true);
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/lead/search-by-phone`, { phone: searchValue })
                .then(res => res.data)
                .then(data => {
                    setSearchedItems(data)
                })
                .catch(err => {
                    if (err.response.status === 404) {
                        renderErrorButton('Tidak menemukan customer dengan nomor HP tersebut')
                    } else {
                        generalErrorHandler(err)
                    }
                })
                .finally(() => { setIsLoading(false); });
        }
    }

    const handleWhatsappPreview = (id: number) => {
        setIsLoading(true)

        let params = window.location.search

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/wa-chats/lead${params || ''}`, { params: { id, freshness: filter.freshness } })
            .then(res => res.data)
            .then(data => {
                setChatsData({
                    chats: data.data,
                    code: data.code,
                    created_at: data.created_at,
                    next_id: data.next_id,
                    prev_id: data.prev_id,
                })
            })
            .catch(err => generalErrorHandler(err))
            .finally(() => {
                setIsLoading(false)
            })
    }

    const renderFreshnessFilter = (
        <Grid item xs={12}>
            <BottomNavigation
                value={filter.freshness}
                onChange={(event, newValue) => {
                    setFilter(prev => ({
                        ...prev,
                        freshness: newValue,
                    }))
                }}
                showLabels
                className={classes.filter}
                style={props.isApp ? { width: '100%', height: '40px' } : {}}
            >
                <BottomNavigationAction
                    label="All"
                    value={''}
                    classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }}
                    style={props.isApp ? { flex: 1 } : {}}
                />
                <BottomNavigationAction
                    label="Fresh"
                    value={'1'}
                    classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }}
                    style={props.isApp ? { flex: 1 } : {}}
                />
                <BottomNavigationAction
                    label="Recycle"
                    value={'0'}
                    classes={{ root: classes.filterButton, label: classes.filterButtonLabel, selected: classes.filterButtonSelected }}
                    style={props.isApp ? { flex: 1 } : {}}
                />
            </BottomNavigation>
        </Grid>
    )

    const renderScoreSummary = () => {
        const capitalizeFirstLetter = (string: string) => {
            let text = string.charAt(0).toUpperCase() + string.slice(1);
            return text.replace("_", " ");
        }

        return (
            <>
                {['closing', 'booking_fee', 'hot', 'warm', 'cold', 'bodong'].map((status) => {
                    return (
                        <Fragment key={status}>
                            <Typography>
                                {capitalizeFirstLetter(status)}: {leadsInformation[status].total} ({leadsInformation[status].percentage}%)
                            </Typography>
                            <Typography style={{ marginRight: '10px', marginLeft: "10px" }}>
                                |
                            </Typography>
                        </Fragment>
                    )
                })}
                <Typography>
                    Score Agent: {leadsInformation.score_agent || '0'}
                </Typography>
                <Typography style={{ marginRight: '10px', marginLeft: "10px" }}>
                    |
                </Typography>
                <Typography>
                    Score Client: {leadsInformation.score_client || '0'}
                </Typography>
                <Typography style={{ marginRight: '10px', marginLeft: "10px" }}>
                    |
                </Typography>
                <Typography>
                    Lead Value: {numberToCurrency(leadsInformation.lead_value || 0, 'Rp. ')}
                </Typography>
            </>
        )
    }

    return (
        <Root>
            {
                isNewLeadSystem
                    ? <NewLeadForm
                        open={modalOpen}
                        onClose={handleModalClose}
                        leadId={leadId}
                    />
                    : <LeadForm
                        open={modalOpen}
                        onClose={handleModalClose}
                        leadId={leadId}
                    />
            }

            <WhatsappPreviewModal
                open={!!chatsData.chats.length}
                chats={chatsData.chats}
                code={chatsData.code}
                created_at={chatsData.created_at}
                onClose={() => {
                    setChatsData(INITIAL_CHATS_DATA)
                }}
                pagination={{
                    hasNext: chatsData.next_id !== null,
                    hasPrev: chatsData.prev_id !== null,
                }}
                onNextPressed={() => {
                    if (chatsData.next_id !== null) handleWhatsappPreview(chatsData.next_id)
                }}
                onPrevPressed={() => {
                    if (chatsData.prev_id !== null) handleWhatsappPreview(chatsData.prev_id)
                }}
            />

            <LeadDetail
                open={modalDetailOpen}
                onClose={handleModalDetailClose}
                leadId={leadId}
                handleEdit={handleEdit}
                isMobile={isMobile}
                fetchCount={fetchCount}
            />

            <Dialog
                open={searchedItems.length > 0}
                onClose={() => setSearchedItems([])}
                fullWidth
                maxWidth="lg"
            >

                <DialogTitle>Lead Detail</DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Tanggal
                                    </TableCell>
                                    <TableCell>
                                        Client Name
                                    </TableCell>
                                    <TableCell>
                                        Cluster
                                    </TableCell>
                                    <TableCell>
                                        Agent
                                    </TableCell>
                                    <TableCell>
                                        Agent Phone
                                    </TableCell>
                                    <TableCell>
                                        Notes
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    searchedItems.map((item, index) =>
                                        <TableRow key={index}>
                                            <TableCell>
                                                {item.created_at ? format(new Date(item.created_at), 'dd-MM-yyyy HH:mm:ss') : '-'}
                                            </TableCell>
                                            <TableCell>
                                                {item.client_name || '-'}
                                            </TableCell>
                                            <TableCell>
                                                {item.cluster_name || '-'}
                                            </TableCell>
                                            <TableCell>
                                                {item.agent_name || '-'}
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    item.agent_phone ?
                                                        <>
                                                            <span style={{ marginRight: 4 }}>
                                                                {item.agent_phone}
                                                            </span>
                                                            <IconButton
                                                                href={`https://api.whatsapp.com/send?phone=${convertIndonesiaPhoneNumber(item.agent_phone)}`}
                                                                target="_blank"
                                                                rel='noopener noreferrer'
                                                            >
                                                                <WhatsApp color='success' />
                                                            </IconButton>
                                                        </>
                                                        :
                                                        '-'
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {item.notes || '-'}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>

            <DeleteLeadModal
                open={deletedId !== ''}
                onClose={() => setDeletedId('')}
                inputRef={inputDeleteReason}
                onDeleteLead={handleDelete}
            />

            <ReassignAgentModal
                leadId={reassignId}
                open={reassignId !== ''}
                onClose={(reload = false) => {
                    setReassignId('');
                    if (reload) {
                        setReDrawDT(new Date().getTime());
                    }
                }}
            />

            {
                props.isApp ?
                    <div style={AppDataTablePageStyle}>
                        <AppDataTable
                            url={API_URL}
                            customFilterComponent={(
                                <Grid container>
                                    {renderFreshnessFilter}
                                </Grid>
                            )}
                            customParams={filter}
                            columns={columns}
                            reDraw={reDrawDT}
                        />
                    </div>
                    :
                    <>
                        <LoadingScreen open={isLoading} fullScreen />

                        {props && !props.is_unassigned ?
                            <>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        {
                                            permissions['lead.add-admin'] &&
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                className={classes.addButton}
                                                startIcon={<AddIcon />}
                                                onClick={handleModalOpen}
                                            >
                                                Add New Lead
                                            </Button>
                                        }
                                    </Grid>
                                    <Grid item>
                                        {
                                            permissions['lead.export'] &&
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                className={classes.addButton}
                                                startIcon={<GetAppIcon />}
                                                onClick={handleClickExport}
                                            >
                                                Export
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} style={{ marginBottom: '10px' }}>
                                    <Grid item xs={9} sm={4} md={2}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Search"
                                            value={inputNumber(searchValue)}
                                            placeholder="Input phone here"
                                            onChange={(e) => setSearchValue(inputNumber(e.target.value))}
                                        />
                                    </Grid>
                                    <Grid item xs={2} sm={4} md={6} className={classes.buttonContainer}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSearch}
                                        >
                                            Search
                                        </Button>
                                    </Grid>
                                </Grid>

                                {(permissions['lead.see-summary-data'] && showBreakdown) &&
                                    <Grid container justifyContent="center">
                                        <Grid item style={{ display: 'flex' }}>
                                            {renderScoreSummary()}
                                        </Grid>
                                    </Grid>
                                }
                            </> : ""
                        }
                        <Grid>
                            {renderFreshnessFilter}
                        </Grid>

                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={moreDetail}
                                            onChange={() => { setMoreDetail(!moreDetail) }}
                                            color="primary"
                                        />
                                    }
                                    label="More Detail"
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={showBreakdown}
                                            onChange={() => {
                                                setShowBreakdown(prev => !prev)
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Show Summary"
                                />
                            </Grid>
                        </Grid>

                        <DataTable
                            url={API_URL}
                            columns={columns}
                            reDraw={reDrawDT}
                            responseCallback={(res: any) => {
                                if (permissions['lead.see-summary-data'] && res.meta.current_page === 1) {
                                    const checkNominalPercentage = (nominal: number, total: number) => {
                                        return res.total > 0 ? (nominal / total * 100).toFixed(2) : 0
                                    }

                                    const data: any = {};
                                    ['closing', 'booking_fee', 'hot', 'warm', 'cold', 'bodong', 'score_agent'].map((status) => {
                                        data[status] = {
                                            total: res[status],
                                            percentage: checkNominalPercentage(res[status], res.total)
                                        }

                                        return true;
                                    })
                                    data.score_agent = res.score_agent;
                                    data.score_client = res.score_client;
                                    data.lead_value = res.lead_value || 0;


                                    setLeadsInformation(data);
                                }
                            }}
                            isHeaderSticky
                            tableContainerMaxHeight={props.isApp ? 'calc(100vh - 140px)' : 'calc(100vh - 230px)'}
                            customParams={filter}
                            customParamsFromFirstPage={breakdownFilter}
                        />
                    </>
            }
        </Root>
    );
}

export default LeadList;