import React, { useState, useContext } from 'react'

/**
 * Components
 */
import { Button, IconButton, Tooltip } from '@mui/material';
import Swal from 'sweetalert2';
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';
import LeadGroupForm from './LeadGroupForm';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';

/**
 * Icons
 */
import { Add, Delete, Edit, RoomPreferences } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import LeadGroupDetail from './LeadGroupDetail';
import LeadClusterPlacementModal from './LeadClusterPlacementModal';

/**
 * Contexts
 */
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyles';

const useStyles = generateStyle(theme => ({
    actionContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    addButton: {
        marginBottom: '20px'
    },
    importButton: {
        float: 'right'
    },
    errorContainer: {
        maxHeight: '200px',
        overflowY: 'auto'
    }
}), "LeadGroup_List")

const LeadGroupList = () => {
    const API_URL = `${process.env.REACT_APP_API_URL}/lead-group`;
    const { Root, classes } = useStyles();

    const permissions = useContext(PermissionContext)

    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState<number | boolean>(false);
    const [modalDetailOpen, setModalDetailOpen] = useState<number | boolean>(false);

    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const [leadPlacementValue, setLeadPlacementValue] = useState({
        id: "",
        name: ""
    });

    const columns = [
        {
            name: 'name',
            label: 'Name',
            type: 'string',
            filterable: true,
            render: (data: string, row: any) => (
                <>
                    {data}
                    <Tooltip title="Change Name">
                        <IconButton
                            color="primary"
                            component="span"
                            onClick={() => handleEdit(row.id)}
                        >
                            <Edit fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>
            )
        },
        {
            name: 'telesales_name',
            label: 'Telesales Name',
            type: 'string',
            filterable: true,
            render: (data: string) => data || '-'
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return (
                    <div className={classes.actionContainer}>
                        {/* {permissions['group.edit'] &&
                        } */}
                        <Tooltip title="Manage Clusters">
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => handleDetail(row.id)}
                            >
                                <RoomPreferences fontSize="small" />
                            </IconButton>
                        </Tooltip>

                        {
                            permissions['cluster.manage-member'] &&
                            <Tooltip title="View Cluster Placement">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => setLeadPlacementValue({
                                        id: row.id,
                                        name: row.name
                                    })}
                                >
                                    <GroupIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }

                        <Tooltip title="Delete Group">
                            <IconButton
                                color="error"
                                component="span"
                                onClick={() => handleDelete(row.id)}
                            >
                                <Delete fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {/* {permissions['user.reset-password'] &&
                        } */}
                    </div>
                );
            }
        },
    ];

    const handleModalClose = (fetch: boolean) => {
        setModalOpen(false);
        if (fetch) {
            setReDrawDatatable(new Date().getTime());
        }
    }

    const handleModalDetailClose = (fetch: boolean) => {
        setModalDetailOpen(false)
        if (fetch) {
            setReDrawDatatable(new Date().getTime());
        }
    }

    const handleEdit = (id: number) => {
        setModalOpen(id)
    }

    const handleDetail = (id: number) => {
        setModalDetailOpen(id)
    }

    const handleDelete = (id: number) => {
        renderWarningButton('Apakah anda yakin ingin menghapus group ini?')
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .delete(`${API_URL}/${id}`)
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil menghapus",
                                icon: 'success',
                                timer: 1000
                            })
                                .then(res => {
                                    setReDrawDatatable(new Date().getTime());
                                })
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />

            <LeadClusterPlacementModal
                isOpen={!!leadPlacementValue.id}
                lead_id={leadPlacementValue.id}
                lead_name={leadPlacementValue.name}
                onClose={() => {
                    setLeadPlacementValue({
                        id: '',
                        name: ''
                    })
                }}
            />

            <LeadGroupForm
                open={!!modalOpen}
                onClose={handleModalClose}
                groupId={typeof modalOpen === 'number' ? modalOpen : undefined}
            />

            <LeadGroupDetail
                open={!!modalDetailOpen}
                onClose={handleModalDetailClose}
                groupId={typeof modalDetailOpen === 'number' ? modalDetailOpen : 0}
            />

            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.addButton}
                startIcon={<Add />}
                onClick={() => setModalOpen(true)}
            >
                Add New Group
            </Button>
            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </Root>
    );
}

export default LeadGroupList
