
import React from 'react';
import {
    Grid,
    Paper,
    Typography,
    Divider,
    Box,
} from '@mui/material';
import { generateStyle } from '../../_utils/DefaultStyles';

interface IProps {
    xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    title: string
    titleContent?: React.ReactElement
    content: Array<any>
    additionalContent?: React.ReactElement
    row?: boolean
}
const useStyles = generateStyle(theme => ({
    title: {
        fontWeight: 600,
    },
    content: {
        marginTop: theme.spacing(2),
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    box: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    h6_title: {
        color: theme.palette.primary.main,
    },
    body: {
        wordBreak: 'break-word',
    },
}), "Modal_Section", {
    CustomParent: Grid
})

const ModalSection = ({
    xs,
    md,
    title,
    content,
    titleContent,
    additionalContent,
    row
}: IProps) => {
    const { Root: SGrid, classes } = useStyles()
    const generateList = (array: Array<Array<string>>) => {
        return array.map((data: Array<string> | undefined, idx: number) => {
            if (typeof data === "undefined" || data === null) {
                return null;
            } else {
                return (
                    <Box className={classes.box} key={idx}>
                        <Grid>
                            <Typography variant="body1" className={classes.title}>
                                {data[0]}
                            </Typography>
                        </Grid>
                        <Grid style={{ marginTop: "7px" }}>
                            {typeof data[1] === 'string' || data[1] === null ?
                                <Typography variant="body2" className={classes.body}>
                                    {(data[1] ? data[1] : "-")}
                                </Typography>
                                : data[1]
                            }
                        </Grid>
                    </Box>
                )
            }
        }
        )
    }

    const generateListRow = (array: Array<Array<string>>) => {
        return array.map((data: Array<string> | undefined) => {
            if (typeof data === "undefined" || data === null) {
                return null;
            } else {
                return (
                    <Grid item xs={12} lg={3} key={data[0]}>
                        <Grid style={{ marginBottom: "16px" }}>
                            <Grid>
                                <Typography variant="body1" className={classes.title}>
                                    {data[0][0]}
                                </Typography>
                            </Grid>
                            <Grid style={{ marginTop: "7px" }}>
                                {typeof data[0][1] === 'string' || data[0][1] === null ?
                                    <Typography variant="body2" className={classes.body}>
                                        {(data[0][1] ? data[0][1] : "-")}
                                    </Typography>
                                    : data[0][1]}
                            </Grid>
                        </Grid>
                        <Grid style={{ marginBottom: "16px" }}>
                            <Grid>
                                <Typography variant="body1" className={classes.title}>
                                    {data[1][0]}
                                </Typography>
                            </Grid>
                            <Grid style={{ marginTop: "7px" }}>
                                <Typography variant="body2" className={classes.body}>
                                    {typeof data[1][1] === 'string' || data[1][1] === null ? (data[1][1] ? data[1][1] : "-") : data[1][1]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        }
        )
    }

    return (
        <SGrid item md={md ? md : 12} xs={xs ? xs : 12}>
            <Paper className={classes.paper}>
                {titleContent ?
                    titleContent
                    :
                    <Typography variant="h6" className={classes.h6_title}>
                        {title}
                    </Typography>
                }

                <Divider></Divider>

                <Box className={classes.content}>
                    {(typeof content === "object") ?
                        row ?
                            <Grid container>
                                {generateListRow(content)}
                            </Grid>
                            : generateList(content)
                        : content
                    }

                    {additionalContent}
                </Box>
            </Paper>
        </SGrid>
    );
}

export default ModalSection;