import { Button, Dialog, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete'

/**
 * Utils
 */
import { convertNumber } from '../../../_utils/Helper'

/**
 * Icons
 */
import { Grid } from '@mui/material'

type Props = {
    open: boolean
    statementList: IStatementList[]
    onClose: () => void
    onSubmit: (statement: IStatementList) => void
}

interface IStatementList {
    id: number
    type: string,
    trailer: string,
    amount: number
}

const initialStatement = {
    id: 0,
    type: '',
    trailer: '',
    amount: 0
}

export const typeOptions = [
    { label: 'Komisi Kantor', value: 'office_commission' },
    { label: 'PPN', value: 'ppn' },
    { label: 'PPH21 Setor Negara Advisor 1', value: 'pph21' },
    { label: 'PPH21 Setor Negara Advisor 2', value: 'pph21_cobroke' },
    { label: 'Pengembalian Pinjaman Advisor 1', value: 'return_loan' },
    { label: 'Pengembalian Pinjaman Advisor 2', value: 'return_loan_cobroke' },
    { label: 'Komisi Advisor 1', value: 'agent_commission' },
    { label: 'Komisi Advisor 2', value: 'agent_cobroke_commission' },
    { label: 'Telesales', value: 'telesales_commission' },
];


const StatementFormModal = (props: Props) => {
    const [statement, setStatement] = useState<IStatementList>(initialStatement)
    const handleChangeAutocomplete = (name: string, value: any) => {
        setStatement(prev => ({
            ...prev,
            id: value.id,
            trailer: value.trailer,
            amount: value.amount,
        }))
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStatement(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = () => {
        props.onSubmit(statement)
        props.onClose()
    }

    useEffect(() => {
        if (props.open) {
            setStatement(initialStatement)
        }
    }, [props.open])

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle>
                    Add Statement
                </DialogTitle>
                <DialogContent>
                    <Grid container rowSpacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12}>
                            <AsyncAutoComplete
                                label='Statement'
                                name='statement_id'
                                onChange={handleChangeAutocomplete}
                                url={`${process.env.REACT_APP_API_URL}/autocomplete/bank-account-statement?excluded_ids=${props.statementList.map((item) => item.id).join(',')}`}
                                clearable
                                onClear={() => {
                                    setStatement(prev => ({
                                        ...prev,
                                        id: 0,
                                        amount: 0,
                                        trailer: ''
                                    }))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Amount"
                                value={convertNumber(String(statement.amount), 'Rp. ')}
                                disabled
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Type"
                                name='type'
                                value={statement.type}
                                onChange={handleChange}
                                fullWidth
                                select
                            >
                                {
                                    typeOptions.map((type) => {
                                        return (
                                            <MenuItem value={type.value}>{type.label}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                style={{ float: 'right' }}
                                disabled={(!statement.id || !statement.type)}
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default StatementFormModal