import React, { useEffect, useState } from 'react'
import { Grid, TextField, Button, Box, Link } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * Icons
 */
import Swal from 'sweetalert2';

/**
 * Component
 */
import AddContentModal from './_components/AddContentModal';
import AddGroupModal from './_components/AddGroupModal';
import GroupCard from './_components/GroupCard';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyles';


interface IState {
    name: string;
    logo: File | null;
    logo_url?: string;
    provision_primary: string;
    provision_secondary: string;
    groups: IGroups[];
}

interface IGroups {
    label: string;
    contents: IContent[]
}

interface IContent {
    type: string;
    content: string | IContentSmall
}

type IContentSmall = { interest: string; label: string }

interface IParams {
    id: string;
}

interface IEditContent {
    indexCard?: number,
    index?: number,
    edit: boolean
}

interface ILogo {
    preview: string | null;
    file: File | null
}

const useStyles = generateStyle(theme => ({
    borderBottomLine: {
        borderBottom: '1px solid #eee'
    },
    buttonBottom: {
        justifyContent: "space-between"
    },
    cardSection: {
        border: "1px solid #eee",
        padding: "10px ",
    },
    groupContainer: {
        marginBottom: '15px',
        padding: "15px"
    },
    isGray: {
        background: "#eee"
    },
    addMargin: {
        marginBottom: "15px"
    },
    buttonGroup: {
        position: "fixed",
        bottom: '2rem',
        zIndex: 1
    },
    headerButton: {
        marginLeft: "auto"
    },
    cardHeader: {
        borderBottom: "1px solid #eee"
    },
    groupHead: {
        margin: "0px auto 10px auto"
    },
    imagePreview: {
        "& img": {
            width: "100%",
        },
        width: "200px",
        height: "200px"
    }

}), "BankForm")

const BankForm = () => {
    const { Root, classes } = useStyles()
    const navigate = useNavigate()
    const params = useParams<keyof IParams>() as IParams
    const [isEdit, setIsEdit] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [showContentModal, setShowContentModal] = useState(false)
    const [state, setState] = useState<IState>()
    const [groups, setGroups] = useState<IGroups[]>([])
    const [seletedGroup, setSelectedGroup] = useState<number | any>()
    const [editGroupName, setEditGroupName] = useState(false)
    const [editContent, setEditContent] = useState<IEditContent>({ edit: false })
    const [logo, setLogo] = useState<ILogo>()

    const toastMixin = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
    });

    const fetchDefaultValue = (id: string) => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/kpr-interest/${id}`)
            .then(res => {
                setState(res.data as IState)
                setGroups(res.data.groups as IGroups[])
                setLogo({
                    preview: res.data.logo_url,
                    file: null
                } as ILogo)
            })
            .catch(err => {
                console.log(err);
            });
    }

    const handleSubmit = () => {
        const data = {
            ...state as IState,
            logo: logo?.file || null,
            groups: [...groups as IGroups[]]
        }
        let fd = generateFd(data)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/kpr-interest${isEdit ? `/${params.id}` : ''}`, fd).then((res: any) => {
            Swal.fire(
                'Success',
                isEdit ? 'Bank Interest Updated' : 'Bank Interest Submitted',
                'success'
            )
            navigate('/bank-interest')
        })
            .catch(err => {
                generalErrorHandler(err)

            });
    }

    const generateFd = (data: IState) => {
        let fd = new FormData()

        /**
         * Add append patch if edit
         */
        if (isEdit) {
            fd.append('_method', 'PATCH');
        }

        for (let [key, value] of Object.entries(data)) {
            if (key === 'logo') {
                if (data.logo) {
                    fd.append('logo', data.logo)
                }
            } else if (key === 'groups') {
                for (let i = 0; i < data.groups.length; i++) {
                    for (let key in data.groups[i]) {
                        if (key === 'contents') {
                            for (let a = 0; a < data.groups[i].contents.length; a++) {
                                for (let keyContent in data.groups[i].contents[a]) {
                                    if (keyContent === 'content') {
                                        if (data.groups[i]['contents'][a].type === 'point') {
                                            for (let [k, v] of Object.entries(data.groups[i]['contents'][a].content)) {
                                                fd.append(`groups[${i}][contents][${a}][${keyContent}][${k}]`, v as string)
                                            }
                                        } else if (data.groups[i]['contents'][a].type === 'freetext') {
                                            fd.append(`groups[${i}][contents][${a}][${keyContent}]`, data.groups[i]['contents'][a][keyContent] as string)
                                        }
                                    } else if (keyContent === 'type') {
                                        fd.append(`groups[${i}][contents][${a}][${keyContent}]`, data.groups[i]['contents'][a][keyContent])
                                    }
                                }
                            }
                        } else if (key === 'label') {
                            if (groups[i][key] === null) {
                                fd.append(`groups[${i}][${key}]`, "")
                            } else {
                                fd.append(`groups[${i}][${key}]`, String(data.groups[i][key]))
                            }
                        }
                    }
                }
            } else if (value === null) {
                fd.append(key, "");
            } else {
                fd.append(key, value as string);
            }
        }
        return fd
    }

    const handleAddGroup = (name: string, edit?: boolean) => {
        if (!edit) {
            groups.push({
                label: name,
                contents: []
            })
        } else {
            groups[seletedGroup].label = name
        }
        setEditGroupName(false)
    }

    const handleAddContent = (indexGroup: number) => {
        setSelectedGroup(indexGroup)
        setShowContentModal(true)
    }

    const handleDeleteCard = (index: number) => {
        const currentGroup = [...groups]
        currentGroup.splice(index, 1)
        setGroups(currentGroup)
        toastMixin.fire({
            icon: 'success',
            title: 'Card deleted!'
        })
    }

    const handleEditCard = (index: number) => {
        setSelectedGroup(index)
        setEditGroupName(true)
        setAddModal(true)
    }

    const handleDeleteContent = (indexCard: number, index: number) => {
        const currentGroup = [...groups]
        currentGroup[indexCard].contents.splice(index, 1)
        setGroups(currentGroup)
        toastMixin.fire({
            icon: 'success',
            title: 'Content deleted!'
        })
    }

    const handleSubmitContent = (data: IContent, edit: boolean) => {
        if (edit) {
            groups[editContent.indexCard as number].contents[editContent.index as number] = data
        } else {
            groups[seletedGroup].contents.push(data)
        }
        setEditContent({
            edit: false
        })
    }

    const handleEditContent = (indexCard: number, index: number) => {
        setEditContent({
            indexCard: indexCard,
            index: index,
            edit: true
        })
        setShowContentModal(true)
    }

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;

        if (!target.files) return

        setLogo({
            preview: null,
            file: target.files[0]
        })
    }

    useEffect(() => {
        if (params.id) {
            setIsEdit(true)
            fetchDefaultValue(params.id)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <Root>
            <Grid container spacing={2} className={classes.addMargin}>
                <Grid item xs={12}>
                    <h1 className={classes.borderBottomLine}>{isEdit ? "Edit Bank" : "Add Bank"}</h1>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12} md={6}>
                        <TextField label="Name" value={state?.name || ""} fullWidth onChange={(e) => setState({ ...state as IState, name: e.target.value })} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12} md={6}>
                        <TextField label="Provision Primary" value={state?.provision_primary || ""} defaultValue={state?.provision_primary} fullWidth onChange={(e) => setState({ ...state as IState, provision_primary: e.target.value.replace(/[^.\d]/g, '') as string })} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12} md={6}>
                        <TextField label="Provision Secondary" value={state?.provision_secondary || ""} defaultValue={state?.provision_secondary} fullWidth onChange={(e) => setState({ ...state as IState, provision_secondary: e.target.value.replace(/[^.\d]/g, '') as string })} />
                    </Grid>
                </Grid>
            </Grid>

            {/* begin group container */}

            <>
                <Grid container sx={{ mb: 3 }}>
                    <Grid item xs={12} md={6} className={classes.isGray} sx={{ p: 2 }}>
                        <Grid item xs={12}>
                            <h1 className={classes.groupHead}>Group</h1>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 3 }}>
                            {
                                groups.length > 0 &&
                                groups.map((group, index) => {
                                    return (
                                        <GroupCard
                                            key={index}
                                            index={index}
                                            group={group}
                                            onAddContent={handleAddContent}
                                            onDelete={handleDeleteCard}
                                            onEdit={handleEditCard}
                                            onDeleteContent={handleDeleteContent}
                                            onEditContent={handleEditContent}
                                        />
                                    )
                                })}

                            {showContentModal &&
                                <AddContentModal
                                    id={seletedGroup}
                                    open={showContentModal}
                                    onClose={() => {
                                        setShowContentModal(false)
                                        setEditContent({ edit: false })
                                    }}
                                    onSubmit={handleSubmitContent}
                                    defaultValue={groups[editContent.indexCard as number]?.contents[editContent.index as number]}
                                    edit={editContent.edit}
                                />
                            }

                        </Grid>
                        <Button variant='contained'
                            onClick={() => setAddModal(true)}>
                            Add Group
                        </Button>
                    </Grid>
                </Grid>
            </>

            {/* end group container */}

            {addModal &&
                <AddGroupModal
                    open={addModal}
                    onClose={() => {
                        setAddModal(false);
                        setEditGroupName(false)
                    }}
                    onSubmit={handleAddGroup}
                    edit={editGroupName}
                    defaultValue={editGroupName ? groups[seletedGroup]?.label : ""}
                />
            }
            <Grid container>
                <Grid item xs={12}>
                    <h3>Logo</h3>

                    {/* preview logo */}
                    {isEdit &&
                        <>
                            {logo && logo.preview !== null ?
                                <>
                                    <div className={classes.imagePreview}>
                                        <img src={logo.preview} alt="logo" />
                                    </div>
                                    <div>
                                        <p></p>
                                        <Link
                                            component="button"
                                            color="secondary"
                                            onClick={() => setLogo({ preview: null, file: null })}>
                                            Delete
                                        </Link>
                                    </div>
                                </> : ""
                            }
                        </>
                    }
                    <input type="file" name="logo" onChange={handleChanged} style={{ marginBottom: '10px' }} />
                    <br />
                    <i>Recommended dimension : 500 x 500</i>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button variant='contained'
                            onClick={handleSubmit}
                            disabled={groups.every((group) => group.contents.length <= 0)}>
                            Submit</Button>
                    </Box>
                </Grid>
            </Grid>
        </Root>
    )
}

export default BankForm