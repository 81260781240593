import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import ButtonWithLoading from '../../_components/_form/ButtonWithLoading'
import { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete'
import DefaultAxios from '../../_utils/DefaultAxios'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../_utils/Validation'
import { generalErrorHandler, renderToastSuccess } from '../../_utils/Helper'
import AsyncMultipleAutoComplete from '../../_components/_form/AsycMultipleAutoComplete'

export type PICUserGroupFormModalState = {
    group_ids?: string | null
    group_names?: string | null
    id?: string
}

interface PICUserGroupFormModalProps {
    isOpen: boolean
    onClose: () => void
    onFinish: () => void
    label: string;
    editData?: PICUserGroupFormModalState;
    setTargetIds: Function;
    targetIds: IAutoCompleteOption[]
}

const PICUserGroupFormModal = (props: PICUserGroupFormModalProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState<PICUserGroupFormModalState>(INITIAL_STATE)
    const [error, setError] = useState<IValidationErrors<PICUserGroupFormModalState>>({})

    useEffect(() => {
        if (!props.isOpen) {
            setState(INITIAL_STATE)
            setError({})
        }
    }, [props.isOpen])

    useEffect(() => {
        if (props.isOpen && props.editData) {
            setState(props.editData);

            // mengeset group_ids dan group_names jadi format [{id, label}]
            const groupIdsArray = props?.editData?.group_ids?.split(',').map(Number) ?? [];
            const groupNamesArray = props?.editData?.group_names?.split(',') ?? [];

            const mappedOptions = groupIdsArray?.map((id, index) => ({
                id: id,
                label: groupNamesArray ? groupNamesArray[index] || '' : undefined
            }));

            props.setTargetIds(mappedOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.editData, props.isOpen])

    const handleAutoCompleteMultipleChange = (name: string, value: IAutoCompleteOption[]) => {
        props.setTargetIds(value);
        const groupIds = value.map(option => Number(option.id));
        setState(prevState => ({
            ...prevState,
            group_ids: groupIds.join(',') // Mengubah array menjadi string
        }));
    };

    const handleSubmit = () => {
        const { errors, isValid } = validateData(state, rules, alias)
        setError(errors)

        if (isValid) {
            setIsLoading(true)
            DefaultAxios.post(process.env.REACT_APP_API_URL + '/pic-user-group', {
                group_ids: state?.group_ids?.split(',').map(Number),
                id: state.id
            })
                .then(res => res.data)
                .then(() => {
                    renderToastSuccess('Berhasil mengubah data')
                    props.onClose()
                    props.onFinish()
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={() => isLoading ? null : props.onClose()}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>Group Names</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ paddingTop: 8 }}>
                    <Grid item xs={12}>
                        <AsyncMultipleAutoComplete
                            label={props.label}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/lead-group`}
                            name="group_ids"
                            value={props.targetIds.map(option => ({
                                ...option,
                                id: Number(option.id),
                            }))}
                            error={error.group_ids ? true : false}
                            onChange={handleAutoCompleteMultipleChange}
                            helperText={error.group_ids}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWithLoading
                    color="primary"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                >
                    Submit
                </ButtonWithLoading>
            </DialogActions>
        </Dialog>
    )
}

const INITIAL_STATE: PICUserGroupFormModalState = {
    group_ids: '',
    group_names: ''
}

const rules: IValidationRules = {
    group_ids: 'required'
}

const alias: IValidationAlias = {
    group_ids: 'Group'
}

export default PICUserGroupFormModal