import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material'
import LoadingScreen from '../../_components/LoadingScreen'
import { Grid } from '@mui/material'
import { TextField } from '@mui/material'
import BasicUploadForm from '../../_components/_form/BasicUploadForm'

/**
 * Utils
 */
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper'
import { IValidationErrors, validateData } from '../../_utils/Validation'
import DefaultAxios from '../../_utils/DefaultAxios'

/**
 * Icons
 */
import { Delete, Edit } from '@mui/icons-material'

interface SlpIconListProps {

}

type State = {
    id?: string
    name: string
    icon: null | File
}

const SlpIconList = (props: SlpIconListProps) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/slp-icon`
    const [reDraw, setReDraw] = useState(0)

    const [isFormOpen, setIsFormOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState<State>(INITIAL_STATE)
    const [error, setError] = useState<IValidationErrors<State>>({})

    const rules = useMemo(() => ({
        name: 'required',
        icon: state.id ? '' : 'required',
    }), [state.id])

    useEffect(() => {
        if (!isFormOpen) {
            setState(INITIAL_STATE)
            setError({})
        }
    }, [isFormOpen])

    const handleEdit = (row: any) => {
        setState({
            id: row.id,
            icon: null,
            name: row.name,
        })

        setIsFormOpen(true)
    }

    const handleDelete = (id: string) => {
        renderWarningButton('Apakah anda yakin ingin menghapus icon ini?')
            .then(res => res.value)
            .then(value => {
                if (value) {
                    setIsLoading(true)

                    DefaultAxios.delete(`${API_URL}/${id}`)
                        .then(res => res.data)
                        .then(data => {
                            renderToastSuccess('Berhasil menghapus icon')
                            setReDraw(prev => prev + 1)
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, files } = e.target

        if (files && files.length) {
            setState(prev => ({
                ...prev,
                [name]: files[0]
            }))
        } else {
            setState(prev => ({
                ...prev,
                [name]: value,
            }))
        }

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const submit = () => {
        const { errors, isValid } = validateData(state, rules)
        setError(errors)

        if (isValid) {
            setIsLoading(true)

            const fd = new FormData()
            fd.append('name', state.name)
            if (state.icon) fd.append('file', state.icon)

            DefaultAxios[state.id ? 'patch' : 'post'](`${API_URL}${state.id ? `/${state.id}` : ''}`, fd)
                .then(res => res.data)
                .then(data => {
                    renderToastSuccess(`Berhasil ${state.id ? 'mengubah' : 'menambahkan'} icon`)
                    setIsFormOpen(false)
                    setReDraw(prev => prev + 1)
                })
                .catch(generalErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Button
                variant='contained'
                color='primary'
                onClick={() => setIsFormOpen(true)}
                sx={{ mb: 2 }}
            >
                Add Icon
            </Button>
            <Dialog
                open={isFormOpen}
                onClose={() => setIsFormOpen(false)}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>
                    {state.id ? 'Ubah' : 'Tambah'} Icon
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3} sx={{ mt: -2 }}>
                        <Grid item xs={12}>
                            <TextField
                                label="Name"
                                name="name"
                                value={state.name}
                                error={!!error.name}
                                helperText={error.name}
                                variant="outlined"
                                fullWidth
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BasicUploadForm
                                label='Icon (SVG Only)'
                                name='icon'
                                onChange={handleChange}
                                accept='.svg'
                                errorText={error.icon}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={isLoading}
                        onClick={submit}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <DataTable
                url={API_URL}
                columns={[
                    {
                        label: 'Icon',
                        name: 'file_url',
                        filterable: false,
                        render: (data: any) => <img src={data} width={40} height={40} alt='icon' />
                    },
                    {
                        label: 'Name',
                        name: 'name',
                        type: 'string',
                        filterable: true,
                    },
                    {
                        label: 'Action',
                        name: 'EXTRA',
                        filterable: false,
                        render: (row: any) => (
                            <>
                                <Tooltip title='Edit'>
                                    <IconButton
                                        color="primary"
                                        component="span"
                                        onClick={() => handleEdit(row)}
                                    >
                                        <Edit fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Delete'>
                                    <IconButton
                                        color="secondary"
                                        component="span"
                                        onClick={() => handleDelete(row.id)}
                                    >
                                        <Delete fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )
                    },
                ]}
                reDraw={reDraw}
            />
        </>
    )
}

const INITIAL_STATE = {
    name: '',
    icon: null,
}

export default SlpIconList
