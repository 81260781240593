import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, MenuItem, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Typography, useTheme } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import DateRangePicker, { OnDatesChange } from '../_form/DateRangePicker'
import LoadingScreen from '../LoadingScreen'

/**
 * Utils
 */
import { format } from 'date-fns'
import { useInView } from 'react-intersection-observer'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generateStyle } from '../../_utils/DefaultStyles';

import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';

export type AppDataTableColumn = {
    name: string
    label: string | React.ReactNode
    render?: (
        dataOrRow: any,
        row: Item,
        columnName: string,
        options: any[],
        setRows: (items: Item[]) => void,
        rows: Item[]
    ) => React.ReactNode
    // Unused props
    type?: any
    filterable?: any
    defaultOption?: any
    options?: any
    sortable?: any
}

type Item = {
    [key: string]: any
}

interface AppDataTableProps {
    url: string
    columns: AppDataTableColumn[]
    /**
     * start with 0, so it won't trigger the first fetchData twice
     */
    responseCallback?: Function;
    reDraw?: number
    customFilterComponent?: React.ReactNode
    customParams?: { [key: string]: any }
}

/**
 * @example
 * const windowRN = window as WindowRN
 * if (windowRN.ReactNativeWebView) {
 *     windowRN.ReactNativeWebView.postMessage('data')
 * }
 */
export interface WindowRN extends Window {
    ReactNativeWebView?: {
        postMessage: (data: string) => void
    }
}

const AppDataTable = (props: AppDataTableProps) => {
    const { Root, classes } = useStyles()
    const [filterOpen, setFilterOpen] = useState(false)
    const theme = useTheme()

    const [ref] = useInView({
        onChange: (inView) => {
            if (!isFilteredLoading && !isPageLoading && inView && (hasMore)) {
                setPage(prev => prev + 1)
                setFetchPageFlag(prev => prev + 1)
            }
        },
    })

    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [items, setItems] = useState<Item[]>([])
    const [filter, setFilter] = useState<Record<string, any>>({})

    const [fetchFilteredFlag, setFetchFilteredFlag] = useState(0)
    const [fetchPageFlag, setFetchPageFlag] = useState(0)

    const [isFilteredLoading, setIsFilteredLoading] = useState(false)
    const [isPageLoading, setIsPageLoading] = useState(false)

    useEffect(() => {
        setIsFilteredLoading(true)
        fetchData(true)
        // eslint-disable-next-line
    }, [fetchFilteredFlag, props.customParams])

    useEffect(() => {
        if (fetchPageFlag) {
            setIsPageLoading(true)
            fetchData()
        }
        // eslint-disable-next-line
    }, [fetchPageFlag])

    useEffect(() => {
        if (props.reDraw) setFetchFilteredFlag(prev => prev + 1)
        // eslint-disable-next-line
    }, [props.reDraw])

    const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setFilter(prev => ({
            ...prev,
            search: value,
        }))

    }

    const onDateChange = (name: string) => ({ startDate, endDate }: OnDatesChange) => {
        setFilter(prev => ({
            ...prev,
            [name]: {
                start_date: startDate,
                end_date: endDate
            }
        }))
    }

    const convertedFilter = (filter: Record<string, any>) => {
        let obj = {}
        Object.entries(filter).map(([key, value]) => {
            if (typeof value === 'object') {
                if ("start_date" in value || "end_date" in value) {
                    if (value.start_date || value.end_date) {
                        return Object.assign(obj, { [key]: `${value.start_date ? format(new Date(value.start_date), 'dd/MM/yyyy') : ''} - ${value.end_date ? format(new Date(value.end_date), 'dd/MM/yyyy') : ''}` })
                    } else {
                        return Object.assign(obj, {})
                    }
                }
            }

            return Object.assign(obj, { [key]: value })
        })
        return obj
    }

    const fetchData = (isFiltered?: boolean) => {
        const curPage = isFiltered ? 1 : page

        if (isFiltered) {
            setPage(1)
        }

        DefaultAxios.get(props.url, {
            params: {
                page: curPage,
                ...convertedFilter(filter),
                ...props.customParams,
                see_total_data: 0
            }
        })
            .then(res => res.data)
            .then(res => {
                setItems(prev => isFiltered ? res.data : prev.concat(res.data))
                setHasMore(res.more)

                if (typeof props.responseCallback !== 'undefined') {
                    props.responseCallback(res);
                }
            })
            .finally(() => {
                setIsPageLoading(false)
                setIsFilteredLoading(false)
            })
    }

    const onChangeFilter = (name: string, value: any) => {
        setFilter(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmitFilter = () => {
        setFetchFilteredFlag(new Date().getTime())
        setFilterOpen(false)
    }

    const handleClose = () => {
        if (Object.keys(filter).length) {
            handleSubmitFilter()
        } else {
            setFilterOpen(false)
        }
    }

    const countFilter = useMemo(() => {
        return Object.entries(filter).filter(([key, value]) => {
            if (['search'].includes(key)) return false
            if (value === '' || value === undefined || value === null) return false
            if (typeof value === 'object') {
                if (!Object.values(value).filter(item => !!item).length) return false
            }

            return true
        }).length
    }, [filter])

    useEffect(() => {
        let timer: NodeJS.Timeout
        timer = setTimeout(() => {
            setFetchFilteredFlag(new Date().getTime())
        }, 300)
        return () => {
            clearTimeout(timer)
        }
    }, [filter.search])

    return (
        <Root>
            <div className={classes.root}>
                <div className={classes.headerContainer}>
                    <div className={classes.searchContainer}>
                        <div className={classes.searchInput}>
                            <TextField
                                placeholder='Cari kode, nama client atau cluster'
                                value={filter.search}
                                onChange={onChangeSearch}
                                size='small'
                                fullWidth
                                className={classes.searchInput}
                                inputProps={{
                                    style: {
                                        borderRadius: '8px'
                                    }
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <IconButton
                            style={{
                                border:
                                    `1px solid ${countFilter
                                        ? theme.palette.primary.main
                                        : '#ababab'}`,
                                borderRadius: '8px',
                                backgroundColor: countFilter ? '#D2EBFF' : ''
                            }}
                            onClick={() => setFilterOpen(true)}>
                            <TuneIcon color={countFilter ? 'primary' : 'inherit'} />
                            {
                                !!countFilter &&
                                <Typography fontSize={14} color={theme.palette.primary.main} fontWeight="700">{countFilter}</Typography>
                            }
                        </IconButton>
                    </div>
                </div>
                {props.customFilterComponent || null}
                <div className={classes.tableContainer} style={{ flex: (!items.length && (!isPageLoading && !isFilteredLoading)) ? '0 1 auto' : '1' }}>
                    <TableContainer sx={{ height: '100%' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {
                                        props.columns.map((column, index) =>
                                            <TableCell
                                                key={index}
                                                className={index === 0 ? classes.headerSticky : undefined}
                                            >
                                                {column.label}
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ position: 'relative' }}>
                                <LoadingScreen
                                    open={isFilteredLoading}
                                />
                                {
                                    items.map((item, index) =>
                                        <TableRow key={index} ref={index === items.length - 2 ? ref : undefined}>
                                            {
                                                props.columns.map((column, colIndex) =>
                                                    <TableCell
                                                        key={colIndex}
                                                        className={colIndex === 0 ? classes.bodySticky : undefined}
                                                    >
                                                        {
                                                            column.render ?
                                                                column.render(column.name === 'EXTRA' ? item : item[column.name], item, column.name, column.options, setItems, items)
                                                                :
                                                                item[column.name]
                                                        }
                                                    </TableCell>
                                                )
                                            }
                                        </TableRow>
                                    )
                                }
                                {
                                    (isPageLoading || isFilteredLoading) &&
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <CircularProgress />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {
                    !items.length && (!isPageLoading && !isFilteredLoading) &&
                    <div style={{ flex: '1', display: 'flex' }}>
                        <div style={{ margin: 'auto' }}>
                            No Data Available
                        </div>
                    </div>
                }
            </div>
            <Dialog
                open={filterOpen}
                fullScreen
                onClose={handleClose}
            >
                <DialogTitle>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
                        <span>Filter</span>
                        <CloseIcon onClick={handleClose} />
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2}>
                        {
                            props.columns.map((column) => {
                                if (column.type === 'date') {
                                    return (
                                        <Grid item xs={12} key={column.name}>
                                            <Typography sx={{ mb: 1 }}>{column.label}</Typography>
                                            <DateRangePicker
                                                startDate={filter[column.name]?.start_date || null}
                                                endDate={filter[column.name]?.end_date || null}
                                                onDatesChange={onDateChange(column.name)}
                                                onFinished={() => { }}
                                                fullWidth
                                                dateMaxWidth={'30%'}
                                                isApp
                                            />
                                        </Grid>
                                    )
                                }

                                return false
                            })
                        }
                        {
                            props.columns.map((column) => {
                                if (typeof column.options !== 'undefined') {
                                    return (
                                        <Grid item xs={12} key={column.name}>
                                            <Typography sx={{ mb: 1 }}>{column.label}</Typography>
                                            <Select
                                                name={column.name}
                                                value={(filter[column.name] !== '' && filter[column.name] !== undefined) ? filter[column.name] : ''}
                                                style={
                                                    (filter[column.name] !== '' && filter[column.name] !== undefined) ? {
                                                        border: `1px solid ${theme.palette.primary.main}`,
                                                        backgroundColor: '#D2EBFF'
                                                    } : {}}
                                                onChange={(e: SelectChangeEvent<any>, child: React.ReactNode) => onChangeFilter(column.name, e.target.value)}
                                                defaultValue=""
                                                displayEmpty
                                                fullWidth
                                            >
                                                {
                                                    column.options.map((item: any) => (
                                                        <MenuItem key={item.key} value={item.key} style={item.style && item.style}>{item.value}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </Grid>
                                    )
                                }

                                return false
                            })
                        }
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                onClick={handleSubmitFilter}
                                fullWidth
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 8,
        position: 'relative',
    },
    searchContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '5px',
        width: '100%'
    },
    searchInput: {
        flex: '1 1 0%'
    },
    filterButton: {
        aspectRatio: '1/1'
    },
    datePickerContainer: {
        position: 'absolute',
        right: 8,
    },
    tableContainer: {
        position: 'relative',
        flex: 1,
        display: 'flex',
        overflowX: 'auto',
    },
    headerSticky: {
        position: 'sticky',
        left: 0,
        top: 0,
        background: 'white',
        zIndex: 900,
    },
    bodySticky: {
        position: 'sticky',
        left: 0,
        background: 'white',
        zIndex: 800,
    },
}), "AppDataTable")

export const AppDataTablePageStyle = {
    height: '100vh',
    maxHeight: '100vh',
}

export default AppDataTable
