import { Divider, IconButton, Theme, Tooltip } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'
import React, { useMemo, useState } from 'react'
import { ShowingReportItem } from '../ShowingReport'
import PhotoIcon from '@mui/icons-material/Photo';
import ImagePreview from '../../../_components/ImagePreview'
import { generateStyle } from '../../../_utils/DefaultStyles';

const ShowingReportCard = (props: ShowingReportItem) => {
    const { Root, classes } = useStyles()

    const [selectedImage, setSelectedImage] = useState('')

    const limit = 6

    const [isExpanded, setIsExpanded] = useState(false)

    const total = useMemo(() => props.clusters.length, [props.clusters])

    const isUsingExpansion = (isExpanded || props.clusters.length <= limit)

    const renderContent = () => {
        return (
            props.clusters.slice(0, isUsingExpansion ? props.clusters.length : limit).map(cluster =>
                <div key={cluster} className={classes.cluster_row}>
                    <span className={classes.cluster_text}>
                        {cluster.split('|||')[0]}
                        {
                            cluster.substring(cluster.indexOf("|||") + 3) ?
                                <Tooltip title='Photo'>
                                    <IconButton
                                        color="primary"
                                        component="span"
                                        onClick={() => { setSelectedImage(cluster.substring(cluster.indexOf("|||") + 3)) }}
                                    >
                                        <PhotoIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                :
                                null
                        }
                    </span>
                </div>
            )
        )
    }

    return (
        <Root>
            <div className={classes.root} style={{ paddingBottom: props.clusters.length <= limit ? undefined : 4 }}>
                <ImagePreview
                    image={selectedImage}
                    onClose={() => setSelectedImage('')}
                />
                <span className={classes.name}>
                    {props.name}
                </span>
                <Divider className={classes.divider} />
                <div className="">
                    {
                        renderContent()
                    }
                </div>
                <div className={classes.total}>
                    <span className={classes.total_text}>
                        Total
                    </span>
                    <span className={classes.total_text}>
                        {total}
                    </span>
                </div>
                {
                    (props.clusters.length > limit) &&
                    <div
                        className={classes.showMoreContainer}
                        onClick={() => setIsExpanded(prev => !prev)}
                    >
                        {
                            isExpanded &&
                            <ChevronLeft className={`${classes.showMoreIcon} ${isExpanded ? classes.less : classes.more}`} />
                        }
                        <span className={classes.showMoreText}>
                            Show {isExpanded ? 'Less' : 'More'}
                        </span>
                        {
                            !isExpanded &&
                            <ChevronLeft className={`${classes.showMoreIcon} ${isExpanded ? classes.less : classes.more}`} />
                        }
                    </div>
                }
            </div>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        borderRadius: 20,
        boxShadow: '3px 3px 10px #52575d1a',
        padding: '21px 23px 33px',
        backgroundColor: 'white',
        alignSelf: 'start',
    },
    name: {
        color: '#3D3D3D',
        fontSize: 20,
        fontWeight: 700,
        display: 'flex',
        gap: 10,
        alignItems: 'center'
    },
    score: {
        color: '#3D3D3D',
        fontSize: 20,
        fontWeight: 700,
        textAlign: 'center'
    },
    divider: {
        borderColor: '#EBF2F7',
        marginTop: 13,
        marginBottom: 11,
    },
    cluster_row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 14,
    },
    cluster_text: {
        color: '#3D3D3D',
        fontSize: 14,
    },
    total: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#035AA633',
        padding: '10px 14px',
        borderRadius: 10,
    },
    total_text: {
        color: '#3D3D3D',
        fontSize: 16,
        fontWeight: 500,
    },
    showMoreContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContentL: 'center',
        marginTop: 8,
        cursor: 'pointer',
        opacity: 0.6,
    },
    showMoreText: {
        color: theme.palette.primary.main,
        fontSize: 12,
    },
    showMoreIcon: {
        color: theme.palette.primary.main,
        fontSize: 16,
    },
    more: {
        transform: 'rotate(-90deg)',
    },
    less: {
        transform: 'rotate(90deg)',
    },
    avatar: {
        width: '40px',
        height: '40px',
        borderRadius: '50%'
    }
}), "ShowingReport_Card"
)

export default ShowingReportCard