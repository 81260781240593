import { Table, TableRow } from '@mui/material'
import { TableContainer } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SortableTableHeader, { Order } from '../../../_components/_dataTable/SortableTableHeader'
import { TableCell } from '@mui/material'
import { TableBody } from '@mui/material'
import { TableHead } from '@mui/material'
import { generateStyle } from '../../../_utils/DefaultStyles'
import DashboardAdsModalDetail from './DashboardAdsModalDetail'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { IFacebookAds, IState } from '../DashboardAds'
import LoadingScreen from '../../../_components/LoadingScreen'
import ChipInfo from './ChipInfo'
import { calculateScore } from '../_utils/DashboardAdsHelper'
import { convertNumber, generalErrorHandler } from '../../../_utils/Helper'
import { format } from 'date-fns'

type Props = {
    firstColumnName?: string
    filter?: any
    url: string
    redraw: number
    customName?: string
    campaignAdset?: string
    clusterId?: number
    isDashboard?: boolean
    hideCPL?: boolean
}

const AdsMainTable = (props: Props) => {
    const { Root, classes } = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [selectedRow, setSelectedRow] = useState<IFacebookAds | null>(null)
    const [rows, setRows] = useState<IFacebookAds[]>([])
    const [state, setState] = useState<IState | Record<string, any>>({})
    const [sort, setSort] = useState<{
        order: string,
        dir: Order
    }>({
        order: '',
        dir: false
    })

    const handleSeeDetail = (data: any) => {
        setSelectedRow(data)
    }

    const handleSort = (att: string) => {
        let newSort = { ...sort }
        if (att === 'client_score_average') {
            if (newSort.order === att) {
                if (newSort.dir === 'desc') {
                    newSort.dir = 'asc'
                } else {
                    newSort.dir = false
                    newSort.order = ''
                }
            } else {
                newSort.dir = 'desc'
                newSort.order = att
            }
        } else {
            if (newSort.order === att) {
                if (newSort.dir === 'asc') {
                    newSort.dir = 'desc'
                } else {
                    newSort.dir = false
                    newSort.order = ''
                }
            } else {
                newSort.dir = 'asc'
                newSort.order = att
            }
        }

        setSort(newSort)
    }

    const fetchData = () => {
        if (props.isDashboard && !Object.keys(state).length) {
            return null
        }

        setIsLoading(true)

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}${props.url}`, props.campaignAdset ? {
            params: {
                date: props.filter ? props.filter['date'] as string : '',
                cluster_id: props.clusterId || 0,
                dir: sort.dir || null,
                order: sort.order || null,
            }
        } : {
            params: {
                date: (state.startDate || state.endDate) ? `${state.startDate ? format(new Date(state.startDate as Date), 'dd/M/yyyy') : ''}-${state.endDate ? format(new Date(state.endDate as Date), 'dd/M/yyyy') : ''}` : null,
                group_id: state.group_id !== 'all' ? state.group_id : null,
                dir: sort.dir || null,
                order: sort.order || null,
                name: state.search
            }
        })
            .then(res => res.data)
            .then(res => {
                setRows(res.data)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
                setState(prev => ({
                    ...prev,
                    fetch: 0
                }))
            })
    }

    const getCPLColor = (cpl: number, priceAverage: number) => {
        if (!cpl && !priceAverage) return 'warning'
        const standard = (priceAverage / 1000_000_000) * 20_000

        switch (true) {
            case cpl > standard:
                return 'danger'
            case cpl <= standard:
                return 'default'
            default: 
                return 'warning'
        }
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [state.group_id, sort, props.url])

    useEffect(() => {
        if (props.redraw) {
            fetchData()
        }

        // eslint-disable-next-line
    }, [props.redraw])

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (state.fetch) {
                fetchData()
            }
        }, 500)

        return () => {
            clearTimeout(timeout)
        }

        // eslint-disable-next-line
    }, [state.fetch])

    useEffect(() => {
        if (props.filter) {
            setState(props.filter)
        }
    }, [props.filter])

    return (
        <>
            <DashboardAdsModalDetail
                data={selectedRow}
                open={selectedRow !== null}
                customParams={{
                    'restrict_date_ads': (state.startDate || state.endDate) ? `${state.startDate ? format(new Date(state.startDate as Date), 'dd/M/yyyy') : ''}-${state.endDate ? format(new Date(state.endDate as Date), 'dd/M/yyyy') : ''}` : null,
                    'cluster_id': selectedRow?.id || 0
                }}
                onClose={() => {
                    setSelectedRow(null)
                }}
            />
            <LoadingScreen open={isLoading} fullScreen />
            <Root>
                <TableContainer style={{ maxHeight: '75vh', overflowX: 'auto', paddingRight: 10 }}>
                    <Table>
                        <TableHead className={classes.tableHead}>
                            <SortableTableHeader
                                headCells={[
                                    { name: 'name', label: props.firstColumnName ? props.firstColumnName : (props.campaignAdset ? 'Adset Name' : 'Cluster Name') },
                                    { name: 'client_score_average', label: 'Client Score', sortable: true },
                                    { name: 'leads_warm_and_hot_count', label: 'Warm + Hot', sortable: true },
                                    ...(props.hideCPL ? [] : [{ name: 'cpl', label: 'CPL', sortable: true }]),
                                    { name: 'leads_count', label: 'Lead', sortable: true },
                                    { name: 'showing_count', label: 'Showing', sortable: true },
                                    { name: 'closing_count', label: 'Closing', sortable: true },
                                ]}
                                onRequestSort={(e, property) => handleSort(property)}
                                order={sort.dir}
                                orderBy={sort.order}
                                sorting={true}
                            />
                        </TableHead>
                        <TableBody>
                            {
                                isLoading ?
                                    <TableRow>
                                        <TableCell>
                                            <LoadingScreen open={true} />
                                        </TableCell>
                                    </TableRow> :
                                    rows.length ?
                                        rows.map((data, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell height={85}>
                                                    {
                                                        props.campaignAdset ?
                                                            <span style={{ fontSize: 14, fontWeight: '700' }}>{data.name}</span> :
                                                            <div
                                                                style={{ fontSize: 14, cursor: 'pointer', fontWeight: '700', textDecoration: 'underline' }}
                                                                onClick={() => handleSeeDetail(data)}
                                                                onTouchStart={() => handleSeeDetail(data)}
                                                                role='button'
                                                                id="seeDetail"
                                                            >
                                                                {data.name}
                                                            </div>
                                                    }
                                                </TableCell>
                                                <TableCell height={85}><ChipInfo type="circle" status={calculateScore(data).scoreStatus} label={data.client_score_average || '-'} /></TableCell>
                                                <TableCell height={85}><ChipInfo type="circle" status={calculateScore(data).status} label={`${data.leads_warm_and_hot_count} \r\n (${calculateScore(data).warmPercentage} %)`} /></TableCell>
                                                {
                                                    props.hideCPL ?
                                                        null :
                                                        <TableCell height={85}><ChipInfo type="circle" status={getCPLColor(data.cpl, data.cluster_price_average)} label={convertNumber(String(data.cpl || 0), 'Rp. ')} /></TableCell>
                                                }
                                                <TableCell height={85}><ChipInfo type="polygon" label={data.leads_count} /></TableCell>
                                                <TableCell height={85}><ChipInfo type="polygon" label={data.showing_count} /></TableCell>
                                                <TableCell height={85}><ChipInfo type="polygon" label={data.closing_count} /></TableCell>
                                            </TableRow>
                                        )) :
                                        <TableRow>
                                            <TableCell colSpan={7}>
                                                Tidak ada data yang ditemukan
                                            </TableCell>
                                        </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Root>
        </>
    )
}

const useStyles = generateStyle(theme => ({
    tableHead: {
        position: 'sticky',
        top: 0,
        background: '#fff',
        zIndex: 2,
        overflow: 'hidden',
        marginBottom: 10,
        "&::before": {
            position: 'absolute',
            content: '""',
            width: '100%',
            height: '100%',
            borderRadius: 8,
            border: '2px solid #d8d8d8',
            pointerEvents: 'none',
        }
    },


}), "Dashboard_Ads")

export default AdsMainTable