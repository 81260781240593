import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import { TimePicker } from '@mui/x-date-pickers';

/**
 * Context
 */
// import { PermissionContext } from "../../_contexts/PermissionContext";

/**
 * Utils
 */
import helpers from '../../_utils/Helpers';
import DefaultAxios from '../../_utils/DefaultAxios';
import { format } from 'date-fns';

interface Props {
    open: boolean;
    onClose: (fetch: boolean) => void;
    day: number;
    day_label: string
    agent_id: string
}

interface IState {
    leads_start_time: Date | null
    leads_end_time: Date | null
}
const AgentLeadScheduleForm = ({ open, onClose, day, day_label, agent_id }: Props) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/user/lead-off-schedule`;

    const [inputState, setInputState] = useState<IState>({
        leads_start_time: null,
        leads_end_time: null
    });

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!open) {
            setInputState({
                leads_start_time: null,
                leads_end_time: null
            })
        }
        // eslint-disable-next-line
    }, [open])

    
    const checkValidation = () => {
        let isValid = true;

        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            // return;
        }

        const data = {
            day: day,
            agent_id: agent_id,
            start_time: inputState.leads_start_time ? format(new Date(inputState.leads_start_time), "HH:mm") : null,
            end_time: inputState.leads_end_time ? format(new Date(inputState.leads_end_time), "HH:mm") : null
        }

        let axios;

        axios = DefaultAxios.post(API_URL, { ...data });

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        onClose(true);
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }


    const handleTimeChanged = (name: string, date: any) => {
        setInputState(prev => ({
            ...prev,
            [name]: date
        }))
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            fullWidth
            maxWidth="md"
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>Hari: {day_label}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ pt: 1 }}>
                    <Grid item xs={12} md={6}>
                        <TimePicker
                            label="Start Time"
                            ampm={false}
                            value={inputState.leads_start_time}
                            onChange={(date: any) => handleTimeChanged('leads_start_time', date)}
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    variant="outlined"
                                    fullWidth
                                    label={props.label}
                                    value={inputState.leads_start_time}
                                    placeholder={props.placeholder ? props.placeholder : "dd/MM/yyyy"}
                                // error={props.error ? props.error : false}
                                // helperText={props.helperText ? props.helperText : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TimePicker
                            label="End Time"
                            ampm={false}
                            value={inputState.leads_end_time}
                            onChange={(date: any) => handleTimeChanged('leads_end_time', date)}
                            minTime={inputState.leads_start_time}
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    variant="outlined"
                                    fullWidth
                                    label={props.label}
                                    placeholder={props.placeholder ? props.placeholder : "dd/MM/yyyy"}
                                    error={props.error ? props.error : false}
                                    helperText={props.helperText ? props.helperText : ''}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AgentLeadScheduleForm;