import React, { useEffect, useState, useReducer } from 'react'
import { Button, Grid, TextField } from '@mui/material'
import Swal from 'sweetalert2'
import LoadingScreen from '../../../_components/LoadingScreen'
import TextEditor from '../../../_components/_form/TextEditor'

import DefaultAxios from '../../../_utils/DefaultAxios'
import helpers from '../../../_utils/Helpers'
import { generateStyle } from '../../../_utils/DefaultStyles'

interface IProps {
    // id?: string
    permissions?: any
}

interface IState {
    meta_title: string
    meta_description: string
    title: string
    article: string
}

interface IAction {
    name: string,
    value: any,
    type: string
}

const ArticleKPR = (props: IProps) => {
    const { Root, classes } = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const initialState = {
        meta_title: '',
        meta_description: '',
        title: '',
        article: '',
    }

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    // Error Reducer

    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    const handleEditorChange = (name: string, value: string) => {
        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;
        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const submit = () => {

        setIsLoading(true);
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/article-kpr`, inputState)
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        fetchData()
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const fetchData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/article-kpr`)
            .then(res => res.data)
            .then(res => {
                const newState = { ...inputState };
                newState.meta_title = res.meta_title || '';
                newState.meta_description = res.meta_description || '';
                newState.article = res.article || ''
                newState.title = res.title || ''

                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Meta Title"
                        name="meta_title"
                        value={inputState.meta_title}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Meta Description"
                        name="meta_description"
                        value={inputState.meta_description}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Title"
                        name="title"
                        value={inputState.title}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.highlightContainer}>
                        <h3>Article</h3>
                        <TextEditor
                            name="article"
                            value={inputState.article}
                            onChange={handleEditorChange}
                            imagesUpload
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={submit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Root>
    );
}

const useStyles = generateStyle(theme => ({
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > .label': {
            fontSize: 16,
            color: '#484848',
            marginBottom: 12,
        },
        '& > .error': {
            color: theme.palette.error.main,
            marginTop: 12,
        }
    },
    line: {
        display: 'block',
        width: '100%',
        height: 1,
        backgroundColor: '#cacaca',
        margin: '16px 0px 16px',
    },
    metaContainer: {
        '& .title': {
            fontSize: 16,
            fontWeight: '500',
        },
        '& .subtitle': {
            fontWeight: '500',
        }
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    submitContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    listContainer: {
        '& > *': {
            paddingBottom: 16,
            paddingTop: 16,
            borderBottom: '2px solid #cacaca',
        },
        '& > *:first-of-type': {
            paddingTop: 0,
        },
        '& > *:last-child': {
            borderBottom: 'none',
        }
    },
    highlightContainer: {
        marginBottom: 16,
        '& > h3': {
            marginTop: 0,
        },
        '& > .error': {
            marginTop: 8,
            color: 'red',
            fontSize: 14,
            display: 'block',
        },
    },
}), "Article_KPR"
)

export default ArticleKPR
