import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Typography,
    IconButton,
    Tooltip
} from '@mui/material';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import ImagePreview from '../../_components/ImagePreview';

/**
 * Utils
 */
import helpers from '../../_utils/Helpers';
import DefaultAxios from '../../_utils/DefaultAxios';
import { format } from 'date-fns'
import { activityTypes } from './ActivityList';

/**
 * Icons
 */
import { ChevronLeft } from '@mui/icons-material'
import PhotoIcon from '@mui/icons-material/Photo';
import MapIcon from '@mui/icons-material/Map';
import { generateStyle } from '../../_utils/DefaultStyles';

interface Props {
    open: boolean;
    onClose: () => void;
    activityId: string;
    isMobile: boolean;
}

interface IState {
    id: string
    is_done: number
    datetime: string
    type: string
    client_name: string
    client_phone: string
    latitude: number
    longitude: number
    created_at: string
    done_at: string
    is_inputted_by_other_agent: number
    agent_name: string
    created_by_name: string
    cluster_name: string
    code: string
    notes: string
    photo_url: string
}

const useStyles = generateStyle(theme => ({
    appBar: {
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingTop: 10,
        paddingBottom: 10,
        marginBottom: 16,
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
    },
    container: {
        paddingBottom: '50px'
    },
    rightSection: {
        borderLeft: '1px solid #484848'
    },
    buttonStatus: {
        marginTop: 5,
        minWidth: 100
    },
    verticleContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    verticleLine: {
        height: 40,
        borderLeft: '1px solid #484848',
    },
    timeline: {
        overflow: 'auto',
        borderTop: '1px solid lightgray',
        borderBottom: '1px solid lightgray',
        paddingTop: 12,
    }
}), "Activity_Detail"
);

const ActivityDetail = ({ open, onClose, activityId, isMobile }: Props) => {
    const { Root, classes } = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState<IState>(initialState)
    const [selectedImage, setSelectedImage] = useState('')

    useEffect(() => {
        if (activityId && open) {
            setIsLoading(true);
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/activity/${activityId}`)
                .then(res => {
                    setState(res.data)
                })
                .catch(err => {
                    helpers.generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }, [open, activityId]);

    return (
        <>
            <ImagePreview
                image={selectedImage}
                onClose={() => setSelectedImage('')}
            />
            <Dialog
                open={open}
                onClose={() => onClose()}
                fullWidth
                maxWidth="md"
                fullScreen={isMobile}
            >
                <Root>
                    <LoadingScreen open={isLoading} fullScreen />
                    <DialogTitle className={classes.appBar}>
                        <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid>
                                <IconButton aria-label="back" onClick={() => onClose()} style={{ marginLeft: -8, marginRight: 2, color: '#fff' }}>
                                    <ChevronLeft />
                                </IconButton>
                                {state.code || '-'} - {activityTypes.find(activityType => activityType.key === state.type)?.value || '-'} - {state.client_name || '-'}
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={6} md={4}>
                                <Typography variant="subtitle2" >
                                    Clusters
                                </Typography>
                                {state.cluster_name || '-'}
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="subtitle2" >
                                    Showing Schedule
                                </Typography>
                                <Typography variant="body2">
                                    {state.datetime ? format(new Date(state.datetime), 'dd MMMM yyyy HH:mm') : '-'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="subtitle2">
                                    Created At
                                </Typography>
                                <Typography variant="body2">
                                    {state.created_at ? format(new Date(state.created_at), 'dd MMMM yyyy HH:mm') : '-'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="subtitle2">
                                    Agent Name
                                </Typography>
                                <Typography variant="body2">
                                    {state.agent_name || '-'}
                                </Typography>
                            </Grid>
                            {
                                state.is_inputted_by_other_agent === 1 &&
                                <Grid item xs={6} md={4}>
                                    <Typography variant="subtitle2">
                                        Agent Assigner
                                    </Typography>
                                    <Typography variant="body2">
                                        {state.created_by_name || '-'}
                                    </Typography>
                                </Grid>
                            }
                            <Grid item xs={6} md={4}>
                                <div style={{ position: 'relative' }}>
                                    <Typography variant="subtitle2">
                                        Status
                                    </Typography>
                                    <div style={{ position: 'absolute', top: -8, display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="subtitle2" style={{ opacity: 0 }}>
                                            Status
                                        </Typography>
                                        {
                                            state.photo_url &&
                                            <Tooltip title='Photo'>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() => setSelectedImage(state.photo_url)}
                                                >
                                                    <PhotoIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {
                                            (state.latitude && state.longitude) &&
                                            <Tooltip title='Location'>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() => window.open(`https://www.google.com.sa/maps/search/${state.latitude}, ${state.longitude}`, '_blank')}
                                                >
                                                    <MapIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </div>
                                </div>
                                <Typography variant="body2">
                                    {state.is_done === 0 ? 'On Process' : `Done at ${state.done_at ? format(new Date(state.done_at), 'dd MMMM yyyy HH:mm') : '-'}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">
                                    Notes
                                </Typography>
                                <Typography variant="body2">
                                    {state.notes || '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Root>
            </Dialog>
        </>
    );
}

const initialState = {
    id: '',
    is_done: -1,
    datetime: '',
    type: '',
    client_name: '',
    client_phone: '',
    latitude: -1,
    longitude: -1,
    created_at: '',
    done_at: '',
    is_inputted_by_other_agent: -1,
    agent_name: '',
    created_by_name: '',
    cluster_name: '',
    code: '',
    notes: '',
    photo_url: '',
};

export default ActivityDetail;