import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

/**
 * Components
 */
import AsyncMultipleAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsycMultipleAutoComplete';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';

/**
 * Icons
 */
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const FeaturedClusters = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/featured-clusters';

    const [isLoading, setIsLoading] = useState(false);
    const [clusterIds, setClusterIds] = useState<IAutoCompleteOption[]>([]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(API_URL)
            .then(res => {
                setClusterIds(res.data);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleAutoCompleteMultipleChange = (name: string, value: IAutoCompleteOption[]) => {
        let newClusterIds = clusterIds.slice();

        setClusterIds(newClusterIds.concat(value));
    }

    const handleDelete = (id: number) => {
        let newClusterIds = clusterIds.slice();

        newClusterIds = newClusterIds.filter(item => item.id !== id);

        setClusterIds(newClusterIds);
    }

    const handleSort = (id: number, dir: 'up' | 'down') => {
        let newClusterIds = clusterIds.slice();
        let temp = null;

        const currentSort = newClusterIds.findIndex(item => item.id === id);

        if (currentSort > -1) {
            let newSort = dir === 'up' ? currentSort - 1 : currentSort + 1;

            temp = newClusterIds[currentSort];
            newClusterIds[currentSort] = newClusterIds[newSort];
            newClusterIds[newSort] = temp;

            setClusterIds(newClusterIds);
        }
    }

    const handleSubmit = () => {
        setIsLoading(true);
        DefaultAxios.post(API_URL, { cluster_ids: clusterIds.map(item => ({ id: item.id, label: item.label })) })
            .then(res => {

            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Grid container spacing={3}>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid item xs={12} md={6}>
                <AsyncMultipleAutoComplete 
                    label="Cluster"
                    url={process.env.REACT_APP_API_URL + '/autocomplete/cluster?except_ids=' + clusterIds.map(clusterId => clusterId.id).join(',') + '&featured_clusters=1'}
                    name="cluster_id"
                    value={[]}
                    onChange={handleAutoCompleteMultipleChange}
                />
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clusterIds.map((clusterId, key) => (
                                <TableRow key={clusterId.id}>
                                    <TableCell component="th" scope="row">
                                        {clusterId.label}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(clusterId.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleSort(clusterId.id, 'up')}
                                            disabled={key === 0}
                                        >
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleSort(clusterId.id, 'down')}
                                            disabled={key === clusterIds.length - 1}
                                        >
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </Grid>
        </Grid>
    );
}

export default FeaturedClusters;