import React, { useState, useEffect } from 'react';
import {
    Button,
    Typography,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Theme
} from '@mui/material';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';

/**
 * Transition
 */
import { TransitionProps } from '@mui/material/transitions';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import LoadingScreen from '../../_components/LoadingScreen';
import { generateStyle } from '../../_utils/DefaultStyles';

interface IProps {
    open: boolean;
    groupId: number;
    onClose: Function;
}

interface ICluster {
    id: number;
    name: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = generateStyle((theme: Theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    table: {
        minWidth: 650,
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}), "LeadGroup_Detail");

export default function LeadGroupDetail({ open, groupId, onClose }: IProps) {
    const { Root, classes } = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [clusters, setClusters] = useState<ICluster[]>([])
    const [selectedCluster, setSelectedCluster] = useState<IAutoCompleteOption>(initialOption);
    const [groupName, setGroupName] = useState('')

    const [isSomethingChanged, setIsSomethingChanged] = useState(false)

    const addCluster = () => {
        if (selectedCluster.id === -1) {
            alert('No Cluster Selected');
        } else {
            renderWarningButton("Apakah anda yakin ingin menambah cluster ini?")
                .then((result) => {
                    if (result.value) {
                        setIsLoading(true);
                        /**
                         * Add To List, send pic_id + groupId
                         */
                        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/lead-group/add-cluster`, {
                            id: groupId,
                            cluster_id: selectedCluster.id,
                        })
                            .then(res => {
                                loadData();
                                setSelectedCluster(initialOption)
                                setIsSomethingChanged(true)
                            })
                            .catch(err => {
                                generalErrorHandler(err);
                            })
                            .finally(() => {
                                setIsLoading(false);
                            })
                    }
                });
        }
    }

    const handleClose = () => {
        onClose(isSomethingChanged);
    }

    const deleteCluster = (cluster_id: number) => {
        renderWarningButton("Apakah anda yakin ingin menghapus cluster ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    let data = {
                        cluster_id,
                    }

                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/lead-group/delete-cluster`, data)
                        .then(res => {
                            loadData();
                            setIsSomethingChanged(true)
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                }
            });
    }

    useEffect(() => {
        if (groupId !== 0) {
            loadData();
        }
        // eslint-disable-next-line
    }, [groupId]);

    useEffect(() => {
        if (!open) {
            setClusters([])
            setSelectedCluster(initialOption)
            setGroupName('')
            setIsSomethingChanged(false)
        }
    }, [open])

    const loadData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/lead-group/${groupId}`)
            .then(res => {
                setClusters(res.data.cluster_array);
                setGroupName(res.data.name)
                // setSelectedCluster(0)
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setSelectedCluster(value);
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setSelectedCluster(initialOption);
    }

    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <Root>

                <LoadingScreen open={isLoading} fullScreen />
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Manage Lead Group - {groupName}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.content}>
                    <div style={{ display: "flex", marginTop: "20px", width: 500 }}>
                        <AsyncAutoComplete
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/cluster-unassigned`}
                            label="Tambah Cluster"
                            name="cluster_id"
                            initialQuery={selectedCluster.label}
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            placeholder="Masukan Nama Cluster"
                        />

                        <br></br>

                        <Button
                            color="primary"
                            variant="contained"
                            style={{ marginLeft: "5px" }}
                            disabled={selectedCluster.id === -1}
                            onClick={addCluster}
                        >
                            Submit
                        </Button>
                    </div>
                    {/* } */}

                    <br></br>
                    <br></br>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nama</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clusters.map((row, key) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                onClick={() => deleteCluster(row.id)}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Root>
        </Dialog>
    )
}

const initialOption = { id: -1, label: '' }