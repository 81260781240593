import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Button
} from '@mui/material';

interface Props {
    open: boolean;
    onClose: () => void;
    inputRef: any;
    onDeleteLead: () => void;
}

const DeleteLeadModal = (props: Props) => {
    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Delete Leads</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Harap masukkan alasan menghapus leads ini.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Isi alasan disini"
                    type="text"
                    fullWidth
                    inputRef={props.inputRef}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Batal
                </Button>
                <Button onClick={props.onDeleteLead} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteLeadModal;