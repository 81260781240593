import { Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Theme } from '@mui/material'
import React, { useReducer, useEffect, useState } from 'react'
import Swal from 'sweetalert2';

/**
 * Components
 */
import TextEditor from '../../../_components/_form/TextEditor'
import { generateStyle } from '../../../_utils/DefaultStyles';

const toastMixin = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true
});

interface IProps {
    open: boolean,
    id: number;
    onClose: () => void,
    onSubmit: (data: IState, edit: boolean) => void,
    defaultValue?: IState,
    edit: boolean
}

interface IState {
    type: string;
    content: string | IContent
}

type IContent = { interest: string; label: string }

interface IAction {
    name: string,
    value: any,
    type: string
}
const initialState = {
    type: "point",
    content: ""
}

const useStyles = generateStyle((theme: Theme) => ({
    buttonContainer: {
        marginTop: theme.spacing(2)
    }
}), "AddContentModal", {
    CustomParent: Dialog
});

const AddContentModal = (props: IProps) => {
    const { Root: SDialog, classes } = useStyles();

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };
    const [inputState, setInputState] = useReducer(inputReducer, initialState)
    const [isChange, setIsChange] = useState(false)

    useEffect(() => {
        if (props.edit) {
            setInputState({ name: "type", value: props.defaultValue?.type, type: "SET_ITEM" })
            setInputState({ name: "content", value: props.defaultValue?.content, type: 'SET_ITEM' })
        }

        return () => {
            setInputState({ name: "content", value: null, type: 'RESET_ITEM' })
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isChange) {
            if (inputState.type === 'point') {
                console.log('change to point')
                setInputState({ name: 'content', value: { label: '', interest: '' }, type: 'SET_ITEM' });
            } else if (inputState.type === 'freetext') {
                console.log('change to freetext')
                setInputState({ name: 'content', value: '', type: 'SET_ITEM' });
            }
        }
        // eslint-disable-next-line
    }, [isChange])

    const handleEditorChange = (name: string, value: string) => {
        setInputState({ name: 'content', value, type: 'SET_ITEM' });
    }

    const handleSubmit = () => {
        if ((typeof inputState.content === 'string' && inputState.content === '') ||
            (typeof inputState.content === 'object' && (
                inputState.content.interest === '' || inputState.content.label === ''
            ))) {
            toastMixin.fire({
                icon: 'error',
                title: 'Harap isi formnya'
            })

            return
        }

        if (props.edit) {
            props.onSubmit(inputState, true)
        } else {
            props.onSubmit(inputState, false)
        }
        props.onClose()
    }

    return (
        <SDialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                {props.edit ? "Edit Content" : "Add Content"}
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <InputLabel id="type">Type</InputLabel>
                            <Select
                                labelId='type'
                                id="type"
                                defaultValue={props.edit ? props.defaultValue?.type : "point"}
                                label="type"
                                onChange={
                                    (e) => {
                                        setInputState({ name: 'type', value: e.target.value, type: 'SET_ITEM' })
                                        setIsChange(!isChange)
                                    }}
                                fullWidth
                            >
                                <MenuItem value="point">Point</MenuItem>
                                <MenuItem value="freetext">Freetext</MenuItem>
                            </Select>
                        </Grid>
                        {inputState.type === 'point' ?
                            (
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Interest"
                                            defaultValue={typeof inputState.content === 'object' ? inputState.content.interest : ''}
                                            fullWidth
                                            onChange={(e) => {
                                                setInputState({ name: 'content', value: { ...(inputState.content as IContent), interest: e.target.value }, type: 'SET_ITEM' })
                                            }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Label"
                                            defaultValue={typeof inputState.content === 'object' ? inputState.content.label : ''}
                                            fullWidth
                                            onChange={(e) => setInputState({ name: `content`, value: { ...(inputState.content as IContent), label: e.target.value }, type: 'SET_ITEM' })} />
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={12}>
                                    <TextEditor
                                        name="freetext"
                                        value={typeof inputState.content === 'string' ? inputState.content : ''}
                                        onChange={handleEditorChange}
                                        imagesUpload
                                    />
                                </Grid>
                            )}
                        <Grid container className={classes.buttonContainer} xs={12} flexDirection="row-reverse">
                            <Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                >Submit</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormControl>
            </DialogContent>
        </SDialog>
    )
}

export default AddContentModal