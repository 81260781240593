import { CircularProgress, MenuItem, Switch, TextField, Theme } from '@mui/material'
import { Sort } from '@mui/icons-material'
import moment from 'moment'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import DataNA from '../../_components/DataNA'
import LoadingCard from '../../_components/_cards/LoadingCard'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../_utils/Helper'
import ShowingReportCard from './_components/ShowingReportCard'
import DateRangePicker, { DateRange } from '../../_components/_form/DateRangePicker'
import { PermissionContext } from "../../_contexts/PermissionContext";
import { useParams } from 'react-router-dom'
import { generateStyle } from '../../_utils/DefaultStyles'

interface IState {
    startDate: DateRange
    endDate: DateRange
}

export type IReportCluster = {
    label: string
    amount: number
}

export interface ShowingReportItem {
    id: string
    name: string
    clusters: string[]
    total_response_time?: number
    total_done: number
    avatar_url: string
}

const formatDate = (date: DateRange) => {
    if (!date) return ''

    return moment(date).format('DD/M/Y')
}

const ShowingReport = () => {
    const { Root, classes } = useStyles()

    const ListRef = useRef<HTMLDivElement>(null)

    const [state, setState] = useState<IState>({
        startDate: moment(),
        endDate: moment(),
    })

    const permissions = useContext(PermissionContext);

    const [isFirstInit, setIsFirstInit] = useState(true)
    const [list, setList] = useState<ShowingReportItem[]>([])
    const [groupId, setGroupId] = useState('default');
    const [groups, setGroups] = useState<{ id: number, name: string }[]>([]);
    const [tableSort, setTableSort] = useState<'' | 'asc' | 'desc'>('')
    const [tableSortBy, setTableSortBy] = useState<'' | 'showing' | 'done'>('')
    const params: any = useParams()

    const [isDetail, setIsDetail] = useState(false)

    const [flags, setFlags] = useState({
        isInit: true,
        hasMore: true,
        isLoading: true,
        isRefreshing: false,
        isChanged: false,
    })

    const handleDateChanged = (dates: { startDate: DateRange, endDate: DateRange }) => {
        setState(prev => ({
            ...prev,
            ...dates
        }));
    }

    useEffect(() => {
        if (params.group_id) {
            setGroupId(params.group_id)
        }
    }, [params.group_id])

    useEffect(() => {
        fetchData(true)
        fetchGroups()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (flags.isRefreshing) fetchData(true)
        // eslint-disable-next-line
    }, [flags.isRefreshing])

    useEffect(() => {
        if (!isFirstInit) {
            fetchData(true)
        }
        // eslint-disable-next-line
    }, [groupId]);

    const processClustersData = (data: any) => data[1].showing

    const fetchGroups = () => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/lead-group/all`)
            .then(res => res.data)
            .then(data => {
                setGroups(data)
            })
            .catch(generalErrorHandler)
    }

    const fetchData = (isFiltered?: boolean) => {
        let initialGroupId: null | string = null

        setFlags(prev => ({
            ...prev,
            isLoading: true,
        }))

        if (isFiltered) {
            ListRef.current?.scrollTo({ top: 0 })
            setList([])
        }

        if (isFirstInit && !params.group_id) {
            const fetchGroupId = localStorage.getItem(`selected_group_showing_report`)

            if (fetchGroupId && fetchGroupId !== 'default') {
                initialGroupId = fetchGroupId
                setGroupId(initialGroupId)
            }
        }

        const data = {
            created_at: (state.startDate || state.endDate) ? `${state.startDate ? formatDate(state.startDate) : ''}-${state.endDate ? formatDate(state.endDate) : ''}` : null,
            date: (state.startDate || state.endDate) ? `${state.startDate ? formatDate(state.startDate) : ''}-${state.endDate ? formatDate(state.endDate) : ''}` : null,
            group_id: initialGroupId || (groupId !== 'default' ? groupId : null),
        }

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/report-activity`, { params: data })
            .then(res => res.data)
            .then((res: any) => {
                let items: any = []
                items = Object.entries(res.data).map((value: any) => ({
                    id: value[0],
                    name: value[0],
                    total_response_time: value[1].total_response_time,
                    total_done: value[1].total_done,
                    clusters: processClustersData(value)
                }))

                const hasMore = false

                setList(isFiltered ? items : list.concat(items))

                setFlags(prev => ({
                    ...prev,
                    isLoading: false,
                    isRefreshing: false,
                    isInit: false,
                    isChanged: false,
                    hasMore,
                }))

                setIsFirstInit(false)
            })
            .catch((err) => {
                generalErrorHandler(err)
            })
    }

    const total = useMemo(() => list.length === 0 ? 0 : list.reduce((prev, current) => prev + current.clusters.length, 0), [list])
    const totalDone = useMemo(() => list.length === 0 ? 0 : list.reduce((prev, current) => prev + current.total_done, 0), [list])

    const ListHeaderComponent = (
        <>
            <div className={classes.selectsContainer}>
                {
                    permissions['report.filter-group'] &&
                    <div style={{ marginLeft: 'auto', minWidth: 100 }}>
                        <TextField
                            select
                            variant="outlined"
                            label="Group"
                            name="group_id"
                            value={groupId}
                            onChange={(e) => {
                                setGroupId(e.target.value)
                                localStorage.setItem(`selected_group_showing_report`, e.target.value)
                            }}
                            fullWidth
                        >
                            <MenuItem value='default'>All</MenuItem>
                            {groups.map(group => <MenuItem key={group.id.toString()} value={group.id.toString()}>{group.name}</MenuItem>)}
                        </TextField>
                    </div>
                }

            </div>
            <div className={classes.date}>
                <div className={classes.switchContainer}>
                    <span className={classes.switchText}>
                        Details
                    </span>
                    <Switch
                        value={isDetail}
                        onChange={e => setIsDetail(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color="primary"
                    />
                </div>
                <div style={{ marginLeft: 'auto' }} className={classes.datePickerContainer}>
                    <DateRangePicker
                        startDate={state.startDate}
                        endDate={state.endDate}
                        onDatesChange={handleDateChanged}
                        format="DD/M/Y"
                        onFinished={() => fetchData(true)}
                        fullWidth
                    />
                </div>
            </div>
        </>
    )

    const sortedList = useMemo(() => {
        switch (tableSort) {
            case 'asc':
                // Sort without mutating the original value
                return [...list].sort((a, b) => {
                    const aAmount = tableSortBy === 'showing' ? a.clusters.length : a.total_done
                    const bAmount = tableSortBy === 'showing' ? b.clusters.length : b.total_done

                    return aAmount - bAmount
                })
            case 'desc':
                // Sort without mutating the original value
                return [...list].sort((a, b) => {
                    const aAmount = tableSortBy === 'showing' ? a.clusters.length : a.total_done
                    const bAmount = tableSortBy === 'showing' ? b.clusters.length : b.total_done

                    return bAmount - aAmount
                })
            default:
                return list
        }
    }, [list, tableSort, tableSortBy])

    return (
        <Root>
            <div className={classes.root}>
                {ListHeaderComponent}
                {
                    isDetail ?
                        <div className={classes.detailRoot}>
                            {
                                flags.isLoading ?
                                    <>
                                        <LoadingCard />
                                        <LoadingCard />
                                        <LoadingCard />
                                    </>
                                    :
                                    list.length === 0 ?
                                        <>
                                            <div />
                                            <DataNA
                                                type="leads"
                                                init={false}
                                                navigate={() => { }}
                                                renderBody={(titleStyle, subtitleStyle) =>
                                                    <>
                                                        <h5 style={titleStyle}>Maaf, showing tidak ditemukan</h5>
                                                        <p style={subtitleStyle}>Silahkan atur rentang tanggal</p>
                                                    </>
                                                }
                                            />
                                        </>
                                        :
                                        list.map(item =>
                                            <ShowingReportCard key={item.id} {...item} />
                                        )
                            }
                        </div>
                        :
                        <div className={classes.table}>
                            <div className={`${classes.tableCell} ${classes.tableHeader} ${classes.cellWhite}`} style={{ padding: 0 }}>
                                <span className={`${classes.tableHeaderText}`} style={{ padding: '17px 16px', flex: 1 }}>
                                    Agent
                                </span>
                                <span
                                    className={`${classes.tableHeaderText} ${classes.center} ${classes.sortable}`}
                                    style={{ padding: '17px 16px', justifyContent: 'center', flex: 1 }}
                                    onClick={() => {
                                        setTableSortBy(prev => {
                                            if (prev === 'showing') {
                                                setTableSort(prev => prev === '' ? 'desc' : prev === 'desc' ? 'asc' : '')
                                            } else {
                                                setTableSort('desc')
                                            }
                                            return 'showing'
                                        })
                                    }}
                                >
                                    Showing
                                    <Sort fontSize='small' className={`sort-icon ${tableSortBy === 'showing' ? tableSort : ''}`} />
                                </span>
                                <span
                                    className={`${classes.tableHeaderText} ${classes.right} ${classes.sortable}`}
                                    style={{ padding: '17px 16px', justifyContent: 'flex-end', flex: 1 }}
                                    onClick={() => {
                                        setTableSortBy(prev => {
                                            if (prev === 'done') {
                                                setTableSort(prev => prev === '' ? 'desc' : prev === 'desc' ? 'asc' : '')
                                            } else {
                                                setTableSort('desc')
                                            }
                                            return 'done'
                                        })
                                    }}
                                >
                                    Done
                                    <Sort fontSize='small' className={`sort-icon ${tableSortBy === 'done' ? tableSort : ''}`} />
                                </span>
                            </div>
                            {
                                flags.isLoading ?
                                    <div className={`${classes.tableLoading} ${classes.cellLightBlue}`}>
                                        <CircularProgress size={32} />
                                    </div>
                                    :
                                    list.length === 0 ?
                                        <div className={`${classes.tableCell} ${classes.cellLightBlue}`}>
                                            <span className={`${classes.cellText}`}>
                                                Maaf, showing tidak ditemukan
                                            </span>
                                        </div>
                                        :
                                        sortedList.map((item, index) =>
                                            <div key={item.id} className={`${classes.tableCell} ${index % 2 === 0 ? classes.cellLightBlue : classes.cellWhite}`}>
                                                <div className={`${classes.cellText}`} style={{ flex: 1 }}>
                                                    {item.name}
                                                </div>
                                                <div className={`${classes.cellText} ${classes.center}`} style={{ flex: 1 }}>
                                                    {item.clusters.length}
                                                </div>
                                                <div className={`${classes.cellText} ${classes.right}`} style={{ flex: 1 }}>
                                                    {item.total_done || '-'}
                                                </div>
                                            </div>
                                        )
                            }
                            <div className={`${classes.tableCell} ${classes.cellDarkBlue} ${classes.cellText}`}>
                                <span className={`${classes.tableFooterText} ${classes.tableFooterTextLeft}`} style={{ flex: 1 }}>
                                    Total
                                </span>
                                {
                                    flags.isLoading ?
                                        <CircularProgress size={16} />
                                        :
                                        <>
                                            <span className={`${classes.tableFooterText} ${classes.center}`} style={{ flex: 1 }}>
                                                {total}
                                            </span>
                                            <span className={`${classes.tableFooterText} ${classes.right}`} style={{ flex: 1 }}>
                                                {totalDone || '-'}
                                            </span>
                                        </>
                                }
                            </div>
                        </div>
                }
            </div>
        </Root>
    )
}

const useStyles = generateStyle((theme: Theme) => ({
    root: {
        // backgroundColor: '#DBEAFE4D',
        padding: '0px 16px 32px',
    },
    detailRoot: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        gap: '20px',

        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        },
    },
    switchContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#E9F0F5',
        padding: '6px 20px',
        borderRadius: 10,

        [theme.breakpoints.down('md')]: {
            marginBottom: 16,
        },
    },
    switchText: {
        fontSize: 18,
        lineHeight: 1,
        marginRight: 4,
        fontWeight: 500,
    },
    date: {
        display: 'flex',
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        padding: '16px 8px 8px',
        marginBottom: 20,

        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    dateButton: {
        backgroundColor: "#EBF3F6",
    },
    dateText: {
        color: 'black',
        fontSize: 18,
    },
    footer_root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 16px',
        marginTop: 8,
    },
    footer_leads: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    footer_text: {
        fontSize: 26,
        color: 'black',
        fontWeight: 700,
    },
    footer_label: {
        fontSize: 18,
        color: 'black',
        marginLeft: 8,
    },
    table: {
        boxShadow: '0px 3px 8px #52575D30',
        backgroundColor: 'white',
    },
    tableCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tableLoading: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 130,
    },
    tableHeader: {
        borderBottomColor: theme.palette.primary.main,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
    },
    tableHeaderText: {
        fontWeight: 700,
        fontSize: 16,
        color: theme.palette.primary.main,
    },
    tableFooterText: {
        fontWeight: 700,
        fontSize: 16,
        color: '#363636',
    },
    tableFooterTextLeft: {
        marginRight: 'auto',
    },
    cellText: {
        fontSize: 16,
        color: '#3D3D3D',
        padding: '17px 16px',
    },
    center: {
        textAlign: 'center',
    },
    right: {
        textAlign: 'right',
    },
    cellWhite: {
        backgroundColor: 'white',
    },
    cellLightBlue: {
        backgroundColor: '#bcd6eb40',
    },
    cellDarkBlue: {
        backgroundColor: '#035aa633',
    },
    sortable: {
        transition: 'background-color 200ms linear',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',

        '& > .sort-icon': {
            marginLeft: 8,
            color: '#aaaaaa',

            '&.desc': {
                color: 'unset',
            },

            '&.asc': {
                color: 'unset',
                transform: 'rotate(180deg)',
            },
        },

        '&:hover': {
            backgroundColor: '#eeeeee',
        },
    },
    datePickerContainer: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            maxWidth: 220,
        }
    },
    selectsContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
}), "Showing_Report"
)

export default ShowingReport