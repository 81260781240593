import React, { useEffect, useState, useContext } from 'react'
import { Grid, Button, Typography, IconButton, capitalize, Chip } from '@mui/material'

/**
 * Componenst
 */
import ModalDetail from '../../_components/_modalDetail/ModalDetail'
import ModalSection from '../../_components/_modalDetail/ModalSection'
import LoadingScreen from '../../_components/LoadingScreen'
import ReimbursementItemModalFrom from './ReimbursementItemModalFrom'
import SimpleDataTable from '../../_components/_dataTable/SimpleDataTable'
// import ImagePreview from '../../_components/ImagePreview'
import RejectReasonModal from './_components/RejectReasonModal'
import EditDateModal from './_components/EditDateModal'
import MultipleImagePreview from '../../_components/MultipleImagePreview'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper'
import { numberToCurrency } from '../../_utils/Helpers'
import { format } from 'date-fns'
import Swal from 'sweetalert2'

/**
 * Icons
 */
import PhotoIcon from '@mui/icons-material/Photo';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * Contexts
 */
import { PermissionContext } from '../../_contexts/PermissionContext'



interface IProps {
    open: boolean
    onClose: (refresh: boolean) => void
    reimbursementCode: string
}
interface IProof {
    id: number
    item_id: number
    proof: File | null
    proof_url: string | null
}

export interface IItem {
    id: string
    reimburse_id: string
    type: string
    nominal: number
    participants: number
    distance: number
    vehicle_type: string
    vehicle_owner: string
    vehicle_owner_name: string
    reimburse_to: string
    reimburse_to_name: string
    notes: string
    proofs: IProof[]
}

interface IState {
    approved_by_name: string
    can_request: boolean
    code: string
    date: string
    deleted_at: string
    id: number
    items: IItem[]
    processed_by_name: string
    rejected_by: string
    requested_by_name: string
    rejected_reason: string
    status: string
}

const initialState = {
    approved_by_name: '',
    can_request: false,
    code: '',
    date: '',
    deleted_at: '',
    id: 0,
    items: [],
    processed_by_name: '',
    rejected_by: '',
    requested_by_name: '',
    rejected_reason: '',
    status: 'draft'
}

export const typeOption = [
    { value: 'consumption', label: 'Konsumsi' },
    { value: 'toll', label: 'Tol' },
    { value: 'parking', label: 'Parkir' },
    { value: 'fuel', label: 'Bensin' },
    { value: 'other', label: 'Lainnya' },
]

export const vehicleOption = [
    { value: 'bike', label: 'Motor' },
    { value: 'car', label: 'Mobil' },
]

const ReimbursementModalDetail = (props: IProps) => {

    const permissions = useContext(PermissionContext)

    const [state, setState] = useState<IState>(initialState)
    const [isLoading, setIsLoading] = useState(false)
    const [reDrawItemTable, setRedrawItemTable] = useState(new Date().getTime())

    const [typeModal, setTypeModal] = useState<string | null>(null)
    const [rejectModal, setRejectModal] = useState(false)
    const [dateModal, setDateModal] = useState(false)

    const [previewImage, setPreviewImage] = useState<string[]>([])
    const [seletedItem, setSeletedItem] = useState<IItem | null>(null)

    const [hasEdited, setHasEdited] = useState(false)

    const consumptionColumn = [
        {
            name: 'type',
            label: 'Item',
            style: {
                textAlign: 'center'
            },
            render: (data: any) => {
                const seletedOption = typeOption.find(option => option.value === data)
                return seletedOption ? seletedOption.label : '-'
            }
        },
        {
            name: 'reimburse_to_name',
            label: 'Reimburse to',
            style: {
                textAlign: 'center'
            },
        },
        {
            name: 'participants',
            label: 'Jumlah Partisipan',
            style: {
                textAlign: 'center'
            },
            render: (data: any, rows: IItem) => {
                if (rows.type !== 'consumption') {
                    return '-'
                }

                return `${data} Orang`
            }
        },
        {
            name: 'nominal',
            label: 'Value',
            style: {
                textAlign: 'center'
            },
            render: (data: any) => `Rp. ${numberToCurrency(data)}`
        },
        {
            name: 'notes',
            label: 'Notes',
            style: {
                textAlign: 'center',
            },
            columnStyle: {
                maxWidth: '200px'
            },
            render: (data: any) => {
                return <span style={{ wordBreak: 'break-word' }}>{data ? data : '-'}</span>

            }
        },
        {
            name: 'proofs',
            label: 'Bukti',
            style: {
                textAlign: 'center'
            },
            render: (data: any) => {
                if (data.length) {
                    return (
                        <>
                            <IconButton
                                onClick={() => setPreviewImage(data.map((item: IProof) => item.proof_url))}
                            >
                                <PhotoIcon color='primary' />
                            </IconButton>
                        </>

                    )
                } else {
                    return "-"
                }
 
            }
        },
        ...(state.status === 'draft' && state.can_request ?
            [
                {
                    name: 'EXTRA',
                    label: 'Action',
                    style: {
                        textAlign: 'center'
                    },
                    render: (data: any) => {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <IconButton
                                    onClick={() => handleEditData(data)}
                                >
                                    <EditIcon color='primary' />
                                </IconButton>
                                <IconButton
                                    onClick={() => handleDeleteItem(data.id)}
                                >
                                    <DeleteIcon color="secondary" />
                                </IconButton>
                            </div>
                        )
                    }
                }
            ]
            : []
        )
    ]

    const fuelColumn = [
        {
            name: 'vehicle_type',
            label: 'Kendaraan',
            style: {
                textAlign: 'center'
            },
            render: (data: any) => {
                const seletedOption = vehicleOption.find(option => option.value === data)
                return seletedOption ? seletedOption.label : '-'
            }
        },
        {
            name: 'vehicle_owner_name',
            label: 'Pemilik Kendaraan',
            style: {
                textAlign: 'center'
            },
        },
        {
            name: 'reimburse_to_name',
            label: 'Reimburse to',
            style: {
                textAlign: 'center'
            },
        },
        {
            name: 'distance',
            label: 'Jarak',
            style: {
                textAlign: 'center'
            },
            render: (data: any) => `${data} KM`
        },
        {
            name: 'nominal',
            label: 'Value',
            style: {
                textAlign: 'center'
            },
            render: (data: any) => `Rp. ${numberToCurrency(data)}`
        },
        {
            name: 'notes',
            label: 'Notes',
            style: {
                textAlign: 'center',
            },
            columnStyle: {
                maxWidth: '200px'
            },
            render: (data: any) => {
                return <span style={{ wordBreak: 'break-word' }}>{data ? data : '-'}</span>
            }
        },
        {
            name: 'proofs',
            label: 'Bukti',
            style: {
                textAlign: 'center'
            },
            render: (data: any) => {
                if (data.length) {
                    return (
                        <>
                            <IconButton
                                onClick={() => setPreviewImage(data.map((item: IProof) => item.proof_url))}
                            >
                                <PhotoIcon color='primary' />
                            </IconButton>
                        </>

                    )
                } else {
                    return "-"
                }
            }
        },
        ...(state.status === 'draft' && state.can_request ?
            [
                {
                    name: 'EXTRA',
                    label: 'Action',
                    style: {
                        textAlign: 'center'
                    },
                    render: (data: any) => {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <IconButton
                                    onClick={() => handleEditData(data)}
                                >
                                    <EditIcon color='primary' />
                                </IconButton>
                                <IconButton
                                    onClick={() => handleDeleteItem(data.id)}
                                >
                                    <DeleteIcon color="secondary" />
                                </IconButton>
                            </div>
                        )
                    }
                }
            ]
            : []
        )
    ]

    const handleEditData = (data: IItem) => {
        setTypeModal(data.type)
        setSeletedItem(data)
    }

    const handleDeleteItem = (id: number) => {
        renderWarningButton("Apakah anda yakin ingin menghapus item?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)
                    DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/reimbursement/${state.id}/item/${id}`)
                        .then(res => res.data)
                        .then((res) => {
                            renderToastSuccess('Item Deleted')
                            setIsLoading(false)
                            setHasEdited(true)
                        })
                        .catch(err => generalErrorHandler(err))
                        .finally(() => {
                            fetchData(props.reimbursementCode)
                            setIsLoading(false)
                        })
                }
            })
    }

    const fetchData = (code: string) => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/reimbursement/${code}`)
            .then(res => res.data)
            .then(res => res && setState(res as IState))
            .catch(err => generalErrorHandler(err))
            .finally(() => {
                setIsLoading(false)
                setRedrawItemTable(new Date().getTime())
            })
    }

    const handleReject = (reason: string) => {
        let url = `${process.env.REACT_APP_API_URL}/reimbursement/reject`
        setHasEdited(true)
        setIsLoading(true)
        DefaultAxios.post(url, {
            id: state.id,
            reason: reason
        })
            .then(res => res.data)
            .then(res => res && Swal.fire(`Success`, `Reject Success!`, 'success'))
            .catch(err => generalErrorHandler(err))
            .finally(() => {
                fetchData(props.reimbursementCode)
                setIsLoading(false)
            })
    }

    const handleChangeDate = (date: Date) => {
        DefaultAxios.patch(`${process.env.REACT_APP_API_URL}/reimbursement/${state.id}`, {
            date: format(new Date(date), 'yyyy-MM-dd')
        })
            .then(res => res.data)
            .then(res => {
                if (res) {
                    Swal.fire(`Success`, `Date changed success!`, 'success')
                }
                setDateModal(false)
            })
            .catch(err => generalErrorHandler(err))
            .finally(() => {
                setIsLoading(false)
                fetchData(props.reimbursementCode)
            })
    }

    const handleActionButton = (action: string) => {
        if (action === 'reject') {
            return setRejectModal(true)
        }

        let url = `${process.env.REACT_APP_API_URL}/reimbursement/${action}`
        renderWarningButton(`Are you sure want to ${capitalize(action)}?`)
            .then((res) => {
                if (res.value) {
                    setHasEdited(true)
                    setIsLoading(true)
                    DefaultAxios.post(url, {
                        id: state.id
                    })
                        .then(res => res.data)
                        .then(res => res && Swal.fire(`Success`, `${capitalize(action)} Success!`, 'success'))
                        .catch(err => generalErrorHandler(err))
                        .finally(() => {
                            if (action === 'cancel') {
                                props.onClose(true)
                            } else {
                                fetchData(props.reimbursementCode)
                            }
                            setIsLoading(false)
                        })
                }
            })
    }

    const getTotalReimbursement = (item: IItem[]) => {
        let total = 0
        if (item.length) {
            total = item.reduce((prev: number, curr: IItem) => prev + +curr.nominal, 0)
        }
        return `Rp. ${numberToCurrency(total)}`
    }

    const renderButtonActionStatus = (status: string) => {
        const renderChip = () => {
            let colorChip: 'default' | 'primary' | 'success' | 'error' = 'default'
            switch (status) {
                case 'draft':
                    colorChip = 'default'
                    break;
                case 'requested':
                    colorChip = 'default'
                    break;
                case 'approved':
                    colorChip = 'primary'
                    break;
                case 'processed':
                    colorChip = 'primary'
                    break;
                case 'released':
                    colorChip = 'success'
                    break;
                case 'rejected':
                    colorChip = 'error'
                    break;
            }
            return (
                <Chip
                    variant="filled"
                    color={status !== 'requested' ? colorChip : "default"}
                    label={capitalize(status)}
                    style={status === 'requested' ? { backgroundColor: '#F5E8C4' } : {}}
                />
            )
        }
        switch (status) {
            case 'draft':
                return (
                    <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                        {renderChip()}
                        {
                            state.can_request ?
                                <>
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        onClick={() => handleActionButton('request')}
                                    >
                                        Request
                                    </Button>
                                    <Button
                                        color='secondary'
                                        variant='contained'
                                        onClick={() => handleActionButton('cancel')}
                                    >
                                        Cancel
                                    </Button>
                                </>
                                : null
                        }
                    </div>
                )
            case 'requested':
                return (
                    <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                        {renderChip()}
                        {
                            permissions['reimbursement.approve'] ?
                                <>
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        onClick={() => handleActionButton('approve')}
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        color='secondary'
                                        variant='contained'
                                        onClick={() => handleActionButton('reject')}
                                    >
                                        Reject
                                    </Button>
                                </>
                                : null

                        }
                    </div>
                )
            case 'approved':
                return (
                    <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                        {renderChip()}
                        {
                            permissions['reimbursement.process'] ?
                                <>
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        onClick={() => handleActionButton('process')}
                                    >
                                        Process
                                    </Button>
                                    <Button
                                        color='secondary'
                                        variant='contained'
                                        onClick={() => handleActionButton('reject')}
                                    >
                                        Reject
                                    </Button>
                                </>
                                : null
                        }
                    </div>
                )
            default:
                return (
                    <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                        {renderChip()}
                    </div>
                )
        }
    }

    useEffect(() => {
        if (typeof props.reimbursementCode !== 'undefined') {
            fetchData(props.reimbursementCode)
            setHasEdited(false)
        }
    }, [props.reimbursementCode])

    useEffect(() => {
        setRedrawItemTable(new Date().getTime())
    }, [state.items])

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />

            <MultipleImagePreview
                images={previewImage}
                onClose={() => setPreviewImage([])}
            />

            <ReimbursementItemModalFrom
                open={typeModal !== null}
                type={typeModal}
                defaultState={seletedItem}
                reimburse_id={state.id}
                onClose={(refresh) => {
                    if (refresh) {
                        fetchData(props.reimbursementCode)
                        setHasEdited(true)
                    }

                    setTypeModal(null)
                    setSeletedItem(null)
                }}
            />

            <RejectReasonModal
                open={rejectModal}
                onClose={() => setRejectModal(false)}
                onSubmit={handleReject}
            />

            <EditDateModal
                open={dateModal}
                onClose={() => setDateModal(false)}
                onSubmit={handleChangeDate}
                initialDate={state.date}
            />

            <ModalDetail
                title={`${props.reimbursementCode || ''}`}
                open={props.open}
                onCloseModal={() => props.onClose(hasEdited)}
                isLoading={false}
            >
                <Grid container spacing={1} sx={{ pl: 2 }}>
                    <Grid item xs={12}>
                        <ModalSection
                            row={true}
                            content={[
                                [["Tanggal",
                                    <>
                                        <span>{state.date}</span>
                                        {
                                            state.status === 'draft' && state.can_request ?
                                                <IconButton onClick={() => setDateModal(true)}>
                                                    <EditIcon color='primary' />
                                                </IconButton>
                                                : null
                                        }
                                    </>
                                ], []],
                                [["Status", renderButtonActionStatus(state.status)], []],
                                ...(state.status === 'rejected' ? [[["Reject Reason", state.rejected_reason || '-'], []]] : [])
                            ]}
                            additionalContent={
                                <>
                                    <Grid container>
                                        <Grid item xs={12} lg={3}>
                                            <Typography variant='body1'><strong>Requested By</strong></Typography>
                                            <Typography variant='body2'>{state.requested_by_name || '-'}</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={3}>
                                            <Typography variant='body1'><strong>Approved By</strong></Typography>
                                            <Typography variant='body2'>{state.approved_by_name || '-'}</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={3}>
                                            <Typography variant='body1'><strong>Processed By</strong></Typography>
                                            <Typography variant='body2'>{state.processed_by_name || '-'}</Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                            title='Basic Information'
                        />
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Typography variant='h6' sx={{ mb: 1 }}>Konsumsi dan Lainnya</Typography>
                        {
                            state.status === 'draft' && state.can_request ?
                                <Button
                                    variant='contained'
                                    sx={{ mb: 2 }}
                                    onClick={() => setTypeModal('consumption')}
                                >
                                    Add Item
                                </Button>
                                : null
                        }
                        <SimpleDataTable
                            columns={consumptionColumn}
                            customData={state.items.filter((item) => item.type !== 'fuel')}
                            reDraw={reDrawItemTable}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Typography variant='h6' sx={{ mb: 1 }}>Bensin</Typography>
                        {
                            state.status === 'draft' && state.can_request ?
                                <Button
                                    variant='contained'
                                    sx={{ mb: 2 }}
                                    onClick={() => setTypeModal('fuel')}
                                >
                                    Add Item
                                </Button>
                                : null
                        }
                        {
                            <SimpleDataTable
                                columns={fuelColumn}
                                customData={state.items.filter(item => item.type === 'fuel')}
                                reDraw={reDrawItemTable}
                            />
                        }
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Typography variant='h6' textAlign='right'>
                            <strong>
                                Total : Rp. {getTotalReimbursement(state.items)}
                            </strong>
                        </Typography>
                    </Grid>

                </Grid>

            </ModalDetail>
        </>
    )
}

export default ReimbursementModalDetail