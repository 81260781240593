import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField } from '@mui/material'
import LoadingScreen from '../../_components/LoadingScreen'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete'

/**
 * Utils
 */
import { z } from 'zod'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, renderToastSuccess } from '../../_utils/Helper'
import { ErrorType, getFormError, zodValidate } from '../../_utils/ZodHelper'

interface AgentGroupAddModalProps {
    open: boolean
    onClose: () => void
    onRefresh: () => void
}

const stateSchema = z.object({
    name: z
        .string()
        .min(1, { message: "Name is required" }),
    leader_id: z
        .string()
        .min(1, { message: "Leader is required" }),
    leader_name: z.string(),
    // leader: z
    //     .object({
    //         id: z.number(),
    //         label: z.string(),
    //     })
    //     .nullable()
    // .transform((value, ctx) => {
    //     if (value == null)
    //       ctx.addIssue({
    //         code: 'custom',
    //         message: 'Leader is required',
    //       });
    //     return value;
    //   }),
})

type State = z.infer<typeof stateSchema>

const AgentGroupAddModal = (props: AgentGroupAddModalProps) => {
    const [state, setState] = useState<State>(initialState)
    const [error, setError] = useState<ErrorType<State>>({})

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!props.open) {
            setState(initialState)
            setError({})
            props.onRefresh()
        }
        // eslint-disable-next-line
    }, [props.open])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value,
        }))

        setError(prev => ({
            ...prev,
            [name]: undefined,
        }))
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            leader_id: value.id as string,
            leader_name: value.label,
        }))

        setError(prev => ({
            ...prev,
            leader_id: undefined,
        }))
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setState(prev => ({
            ...prev,
            leader_id: '',
            leader_name: '',
        }))

        setError(prev => ({
            ...prev,
            leader_id: undefined,
        }))
    }

    const submit = () => {
        zodValidate(stateSchema, state,
            () => {
                setError({})
                setIsLoading(true)

                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/agent-group`, {
                    name: state.name,
                    leader_id: state.leader_id,
                })
                    .then(res => res.data)
                    .then(data => {
                        renderToastSuccess('Berhasil menambahkan group agent')
                        props.onClose()
                    })
                    .catch(generalErrorHandler)
                    .finally(() => {
                        setIsLoading(false)
                    })
            }, setError
        )
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth
            maxWidth="xs"
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>Add Group</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ marginTop: 0 }}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Name"
                            name="name"
                            value={state.name}
                            onChange={handleChange}
                            error={getFormError(error.name) !== ''}
                            helperText={getFormError(error.name)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/user/vice-sales-manager`}
                            label="Leader"
                            name="leader_id"
                            initialQuery={state.leader_name}
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            placeholder="Masukkan nama VSM"
                            errorText={getFormError(error.leader_id)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={submit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const initialState = {
    name: '',
    leader_id: '',
    leader_name: '',
}

export default AgentGroupAddModal
