import React, { useEffect, useState } from 'react'
import { Button, Dialog, Paper, DialogContent, DialogTitle, FormControlLabel, Grid, MenuItem, Switch, TextField, Typography } from '@mui/material'

/**
 * Components
 */
import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete'
import { API_URL, IBankAccountStatement, escrowTransactionType } from '../StatementList'

/**
 * Utils
 */
import { format } from 'date-fns'
import { numberToCurrency } from '../../../_utils/Helpers'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../../_utils/Helper'

interface StatementAssignFormProps {
    isOpen: boolean
    id: number
    onClose: () => void
    handleSubmit: (state: IState) => void
}

interface IState extends IBankAccountStatement {
    is_assigned: boolean | number
    closing_transaction_id: string
    description: string
    transaction_type: string
    other_transaction_type_label: string
    notes: string
}

const initialState = {
    id: 0,
    is_assigned_jendela: 0,
    bank_source_number: "",
    duplicate_of: "",
    type: '',
    is_other: 0,
    unique_code: "",
    date: "",
    amount: "",
    trailer: "",
    description: "",
    mapped_by: "",
    mapped_at: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    deleted_reason: "",
    import_id: "",
    mapping_type: "",
    code: '',
    is_assigned: 0,
    transaction_type: '',
    closing_transaction_id: '',
    other_transaction_type_label: '',
    notes: '',
}

const StatementAssignForm = (props: StatementAssignFormProps) => {
    const [state, setState] = useState<IState>(initialState)

    const fetchData = (id: number) => {
        DefaultAxios.get(`${API_URL}/${id}`)
            .then(res => res.data)
            .then(res => setState(prev => ({
                ...prev,
                ...res
            })))
            .catch(err => generalErrorHandler(err))
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleAutocompleteChange = (name: string, value: any) => {
        setState(prev => ({
            ...prev,
            [name]: value.id,
            [name.concat('_name')]: value.label
        }))
    }

    const handleAutocompleteInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name
        setState(prev => ({
            ...prev,
            [name]: null,
            [name.concat('_name')]: null
        }))
    }

    useEffect(() => {
        if (props.isOpen && props.id) {
            fetchData(props.id)
        }

        return () => setState(initialState)
        // eslint-disable-next-line
    }, [props.isOpen])

    const renderItem = (title: string, value: any) => {
        return (
            <>
                <Grid item xs={4}>
                    <Typography sx={{ fontWeight: '600' }}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography>
                        {value}
                    </Typography>
                </Grid>
            </>
        )
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>ASSIGN TRANSAKSI {state.type} - {state.is_other ? 'OTHER' : 'NON OTHER'}</DialogTitle>
            <DialogContent>
                <Paper sx={{ p: 2, mb: 2 }}>
                    <Grid container rowSpacing={1}>
                        {renderItem('Tipe', state.type === 'DB' ? 'Debit' : 'Kredit')}
                        {renderItem('Tanggal', state.date ? format(new Date(state.date), 'dd MMMM yyyy') : '-')}
                        {renderItem('Keterangan', state.trailer || "-")}
                        {renderItem('Nominal', `Rp. ${numberToCurrency(Number(state.amount)) || '-'}`)}
                    </Grid>
                </Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            checked={!!state.is_other}
                            name='is_other'
                            control={<Switch />}
                            label="Transaksi Lainnya"
                            labelPlacement='start'
                            sx={{ ml: 0, display: 'flex', justifyContent: 'space-between' }}
                            onChange={(e, checked) => setState((prev) => ({
                                ...prev,
                                is_other: checked ? 1 : 0
                            }))
                            }
                        />
                    </Grid>
                    {
                        state.is_other ?
                            <Grid item xs={12}>
                                <TextField
                                    onChange={handleChange}
                                    name='description'
                                    label="Deskripsi"
                                    value={state.description}
                                    fullWidth
                                />
                            </Grid>
                            :
                            <>
                                {
                                    state.type === 'DB' ?
                                        // true ?
                                        <Grid item xs={12}>
                                            <TextField
                                                name='transaction_type'
                                                label="Jenis Transaksi"
                                                fullWidth
                                                onChange={handleChange}
                                                value={state.transaction_type}
                                                select
                                            >
                                                {
                                                    escrowTransactionType.map((type) => {
                                                        return <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                                                    })
                                                }
                                            </TextField>
                                        </Grid>
                                        : null
                                }
                                <Grid item xs={12}>
                                    <AsyncAutoComplete
                                        url={`${process.env.REACT_APP_API_URL}/autocomplete/closing-transaction`}
                                        label='Pilih Transaksi'
                                        name='closing_transaction_id'
                                        placeholder='Silahkan pilih transaksi'
                                        onChange={handleAutocompleteChange}
                                        onInputChange={handleAutocompleteInputChange}
                                        clearable
                                        onClear={() => setState(prev => ({ ...prev, closing_transaction_id: '' }))}
                                    />
                                </Grid>
                            </>
                    }

                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <Button
                            color="primary"
                            variant='contained'
                            sx={{ float: 'right' }}
                            onClick={() => props.handleSubmit(state)}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default StatementAssignForm
