import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Switch, TextField } from '@mui/material'
import TextArea from '../../_components/_form/TextArea'
import ButtonWithLoading from '../../_components/_form/ButtonWithLoading'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete'
import LoadingScreen from '../../_components/LoadingScreen'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../_utils/Validation'
import { converNumberFormat, generalErrorHandler, renderToastSuccess } from '../../_utils/Helper'
import { currencyToNumber } from '../../_utils/Helpers'

type Props = {
    open: boolean
    onClose: () => void
    onSuccess: () => void
}

interface State {
    id: string
    label: string
    is_cobroke: boolean
    nominal: string
    notes: string
}

const initialState = {
    id: '',
    label: '',
    is_cobroke: false,
    nominal: '',
    notes: '',
}

const AddPinjamanModal = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState<State>(initialState)
    const [error, setError] = useState<IValidationErrors<State>>({})

    useEffect(() => {
        if (!props.open) {
            setState(initialState)
            setError({})
        }
    }, [props.open])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: name === 'nominal' ? converNumberFormat(value) : value
        }))

        setError(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setState(prev => ({
            ...prev,
            id: String(value.id),
            label: value.label
        }))

        setError(prev => ({
            ...prev,
            id: '',
        }))
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setState(prev => ({
            ...prev,
            label: ''
        }))

        setError(prev => ({
            ...prev,
            id: '',
        }))
    }

    const handleSubmit = () => {
        const { errors, isValid } = validateData(state, validationRules, validationAlias)
        setError(errors)

        if (isValid) {
            setIsLoading(true)
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-loan/store`, {
                ...state,
                is_cobroke: state.is_cobroke ? 1 : 0,
                nominal: currencyToNumber(state.nominal),
            })
                .then(res => {
                    renderToastSuccess('Berhasil menambahkan data')
                    props.onSuccess()
                })
                .catch(err => {
                    generalErrorHandler(err)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            maxWidth="sm"
            fullWidth
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>Add Data Pinjaman</DialogTitle>
            <DialogContent>
                <Grid container sx={{ mt: 0 }} spacing={2}>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/closing-code`}
                            initialQuery={state.label}
                            label='Closing'
                            name="id"
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            errorText={error.id}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={state.is_cobroke}
                                    onChange={() => {
                                        setState(prev => ({
                                            ...prev,
                                            is_cobroke: !prev.is_cobroke
                                        }))
                                    }}
                                    color="primary"
                                />
                            }
                            label="Is Cobroke"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label='Nominal'
                            name='nominal'
                            onChange={handleChange}
                            value={converNumberFormat(state.nominal)}
                            fullWidth
                            error={!!error.nominal}
                            helperText={error.nominal}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextArea
                            label='Notes'
                            name='notes'
                            onChange={handleChange}
                            value={state.notes}
                            rows={3}
                            error={!!error.notes}
                            helperText={error.notes}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonWithLoading
                            isLoading={isLoading}
                            variant='contained'
                            onClick={handleSubmit}
                            sx={{ float: 'right' }}
                        >
                            Submit
                        </ButtonWithLoading>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

const validationRules: IValidationRules = {
    id: 'required',
    nominal: 'required',
}

const validationAlias: IValidationAlias = {
    id: 'closing',
    nominal: 'nominal',
}

export default AddPinjamanModal