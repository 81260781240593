import React from 'react';
import { TextField } from '@mui/material';

interface Props {
    label?: string;
    name: string;
    value: any;
    onChange: (event: any) => void;
    rowsMax?: number;
    placeholder?: string;
    disabled?: boolean;
    multiline?: boolean;
    rows?: number;
    error?: boolean;
    helperText?: string;
    handleBlur?: Function
}

const TextArea = (props: Props) => {
    return (
        <TextField
            fullWidth
            multiline
            variant="outlined"
            error={props.error}
            helperText={props.helperText}
            onBlur={() => props.handleBlur}
            { ...props }
        />
    );
}

TextArea.defaultProps = {
    rowmax: 3
}

export default TextArea;