import React from 'react'
import { Button } from '@mui/material'

interface IProps {
  type: string
  navigate: Function
  init?: boolean
  renderBody?: (titleStyle: React.CSSProperties, subtitleStyle: React.CSSProperties) => React.ReactNode
}

const DataNA = (props: IProps) => {
  const init = props.init !== undefined ? props.init : true

  return (
    <div style={styles.root}>
      <img src={`https://pintu-cdn.sgp1.cdn.digitaloceanspaces.com/assets/img/dataNA.png`} alt="na" style={styles.image} />
      {
        init ?
          <>
            <h5 style={styles.h5}>Anda Belum Mempunyai {props.type}</h5>
            <p style={styles.p}>Silahkan Tambah {props.type}</p>
            <Button style={styles.button} variant="contained" color="primary" onClick={() => props.navigate()}>
              Add {props.type}
            </Button>
          </>
          :
          <>
            {
              props.renderBody ?
                props.renderBody(styles.h5, styles.p)
                :
                <>
                  <h5 style={styles.h5}>Maaf, properti tidak ditemukan</h5>
                  <p style={styles.p}>Silahkan kurangi filternya</p>
                </>
            }
          </>
      }
    </div>
  )
}

const styles: {
  [key: string]: React.CSSProperties
} = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '3rem',
  },
  image: {

  },
  h5: {
    fontSize: 16,
    color: '#666666',
    fontWeight: 500,
    marginBottom: 0
  },
  p: {
    fontSize: 14,
    color: '#666666',
    fontWeight: 300,
    marginTop: '0.4rem'
  },
  button: {
    fontWeight: 400,
    textTransform: 'capitalize',
    fontSize: 16,
    padding: '0.5rem 3rem',
    marginTop: '1rem',
    borderRadius: '5rem'
  }
}

export default DataNA