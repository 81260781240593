import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Button, TextField, MenuItem, Typography, InputAdornment } from '@mui/material'

/**
 * Components
 */
import AsyncAutoComplete from '../../_components/_form/AsyncAutoComplete'
import LoadingScreen from '../../_components/LoadingScreen'
import TextArea from '../../_components/_form/TextArea'

/**
 * Utils
 */
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper'
import { currencyToNumber, numberToCurrency } from '../../_utils/Helpers'
import DefaultAxios from '../../_utils/DefaultAxios'
import Swal from 'sweetalert2'

/**
 * Interfaces
 */
import { IItem } from './ReimbursementModalDetail'
import DropZone from '../../_components/DropZone'
import PhotoPreview from '../../_components/PhotoPreview'

interface IState {
    reimburse_id: string
    type: string
    nominal: number
    participants: number
    distance: number
    vehicle_type: string
    vehicle_owner: string
    vehicle_owner_name: string
    reimburse_to: string
    reimburse_to_name: string
    notes: string
    proofs: IProof[]
}

interface IProof {
    id: number | null
    item_id: number | null
    proof: File | null
    proof_url: string | null
}

const initialState = {
    reimburse_id: '',
    type: '',
    nominal: 0,
    participants: 0,
    distance: 0,
    vehicle_type: '',
    vehicle_owner: '',
    vehicle_owner_name: '',
    reimburse_to: '',
    reimburse_to_name: '',
    notes: '',
    proofs: []
}

interface IProps {
    reimburse_id: number
    type: string | null
    doneSubmit: (refresh: boolean) => void
    defaultState?: IItem | null
}

const ReimbursementItemForm = (props: IProps) => {
    const [state, setState] = useState<IState>(initialState)
    const [isEdit, setIsEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target

        if (name === 'distance') {
            value = value.replace(/[^\d.]/g, '')

            setState(prev => ({
                ...prev,
                nominal: state.vehicle_type === 'car' ? +value * 1300 : +value * 350
            }))
        }

        if (name === 'type' || name === 'vehicle_type') {
            setState(prev => ({
                ...prev,
                nominal: 0,
                participants: 0,
                distance: 0,
            }))
        }

        if (name === 'participants') {
            let maxNominal = Number(value) * 35_000
            if (Number(state.nominal) > maxNominal) {
                setState(prev => ({
                    ...prev,
                    nominal: maxNominal
                }))
            }
        }

        if (name === 'nominal') {
            if (state.type === 'consumption') {
                let maxNominal = state.participants * 35_000
                if (Number(currencyToNumber(value)) > maxNominal) {
                    value = String(maxNominal)
                }
            }

            value = String(currencyToNumber(value))
        }

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleChangeAutoComplete = (name: string, value: any) => {
        setState(prev => ({
            ...prev,
            [name]: value.id,
            [name.concat('_name')]: value.label
        }))
    }

    const handleAutoCompleteInput = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        const { value } = e.target

        if (!value) {
            setState(prev => ({
                ...prev,
                [name]: '',
                [name.concat('_name')]: ''
            }))
        }
    }

    const handleChangeFile = (data: IState) => {
        return (files: File[], name: string) => {
            let newFiles: IProof[]

            newFiles = files.map((file) => ({
                id: null,
                item_id: null,
                proof: file,
                proof_url: URL.createObjectURL(file),
            }))

            setState(prev => ({
                ...prev,
                [name]: prev.proofs.length ? prev.proofs.concat(newFiles) : newFiles
            }))
        }
    }

    const shiftProof = (id: number) => {
        setState(prev => {
            const oldProof = prev.proofs
            const newProof = oldProof.filter((_, idx) => idx !== id)

            return {
                ...prev,
                proofs: newProof
            }
        })
    }

    const handleRemoveProof = (id: number, idArr: number) => {
        if (id) {
            renderWarningButton('This Image from database, will you delete it?')
                .then((res) => {
                    if (res.value) {
                        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/reimbursement/delete-proof`, {
                            id: id
                        })
                            .then((res) => res.data)
                            .then(res => {
                                if (res) {
                                    renderToastSuccess('Proof Deleted')
                                    shiftProof(idArr)
                                }
                            })
                            .catch(generalErrorHandler)
                    }
                })
        } else {
            shiftProof(idArr)
        }
    }

    const generatePreviewImage = useMemo(() => {
        return (state: IState) => {
            if (state.proofs && state.proofs.length) {
                const imageFromAPI = state.proofs.filter(proof => !!proof.id)
                return (
                    <Grid item xs={12}>
                        {
                            state.proofs.map((proof, idArr) => {
                                let url = ''

                                if (proof.proof instanceof File) {
                                    url = URL.createObjectURL(proof.proof)
                                } else if (proof.proof_url) {
                                    url = proof.proof_url
                                } else {
                                    url = ''
                                }

                                return (
                                    <PhotoPreview
                                        key={url}
                                        onRemoveFile={(id) => handleRemoveProof(id, idArr)}
                                        src={url}
                                        hideDelete={(idArr === 0 && imageFromAPI.length === 1)}
                                        index={proof.id ? proof.id : 0}
                                        style={{ width: '40%', height: '200px', margin: 0, marginRight: 10 }}
                                    />
                                )
                            })
                        }
                    </Grid>
                )
            }
            return null
        }
        // eslint-disable-next-line
    }, [state.proofs])

    const handleSubmit = () => {

        let fd = new FormData()
        Object.entries(state).forEach(([key, value]) => {
            if (key === 'proofs') {
                if (value.length) {
                    value.forEach((proof: IProof, idx: number) => {
                        if (proof.proof instanceof File) {
                            fd.append(`proofs[${idx}]`, proof.proof)
                        }
                    })
                }
            } else {
                if (value) {
                    fd.append(key, value)
                }
            }
        })

        if (props.type === 'fuel') fd.append('type', 'fuel')
        if (isEdit) {
            fd.append('id', props.defaultState?.id as string)
            fd.append('_method', 'patch');
        }

        setIsLoading(true)
        DefaultAxios({
            method: 'POST',
            url: !isEdit ?
                `${process.env.REACT_APP_API_URL}/reimbursement/${props.reimburse_id}/item` :
                `${process.env.REACT_APP_API_URL}/reimbursement/${props.reimburse_id}/item/${props.defaultState?.id as string}`,
            data: fd
        })
            .then(res => res.data)
            .then(res => {
                if (res) {
                    Swal.fire('Success', !isEdit ? 'Item Added!' : 'Item Updated!', 'success')
                    props.doneSubmit(true)
                }
            })
            .catch(err => generalErrorHandler(err))
            .finally(() => setIsLoading(false))
    }

    const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
        const items = event.clipboardData?.items;
        if (items?.length) {

            const files = Array.from(items).map((item) => {
                const file = item.getAsFile()
                if (file && file.type.includes('image')) {
                    return file
                }

                return null
            })

            const newFiles = files.map((file) => ({
                id: null,
                item_id: null,
                proof_url: URL.createObjectURL(file as File),
                proof: file as File
            }))

            setState(prev => ({
                ...prev,
                proofs: prev.proofs.concat(newFiles)
            }))
            // const file = items[0].getAsFile()
            // if (file && file.type.includes('image')) {
            // setState(prev => ({
            //     ...prev,
            //     proofs: prev.proofs.concat({id: null, item_id: null, proof_url: URL.createObjectURL(file), proof: file})
            // }))
            // }
        }
    };

    useEffect(() => {
        if (props.defaultState) {
            setState(props.defaultState)
            setIsEdit(true)
        }
    }, [props.defaultState])

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />

            <Grid container spacing={1} rowSpacing={2} onPaste={handlePaste}>
                {
                    props.type === 'fuel' ?
                        <>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <TextField
                                    name='vehicle_type'
                                    label="Tipe Kendaraan"
                                    value={state.vehicle_type}
                                    onChange={handleChange}
                                    fullWidth
                                    select
                                >
                                    <MenuItem value="" disabled>--Select Vehicle Type--</MenuItem>
                                    <MenuItem value="car">Car</MenuItem>
                                    <MenuItem value="bike">Bike</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    name='distance'
                                    label='Jarak'
                                    fullWidth
                                    value={state.distance ? state.distance : ''}
                                    onChange={handleChange}
                                    disabled={!state.vehicle_type}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">KM</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name='nominal'
                                    label="Nominal"
                                    fullWidth
                                    value={state.nominal ? numberToCurrency(Math.ceil(state.nominal)) : ''}
                                    onChange={handleChange}
                                    disabled
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Rp. </InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AsyncAutoComplete
                                    label='Pemilik Kendaraan'
                                    name='vehicle_owner'
                                    initialQuery={state.vehicle_owner_name ? state.vehicle_owner_name : ''}
                                    onChange={handleChangeAutoComplete}
                                    onInputChange={handleAutoCompleteInput}
                                    url={`${process.env.REACT_APP_API_URL}/autocomplete/user`}
                                />
                            </Grid>
                        </>
                        :
                        <>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <TextField
                                    name='type'
                                    label="Type"
                                    value={state.type}
                                    onChange={handleChange}
                                    fullWidth
                                    select
                                >
                                    <MenuItem value="" disabled>--Select Type--</MenuItem>
                                    <MenuItem value="consumption">Konsumsi</MenuItem>
                                    <MenuItem value="toll">Toll</MenuItem>
                                    <MenuItem value="parking">Parkir</MenuItem>
                                    <MenuItem value="other">Lainnya</MenuItem>
                                </TextField>
                            </Grid>
                            {
                                state.type === 'consumption' ?
                                    <Grid item xs={12}>
                                        <TextField
                                            name='participants'
                                            label="Partisipan"
                                            fullWidth
                                            value={state.participants}
                                            onChange={handleChange}
                                            select
                                        >
                                            <MenuItem value={0} disabled>Number of Participant</MenuItem>
                                            {
                                                Array.from({ length: 10 }, (_, i) => i + 1).map((val) => (
                                                    <MenuItem value={val} key={val}>{val} Orang</MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                    :
                                    null
                            }
                            <Grid item xs={12}>
                                <TextField
                                    name='nominal'
                                    label="Nominal"
                                    fullWidth
                                    onChange={handleChange}
                                    value={state.nominal ? numberToCurrency(Math.ceil(state.nominal)) : ''}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Rp. </InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </>
                }

                <Grid item xs={12}>
                    <AsyncAutoComplete
                        label='Reimburse to'
                        name='reimburse_to'
                        onChange={handleChangeAutoComplete}
                        onInputChange={handleAutoCompleteInput}
                        initialQuery={state.reimburse_to_name ? state.reimburse_to_name : ''}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/user`}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextArea
                        name='notes'
                        label={`${['consumption', 'other'].includes(state.type) ? 'Notes (wajib)' : 'Notes (optional)'}`}
                        onChange={handleChange}
                        value={state.notes}
                        placeholder={state.type === 'consumption' ? 'Isi nama partisipan (C: Anto, Hengky, Harto)' : ''}
                        rows={4}
                    />
                </Grid>

                <Grid item xs={12} sx={{ paddingBottom: 0 }}>
                    <Typography>
                        <strong>Bukti Struk</strong>
                    </Typography>
                    <span style={{ fontSize: 14 }}>Max size 8MB</span>
                </Grid>

                {generatePreviewImage(state)}

                <Grid item xs={12}>
                    <DropZone
                        name='proofs'
                        accept={{
                            'image/*': []
                        }}
                        onChange={handleChangeFile(state)}
                        multiple={true}
                    />
                </Grid>

                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Button
                        onClick={handleSubmit}
                        variant='contained'
                        style={{ float: 'right' }}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default ReimbursementItemForm