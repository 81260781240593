import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, Grid, TextField, Button } from '@mui/material'

/**
 * Components
*/
import TextArea from '../../../_components/_form/TextArea'

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, renderToastSuccess } from '../../../_utils/Helper'
import LoadingScreen from '../../../_components/LoadingScreen'


interface IProps {
    open: boolean,
    onClose: (refresh: boolean) => void
}

const initialState = {
    phone: '',
    reason: ''
}

const BlacklistSystemFormModal = (props: IProps) => {
    const [state, setState] = useState(initialState)
    const [isLoading, setIsLoading] = useState(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target

        if (name === 'phone') {
            value = value.replace(/[^\d]/g, "")
        }
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit = () => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/blacklisted-phone`, state)
            .then(res => res.data)
            .then(res => res && [renderToastSuccess('Phone Added!'), props.onClose(true)])
            .catch(generalErrorHandler)
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (!props.open) {
            setState(initialState)
        }
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
        >
            <LoadingScreen open={isLoading} />
            <DialogTitle>Blacklisted Phone Add</DialogTitle>
            <DialogContent>
                <Grid container sx={{ mt: 0 }} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Phone"
                            onChange={handleChange}
                            name="phone"
                            value={state.phone}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextArea
                            name='reason'
                            label="Reason"
                            rows={3}
                            onChange={handleChange}
                            value={state.reason}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            onClick={handleSubmit}
                            sx={{ float: 'right' }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default BlacklistSystemFormModal