import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import { IconButton, TextField, Tooltip } from '@mui/material'

/**
 * Utils
 */
import { convertNumber } from '../../../_utils/Helper'
import { generateStyle } from '../../../_utils/DefaultStyles'

/**
 * Icons
 */
import { Clear, Done, Edit } from '@mui/icons-material'

interface EstimatedTransactionAmountFormProps {
    onSubmit: (price: string) => void
    initialValue?: number
}

const EstimatedTransactionAmountForm = (props: EstimatedTransactionAmountFormProps) => {
    const [isEditing, setIsEditing] = useState(false)
    const { Root, classes } = useStyles();

    const [value, setValue] = useState('')

    useEffect(() => {
        setValue((props.initialValue !== undefined && props.initialValue !== null) ? props.initialValue.toString() : '')
    }, [props.initialValue])

    return (
        <Root>
            <div className={classes.priceContainer}>
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="price"
                    value={value ? convertNumber(value.toString()) : ''}
                    onChange={(e) => {
                        setValue(convertNumber(e.target.value))
                    }}
                    disabled={!isEditing}
                />

                <Tooltip title={isEditing ? 'Save' : 'Edit'}>
                    <IconButton
                        color="primary"
                        component="span"
                        onClick={() => {
                            if (!isEditing) {
                                setIsEditing(true)
                            } else {
                                props.onSubmit(value)
                            }

                        }}
                    >
                        {
                            isEditing
                                ? <Done fontSize="small" />
                                : <Edit fontSize="small" />
                        }
                    </IconButton>
                </Tooltip>

                {
                    isEditing &&
                    <Tooltip title="Cancel">
                        <IconButton
                            color="primary"
                            component="span"
                            onClick={() => {
                                setIsEditing(false)
                                setValue((props.initialValue !== undefined && props.initialValue !== null) ? props.initialValue.toString() : '')
                            }}
                        >
                            <Clear fontSize="small" />
                        </IconButton>
                    </Tooltip>
                }
            </div>
        </Root>
    )
}

const useStyles = generateStyle(theme => ({
    priceContainer: {
        display: 'flex',
        alignItems: 'center'
    },
}), "EstimatedTransactionAmountForm")

export default EstimatedTransactionAmountForm