import React from 'react'
import { Grid } from '@mui/material'

/* 
* Components
*/
import DataTable from '../../_components/_dataTable/DataTable'

/* 
* Utils
*/
import helpers from '../../_utils/Helpers';

const ConsentFormList = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/consent-form';

    const columns = [
        {
            name: 'created_at',
            label: 'Input Date',
            type: 'date',
            filterable: true,
            render: (data: any) => {
                return helpers.dateFormat(data, 'DD MMM YYYY HH:mm');
            }
        },
        {
            name: 'name',
            label: 'Name',
            type: 'string',
            filterable: true,
        },
        {
            name: 'ktp_number',
            label: 'KTP',
            type: 'string',
            filterable: true,
        },
        {
            name: 'email',
            label: 'Email',
            type: 'string',
            filterable: true,
        },
        {
            name: 'phone',
            label: 'Phone',
            type: 'string',
            filterable: true,
        },
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <DataTable
                    url={API_URL}
                    columns={columns}
                />
            </Grid>
        </Grid>
    )
}

export default ConsentFormList