import React, { useContext, useState } from 'react';
import { Button, Link, Switch, IconButton, Select, MenuItem, FormControl, Tooltip, SelectChangeEvent, Grid } from '@mui/material';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

/**
 * Icons
 */
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import GroupIcon from '@mui/icons-material/Group';

/**
 * Components
 */
import ClusterMemberModal from "./_components/ClusterMemberModal";
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';
import AppDataTable, { AppDataTablePageStyle } from '../../_components/_dataTable/AppDataTable';

/**
 * Context
 */
import { PermissionContext } from "../../_contexts/PermissionContext";

/**
 * Utils
 */
import format from 'date-fns/format';
import DefaultAxios from '../../_utils/DefaultAxios';
import helpers from '../../_utils/Helpers';
import ClusterPlacementModal from './ClusterPlacementModal';
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper';

interface Props {
    isApp?: boolean;
}

export interface IState {
    file: File | null;
}

const ClusterList = (props: Props) => {
    const CLUSTER_URL = `${process.env.REACT_APP_API_URL}/cluster`;
    const permissions = useContext(PermissionContext);
    const navigate = useNavigate();
    const [clusterMemberModalValue, setClusterMemberModalValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [clusterPlacementModalValue, setClusterPlacementModalValue] = useState(0);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());
    const [defaultLimit, setDefaultLimit] = useState(0)

    const handleChangeStatusDisplay = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => (event: SelectChangeEvent<any>, child: React.ReactNode) => {
        let type = event.target.value
        let text = ' | Do you want to proceed ?';
        switch (type) {
            case 0: text = 'Please Select' + text; break;
            // case 1: text = 'Custom Page' + text; break;
            case 1: text = 'Blog Article' + text; break;
            default: text = 'undefined'; break;
        }
        if (window.confirm(text)) {
            setIsLoading(true)
            const param = {
                id: row.id,
                type: type
            }
            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/cluster/toggle-page-type`, param)
                .then(res => res.data)
                .then(data => {
                    Swal.fire({
                        title: "Page Type telah di set",
                        icon: 'success',
                        timer: 1000
                    })
                        .then(() => {
                            const newRows = rows.map((element: any) => {
                                if (element.id === row.id) element[columnName] = type
                                return element
                            })
                            setRows(newRows)
                        })
                })
                .catch(error => {
                    // helper.generalErrorHandler(error)    
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }

    }

    const handleChangeLimitPerday = (limit_leads_per_day: number, id: string) => {
        renderWarningButton('Apakah anda yakin akan mengubah limit leads per day pada cluster ini ?')
            .then(result => {
                if (result.value) {
                    setIsLoading(true)
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/cluster/change-limit-per-day`, {
                        id,
                        limit_leads_per_day
                    })
                        .then(res => res.data)
                        .then(res => {
                            if (res) {
                                renderToastSuccess('Limit berhasil diganti')
                            }
                        })
                        .catch(err => generalErrorHandler(err))
                        .finally(() => {
                            setIsLoading(false)
                            setReDrawDatatable(new Date().getTime())
                        })
                }
            })
    }

    const renderStatusDisplay = (name: string, row: any, columnName: string, options: any[], setRows: Function, rows: any[]) => {
        return (
            <FormControl>
                <Select
                    value={row[columnName]}
                    onChange={handleChangeStatusDisplay(name, row, columnName, options, setRows, rows)}
                >
                    <MenuItem value={0}>Default</MenuItem>
                    {/* <MenuItem value={1}>Custom Page</MenuItem> */}
                    <MenuItem value={1}>Blog Article</MenuItem>
                </Select>
            </FormControl>
        )
    }

    const columnsTable = [
        {
            name: 'created_at',
            label: 'Created At',
            type: 'date',
            filterable: true,
            sortable: true,
            render: (data: any, row: any) => {
                return format(new Date(data), 'dd MMMM yyyy')
            }
        },
        {
            name: 'name',
            label: 'Nama Cluster',
            type: 'string',
            filterable: true,
            sortable: true,
            render: (data: any, row: any) => {
                return (
                    <Link
                        component={RouterLink}
                        to={`/cluster/edit/${row.id}`}
                    >
                        {data}
                    </Link>
                )
            }
        },
        {
            name: 'code',
            label: 'Code',
            type: 'string',
            filterable: true,
            sortable: true
        },
        ...(
            permissions['cluster.admin'] ? [{
                name: 'group_name',
                label: 'Group',
                type: 'string',
                filterable: true,
                sortable: true
            }] : []
        ),
        {
            name: 'level',
            label: 'Level',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: 'none',
                    value: 'None'
                },
                {
                    key: 'easy',
                    value: 'Easy'
                },
                {
                    key: 'medium',
                    value: 'Medium'
                },
                {
                    key: 'hard',
                    value: 'Hard'
                },
            ],
            filterable: true,
            render: (data: any) => {
                return data ? data.charAt(0).toUpperCase() + data.slice(1) : 'None';
            }
        },
        {
            name: 'limit_leads_per_day',
            label: 'Max Leads per day',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: 1,
                    value: '1 Per Day'
                },
                {
                    key: 2,
                    value: '2 Per Day'
                },
                {
                    key: 3,
                    value: '3 Per Day'
                },
                {
                    key: 4,
                    value: '4 Per Day'
                },
                {
                    key: 5,
                    value: '5 Per Day'
                },
                {
                    key: 6,
                    value: '6 Per Day'
                },
                {
                    key: 7,
                    value: '7 Per Day'
                },
                {
                    key: 8,
                    value: '8 Per Day'
                },
                {
                    key: 9,
                    value: '9 Per Day'
                },
                {
                    key: 10,
                    value: '10 Per Day'
                },

            ],
            filterable: true,
            render: (data: any, row: any) => {
                return (
                    permissions['cluster.change-limit-leads'] ?
                        <Select
                            value={data}
                            onChange={(e) => handleChangeLimitPerday(e.target.value, row.id)}
                        >
                            <MenuItem value={0}>Default ({defaultLimit} Per day)</MenuItem>
                            {
                                (() => {
                                    let list = []
                                    for (let limit = 1; limit <= 10; limit++) {
                                        list.push(<MenuItem value={limit}>{limit} Per day</MenuItem>)
                                    }
                                    return list
                                })()
                            }
                        </Select> :
                        <span>{data ? data : defaultLimit} Per day</span>
                )
            }
        },
        {
            name: 'cluster_placement_member',
            label: 'PIC',
            type: 'string',
            filterable: true,
            sortable: true,
            render: (data: string, row: any) => {
                let text: any = "";
                if (data) {
                    if (data.includes(",")) {
                        let new_data = data.split(",");

                        text = new_data.map((advisor_data, key) => {
                            if (advisor_data.includes('IS_NOT_ACTIVE')) {
                                let advisor_name = advisor_data.replace('IS_NOT_ACTIVE', '');

                                return (
                                    <span style={{ textDecoration: "line-through", color: "red" }}>
                                        {advisor_name}{key + 1 === new_data.length ? "" : ","}
                                    </span>
                                )
                            } else {
                                return (
                                    <span>{advisor_data}{key + 1 === new_data.length ? "" : ","}</span>
                                )
                            }
                        })
                    } else {
                        if (data.includes('IS_NOT_ACTIVE')) {
                            const advisor_name = data.replace('IS_NOT_ACTIVE', '');
                            text = (
                                <span style={{ textDecoration: "line-through", color: "red" }}>
                                    {advisor_name}
                                </span>
                            )
                        } else {
                            text = <span>{data}</span>
                        }
                    }
                } else {
                    text = "-";
                }

                return (
                    <>
                        {text}
                        {
                            permissions['cluster.manage-member'] &&
                            <Tooltip title="Edit Cluster Placement">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => setClusterPlacementModalValue(row.id)}
                                >
                                    <GroupIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                )
            }
        },
        {
            name: 'developer_name',
            label: 'Developer',
            type: 'string',
            filterable: true,
            sortable: true
        },
        {
            name: 'district_name',
            label: 'District',
            type: 'string',
            filterable: true,
            sortable: true
        },
        {
            name: 'kota_mandiri_name',
            label: 'Kota Mandiri',
            type: 'string',
            filterable: true,
            sortable: true
        },
        {
            name: 'kawasan_name',
            label: 'Kawasan',
            type: 'string',
            filterable: true,
            sortable: true
        },
        {
            name: 'page_type',
            label: 'Page Type',
            type: 'string',
            filterable: true,
            sortable: true,
            defaultOption: '',
            render: renderStatusDisplay,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'Default'
                },
                {
                    key: '1',
                    value: 'Blog Article'
                },
            ]
        },
        {
            name: 'is_custom_page',
            label: 'Custom Page',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'No'
                },
                {
                    key: '1',
                    value: 'Yes'
                }
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return data ? 'Yes' : 'No'
            }
        },
        {
            name: 'is_slp',
            label: 'Short LP',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'No'
                },
                {
                    key: '1',
                    value: 'Yes'
                }
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return data ? 'Yes' : 'No'
            }
        },
        {
            name: 'status',
            label: 'Is Published',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'Not Published'
                },
                {
                    key: '1',
                    value: 'Published'
                }
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return (
                    <Switch
                        checked={data === 1}
                        onChange={() => handlePublishSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                )
            }
        },
        {
            name: 'is_free_dp',
            label: 'Is Free DP',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'No'
                },
                {
                    key: '1',
                    value: 'Yes'
                }
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return !permissions['cluster.manage'] ? (data ? 'Yes' : 'No') : (
                    <Switch
                        checked={data}
                        onChange={() => handleFreeDPSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                )
            }
        },
        {
            name: 'is_full_furnish',
            label: 'Is Full Furnish',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'No'
                },
                {
                    key: '1',
                    value: 'Yes'
                }
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return !permissions['cluster.manage'] ? (data ? 'Yes' : 'No') : (
                    <Switch
                        checked={data}
                        onChange={() => handleFullFurnishSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                )
            }
        },
        {
            name: 'is_handled_by_cp',
            label: 'Is Handled by CP',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'No'
                },
                {
                    key: '1',
                    value: 'Yes'
                }
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return !permissions['cluster.manage'] ? (data ? 'Yes' : 'No') : (
                    <Switch
                        checked={data}
                        onChange={() => handleHandledByCPSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                )
            }
        },
        {
            name: 'is_force_leads',
            label: 'Force Placement Only',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'No'
                },
                {
                    key: '1',
                    value: 'Yes'
                }
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return !permissions['cluster.toggle-force-leads'] ? (data ? 'Yes' : 'No') : (
                    <Switch
                        checked={data}
                        onChange={() => handleToggleForceLeads(row, setRows, rows)}
                        color="secondary"
                    />
                )
            }
        },
        {
            name: 'is_lead_transition',
            label: 'Lead Transition',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '0',
                    value: 'No'
                },
                {
                    key: '1',
                    value: 'Yes'
                }
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return !permissions['cluster.toggle-lead-transition'] ? (data ? 'Yes' : 'No') : (
                    <Switch
                        checked={data}
                        onChange={() => handleToggleLeadTransition(row, setRows, rows)}
                        color="secondary"
                    />
                )
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="View">
                            <IconButton color="primary" href={`${process.env.REACT_APP_MAIN_URL}${row.is_slp === 1 ? '/short' : ''}/${row.slug}`} target="_blank">
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Copy">
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => handleCopyCluster(row.id)}
                            >
                                <FileCopyIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }
        }
    ]

    const handlePublishSwitch = (cluster: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengganti status ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${CLUSTER_URL}/toggle-publish`, { id: cluster.id })
                        .then(res => {
                            const checked = res.data;
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +cluster.id) element['status'] = checked ? 1 : 0
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(error => {
                            if (error.response.status === 422) {
                                const error_text = error.response.data.message;
                                if (error_text === 'no-unit') {
                                    Swal.fire({
                                        title: "Validasi Error",
                                        html: `Cluster tidak bisa disubmit, silahkan buat minimal 1 Unit di Master Data > <a href="/listing/add" rel="noopener noreferrer" target="_blank">Unit</a>`,
                                        icon: "error",
                                    });
                                } else {
                                    Swal.fire({
                                        title: "Validasi Error",
                                        html: error_text,
                                        icon: "error",
                                    });
                                }
                            } else {
                                helpers.generalErrorHandler(error)
                            }
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleFreeDPSwitch = (cluster: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengganti free DP?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${CLUSTER_URL}/toggle-free-dp`, { id: cluster.id })
                        .then(res => {
                            const checked = res.data;
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +cluster.id) element['is_free_dp'] = checked
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(helpers.generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleFullFurnishSwitch = (cluster: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengganti full furnish?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${CLUSTER_URL}/toggle-full-furnish`, { id: cluster.id })
                        .then(res => {
                            const checked = res.data;
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +cluster.id) element['is_full_furnish'] = checked
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(helpers.generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleHandledByCPSwitch = (cluster: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin mengganti handled by CP?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${CLUSTER_URL}/toggle-handled-by-cp`, { id: cluster.id })
                        .then(res => {
                            const checked = res.data;
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +cluster.id) element['is_handled_by_cp'] = checked
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(helpers.generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleToggleLeadTransition = (cluster: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin merubah Lead Transition?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${CLUSTER_URL}/toggle-lead-transition`, { id: cluster.id })
                        .then(res => {
                            const checked = res.data;
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +cluster.id) element['is_lead_transition'] = checked
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(helpers.generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleToggleForceLeads = (cluster: any, setRows: Function, rows: any[]) => {
        renderWarningButton("Apakah anda yakin ingin merubah status Force Placement?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${CLUSTER_URL}/toggle-force-leads`, { id: cluster.id })
                        .then(res => {
                            const checked = res.data;
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +cluster.id) element['is_force_leads'] = checked
                                return element
                            })
                            setRows(newRows);
                        })
                        .catch(helpers.generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleCopyCluster = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin meng-copy cluster ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${CLUSTER_URL}/copy`, { id })
                        .then((res) => {
                            Swal.fire({
                                title: "Berhasil meng-copy",
                                icon: 'success',
                                timer: 1000,
                                onAfterClose: () => {
                                    window.location.href = `cluster/edit/${res.data}`
                                }
                            });
                        })
                        .catch(error => {
                            helpers.generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    return (
        <>
            <ClusterPlacementModal
                isOpen={clusterPlacementModalValue !== 0 ? true : false}
                onClose={() => {
                    setReDrawDatatable(new Date().getTime());
                    setClusterPlacementModalValue(0);
                }}
                cluster_id={clusterPlacementModalValue}
            />

            {props.isApp ?
                <div style={AppDataTablePageStyle}>
                    <AppDataTable
                        url={CLUSTER_URL}
                        columns={columnsTable}
                        responseCallback={(data: any) => setDefaultLimit(data.default_limit_per_day)}
                        reDraw={reDrawDatatable}
                    />
                </div>
                :
                <>
                    <LoadingScreen open={isLoading} fullScreen />
                    <ClusterMemberModal
                        isOpen={clusterMemberModalValue !== 0 ? true : false}
                        onClose={() => setClusterMemberModalValue(0)}
                        cluster_id={clusterMemberModalValue}
                    />
                    <Grid container>
                        <Grid item xs={12}>
                            {
                                permissions['cluster.add'] &&
                                <>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ marginBottom: 24, float: 'left'}}
                                        onClick={() => navigate('/cluster/add')}
                                    >
                                        Add Data
                                    </Button>
                                </>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <DataTable
                                reDraw={reDrawDatatable}
                                url={CLUSTER_URL}
                                columns={columnsTable}
                                responseCallback={(data: any) => setDefaultLimit(data.default_limit_per_day)}
                            // customParams={customParams}
                            />
                        </Grid>
                    </Grid>
                </>
            }
        </>
    )
}

export default ClusterList;
