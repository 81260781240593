import React, { useState, useEffect } from 'react'
import { Grid, Dialog, DialogTitle, DialogContent, Button } from '@mui/material'
import TextArea from '../../../_components/_form/TextArea'

interface IProps {
    open: boolean,
    title?: string
    onSubmit: (reason: string) => void
    onClose: () => void
}

const CancelTransactionModal = (props: IProps) => {
    const [reason, setReason] = useState('')

    useEffect(() => {
        setReason('')
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                { props.title ? props.title : 'Cancel Reason'}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <TextArea
                            name='reason'
                            label="Reason"
                            onChange={(e) => setReason(e.target.value)}
                            value={reason}
                            rows={3}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            onClick={() => props.onSubmit(reason)}
                            disabled={!reason}
                            variant='contained'
                            sx={{float: 'right'}}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default CancelTransactionModal