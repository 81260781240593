import React, { useState } from 'react'

/**
 * Components
 */
import DataTable, { DataTableColumn } from '../../_components/_dataTable/DataTable'
import { IconButton } from '@mui/material'
import TelesalesLeadGroupEdit from './TelesalesLeadGroupEdit'

/**
 * Utils
 */
import { IAutoCompleteOption } from '../../_components/_form/AsycMultipleAutoComplete'

/**
 * Icons
 */
import { Edit } from '@mui/icons-material'

interface TelesalesLeadGroupProps {

}

type Data = {
    id: string
    name: string
    groups: IAutoCompleteOption[]
}

const TelesalesLeadGroupList = (props: TelesalesLeadGroupProps) => {
    const API_URL = process.env.REACT_APP_API_URL + '/lead-group-telesales'

    const [reDrawDatatable, setReDrawDatatable] = useState(0)
    const [selectedData, setSelectedData] = useState<null | Data>(null)

    const openDetail = (id: string, name: string, group_id: number[], group_name: string[]) => {
        setSelectedData({
            id,
            name,
            groups: group_id.map((id, index) => ({
                id,
                label: group_name[index] || '-',
            }))
        })
    }

    const columns: DataTableColumn[] = [
        {
            name: 'name',
            label: 'Name',
            filterable: true,
            type: 'string',
        },
        {
            name: 'group_telesales_name',
            label: 'Area',
            filterable: true,
            type: 'string',
            render: (data: any, row: any) => (
                <span>
                    {data ? data.split(',').join(', ') : '-'}
                    <IconButton
                        sx={{ fontSize: 14, ml: 0.5 }}
                        size='small'
                        onClick={() => openDetail(row.id, row.name, row.group_telesales_id ? row.group_telesales_id.split(',') : [], data ? data.split(',') : [])}
                    >
                        <Edit fontSize='inherit' color='primary' />
                    </IconButton>
                </span>
            )
        },
    ]

    return (
        <>
            <TelesalesLeadGroupEdit
                data={selectedData}
                onClose={() => {
                    setSelectedData(null)
                }}
                onSubmit={() => {
                    setReDrawDatatable(prev => prev + 1)
                }}
            />
            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </>
    )
}

export default TelesalesLeadGroupList
