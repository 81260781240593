import React, { useEffect, useState, useReducer } from 'react'
import { Button, Grid, TextField } from '@mui/material'
import Swal from 'sweetalert2'
import { useParams, useNavigate } from 'react-router-dom'

import LoadingScreen from '../../../_components/LoadingScreen'

import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../../_utils/Helper'
import helpers from '../../../_utils/Helpers'

interface IProps {
    id?: string
    permissions?: any
}

interface IState {
    title: string
    slug: string
    meta_title: string
    meta_description: string
}

interface IAction {
    name: string,
    value: any,
    type: string
}


const CategoryForm = (props: IProps) => {
    const navigate = useNavigate();
    const params: any = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const initialState = {
        title: '',
        slug: '',
        meta_title: '',
        meta_description: '',
    }
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }


    const fetchData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/blog-categories/${params.id}`)
            .then(res => res.data)
            .then(data => {
                setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
            })
            .catch(error => {
                generalErrorHandler(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const submit = () => {

        let axios;

        if (params.id) {
            axios = DefaultAxios.put(`${process.env.REACT_APP_API_URL}/blog-categories/${params.id}`, inputState);
        } else {
            axios = DefaultAxios.post(`${process.env.REACT_APP_API_URL}/blog-categories`, inputState);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        if (params.id) {
                            navigate('/category');
                        } else {
                            navigate('/category');
                        }
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        if (params.id) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])
    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Name"
                        name="title"
                        value={inputState.title}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Slug"
                        name="slug"
                        value={inputState.slug}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Meta Title"
                        name="meta_title"
                        value={inputState.meta_title}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Meta Description"
                        name="meta_description"
                        value={inputState.meta_description}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={submit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default CategoryForm
