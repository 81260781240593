import React, { useState, useEffect } from 'react';
import {
    Button,
    Typography,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Theme,
    Grid,
    Chip,
} from '@mui/material';

/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';
import PublishIcon from '@mui/icons-material/Publish';
import GetAppIcon from '@mui/icons-material/GetApp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import ClearAllIcon from '@mui/icons-material/ClearAll';

/**
 * Transition
 */
import { TransitionProps } from '@mui/material/transitions';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import LoadingScreen from '../../_components/LoadingScreen';
import { generateStyle } from '../../_utils/DefaultStyles';

interface URLPlacementMemberModalProps {
    isOpen: boolean;
    id?: number;
    onClose: (refresh: boolean) => void;
}

interface MemberPlacement {
    id: string;
    user_name: string;
    round_robin_flag: number;
    user_id: string
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = generateStyle((theme: Theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    table: {
        minWidth: 650,
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    containerButton: {
        gap: "5px",
        height: '100%'
    },
    button: {
        height: "100%"
    },
    buttonIconContainer: {
        justifyContent: 'flex-start',
        gap: '5px'
    }
}), "URLPlacementMemberModal");


export default function URLPlacementMemberModal({ isOpen, id, onClose }: URLPlacementMemberModalProps) {
    const { Root, classes } = useStyles();
    const [isEdited, setIsEdited] = useState(false)

    const [isLoading, setIsLoading] = useState(true);
    const [listPlacement, setListPlacement] = useState<Array<MemberPlacement>>([]);
    const [selectedMember, setSelectedMember] = useState<null | { id: string, label: string }>(null);

    useEffect(() => {
        if (!isOpen) setIsEdited(false)
    }, [isOpen])

    const addAdvisor = () => {
        if (selectedMember === null) {
            alert('No PIC Selected');
        } else {
            renderWarningButton("Apakah anda yakin ingin menambah PIC ini?")
                .then((result) => {
                    if (result.value) {
                        setIsLoading(true);
                        /**
                         * Add To List, send pic_id + cluster_id
                         */
                        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/url-whatsapp-placement`, {
                            id,
                            user_id: selectedMember.id
                        })
                            .then(res => {
                                loadData();
                            })
                            .catch(err => {
                                generalErrorHandler(err);
                            })
                            .finally(() => {
                                setIsLoading(false);
                                setIsEdited(true)
                            })
                    }
                });
        }
    }

    const handleDeleteAllPlacement = () => {
        renderWarningButton("Apakah anda yakin ingin menghapus semua member placement ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);

                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/url-whatsapp-placement/delete-all`, {
                        id,
                    })
                        .then(res => {
                            loadData();
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                            setIsEdited(true)
                        })
                }
            });
    }

    const handleAutoAssignActiveAgent = () => {
        renderWarningButton("Apakah anda yakin ingin meng-assign semua aktif agent ke url ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/url-whatsapp-placement/auto-assign-active-agent`, {
                        id,
                    })
                        .then(res => {
                            loadData();
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                            setIsEdited(true)
                        })
                }
            });
    }

    const handleClose = () => {
        onClose(isEdited);
    }

    const deleteMember = (pic_id: string) => {
        renderWarningButton("Apakah anda yakin ingin menghapus member ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    let data = {
                        id,
                        user_id: pic_id
                    }
                    /**
                     * Delete from List
                     */
                    DefaultAxios
                        .post(`${process.env.REACT_APP_API_URL}/url-whatsapp-placement/delete`, data)
                        .then(res => {
                            loadData();
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                            setIsEdited(true)
                        });
                }
            });
    }

    useEffect(() => {
        if (id) {
            loadData();
        }
        // eslint-disable-next-line
    }, [id]);

    const loadData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/url-whatsapp-placement`, { params: { id } })
            .then(res => res.data)
            .then(data => {
                setListPlacement(data)
                setSelectedMember(null)
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setSelectedMember({
            id: value.id.toString(),
            label: value.label,
        });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setSelectedMember(null);
    }

    const handleSortPlacement = (placementId: string, dir: 'up' | 'down' | 'top' | 'bottom') => {
        setIsLoading(true);
        DefaultAxios
            .post(`${process.env.REACT_APP_API_URL}/url-whatsapp-placement/sort`, { dir, id: placementId })
            .then(res => {
                loadData();
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
                setIsEdited(true)
            });
    }

    return (
        <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
            <Root>
                <LoadingScreen open={isLoading} fullScreen />
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Placement Menu
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.content}>
                    <Grid container sx={{ mt: 2 }} spacing={1}>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <AsyncAutoComplete
                                        url={`${process.env.REACT_APP_API_URL}/autocomplete/pic`}
                                        label="Tambah PIC"
                                        name="pic_id"
                                        onChange={handleAutocomplete}
                                        onInputChange={handleAutocompleteInputChanged}
                                        placeholder="Masukan Nama PIC"
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        className={classes.button}
                                        color="primary"
                                        variant="contained"
                                        disabled={selectedMember === null}
                                        onClick={addAdvisor}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Grid container className={classes.containerButton} display={{ md: 'flex', xs: 'none' }}>
                                <Grid item>
                                    <Button
                                        className={classes.button}
                                        color="primary"
                                        variant="contained"
                                        onClick={handleAutoAssignActiveAgent}
                                    >
                                        Auto Assign Active Agent
                                    </Button>
                                </Grid>
                                <Grid item sx={{ ml: "auto" }}>
                                    <Button
                                        className={classes.button}
                                        sx={{ ml: "auto" }}
                                        color="secondary"
                                        variant="contained"
                                        onClick={handleDeleteAllPlacement}
                                    >
                                        Delete All Placement
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container display={{ md: 'none', xs: 'flex' }} className={classes.buttonIconContainer}>
                                <Chip
                                    label="Auto Assign Active Agent"
                                    color="primary"
                                    onClick={handleAutoAssignActiveAgent}
                                    icon={<AutoModeIcon fontSize='small' />} />
                                <Chip
                                    label="Delete All Placement"
                                    color="secondary"
                                    onClick={handleDeleteAllPlacement}
                                    icon={<ClearAllIcon fontSize='small' />} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <TableContainer component={Paper} sx={{ mt: 5 }}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nama</TableCell>
                                    <TableCell>Antrian Leads</TableCell>
                                    <TableCell align='right'>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    listPlacement.length ?
                                        listPlacement.map((row, key) => (
                                            <TableRow key={key}>
                                                <TableCell component="th" scope="row">
                                                    {row.user_name}
                                                </TableCell>
                                                <TableCell>
                                                    {+row.round_robin_flag === 1 ? 'Yes' : 'No'}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    <Button
                                                        color="secondary"
                                                        variant="contained"
                                                        onClick={() => deleteMember(row.user_id)}
                                                    >
                                                        Delete
                                                    </Button>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => handleSortPlacement(row.id, 'top')}
                                                        disabled={key === 0}
                                                    >
                                                        <PublishIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => handleSortPlacement(row.id, 'up')}
                                                        disabled={key === 0}
                                                    >
                                                        <ArrowUpwardIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => handleSortPlacement(row.id, 'down')}
                                                        disabled={key === listPlacement.length - 1}
                                                    >
                                                        <ArrowDownwardIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => handleSortPlacement(row.id, 'bottom')}
                                                        disabled={key === listPlacement.length - 1}
                                                    >
                                                        <GetAppIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        :
                                        <TableRow>
                                            <TableCell colSpan={3} component="th" scope="row">
                                                Tidak ada PIC yang diassign
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Root>
        </Dialog>
    )
}