import React, { useState } from 'react'

/**
 * Components
 */
import { Box, Button, Grid, Typography } from '@mui/material'
import LoadingScreen from '../../_components/LoadingScreen'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { download, generalErrorHandler, renderSuccessButton } from '../../_utils/Helper'


const FinanceTools = () => {
    const [isLoading, setIsLoading] = useState(false)

    const fixMissingLoan = () => {
        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/finance-tools/fix-missing-db`)
            .then(() => {
                renderSuccessButton('Data Finance berhasil di-fix. Silahkan cek ulang datanya')
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const exportClusterListCommission = () => {
        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/cluster/export-commission`, undefined, { responseType: 'blob' })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                download(url, 'komisi_cluster_list.xlsx')
            })
            .catch(err => {
                generalErrorHandler(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const importClusterListCommission = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files && files[0]) {
            const fd = new FormData()
            fd.append('file', files[0])

            setIsLoading(true)

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/cluster/import-commission`, fd)
                .then((res: { data: string[] }) => {
                    renderSuccessButton('Berhasil import file')
                })
                .catch(err => {
                    generalErrorHandler(err)
                })
                .finally(() => {
                    document.querySelectorAll('[type="file"]').forEach(el => {
                        (el as HTMLInputElement).value = ''
                    })
                    setIsLoading(false)
                })
        }
    }
    const exportCompanyClusterList = () => {
        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/cluster/export`, undefined, { responseType: 'blob' })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                download(url, 'cluster_company_list.xlsx')
            })
            .catch(err => {
                generalErrorHandler(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const importCompanyClusterList = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files && files[0]) {
            const fd = new FormData()
            fd.append('file', files[0])

            setIsLoading(true)

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/cluster/import`, fd)
                .then((res: { data: string[] }) => {
                    renderSuccessButton('Berhasil import file')
                })
                .catch(err => {
                    generalErrorHandler(err)
                })
                .finally(() => {
                    document.querySelectorAll('[type="file"]').forEach(el => {
                        (el as HTMLInputElement).value = ''
                    })
                    setIsLoading(false)
                })
        }
    }

    return (
        <>
            <LoadingScreen
                open={isLoading}
                fullScreen
            />
            <Grid container>
                <Grid item xs={12} sx={{ mb: 3 }}>
                    <Typography variant='h6' sx={{ mb: 1 }}>
                        Fix Missing Loan
                    </Typography>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={fixMissingLoan}
                        disabled={isLoading}
                    >
                        Fix Missing Loan
                    </Button>
                </Grid>
                <Grid item xs={12} sx={{mb: 3}}>
                    <Typography variant='h6' sx={{ mb:  1}}>
                        Komisi Cluster List
                    </Typography>
                    <Box display='flex' alignItems='center' flexDirection='row'>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={exportClusterListCommission}
                            disabled={isLoading}
                        >
                            Export
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            component="label"
                            disabled={isLoading}
                            sx={{ ml: 2 }}
                        >
                            Import
                            <input
                                type="file"
                                style={{ display: "none" }}
                                accept=".xls,.xlsx"
                                onChange={importClusterListCommission}
                            />
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h6' sx={{ mb: 1 }}>
                        Cluster Perusahaan List
                    </Typography>
                    <Box display='flex' alignItems='center' flexDirection='row'>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={exportCompanyClusterList}
                            disabled={isLoading}
                        >
                            Export
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            component="label"
                            disabled={isLoading}
                            sx={{ ml: 2 }}
                        >
                            Import
                            <input
                                type="file"
                                style={{ display: "none" }}
                                accept=".xls,.xlsx"
                                onChange={importCompanyClusterList}
                            />
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default FinanceTools
