import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

/**
 * Components
 */
import LoadingScreen from '../../../_components/LoadingScreen';

/**
 * Utils
 */
import { dateFormat } from '../../../_utils/Helper';
import DefaultAxios from '../../../_utils/DefaultAxios';

interface Props {
    open: boolean;
    onClose: () => void;
    userId: string;
}

const UserLog = ({ open, onClose, userId }: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/user/' + userId + '/logs';

    const [isLoading, setIsLoading] = useState(false);
    const [logs, setLogs] = useState<any[]>([]);
    const [selectedLog, setSelectedLog] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    useEffect(() => {
        if (userId) {
            setIsLoading(true);
            DefaultAxios.get(API_URL)
                .then(res => {
                    setLogs(res.data);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
        // eslint-disable-next-line
    }, [userId]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
        >
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
            >
                <DialogContent>
                    <div>
                        <pre>
                            {selectedLog ? JSON.stringify(selectedLog, null, 2) : 'Tidak Ada  Log'}
                        </pre>
                    </div>
                </DialogContent>
            </Dialog>

            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>User Log</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Tanggal</TableCell>
                                <TableCell>Label</TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>Log</TableCell>
                                </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                logs.length
                                    ? logs.map((log, i) => (
                                        <TableRow key={i}>
                                            <TableCell>
                                                <p>{dateFormat(log.datetime, "DD MMMM YYYY HH:mm:ss")}</p>
                                            </TableCell>
                                            <TableCell>
                                                <p>{log.label ? log.label : '-'}</p>
                                            </TableCell>
                                            <TableCell>
                                                <p>{log.user_name ? log.user_name : '-'}</p>
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    ['create', 'update', 'change-buffer-leads'].includes(log.label)
                                                        ? <Button
                                                            color="primary"
                                                            variant="contained"
                                                            size="small"
                                                            onClick={() => {
                                                                setSelectedLog(log.log);
                                                                handleModalOpen();
                                                            }}
                                                        >
                                                            See Log
                                                        </Button>
                                                        : log.log
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    : <TableRow>
                                        <TableCell colSpan={3}>Tidak ada Log</TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog>
    );
}

export default UserLog;
