import { Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler } from '../../../_utils/Helper'

interface IProps {
    slug: string
    value: any
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    loadData: () => void
    setIsLoading: (value: boolean) => void
}

const FormChangeSettingSlug = (props: IProps) => {
    const [error, setError] = useState('');

    const saveLimit = () => {
        if (isNaN(props.value)) {
            setError('Value harus berupa angka dan lebih besar dari 0');

            return;
        }

        props.setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/site-setting/${props.slug}`, { value: props.value || '' })
            .then(res => res.data)
            .then(data => {
                Swal.fire({
                    title: "Berhasil menyimpan",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        props.loadData();
                        setError('');
                    })
            })
            .catch(generalErrorHandler)
            .finally(() => {
                props.setIsLoading(false)
            })
    }

    return (
        <>
            <TextField
                value={props.value}
                onChange={props.handleChange}
                variant='outlined'
                size='small'
                error={!!error}
                helperText={error}
            />
            <Button
                variant='contained'
                color='primary'
                style={{marginLeft: 8}}
                onClick={saveLimit}
            >
                Submit
            </Button>
        </>
    )
}

export default FormChangeSettingSlug
