import React, { useState, useEffect } from 'react';
import {
    Button,
    Typography,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Theme,
    Grid,
    // Chip
} from '@mui/material';
import Swal from 'sweetalert2';


/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';
// import CopyAllIcon from '@mui/icons-material/CopyAll';

/**
 * Transition
 */
import { TransitionProps } from '@mui/material/transitions';

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler, renderWarningButton } from '../../../_utils/Helper';

/**
 * Components
 */
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete';
import { IAutoCompleteOption as IAutocompleteMultipleOption } from '../../../_components/_form/AsycMultipleAutoComplete';
import LoadingScreen from '../../../_components/LoadingScreen';
import CopyPlacementModal from '../../../_components/_modals/CopyPlacementModal';
import { generateStyle } from '../../../_utils/DefaultStyles';

interface IProps {
    isOpen: boolean;
    user_name: string;
    user_id: string;
    onClose: Function;
}

interface IListCluster {
    id: string;
    name: string;
    page_type: string;
    slug: string;
    cluster_id: number;
    avg_daily_leads: number;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = generateStyle((theme: Theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    table: {
        minWidth: 650,
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    button: {
        height: "100%"
    },
    containerButton: {
        gap: "5px",
        height: '100%'
    },
    buttonIconContainer: {
        justifyContent: 'flex-start',
        gap: '5px'
    }
}), "UserPlacement_Modal");

export default function ClusterPlacementModal({ isOpen, user_name, user_id, onClose }: IProps) {
    const { Root, classes } = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [listPlacement, setListPlacement] = useState<Array<IListCluster>>([]);
    const [selectedCluster, setSelectedCluster] = useState("");
    const [copyPlacementModalOpen, setCopyPlacementModalOpen] = useState(false);
    const [copyPlacementTargetIds, setCopyPlacementTargetIds] = useState<IAutocompleteMultipleOption[]>([]);

    const [refresh, setRefresh] = useState(new Date().getTime())

    // const handleCopyPlacementModalOpen = () => setCopyPlacementModalOpen(true);
    const handleCopyPlacementModalClose = () => setCopyPlacementModalOpen(false);

    const addAdvisor = () => {
        if (selectedCluster === "") {
            alert('No Cluster Selected');
        } else {
            renderWarningButton("Apakah anda yakin ingin menambah Cluster ini?")
                .then((result) => {
                    if (result.value) {
                        setIsLoading(true);
                        /**
                         * Add To List, send pic_id + cluster_id
                         */
                        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/cluster/placement`, {
                            cluster_id: selectedCluster,
                            pic_id: user_id
                        })
                            .then(res => {
                                loadData();
                            })
                            .catch(err => {
                                generalErrorHandler(err);
                            })
                            .finally(() => {
                                setIsLoading(false);
                            })
                    }
                });
        }
    }

    const handleClose = () => {
        onClose();
    }

    const deleteMember = (cluster_id: number) => {
        renderWarningButton("Apakah anda yakin ingin menghapus cluster ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    let data = {
                        cluster_id: cluster_id,
                        pic_id: user_id
                    }
                    /**
                     * Delete from List
                     */
                    DefaultAxios
                        .delete(`${process.env.REACT_APP_API_URL}/cluster/placement/${cluster_id}`, { params: data })
                        .then(res => {
                            loadData();
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                }
            });
    }

    useEffect(() => {
        if (user_id !== "") {
            loadData();
        }
        // eslint-disable-next-line
    }, [user_id]);

    useEffect(() => {
        if (!copyPlacementModalOpen) {
            setCopyPlacementTargetIds([]);
        }
    }, [copyPlacementModalOpen])

    const loadData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/cluster/placement/user`, { params: { id: user_id } })
            .then(res => {
                setListPlacement(res.data as Array<IListCluster>)
                setSelectedCluster("")
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
                setRefresh(new Date().getTime())
            });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setSelectedCluster(value.id as string);
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setSelectedCluster("");
    }

    const handleCopyPlacementSubmit = () => {
        if (copyPlacementTargetIds.length === 0) {
            return;
        }

        renderWarningButton("Apakah anda yakin ingin meng-copy placement ini?")
            .then((result) => {
                if (result.value) {
                    const targetIds = copyPlacementTargetIds.map(item => item.id);

                    setIsLoading(true);
                    DefaultAxios.post(process.env.REACT_APP_API_URL + '/cluster/placement/copy-placement-user', {
                        user_id_source: user_id,
                        user_id_target: targetIds
                    })
                        .then(res => {
                            Swal.fire({
                                title: "Copy placement berhasil",
                                icon: 'success',
                                timer: 1000,
                                onAfterClose: handleCopyPlacementModalClose
                            })
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    return (
        <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
            <Root>
                <LoadingScreen open={isLoading} fullScreen />
                <CopyPlacementModal
                    open={copyPlacementModalOpen}
                    onClose={handleCopyPlacementModalClose}
                    label="Agent"
                    url={process.env.REACT_APP_API_URL + '/autocomplete/user/agent?except_ids=' + user_id}
                    targetIds={copyPlacementTargetIds}
                    setTargetIds={setCopyPlacementTargetIds}
                    onSubmit={handleCopyPlacementSubmit}
                />
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            User Placement - {user_name}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div className={classes.content}>
                    <Grid container spacing={1} sx={{ mt: 1 }}>
                        <Grid item xs={12} md={4}>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <AsyncAutoComplete
                                        key={refresh}
                                        url={`${process.env.REACT_APP_API_URL}/autocomplete/cluster-detailed`}
                                        label="Tambah Cluster"
                                        name="cluster_id"
                                        initialQuery={''}
                                        onChange={handleAutocomplete}
                                        onInputChange={handleAutocompleteInputChanged}
                                        placeholder="Masukan Nama Cluster"
                                    />

                                </Grid>
                                <Grid item>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.button}
                                        disabled={selectedCluster === ""}
                                        onClick={addAdvisor}
                                    >
                                        Submit
                                    </Button>

                                </Grid>

                            </Grid>
                        </Grid>

                        {/* <Grid item md={6} xs={12}>
                        <Grid container className={classes.containerButton} display={{ md: 'flex', xs: 'none' }}>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.button}
                                    onClick={handleCopyPlacementModalOpen}
                                >
                                    Copy Placement
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container display={{ md: 'none', xs: 'flex' }} className={classes.buttonIconContainer}>
                            <Chip
                                label="Copy Placement"
                                color="primary"
                                onClick={handleCopyPlacementModalOpen}
                                icon={<CopyAllIcon fontSize='small' />}
                            />
                        </Grid>
                    </Grid> */}
                    </Grid>

                    <br></br>
                    <br></br>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nama Cluster</TableCell>
                                    {/* <TableCell>
                                    Predicted Daily Leads Cluster
                                    <Tooltip title="Predicted daily Leads for specific cluster for each agent" placement="top">
                                        <InfoIcon fontSize="small" style={{ marginLeft: "5px" }}/>
                                    </Tooltip>
                                </TableCell> */}
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listPlacement.map((row, key) => (
                                    <TableRow key={row.cluster_id}>
                                        <TableCell component="th" scope="row">
                                            {row.name}<span style={{ fontSize: "10px" }}> / {row.page_type}  / {row.slug}</span>
                                        </TableCell>
                                        {/* <TableCell>
                                        {row.avg_daily_leads}
                                    </TableCell> */}
                                        <TableCell>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                onClick={() => deleteMember(row.cluster_id)}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Root>
        </Dialog>
    )
}