import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

/**
 * Components
*/
import SimpleDataTable from '../../../_components/_dataTable/SimpleDataTable'
import { IconButton } from '@mui/material'
import { Grid } from '@mui/material'
import StatementFormModal, { typeOptions } from './StatementFormModal'
import SliderModal from '../../../_components/_modals/SliderModal'
import LoadingScreen from '../../../_components/LoadingScreen'

/**
 * Utils
*/
import DefaultAxios from '../../../_utils/DefaultAxios'
import { convertNumber, generalErrorHandler, renderSuccessButton } from '../../../_utils/Helper'
import { Tooltip } from '@mui/material'

/**
 * Icons
*/
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

type Props = {
    open: boolean
    onClose: (refres: boolean) => void
    id: number
}

interface IStatementList {
    id: number
    type: string,
    trailer: string,
    amount: number
}

const StatementListModal = (props: Props) => {
    const [statementList, setStatementList] = useState<IStatementList[]>([])
    const [reDraw, setReDraw] = useState(new Date().getTime())
    const [statementAddModal, setStatementAddModal] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleDelete = (id: number) => {
        let newStatementList = [...statementList]
        newStatementList = newStatementList.filter(item => item.id !== id)
        setStatementList(newStatementList)
    }

    useEffect(() => {
        setReDraw(new Date().getTime())
    }, [statementList])

    useEffect(() => {
        if (props.open) {
            setStatementList([])
        }
    }, [props.open])

    const columns = [
        {
            name: 'type',
            label: 'Type',
            type: 'string',
            columnStyle: { textAlign: 'left' },
            render: (data: string) => {
                return (
                    <span style={{ textAlign: 'left' }}>
                        {data ? typeOptions.find(item => item.value === data)?.label : '-'}
                    </span>
                )
            }
        },
        {
            name: 'trailer',
            label: 'Trailer',
            columnStyle: { textAlign: 'left' },
        },
        {
            name: 'amount',
            label: 'Amount',
            type: 'string',
            columnStyle: { textAlign: 'left' },
            render: (data: string, row: any) => {
                return data ? convertNumber(String(data), 'Rp. ') : '-'
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            columnStyle: { textAling: 'left', float: 'left' },
            render: (row: any) => {
                return (
                    <div>
                        <Tooltip title="Delete">
                            <IconButton
                                color="secondary"
                                component="span"
                                onClick={() => handleDelete(row.id)}
                            >
                                <HighlightOffIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }
        },
    ];

    const handleAddData = () => {
        setStatementAddModal(true)
    }

    const handelAddStatement = (statement: IStatementList) => {
        setStatementList(prev => ([
            ...prev,
            statement
        ]))
    }

    const handleSubmit = () => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-transaction/assign-manual`, {
            id: +props.id,
            statements: statementList
        })
            .then(res => res.data)
            .then(res => {
                renderSuccessButton('Statement berhasil di-assign manual')
                props.onClose(true)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            {
                isLoading &&
                <LoadingScreen fullScreen open={isLoading} />
            }
            <Dialog
                open={props.open}
                onClose={() => props.onClose(false)}
                maxWidth={'md'}
                fullWidth
            >
                <DialogTitle>
                    Statement list
                </DialogTitle>
                <DialogContent>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <SimpleDataTable
                                reDraw={reDraw}
                                columns={columns}
                                customData={statementList}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant='outlined'
                                onClick={handleAddData}
                            >
                                Add Data
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                disabled={!statementList.length}
                                style={{ float: 'right' }}
                                onClick={() => setConfirmModal(true)}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <StatementFormModal
                statementList={statementList}
                open={statementAddModal}
                onClose={() => {
                    setStatementAddModal(false)
                }}
                onSubmit={(statement) => {
                    handelAddStatement(statement)
                }}
            />

            <SliderModal
                onClose={() => setConfirmModal(false)}
                onSubmit={() => {
                    handleSubmit()
                    setConfirmModal(false)
                }}
                open={confirmModal}
                customMessage={
                    <>
                        <Typography style={{ textAlign: 'center', marginBottom: 10, fontWeight: '700' }}>Peringatan!</Typography>
                        <Typography style={{ textAlign: 'center' }} variant='body2'>Uang keluar akan di-setting manual dan tidak bisa di propose by sistem lagi. Geser untuk melanjutkan</Typography>
                    </>
                }

            />
        </>
    )
}

export default StatementListModal