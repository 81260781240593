import React, { useState } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom'
import { Grid, ButtonGroup, Button, Tooltip, Fab, Theme, Paper, /* TextField */ } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Components
import ListingDetailForm from './ListingDetailForm';
import ImageFormPage from '../../_components/_form/ImageForm/Page';
import Photo360FormPage from '../../_components/_form/Photo360Form/Page';
import LoadingScreen from '../../_components/LoadingScreen';

import ModalDetailListing from "./ModalDetailListing";
import { generateStyle } from '../../_utils/DefaultStyles';

// import helpers from '../../_utils/Helpers';

// import Swal from 'sweetalert2';

const useStyles = generateStyle((theme: Theme) => ({
        borderBottomLine: {
            borderBottom: '1px solid #eee'
        },
        fab: {
            position: 'fixed',
            bottom: '2rem',
            right: '2rem',
            display: 'none',
            zIndex: 1,
            [theme.breakpoints.up('sm')]: {
                display: 'block'
            }
        },
        fabSpacing: {
            marginRight: '1rem',
        },
        container: {
            paddingBottom: '50px'
        }
    }), "Listing_Form"
);

const ListingForm = () => {
    const { Root, classes } = useStyles();
    const location = useLocation();
    const params: any = useParams();
    const navigate = useNavigate();

    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false)

    const [type, setType] = useState(typeof params.type !== 'undefined' ? params.type : 'details');
    const isLoading = false;

    const [copiedAssetCode] = useState("");

    const renderForm = () => {
        if (params.id) {
            if (type === 'details') {
                return <ListingDetailForm
                    listingId={params.id}
                    mode="edit"
                />
            } else if (type === 'image') {
                if (copiedAssetCode !== "" && copiedAssetCode !== "EXIST") {
                    return (
                        <h1>
                            Harap ganti image / 360 di <Link to={`/listing/edit/${copiedAssetCode}/image`} target="_blank" rel="noopener noreferrer">{copiedAssetCode}</Link>
                        </h1>)
                } else {
                    return <ImageFormPage
                        API_URL={`${process.env.REACT_APP_API_URL}/listing/${params.id}/image`}
                    />
                }
            } else if (type === '360-photo') {
                if (copiedAssetCode !== "" && copiedAssetCode !== "EXIST") {
                    return (
                        <h1>
                            Harap ganti image / 360 di <Link to={`/listing/edit/${copiedAssetCode}/360-photo`} target="_blank" rel="noopener noreferrer">{copiedAssetCode}</Link>
                        </h1>)
                } else {
                    return <Photo360FormPage
                        API_URL={`${process.env.REACT_APP_API_URL}/listing/${params.id}/360-photo`}
                    />
                }
            }
        } else {
            return <ListingDetailForm
                listingId={null}
                mode="add"
            />
        }
    }

    const handleNavigation = (route: string) => {
        if (route !== type) {
            if (route === 'details') {
                navigate(`/listing/edit/${params.id}`);
            } else {
                navigate(`/listing/edit/${params.id}/${route}`);
            }
            setType(route);
        }
    }

    // const handleCopyAsset = () => {
    //     if (lockCopy) {
    //         if (window.confirm('Bila anda mengganti ini, semua listing akan dihapus dan diganti dengan asset kode listing yang baru. lanjutkan?')) {
    //             setLockCopy(false);
    //             copiedCode!.current!.value = "";
    //         }
    //     } else {
    //         if (typeof copiedCode.current !== "undefined") {
    //             const code = copiedCode.current;
    //             if (window.confirm('Apakah anda yakin ingin meng-copy data ini?')) {
    //                 DefaultAxios.post(`${process.env.REACT_APP_API_URL}/listing/copy-images`, {
    //                     copied_code: code.value,
    //                     listing_id: params.id
    //                 })
    //                     .then(res => {
    //                         Swal.fire({
    //                             title: "Copy Asset Berhasil",
    //                             icon: 'success',
    //                             timer: 1000
    //                         })
    //                             .then(res => {
    //                                 window.location.reload(false);
    //                             })
    //                     })
    //                     .catch(err => {
    //                         helpers.generalErrorHandler(err);
    //                     })
    //             }
    //         }
    //     }
    // }

    // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const files = e.target.files;
    //     if (files && files.length) {
    //         const newFiles = [];
    //         for (let [, file] of Object.entries(files)) {
    //             newFiles.push({
    //                 name: file.name,
    //                 file: file
    //             });
    //         }
    //         setFiles(newFiles);
    //     } else {
    //         setFiles([]);
    //     }
    // }

    // const handleMultipleUpload = () => {
    //     if (!files.length) {
    //         Swal.fire({
    //             title: 'Error',
    //             text: 'Silahkan pilih gambar untuk di upload',
    //             icon: 'warning'
    //         });

    //         return;
    //     }

    //     setIsLoading(true);
    //     const promises = [];

    //     for (let file of files) {
    //         if (file.file.type.includes('image')) {
    //             promises.push(new Promise((resolve, reject) => {
    //                 helpers.resizeAndResetOrientationImage(file.file)
    //                     .then((res: Blob) => {
    //                         resolve({
    //                             name: file.name,
    //                             file: res
    //                         });
    //                     })
    //             }))
    //         }
    //     }

    //     allSettled(promises)
    //         .then((results: any) => {
    //             const fd = new FormData();
    //             fd.append('listing_id', params.id);
    //             for (let result of results) {
    //                 fd.append('upload_file[]', result.file, result.name);
    //             }

    //             DefaultAxios.post(process.env.REACT_APP_API_URL + '/listing/bulk-image-upload', fd)
    //                 .then(res => {
    //                     Swal.fire({
    //                         title: 'Success',
    //                         text: 'Upload images successful',
    //                         icon: 'success',
    //                         timer: 1000
    //                     })
    //                     .then(() => {
    //                         window.location.reload();
    //                     });
    //                 })
    //                 .catch(err => {
    //                     helpers.generalErrorHandler(err);
    //                 })
    //                 .finally(() => {
    //                     setIsLoading(false);

    //                 })
    //         });
    // }

    // const allSettled = function (promiseList: any) {
    //     let results = new Array(promiseList.length);

    //     return new Promise((ok, rej) => {

    //         let fillAndCheck = function (i: any) {
    //             return function (ret: any) {
    //                 results[i] = ret;
    //                 for (let j = 0; j < results.length; j++) {
    //                     if (results[j] == null) return;
    //                 }
    //                 ok(results);
    //             }
    //         };

    //         for (let i = 0; i < promiseList.length; i++) {
    //             promiseList[i].then(fillAndCheck(i), fillAndCheck(i));
    //         }
    //     });
    // }

    // const handleRefreshAsset = () => {
    //     Swal.fire({
    //         title: "Apakah anda yakin ingin merefresh asset \n (listing yang datanya dicopy akan direfresh!)",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Ya'
    //     })
    //         .then((result) => {
    //             if (result.value) {
    //                 DefaultAxios.post(`${process.env.REACT_APP_API_URL}/listing/refresh-asset`, {
    //                     listing_id: params.id
    //                 })
    //                     .then(res => {
    //                         Swal.fire({
    //                             title: "Copy Asset Berhasil",
    //                             icon: 'success',
    //                             timer: 1000
    //                         })
    //                             .then(res => {
    //                                 // window.location.reload(false);
    //                             })
    //                     })
    //                     .catch(err => {
    //                         helpers.generalErrorHandler(err);
    //                     })
    //             }
    //         })
    // }

    return (
        <Root>
            <Grid container spacing={3} className={classes.container}>
                <LoadingScreen open={isLoading} fullScreen />
                <Grid item xs={12} md={type === 'details' ? 7 : 12}>
                    <Paper style={{ padding: 16 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <h1 className={classes.borderBottomLine}>Listing - {location.pathname === '/listing/add' ? 'Add' : 'EDIT'} </h1>
                            </Grid>

                            {/* {(location.pathname !== '/listing/add' && (copiedAssetCode === "" || copiedAssetCode === "EXIST")) &&
                                <Grid item xs={12}>
                                    <Button variant="contained" color="primary" onClick={handleRefreshAsset}>
                                        Refresh Asset
                                </Button>
                                </Grid>
                            } */}

                            {/* {(type === 'image') &&
                                <>
                                    {(copiedAssetCode === "" || copiedAssetCode === "EXIST") &&
                                        <Grid item xs={6}>
                                            <input type="file" name="file" multiple onChange={handleFileChange} />
                                            <Button variant="contained" color="primary" style={{ marginLeft: "5px" }} onClick={handleMultipleUpload}>
                                                Upload
                                        </Button>
                                        </Grid>
                                    }

                                    {copiedAssetCode !== "EXIST" &&
                                        <Grid container xs={(copiedAssetCode === "" || copiedAssetCode === "EXIST") ? 6 : 12} direction="row-reverse">
                                            <Button variant="contained" color="primary" style={{ marginLeft: "5px" }} onClick={handleCopyAsset}>
                                                {lockCopy ? 'UNLOCK' : 'Copy'}
                                            </Button>

                                            <TextField
                                                variant="outlined"
                                                label="Copy Asset From"
                                                InputLabelProps={{ shrink: true }}
                                                placeholder="Masukkan Kode Listing"
                                                inputRef={copiedCode}
                                                disabled={lockCopy}
                                            >

                                            </TextField>
                                        </Grid>
                                    }
                                </>
                            } */}

                            {
                                location.pathname !== '/listing/add'
                                    ? <Grid item xs={12}>
                                        <ButtonGroup
                                            color="primary"
                                            aria-label="outlined primary button group"
                                            fullWidth
                                        >
                                            <Button
                                                variant={type === 'details' ? 'contained' : 'outlined'}
                                                onClick={() => handleNavigation('details')}
                                            >
                                                Details
                                            </Button>
                                            <Button
                                                variant={type === 'image' ? 'contained' : 'outlined'}
                                                onClick={() => handleNavigation('image')}
                                            >
                                                Images
                                            </Button>
                                            <Button
                                                variant={type === '360-photo' ? 'contained' : 'outlined'}
                                                onClick={() => handleNavigation('360-photo')}
                                            >
                                                360 Photo
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                    : null
                            }

                            {renderForm()}
                        </Grid>
                    </Paper>
                </Grid>

                {
                    !!params.id &&
                    <>
                        <div className={classes.fab}>
                            <Tooltip title="Show Detail" className={classes.fabSpacing} placement="top" arrow>
                                <Fab aria-label="Show Detail" color="primary" onClick={() => setIsDetailModalOpen(true)}>
                                    <VisibilityIcon />
                                </Fab>
                            </Tooltip>
                        </div>
                        {
                            (isDetailModalOpen && !!params.id) &&
                            <ModalDetailListing id={params.id} close={() => setIsDetailModalOpen(false)} />
                        }
                    </>
                }
            </Grid>
        </Root>
    );
}

export default ListingForm;