import { Dialog, DialogContent, DialogTitle, Grid, TextField, Button, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'

/**
 * Components
 */
import AsyncAutoComplete from '../../../_components/_form/AsyncAutoComplete'
import LoadingScreen from '../../../_components/LoadingScreen'

/**
 * Utils
 */
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, renderSuccessButton } from '../../../_utils/Helper'

interface IProps {
    open: boolean,
    onClose: (refresh: boolean) => void
}

interface IState {
    user_id: number | null
    bank_type_id: number | null
    user_label: string
    bank_type_label: string
    behalf_name: string
    number: string
}

const initialState = {
    user_id: null,
    user_label: '',
    bank_type_label: '',
    bank_type_id: null,
    behalf_name: '',
    number: ''
}

const UserBankForm = (props: IProps) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/user-bank`
    const [inputState, setInputState] = useState<IState>(initialState)
    const [isLoading, setIsLoading] = useState(false)

    const handleChangeAutoComplete = (name: string, value: any) => {
        setInputState(prev => ({
            ...prev,
            [`${name}_id`]: value.id,
            [`${name}_label`]: value.label
        }))
    }

    const handleClear = (name: string) => {
        setInputState(prev => ({
            ...prev,
            [`${name}_id`]: null,
            [`${name}_label`]: ''
        }))
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, autoCompleteName?: string) => {
        let { name, value } = e.target
        if (['user', 'bank_type'].includes(name)) {
            return setInputState(prev => ({
                ...prev,
                [`${autoCompleteName}_id`]: null,
                [`${autoCompleteName}_label`]: ''
            }))
        }

        if (name === 'number') {
            value = value.replace(/\D/g, '');
        }

        setInputState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit = () => {
        setIsLoading(true)
        DefaultAxios.post(API_URL, inputState)
            .then(res => {
                renderSuccessButton('User Bank telah ditambahkan')
                props.onClose(true)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })

    }

    useEffect(() => {
        if (!props.open) setInputState(initialState)
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>User Form</DialogTitle>
            <DialogContent>
                <LoadingScreen open={isLoading} fullScreen />
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            label='User'
                            name='user'
                            onChange={handleChangeAutoComplete}
                            placeholder='Masukkan nama User'
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/user/all`}
                            onInputChange={handleInputChange}
                            clearable
                            onClear={() => handleClear('user')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            label='Bank'
                            name='bank_type'
                            placeholder='Masukkan nama Bank'
                            onChange={handleChangeAutoComplete}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/bank-type`}
                            onInputChange={handleInputChange}
                            clearable
                            onClear={() => handleClear('bank_type')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Atas Nama Bank"
                            name="behalf_name"
                            value={inputState.behalf_name}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="No. Rekening"
                            name="number"
                            value={inputState.number}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Note: Bila user sudah punya data bank, data lama akan dinonaktifkan</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            sx={{ float: 'right' }}
                            variant='contained'
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default UserBankForm