import React from 'react'
import { Dialog, DialogTitle, DialogContent } from '@mui/material'

/**
 * Components
 */
import ReimbursementItemForm from './ReimbursementItemForm'

/**
 * Interfaces
 */
import { IItem } from './ReimbursementModalDetail'

interface IProps {
    type: string | null
    open: boolean,
    onClose: (refresh: boolean) => void
    reimburse_id: number,
    defaultState?: IItem | null
}

const ReimbursementItemModalFrom = (props: IProps) => {
  return (
    <Dialog
        open={props.open}
        onClose={() => props.onClose(true)}
        maxWidth="sm"
        fullWidth
    >
        <DialogTitle>
            {
                props.type !== null ?
                props.type === 'consumption' ? 'Form Item Konsumsi dan Lainnya' :
                'Form Item Bensin' : ''
            }
        </DialogTitle>
        <DialogContent>
            <ReimbursementItemForm
                defaultState={props.defaultState || null}
                type={props.type}
                reimburse_id={props.reimburse_id}
                doneSubmit={(refresh) => props.onClose(refresh)}
            />
        </DialogContent>
    </Dialog>
  )
}

export default ReimbursementItemModalFrom