import React, { Fragment, useState } from 'react';
import { Button, Switch, Link, IconButton } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Components
import LoadingScreen from '../../../_components/LoadingScreen';
import DataTable from '../../../_components/_dataTable/DataTable';

// Icons
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import helpers from '../../../_utils/Helpers';
import { renderWarningButton } from '../../../_utils/Helper';

interface Props {
    permissions?: any;
}

const ArticleList = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/blogs';
    // const classes = useStyles();
    const navigate = useNavigate();

    const handlePreview = (id: string, slug: string) => {
        setIsLoading(true);
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/blogs/${id}/preview`)
            .then(res => {
                window.open(`${process.env.REACT_APP_MAIN_URL}/artikel/${slug}?preview=1`);
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }
    const columns = [
        {
            name: 'title',
            label: 'Title',
            type: 'string',
            filterable: true,
            render: (data: any, row: any) => {
                return (
                    <>
                        <Link
                            component={RouterLink}
                            to={`/article/edit/${row.id}`}
                        >
                            {data}
                        </Link>
                    </>
                );
            }
        },
        {
            name: 'slug',
            label: 'Slug',
            type: 'string',
            filterable: true
        },
        {
            name: 'category_label',
            label: 'Category',
            type: 'string',
            filterable: true
        },
        {
            name: 'is_published',
            label: 'Is Published',
            filterable: true,
            sortable: false,
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Yes'
                },
                {
                    key: '0',
                    value: 'No'
                }
            ],
            defaultOption: '',
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return (
                    <Switch
                        checked={data === 1}
                        onChange={(e) => handlePublishSwitch(e, row, setRows, rows)}
                        color="secondary"
                    />
                )
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            sortable: false,
            style: { maxWidth: '100px' },
            render: (row: any) => {
                return (
                    <IconButton
                        color="primary"
                        component="span"
                        onClick={() => handlePreview(row.id, row.slug)}
                    >
                        <VisibilityIcon />
                    </IconButton>
                );
            }
        }
    ];

    const [isLoading, setIsLoading] = useState(false);
    // const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const handlePublishSwitch = (e: React.ChangeEvent<{ checked: boolean }>, article: any, setRows: Function, rows: any[]) => {
        let checked = e.target.checked
        let data = checked ? 1 : 0
        renderWarningButton("Apakah anda yakin ingin mengganti status ini?")
        .then((result) => {
            if (result.value) {
                setIsLoading(true);
                DefaultAxios
                    .post(`${API_URL}/${article.id}/toggle-publish`, { id: article.id, is_published: data })
                    .then(res => {
                        const checked = res.data;
                        const newRows = rows.map((element: any) => {
                            if (+element.id === +article.id) element['is_published'] = checked ? 1 : 0
                            return element
                        })
                        setRows(newRows);
                    })
                    .catch(error => {
                        helpers.generalErrorHandler(error)
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }
        })
    }

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            <Button
                variant="contained"
                color="primary"
                component="label"
                style={{ marginBottom: 24 }}
                onClick={() => navigate(`/article/add`)}
            >
                Add Data
            </Button>
            <DataTable
                url={API_URL}
                // reDraw={reDrawDatatable}
                columns={columns}
            />
        </Fragment>
    );
}

export default ArticleList;