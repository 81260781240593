import React from 'react'
import { generateStyle } from '../../../_utils/DefaultStyles'

type Props = {
    type: 'circle' | 'polygon'
    status?: string
    label?: string | number
    onClick?: () => void
    active?: boolean
}

const ChipInfo = (props: Props) => {
    const { Root, classes } = useStyles()

    const renderScore = (statusValue: string | undefined) => {
        const status = statusValue || 'default'
        const style = {
            default: { background: '#E0FFE4', borderColor: '#1FAB30' },
            warning: { background: '#FFF5DB', borderColor: '#FCBF1E' },
            danger: { background: '#FFE0E0', borderColor: '#F56C6C' },
        }

        return (
            <div className={classes.circle} onClick={props.onClick} style={style[status as keyof typeof style]}>
                {props.label}
            </div>
        )
    }

    const renderPolygon = () => {
        return (
            <div className={`${classes.polygonWrapper} ${typeof props.onClick !== 'undefined' && classes.polygonWrapperActive}`} style={props.active ? { color: '#fff' } : {}} onClick={props.onClick}>
                <div className={`${classes.polygon} ${typeof props.onClick !== 'undefined' && classes.polygonActive}`} style={props.active ? { color: 'rgb(0, 86, 168)' } : {}}>
                    <svg width="80" height="82" viewBox="0 0 80 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.399304 7.87973C-0.881276 3.99797 2.00972 0 6.09725 0H60.4102C62.9187 0 65.1625 1.56056 66.0353 3.91234L79.025 38.9123C79.5249 40.2592 79.5249 41.7408 79.025 43.0877L66.0353 78.0877C65.1625 80.4394 62.9187 82 60.4102 82H6.09726C2.00972 82 -0.881275 78.002 0.399305 74.1203L10.7055 42.8797C11.1082 41.6589 11.1082 40.3411 10.7055 39.1203L0.399304 7.87973Z" fill="currentColor   " />
                    </svg>
                </div>
                <span style={{ zIndex: 1 }}>{props.label}</span>
            </div>
        )
    }
    return (
        <Root>
            {
                props.type === 'circle' ?
                    renderScore(props.status) :
                    renderPolygon()
            }
        </Root>
    )
}

const useStyles = generateStyle(theme => ({
    circle: {
        width: 80,
        height: 80,
        borderRadius: '50%',
        borderWidth: 1,
        fontSize: 14,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid transparent',
        whiteSpace: 'pre-line',
        textAlign: 'center'
    },
    polygonWrapper: {
        width: 80,
        height: 80,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    polygon: {
        position: 'absolute',
        color: '#CCE7FF',
    },
    polygonWrapperActive: {
        cursor: 'pointer',
        [`&:hover`]: {
            color: '#fff'
        },
    },
    polygonActive: {
        [`&:hover`]: {
            color: theme.palette.primary.main
        },
    },
}), 'Chip_Info')

export default ChipInfo