import { ClassNameMap, Theme, styled, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

export type StyleObject = Record<string, React.CSSProperties | Record<string, React.CSSProperties>>
type IClasses = Record<string, string>

interface IOptions {
    CustomParent?: any,
    depedency?: any[]
}

export function generateStyle(callback: (theme: Theme) => StyleObject, prefix: string, { CustomParent, depedency }: IOptions = {}) {
    return function () {
        const theme = useTheme();
        const [refreshHead, setRefreshHead] = useState(new Date().getTime())
        const className = (name: string) => `${name}-${prefix}`;

        useEffect(() => {
            return () => {
                setRefreshHead(new Date().getTime())
            }
        }, [])

        const data = callback(theme);

        type Classes = ClassNameMap<keyof typeof data>

        const classes: Classes = Object.keys(data).reduce((acc, item) => {
            acc[item] = className(item);
            return acc;
        }, {} as Classes);

        const generateStyle = (theme: Theme, classes: IClasses, data: StyleObject) => {
            let allStyles: StyleObject = {};
            Object.entries(data).forEach(([name, style]) => {
                Object.assign(allStyles, {
                    [`& .${classes[name]}`]: style
                })
            })

            return allStyles;
        }

        const Root = useMemo(() => {
            return styled(typeof CustomParent !== 'undefined' ? CustomParent : 'div')(({ theme }) => generateStyle(theme, classes, data));

            // eslint-disable-next-line
        }, [refreshHead, ...(depedency ? depedency : [])])

        return {
            classes,
            Root
        };
    }
}
