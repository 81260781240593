import React, { useState, useReducer, useRef, RefObject, useEffect } from 'react';
import {
    Grid,
    TextField,
    // Collapse,
    Button,
    // Link
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import helpers from '../../_utils/Helpers';

// Assets
import defaultThumbnail from '../../Assets/Images/png/default-thumbnail-unit.png';

interface IState {
    name: string;
    latitude: string;
    longitude: string;
    area_id: string;
    area_name: string
    banner_desktop: {
        name: string;
        file: File | null;
    };
    banner_mobile: {
        name: string;
        file: File | null;
    };
    article_banner: {
        name: string;
        file: File | null;
    };
    thumbnail: {
        name: string;
        file: File | null;
    };
    banner_desktop_url: string | null;
    banner_mobile_url: string | null;
    article_banner_url: string | null;
    thumbnail_url: string | null;
    article: string;
    meta_title: string;
    meta_description: string;
}

interface IErrorState {
    name: string;
    latitude: string;
    longitude: string;
    area_id: string;
    banner_desktop: string;
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IRefs {
    [key: string]: RefObject<HTMLInputElement>
}

const KotaMandiriDetailForm = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/kota-mandiri';
    const params: any = useParams();
    const navigate = useNavigate();

    const booleanAttributes = [
        'is_apartment_project'
    ];

    const refs: IRefs = {
        name: useRef<HTMLInputElement>(null),
        latitude: useRef<HTMLInputElement>(null),
        longitude: useRef<HTMLInputElement>(null),
    }

    const initialState = {
        name: '',
        latitude: '',
        longitude: '',
        area_id: '',
        area_name: '',
        banner_desktop: {
            name: "",
            file: null,
        },
        banner_mobile: {
            name: "",
            file: null,
        },
        article_banner: {
            name: "",
            file: null,
        },
        thumbnail: {
            name: "",
            file: null,
        },
        banner_desktop_url: null,
        banner_mobile_url: null,
        article_banner_url: null,
        thumbnail_url: null,
        article: '',
        meta_title: '',
        meta_description: ''
    };

    const initialErrorState = {
        name: '',
        latitude: '',
        longitude: '',
        area_id: '',
        banner_desktop: '',
    };

    // Input Reducer
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    // Error Reducer
    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);
    const [isLoading, setIsLoading] = useState(false);
    const [submitDone, setSubmitDone] = useState(new Date().getTime());

    useEffect(() => {
        if (params.id) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitDone]);

    const mustNull = ['banner_desktop_url', 'banner_mobile_url', 'article_banner_url', 'thumbnail_url'];

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${params.id}`)
            .then(res => {
                const resData = res.data;
                const newState: any = {};

                for (let [key, value] of Object.entries(resData)) {
                    if (booleanAttributes.includes(key)) {
                        newState[key] = !!value;
                    } else if (value === null && (!mustNull.includes(key))) {
                        newState[key] = "";
                    } else {
                        newState[key] = value;
                    }
                }
                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
                setInputState({ name: 'area_name', value: resData.area.name, type: 'SET_ITEM' });
                if (resData.banner_desktop === null) {
                    setInputState({ name: 'banner_desktop', value: initialState.banner_desktop, type: 'SET_ITEM' });
                }
                if (resData.banner_mobile === null) {
                    setInputState({ name: 'banner_mobile', value: initialState.banner_mobile, type: 'SET_ITEM' });
                }
                if (resData.thumbnail === null) {
                    setInputState({ name: 'thumbnail', value: initialState.thumbnail, type: 'SET_ITEM' });
                }
                if (resData.article_banner === null) {
                    setInputState({ name: 'article_banner', value: initialState.article_banner, type: 'SET_ITEM' });
                } 
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const scrollToRef = (ref: RefObject<HTMLInputElement>) => {
        window.scrollTo({ top: ref.current!.offsetTop - 64, behavior: 'smooth' });
    }

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let name = target.name;
        let value: any = target.value;

        if (name === 'banner_desktop' || name === 'banner_mobile' || name === 'article_banner' || name === 'thumbnail') {
            const oldBanner = { ...inputState[name] };

            if (target.files && target.files[0]) {
                oldBanner.file = target.files[0];
            } else {
                oldBanner.file = null;
            }
            value = oldBanner;
        }

        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setInputState({ name, value: value.id, type: 'SET_ITEM' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        let firstError = '';
        const newError = { ...initialErrorState };

        if (!inputState.name) {
            if (!firstError) firstError = 'name';
            newError.name = 'Name wajib diisi';
            isValid = false;
        }
        if (!inputState.latitude) {
            if (!firstError) firstError = 'latitude';
            newError.latitude = 'Latitude wajib diisi';
            isValid = false;
        } else if (!helpers.isValidLatLng(inputState.latitude)) {
            if (!firstError) firstError = 'Latitude';
            newError.latitude = 'Format longitude tidak valid';
            isValid = false;
        }
        if (!inputState.longitude) {
            if (!firstError) firstError = 'longitude';
            newError.longitude = 'Longitude wajib diisi';
            isValid = false;
        } else if (!helpers.isValidLatLng(inputState.longitude)) {
            if (!firstError) firstError = 'longitude';
            newError.longitude = 'Format longitude tidak valid';
            isValid = false;
        }
        if (!inputState.area_id) {
            if (!firstError) firstError = 'area_id';
            newError.area_id = 'Area wajib diisi';
            isValid = false;
        }
        if (firstError) scrollToRef(refs[firstError]);
        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });

        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;

        const fd = new FormData();

        for (let key in inputState) {
            if (['banner_desktop_url', 'banner_mobile_url', 'article_banner_url', 'thumbnail_url'].includes(key)) continue
            const currentValue = inputState[key as keyof IState]
            if (typeof currentValue !== 'string' && currentValue?.file) {
                fd.append(key, currentValue?.file)
            } else if (typeof currentValue === 'string' || typeof currentValue === 'number') {
                fd.append(key, currentValue)
            }
        }

        if (params.id) {
            fd.append('_method', 'PATCH');
            axios = DefaultAxios.post(`${API_URL}/${params.id}`, fd);
        } else {
            axios = DefaultAxios.post(API_URL, fd);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        if (params.id) {
                            loadData();
                            setSubmitDone(new Date().getTime());
                            document.querySelectorAll('[type="file"]').forEach(el => {
                                (el as HTMLInputElement).value = '';
                            })
                        } else {
                            navigate('/mandiri');
                        }
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
                <Grid item xs={12}>
                    <TextField
                        label="Nama"
                        name="name"
                        value={inputState.name}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.name}
                        helperText={errorState.name}
                        ref={refs.name}
                    />
                </Grid>

                {/* <Collapse in={inputState.is_apartment_project} className={classes.collapse}> */}
                <Grid item xs={12}>
                    <TextField
                        label="Latitude"
                        name="latitude"
                        value={inputState.latitude}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.latitude}
                        helperText={errorState.latitude}
                        ref={refs.latitude}
                        placeholder="Ex: -6.198049"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Longitude"
                        name="longitude"
                        value={inputState.longitude}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                        error={!!errorState.longitude}
                        helperText={errorState.longitude}
                        ref={refs.longitude}
                        placeholder="Ex: 106.7626502"
                    />
                </Grid>
                {/* </Collapse> */}
                <Grid item xs={12}>
                    <AsyncAutoComplete
                        label="Area"
                        name="area_id"
                        initialQuery={inputState.area_name}
                        onChange={handleAutocomplete}
                        onInputChange={handleAutocompleteInputChanged}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/area`}
                        errorText={errorState.area_id}
                        iconSearch
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Meta Title"
                        name="meta_title"
                        value={inputState.meta_title}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Meta Description"
                        name="meta_description"
                        value={inputState.meta_description}
                        variant="outlined"
                        fullWidth
                        onChange={handleChanged}
                    />
                </Grid>
                {/* <Grid item xs={12}>
                    <h3>Article</h3>
                    <TextEditor
                        name="article"
                        value={inputState.article}
                        onChange={handleEditorChanged}
                        imagesUpload
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <h3>Upload Banner Desktop (rekomendasi 1400x520px)</h3>
                    {inputState.banner_desktop_url !== null
                        ? <>
                            <img src={inputState.banner_desktop_url} style={{ width: "350px", height: "130px" }} alt="banner desktop" />
                            <br></br>
                        </>
                        : <div style={{ width: "350px", height: "130px", display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fafafa' }}>
                            <img src={defaultThumbnail} style={{ height: "66%" }} alt="banner desktop" />
                        </div>
                    }

                    <input type="file" name="banner_desktop" onChange={handleChanged} style={{ marginBottom: '10px' }} />
                </Grid>

                <Grid item xs={12}>
                    <h3>Upload Banner Mobile (rekomendasi size 420x450px)</h3>
                    {inputState.banner_mobile_url !== null
                        ? <>
                            <img src={inputState.banner_mobile_url} style={{ width: "210px", height: "225px" }} alt="banner mobile" />
                            <br></br>
                        </>
                        : <div style={{ width: "210px", height: "225px", display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fafafa' }}>
                            <img src={defaultThumbnail} style={{ height: "40%" }} alt="banner mobile" />
                        </div>
                    }

                    <input type="file" name="banner_mobile" onChange={handleChanged} style={{ marginBottom: '10px' }} />
                </Grid>

                <Grid item xs={12}>
                    <h3>Upload Banner Article (rekomendasi 1400x520px)</h3>
                    {inputState.article_banner_url !== null
                        ? <>
                            <img src={inputState.article_banner_url} style={{ width: "350px", height: "130px" }} alt="banner article" />
                            <br></br>
                        </>
                        : <div style={{ width: "350px", height: "130px", display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fafafa' }}>
                            <img src={defaultThumbnail} style={{ height: "66%" }} alt="banner article" />
                        </div>
                    }

                    <input type="file" name="article_banner" onChange={handleChanged} style={{ marginBottom: '10px' }} />
                </Grid>

                <Grid item xs={12}>
                    <h3>Upload Thumbnail (rekomendasi size 300x280px)</h3>

                    {inputState.thumbnail_url !== null
                        ? <>
                            <img src={inputState.thumbnail_url} style={{ width: "150px", height: "140px" }} alt="thumbnail" />
                            <br></br>
                        </>
                        : <div style={{ width: "150px", height: "140px", display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fafafa' }}>
                            <img src={defaultThumbnail} style={{ height: "66%" }} alt="thumbnail" />
                        </div>
                    }

                    <input type="file" name="thumbnail" onChange={handleChanged} style={{ marginBottom: '10px' }} />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Submit
                </Button>
                </Grid>
        </>
    );
}

export default KotaMandiriDetailForm;