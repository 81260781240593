import { IFacebookAds } from "../DashboardAds";

export const calculateScore = (row: IFacebookAds | null) => {
    if (!row) {
        return {
            warmPercentage: 0,
            status: 'default',
            scoreStatus: 'default'
        }
    }

    let warmPercentage = (row.leads_warm_and_hot_count === 0 && row.leads_count === 0) ? 0 : row.leads_warm_and_hot_count / row.leads_count * 100
    let status = 'default'
    let scoreStatus = 'default'

    switch (true) {
        case warmPercentage >= 7: status = 'default'; break;
        case warmPercentage >= 5: status = 'warning'; break;
        default: status = 'danger'
    }

    switch (true) {
        case row.client_score_average >= 70: scoreStatus = 'default'; break;
        case row.client_score_average >= 60: scoreStatus = 'warning'; break;
        default: scoreStatus = 'danger'
    }

    if (warmPercentage % 1) {
        warmPercentage = +warmPercentage.toFixed(2)
    }

    return {
        warmPercentage,
        status,
        scoreStatus
    }
}