import { Button, Dialog, Grid } from '@mui/material'
import { DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import LocalDatePicker from '../_form/DatePicker'
import LoadingScreen from '../LoadingScreen'

type Props = {
    open: boolean
    onClose: () => void
    onSubmit: (date: Date) => void
    isLoading?: boolean
}

const DatePickerModal = (props: Props) => {
    const [date, setDate] = useState(new Date())

    const handleChange = (date: Date | null, value?: string | null | undefined) => {
        if (date) {
            setDate(new Date(date))
        }
    }
    const handleSubmit = () => {
        props.onSubmit(date)
    }
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="sm"
            fullWidth
        >
            <LoadingScreen open={!!props.isLoading} />
            <DialogTitle>Pick Date</DialogTitle>
            <DialogContent>
                <Grid container sx={{ mt: 0 }} spacing={2}>
                    <Grid item xs={12}>
                        <LocalDatePicker
                            onChange={handleChange}
                            value={date}
                            label='Date'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant='contained' sx={{ float: 'right' }} onClick={handleSubmit}>Submit</Button>
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog>
    )
}

export default DatePickerModal