import { Dialog, DialogContent, DialogTitle, FormControlLabel, Checkbox, Typography, Divider, Paper, Button } from '@mui/material'
import React, { useMemo } from 'react'

/**
 * Components
 */
import { IClosingLoan, ILoan } from './FormOrder'
import { numberToCurrency } from '../../../_utils/Helpers'
import { generalErrorHandler } from '../../../_utils/Helper'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generateStyle } from '../../../_utils/DefaultStyles'

interface IProps {
    open: boolean,
    transactionId: number
    onClose: () => void
    closingLoan: ILoan
    changeClosingLoan: (loan: IClosingLoan[], type: string) => void
    handleSubmitSuccess: () => void
}

const DraftClosingTransaction = (props: IProps) => {
    const { Root, classes } = useStyles()

    const handleSubmit = () => {
        let allLoan = props.closingLoan.loan_agent;
        if (props.closingLoan.loan_agent_cobroke) {
            allLoan = allLoan.concat(props.closingLoan.loan_agent_cobroke)
        }

        let loan_ids = allLoan.filter(loan => !!loan.is_assigned).map((loan) => loan.id)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/closing-loan/assign-transaction`, {
            transaction_id: props.transactionId,
            loan_ids: loan_ids
        })
            .then(res => res.data)
            .then(res => {
                props.handleSubmitSuccess()
            })
            .catch(generalErrorHandler)

    }

    const handleChangeLoanId = (id: number, loan: IClosingLoan[], type: string) => (e: any, checked: boolean) => {
        let oldClosingLoan = [...loan]
        oldClosingLoan = oldClosingLoan.map((loan) => {
            if (loan.id === id) {
                return {
                    ...loan,
                    is_assigned: checked ? 1 : 0
                }
            } else {
                return loan
            }
        })

        if (type === 'loan') {
            props.changeClosingLoan(oldClosingLoan, 'agent')
        } else {
            props.changeClosingLoan(oldClosingLoan, 'agent_cobroke')
        }
    }

    const renderAgentLoan = useMemo(() => {
        return (
            <>
                {
                    props.closingLoan.loan_agent && props.closingLoan.loan_agent.length ?
                        <Paper component={"div"} className={classes.box} elevation={2}>
                            <Typography className={classes.title} variant='h6'>Pinjaman Advisor {props.closingLoan.agent_name}</Typography>
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            {
                                props.closingLoan.loan_agent.map((loan) => (
                                    <FormControlLabel
                                        key={loan.id}
                                        value={loan.is_assigned}
                                        checked={!!loan.is_assigned}
                                        onChange={handleChangeLoanId(loan.id, props.closingLoan.loan_agent, 'loan')}
                                        control={<Checkbox />}
                                        label={`${loan.code} - Rp. ${numberToCurrency(loan.nominal)}`}
                                    />
                                ))
                            }
                        </Paper>
                        : null
                }

                {
                    props.closingLoan.loan_agent_cobroke && props.closingLoan.loan_agent_cobroke.length ?
                        <Paper component={"div"} className={classes.box} elevation={2}>
                            <Typography className={classes.title} variant='h6'>Pinjaman Advisor {props.closingLoan.cobroke_agent_name} (Cobroke)</Typography>
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            {
                                props.closingLoan.loan_agent_cobroke.map((loan) => (
                                    <FormControlLabel
                                        key={loan.id}
                                        value={loan.is_assigned}
                                        checked={!!loan.is_assigned}
                                        onChange={handleChangeLoanId(loan.id, props.closingLoan.loan_agent_cobroke, 'loan_cobroke')}
                                        control={<Checkbox />}
                                        label={`${loan.code} - Rp. ${numberToCurrency(loan.nominal)}`}
                                    />
                                ))
                            }
                        </Paper>
                        : null
                }
            </>
        )

        // eslint-disable-next-line
    }, [props.closingLoan.loan_agent, props.closingLoan.loan_agent_cobroke])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>
                Pilih Pinjaman yang dibayar di transaksi ini
                <Typography variant='body1'>(kosongkan bila tidak ada)</Typography>
            </DialogTitle>
            <DialogContent>
                <Root>
                    {renderAgentLoan}
                    {(!props.closingLoan.loan_agent?.length && !props.closingLoan.loan_agent_cobroke?.length) ?
                        <Paper component={"div"} className={classes.box} elevation={2}>
                            <Typography className={classes.title} variant='h6'>Tidak ada loan yang dapat di Assign</Typography>
                        </Paper>
                        : null
                    }
                    <Button
                        variant='contained'
                        className={classes.submitButton}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Root>
            </DialogContent>
        </Dialog>
    )
}

const useStyles = generateStyle(theme => ({
    box: {
        padding: 15,
        boxShadow: '0 0 1px #222',
        marginBottom: 5,
        marginTop: 5
    },
    title: {
        marginBottom: 5
    },
    submitButton: {
        marginTop: 10,
        float: 'right'
    }
}), "DraftClosingTransaction")

export default DraftClosingTransaction