import React, { useState, useEffect } from 'react';
import {
    Typography,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Theme,
} from '@mui/material';


/**
 * Icons
 */
import CloseIcon from '@mui/icons-material/Close';

/**
 * Transition
 */
import { TransitionProps } from '@mui/material/transitions';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler } from '../../_utils/Helper';
/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import { generateStyle } from '../../_utils/DefaultStyles';

interface IProps {
    isOpen: boolean;
    lead_name: string;
    lead_id: string;
    onClose: Function;
}

interface IListCluster {
    id: string;
    name: string;
    page_type: string;
    slug: string;
    cluster_id: number;
    avg_daily_leads: number;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = generateStyle((theme: Theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    table: {
        minWidth: 650,
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    button: {
        height: "100%"
    },
    containerButton: {
        gap: "5px",
        height: '100%'
    },
    buttonIconContainer: {
        justifyContent: 'flex-start',
        gap: '5px'
    }
}), "LeadClusterPlacementModal");

export default function LeadClusterPlacementModal({ isOpen, lead_name, lead_id, onClose }: IProps) {
    const { Root, classes } = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [listPlacement, setListPlacement] = useState<Array<IListCluster>>([]);


    const handleClose = () => {
        onClose();
    }

    useEffect(() => {
        if (lead_id !== "") {
            loadData();
        }
        // eslint-disable-next-line
    }, [lead_id]);

    const loadData = () => {
        setIsLoading(true)
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/lead-group/cluster`, { params: { id: lead_id } })
            .then(res => {
                setListPlacement(res.data as Array<IListCluster>)
            })
            .catch(err => {
                generalErrorHandler(err);
                onClose()
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
            <Root>
                <LoadingScreen open={isLoading} fullScreen />
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Lead Placement - {lead_name}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div className={classes.content}>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nama Cluster</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    listPlacement.length ?
                                        listPlacement.map((row, key) => (
                                            <TableRow key={row.cluster_id}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}<span style={{ fontSize: "10px" }}></span>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        :
                                        <TableRow key="cluster-none">
                                            <TableCell component="th" scope="row">
                                                <i>Tidak ada Cluster</i>
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Root>
        </Dialog>
    )
}