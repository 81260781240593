import React, { useState, useEffect, useReducer, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, MenuItem, InputAdornment, IconButton } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import LoadingScreen from '../../_components/LoadingScreen';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
// import { TimePicker } from '@mui/x-date-pickers';

/**
 * Context
 */
import { PermissionContext } from "../../_contexts/PermissionContext";

//Icon
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

/**
 * Utils
 */
import helpers from '../../_utils/Helpers';
import DefaultAxios from '../../_utils/DefaultAxios';
import { alphaNumeric, inputNumber } from '../../_utils/Helper';
// import { format } from 'date-fns';

interface Props {
    open: boolean;
    onClose: (fetch: boolean) => void;
    userId: string;
}

interface IState {
    id: string;
    full_name: string;
    name: string;
    email: string;
    notification_email: string;
    phone: string;
    level: string;
    primary_bank_type_id: string;
    primary_bank_type_label: string;
    primary_bank_number: string;
    primary_bank_behalf_name: string;
    bukan_kata_sandi: string;
    bukan_kata_sandi_confirmation: string;
    limit_broadcast: number | null;
    leads_percentage: number;
    group_id: string
    group_name: string
    is_manual_level_setting: number;
    // leads_start_time: Date | null
    // leads_end_time: Date | null
    is_part_time: number
    role_id: null | number
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IErrorState {
    name: string;
    full_name: string;
    email: string;
    notification_email: string;
    phone: string;
    level: string;
    primary_bank_type_id: string;
    primary_bank_number: string;
    primary_bank_behalf_name: string;
    bukan_kata_sandi: string;
    bukan_kata_sandi_confirmation: string;
    limit_broadcast: string;
    group_id: string
}

const AgentForm = ({ open, onClose, userId }: Props) => {
    const API_URL = `${process.env.REACT_APP_API_URL}/user`;
    const permissions = useContext(PermissionContext);

    const initialState = {
        id: '',
        full_name: '',
        name: '',
        email: '',
        notification_email: '',
        phone: '',
        level: '',
        primary_bank_type_id: '',
        primary_bank_type_label: '',
        primary_bank_number: '',
        primary_bank_behalf_name: '',
        bukan_kata_sandi: '',
        bukan_kata_sandi_confirmation: '',
        limit_broadcast: null,
        leads_percentage: 100,
        group_id: '',
        group_name: '',
        is_manual_level_setting: 0,
        // leads_start_time: null,
        // leads_end_time: null,
        is_part_time: 0,
        role_id: null,
    };

    const initialErrorState = {
        name: '',
        full_name: '',
        email: '',
        notification_email: '',
        phone: '',
        level: '',
        primary_bank_type_id: '',
        primary_bank_number: '',
        primary_bank_behalf_name: '',
        bukan_kata_sandi: '',
        bukan_kata_sandi_confirmation: '',
        limit_broadcast: '',
        group_id: '',
    };

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState({
        bukan_kata_sandi: false,
        bukan_kata_sandi_confirmation: false
    });

    useEffect(() => {
        setVisible({
            bukan_kata_sandi: false,
            bukan_kata_sandi_confirmation: false
        })

        if (!open) {
            setInputState({ name: '', value: initialState, type: 'REPLACE_STATE' });
            setErrorState({ name: '', value: initialErrorState, type: 'REPLACE_STATE' });
        } else {
            if (userId) {
                loadData();
            }
        }
        // eslint-disable-next-line
    }, [open])

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${userId}`)
            .then(res => {
                // const date = new Date();
                // var start_time = res.data.leads_start_time ? new Date(date.setHours(res.data.leads_start_time.substring(0, 2), res.data.leads_start_time.substring(3, 5))) : null;
                // var end_time = res.data.leads_end_time ? new Date(date.setHours(res.data.leads_end_time.substring(0, 2), res.data.leads_end_time.substring(3, 5))) : null;

                const newState = {
                    ...initialState, ...res.data, group_id: res.data.group_id || '', group_name: res.data.group_name || '',
                    // leads_start_time: start_time, leads_end_time: end_time
                };
                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target;
        let name = target.name;
        let value = target.value;

        switch (name) {
            case 'bukan_kata_sandi':
            case 'bukan_kata_sandi_confirmation':
                value = value.replace(/\s*/g, '');
                break
            case 'phone':
                value = helpers.convertIndonesiaPhoneNumber(value);
                break
            case 'bank_number':
                value = inputNumber(value)
                break
            case 'full_name':
                value = alphaNumeric(value)
                break
        }

        setInputState({ name: name, value: value, type: 'SET_ITEM' });
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        if (!inputState.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        }

        if (!inputState.email) {
            newError.email = 'Email wajib diisi';
            isValid = false;
        } else if (!helpers.isValidEmail(inputState.email)) {
            newError.email = 'Format email tidak benar';
            isValid = false;
        }

        if (inputState.notification_email) {
            if (!helpers.isValidEmail(inputState.notification_email)) {
                newError.notification_email = 'Format email tidak benar';
                isValid = false;
            }
        }

        // if (!inputState.role_id) {
        //     newError.role_id = 'Role wajib diisi';
        //     isValid = false;
        // }

        if (!inputState.id) {
            if (!inputState.bukan_kata_sandi) {
                newError.bukan_kata_sandi = 'Password wajib diisi';
                isValid = false;
            }

            // if (!inputState.password_confirmation) {
            //     newError.password_confirmation = 'Confirm Password wajib diisi';
            //     isValid = false;
            // } else if (inputState.password_confirmation.length < 8) {
            //     newError.password_confirmation = 'Confirm Password minimal 8 karakter';
            //     isValid = false;
            // }
        }

        if (inputState.bukan_kata_sandi && inputState.bukan_kata_sandi.length < 8) {
            newError.bukan_kata_sandi = 'Password minimal 8 karakter';
            isValid = false;
        }

        if (inputState.bukan_kata_sandi !== inputState.bukan_kata_sandi_confirmation) {
            newError.bukan_kata_sandi_confirmation = 'Confirm Password harus sama dengan Password';
            isValid = false;
        }

        // if (
        //     localStorage.getItem("role") !== "superadmin" ||
        //     (localStorage.getItem("role") === "superadmin" && isAgentOrManager())
        // ) {
        // }
        if (!inputState.level) {
            newError.level = 'Level wajib diisi';
            isValid = false;
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });
        return isValid;
    }

    const handleSubmit = () => {
        if (!checkValidation()) {
            // return;
        }

        const data = {
            ...inputState,
            // leads_start_time: inputState.leads_start_time ? format(new Date(inputState.leads_start_time), "HH:mm") : null,
            // leads_end_time: inputState.leads_end_time ? format(new Date(inputState.leads_end_time), "HH:mm") : null
        }

        let axios;

        if (inputState.id) {
            axios = DefaultAxios.patch(`${API_URL}/${inputState.id}`, data)
        } else {
            axios = DefaultAxios.post(API_URL, { ...inputState, role_id: '5' });
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        onClose(true);
                    },
                    timer: 1000
                })
            })
            .catch(err => {
                helpers.generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        if (name === 'primary_bank_type_id') {
            setInputState({ name: 'primary_bank_type_id', value: value.id, type: 'SET_ITEM' });
            setInputState({ name: 'primary_bank_type_label', value: value.label, type: 'SET_ITEM' });
        } else {
            setInputState({ name: 'group_id', value: value.id, type: 'SET_ITEM' });
            setInputState({ name: 'group_name', value: value.label, type: 'SET_ITEM' });
        }

    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        if (name === 'primary_bank_type_id') {
            setInputState({ name: 'primary_bank_type_id', value: '', type: 'SET_ITEM' });
            setInputState({ name: 'primary_bank_type_label', value: '', type: 'SET_ITEM' });
        } else {
            setInputState({ name: 'group_id', value: '', type: 'SET_ITEM' });
            setInputState({ name: 'group_name', value: '', type: 'SET_ITEM' });
        }
    }

    const handleClearAutoComplete = () => {
        setInputState({ name: 'primary_bank_type_id', value: '', type: 'SET_ITEM' });
        setInputState({ name: 'primary_bank_type_label', value: '', type: 'SET_ITEM' });
    }

    // const handleTimeChanged = (name: string, date: any) => {
    //     setInputState({ name: name, value: date, type: 'SET_ITEM' });
    // }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            fullWidth
            maxWidth="md"
        >
            <LoadingScreen open={isLoading} fullScreen />
            <DialogTitle>Agent Form</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ paddingTop: 8 }}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Name"
                            name="name"
                            value={inputState.name}
                            onChange={handleChange}
                            error={!!errorState.name}
                            helperText={errorState.name}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Full Name"
                            name="full_name"
                            value={inputState.full_name}
                            onChange={handleChange}
                            error={!!errorState.full_name}
                            helperText={errorState.full_name}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Email"
                            name="email"
                            value={inputState.email}
                            onChange={handleChange}
                            error={!!errorState.email}
                            helperText={errorState.email}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Notification Email"
                            name="notification_email"
                            value={inputState.notification_email}
                            onChange={handleChange}
                            error={!!errorState.notification_email}
                            helperText={errorState.notification_email}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Phone"
                            name="phone"
                            value={inputState.phone}
                            onChange={handleChange}
                            error={!!errorState.phone}
                            helperText={errorState.phone}
                        />
                    </Grid>

                    {(inputState.id ? (permissions['user.admin']) : true) ?
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Limit Broadcast"
                                name="limit_broadcast"
                                InputLabelProps={{ shrink: true }}
                                value={inputState.limit_broadcast}
                                onChange={handleChange}
                                error={!!errorState.limit_broadcast}
                                helperText={errorState.limit_broadcast}
                            />
                        </Grid>
                        :
                        null
                    }

                    {(inputState.id ? (permissions['user.admin'] && inputState.is_manual_level_setting) : true) ?
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    fullWidth
                                    variant="outlined"
                                    label="Level"
                                    name="level"
                                    value={inputState.level}
                                    onChange={handleChange}
                                    error={!!errorState.level}
                                    helperText={errorState.level}
                                >
                                    <MenuItem value="junior">Junior</MenuItem>
                                    <MenuItem value="mid">Mid</MenuItem>
                                    <MenuItem value="senior">Senior</MenuItem>
                                </TextField>
                            </Grid>
                        </>
                        :
                        null
                    }
                    {/* {
                        inputState.is_part_time === 1 &&
                        <>
                            <Grid item xs={12} md={6}>
                                <TimePicker
                                    label="Waktu Terima Leads"
                                    ampm={false}
                                    value={inputState.leads_start_time}
                                    onChange={(date: any) => handleTimeChanged('leads_start_time', date)}
                                    renderInput={(props) => (
                                        <TextField
                                            {...props}
                                            variant="outlined"
                                            fullWidth
                                            label={props.label}
                                            value={inputState.leads_start_time}
                                            placeholder={props.placeholder ? props.placeholder : "dd/MM/yyyy"}
                                        // error={props.error ? props.error : false}
                                        // helperText={props.helperText ? props.helperText : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TimePicker
                                    label="Waktu Stop Leads"
                                    ampm={false}
                                    value={inputState.leads_end_time}
                                    onChange={(date: any) => handleTimeChanged('leads_end_time', date)}
                                    minTime={inputState.leads_start_time}
                                    renderInput={(props) => (
                                        <TextField
                                            {...props}
                                            variant="outlined"
                                            fullWidth
                                            label={props.label}
                                            placeholder={props.placeholder ? props.placeholder : "dd/MM/yyyy"}
                                            error={props.error ? props.error : false}
                                            helperText={props.helperText ? props.helperText : ''}
                                        />
                                    )}
                                />
                            </Grid>
                        </>
                    } */}
                    {
                        (!inputState.role_id || ![25, 26].includes(inputState.role_id)) ?
                            <Grid item xs={12}>
                                <AsyncAutoComplete
                                    url={`${process.env.REACT_APP_API_URL}/autocomplete/lead-group`}
                                    label="Lead Group"
                                    name="group_id"
                                    initialQuery={inputState.group_name}
                                    onChange={handleAutocomplete}
                                    onInputChange={handleAutocompleteInputChanged}
                                    placeholder="Masukan Nama Group"
                                    errorText={errorState.group_id}
                                />
                            </Grid>
                            : null
                    }

                    {/* {
                        (inputState.id && permissions['user.admin']) &&
                        <>

                            <Grid item xs={12}>
                                <TextField
                                    select
                                    fullWidth
                                    variant="outlined"
                                    label="Leads Percentage"
                                    name="leads_percentage"
                                    value={inputState.leads_percentage}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={100}>100%</MenuItem>
                                    <MenuItem value={90}>90%</MenuItem>
                                    <MenuItem value={80}>80%</MenuItem>
                                    <MenuItem value={70}>70%</MenuItem>
                                    <MenuItem value={60}>60%</MenuItem>
                                    <MenuItem value={50}>50%</MenuItem>
                                    <MenuItem value={40}>40%</MenuItem>
                                    <MenuItem value={30}>30%</MenuItem>
                                    <MenuItem value={20}>20%</MenuItem>
                                    <MenuItem value={10}>10%</MenuItem>
                                    <MenuItem value={0}>0%</MenuItem>
                                </TextField>
                            </Grid>
                        </>

                    } */}

                    <Grid item xs={12}>
                        <AsyncAutoComplete
                            label='Nama Bank'
                            name='primary_bank_type_id'
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/bank-type`}
                            initialQuery={inputState.primary_bank_type_label ? inputState.primary_bank_type_label : ''}
                            errorText={errorState.primary_bank_type_id}
                            clearable
                            onClear={handleClearAutoComplete}
                        />
                        {/* <TextField
                            fullWidth
                            variant="outlined"
                            label="Nama Bank"
                            name="bank_name"
                            value={inputState.bank_name}
                            onChange={handleChange}
                            error={!!errorState.bank_name}
                            helperText={errorState.bank_name}
                        /> */}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Nomor Rekening"
                            name="primary_bank_number"
                            value={inputState.primary_bank_number}
                            onChange={handleChange}
                            error={!!errorState.primary_bank_number}
                            helperText={errorState.primary_bank_number}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Atas Nama Rekening"
                            name="primary_bank_behalf_name"
                            value={inputState.primary_bank_behalf_name}
                            onChange={handleChange}
                            error={!!errorState.primary_bank_behalf_name}
                            helperText={errorState.primary_bank_behalf_name}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type={visible.bukan_kata_sandi ? 'text' : 'password'}
                            label="Password"
                            name="bukan_kata_sandi"
                            value={inputState.bukan_kata_sandi}
                            onChange={handleChange}
                            error={!!errorState.bukan_kata_sandi}
                            helperText={errorState.bukan_kata_sandi}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setVisible(prevState => ({ ...prevState, bukan_kata_sandi: !prevState.bukan_kata_sandi }))}
                                        >
                                            {visible.bukan_kata_sandi ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type={visible.bukan_kata_sandi_confirmation ? 'text' : 'password'}
                            label="Confirm Password"
                            name="bukan_kata_sandi_confirmation"
                            value={inputState.bukan_kata_sandi_confirmation}
                            onChange={handleChange}
                            error={!!errorState.bukan_kata_sandi_confirmation}
                            helperText={errorState.bukan_kata_sandi_confirmation}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm password visibility"
                                            onClick={() => setVisible(prevState => ({ ...prevState, bukan_kata_sandi_confirmation: !prevState.bukan_kata_sandi_confirmation }))}
                                        >
                                            {visible.bukan_kata_sandi_confirmation ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AgentForm;