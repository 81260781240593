import React, { useEffect, useMemo, useRef, useState } from "react";
import { Paper } from "@mui/material";
import EnquiryChart from "./_components/EnquiryChart";
import FilterDate from "./_components/FilterDate";
import DefaultAxios from "../../_utils/DefaultAxios";
import { generalErrorHandler, getMonth } from "../../_utils/Helper";
import LoadingScreen from "../../_components/LoadingScreen";

export interface MonthYearOption {
    display: string;
    month?: number;
    year: number;
    values: number;
}

export interface GroupItem {
    created_at: string;
    id: number;
    is_hide_autocomplete: number;
    name: string;
    telesales_id: number | null;
    updated_at: string;
}

export interface DataBreakdown {
    "Facebook Ads": number;
    "Facebook DM": number;
    Instagram: number;
    Whatsapp: number;
    Youtube: number;
    "Google Ads": number;
    GDN: number;
    Others: number;
    SEO: number;
    "Input Agent": number;
    "Direct Web": number;
    Call: number;
    TikTok: number;
    "Reference Agent": number;
    "Reference CP": number;
    "Facebook Leads": number;
    "Transferred Telesales": number;
    "Direct WA": number;
    "Direct WA SLP V2": number;
    "Direct WA Conversion": number;
}

export interface BreakdownItem {
    breakdown: string;
    data: DataBreakdown;
}

const defaultMonth = {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    display: `${getMonth(new Date().getMonth())} ${new Date().getFullYear()}`,
    values: 0
};


const EnquiryReport = () => {
    const [dataBreakdown, setDataBreakdown] = useState<BreakdownItem[]>([]);
    const [dataGroup, setDataGroup] = useState<GroupItem[]>([]);
    const [valueGrup, setValueGrup] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = useState<
        "daily" | "weekly" | "monthly"
    >("daily");

    const [selectedMonth, setSelectedMonth] =
        useState<MonthYearOption>(defaultMonth);

    const isFirstInit = useRef(true)

    useEffect(() => {
        fetchBreakdownData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonth?.values, valueGrup, selectedFilter])

    useEffect(() => {
        fetchGroups();
    }, []);

    useEffect(() => {
        const today = new Date();
        const defaultMonthly = {
            display: `${today.getFullYear()}`,
            year: today.getFullYear(),
            values: 0,
        };
        const defaultDailyWeekly = {
            display: `${getMonth(today.getMonth())} ${today.getFullYear()}`,
            month: today.getMonth() + 1,
            year: today.getFullYear(),
            values: 0,
        };
        if (selectedFilter === "monthly") {
            setSelectedMonth(defaultMonthly)
        }
        else {
            setSelectedMonth(defaultDailyWeekly)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilter])

    const fetchBreakdownData = async () => {
        setLoading(true);
        try {
            const response = await DefaultAxios.get(
                `${process.env.REACT_APP_API_URL}/lead/breakdown`,
                {
                    params: {
                        time: selectedMonth?.values,
                        breakdown: selectedFilter || "weekly",
                        group_id: valueGrup || null,
                    }
                }
            );
            const data = response.data;
            setDataBreakdown(data);
            isFirstInit.current = false
        } catch (error) {
            generalErrorHandler(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchGroups = () => {
        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/lead-group/all`)
            .then((res) => res?.data)
            .then((data) => {
                setDataGroup(data);
            })
            .catch(generalErrorHandler);
    };

    const handleGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        const selected = dataGroup.find(
            (groupId) => groupId.id === parseInt(value)
        );
        if (selected) {
            setValueGrup(selected.id);
        } else {
            setValueGrup(null);
        }
    };


    const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        const selected = dateYearOptions.find(
            (dateYear) => dateYear.display === value
        );

        if (selected) {
            setSelectedMonth(selected);
        } else {
            setSelectedMonth(defaultMonth);
        }
    };

    const monthlyOptions = useMemo(() => {
        const options: MonthYearOption[] = [];
        const today = new Date();
        const currentYear = today.getFullYear();

        for (let i = 0; i <= 11; i++) {
            const currentDate = new Date(currentYear, today.getMonth() - i, 1);
            options.push({
                display: `${getMonth(currentDate.getMonth())} ${currentDate.getFullYear()}`,
                month: currentDate.getMonth() + 1,
                year: currentDate.getFullYear(),
                values: i,
            });
        }
        return options;
    }, []);

    const yearlyOptions = useMemo(() => {
        const options: MonthYearOption[] = [];
        const today = new Date();
        const currentYear = today.getFullYear();

        for (let i = 0; i <= 11; i++) {
            const currentYears = new Date(currentYear - i, 1);
            options.push({
                display: `${currentYears.getFullYear()}`,
                year: currentYears.getFullYear(),
                values: i,
            });
        }
        return options;
    }, []);

    const dateYearOptions = useMemo(() => {
        return selectedFilter !== "monthly" ? monthlyOptions : yearlyOptions;
    }, [monthlyOptions, selectedFilter, yearlyOptions]);

    return (
        <div>
            <LoadingScreen open={loading} fullScreen />
            <FilterDate
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                selectedMonth={selectedMonth}
                dateYearOptions={dateYearOptions}
                handleSelect={handleSelect}
                dataGroup={dataGroup}
                handleGroup={handleGroup}
                valueGroup={valueGrup}
            />
            <Paper>
                {dataBreakdown.length > 1 ?
                    <EnquiryChart dataBreakdown={dataBreakdown} />
                    : <p style={{ textAlign: 'center' }}>Tidak ada data pada periode tersebut</p>}
            </Paper>
        </div>
    );
};

export default EnquiryReport;
