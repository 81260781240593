import { Button, Dialog, Paper, DialogContent, DialogTitle, Grid, TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material'
import React, { useMemo, useState, useEffect } from 'react'
import LoadingScreen from '../../../_components/LoadingScreen'

/**
 * Utils
 */
import { numberToCurrency } from '../../../_utils/Helpers'
import { IEscrowTransaction, IEscrowTransactionPropose } from '../../_verifyTransaction/VerifyTransactionList'
import DefaultAxios from '../../../_utils/DefaultAxios'
import { generalErrorHandler, renderSuccessButton, renderWarningButton } from '../../../_utils/Helper'
import { escrowTransactionType } from '../../_statement/StatementList'

interface IProps {
    open: boolean,
    status: string
    onClose: (refresh: boolean) => void
    id: number | string
}

type ICombinedInt = IEscrowTransaction & IEscrowTransactionPropose

const ListTransactionModal = (props: IProps) => {
    const [forceNoOfficeCommission, setForceNoOfficeCommission] = useState(false)
    const [transactionList, setTransactionList] = useState<ICombinedInt[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const API_URL_DRAFT = `${process.env.REACT_APP_API_URL}/closing-transaction-propose`;
    const API_URL = `${process.env.REACT_APP_API_URL}/closing-transaction/${props.id}`;

    const fetchData = (status: string, flagForce = false) => {
        setIsLoading(true)

        DefaultAxios.get(status === 'draft' ? API_URL_DRAFT : API_URL, { params: status === 'draft' ? { id: props.id, force_no_office_commission: flagForce ? 1 : 0 } : undefined })
            .then(res => res.data)
            .then(data => {
                if (status === 'draft') {
                    setTransactionList(data as ICombinedInt[])
                } else {
                    setTransactionList(data.escrow_transactions as ICombinedInt[])
                }
            })
            .catch(err => {
                if (err.response.status === 417) {
                    renderWarningButton('Komisi Kantor ditemukan sebagai Nol. Lanjutkan untuk propose?')
                    .then((result) => {
                        if (result.value) {
                            setForceNoOfficeCommission(true)
                            fetchData(props.status, true)
                        } else {
                            props.onClose(false)
                        }
                    })
                } else {
                    props.onClose(false)
                    generalErrorHandler(err)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleRequest = () => {
        // Generate Propose
        setIsLoading(true)
        DefaultAxios.post(`${API_URL_DRAFT}`, { id: props.id, force_no_office_commission: forceNoOfficeCommission ? 1 : 0 })
            .then(res => res.data)
            .then(data => {
                renderSuccessButton('Transaction Proposed!')
                props.onClose(true)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const renderContent = useMemo(() => {
        if (transactionList.length) {
            if (props.status === 'draft') {
                return transactionList.map((transaction: IEscrowTransactionPropose) => {
                    let escrowType = escrowTransactionType.find(value => value.value === transaction.type)

                    return (
                        <TableRow key={transaction.type}>
                            <TableCell>
                                {escrowType?.label || ''}
                            </TableCell>
                            <TableCell>Rp. {numberToCurrency(transaction.amount)}</TableCell>
                            <TableCell>{transaction.bank_type_label}</TableCell>
                            <TableCell>{transaction.number}</TableCell>
                            <TableCell>{transaction.behalf_name}</TableCell>
                        </TableRow>
                    )
                })
            } else {
                return transactionList.map((transaction: IEscrowTransaction) => {
                    return (
                        <TableRow key={transaction.id}>
                            <TableCell>{transaction.code}</TableCell>
                            <TableCell>Rp. {numberToCurrency(transaction.amount)}</TableCell>
                            <TableCell>{transaction.bank_type_label || '-'}</TableCell>
                            <TableCell>{transaction.bank_number || '-'}</TableCell>
                            <TableCell>{transaction.bank_behalf_name || '-'}</TableCell>
                        </TableRow>
                    )
                })
            }
        } else {
            return (
                <TableRow>
                    <TableCell colSpan={5}>Tidak ada data transaksi.</TableCell>
                </TableRow>
            )
        }

        // eslint-disable-next-line
    }, [transactionList])

    const getTotalAmount = (list: ICombinedInt[]) => {
        return list.reduce((total, item) => total + item.amount, 0)
    }

    useEffect(() => {
        if (props.open && props.id) {
            setForceNoOfficeCommission(false)
            fetchData(props.status)
        }

        // eslint-disable-next-line
    }, [props.open])

    return (
        <>
            {
                isLoading ?
                    <LoadingScreen open={isLoading} /> :
                    <Dialog
                        open={props.open}
                        onClose={props.onClose}
                        maxWidth="md"
                        fullWidth
                    >
                        <DialogTitle>List Transaksi</DialogTitle>
                        <DialogContent>
                            <TableContainer component={Paper} elevation={0}>
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {props.status === 'draft' ? 'Jenis Transaksi' : 'Kode'}
                                            </TableCell>
                                            <TableCell style={{ minWidth: '150px' }}>
                                                Nominal
                                            </TableCell >
                                            <TableCell style={{ minWidth: '80px' }}>
                                                Nama Bank
                                            </TableCell>
                                            <TableCell style={{ minWidth: '120px' }}>
                                                No. Rekening
                                            </TableCell>
                                            <TableCell style={{ minWidth: '100px' }}>
                                                Atas Nama
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {renderContent}
                                        {transactionList.length ?
                                            <TableRow>
                                                <TableCell style={{textAlign: 'right'}}>
                                                    <strong>Total</strong>
                                                </TableCell>
                                                <TableCell colSpan={4}>
                                                    {numberToCurrency(getTotalAmount(transactionList), "Rp. ")}
                                                </TableCell>
                                            </TableRow>
                                            : null}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {
                                props.status === 'draft' ?
                                    <Grid container sx={{ mt: 1 }}>
                                        <Grid item xs={12}>
                                            <Button
                                                color='primary'
                                                sx={{ mt: 1, float: 'right' }}
                                                variant='contained'
                                                onClick={handleRequest}
                                            >
                                                Request
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    : null
                            }
                        </DialogContent>
                    </Dialog>
            }
        </>

    )
}

export default ListTransactionModal