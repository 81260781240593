import React, { useState, useEffect, CSSProperties } from 'react';

// Table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// Utilities
import SortableTableHeader from './SortableTableHeader';

// Component
import LoadingScreen from '../LoadingScreen';

// Styles
import './CSS/datatable.css';
import { generateStyle } from '../../_utils/DefaultStyles';

interface Props {
    columns: IColumn[];
    customData: any[]
    reDraw?: number;
}

interface IColumn {
    name: string;
    label: string | React.ReactElement;
    type?: string;
    render?: Function;
    style?: CSSProperties | any;
    options?: any[] | Object;
    defaultOption?: string;
}

const useStyles = generateStyle(theme => ({
    table: {
        // position: 'relative'
    },
    tableContainer: {
        position: 'relative',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '5px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "50%"
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    refresh_btn: {
        marginTop: theme.spacing(2)
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    noData: {
        textAlign: 'center'
    },
    loadingBody: {
        textAlign: 'center',
        position: 'relative',
        height: '100px'
    }
}), "Simple_Data_Table")

export default function SimpleDataTable(props: Props) {
    const columns = props.columns;
    const { Root, classes } = useStyles()
    const [rows, setRows] = useState<any[]>([]);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        setRows(props.customData || [])
        setLoading(false)
        // eslint-disable-next-line
    }, []);

    const loadTable = () => {
        setRows([])
        setLoading(true);
        setRows(props.customData || [])
        setLoading(false)
    }

    useEffect(() => {
        loadTable()
        // eslint-disable-next-line
    }, [props.reDraw])

    const getTableBody = () => {
        if (loading) {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell
                            className={classes.loadingBody}
                            colSpan={props.columns.length}
                        >
                            <LoadingScreen open={loading} fullScreen={false} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            );
        } else if (rows.length > 0) {
            return (
                <TableBody>
                    {
                        rows.map((row, i: number) => {
                            let index = i;
                            const tableRow = columns.map((column: any, i: number) => {
                                let value = null;
                                // let style = {};
                                if (typeof row[column.name] !== 'undefined') {
                                    if (column.render && typeof column.render === 'function') {
                                        if (column.options && column.options !== '') {
                                            value = column.render(row[column.name], row, column.name, column.options, setRows, rows);
                                        } else {
                                            value = column.render(row[column.name], row, rows, index);
                                        }
                                    } else {
                                        value = row[column.name];
                                    }
                                } else if (column.name === 'EXTRA' && column.render && typeof column.render === 'function') {
                                    value = column.render(row);
                                }

                                return value === 'NONE' ? null : value === 'Off Day' ? <TableCell colSpan={2} style={{ textAlign: 'center', ...column.columnStyle }} key={i}>{value}</TableCell> : <TableCell style={{ textAlign: 'center', ...column.columnStyle }} key={i}>{value}</TableCell>
                            });
                            return <TableRow key={i}>{tableRow}</TableRow>
                        })
                    }
                </TableBody>
            )
        } else {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell
                            className={classes.noData}
                            colSpan={props.columns.length}
                        >
                            No Data Available
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        }
    }

    return (
        <Root>
            <TableContainer
                component={Paper}
                className={classes.tableContainer}
            >
                <Table className={`${classes.table}`} size="small" aria-label="a dense table">
                    <TableHead>
                        <SortableTableHeader
                            sorting={false}
                            headCells={props.columns.slice()}
                            order={false}
                            orderBy={''}
                            onRequestSort={() => { }}
                        />
                    </TableHead>
                    {getTableBody()}
                </Table>
            </TableContainer>
        </Root>
    );
}